import fetchService from '../../components/utils/fetchService'
import config from '../../config'
import { handleFetchError } from '../../components/utils/Utils'
import { setVesselServicesData } from '../slices/vessel-slice'
import isEmpty from 'lodash/isEmpty'
import * as Toastify from '../../components/utils/Toastify'

export const fetchVesselServices = (controller, loader) => {
  return async (dispatch) => {
    try {
      loader(true)
      const data = await fetchService({
        url: config.api.oneStop[controller],
      })
      if (isEmpty(data)) {
        Toastify.Error('No Data Found')
        return
      }
      dispatch(setVesselServicesData({ data, controller }))
    } catch (err) {
      handleFetchError(
        err,
        'Some error occurred while fetching Accounting Details.'
      )
    } finally {
      loader(false)
    }
  }
}
