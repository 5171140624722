import { faClose } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import isEmpty from 'lodash/isEmpty'
import React, { useEffect, useState } from 'react'
import Accordion from 'react-bootstrap/Accordion'
import { slide as Menu } from 'react-burger-menu'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { getCurrentRouteData } from '../../store/slices/route-slice'
import { closeSidebar, getShowSidebar } from '../../store/slices/ui-slice'
import './SideNavbar.scss'
import {
  cerebroList,
  clmList,
  moduleNameList,
  wmsList,
  ymsList,
} from '../../lib/navigation.list'

const NavList = ({ list, i }) => (
  <Accordion.Item eventKey={i} className="my-2">
    <Accordion.Header as="div">
      <Link
        style={{ textDecoration: 'none', display: 'flex' }}
        to={list?.imgRedirectTo}
      >
        <img
          style={{ height: '30px', width: '30px' }}
          src={list?.sectionHeaderImgSrc}
          alt=""
        />
        <h3 className="ms-2">{list?.sectionHeader}</h3>
      </Link>
    </Accordion.Header>
    <Accordion.Body style={{ padding: '0' }}>
      <ul className="my-3">
        {list.liS?.map((li, index) => (
          <li key={index}>
            <Link style={{ textDecoration: 'none' }} to={li?.pathTo}>
              <span>{li.liText}</span>
            </Link>
          </li>
        ))}
      </ul>
    </Accordion.Body>
  </Accordion.Item>
)

export default function SideNavbar() {
  const route = useSelector(getCurrentRouteData)
  const showSidebar = useSelector(getShowSidebar)
  const dispatch = useDispatch()

  const [uList, setUList] = useState(cerebroList)

  useEffect(() => {
    if (!isEmpty(route)) {
      if (route?.showSideBarOf === 'clm-dashboard') setUList(clmList)
      else if (route?.showSideBarOf === 'wms-dashboard') setUList(wmsList)
      else if (route?.showSideBarOf === 'yms-dashboard') setUList(ymsList)
      else setUList(cerebroList)
    }
  }, [route])

  const containerStyles = { padding: '8px', border: '1px solid grey' }

  return (
    <Menu
      isOpen={showSidebar}
      burgerButtonClassName="d-none"
      onClose={() => dispatch(closeSidebar())}
      crossButtonClassName="d-none"
    >
      <div className="sideNavbar-wrapper">
        <div
          title="Toggle Navbar"
          className="icon-container"
          aria-label="Close"
          onClick={() => dispatch(closeSidebar())}
        >
          <FontAwesomeIcon className="fa" icon={faClose} />
        </div>
        {uList?.map((_list, i) => (
          <Accordion key={i}>
            <NavList list={_list} i={i} />
          </Accordion>
        ))}
        <div className="bottom-logo">
          {!isEmpty(route) &&
            moduleNameList.map((moduleDashboard) => {
              const { showSideBarOf = 'dashboard' } = route
              if (moduleDashboard.link !== showSideBarOf) {
                return (
                  <Link
                    key={moduleDashboard.link}
                    to={`/${moduleDashboard.link}`}
                    target="_blank"
                    className="trackmile-container"
                    style={containerStyles}
                  >
                    <span className="trackmile-text">
                      {moduleDashboard.title}
                    </span>
                  </Link>
                )
              }
              return null
            })}
        </div>
      </div>
    </Menu>
  )
}
