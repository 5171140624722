const formData = [
  {
    name: 'General Info',
    fields: [
      {
        name: 'Wharf Job Type',
        label: 'Wharf Job Type',
        type: 'select',
        validation: 'Alphabetic',
        fieldId: 'wharfJobType',
        id: 'wharfJobType',
        loadLookUp: 'wharfJobTypes',
        valueId: 'id',
        displayId: 'description',
        validationType: 'string',
        valueasId: true,
        validations: [
          {
            type: 'required',
            params: ['##FIELD_NAME## is required'],
            nullable: true,
          },
        ],
        options: [],
      },
      {
        name: 'Vehicle Job Type',
        label: 'Vehicle Job Type',
        type: 'select',
        validation: 'Alphabetic',
        fieldId: 'vehicleJobType',
        id: 'vehicleJobType',
        loadLookUp: 'vehicleJobTypes',
        valueId: 'id',
        displayId: 'description',
        validationType: 'string',
        valueasId: true,
        validations: [
          {
            type: 'optional',
            params: [],
            nullable: true,
          },
        ],
      },
      {
        name: 'Job Window',
        label: 'Job Window',
        type: 'select',
        validation: 'Alphabetic',
        fieldId: 'jobWindow',
        id: 'jobWindow',
        loadLookUp: 'jobWindows',
        valueId: 'jobWindow',
        displayId: 'description',
        validationType: 'string',
        valueasId: true,
        validations: [
          {
            type: 'optional',
            params: [],
            nullable: true,
          },
        ],
      },
    ],
  },
  {
    name: 'Container Info',
    fields: [
      {
        name: 'Dimension Unit',
        label: 'Dimension Unit',
        type: 'select',
        validation: 'Alphabetic',
        fieldId: 'dimentionUnit',
        id: 'dimentionUnit',
        valueId: 'id',
        displayId: 'description',
        loadLookUp: 'dimensionUnits',
        validationType: 'string',
        valueasId: true,
        validations: [
          {
            type: 'optional',
            params: [],
            nullable: true,
          },
        ],
        options: [],
      },
      {
        name: 'From Range',
        label: 'From Range',
        type: 'number',
        validation: 'Alphabetic',
        fieldId: 'fromRange',
        id: 'fromRange',
        valueId: 'fromRange',
        validationType: 'number',
        validations: [
          {
            type: 'optional',
            params: [],
            nullable: true,
          },
        ],
      },
      {
        name: 'Handling',
        label: 'Handling',
        type: 'select',
        validation: 'Alphabetic',
        fieldId: 'handling',
        id: 'handling',
        valueId: 'id',
        displayId: 'description',
        loadLookUp: 'distinctConducts',
        validationType: 'string',
        valueasId: true,
        validations: [
          {
            type: 'optional',
            params: [],
            nullable: true,
          },
        ],
        options: [],
      },
      {
        name: 'To Range',
        label: 'To Range',
        type: 'number',
        validation: 'Alphabetic',
        fieldId: 'toRange',
        id: 'toRange',
        valueId: 'toRange',
        validationType: 'number',
        validations: [
          {
            type: 'optional',
            params: [],
            nullable: true,
          },
        ],
      },
      {
        name: 'Type Size',
        label: 'Type Size',
        type: 'select',
        validation: 'Alphabetic',
        fieldId: 'typeSize',
        id: 'typeSize',
        loadLookUp: 'equipmentSizes',
        valueId: 'size',
        displayId: 'description',
        validationType: 'string',
        valueasId: true,
        validations: [
          {
            type: 'optional',
            params: [],
            nullable: true,
          },
        ],
      },
    ],
  },
  {
    name: 'Route Details',
    fields: [
      {
        name: 'Deliver To',
        label: 'Deliver To',
        type: 'select',
        validation: 'Alphabetic',
        fieldId: 'deliverTo',
        id: 'deliverTo',
        valueId: 'zoneCode',
        displayId: 'zoneCode',
        loadLookUp: 'zoneCodes',
        validationType: 'string',
        valueasId: true,
        validations: [
          {
            type: 'optional',
            params: [],
            nullable: true,
          },
        ],
        options: [],
      },
      {
        name: 'Empty Dehire',
        label: 'Empty Dehire',
        type: 'select',
        validation: 'Alphabetic',
        fieldId: 'mtDehire',
        id: 'mtDehire',
        valueId: 'code',
        displayId: 'description',
        loadLookUp: 'mtdehires',
        validationType: 'string',
        valueasId: true,
        validations: [
          {
            type: 'optional',
            params: [],
            nullable: true,
          },
        ],
        options: [],
      },
      {
        name: 'Pickup From',
        label: 'Pickup From',
        type: 'select',
        validation: 'Alphabetic',
        fieldId: 'pickupFrom',
        id: 'pickupFrom',
        valueId: 'zoneCode',
        displayId: 'zoneCode',
        loadLookUp: 'zoneCodes',
        validationType: 'string',
        validations: [
          {
            type: 'optional',
            params: [],
            nullable: true,
          },
        ],
        options: [],
      },
      {
        name: 'Remarks',
        label: 'Remarks',
        type: 'textarea',
        validation: 'Alphabetic',
        fieldId: 'remarks',
        id: 'remarks',
        valueId: 'remarks',
        validationType: 'string',
        validations: [
          {
            type: 'optional',
            params: [],
            nullable: true,
          },
        ],
      },
      {
        name: 'Stage Movement Type',
        label: 'Stage Movement Type',
        type: 'select',
        validation: 'Alphabetic',
        fieldId: 'stageMovementType',
        id: 'stageMovementType',
        valueId: 'transportStage',
        displayId: 'description',
        loadLookUp: 'stageMovementTypes',
        validationType: 'string',
        valueasId: true,
        validations: [
          {
            type: 'optional',
            params: [],
            nullable: true,
          },
        ],
      },
      {
        name: 'Wharf',
        label: 'Wharf',
        type: 'select',
        validation: 'Alphabetic',
        fieldId: 'wharf',
        id: 'wharf',
        valueId: 'wharfCode',
        displayId: 'description',
        loadLookUp: 'wharfCodes',
        validationType: 'string',
        valueasId: true,
        validations: [
          {
            type: 'optional',
            params: [],
            nullable: true,
          },
        ],
      },
    ],
  },
  {
    name: 'Rate Details',
    fields: [
      {
        name: 'Rate',
        label: 'Rate',
        type: 'select',
        validation: 'Alphabetic',
        fieldId: 'rateType',
        id: 'rate',
        valueId: 'rate',
        loadLookUp: 'rateTypeEnums',
        validationType: 'string',
        valueasId: true,
        validations: [
          {
            type: 'optional',
            params: [],
            nullable: true,
          },
        ],
        options: [],
      },
      {
        name: 'Rate Code',
        label: 'Rate Code',
        type: 'select',
        validation: 'Alphabetic',
        fieldId: 'rateCode',
        id: 'rateCode',
        valueId: 'rateCode',
        displayId: 'description',
        loadLookUp: 'rateCodes',
        validationType: 'string',
        valueasId: true,
        validations: [
          {
            type: 'optional',
            params: [],
            nullable: true,
          },
        ],
        options: [],
      },
      {
        name: 'Description',
        label: 'Description',
        type: 'textarea',
        validation: 'Alphabetic',
        fieldId: 'description',
        id: 'description',
        valueId: 'description',
        validationType: 'string',
        validations: [
          {
            type: 'optional',
            params: [],
            nullable: true,
          },
        ],
        options: [],
      },
      {
        name: 'Sell Rate',
        label: 'Sell Rate',
        type: 'number',
        validation: 'Alphabetic',
        fieldId: 'sellRate',
        id: 'sellRate',
        valueId: 'sellRate',
        validationType: 'number',
        validations: [
          {
            type: 'optional',
            params: [],
            nullable: true,
          },
        ],
      },
      {
        name: 'Consignee',
        label: 'Consignee',
        type: 'select',
        validation: 'Alphabetic',
        fieldId: 'consignee',
        id: 'consignee',
        valueId: 'consigneeId',
        displayId: 'consigneeName',
        loadLookUp: 'consignee',
        validationType: 'string',
        valueasId: true,
        validations: [
          {
            type: 'optional',
            params: [],
            nullable: true,
          },
        ],
        options: [],
      },
    ],
  },
]

export default formData
