import axios from 'axios'
import React, { useState } from 'react'
import Tab from 'react-bootstrap/Tab'
import Tabs from 'react-bootstrap/Tabs'
import { useForm } from 'react-hook-form'
import { toast } from 'react-toastify'
import config from '../../../config'
import Spinner from '../../Spinner/Spinner'
import { dropDownKey } from '../constant'
import './AdvancedSearch.scss'
// import Table from '../Table/Table'
import { useAdvanceSearchDropDownOptions } from '../../../helper/hooks/apiHooks'
import useModalContainer from '../../../helper/hooks/useModalContainer'
import unplannedJobTableColumns from '../../../modules/clm/loads-planning/data/UnplannedJobTableColumns'
import Table from '../../Common/Tanstack-table/Table'
import { loadsTableColumns } from '../FormData/LoadsPlanning'

const AdvancedSearch = () => {
  const [loading, setLoading] = useState(false)
  const [loadSearchData, setLoadSearchData] = useState([])
  const [jobSearchData, setJobSearchData] = useState([])
  const [activeTab, setActiveTab] = useState('load-search')

  const { handleSubmit, register } = useForm({
    mode: 'onSubmit',
  })

  const { data: advanceSearchDropDownOptionsQueryData } =
    useAdvanceSearchDropDownOptions()

  const dropdownValue = advanceSearchDropDownOptionsQueryData?.data

  const searchAdvancedLoadFields = (formData) => {
    const url = `${config.api.baseUrl}${config.api.loadSearch}`
    axios({
      method: 'POST',
      url: url,
      headers: {
        Authorization: 'Bearer ' + localStorage.token,
      },
      data: formData,
    })
      .then(function (response) {
        setLoadSearchData(response.data.data)
      })
      .catch(function () {
        toast.error('Something went wrong!')
      })
      .finally(() => {
        setLoading(false)
      })
  }

  const searchAdvancedJobFields = (formData) => {
    const url = `${config.api.baseUrl}${config.api.jobSearch}`
    axios({
      method: 'POST',
      url: url,
      headers: {
        Authorization: 'Bearer ' + localStorage.token,
      },
      data: formData,
    })
      .then(function (response) {
        setJobSearchData(response.data.data)
      })
      .catch(function () {
        toast.error('Something went wrong!')
      })
      .finally(() => {
        setLoading(false)
      })
  }

  const { ModalContainer: LoadSearchDataModal } = useModalContainer({
    header: 'View Load Details',
    body: (
      <div className="row">
        <Table
          showOnlyPagination={true}
          columns={[...loadsTableColumns.toSpliced(0, 1)]}
          data={loadSearchData}
        />
      </div>
    ),
    text: 'Search',
    btnClass: 'btn btn-primary',
    openTriggerBtnProps: { type: 'submit' },
    fullscreen: true,
  })

  const { ModalContainer: JobSearchDataModal } = useModalContainer({
    header: 'View Job Details',
    body: (
      <div className="row">
        <Table
          showOnlyPagination={true}
          columns={[...unplannedJobTableColumns.toSpliced(0, 1)]}
          data={jobSearchData}
        />
      </div>
    ),
    text: 'Search',
    btnClass: 'btn btn-primary ',
    openTriggerBtnProps: { type: 'submit' },
    fullscreen: true,
  })

  return (
    <div className="advanced-search-container-wrapper">
      {loading && <Spinner />}
      <Tabs
        defaultActiveKey={activeTab}
        transition={false}
        onSelect={(key) => setActiveTab(key)}
      >
        <Tab eventKey="load-search" title="Load Search">
          <form
            autoComplete="off"
            onSubmit={handleSubmit(searchAdvancedLoadFields)}
          >
            <div className="row mt-2">
              <div className="col-md-2">
                <label>Filter Loads</label>
                <select
                  {...register('loadStatus')}
                  className="form-select form-control-sm input-field"
                >
                  <option value="">Please Select</option>
                  {dropdownValue?.map(
                    (item) =>
                      item.key === dropDownKey.LOAD_STATUS_ENUM &&
                      item.dropdownValues.map((key) => (
                        <option key={key} value={key}>
                          {key}
                        </option>
                      ))
                  )}
                </select>
              </div>
              <div className="col-md-2">
                <label>Channels</label>
                <select
                  {...register('channelName')}
                  className="form-select form-control-sm input-field"
                >
                  <option value="">Please Select</option>
                  {dropdownValue?.map(
                    (item) =>
                      item.key === dropDownKey.VEHICLE_JOB_TYPE &&
                      item.dropdownValues.map((key) => (
                        <option key={key} value={key}>
                          {key}
                        </option>
                      ))
                  )}
                </select>
              </div>
              <div className="col-md-2">
                <label>Wharf Job Type</label>
                <select
                  {...register('wharfJobType')}
                  className="form-select form-control-sm input-field"
                >
                  <option value="">Please Select</option>
                  {dropdownValue?.map(
                    (item) =>
                      item.key === dropDownKey.WHARF_JOB_TYPE &&
                      item.dropdownValues.map((key) => (
                        <option key={key} value={key}>
                          {key}
                        </option>
                      ))
                  )}
                </select>
              </div>
              <div className="col-md-2">
                <label>Shipment Type</label>
                <select
                  {...register('shipmentType')}
                  className="form-select form-control-sm input-field"
                >
                  <option value="">Please Select</option>
                  {dropdownValue?.map(
                    (item) =>
                      item.key === dropDownKey.SHIPMENT_TYPE &&
                      item.dropdownValues.map((key) => (
                        <option key={key} value={key}>
                          {key}
                        </option>
                      ))
                  )}
                </select>
              </div>
              <div className="col-md-2">
                <label>Stage Movt Type</label>
                <select
                  {...register('stageMovementType')}
                  className="form-select form-control-sm input-field"
                >
                  <option value="">Please Select</option>
                  {dropdownValue?.map(
                    (item) =>
                      item.key === dropDownKey.STAGE_MOVEMENT_TYPE &&
                      item.dropdownValues.map((key) => (
                        <option key={key} value={key}>
                          {key}
                        </option>
                      ))
                  )}
                </select>
              </div>
              <div className="col-md-2">
                <label>Load From</label>
                <select
                  {...register('loadFrom')}
                  className="form-select form-control-sm input-field"
                >
                  <option value="">Please Select</option>
                  {dropdownValue?.map(
                    (item) =>
                      item.key === dropDownKey.STAGE_FROM &&
                      item.dropdownValues.map((key) => (
                        <option key={key} value={key}>
                          {key}
                        </option>
                      ))
                  )}
                </select>
              </div>
              <div className="col-md-2">
                <label>Load To</label>
                <select
                  {...register('loadTo')}
                  className="form-select form-control-sm input-field"
                >
                  <option value="">Please Select</option>
                  {dropdownValue?.map(
                    (item) =>
                      item.key === dropDownKey.STAGE_TO &&
                      item.dropdownValues.map((key) => (
                        <option key={key} value={key}>
                          {key}
                        </option>
                      ))
                  )}
                </select>
              </div>
            </div>
            <br />
            <div className="row">
              <div className="col-md-2">{LoadSearchDataModal}</div>
            </div>
            <div className="row mt-3">
              <div className="col-md-6">
                <table className="table">
                  <tbody>
                    <tr>
                      <td>1</td>
                      <th>Billing Customer Name</th>
                      <td>
                        {' '}
                        <input
                          name="billingCustomerName"
                          type="text"
                          {...register('billingCustomerName')}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>2</td>
                      <th>Consignee Name</th>
                      <td>
                        {' '}
                        <input
                          name="consigneeName"
                          type="text"
                          {...register('consigneeName')}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>3</td>
                      <th>Container No</th>
                      <td>
                        {' '}
                        <input
                          name="containerNo"
                          type="text"
                          {...register('containerNo')}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>4</td>
                      <th>Created By</th>
                      <td>
                        {' '}
                        <input
                          name="createdBy"
                          type="text"
                          {...register('createdBy')}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>5</td>
                      <th>Customer Refrece No</th>
                      <td>
                        {' '}
                        <input
                          name="customerReferenceNo"
                          type="text"
                          {...register('customerReferenceNo')}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>6</td>
                      <th>Delivery Address</th>
                      <td>
                        {' '}
                        <input
                          name="deliveryAddress"
                          type="text"
                          {...register('deliveryAddress')}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>7</td>
                      <th>Discharge Port</th>
                      <td>
                        {' '}
                        <input
                          name="dischargePort"
                          type="text"
                          {...register('dischargePort')}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>8</td>
                      <th>Edit By</th>
                      <td>
                        {' '}
                        <input
                          name="editBy"
                          type="text"
                          {...register('editBy')}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>9</td>
                      <th>MT Dehire Code</th>
                      <td>
                        {' '}
                        <input
                          name="mtDehireCode"
                          type="text"
                          {...register('mtDehireCode')}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>10</td>
                      <th>Freight Forwarder Name</th>
                      <td>
                        {' '}
                        <input
                          name="freightForwarderName"
                          type="text"
                          {...register('freightForwarderName')}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>11</td>
                      <th>House B/L No</th>
                      <td>
                        {' '}
                        <input
                          name="houseBLNo"
                          type="text"
                          {...register('houseBLNo')}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>12</td>
                      <th>Job Date</th>
                      <td>
                        {' '}
                        <input
                          name="jobDate"
                          type="date"
                          {...register('jobDate')}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>13</td>
                      <th>Job No</th>
                      <td>
                        {' '}
                        <input
                          name="jobNo"
                          type="text"
                          {...register('jobNo')}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>14</td>
                      <th>Loading Port</th>
                      <td>
                        {' '}
                        <input
                          name="loadingPort"
                          type="text"
                          {...register('loadingPort')}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>15</td>
                      <th>Loading Port ETD</th>
                      <td>
                        {' '}
                        <input
                          name="loadingPortETD"
                          type="text"
                          {...register('loadingPortETD')}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>16</td>
                      <th>Ocean B/L No</th>
                      <td>
                        {' '}
                        <input
                          name="oceanBLNo"
                          type="text"
                          {...register('oceanBLNo')}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>17</td>
                      <th>Order Date</th>
                      <td>
                        {' '}
                        <input
                          name="orderDate"
                          type="date"
                          {...register('orderDate')}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>18</td>
                      <th>Order No</th>
                      <td>
                        {' '}
                        <input
                          name="orderNo"
                          type="text"
                          {...register('orderNo')}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>19</td>
                      <th>Release No</th>
                      <td>
                        {' '}
                        <input
                          name="releaseNo"
                          type="text"
                          {...register('releaseNo')}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>20</td>
                      <th>Seal No</th>
                      <td>
                        {' '}
                        <input
                          name="sealNo"
                          type="text"
                          {...register('sealNo')}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>21</td>
                      <th>Shipper Name</th>
                      <td>
                        {' '}
                        <input
                          name="shipperName"
                          type="text"
                          {...register('shipperName')}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>22</td>
                      <th>Shipping Agent Name</th>
                      <td>
                        {' '}
                        <input
                          name="shippingAgentName"
                          type="text"
                          {...register('shippingAgentName')}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>23</td>
                      <th>Transhippment ETA</th>
                      <td>
                        {' '}
                        <input
                          name="transhippmentETA"
                          type="text"
                          {...register('transhippmentETA')}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>24</td>
                      <th>Transhippment Port</th>
                      <td>
                        {' '}
                        <input
                          name="transhippmentPort"
                          type="text"
                          {...register('transhippmentPort')}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>25</td>
                      <th>Vessel ID</th>
                      <td>
                        {' '}
                        <input
                          name="vesselID"
                          type="text"
                          {...register('vesselID')}
                        />
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div className="col-md-6">
                <table className="table table-bordered">
                  <tbody>
                    <tr>
                      <td>26</td>
                      <th>Vessel Name</th>
                      <td>
                        {' '}
                        <input
                          name="vesselName"
                          type="text"
                          {...register('vesselName')}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>27</td>
                      <th>Voyage No</th>
                      <td>
                        {' '}
                        <input
                          name="voyageNo"
                          type="text"
                          {...register('voyageNo')}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>28</td>
                      <th>Wharf Code</th>
                      <td>
                        {' '}
                        <input
                          name="wharfCode"
                          type="text"
                          {...register('wharfCode')}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>29</td>
                      <th>Channels Jobs</th>
                      <td>
                        {' '}
                        <input
                          name="channelsJobs"
                          type="text"
                          {...register('channelsJobs')}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>30</td>
                      <th>Load Status</th>
                      <td>
                        {' '}
                        <input
                          name="loadStatus"
                          type="text"
                          {...register('loadStatus1')}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>31</td>
                      <th>Container Order Number</th>
                      <td>
                        {' '}
                        <input
                          name="containerOrderNumber"
                          type="text"
                          {...register('containerOrderNumber')}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>32</td>
                      <th>Truck Number</th>
                      <td>
                        {' '}
                        <input
                          name="truckNumber"
                          type="text"
                          {...register('truckNumber')}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>33</td>
                      <th>Trailer Number</th>
                      <td>
                        {' '}
                        <input
                          name="trailerNumber"
                          type="text"
                          {...register('trailerNumber')}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>34</td>
                      <th>Container Size</th>
                      <td>
                        {' '}
                        <input
                          name="containerSize"
                          type="text"
                          {...register('containerSize')}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>35</td>
                      <th>Cargo Type</th>
                      <td>
                        {' '}
                        <input
                          name="cargoType"
                          type="text"
                          {...register('cargoType')}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>36</td>
                      <th>IMO Code</th>
                      <td>
                        {' '}
                        <input
                          name="imoCode"
                          type="text"
                          {...register('imoCode')}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>37</td>
                      <th>Container Delivery Type</th>
                      <td>
                        {/* <input
                                                name="voyageNo"
                                                type="text"
                                                {...register("voyageNo")}
                                            /> */}
                      </td>
                    </tr>
                    <tr>
                      <td>38</td>
                      <th>Pick-up Location</th>
                      <td>
                        {' '}
                        <input
                          name="pickupLocation"
                          type="text"
                          {...register('pickupLocation')}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>39</td>
                      <th>Pick-up Suburb</th>
                      <td>
                        {' '}
                        <input
                          name="pickupSuburb"
                          type="text"
                          {...register('pickupSuburb')}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>40</td>
                      <th>Delivery Location</th>
                      <td>
                        {' '}
                        <input
                          name="deliveryLocation"
                          type="text"
                          {...register('deliveryLocation')}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>41</td>
                      <th>Delivery Suburb</th>
                      <td>
                        {' '}
                        <input
                          name="deliverySuburb"
                          type="text"
                          {...register('deliverySuburb')}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>42</td>
                      <th>Schedule Delivery Date</th>
                      <td>
                        {' '}
                        <input
                          name="scheduleDeliveryDate"
                          type="date"
                          {...register('scheduleDeliveryDate')}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>43</td>
                      <th>Schedule Pickup Date</th>
                      <td>
                        {' '}
                        <input
                          name="schedulePickupDate"
                          type="date"
                          {...register('schedulePickupDate')}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>44</td>
                      <th>Door Position</th>
                      <td>
                        {' '}
                        <input
                          name="doorPosition"
                          type="text"
                          {...register('doorPosition')}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>45</td>
                      <th>Driver ID</th>
                      <td>
                        {' '}
                        <input
                          name="driverID"
                          type="text"
                          {...register('driverID')}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>46</td>
                      <th>Driver Name</th>
                      <td>
                        {' '}
                        <input
                          name="driverName"
                          type="text"
                          {...register('driverName')}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>47</td>
                      <th>Grade</th>
                      <td>
                        <input
                          name="grade"
                          type="text"
                          {...register('grade')}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>48</td>
                      <th>Distinct Conduct</th>
                      <td>
                        {' '}
                        <input
                          name="distinctConduct"
                          type="text"
                          {...register('distinctConduct')}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>49</td>
                      <th>Hold</th>
                      <td>
                        {' '}
                        <input name="hold" type="text" {...register('hold')} />
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </form>
        </Tab>
        <Tab eventKey="job-search" title="Job Search">
          {activeTab === 'job-search' && (
            <form
              autoComplete="off"
              onSubmit={handleSubmit(searchAdvancedJobFields)}
            >
              <div className="row mt-2">
                <div className="col-md-2">
                  <label>Filter Loads</label>
                  <select
                    {...register('loadStatus')}
                    className="form-select form-control-sm input-field"
                  >
                    <option value="">Please Select</option>
                    {dropdownValue?.map(
                      (item) =>
                        item.key === dropDownKey.LOAD_STATUS_ENUM &&
                        item.dropdownValues.map((key) => (
                          <option key={key} value={key}>
                            {key}
                          </option>
                        ))
                    )}
                  </select>
                </div>
                <div className="col-md-2">
                  <label>Channels</label>
                  <select
                    {...register('channelName')}
                    className="form-select form-control-sm input-field"
                  >
                    <option value="">Please Select</option>
                    {dropdownValue?.map(
                      (item) =>
                        item.key === dropDownKey.VEHICLE_JOB_TYPE &&
                        item.dropdownValues.map((key) => (
                          <option key={key} value={key}>
                            {key}
                          </option>
                        ))
                    )}
                  </select>
                </div>
                <div className="col-md-2">
                  <label>Wharf Job Type</label>
                  <select
                    {...register('wharfJobType')}
                    className="form-select form-control-sm input-field"
                  >
                    <option value="">Please Select</option>
                    {dropdownValue?.map(
                      (item) =>
                        item.key === dropDownKey.WHARF_JOB_TYPE &&
                        item.dropdownValues.map((key) => (
                          <option key={key} value={key}>
                            {key}
                          </option>
                        ))
                    )}
                  </select>
                </div>
                <div className="col-md-2">
                  <label>Shipment Type</label>
                  <select
                    {...register('shipmentType')}
                    className="form-select form-control-sm input-field"
                  >
                    <option value="">Please Select</option>
                    {dropdownValue?.map(
                      (item) =>
                        item.key === dropDownKey.SHIPMENT_TYPE &&
                        item.dropdownValues.map((key) => (
                          <option key={key} value={key}>
                            {key}
                          </option>
                        ))
                    )}
                  </select>
                </div>
                <div className="col-md-2">
                  <label>Stage Movt Type</label>
                  <select
                    {...register('stageMovementType')}
                    className="form-select form-control-sm input-field"
                  >
                    <option value="">Please Select</option>
                    {dropdownValue?.map(
                      (item) =>
                        item.key === dropDownKey.STAGE_MOVEMENT_TYPE &&
                        item.dropdownValues.map((key) => (
                          <option key={key} value={key}>
                            {key}
                          </option>
                        ))
                    )}
                  </select>
                </div>
                <div className="col-md-2">
                  <label>Load From</label>
                  <select
                    {...register('loadFrom')}
                    className="form-select form-control-sm input-field"
                  >
                    <option value="">Please Select</option>
                    {dropdownValue?.map(
                      (item) =>
                        item.key === dropDownKey.STAGE_FROM &&
                        item.dropdownValues.map((key) => (
                          <option key={key} value={key}>
                            {key}
                          </option>
                        ))
                    )}
                  </select>
                </div>
                <div className="col-md-2">
                  <label>Load To</label>
                  <select
                    {...register('loadTo')}
                    className="form-select form-control-sm input-field"
                  >
                    <option value="">Please Select</option>
                    {dropdownValue?.map(
                      (item) =>
                        item.key === dropDownKey.STAGE_TO &&
                        item.dropdownValues.map((key) => (
                          <option key={key} value={key}>
                            {key}
                          </option>
                        ))
                    )}
                  </select>
                </div>
              </div>
              <br />
              <div className="row">
                <div className="col-md-2">{JobSearchDataModal}</div>
              </div>
              <div className="row mt-3">
                <div className="col-md-6">
                  <table className="table table-bordered">
                    <tbody>
                      <tr>
                        <td>1</td>
                        <th>Billing Customer Name</th>
                        <td>
                          {' '}
                          <input
                            name="billingCustomerName"
                            type="text"
                            {...register('billingCustomerName')}
                          />
                        </td>
                      </tr>
                      <tr>
                        <td>2</td>
                        <th>Consignee Name</th>
                        <td>
                          {' '}
                          <input
                            name="consigneeName"
                            type="text"
                            {...register('consigneeName')}
                          />
                        </td>
                      </tr>
                      <tr>
                        <td>3</td>
                        <th>Container No</th>
                        <td>
                          {' '}
                          <input
                            name="containerNo"
                            type="text"
                            {...register('containerNo')}
                          />
                        </td>
                      </tr>
                      <tr>
                        <td>4</td>
                        <th>Created By</th>
                        <td>
                          {' '}
                          <input
                            name="createdBy"
                            type="text"
                            {...register('createdBy')}
                          />
                        </td>
                      </tr>
                      <tr>
                        <td>5</td>
                        <th>Customer Refrece No</th>
                        <td>
                          {' '}
                          <input
                            name="customerReferenceNo"
                            type="text"
                            {...register('customerReferenceNo')}
                          />
                        </td>
                      </tr>
                      <tr>
                        <td>6</td>
                        <th>Delivery Address</th>
                        <td>
                          {' '}
                          <input
                            name="deliveryAddress"
                            type="text"
                            {...register('deliveryAddress')}
                          />
                        </td>
                      </tr>
                      <tr>
                        <td>7</td>
                        <th>Discharge Port</th>
                        <td>
                          {' '}
                          <input
                            name="dischargePort"
                            type="text"
                            {...register('dischargePort')}
                          />
                        </td>
                      </tr>
                      <tr>
                        <td>8</td>
                        <th>Edit By</th>
                        <td>
                          {' '}
                          <input
                            name="editBy"
                            type="text"
                            {...register('editBy')}
                          />
                        </td>
                      </tr>
                      <tr>
                        <td>9</td>
                        <th>MT Dehire Code</th>
                        <td>
                          {' '}
                          <input
                            name="mtDehireCode"
                            type="text"
                            {...register('mtDehireCode')}
                          />
                        </td>
                      </tr>
                      <tr>
                        <td>10</td>
                        <th>Freight Forwarder Name</th>
                        <td>
                          {' '}
                          <input
                            name="freightForwarderName"
                            type="text"
                            {...register('freightForwarderName')}
                          />
                        </td>
                      </tr>
                      <tr>
                        <td>11</td>
                        <th>House B/L No</th>
                        <td>
                          {' '}
                          <input
                            name="houseBLNo"
                            type="text"
                            {...register('houseBLNo')}
                          />
                        </td>
                      </tr>
                      <tr>
                        <td>12</td>
                        <th>Job Date</th>
                        <td>
                          {' '}
                          <input
                            name="jobDate"
                            type="date"
                            {...register('jobDate')}
                          />
                        </td>
                      </tr>
                      <tr>
                        <td>13</td>
                        <th>Job No</th>
                        <td>
                          {' '}
                          <input
                            name="jobNo"
                            type="text"
                            {...register('jobNo')}
                          />
                        </td>
                      </tr>
                      <tr>
                        <td>14</td>
                        <th>Loading Port</th>
                        <td>
                          {' '}
                          <input
                            name="loadingPort"
                            type="text"
                            {...register('loadingPort')}
                          />
                        </td>
                      </tr>
                      <tr>
                        <td>15</td>
                        <th>Loading Port ETD</th>
                        <td>
                          {' '}
                          <input
                            name="loadingPortETD"
                            type="text"
                            {...register('loadingPortETD')}
                          />
                        </td>
                      </tr>
                      <tr>
                        <td>16</td>
                        <th>Ocean B/L No</th>
                        <td>
                          {' '}
                          <input
                            name="oceanBLNo"
                            type="text"
                            {...register('oceanBLNo')}
                          />
                        </td>
                      </tr>
                      <tr>
                        <td>17</td>
                        <th>Order Date</th>
                        <td>
                          {' '}
                          <input
                            name="orderDate"
                            type="date"
                            {...register('orderDate')}
                          />
                        </td>
                      </tr>
                      <tr>
                        <td>18</td>
                        <th>Order No</th>
                        <td>
                          {' '}
                          <input
                            name="orderNo"
                            type="text"
                            {...register('orderNo')}
                          />
                        </td>
                      </tr>
                      <tr>
                        <td>19</td>
                        <th>Release No</th>
                        <td>
                          {' '}
                          <input
                            name="releaseNo"
                            type="text"
                            {...register('releaseNo')}
                          />
                        </td>
                      </tr>
                      <tr>
                        <td>20</td>
                        <th>Seal No</th>
                        <td>
                          {' '}
                          <input
                            name="sealNo"
                            type="text"
                            {...register('sealNo')}
                          />
                        </td>
                      </tr>
                      <tr>
                        <td>21</td>
                        <th>Shipper Name</th>
                        <td>
                          {' '}
                          <input
                            name="shipperName"
                            type="text"
                            {...register('shipperName')}
                          />
                        </td>
                      </tr>
                      <tr>
                        <td>22</td>
                        <th>Shipping Agent Name</th>
                        <td>
                          {' '}
                          <input
                            name="shippingAgentName"
                            type="text"
                            {...register('shippingAgentName')}
                          />
                        </td>
                      </tr>
                      <tr>
                        <td>23</td>
                        <th>Transhippment ETA</th>
                        <td>
                          {' '}
                          <input
                            name="transhippmentETA"
                            type="text"
                            {...register('transhippmentETA')}
                          />
                        </td>
                      </tr>
                      <tr>
                        <td>24</td>
                        <th>Transhippment Port</th>
                        <td>
                          {' '}
                          <input
                            name="transhippmentPort"
                            type="text"
                            {...register('transhippmentPort')}
                          />
                        </td>
                      </tr>
                      <tr>
                        <td>25</td>
                        <th>Vessel ID</th>
                        <td>
                          {' '}
                          <input
                            name="vesselID"
                            type="text"
                            {...register('vesselID')}
                          />
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div className="col-md-6">
                  <table className="table table-bordered">
                    <tbody>
                      <tr>
                        <td>26</td>
                        <th>Vessel Name</th>
                        <td>
                          {' '}
                          <input
                            name="vesselName"
                            type="text"
                            {...register('vesselName')}
                          />
                        </td>
                      </tr>
                      <tr>
                        <td>27</td>
                        <th>Voyage No</th>
                        <td>
                          {' '}
                          <input
                            name="voyageNo"
                            type="text"
                            {...register('voyageNo')}
                          />
                        </td>
                      </tr>
                      <tr>
                        <td>28</td>
                        <th>Wharf Code</th>
                        <td>
                          {' '}
                          <input
                            name="wharfCode"
                            type="text"
                            {...register('wharfCode')}
                          />
                        </td>
                      </tr>
                      <tr>
                        <td>29</td>
                        <th>Channels Jobs</th>
                        <td>
                          {' '}
                          <input
                            name="channelsJobs"
                            type="text"
                            {...register('channelsJobs')}
                          />
                        </td>
                      </tr>
                      <tr>
                        <td>30</td>
                        <th>Load Status</th>
                        <td>
                          {' '}
                          <input
                            name="loadStatus"
                            type="text"
                            {...register('loadStatus1')}
                          />
                        </td>
                      </tr>
                      <tr>
                        <td>31</td>
                        <th>Container Order Number</th>
                        <td>
                          {' '}
                          <input
                            name="containerOrderNumber"
                            type="text"
                            {...register('containerOrderNumber')}
                          />
                        </td>
                      </tr>
                      <tr>
                        <td>32</td>
                        <th>Truck Number</th>
                        <td>
                          {' '}
                          <input
                            name="truckNumber"
                            type="text"
                            {...register('truckNumber')}
                          />
                        </td>
                      </tr>
                      <tr>
                        <td>33</td>
                        <th>Trailer Number</th>
                        <td>
                          {' '}
                          <input
                            name="trailerNumber"
                            type="text"
                            {...register('trailerNumber')}
                          />
                        </td>
                      </tr>
                      <tr>
                        <td>34</td>
                        <th>Container Size</th>
                        <td>
                          {' '}
                          <input
                            name="containerSize"
                            type="text"
                            {...register('containerSize')}
                          />
                        </td>
                      </tr>
                      <tr>
                        <td>35</td>
                        <th>Cargo Type</th>
                        <td>
                          {' '}
                          <input
                            name="cargoType"
                            type="text"
                            {...register('cargoType')}
                          />
                        </td>
                      </tr>
                      <tr>
                        <td>36</td>
                        <th>IMO Code</th>
                        <td>
                          {' '}
                          <input
                            name="imoCode"
                            type="text"
                            {...register('imoCode')}
                          />
                        </td>
                      </tr>
                      <tr>
                        <td>37</td>
                        <th>Container Delivery Type</th>
                        <td>
                          {/* <input
                                                name="voyageNo"
                                                type="text"
                                                {...register("voyageNo")}
                                            /> */}
                        </td>
                      </tr>
                      <tr>
                        <td>38</td>
                        <th>Pick-up Location</th>
                        <td>
                          {' '}
                          <input
                            name="pickupLocation"
                            type="text"
                            {...register('pickupLocation')}
                          />
                        </td>
                      </tr>
                      <tr>
                        <td>39</td>
                        <th>Pick-up Suburb</th>
                        <td>
                          {' '}
                          <input
                            name="pickupSuburb"
                            type="text"
                            {...register('pickupSuburb')}
                          />
                        </td>
                      </tr>
                      <tr>
                        <td>40</td>
                        <th>Delivery Location</th>
                        <td>
                          {' '}
                          <input
                            name="deliveryLocation"
                            type="text"
                            {...register('deliveryLocation')}
                          />
                        </td>
                      </tr>
                      <tr>
                        <td>41</td>
                        <th>Delivery Suburb</th>
                        <td>
                          {' '}
                          <input
                            name="deliverySuburb"
                            type="text"
                            {...register('deliverySuburb')}
                          />
                        </td>
                      </tr>
                      <tr>
                        <td>42</td>
                        <th>Schedule Delivery Date</th>
                        <td>
                          {' '}
                          <input
                            name="scheduleDeliveryDate"
                            type="date"
                            {...register('scheduleDeliveryDate')}
                          />
                        </td>
                      </tr>
                      <tr>
                        <td>43</td>
                        <th>Schedule Pickup Date</th>
                        <td>
                          {' '}
                          <input
                            name="schedulePickupDate"
                            type="date"
                            {...register('schedulePickupDate')}
                          />
                        </td>
                      </tr>
                      <tr>
                        <td>44</td>
                        <th>Door Position</th>
                        <td>
                          {' '}
                          <input
                            name="doorPosition"
                            type="text"
                            {...register('doorPosition')}
                          />
                        </td>
                      </tr>
                      <tr>
                        <td>45</td>
                        <th>Driver ID</th>
                        <td>
                          {' '}
                          <input
                            name="driverID"
                            type="text"
                            {...register('driverID')}
                          />
                        </td>
                      </tr>
                      <tr>
                        <td>46</td>
                        <th>Driver Name</th>
                        <td>
                          {' '}
                          <input
                            name="driverName"
                            type="text"
                            {...register('driverName')}
                          />
                        </td>
                      </tr>
                      <tr>
                        <td>47</td>
                        <th>Grade</th>
                        <td>
                          <input
                            name="grade"
                            type="text"
                            {...register('grade')}
                          />
                        </td>
                      </tr>
                      <tr>
                        <td>48</td>
                        <th>Distinct Conduct</th>
                        <td>
                          {' '}
                          <input
                            name="distinctConduct"
                            type="text"
                            {...register('distinctConduct')}
                          />
                        </td>
                      </tr>
                      <tr>
                        <td>49</td>
                        <th>Hold</th>
                        <td>
                          {' '}
                          <input
                            name="hold"
                            type="text"
                            {...register('hold')}
                          />
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </form>
          )}
        </Tab>
      </Tabs>
    </div>
  )
}

export default AdvancedSearch
