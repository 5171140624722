export default function formatDate(interval) {
  const currentDate = new Date()

  switch (interval) {
    case 'weekBefore':
      currentDate.setDate(currentDate.getDate() - 7)
      break
    case 'monthBefore':
      currentDate.setMonth(currentDate.getMonth() - 1)
      break
    case 'sixMonthsBefore':
      currentDate.setMonth(currentDate.getMonth() - 6)
      break
    case 'yearBefore':
      currentDate.setFullYear(currentDate.getFullYear() - 1)
      break
    default:
      // If an invalid interval is provided, return the current date
      break
  }

  const year = currentDate.getFullYear()
  const month = String(currentDate.getMonth() + 1).padStart(2, '0')
  const day = String(currentDate.getDate()).padStart(2, '0')
  return `${year}-${month}-${day}`
}
