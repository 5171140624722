import React from 'react'
const PackedList = () => {
  return (
    <div className="w-100 overflow-auto">
      <div className="header">
        <h6>Packed List</h6>
      </div>
    </div>
  )
}
export default PackedList
