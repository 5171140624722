import { yupResolver } from '@hookform/resolvers/yup'
import axios from 'axios'
import { format, isDate, parse } from 'date-fns'
import React from 'react'
import { Col, Form, Row } from 'react-bootstrap'
import { useForm } from 'react-hook-form'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import * as Yup from 'yup'
import config from '../../config'
import ContactDetails from '../../modules/cerebro/customer/customerDetails/components/ContactDetails'
import { getLookupDataState } from '../../store/slices/lookup-slice'
import Input from '../Common/Form/Fields/Input'
import SearchableSelectField from '../Common/Form/Fields/SearchableSelectField'
import Select from '../Common/Form/Fields/Select'
import Switch from '../Common/Form/Fields/Switch'
import ContainerWithHeading from '../styles/Containers/ContainerWithHeading'
import toasts, { Success } from '../utils/Toastify'
import LocationSearchInput from './LocationSearchInput'
import RequiredSymbol from '../Common/Form/RequiredSymbol'

function parseDateString(value, originalValue) {
  if (originalValue === '') return null
  return isDate(originalValue)
    ? originalValue
    : parse(originalValue, 'yyyy-MM-dd', new Date())
}

const today = new Date()
const phoneRegExp =
  /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/

const validationSchema = Yup.object().shape({
  customerName: Yup.string()
    .max(200, "name can't be more 200 char")
    .required('Customer Name is required'),
  // abn: Yup.string().required('ABN Id is required'),
  address: Yup.string().required('Please enter a valid address'),
  // currencyCodeId: Yup.string().required('currency  is required'),
  suburb: Yup.string().required('suburb  is required'),
  countryCodeId: Yup.string().required('Country  is required'),
  stateCodeId: Yup.string().required('State is required'),
  postCodeId: Yup.string().required('postcode is required'),
  potentialCustomer: Yup.boolean(),
  isManual: Yup.boolean(),
  customerSince: Yup.date()
    .required('Customer Since is required')
    .typeError('Customer Since is required')
    .transform(parseDateString)
    .max(today),
  notes: Yup.string().nullable().optional().max(500),

  operationContactDetails: Yup.array().of(
    Yup.object().shape({
      email: Yup.string()
        .required('Email is required')
        .email('Email is invalid'),
      name: Yup.string().required('name is required'),
      mobile: Yup.string()
        .nullable()
        .required('Phone number is required')
        .matches(phoneRegExp, 'Phone number is not valid')
        .max(10),
      telephone: Yup.string()
        .nullable()
        .required('Telephone number is required')
        .matches(phoneRegExp, 'Telephone number is not valid')
        .max(20),
    })
  ),

  accountContactDetails: Yup.array().of(
    Yup.object().shape({
      email: Yup.string()
        .required('Email is required')
        .email('Email is invalid'),
      name: Yup.string().required('name is required'),
      mobile: Yup.string()
        .nullable()
        .required('Phone number is required')
        .matches(phoneRegExp, 'Phone number is not valid')
        .max(10),
      telephone: Yup.string()
        .nullable()
        .required('Telephone number is required')
        .matches(phoneRegExp, 'Telephone number is not valid')
        .max(20),
    })
  ),
})

const CustomerInitialData = ({ customerData, type, customerCardId }) => {
  let history = useHistory()
  const lookupsList = useSelector(getLookupDataState)

  const form = useForm({
    resolver: yupResolver(validationSchema),
    reValidateMode: 'onChange',
    defaultValues: customerData,
    values: customerData,
  })

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    clearErrors,
    watch,
    setError,
    control,
  } = form

  const isManualEntry = watch('isManual')

  const onSubmitFunction = (formData) => {
    let options = {
      ...formData,
    }

    options.customerCardId = ''
    options.customerCodeConfirmation = '' //
    options.customerSince = format(options.customerSince, 'yyyy-MM-dd')

    let updatedUrl = config.api.customerCard
    if (customerCardId) {
      updatedUrl = updatedUrl + '/' + customerCardId
    }
    const url = config.api.baseUrl + updatedUrl

    axios({
      method: customerCardId ? 'PUT' : 'POST', //you can set what request you want to be
      url: url,
      data: options,
      headers: {
        Authorization: 'Bearer ' + localStorage.token,
      },
    })
      .then((response) => {
        if (customerCardId) Success('Customer details updated successfully.')
        else Success('Customer created successfully.')
        history.push(`/Customer/edit/${response.data.customerCardId}`)
      })
      .catch((error) => {
        if (Array.isArray(error?.response?.data?.data)) {
          error?.response?.data?.data.forEach(({ message, key }) => {
            if (typeof key === 'string' && key.includes(' ')) {
              toasts.Error(key)
            } else setError(key, { type: 'custom', message })
          })
        }
      })
  }

  const getAddressDetails = (address) => {
    setValue('address', address.address)
    setValue('stateCodeId', address.stateCodeId)
    setValue('countryCodeId', address.countryCodeId)
    clearErrors(['address', 'stateCodeId', 'countryCodeId'])
  }

  const modeIsView = type === 'view'

  return (
    <form autoComplete="off" onSubmit={handleSubmit(onSubmitFunction)}>
      <div className="row">
        <div className="card-body col-md-12">
          <ContainerWithHeading
            heading="Customer Details"
            containerClassNames="px-3"
          >
            <Row>
              <Col md={6}>
                <Row>
                  {/* Customer Name */}
                  <Input
                    name="customerName"
                    md={12}
                    register={register}
                    errors={errors}
                    required
                  />
                  <Input
                    name="customerCodeConfirmation"
                    disabled
                    md={12}
                    register={register}
                    errors={errors}
                  />
                  <Input
                    name="customerId"
                    disabled
                    md={12}
                    register={register}
                    errors={errors}
                  />
                  {/* <Input
                    name="abn"
                    // disabled
                    md={12}
                    register={register}
                    errors={errors}
                  />
                  <Select
                    name="currencyCodeId"
                    register={register}
                    errors={errors}
                    options={lookupsList?.currencyCodes}
                    optionValueKey="currencyCode"
                    setOptionLabel={(o) =>
                      `${o?.description} - ${o?.currencyCode}`
                    }
                    md={12}
                    disabled={modeIsView}
                  /> */}

                  <Switch
                    register={register}
                    errors={errors}
                    name="isManual"
                    md={12}
                  />
                </Row>
              </Col>

              <Col md={6}>
                <Row>
                  {!isManualEntry && (
                    <Form.Group
                      className="mb-3"
                      as={Col}
                      md={12}
                      controlId="address"
                    >
                      <Form.Label>
                        Search Location <RequiredSymbol />
                      </Form.Label>
                      {type !== 'view' && (
                        <LocationSearchInput
                          onSelectAddress={getAddressDetails}
                          id="address"
                          errors={errors}
                          register={register}
                          setValue={setValue}
                        />
                      )}
                    </Form.Group>
                  )}

                  <Input
                    name="address"
                    register={register}
                    errors={errors}
                    disabled={!isManualEntry || modeIsView}
                    md={12}
                    required
                  />

                  <Select
                    options={lookupsList?.stateCodes}
                    optionValueKey="stateCode"
                    setOptionLabel={(o) => `${o.description} - ${o.stateCode}`}
                    register={register}
                    errors={errors}
                    name="stateCodeId"
                    md={12}
                    required
                    disabled={!isManualEntry || modeIsView}
                  />

                  <Select
                    options={lookupsList?.countryCodes}
                    optionValueKey="countryCode"
                    register={register}
                    errors={errors}
                    name="countryCodeId"
                    md={12}
                    required
                    disabled={!isManualEntry || modeIsView}
                  />
                  <SearchableSelectField
                    options={lookupsList?.postCodes}
                    optionValueKey="postCode"
                    optionLabelKey="postCode"
                    name="postCodeId"
                    control={control}
                    setValue={setValue}
                    getOptionLabel={(o) => `${o.postCode} - ${o.citySuburb}`}
                    required
                    md={12}
                    onChange={(val) => {
                      clearErrors(['postCodeId', 'suburb'])
                      setValue('postCodeId', val?.postCode || null)
                      setValue('suburb', val?.citySuburb || null)
                    }}
                  />
                  <Input
                    disabled
                    name="suburb"
                    md={12}
                    register={register}
                    errors={errors}
                    required
                  />

                  <Input
                    register={register}
                    errors={errors}
                    name="customerSince"
                    type="date"
                    md={12}
                    required
                  />
                  <Switch
                    register={register}
                    errors={errors}
                    name="potentialCustomer"
                    md={12}
                  />
                </Row>
              </Col>
            </Row>
          </ContainerWithHeading>
        </div>

        <div className="card-body col-md-12">
          <ContactDetails form={form} type={type} />
        </div>

        <div className="card-body col-md-12">
          <ContainerWithHeading containerClassNames="p-3 pt-4" heading="Notes">
            <Input
              register={register}
              errors={errors}
              name="notes"
              as="textarea"
              md={12}
              style={{ height: '6rem' }}
            />
          </ContainerWithHeading>
        </div>

        <div className="col-12 text-end">
          {!modeIsView && (
            <button
              type="submit"
              className="btn btn-primary"
              style={{ borderRadius: '5px' }}
            >
              {customerCardId ? 'Update Customer' : 'Create Customer'}
            </button>
          )}
        </div>
      </div>
    </form>
  )
}

export default CustomerInitialData
