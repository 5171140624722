import { truckOutgoing, truckIncoming, dashboard, gateIncoming, gateOutgoing } from "../modules/yms"
const ymsRoutes = [

    {
        name: 'dashboard',
        path: '/yms-dashboard',
        component: dashboard,
        includeHeaderFooter: true,
        showSideBarOf: "yms-dashboard",
        headerFooterOf: 'yms-dashboard'
    },
    {
        name: 'packing-list',
        path: '/yms-gate-incoming',
        component: gateIncoming,
        includeHeaderFooter: true,
        showSideBarOf: "yms-dashboard",
        headerFooterOf: 'yms-dashboard'
    },
    {
        name: 'unpacking-list',
        path: '/yms-gate-outgoing',
        component: gateOutgoing,
        includeHeaderFooter: true,
        showSideBarOf: "yms-dashboard",
        headerFooterOf: 'yms-dashboard'
    },
    {
        name: 'reports',
        path: '/yms-truck-incoming',
        component: truckIncoming,
        includeHeaderFooter: true,
        showSideBarOf: "yms-dashboard",
        headerFooterOf: 'yms-dashboard'
    },
    {
        name: 'reports',
        path: '/yms-truck-outgoing',
        component: truckOutgoing,
        includeHeaderFooter: true,
        showSideBarOf: "yms-dashboard",
        headerFooterOf: 'yms-dashboard'
    },
]
export default ymsRoutes