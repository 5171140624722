export const calculatePercentage = (driversData, recordKey) => {
  if (!Array.isArray(driversData?.data) || driversData?.data.length === 0)
    return ''

  const driversList = driversData.data

  // Filter drivers based on the provided recordKey
  const filteredDrivers = driversList.filter((driver) => driver?.[recordKey])

  // Calculate the percentage
  const percentage = (filteredDrivers.length / driversList.length) * 100

  return percentage.toFixed(2)
}

// Usage example for criminal records
export const calCriminalRecords = (driversData) => {
  return calculatePercentage(driversData, 'criminalRecord')
}

// Usage example for driving disqualifications
export const calDisqualificationsPer = (driversData) => {
  return calculatePercentage(driversData, 'drivingDisqualification')
}
