const cargoType = {
  name: 'Cargo Type',
  apiUrlAppend: false,
  path: '/dataMgmt/customer/cargoType',
  req: '/',
  columns: 4,
  fields: [
    {
      name: 'Cargo Type',
      label: 'Cargo Type',
      type: 'text',
      validation: 'Alphabetic',
      fieldId: 'cargoType',
      id: 'cargoType',
      validationType: 'string',
      validations: [
        {
          type: 'required',
          params: ['ID is required'],
        },
        {
          type: 'max',
          params: [30, 'ID cannot be more than 30 characters'],
        },
      ],
    },
    {
      name: 'Description',
      label: 'Description',
      type: 'text',
      validation: 'Alphabetic',
      fieldId: 'description',
      id: 'description',
      validationType: 'string',
      validations: [
        {
          type: 'required',
          params: ['Description is required'],
        },
        {
          type: 'max',
          params: [50, 'Description cannot be more than 50 characters'],
        },
      ],
    },
    {
      name: 'AQIS Control',
      label: 'AQIS Control',
      type: 'checkbox',
      validation: 'Alphabetic',
      fieldId: 'aqisControl',
      id: 'aqisControl',
      validationType: 'boolean',
    },
    {
      name: 'DangerousGoods',
      label: 'Dangerous Goods',
      type: 'checkbox',
      validation: 'Alphabetic',
      fieldId: 'dangerousGoods',
      id: 'dangerousGoods',
      validationType: 'boolean',
    },
    {
      name: 'TemperatureCtrl',
      label: 'Temperature Control',
      type: 'checkbox',
      validation: 'Alphabetic',
      fieldId: 'temperatureControl',
      id: 'temperatureControl',
      validationType: 'boolean',
    },
    {
      name: 'ReeferMonReq',
      label: 'Reefer Monitoring Required',
      type: 'checkbox',
      validation: 'Alphabetic',
      fieldId: 'reeferMonitoringRequired',
      id: 'reeferMonitoringRequired',
      validationType: 'boolean',
    },
  ],
}
export default cargoType
