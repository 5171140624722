import React, { useEffect, useState } from 'react'
import '../clmStyle.scss'
import { toast } from 'react-toastify'
import Table from '../../Common/Table/Table'
import { FORKLIFT, TRAILER, TRUCK } from '../FormData/Vehicle'
import Tabs from 'react-bootstrap/Tabs'
import Tab from 'react-bootstrap/Tab'
import { useRouter } from '../../../helper/hooks/useRouter'
import FilteredTable from '../Table/FilteredTable'
import {
  fetchTrucks,
  getVehicleByStatus,
  assignVehicleToDriver,
} from '../clm-services'
import useLoading from '../hooks/useLoading'
import { Error as Err, Success } from '../../utils/Toastify'

const TrucksDashboard = () => {
  const router = useRouter()
  const { setLoading } = useLoading()
  const { history } = useRouter()
  const [show, setShow] = useState(false)
  const [vehicleTruckId, setVehicleTruckId] = useState()
  const [vehicleList, setVehicleList] = useState({
    FORKLIFT: [],
    TRAILER: [],
    TRUCK: [],
  })
  const [truckList, setTruckList] = useState({
    TRUCK: [],
  })
  const currentActiveTab = router.query['vehicleType']
  const status = router.query['status']
  const driverCardId = router.query['driverCardId']

  const fetchTrucksList = () => {
    try {
      setLoading(true)
      fetchTrucks().then((response) => {
        setVehicleList(response)
      })
    } catch (err) {
      toast.error('Something went wrong!')
    } finally {
      setLoading(false)
    }
  }

  const getVehicleTrucksByStatus = async () => {
    try {
      setLoading(true)
      getVehicleByStatus().then((response) => {
        setTruckList(response)
      })
    } catch (err) {
      toast.error('Something went wrong!')
    } finally {
      setLoading(false)
    }
  }

  const assignVehicleTruckToDriver = async () => {
    try {
      setLoading(true)
      await assignVehicleToDriver(driverCardId, vehicleTruckId)
      Success('Vehicle Assigned successfully.')
      history.push('/loads-planning')
    } catch (err) {
      Err(err?.data[0]?.message)
    } finally {
      setLoading(false)
      setShow(false)
    }
  }

  const handleSetSelection = (data) => {
    if (data.length > 0) {
      setShow(true)
    }
    data.forEach((fields) => {
      setVehicleTruckId(fields.vehicleTruckId)
    })
  }

  useEffect(() => {
    fetchTrucksList()
    if (status) {
      getVehicleTrucksByStatus()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status])

  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <div className="">
            <Tabs
              defaultActiveKey={currentActiveTab ? 'TRUCK' : 'FORKLIFT'}
              transition={false}
              className="mb-3"
            >
              <Tab eventKey="TRUCK" title="Truck">
                <div className="col-md-12 column">
                  <div className="columnData table-responsive customiseTruckTable">
                    <FilteredTable
                      data={
                        status
                          ? truckList.TRUCK?.map((rows) => ({
                              ...rows,
                            }))
                          : vehicleList.TRUCK?.map((rowsData) => ({
                              ...rowsData,
                            }))
                      }
                      setSelection={(selection) =>
                        handleSetSelection(selection)
                      }
                      columns={[...TRUCK]}
                    />
                  </div>
                  {show && (
                    <div
                      className={`modal fade ${show ? ' show' : ''}`}
                      tabIndex="-1"
                      role="dialog"
                      aria-labelledby="editDataModalLabel"
                      aria-hidden="true"
                      style={{
                        display: `${show ? 'block' : 'none'}`,
                      }}
                    >
                      <div className="modal-dialog modal-md modal-content">
                        <div className="modal-header">
                          <h5 className="modal-title" id="editDataModalLabel">
                            Confirmation
                          </h5>

                          <span
                            className="close"
                            data-dismiss="modal"
                            aria-label="Close"
                            onClick={() => setShow(false)}
                          >
                            X
                          </span>
                        </div>
                        <div className="modal-body">
                          <div className="row">
                            <p>Are you sure you want to assign this vehicle?</p>
                          </div>
                        </div>
                        <div className="modal-footer">
                          <button
                            type="button"
                            className="btn btn-primary"
                            onClick={() => setShow(false)}
                            data-dismiss="modal"
                          >
                            No
                          </button>
                          <button
                            type="button"
                            className="btn btn-primary"
                            onClick={() => assignVehicleTruckToDriver()}
                            data-dismiss="modal"
                          >
                            Confirm
                          </button>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </Tab>
              <Tab eventKey="TRAILER" title="Trailer">
                <div className="col-md-12 column">
                  <div className="columnData table-responsive">
                    {vehicleList.TRAILER && (
                      <Table
                        className="table table-hover"
                        data={vehicleList.TRAILER?.map((rowsData) => ({
                          ...rowsData,
                        }))}
                        columns={[...TRAILER]}
                      />
                    )}
                  </div>
                </div>
              </Tab>
              <Tab eventKey="FORKLIFT" title="Fork Lift">
                <div className="col-md-12 column">
                  <div className="columnData table-responsive">
                    {vehicleList.FORKLIFT && (
                      <Table
                        className="table table-hover"
                        data={vehicleList.FORKLIFT?.map((rowsData) => ({
                          ...rowsData,
                        }))}
                        columns={[...FORKLIFT]}
                      />
                    )}
                  </div>
                </div>
              </Tab>
            </Tabs>
          </div>
        </div>
      </div>
    </>
  )
}

export default TrucksDashboard
