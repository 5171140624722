import { yupResolver } from '@hookform/resolvers/yup'
import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import { useForm } from 'react-hook-form'
import * as Yup from 'yup'
import MasterTemplateList from './components/MasterTemplateList'
import TemplateForm from './components/TemplateForm'
import UserTemplatesList from './components/UserTemplatesList'

export const templateFormDefaultValues = {
  // Input Fields
  selectedFields: [],
  templateName: '',
  selectedChannel: '',
  copyFromTemplateId: null,

  // Data Fields
  editingTemplateId: null,
  editingTemplateName: '',
  editingTemplate: [],
}

const validationSchema = Yup.object().shape({
  selectedFields: Yup.array()
    .min(1, 'Please select at least one field')
    .required('Please select fields'),
  templateName: Yup.string().required('Please enter a template name'),
  selectedChannel: Yup.string().required('Please select a channel'),
})

export default function UserTemplates() {
  const form = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: templateFormDefaultValues,
  })

  const editingTemplateId = form.watch('editingTemplateId')
  const editingTemplateName = form.watch('editingTemplateName')

  return (
    <Container fluid className="mb-5">
      <Row>
        <Col md={3}>
          <h3>Master Template</h3>
          <MasterTemplateList form={form} />
        </Col>
        <Col md={5}>
          <h3>
            {editingTemplateId
              ? `Editing Template - ${editingTemplateName}`
              : 'Create New Template'}
          </h3>
          <TemplateForm form={form} />
        </Col>
        <Col md={4} className="ms-auto">
          <h3>User Templates</h3>
          <UserTemplatesList form={form} />
        </Col>
      </Row>
    </Container>
  )
}
