import React from 'react'

export default function IconButton({
  onClick,
  children,
  classes = '',
  ...btnProps
}) {
  return (
    <button
      style={{
        width: '30px',
        height: '30px',
      }}
      type="button"
      className={`btn p-0 border-0 ${classes}`}
      onClick={onClick}
      {...btnProps}
    >
      {children}
    </button>
  )
}
