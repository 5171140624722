import React from 'react'
import { Modal } from 'react-bootstrap'
import UplDetails from './UplDetails'

const UplDetailsModal = ({ setShowUPLDetailsModal, uplDetails, children }) => {
  return (
    <Modal
      show={true}
      onHide={() => setShowUPLDetailsModal(false)}
      size="lg"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title>
          {<div className="fs-3 text-info">UPL Details</div>}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <UplDetails uplDetails={uplDetails} />
        {children}
      </Modal.Body>
    </Modal>
  )
}

export default UplDetailsModal
