import capacityPlannerRoute from './capacityPlanningRoute'
import datMgmtFields from './datMgmtFieldsRoute'
import customerDataMgmt from './data-management/customerDataMgmtRoute'
import { operationsDataMgmt } from './data-management/operationsDataMgmtRouter'
import { transportDataMgmt } from './data-management/transportDataMgmtRouter'
import userDataMgmt from './data-management/userDataMgmtRoute'
import invoiceRoutes from './invoicesRoute'
import routeConfig from './routeConfig'
import vesselRoutes from './vesselsRoutes'
import wmsRoutes from './wmsRoutes'
import ymsRoutes from './ymsRoutes'
import cerebroRoutes from './cerebro/index'

const routeConfigWpr = [
  ...routeConfig,
  ...customerDataMgmt,
  ...userDataMgmt,
  ...operationsDataMgmt,
  ...transportDataMgmt,
  ...wmsRoutes,
  ...invoiceRoutes,
  ...vesselRoutes,
  ...ymsRoutes,
  ...capacityPlannerRoute,
  ...cerebroRoutes,
]

export {
  customerDataMgmt,
  datMgmtFields,
  operationsDataMgmt,
  routeConfigWpr,
  transportDataMgmt,
  userDataMgmt,
}
