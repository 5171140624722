import { useState, useEffect } from 'react'

import ShipperAgreedRatesTable from './ShipperAgreedRatesTable'
import AddShipperAgreedRatesForm from './AddShipperAgreedRatesForm'
import startCase from 'lodash/startCase'
import config from '../../../../config'
import useModalContainer from '../../../../helper/hooks/useModalContainer'

const ShipperAgreedRates = (props) => {
  const {
    shipperAgreedRates,
    setShipperAgreedRates,
    lookupsList,
    customerCardId,
    type,
  } = props
  const [consigneeData, setConsigneeData] = useState([])
  const [driverData, setDriverData] = useState([])
  const [shipperAgreedRateData, setShipperAgreedRateData] = useState(null)
  const [shipperAgreedRateIdx, setShipperAgreedRateIdx] = useState(null)
  const [formActionType, setFormActionType] = useState(null)

  useEffect(() => {
    const consigneeUrl =
      config.api.baseUrl +
      '/customercard/consignee-address/?customerCardId=' +
      customerCardId
    const driverUrl =
      config.api.baseUrl +
      '/customercard/shipper-address/shipper-name?customerCardId=' +
      customerCardId
    const requestOptions = {
      method: 'GET',
      headers: { Authorization: 'Bearer ' + localStorage.token },
    }
    fetch(consigneeUrl, requestOptions)
      .then((response) => response.json())
      .then((data) => {
        setConsigneeData(data)
      })

    fetch(driverUrl, requestOptions)
      .then((response) => response.json())
      .then((data) => {
        setDriverData(data)
      })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleAddShipperAgreedRates = () => {
    setFormActionType('add')
  }

  const { ModalContainer } = useModalContainer({
    text: 'Add Shipper Agreed Rate',
    onCloseCallback: () => {
      setFormActionType(null)
    },
    body: (
      <AddShipperAgreedRatesForm
        cancelAction={setFormActionType.bind(this, null)}
        lookupsList={{
          ...lookupsList,
          consignee: consigneeData,
          shippers: driverData,
        }}
        setShipperAgreedRates={setShipperAgreedRates}
        defaultData={formActionType === 'import' && shipperAgreedRateData}
        formActionType={formActionType}
        type={type}
        shipperAgreedRateIdx={shipperAgreedRateIdx}
      />
    ),
    onShow: handleAddShipperAgreedRates,
    header: <>{startCase(formActionType)} Shipper Agreed Rates Form</>,
    btnClass: 'btn btn-primary mx-1 my-3',
  })

  return (
    <div className="shipper-agreed-rates">
      <ShipperAgreedRatesTable
        csvArray={shipperAgreedRates}
        setCsvArray={setShipperAgreedRates}
        setAgreedRateData={setShipperAgreedRateData}
        setAgreedRateIdx={setShipperAgreedRateIdx}
        setFormActionType={setFormActionType}
        type={type}
      />

      {ModalContainer}
    </div>
  )
}

export default ShipperAgreedRates
