const cursorStyle = { cursor: 'pointer' }
export const ICONS = {
  VIEW: <img style={cursorStyle} src="/assets/vIEW.png" alt="View" />,
  EDIT: <img style={cursorStyle} src="/assets/Edit.png" alt="Edit" />,
  EDIT_DISABLED: <img src="/assets/edit_disabled.png" alt="disable" />,
  DELETE: <img style={cursorStyle} src="/assets/Delete.png" alt="" />,
  ADD: <img src="/assets/add-button.png" alt="Add New" />,
  RAISED_BACK_OF_HAND: ' 🤚',
  RELOAD_ICON: (
    <img src="/assets/loading.png" alt="Loading" style={{ width: '25px' }} />
  ),
}
