import React from 'react'
import { Form, ListGroup } from 'react-bootstrap'
import { useFieldArray } from 'react-hook-form'
import startCase from 'lodash/startCase'

export default function TemplateField({ field, control }) {
  const {
    fields: selectedFields,
    append,
    remove,
  } = useFieldArray({
    control,
    name: 'selectedFields',
  })

  return (
    <ListGroup.Item
      as="li"
      className="d-flex justify-content-between align-items-start"
    >
      <div className="ms-2 me-auto">
        <div className="fw-bold">{startCase(field.fieldName)}</div>
      </div>
      <Form.Check
        checked={selectedFields.some(
          ({ fieldId }) => fieldId === field.fieldId
        )}
        onChange={(e) => {
          if (e.target.checked) append(field)
          else
            remove(
              selectedFields.findIndex(
                ({ fieldId }) => fieldId === field.fieldId
              )
            )
        }}
      />
    </ListGroup.Item>
  )
}
