export const driversCols = [
  {
    header: 'ID',
    accessorKey: 'employeeId',
  },
  {
    header: 'Name',
    accessorKey: 'fullName',
  },
  {
    header: 'Driver Type',
    accessorKey: 'driverTypeName',
  },
  {
    header: 'License No.',
    accessorKey: 'licenseNo',
  },
  {
    header: 'License Exp.',
    accessorKey: 'licenseExpiryDateStr',
  },
  {
    header: 'MSIC',
    accessorKey: 'msic',
  },
  {
    header: 'Groups',
    accessorKey: 'driverGroupName',
  },
  //   {
  //     header: 'Driving Record',
  //     accessorKey: '',
  //     disableFilters: true,
  //     Cell: ({ row: { original } }) => (
  //       <DownloadDocumentButton
  //         docName={original?.driversLicenceUrl}
  //         userId={original?.userId}
  //       />
  //     ),
  //   },
  //   {
  //     header: 'Passport',
  //     accessorKey: '',
  //     disableFilters: true,
  //     Cell: ({ row: { original } }) => (
  //       <DownloadDocumentButton
  //         docName={original?.passportUrl}
  //         userId={original?.userId}
  //       />
  //     ),
  //   },
  //   {
  //     header: 'Medical',
  //     accessorKey: '',
  //     disableFilters: true,
  //     Cell: ({ row: { original } }) => (
  //       <DownloadDocumentButton
  //         docName={original?.medicalUrl}
  //         userId={original?.userId}
  //       />
  //     ),
  //   },
  //   {
  //     header: 'License Back',
  //     accessorKey: '',
  //     disableFilters: true,
  //     Cell: ({ row: { original } }) => (
  //       <DownloadDocumentButton
  //         docName={original?.abnUrl}
  //         userId={original?.userId}
  //       />
  //     ),
  //   },
  //   {
  //     header: 'Assessment',
  //     accessorKey: '',
  //     disableFilters: true,
  //     Cell: ({ row: { original } }) => (
  //       <DownloadDocumentButton
  //         docName={original?.driverAssessmentUrl}
  //         userId={original?.userId}
  //       />
  //     ),
  //   },
  //   {
  //     header: 'Subcontract Form',
  //     accessorKey: '',
  //     disableFilters: true,
  //     Cell: ({ row: { original } }) => (
  //       <DownloadDocumentButton docName={original?.subcontractorFormUrl} />
  //     ),
  //   },
  //   generateDriversEditViewDeleteColumn('userId', false, driversTableClickEvents),
]
