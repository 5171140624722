import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { toast } from 'react-toastify'

import Spinner from '../../Spinner/Spinner'
import config from '../../../config'

import { formData } from './DeliveryFormData'
import Table from '../../Common/Table/Table'
import format from 'date-fns/format'

const Delivery = () => {
  document.body.setAttribute('data-class', 'container')

  const [loading, setLoading] = useState(false)
  const [listData, setListData] = useState([])
  const [slotDate, setSlotDate] = useState(format(new Date(), 'yyyy-MM-dd'))

  const fetchList = async () => {
    setLoading(true)
    axios({
      method: 'POST',
      url: `${config.api.baseUrl}/container/search/summary-display`,
      headers: {
        Authorization: 'Bearer ' + localStorage.token,
      },
      data: {
        slotDate,
      },
    })
      .then((response) => {
        setListData(response?.data?.data)
        if (response?.data?.data?.length === 0) {
          toast.warn('No Data found!')
        }
      })
      .catch((error) => {
        console.error('fetch error', error)
        toast.error(error?.response?.data?.error ?? 'Something went wrong!')
      })
      .finally(() => {
        setLoading(false)
      })
  }

  useEffect(() => {
    fetchList()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div className="container-fluid">
      {loading && <Spinner />}
      <div className="card white-bg-card" style={{ marginBottom: 100 }}>
        <div className="card-body">
          <div className="row">
            <h2>Container Delivery</h2>
            <form className="search-container">
              <label>Search By Slot Date</label>
              <input
                id="slot-date"
                className="form-control"
                placeholder="Slot Date"
                type="date"
                disabled={loading}
                value={slotDate}
                onChange={(e) => {
                  const inputDate = new Date(e.target.value)
                  const year = inputDate.getFullYear()

                  if (year.toString().length <= 4) {
                    setSlotDate(
                      e.target.value ? format(inputDate, 'yyyy-MM-dd') : ''
                    )
                  }
                }}
              />
              <button
                type="button"
                className="btn btn-primary"
                disabled={!slotDate}
                onClick={() => {
                  fetchList()
                }}
              >
                Search
              </button>
            </form>
            <div className="col-md-12 mt-3">
              <Table className="table" data={listData} columns={formData} />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Delivery
