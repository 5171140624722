const stageMovementType = {
  name: 'Movement Type',
  path: '/dataMgmt/customer/stageMovementType',
  req: '/',
  columns: 4,
  fields: [
    {
      name: 'Transport Stage',
      label: 'Transport Stage',
      type: 'text',
      validation: 'Alphabetic',
      fieldId: 'transportStage',
      id: 'transportStage',
      validationType: 'string',
      validations: [
        {
          type: 'required',
          params: ['this field is required'],
        },
        {
          type: 'max',
          params: [20, 'Transport Stage cannot be more than 20 characters'],
        },
        {
          type: 'min',
          params: [3, 'Field should be min 3 characters'],
        },
      ],
    },
    {
      name: 'HLG',
      label: 'HLG',
      type: 'checkbox',
      validation: 'Alphabetic',
      fieldId: 'hlg',
      id: 'hlg',
      validationType: 'boolean',
    },
    {
      name: 'Shipment Type',
      label: 'Shipment Type',
      type: 'select',
      loadLookUp: 'shipmentTypes',
      validation: 'Alphabetic',
      fieldId: 'shipmentType',
      id: 'shipmentType',
      validationType: 'string',
      validations: [
        {
          type: 'required',
          params: ['this field is required'],
        },
        {
          type: 'max',
          params: [20, 'Shipment Type cannot be more than 20 characters'],
        },
        {
          type: 'min',
          params: [3, 'Field should be min 3 characters'],
        },
      ],
    },
    {
      name: 'CY',
      label: 'CY',
      type: 'checkbox',
      validation: 'Alphabetic',
      fieldId: 'cy',
      id: 'cy',
      validationType: 'boolean',
    },
    {
      name: 'Wharf Job Type',
      label: 'Wharf Job Type',
      type: 'select',
      loadLookUp: 'wharfJobTypes',
      validation: 'Alphabetic',
      fieldId: 'wharfJobType',
      id: 'wharfJobType',
      validationType: 'string',
      validations: [
        {
          type: 'required',
          params: ['this field is required'],
        },
        {
          type: 'max',
          params: [30, 'Wharf Job Type cannot be more than 20 characters'],
        },
        {
          type: 'min',
          params: [3, 'Field should be min 3 characters'],
        },
      ],
    },
    {
      name: 'CFS',
      label: 'CFS',
      type: 'checkbox',
      validation: 'Alphabetic',
      fieldId: 'cfs',
      id: 'cfs',
      validationType: 'boolean',
    },
    {
      name: 'Freight',
      label: 'Freight',
      type: 'text',
      validation: 'Alphabetic',
      fieldId: 'freight',
      id: 'freight',
      validationType: 'string',
      validations: [
        {
          type: 'required',
          params: ['this field is required'],
        },
        {
          type: 'max',
          params: [30, 'freight cannot be more than 20 characters'],
        },
        {
          type: 'min',
          params: [3, 'Field should be min 3 characters'],
        },
      ],
    },
    {
      name: 'RAIL',
      label: 'RAIL',
      type: 'checkbox',
      validation: 'Alphabetic',
      fieldId: 'rail',
      id: 'rail',
      validationType: 'boolean',
    },
    {
      name: 'Description',
      label: 'Description',
      type: 'textarea',
      validation: 'Alphabetic',
      fieldId: 'description',
      id: 'description',
      validationType: 'string',
      validations: [
        {
          type: 'max',
          params: [100, 'Description cannot be more than 30 characters'],
        },
      ],
    },
    {
      name: 'Priority',
      label: 'Priority',
      type: 'number',
      validation: 'Alphabetic',
      fieldId: 'priority',
      id: 'priority',
      validationType: 'number',
      validations: [
        {
          type: 'required',
          params: ['this field is required'],
        },
      ],
    },
  ],
}
export default stageMovementType
