import React from 'react'
import { Link } from 'react-router-dom'
import ContainerWithHeading from '../../../components/styles/Containers/ContainerWithHeading'
import Table from '../../../components/Common/Tanstack-table/Table'
import { useQuery } from '@tanstack/react-query'
import fetchService from '../../../components/utils/fetchService'
import config from '../../../config'
import { Spinner } from 'react-bootstrap'

const columns = [
  {
    id: 'name',
    accessorFn: (row) => `${row.firstName} ${row.lastName}`,
    header: 'Name',
    size: 400,
  },
  {
    accessorKey: 'id',
    id: 'id',
    header: 'User Id',
  },
  {
    accessorKey: 'profileGroup',
    id: 'profileGroup',
    header: 'Profile Group',
    size: 300,
  },
  {
    id: 'actions',
    header: 'Actions',
    cell: ({ row: { original } }) => (
      <Link className="btn btn-primary btn-sm" to={`/user/view/${original.id}`}>
        Get More
      </Link>
    ),
  },
]

export default function UsersList() {
  const { data, isLoading, error } = useQuery({
    queryKey: ['users'],
    queryFn: async () =>
      await fetchService({
        url: config.api.allUsers,
      }),
  })

  if (isLoading)
    return (
      <div className="d-flex justify-content-center align-items-center w-100">
        <Spinner />
      </div>
    )

  if (error) {
    console.error(error)
    return (
      <p className="text-danger">
        Some error occurred while fetching users list
      </p>
    )
  }

  return (
    <ContainerWithHeading
      heading="Users"
      Wrapper="div"
      wrapperProps={{ className: 'px-2 w-100 mb-3' }}
    >
      <Table columns={columns} data={data} showOnlyPagination />
    </ContainerWithHeading>
  )
}
