import { flexRender } from '@tanstack/react-table'
import isEmpty from 'lodash/isEmpty'
import React, { useCallback } from 'react'

export default function TableRow({
  table,
  rowSelection,
  row,
  draggable,
  renderSubComponent,
  nestedTableDataId,
  onContextMenu,
  onDrop,
  ...props
}) {
  const dragStart = (event) => {
    let dragData = []
    if (isEmpty(rowSelection)) dragData = [row.original]
    else
      dragData = table.getSelectedRowModel().flatRows.map((row) => row.original)

    event.dataTransfer.setData('row', JSON.stringify(dragData))
  }

  const getTdBackground = (cell) => {
    if (row.getIsSelected()) return 'transparent'
    if (cell.getIsGrouped()) return '#0aff0082'
    if (cell.getIsAggregated()) return '#ffa50078'
    if (cell.getIsPlaceholder()) return '#ff000042'
    return 'white'
  }

  const renderTd = useCallback(
    (cell) => {
      if (cell.getIsGrouped())
        // If it's a grouped cell, add an expander and row count
        return (
          <span style={{ fontWeight: 'bolder', color: 'black' }}>
            <span style={{ fontSize: '14px' }}>
              {row.getIsExpanded() ? '👇' : '👉'}{' '}
            </span>
            {flexRender(cell.column.columnDef.cell, cell.getContext())} (
            {row.subRows.length})
          </span>
        )
      else if (cell.getIsAggregated())
        // If the cell is aggregated, use the Aggregated renderer for cell
        return flexRender(
          cell.column.columnDef.aggregatedCell ?? cell.column.columnDef.cell,
          cell.getContext()
        )
      // For cells with repeated values, render null
      else if (cell.getIsPlaceholder()) return null

      // Otherwise, just render the regular cell
      return flexRender(cell.column.columnDef.cell, cell.getContext())
    },
    [row]
  )

  return (
    <>
      <div
        className="tr"
        style={{
          cursor: row.getCanExpand() ? 'pointer' : 'normal',
          ...(row.getIsSelected() && {
            backgroundColor: 'hsl(90.12deg 100% 50% / 65%)',
          }),
        }}
        onContextMenu={() => (onContextMenu ? onContextMenu(row) : undefined)}
        key={row.id}
        onDragOver={(e) => e.preventDefault()}
        onDrop={(e) => {
          onDrop && onDrop(e, row.original)
        }}
        draggable={draggable}
        onDragStart={(event) => dragStart(event)}
        onClick={
          row.getCanExpand() ? row.getToggleExpandedHandler() : undefined
        }
        onDoubleClick={row.getToggleSelectedHandler()}
        data-row={JSON.stringify(row)}
        {...props}
      >
        {row.getVisibleCells().map((cell) => (
          <div
            {...{
              key: cell.id,
              className: 'td',
              style: {
                width: cell.column.getSize(),
                background: getTdBackground(cell),
              },
            }}
          >
            {renderTd(cell)}
          </div>
        ))}
      </div>
      {row.getIsExpanded() && renderSubComponent ? (
        <div>
          {/* 2nd row is a custom 1 cell row */}
          <div colSpan={row.getVisibleCells().length}>
            {renderSubComponent(row.original[nestedTableDataId])}
          </div>
        </div>
      ) : null}
    </>
  )
}
