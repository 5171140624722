const user = {
  type: 'User Management Data',
  profileType: {
    name: 'User Profile Type',
    path: '/dataMgmt/user/profileType',
    req: '/',
    fields: [
      {
        name: 'User Profile Type',
        type: 'text',
        validation: 'Alphabetic',
        fieldId: 'value',
        id: 'value',
        validationType: 'string',
        validations: [
          {
            type: 'required',
            params: ['this field is required'],
          },
          {
            type: 'max',
            params: [50, 'Profile Type cannot be more than 50 characters'],
          },
          {
            type: 'min',
            params: [3, 'Field should be min 3 characters'],
          },
        ],
      },
    ],
  },
  profileGroup: {
    name: 'User Profile Group',
    path: '/dataMgmt/user/profileGroup',
    req: '/',
    fields: [
      {
        name: 'Profile Group',
        type: 'text',
        validation: 'Alphabetic',
        fieldId: 'value',
        id: 'value',
        validationType: 'string',
        validations: [
          {
            type: 'required',
            params: ['this field is required'],
          },
          {
            type: 'max',
            params: [50, 'Profile Group cannot be more than 50 characters'],
          },
        ],
      },
    ],
  },
}

export default user
