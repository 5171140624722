import React, { useEffect } from 'react'
import { Col, Row, Spinner } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import ContainerWithHeading from '../../../components/styles/Containers/ContainerWithHeading'
import { useDriversDetails } from '../../../helper/hooks/apiHooks'
import {
  selectSelectedDriver,
  selectStatus,
  setSelectedDriver,
} from '../../../store/slices/allocation/drivers'
import DriverAlerts from './components/DriverAlerts'
import DriverStatusOptions from './components/DriverStatusOptions'
import DriversJobsList from './components/DriversJobsList'
import DriversList from './components/DriversList'
import JobsDetails from './components/JobsDetails/JobsDetails'
import RecentActivities from './components/RecentActivities'
import VehicleLocationMap from './components/VehicleLocationMap'

export default function DriversMasterDetails() {
  const dispatch = useDispatch()
  const selectedDriver = useSelector(selectSelectedDriver)
  const status = useSelector(selectStatus)

  const { isLoading, error, data } = useDriversDetails(status)

  /**
   * If there is no driver selected sets the first driver as the selected driver
   */
  useEffect(() => {
    if (!selectedDriver && Array.isArray(data) && data.length) {
      dispatch(setSelectedDriver(data[0]))
    }
  }, [data, dispatch, selectedDriver])

  if (error)
    return (
      <p className="text-danger">
        Error occurred while fetching drivers-vehicles list
      </p>
    )

  return (
    <ContainerWithHeading
      heading="Drivers Jobs Planning"
      Wrapper="div"
      wrapperProps={{
        className: 'container-fluid',
      }}
    >
      <Row>
        <Col md={3} xxl={2}>
          <DriverStatusOptions />
          {isLoading ? <Spinner /> : <DriversList />}
        </Col>
        <Col md={4} xxl={5}>
          <VehicleLocationMap />
          <Row className="mt-3">
            <Col md={4}>
              <RecentActivities />
            </Col>
            <Col md={8}>
              <DriverAlerts />
            </Col>
          </Row>
        </Col>
        <Col md={5} xxl={5}>
          <DriversJobsList />
          <JobsDetails />
        </Col>
      </Row>
    </ContainerWithHeading>
  )
}
