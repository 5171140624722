import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import isEmpty from 'lodash/isEmpty'
import startCase from 'lodash/startCase'
import React, { useCallback, useMemo, useState } from 'react'
import { Button, Modal, Spinner } from 'react-bootstrap'
import { useForm } from 'react-hook-form'
import config from '../../../../config'
import { ICONS } from '../../../../components/Common/Icons'
import Table from '../../../../components/Common/Tanstack-table/Table'
import { Info, Success } from '../../../../components/utils/Toastify'
import fetchService from '../../../../components/utils/fetchService'

const { containerNotes, jobNotes } = config.api

const NOTE_FIELD = 'notes'
const CONTAINER_ID_FIELD = 'containerStageTrackerId'

export default function JobNotesModal({ job }) {
  const queryClient = useQueryClient()

  const [show, setShow] = useState(false)

  const { data: responseData, isLoading } = useQuery({
    queryKey: [jobNotes, job?.containerStageJobDriverId],
    queryFn: async () =>
      await fetchService({
        url: `${jobNotes}/${job?.containerStageJobDriverId}`,
      }),
    enabled: !!job?.containerStageJobDriverId && show,
    staleTime: 60_000 * 2,
  })

  const data = responseData?.data

  const defaultValues = {
    [NOTE_FIELD]: '',
    [CONTAINER_ID_FIELD]: '',
  }

  const {
    register,
    handleSubmit,
    reset,
    watch,
    formState: { errors },
  } = useForm({
    defaultValues,
  })

  const selectedContainerStageTrackerNoteId = watch(
    'containerStageTrackerNoteId'
  )

  const saveNoteMutation = useMutation({
    mutationKey: ['saveNote'],
    mutationFn: async (body) => {
      let url = containerNotes
      let method = 'POST'
      if (body?.containerStageTrackerNoteId) {
        url += `/${body?.containerStageTrackerNoteId}`
        method = 'PUT'
      }
      return await fetchService({
        url,
        method,
        body,
      })
    },
    onSuccess: async (_, body) => {
      await queryClient.refetchQueries({
        queryKey: [jobNotes, job?.containerStageJobDriverId],
      })
      let msg = body?.containerStageTrackerNoteId
        ? 'Note Updated successfully'
        : 'Note Added successfully'
      Success(msg)
      reset(defaultValues)
    },
  })

  const deleteNoteMutation = useMutation({
    mutationKey: ['deleteNote'],
    mutationFn: async (containerStageTrackerNoteId) =>
      await fetchService({
        url: `${containerNotes}/${containerStageTrackerNoteId}`,
        method: 'DELETE',
        isFile: true,
      }),
    onSuccess: async () => {
      await queryClient.refetchQueries({
        queryKey: [jobNotes, job?.containerStageJobDriverId],
      })
      Info('Note Deleted successfully')
      reset(defaultValues)
    },
  })

  const handleActionClick = useCallback(
    (action, row) => {
      switch (action) {
        case 'edit':
          reset({
            [NOTE_FIELD]: row.notes,
            [CONTAINER_ID_FIELD]: row[CONTAINER_ID_FIELD],
            containerStageTrackerNoteId: row.containerStageTrackerNoteId,
          })
          break

        case 'delete':
          deleteNoteMutation.mutate(row.containerStageTrackerNoteId)
          break

        default:
          break
      }
    },
    [deleteNoteMutation, reset]
  )

  const columns = useMemo(() => {
    if (!isEmpty(data)) {
      const cols = Object.keys(data[0]).map((key) => ({
        header: startCase(key),
        accessorKey: key,
        id: key,
      }))
      cols.push({
        header: 'Actions',
        id: 'actions',
        cell: ({ row }) => (
          <div className="d-flex ">
            <button
              title="edit"
              className="btn p-1 me-1"
              onClick={() => {
                handleActionClick('edit', row.original)
              }}
            >
              {ICONS.EDIT}
            </button>
            <button
              title="delete"
              className="btn p-1 me-1 lh-1"
              onClick={() => {
                handleActionClick('delete', row.original)
              }}
            >
              {ICONS.DELETE}
            </button>
          </div>
        ),
      })
      return cols
    }
    return []
  }, [data, handleActionClick])

  const handleClose = () => {
    setShow(false)
  }

  const onSubmit = (values) => {
    saveNoteMutation.mutate(values)
  }

  if (!job) return null
  return (
    <>
      <button className="btn btn-info btn-sm" onClick={() => setShow(true)}>
        Notes
      </button>
      <Modal show={show} onHide={handleClose} size="xl" centered>
        <Modal.Header closeButton>
          <Modal.Title>Add Notes</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h5>Add New Note</h5>
          <form onSubmit={handleSubmit(onSubmit)}>
            <textarea
              {...register(NOTE_FIELD, { required: true })}
              className="form-control"
              disabled={saveNoteMutation.isLoading}
            />
            {errors?.[NOTE_FIELD] && (
              <p className="text-danger">This field is required</p>
            )}
            <select
              disabled={saveNoteMutation.isLoading}
              className="form-control mt-1"
              {...register('containerStageTrackerId', { required: true })}
            >
              <option value="" disabled>
                Please select a container
              </option>
              {Object.values(job?.containerLoads)?.map((load) => (
                <option
                  key={load?.containerLoadNumber}
                  value={load?.containerStageTrackerId}
                >
                  {load?.containerLoadNumber}
                </option>
              ))}
            </select>
            {errors?.[CONTAINER_ID_FIELD] && (
              <p className="text-danger">This field is required</p>
            )}
            {selectedContainerStageTrackerNoteId ? (
              <Button
                disabled={saveNoteMutation.isLoading}
                type="button"
                onClick={() => reset(defaultValues)}
                className="mt-1 me-1"
                variant="secondary"
              >
                Cancel
              </Button>
            ) : null}

            <Button
              disabled={saveNoteMutation.isLoading}
              type="submit"
              className="mt-1"
            >
              {selectedContainerStageTrackerNoteId ? 'Update' : 'Submit'}
            </Button>
          </form>

          <hr />
          <h5>Notes History</h5>
          {isLoading && <Spinner style={{ color: 'aqua' }} />}

          {isEmpty(data) && <p className="text-info">No History found</p>}

          {!isEmpty(data) && (
            <Table
              inheritTableWidth
              showTableBottom={false}
              dontShowFilters
              columns={columns}
              data={data}
            />
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}
