import React, { useContext, useState } from 'react'
import ProfileManager from '../hooks/profile-manager'

const ProfileManagerContext = React.createContext()

export const ProfileManagerProvider = ({ children }) => {
  const profileManager = new ProfileManager()
  const [profiles, setProfiles] = useState(profileManager.profiles)

  return (
    <ProfileManagerContext.Provider
      value={{ profileManager, profiles, setProfiles }}
    >
      {children}
    </ProfileManagerContext.Provider>
  )
}

export default ProfileManagerContext

export const useProfileManager = () => {
  return useContext(ProfileManagerContext)
}
