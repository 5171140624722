import { useEffect } from 'react'
import { Info } from '../../../../components/utils/Toastify'
import { useRouter } from '../../../../helper/hooks/useRouter'
import {
  CREATE_JOB_MODAL,
  LOAD_NOTES_MODAL,
  SERVICE_RATE_MODAL,
} from '../data/allocation-planning.constants'

export default function useKeyBoardsShortcuts(
  selectedUnplannedLoads,
  setSelectedRow,
  setModal
) {
  const { history } = useRouter()
  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.altKey && event.key.toLowerCase() === 'n') {
        event.preventDefault()
        if (selectedUnplannedLoads.length > 0) {
          setSelectedRow(selectedUnplannedLoads[0])
          setModal(LOAD_NOTES_MODAL)
        }
        if (!selectedUnplannedLoads.length) {
          Info('Please select one unplanned load to view notes')
        }
      }
      if (event.altKey && event.key.toLowerCase() === 'v') {
        event.preventDefault()
        if (!selectedUnplannedLoads.length) {
          Info('Please select one unplanned load to view order')
          return
        }
        history.push(
          `/Operations/view/${selectedUnplannedLoads[0].orderCustomerDetailsId}`
        )
      }
      if (event.altKey && event.key.toLowerCase() === 'l') {
        event.preventDefault()
        if (!selectedUnplannedLoads.length) {
          Info('Please select one unplanned load to live track')
          return
        }
        history.push(
          `/live-load-tracking?containerStageTrackerId=${selectedUnplannedLoads[0].containerStageTrackerId}`
        )
      }
      if (event.altKey && event.key.toLowerCase() === 's') {
        event.preventDefault()
        if (!selectedUnplannedLoads.length) {
          Info('Please select one unplanned to access service rates')
          return
        }
        setSelectedRow(selectedUnplannedLoads[0])
        setModal(SERVICE_RATE_MODAL)
      }
      if (event.altKey && event.key.toLowerCase() === 'c') {
        event.preventDefault()
        if (!selectedUnplannedLoads.length) {
          Info('Please select loads to create planned Jobs')
          return
        }
        setModal(CREATE_JOB_MODAL)
      }
    }

    window.addEventListener('keydown', handleKeyDown)
    return () => {
      /*removes event listener on cleanup*/
      window.removeEventListener('keydown', handleKeyDown)
    }
  }, [history, setSelectedRow, selectedUnplannedLoads, setModal])

  return null
}
