import React, { useState } from 'react'
import { Modal, Spinner } from 'react-bootstrap'
import {
  queryKeys,
  useAssignLoadsToDriverMutation,
  useDriversDetails,
} from '../../../../helper/hooks/apiHooks'

export default function CreateJobModal({
  show,
  handleClose,
  selectedRows,
  pagination,
}) {
  const [selectedDriverId, setSelectedDriverId] = useState(null)

  const { data: drivers, isLoading, error } = useDriversDetails()

  const mutation = useAssignLoadsToDriverMutation(
    queryKeys.allocationPlanner.allocations.unplannedLoads(
      pagination.pageIndex,
      pagination.pageSize
    )
  )

  const assignJobToDriver = async () => {
    try {
      await mutation.mutateAsync({
        loads: selectedRows,
        driver: { driverCardId: selectedDriverId },
      })
      handleClose()
    } catch (error) {
      console.log('Error occurred while assigning job', error)
    }
  }

  return (
    <Modal show={show} onHide={handleClose} size="lg" centered>
      <Modal.Header closeButton>
        <Modal.Title>Are you sure you want to create this job?</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {Array.isArray(selectedRows) && selectedRows.length > 0 ? (
          selectedRows.map((row, i) => (
            <h4>
              {i + 1}. {row.loadID}
            </h4>
          ))
        ) : (
          <h4 className="text-danger">Please Select a Load</h4>
        )}
        {isLoading && <Spinner />}
        {error && <pre>{JSON.stringify(error, null, 2)}</pre>}
        {selectedRows?.length && drivers?.length > 0 ? (
          <select
            style={{ height: 'fit-content' }}
            onChange={(e) => setSelectedDriverId(e.target.value)}
            className="form-select w-50 ms-auto"
          >
            <option value="">Please select a driver</option>
            {drivers.map((driver, index) => (
              <option value={driver.driverCardId}>{driver.driverName}</option>
            ))}
          </select>
        ) : null}
      </Modal.Body>
      <Modal.Footer>
        <button
          className="btn btn-primary"
          disabled={!selectedDriverId}
          onClick={assignJobToDriver}
        >
          Create
        </button>
      </Modal.Footer>
    </Modal>
  )
}
