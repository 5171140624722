import React from 'react'
import { useMasterTemplate } from '../../../../helper/hooks/api/allocations'
import { ListGroup, Spinner } from 'react-bootstrap'
import TemplateField from './TemplateField'

export default function MasterTemplateList({ form }) {
  const { isLoading: isLoadingMasterTemplate, data: masterData } =
    useMasterTemplate()

  return (
    <ListGroup
      as="ol"
      numbered
      style={{
        maxHeight: 'calc(100vh - 120px)',
        overflow: 'auto',
        maxWidth: '50ch',
      }}
    >
      {isLoadingMasterTemplate && <Spinner />}
      {Array.isArray(masterData?.masterTemplateFields) &&
        masterData?.masterTemplateFields.map((f) => (
          <TemplateField key={f.fieldId} field={f} control={form.control} />
        ))}
    </ListGroup>
  )
}
