import Dropzone from 'react-dropzone'
import { useUploadOrderEdoMutation } from '../../../../../helper/hooks/api/cerebro'
import { Button, Spinner } from 'react-bootstrap'

export default function EdoInputCell({ cell }) {
  const uploadEdoMutation = useUploadOrderEdoMutation()

  const handleFileUpload = (files) => {
    const data = cell.row?.original
    const orderCustomerDetailsId = data?.orderCustomerDetailsId

    const formData = new FormData()
    formData.append('file', files[0])
    formData.append('orderCustomerDetailsId', orderCustomerDetailsId)
    uploadEdoMutation.mutate(formData)
  }

  return (
    <Dropzone
      noDragEventsBubbling
      accept={{
        'text/csv': [],
        'application/pdf': ['.pdf'],
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': [],
      }}
      onDrop={handleFileUpload}
      multiple={false}
    >
      {({ getRootProps, getInputProps }) => (
        <div {...getRootProps()}>
          <input {...getInputProps()} hidden />
          {uploadEdoMutation.isPending ? (
            <Spinner />
          ) : (
            <Button className="w-100 justify-content-center btn-gold">
              EDO
            </Button>
          )}
        </div>
      )}
    </Dropzone>
  )
}
