import { useMemo } from 'react'
import { useVesselsVoyageNumbers } from '../../../helper/hooks/api/cerebro'

export default function useGetVesselAndVoyageOptions(
  form,
  jobTypeFormKey,
  voyageFormKey
) {
  const { watch } = form

  const selectedJobType = watch(jobTypeFormKey)
  const selectedVoyageNo = watch(voyageFormKey)

  const { data: voyageNumbersData, isLoading: isLoadingVoyageNumbers } =
    useVesselsVoyageNumbers(selectedJobType)

  const voyageOptions = voyageNumbersData?.map((v) => v.voyageNo) || []

  const vesselIdOptions = useMemo(() => {
    return voyageNumbersData?.find((v) => v.voyageNo === selectedVoyageNo)
      ?.vesselIds
  }, [selectedVoyageNo, voyageNumbersData])

  return { vesselIdOptions, voyageOptions, isLoadingVoyageNumbers }
}
