import TablePage from '../components/Common/TablePage/TablePage'

const routes = [
  {
    name: 'invoice',
    path: '/vessel/:controller',
    component: TablePage,
    staticProps: {},
  },
]
export default routes
