export const VIEW_LOAD_MODAL = 'view-load'
export const LOAD_NOTES_MODAL = 'load-notes'
export const SERVICE_RATE_MODAL = 'service-rate'
export const CREATE_JOB_MODAL = 'create-job'
export const STAGE_TRACKER_MODAL = 'stage-tracker'

export const unplannedLoadsFiltersFormDefaultValues = {
  searchDate: '',
  loadstatus: '',
  loadFrom: '',
  loadTo: '',
}
