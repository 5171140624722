import { yupResolver } from '@hookform/resolvers/yup'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import isEmpty from 'lodash/isEmpty'
import React, { useCallback, useMemo } from 'react'
import { Button, FormSelect, Spinner } from 'react-bootstrap'
import { useForm } from 'react-hook-form'
import config from '../../config'
import TextInputField from '../Common/Form/Fields/TextInputField'
import { ICONS } from '../Common/Icons'
import fetchService from '../utils/fetchService'
import * as Yup from 'yup'
import { getLookupDataState } from '../../store/slices/lookup-slice'
import { useSelector } from 'react-redux'
import Table from '../Common/Tanstack-table/Table'
import { Info, Success } from '../utils/Toastify'

const { correspondence } = config.api

export default function Correspondence({ customerCardId }) {
  const queryClient = useQueryClient()
  const lookupsList = useSelector(getLookupDataState)

  const { data, isLoading } = useQuery({
    queryKey: [correspondence, customerCardId],
    queryFn: async () =>
      await fetchService({
        url: `${correspondence}?customerCardid=${customerCardId}`,
      }),
    enabled: !!customerCardId,
    staleTime: 60_000 * 2,
  })

  const defaultValues = {
    messageType: '',
    sendTo: '',
    report: '',
  }

  const {
    register,
    handleSubmit,
    reset,
    watch,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(
      Yup.object({
        messageType: Yup.string().required(),
        sendTo: Yup.string().email().required(),
        report: Yup.string().required(),
      })
    ),
    defaultValues,
  })

  const saveCorrespondenceMutation = useMutation({
    mutationKey: ['saveCorrespondence'],
    mutationFn: async (body) => {
      let url = correspondence
      let method = 'POST'
      if (body?.ccCorrespondenceId) {
        method = 'PUT'
      }
      return await fetchService({
        url,
        method,
        body: [body],
      })
    },
    onSuccess: async (_, body) => {
      await queryClient.refetchQueries({
        queryKey: [correspondence, customerCardId],
      })
      const msg = body?.ccCorrespondenceId
        ? 'Correspondence Updated successfully'
        : 'Correspondence Added successfully'
      Success(msg)
      reset(defaultValues)
    },
  })

  const deleteCorrespondenceMutation = useMutation({
    mutationKey: ['deleteNote'],
    mutationFn: async (ccCorrespondenceId) =>
      await fetchService({
        url: `${correspondence}/${ccCorrespondenceId}`,
        method: 'DELETE',
        isFile: true,
      }),
    onSuccess: async () => {
      await queryClient.refetchQueries({
        queryKey: [correspondence, customerCardId],
      })
      Info('Correspondence Deleted successfully')
      reset(defaultValues)
    },
  })

  const selectedCorrespondenceId = watch('ccCorrespondenceId')

  const handleActionClick = useCallback(
    (action, row) => {
      switch (action) {
        case 'edit':
          reset({
            messageType: row.messageType,
            report: row.report,
            sendTo: row.sendTo,
            ccCorrespondenceId: row.ccCorrespondenceId,
          })
          break

        case 'delete':
          deleteCorrespondenceMutation.mutate(row.ccCorrespondenceId)
          break

        default:
          break
      }
    },
    [deleteCorrespondenceMutation, reset]
  )

  const columns = useMemo(() => {
    const cols = [
      {
        header: 'Msg Type',
        accessorKey: 'messageType',
        id: 'messageType',
      },
      {
        header: 'Report',
        accessorKey: 'report',
        id: 'report',
      },
      {
        header: 'Send To',
        accessorKey: 'sendTo',
        id: 'sendTo',
      },
      {
        header: 'By',
        accessorKey: 'createdBy',
        id: 'createdBy',
      },
    ]

    cols.unshift({
      header: 'Actions',
      id: 'actions',
      cell: ({ row }) => (
        <div className="d-flex ">
          <button
            title="edit"
            className="btn p-1 me-1"
            onClick={() => {
              handleActionClick('edit', row.original)
            }}
          >
            {ICONS.EDIT}
          </button>
          <button
            title="delete"
            className="btn p-1 me-1 lh-1"
            onClick={() => {
              handleActionClick('delete', row.original)
            }}
          >
            {ICONS.DELETE}
          </button>
        </div>
      ),
    })
    return cols
  }, [handleActionClick])

  const onSubmit = (values) => {
    const formValues = {
      ...values,
      customerCardId: parseInt(customerCardId),
    }
    saveCorrespondenceMutation.mutate(formValues)
  }

  return (
    <div>
      <h5>
        {selectedCorrespondenceId
          ? `Editing Correspondence Id ${selectedCorrespondenceId}`
          : 'Add New Correspondence'}
      </h5>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="row">
          <div className="col-md-6">
            <div className="row mb-3 align-items-center">
              <label className="col-sm-4 col-form-label">Msg Type*</label>
              <div className="col-md-8">
                <FormSelect
                  disabled={isLoading}
                  {...register('messageType', { required: true })}
                >
                  <option disabled value="">
                    Please select a type
                  </option>
                  {lookupsList?.messageTypes?.map((msg) => (
                    <option key={msg.createdDate} value={msg.messageCode}>
                      {msg.messageCode}
                    </option>
                  ))}
                </FormSelect>
                {errors?.messageType && (
                  <p className="invalid-feedback">
                    {errors.messageType.message}
                  </p>
                )}
              </div>
            </div>
          </div>
          <div className="col-md-6">
            <TextInputField
              register={register}
              title="Send to"
              id="sendTo"
              required
              type="email"
              errors={errors}
              rowClasses="align-items-center mb-0"
            />
          </div>
          <div className="col-md-6">
            <TextInputField
              register={register}
              title="Report"
              id="report"
              required
              type="text"
              errors={errors}
              rowClasses="align-items-center"
            />
          </div>
        </div>
        <div className="d-flex">
          <div className="ms-auto">
            {selectedCorrespondenceId ? (
              <Button
                disabled={saveCorrespondenceMutation.isLoading}
                type="button"
                onClick={() => reset(defaultValues)}
                variant="secondary"
              >
                Cancel
              </Button>
            ) : null}
            <Button
              disabled={saveCorrespondenceMutation.isLoading}
              type="submit"
              className="ms-1"
            >
              {saveCorrespondenceMutation.isLoading && (
                <Spinner
                  style={{ color: 'aqua', width: '14px', height: '14px' }}
                  className="me-1"
                />
              )}
              Save
            </Button>
          </div>
        </div>
      </form>
      {!isEmpty(data) && (
        <div className="mt-3">
          <Table
            columns={columns}
            data={data}
            showGroupingIcon={false}
            showTableBottom={false}
          />
        </div>
      )}
    </div>
  )
}
