import React, { useMemo } from 'react'
import useDrivers from '../../hooks/useDrivers'
import ResponsivePie from '../../../../../components/Common/Charts/ResponsivePie'
import { ArrayFilters } from '../../helpers/ArrayFilters'

export default function EmployeeTypesPie({ setSelectedDrivers }) {
  const { data: driversData } = useDrivers()

  const driversClass = useMemo(
    () => new ArrayFilters(driversData?.data),
    [driversData?.data]
  )

  const driversEmployeeTypesData = useMemo(
    () => driversClass.groupDataByColumn('employeeType'),
    [driversClass]
  )

  if (!driversData) return []

  return (
    <div className="d-flex h-100">
      <div className="h-100 w-75 text-dark">
        <h3 style={{ fontSize: '1.25rem' }} className="text-light">
          Employment Type Ratio
        </h3>
        <ResponsivePie
          data={driversEmployeeTypesData}
          innerRadius={0.2}
          margin={{ top: 80, right: 80, bottom: 80, left: 80 }}
          cornerRadius={0}
          arcLinkLabelsTextColor="#fafafa"
          arcLabelsTextColor="white"
          arcLinkLabelsOffset={-24}
          arcLinkLabel="label"
          colors={{ scheme: 'purpleRed_green' }}
          onClick={({ id }) => {
            setSelectedDrivers({
              drivers: driversClass.filterByColumn('employeeType', id),
              header: `Employee Type - ${id}`,
            })
          }}
        />
      </div>
      <div style={{ fontSize: '0.75rem' }}>
        {driversEmployeeTypesData.map((t) => (
          <p>
            {t.label} - {t.value}
          </p>
        ))}
      </div>
    </div>
  )
}
