import { createColumnHelper } from '@tanstack/react-table'
import React, { useEffect, useState, useCallback } from 'react'
import isNull from 'lodash/isNull'
import axios from 'axios'
import isEmpty from 'lodash/isEmpty'
import Table from '../../../components/Common/Tanstack-table/Table'
import useModalContainer from '../../../helper/hooks/useModalContainer'
import * as Toastify from '../../../components/utils/Toastify'
import fetchService from '../../../components/utils/fetchService'
import config from '../../../config'
import DropZone from '../../../components/DropZone/DropZone'
import IndeterminateCheckbox from '../../../components/Common/Tanstack-table/components/IndeterminateCheckbox'
import { fileDownload } from '../../../components/utils/downloadFile'

const PlusSign = (props) => (
  <span {...props} className="plus-sign">
    +
  </span>
)

export default function useInvoiceDocsModal(
  customerInvoiceId,
  onCloseModalCallback
) {
  const columnHelper = createColumnHelper()
  const [documents, setDocuments] = useState([])
  const [selectedDocuments, setSelectedDocuments] = useState([])
  const [showUploadDocsSection, setShowUploadDocsSection] = useState(false)

  const fetchDocuments = async () => {
    try {
      const docs = await fetchService({
        url: config.api.invoice.fetchInvoiceDocs + customerInvoiceId,
      })
      if (isEmpty(docs)) {
        Toastify.Error(
          'No documents found for Customer Invoice Id' + customerInvoiceId
        )
      }
      setDocuments(docs)
    } catch (err) {
      console.error(
        'Some error occurred while fetching Invoices Documents.',
        err
      )
      Toastify.Error('Some error occurred while fetching Invoices.')
    }
  }

  useEffect(() => {
    if (!isNull(customerInvoiceId)) {
      setDocuments([])
      setSelectedDocuments([])
      fetchDocuments()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customerInvoiceId])

  const onDrop = useCallback(
    (acceptedFiles) => {
      const formData = new FormData()
      acceptedFiles.forEach((file) => {
        formData.append('files', file)
      })
      const uploadDocs = async () => {
        try {
          const res = await axios({
            method: 'POST',
            url:
              config.api.baseUrl +
              config.api.invoice.uploadDoc +
              customerInvoiceId,
            headers: {
              Authorization: 'Bearer ' + localStorage.token,
              'Content-Type': 'multipart/form-data',
            },
            data: formData,
          })
          if (res.status === 200) {
            Toastify.Success('Documents Uplaoded successfully')
            fetchDocuments()
          }
        } catch (error) {
          error.response.data.data.map((err) => Toastify.Error(err.message))
        }
      }

      uploadDocs()
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [customerInvoiceId]
  )

  const downLoadDocs = () => {
    selectedDocuments.forEach(async (doc) => {
      const url = `${config.api.invoice.downloadDoc}${doc.documentType}/${doc.documentId}`

      try {
        const response = await fetchService({
          returnRaw: true,
          url,
        })

        if (typeof response === 'string') {
          fileDownload(response)
        }
      } catch (error) {
        console.log(error)
      }
    })
  }

  const deleteDocs = () => {
    selectedDocuments.forEach((doc, idx) => {
      const deleteDoc = async () => {
        try {
          await fetchService({
            url: `${config.api.invoice.deleteDoc}${doc.documentType}/${doc.documentId}`,
            method: 'DELETE',
            onSuccessCallback: () => {
              Toastify.Success(
                `${doc.documentType} with Id ${doc.documentId} deleted successfully`
              )
              if (idx === selectedDocuments.length - 1) fetchDocuments()
            },
          })
        } catch (error) {
          console.error(
            'Some error occurred while Deleting Invoices Documents.',
            error
          )
          Toastify.Error('Some error occurred while Deleting Invoices.')
        }
      }

      deleteDoc()
    })
  }

  const invoiceDocsTableColumns = [
    {
      id: 'select',
      header: ({ table }) => (
        <div className="d-flex justify-content-center">
          <IndeterminateCheckbox
            {...{
              checked: table.getIsAllRowsSelected(),
              indeterminate: table.getIsSomeRowsSelected(),
              onChange: table.getToggleAllRowsSelectedHandler(),
            }}
          />
        </div>
      ),
      cell: ({ row }) => (
        <div className="d-flex justify-content-center">
          <IndeterminateCheckbox
            {...{
              checked: row.getIsSelected(),
              indeterminate: row.getIsSomeSelected(),
              onChange: row.getToggleSelectedHandler(),
            }}
          />
          {row.getIsExpanded() && row.original.customerName}
        </div>
      ),
    },
    columnHelper.accessor('fileName', {
      id: 'fileName',
      header: () => <span>Document Name</span>,
      filterFn: 'fuzzy',
    }),
    columnHelper.accessor('uploadedBy', {
      id: 'uploadedBy',
      header: () => <span>Uploaded By</span>,
    }),
    columnHelper.accessor('uploadedDate', {
      id: 'uploadedDate',
      header: () => <span>Uploaded Date</span>,
    }),
    columnHelper.accessor('notes', {
      id: 'notes',
      header: () => <span>Description</span>,
    }),
    columnHelper.accessor('documentType', {
      id: 'documentType',
      header: () => <span>Document Type</span>,
    }),
    columnHelper.accessor('attachedToLoad', {
      id: 'attachedToLoad',
      header: () => <span>Attached to Load</span>,
      filterFn: 'fuzzy',
    }),
    columnHelper.accessor('Status', {
      id: 'Status',
      header: () => <span>Status</span>,
      filterFn: 'fuzzy',
    }),
    columnHelper.accessor('revStat', {
      id: 'revStat',
      header: () => <span>Review Status</span>,
      filterFn: 'fuzzy',
    }),
  ]

  const {
    ModalContainer: InvoiceDocumentsModal,
    setShow: setShowInvoiceDocumentsModal,
  } = useModalContainer({
    body: (
      <div className="invoice-docs-modal-body">
        <div className="actions-tabs">
          <span style={{ color: '#127EA6' }}>
            <button
              type="button"
              className="btn"
              onClick={() => setShowUploadDocsSection((show) => !show)}
            >
              <PlusSign /> Upload Doc
            </button>
          </span>
          <div className="ms-auto">
            <button
              onClick={downLoadDocs}
              disabled={selectedDocuments.length < 1}
            >
              Download Doc
            </button>
            <button disabled>Email Docs</button>
            <button
              disabled={selectedDocuments.length < 1}
              onClick={deleteDocs}
            >
              Delete Docs
            </button>
            <button disabled>Preivew / Process Docs</button>
          </div>
        </div>
        {!isEmpty(documents) ? (
          <Table
            columns={invoiceDocsTableColumns}
            data={documents}
            inheritTableWidth
            setSelectedRows={setSelectedDocuments}
          />
        ) : null}
        {showUploadDocsSection ? (
          <div style={{ height: '300px' }}>
            <DropZone onDrop={onDrop} />
          </div>
        ) : null}
      </div>
    ),
    modalSize: 'xl',
    onCloseCallback: onCloseModalCallback,
  })

  return [InvoiceDocumentsModal, setShowInvoiceDocumentsModal]
}
