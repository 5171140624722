import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faExclamationTriangle,
  faBell,
} from '@fortawesome/free-solid-svg-icons'
import '../clmStyle.scss'
import ContainerWithHeading from '../../styles/Containers/ContainerWithHeading'

const ClmDashboard = () => {
  useEffect(() => {
    document.body.setAttribute('data-class', 'clm-container')
    return () => {
      document.body.removeAttribute('data-class')
    }
  }, [])
  return (
    <div className="w-100">
      <ContainerWithHeading
        heading="Customer Load Management Dashboard"
        Wrapper="div"
        wrapperProps={{ className: 'container-fluid' }}
      >
        <div className="row">
          <div className="col-md-4">
            <div className="columnData">
              <div className="list-group">
                <Link
                  to="/clm-dashboard"
                  className="list-group-item list-group-item-action"
                >
                  Alerts{' '}
                  <FontAwesomeIcon
                    style={{ color: 'red' }}
                    className="fa"
                    icon={faExclamationTriangle}
                  />
                </Link>
                <Link
                  to="/clm-dashboard"
                  className="list-group-item list-group-item-action"
                >
                  Notifications{' '}
                  <FontAwesomeIcon
                    style={{ color: '#d9d93eeb' }}
                    className="fa"
                    icon={faBell}
                  />
                </Link>
              </div>
            </div>
            <br />
            <div className="columnData">
              <div className="flex-content">
                <p>Standard Deliveries</p>
                <p>50/100</p>
              </div>
              <div className="flex-content">
                <p>Sideloader Deliveries</p>
                <p>80/100</p>
              </div>
              <div className="flex-content">
                <p>Dehires completed </p>
              </div>
              <div className="flex-content">
                <p>Trucks Logged On </p>
              </div>
              <div className="flex-content">
                <p>Trucks Logged Off</p>
              </div>
              <div className="flex-content">
                <p>Truck not logged on for {'>'} 2 days</p>
              </div>
              <div className="flex-content">
                <p>No of legs completed from Wharf to Yard leg</p>
              </div>
              <div className="flex-content">
                <p>No of time jobs</p>
              </div>
              <div className="flex-content">
                <p>No of late jobs outside window over 15 min </p>
              </div>
            </div>
          </div>
          <div className="col-md-4 column">
            <div style={{ padding: '10px 25px' }} className="columnData">
              <h5>Load Count</h5>
              <div className="row">
                <div className="col-md-6">
                  <p>Total Load Count</p>
                </div>
                <div className="col-md-6">
                  <div className="bar">
                    <div className="bar1">
                      <span>90%</span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-6">
                  <p>Total Active Count</p>
                </div>
                <div className="col-md-6">
                  <div className="bar">
                    <div className="bar1">
                      <span>90%</span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-6">
                  <p>Total Pending Count</p>
                </div>
                <div className="col-md-6">
                  <div className="bar">
                    <div className="bar2">
                      <span>60%</span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-6">
                  <p>Total Planned Count</p>
                </div>
                <div className="col-md-6">
                  <div className="bar">
                    <div className="bar1">
                      <span>90%</span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-6">
                  <p>Total UnPlanned Count</p>
                </div>
                <div className="col-md-6">
                  <div className="bar">
                    <div className="bar1">
                      <span>90%</span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-6">
                  <p>Total Loads In Progress</p>
                </div>
                <div className="col-md-6">
                  <div className="bar">
                    <div className="bar3">
                      <span>80%</span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-6">
                  <p>Total Loads Staged</p>
                </div>
                <div className="col-md-6">
                  <div className="bar">
                    <div className="bar1">
                      <span>90%</span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-6">
                  <p>Total Loads Completed</p>
                </div>
                <div className="col-md-6">
                  <div className="bar">
                    <div className="bar4">
                      <span>30%</span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-6">
                  <p>Total Loads on Hold</p>
                </div>
                <div className="col-md-6">
                  <div className="bar">
                    <div className="bar1">
                      <span>90%</span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-6">
                  <p>Hazardous</p>
                </div>
                <div className="col-md-6">
                  <div className="bar">
                    <div className="bar5">
                      <span>70%</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-4 column">
            <div className="columnData">
              <div className="list-group">
                <Link
                  to="/clm-dashboard"
                  className="list-group-item list-group-item-action"
                >
                  Tracker Status
                </Link>
                <Link
                  to="/clm-dashboard"
                  className="list-group-item list-group-item-action"
                >
                  Loads Tracked
                </Link>
                <Link
                  to="/clm-dashboard"
                  className="list-group-item list-group-item-action"
                >
                  Driver messages sent
                </Link>
                <Link
                  to="/clm-dashboard"
                  className="list-group-item list-group-item-action"
                >
                  Driver messages received
                </Link>
                <Link
                  to="/clm-dashboard"
                  className="list-group-item list-group-item-action"
                >
                  <span style={{ color: 'red' }}>Tracker Quick Status</span>
                </Link>
              </div>
            </div>
            <br />
            <div className="columnData">
              <div className="list-group">
                <Link
                  to="/clm-dashboard"
                  className="list-group-item list-group-item-action"
                >
                  Fuel Index
                </Link>
                <Link
                  to="/clm-dashboard"
                  className="list-group-item list-group-item-action"
                >
                  Fuel Price
                </Link>
                <Link
                  to="/clm-dashboard"
                  className="list-group-item list-group-item-action"
                >
                  Diesel Price
                </Link>
              </div>
            </div>
          </div>
        </div>
      </ContainerWithHeading>
    </div>
  )
}

export default ClmDashboard
