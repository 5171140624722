import React, { useState } from 'react'
import { Spinner } from 'react-bootstrap'
import { toast } from 'react-toastify'
import Table from '../../../../../components/Common/Tanstack-table/Table'
import { useDriversDetails } from '../../../../../helper/hooks/apiHooks'
import AssignJobConfirmationModal from '../AssignJobConfirmationModal'
import DriversTablePagination from '../DriversTablePagination'

export default function DriversTable({ invalidateQuery }) {
  const [loadToDriverData, setLoadToDriverData] = useState(null)

  const { data: drivers, isLoading, error } = useDriversDetails()

  if (error) return <p>Error occurred while fetching data</p>

  if (isLoading) return <Spinner />

  if (!Array.isArray(drivers) || drivers?.length === 0)
    return <p>No data available</p>

  const closeConfirmation = () => {
    setLoadToDriverData(null)
  }

  const onDrop = (event, driver) => {
    const loads = JSON.parse(event.dataTransfer.getData('row'))
    if (driver?.vehicleTruckDTO?.vehicleTruckId) {
      setLoadToDriverData({ loads, driver })
    } else {
      toast.info('The job can not be assigned - vehicle not assigned to driver')
    }
  }

  return (
    <div className="drivers-table-container">
      <Table
        columns={[
          {
            accessorKey: 'driverName',
            header: 'Driver Name',
          },
        ]}
        data={drivers}
        dontShowFilters={true}
        showGlobalSearch={false}
        CustomPaginationComponent={DriversTablePagination}
        onDrop={onDrop}
      />
      <AssignJobConfirmationModal
        closeConfirmation={closeConfirmation}
        loadToDriverData={loadToDriverData}
        invalidateQuery={invalidateQuery}
      />
    </div>
  )
}
