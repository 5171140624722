import config from '../../config'
import isEmpty from 'lodash/isEmpty'
import {
  setInvoicesDetailsMap,
  setInvoicesList,
  setOrderFinancials,
} from '../slices/invoice-slice'
import * as Toastify from '../../components/utils/Toastify'
import fetchService from '../../components/utils/fetchService'
import { createAsyncThunk } from '@reduxjs/toolkit'

export const fetchOrderFinancials = () => {
  return async (dispatch) => {
    try {
      const data = await fetchService({
        url: config.api.invoice.orderFinancials,
      })
      dispatch(setOrderFinancials(data))
    } catch (err) {
      console.error('Some error occurred while fetching Order Financials.', err)
      Toastify.Error('Some error occurred while fetching Order Financials.')
    }
  }
}

export const fetchInvoices = () => {
  return async (dispatch) => {
    try {
      const data = await fetchService({ url: config.api.invoice.invoices })
      dispatch(setInvoicesList(data))
    } catch (err) {
      console.error('Some error occurred while fetching Invoices.', err)
      Toastify.Error('Some error occurred while fetching Invoices.')
    }
  }
}

export const fetchInvoiceDetails = (invoiceNo) => {
  return async (dispatch) => {
    try {
      const data = await fetchService({
        url: `${config.api.invoice.invoiceDetails}${invoiceNo}`,
      })
      dispatch(setInvoicesDetailsMap({ key: data.invoiceNo, value: data }))
    } catch (err) {
      console.error(
        `Some error occurred while fetching Invoice Details for ${invoiceNo} .`,
        err
      )
      Toastify.Error(
        `Some error occurred while fetching Invoice Details for${invoiceNo} .`
      )
    }
  }
}

export const fetchCreditNotes = createAsyncThunk(
  'invoice/fetchCreditNotes',
  () =>
    fetchService({ url: config.api.invoice.creditNotes }).then((response) => {
      if (isEmpty(response)) throw new Error('No Data Found')
      else return response
    })
)
