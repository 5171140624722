export const crdTableColumnsKeys = [
  'wharfJobType',
  'stageMovementType',
  'vehicleJobType',
  'rateCode',
  'description',
  'location1AreaCode',
  'location2AreaCode',
]

export const zoneTableColumnKeys = [
  'zone',
  'kms',
  'standard20FtContainer',
  'standard40FtContainer',
  'sideLoader20FtContainer',
  'sideLoader40FtContainer',
  'dropTrailerDeliver20FtContainer',
  'dropTrailerDeliver40FtContainer',
  'tollsPrice',
  // 'zoneProfileName',
]

export const suburbTableColumnKeys = [
  'client',
  'deliverToCustomerZone',
  'containerType',
  'deliveryType',
  'areaCode',
  'state',
  'cartageRate',
]
