import { yupResolver } from '@hookform/resolvers/yup'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import React from 'react'
import { Col, Form, Spinner } from 'react-bootstrap'
import { useFieldArray, useForm } from 'react-hook-form'
import * as Yup from 'yup'
import Switch from '../../../../../components/Common/Form/Fields/Switch'
import * as Toastify from '../../../../../components/utils/Toastify'
import fetchService from '../../../../../components/utils/fetchService'
import useModalContainer from '../../../../../helper/hooks/useModalContainer'

const schema = Yup.object().shape({
  holdCodes: Yup.array().of(
    Yup.object().shape({
      hold: Yup.boolean(),
      notes: Yup.string()
        .nullable()
        .when('hold', {
          is: true,
          then: (yup) => yup.required('Notes are required'),
        }),
    })
  ),
})

const HoldStatus = ({
  url,
  profileId,
  heading,
  profileRecordUnqKey,
  profileIdKey,
}) => {
  const queryClient = useQueryClient()

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    control,
  } = useForm({
    resolver: yupResolver(schema),
    reValidateMode: 'onChange',
  })

  const { fields } = useFieldArray({
    name: 'holdCodes',
    control,
  })

  const { data: holdCodesData } = useQuery({
    queryKey: [url],
    queryFn: async () =>
      await fetchService({ url }).then((response) => {
        reset({
          holdCodes: response,
        })
        return response
      }),
    enabled: profileId > 0,
    refetchOnMount: 'always',
  })

  const mutation = useMutation({
    mutationFn: async (body) =>
      await fetchService({
        body,
        url,
        method: 'POST',
      }),
    onSuccess: (response) => {
      Toastify.Success('Hold Status details saved successfully..!')
      queryClient.invalidateQueries({
        queryKey: [url],
      })
    },
    onError: (error) => {
      if (Array.isArray(error?.data)) {
        error.data.forEach(({ key, message }) => {
          Toastify.Error(`${key} - ${message}`)
        })
      }
    },
  })

  const submitContainerHoldStatus = (values) => {
    mutation.mutate(
      values.holdCodes
        .map((c) => ({
          [profileRecordUnqKey]: c?.[profileRecordUnqKey] ?? null,
          hold: c.hold,
          holdCode: c.holdCode,
          [profileIdKey]: profileId,
          notes: c.notes,
        }))
        .filter((c) => c.hold || !!c[profileRecordUnqKey])
    )
  }

  const handleSubmitWithoutPropagation = (e) => {
    e.preventDefault()
    e.stopPropagation()
    handleSubmit(submitContainerHoldStatus)(e)
  }

  const { ModalContainer, setShow } = useModalContainer({
    text: (
      <button
        type="button"
        className="unset-all d-flex flex-column align-items-center"
        onClick={(e) => {
          e.preventDefault()
          if (profileId > 0) {
            setShow(true)
          }
        }}
      >
        <img
          src="./assets/customer-order/hold.png"
          alt="Hold Status"
          width="40px"
          height="40px"
          className="img"
        />
        Hold
      </button>
    ),
    header: heading,
    body: (
      <form onSubmit={handleSubmitWithoutPropagation}>
        <div>
          <div className="row">
            <div className="col-md-2" />
            <div className="col-md-3">
              <h4>Hold Code</h4>
            </div>
            <div className="col-md-4 mb-4">
              <h4>Status</h4>
            </div>
          </div>
          {fields.map((field, index) => (
            <div className="row" key={field.id}>
              <div className="col-md-2" />
              <div className="col-md-3">
                <h5>{field.holdCode} :</h5>
              </div>
              <div className="col-md-1 mb-4">
                <Switch
                  register={register}
                  errors={errors}
                  name={`holdCodes[${index}].hold`}
                  label=""
                />
              </div>
              <div className="col-md-3">
                <Form.Group className="mb-3" as={Col} md={12}>
                  <Form.Control
                    type="text"
                    placeholder={`${field.holdCode} Notes`}
                    {...register(`holdCodes[${index}].notes`)}
                  />
                  {errors?.holdCodes?.[index]?.notes && (
                    <Form.Text className="text-danger">
                      {errors?.holdCodes?.[index].notes.message}
                    </Form.Text>
                  )}
                </Form.Group>
              </div>
            </div>
          ))}
        </div>

        <div className="row">
          <div className="col-md-2" />
          <div className="col-5 text-right">
            <button
              disabled={mutation.isLoading}
              className="btn btn-primary float-end ms-3"
              type="submit"
            >
              {mutation.isLoading ? <Spinner /> : 'Save'}
            </button>
            <button
              className="btn btn-secondary float-end"
              onClick={() => {
                reset({
                  holdCodes: holdCodesData.map((c) => ({
                    ...c,
                    hold: false,
                    notes: null,
                  })),
                })
              }}
              type="button"
            >
              Reset
            </button>
          </div>
        </div>
      </form>
    ),
    onCloseCallback: () => {
      reset()
    },
  })

  return ModalContainer
}

export default HoldStatus
