import React from 'react'
import { selectSelectedJob } from '../../../../../store/slices/allocation/drivers'
import { useSelector } from 'react-redux'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMoon, faSun } from '@fortawesome/free-solid-svg-icons'

export default function JobShiftIcon() {
  const selectedJob = useSelector(selectSelectedJob)

  if (!selectedJob) return null

  const { jobDriverDTO } = selectedJob

  if (jobDriverDTO?.shift === 'DAY') return <FontAwesomeIcon icon={faSun} />

  return <FontAwesomeIcon icon={faMoon} />
}
