import React from 'react'
import { Col, Form } from 'react-bootstrap'
import startCase from 'lodash/startCase'
import RequiredSymbol from '../RequiredSymbol'

export default function Input({
  name,
  type = 'text',
  label = startCase(name),
  placeholder = label,
  register,
  md = 4,
  errors,
  formGroupProps,
  registerOptions = {},
  required = false,
  ...props
}) {
  return (
    <Form.Group
      className="mb-3"
      as={Col}
      md={md}
      controlId={name}
      {...formGroupProps}
    >
      <Form.Label>
        {label} {required && <RequiredSymbol />}
      </Form.Label>
      <Form.Control
        type={type}
        placeholder={placeholder}
        {...register(name, registerOptions)}
        autoComplete="off"
        {...props}
      />
      {errors?.[name] && (
        <Form.Text className="text-danger">{errors?.[name].message}</Form.Text>
      )}
    </Form.Group>
  )
}
