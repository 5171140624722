export const service_yard = {
  name: 'Service Yard',
  apiUrlAppend: false,
  apiUrlRepelaceUnderscore: true,
  path: '/dataMgmt/customer/service_yard',
  req: '/',
  columns: 4,
  fields: [
    {
      name: 'code',
      label: 'code',
      type: 'text',
      validation: 'Alphabetic',
      fieldId: 'code',
      id: 'code',
      validationType: 'string',
      validations: [
        {
          type: 'required',
          params: ['Code is required'],
        },
        {
          type: 'max',
          params: [15, 'ID cannot be more than 15 characters'],
        },
      ],
    },
    {
      name: 'Special Service',
      label: 'Special Service',
      type: 'select',
      loadLookUp: 'specialServices',
      fieldId: 'specialService',
      id: 'specialService',
    },
    {
      name: 'Description',
      label: 'Description',
      type: 'text',
      validation: 'Alphabetic',
      fieldId: 'description',
      id: 'description',
      validationType: 'string',
      validations: [
        {
          type: 'required',
          params: ['Description is required'],
        },
        {
          type: 'max',
          params: [30, 'Description cannot be more than 30 characters'],
        },
      ],
    },
    {
      name: 'Address',
      label: 'Address',
      type: 'textarea',
      validation: 'Alphabetic',
      fieldId: 'address',
      id: 'address',
      validationType: 'string',
      validations: [
        {
          type: 'required',
          params: ['Address is required'],
        },
        {
          type: 'max',
          params: [200, 'Address cannot be more than 200 characters'],
        },
      ],
    },
    {
      name: 'Area Code',
      label: 'Area Code',
      type: 'select',
      loadLookUp: 'areaCodes',
      validation: 'Alphabetic',
      fieldId: 'areaCode',
      id: 'areaCode',
      validationType: 'string',
      validations: [
        {
          type: 'required',
          params: ['Area Code is required'],
        },
      ],
      options: [],
    },
    {
      name: 'Suburb',
      label: 'Suburb',
      type: 'textarea',
      validation: 'Alphabetic',
      fieldId: 'suburb',
      id: 'suburb',
      validationType: 'string',
      validations: [
        {
          type: 'required',
          params: ['Suburb is required'],
        },
        {
          type: 'max',
          params: [100, 'Suburb cannot be more than 100 characters'],
        },
      ],
    },
    {
      name: 'Zone Code',
      label: 'Zone Code',
      type: 'select',
      loadLookUp: 'zoneCodes',
      validation: 'Alphabetic',
      fieldId: 'zoneCode',
      id: 'zoneCode',
      validationType: 'string',
      validations: [
        {
          type: 'required',
          params: ['Zone Code is required'],
        },
      ],
      options: [],
    },
  ],
}
export default service_yard
