import React, { Fragment } from 'react'
import { Col, Form } from 'react-bootstrap'
import startCase from 'lodash/startCase'
import FormErrorText from '../Common/Form/FormErrorText'

export const getErrorClassName = (id) => {
  if (id) return 'is-invalid'
  return ''
}

export const TextBox = (props) => {
  const {
    register,
    id,
    errors,
    maxLength,
    disabled,
    type,
    viewMode,
    defaultData,
    className,
  } = props

  return (
    <React.Fragment>
      {!viewMode || viewMode === 'create' || viewMode === 'edit' ? (
        <>
          <input
            id={id}
            placeholder=""
            name={id}
            {...register(id)}
            className={`form-control ${className} ${getErrorClassName(
              errors?.[id]
            )}`}
            type={type ?? 'text'}
            maxLength={maxLength}
            // onChange={onChangeEvent}
            // value={props.value? props.value : ""}
            disabled={disabled}
          />
          <FormErrorText errors={errors} name={id} />
        </>
      ) : (
        <h5 className="m-0">{defaultData?.[id]}</h5>
      )}
    </React.Fragment>
  )
}

export const TextInputWithLabelField = ({
  name,
  type = 'text',
  label = startCase(name),
  placeholder = label,
  register,
  registerOptions,
  viewMode,
  ratio = [6, 6],
  errors,
  Wrapper = Fragment,
  defaultData,
  ...props
}) => {
  return (
    <Wrapper>
      <Col md={ratio[0]}>
        <label htmlFor="name">{label} :</label>
      </Col>
      <Col md={ratio[1]} className="mb-2">
        {viewMode !== 'view' ? (
          <>
            <Form.Control
              type={type}
              placeholder={placeholder}
              {...register(name, registerOptions)}
              autoComplete="off"
              {...props}
            />
            {errors?.[name] && (
              <Form.Text className="text-danger">
                {errors?.[name].message}
              </Form.Text>
            )}
          </>
        ) : (
          <h5 className="m-0">{defaultData?.[name]}</h5>
        )}
      </Col>
    </Wrapper>
  )
}
