import { createColumnHelper } from '@tanstack/react-table'
import IndeterminateCheckbox from '../../../../components/Common/Tanstack-table/components/IndeterminateCheckbox'

const columnHelper = createColumnHelper()

const columns = [
  columnHelper.accessor('', {
    id: 'select',
    header: ({ table }) => (
      <IndeterminateCheckbox
        {...{
          checked: table.getIsAllRowsSelected(),
          indeterminate: table.getIsSomeRowsSelected(),
          onChange: table.getToggleAllRowsSelectedHandler(),
        }}
      />
    ),
    cell: ({ row }) => (
      <div className="px-1">
        <IndeterminateCheckbox
          {...{
            checked: row.getIsSelected(),
            indeterminate: row.getIsSomeSelected(),
            onChange: row.getToggleSelectedHandler(),
          }}
        />
      </div>
    ),
  }),
  columnHelper.accessor('jobNumber', {
    id: 'jobNumber',
    header: () => <span>Job Number</span>,
    cell: (info) => info.getValue(),
    footer: (info) => info.column.id,
    filterFn: 'fuzzy',
    // sortingFn: fuzzySort,
  }),
  columnHelper.accessor((row) => row.driverName, {
    id: 'driverName',
    cell: (info) => <i>{info.getValue()}</i>,
    header: () => <span>Driver Name</span>,
    footer: (info) => info.column.id,
    filterFn: 'fuzzy',
  }),
  columnHelper.accessor('jobDriverStatus', {
    id: 'jobDriverStatus',
    header: () => 'Job Status',
    cell: (info) => info.renderValue(),
    footer: (info) => info.column.id,
    filterFn: 'fuzzy',
  }),
  columnHelper.accessor('truckRego', {
    id: 'truckRego',
    header: () => <span>Truck Rego</span>,
    footer: (info) => info.column.id,
    filterFn: 'fuzzy',
  }),
]

export default columns
