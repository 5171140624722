import has from 'lodash/has'
import startCase from 'lodash/startCase'
import React, { useMemo, useState } from 'react'
import { Button, ButtonGroup, Col, Offcanvas, Spinner } from 'react-bootstrap'
import { Controller, useForm } from 'react-hook-form'
import { useSelector } from 'react-redux'
import SearchableSelectField from '../../../../../components/Common/Form/Fields/SearchableSelectField'
import Select from '../../../../../components/Common/Form/Fields/Select'
import Table from '../../../../../components/Common/Tanstack-table/Table'
import { TextBox } from '../../../../../components/utils/TextBox'
import { checkValidColValue } from '../../../../../components/utils/Utils'
import {
  useSearchPort,
  useVesselsVoyageNumbers,
} from '../../../../../helper/hooks/api/cerebro'
import { getLookupDataState } from '../../../../../store/slices/lookup-slice'
import { portSlotKeyToColDefMap, transitModeOptions } from '../data'

const JobTypes = ({ control, name }) => {
  const lookupsList = useSelector(getLookupDataState)

  return (
    <Col md={12} className="d-flex align-items-center mb-2">
      <Controller
        control={control}
        name={name}
        render={({ field: { onChange, value } }) => (
          <ButtonGroup aria-label="Basic example" className="w-100">
            {lookupsList?.wharfJobTypes.map((item) => {
              const selected = item.id === value
              return (
                <Button
                  key={item.id}
                  onClick={() => onChange(item.id)}
                  variant={selected ? 'info' : 'outline-info'}
                  type="button"
                >
                  {item.description}
                </Button>
              )
            })}
          </ButtonGroup>
        )}
      ></Controller>
    </Col>
  )
}

const FiltersForm = ({ form }) => {
  const lookupsList = useSelector(getLookupDataState)

  const [show, setShow] = useState(false)

  const handleClose = () => setShow(false)
  const toggleShow = () => setShow((s) => !s)

  const {
    register,
    watch,
    formState: { errors },
    reset,
    control,
    setValue,
  } = form

  const selectedJobType = watch('jobType')
  const selectedVoyageNo = watch('voyageNumber')

  const { data: voyageNumbersData, isLoading: isLoadingVoyageNumbers } =
    useVesselsVoyageNumbers(selectedJobType)

  const voyageOptions = voyageNumbersData?.map((v) => v.voyageNo) || []

  const vesselIdOptions = useMemo(() => {
    return voyageNumbersData?.find((v) => v.voyageNo === selectedVoyageNo)
      ?.vesselIds
  }, [selectedVoyageNo, voyageNumbersData])

  return (
    <div className="d-flex">
      <Button
        variant="primary"
        onClick={toggleShow}
        style={{ marginBottom: '-50px' }}
        className="me-2 ms-auto"
      >
        Filters
      </Button>
      <Offcanvas show={show} onHide={handleClose} scroll placement="end">
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>Filters</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <div className="row">
            <JobTypes control={control} name="jobType" />
            <Select
              name="wharfName"
              label="Wharf Code"
              register={register}
              errors={errors}
              options={lookupsList?.wharfCodes}
              optionValueKey="wharfCode"
              md={12}
            />
            {isLoadingVoyageNumbers && <Spinner />}
            {!isLoadingVoyageNumbers && Array.isArray(voyageOptions) && (
              <SearchableSelectField
                name="voyageNumber"
                control={control}
                dependentFieldIds={['vessel']}
                setValue={setValue}
                md={12}
                options={voyageOptions}
              />
            )}
            <Select
              name="vessel"
              register={register}
              errors={errors}
              options={vesselIdOptions}
              md={12}
            />
            <div className="col-md-12 mb-2">
              <label htmlFor="fdate">Slot From Date :</label>
              <TextBox
                className="form-control"
                register={register}
                id="slotFromDate"
                errors={errors}
                type="date"
              />
            </div>
            <div className="col-md-12 mb-2">
              <label htmlFor="tdate">Slot To Date :</label>
              <TextBox
                register={register}
                id="slotToDate"
                errors={errors}
                type="date"
              />
            </div>
            <Select
              register={register}
              errors={errors}
              name="transitMode"
              options={transitModeOptions}
              md={12}
            />
          </div>
          <Button variant="secondary" className="mb-2" onClick={() => reset()}>
            Reset
          </Button>
        </Offcanvas.Body>
      </Offcanvas>
    </div>
  )
}

export default function PortSlot() {
  const form = useForm({
    defaultValues: {
      jobType: 'IMPORT',
      slotFromDate: '',
      slotToDate: '',
      vessel: '',
      voyageNumber: '',
      wharfName: '',
      transitMode: '',
    },
  })

  const { watch } = form

  const formValues = watch()

  const { data: portSlotQueryResponse, isFetching } = useSearchPort(formValues)

  const columns = useMemo(() => {
    if (!portSlotQueryResponse) return []
    if (
      !Array.isArray(portSlotQueryResponse?.data) ||
      portSlotQueryResponse.data.length === 0
    )
      return []

    const obj = portSlotQueryResponse.data[0]

    return Object.keys(obj)
      .filter((k) => checkValidColValue(obj[k]))
      .map((key) => {
        if (has(portSlotKeyToColDefMap, key))
          return {
            header: startCase(key),
            accessorKey: key,
            id: key,
            cell: portSlotKeyToColDefMap[key],
          }

        return {
          header: startCase(key),
          accessorKey: key,
          id: key,
        }
      })
  }, [portSlotQueryResponse])

  return (
    <div>
      <FiltersForm form={form} />
      {isFetching && <Spinner />}
      <Table
        tableId="ppp-port-slot"
        data={portSlotQueryResponse?.data || []}
        columns={columns}
        isFetching={isFetching}
      />
    </div>
  )
}
