import React from 'react'

export const getSelectClass = (_id) => {
  if (_id) return 'form-select is-invalid'
  return 'form-select'
}

export const Select = (props) => {
  const {
    register,
    id,
    errors,
    data,
    type,
    displayKey,
    valueKey,
    viewMode,
    defaultData,
  } = props

  return (
    <React.Fragment>
      {!viewMode ? (
        <>
          <select
            className={getSelectClass(errors?.id)}
            id={id}
            name={id}
            {...register(id)}
            disabled={type === 'view'}
          >
            <option value="">Please Select</option>
            {data?.map((item, i) => (
              <option key={i} value={item[valueKey]}>
                {item[displayKey]}
              </option>
            ))}
          </select>
          {errors && errors[id] && (
            <p className="invalid-feedback"> {errors[id].message}</p>
          )}
        </>
      ) : (
        <h5>{defaultData[id]}</h5>
      )}
    </React.Fragment>
  )
}
