const rateGroup = {
  name: 'Rate Group',
  path: '/dataMgmt/user/profileType',
  req: '/',
  columns: 2,
  fields: [
    {
      name: 'Rate Group Code',
      label: 'Rate Group Code',
      type: 'text',
      validation: 'Alphabetic',
      fieldId: 'rateGroupCode',
      id: 'rateGroupCode',
      validationType: 'string',
      validations: [
        {
          type: 'required',
          params: ['this field is required'],
        },
        {
          type: 'max',
          params: [20, 'Rate Group Code cannot be more than 20 characters'],
        },
      ],
    },
    {
      name: 'Description',
      label: 'Description',
      type: 'text',
      validation: 'Alphabetic',
      fieldId: 'description',
      id: 'description',
      validationType: 'string',
      validations: [
        {
          type: 'required',
          params: ['this field is required'],
        },
        {
          type: 'max',
          params: [30, 'Description cannot be more than 30 characters'],
        },
      ],
    },
  ],
}
export default rateGroup
