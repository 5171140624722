import { useQuery } from '@tanstack/react-query'
import React, { Suspense, lazy, useState } from 'react'
import { Form, Tab, Tabs } from 'react-bootstrap'
import fetchService from '../../../../../components/utils/fetchService'
import { strings } from '../enums'

const CRD = lazy(() => import('../components/CRD/CRD'))
const ZoneProfile = lazy(() => import('../components/ZoneProfile/ZoneProfile'))
const SuburbProfile = lazy(() =>
  import('../components/SuburbProfile/SuburbProfile')
)
const CartageRates = lazy(() => import('../components/CartageRates'))

/**
 * entity refers to data for consignee & shipper stored in entity.data.js
 */
export default function EntityRates({ type, customerCardId, entity }) {
  const { tabKey, name, profileIdKey, tabs } = entity
  const { crd, suburb, zone, cartage } = tabs
  const entityNameUrl = entity.namesApiUrl(customerCardId)

  const [entityId, setEntityId] = useState(null)
  const [activeTab, setActiveTab] = useState(tabKey)

  const { data: entityNames } = useQuery({
    queryKey: [entityNameUrl],
    queryFn: async () => await fetchService({ url: entityNameUrl }),
    enabled: !!customerCardId,
    staleTime: 60_000 * 15,
  })

  return (
    <div>
      <Form.Group className="mb-3">
        <Form.Label>Choose a {name}</Form.Label>
        <Form.Select onChange={(e) => setEntityId(e.target.value)}>
          <option value="">Choose...</option>
          {entityNames?.map((option) => (
            <option value={option.id} key={option.id}>
              {option.code}
            </option>
          ))}
        </Form.Select>
      </Form.Group>
      {entityId !== null && (
        <Tabs
          id="customer-rate-setup-tabs"
          activeKey={activeTab}
          onSelect={(k) => setActiveTab(k)}
          className="mb-3"
        >
          <Tab eventKey={tabKey} title={tabKey}>
            <Suspense fallback={<div>Loading...</div>}>
              <CRD
                customerCardId={customerCardId}
                profileId={entityId}
                profileIdKey={profileIdKey}
                baseUrl={crd.baseUrl}
                queryUrl={crd.queryUrl(entityId)}
                uniqueProfileIdKey={crd.uniqueProfileIdKey}
                type={type}
                optionsConfig={{
                  url: entityNameUrl,
                  labelKey: 'code',
                  valueKey: 'id',
                }}
              />
            </Suspense>
          </Tab>
          <Tab eventKey={strings.ZoneProfile} title={strings.ZoneProfile}>
            <Suspense fallback={<div>Loading...</div>}>
              <ZoneProfile
                profileId={entityId}
                profileIdKey={profileIdKey}
                baseUrl={zone.baseUrl}
                queryUrl={zone.queryUrl(entityId)}
                uniqueProfileIdKey={zone.uniqueProfileIdKey}
                type={type}
                optionsConfig={{
                  url: entityNameUrl,
                  labelKey: 'code',
                  valueKey: 'id',
                }}
              />
            </Suspense>
          </Tab>
          <Tab eventKey={strings.SuburbProfile} title={strings.SuburbProfile}>
            <Suspense fallback={<div>Loading...</div>}>
              <SuburbProfile
                profileId={entityId}
                profileIdKey={profileIdKey}
                baseUrl={suburb.baseUrl}
                queryUrl={suburb.queryUrl(entityId)}
                uniqueProfileIdKey={suburb.uniqueProfileIdKey}
                type={type}
                optionsConfig={{
                  url: entityNameUrl,
                  labelKey: 'code',
                  valueKey: 'id',
                }}
              />
            </Suspense>
          </Tab>
          <Tab eventKey={strings.CartageRates} title={strings.CartageRates}>
            <Suspense fallback={<div>Loading...</div>}>
              <CartageRates
                profileId={entityId}
                profileIdKey={profileIdKey}
                baseUrl={cartage.baseUrl}
                queryUrl={cartage.queryUrl(entityId)}
                uniqueProfileIdKey={cartage.uniqueProfileIdKey}
              />
            </Suspense>
          </Tab>
        </Tabs>
      )}
    </div>
  )
}
