import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  accounting: {
    // active: false,
    // billingAddress: '',
    // ccAccountingId: 0,
    // ccContainerCargoRules: null,
    // createdBy: '',
    // createdDate: '',
    // creditLimit: 0,
    // creditTerms: '',
    // customerCardId: null,
    // lastModifiedBy: '',
    // lastModifiedDate: '',
    // tradingOrAccountHold: false,
  },
}

const customerSlice = createSlice({
  name: 'customer',
  initialState,
  reducers: {
    setAccountingDetails(state, action) {
      state.accounting = action.payload
    },
  },
})

export const { setAccountingDetails } = customerSlice.actions

export const getAccountingDetails = (state) => state.customer.accounting

export default customerSlice.reducer
