import React, { useState } from 'react'
import { useVehicleTypeDetails } from '../../hooks/useVehicleTypeDetails'

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js'
import { Bar } from 'react-chartjs-2'
import DateSelector from './DateSelector'

export const options = {
  //   indexAxis: 'y',
  elements: {
    bar: {
      borderWidth: 0,
    },
  },
  maintainAspectRatio: false,
  scales: {
    y: {
      ticks: {
        color: 'white',
      },
    },
    x: {
      ticks: {
        color: '#fff',
      },
    },
  },
  responsive: true,
  plugins: {
    title: {
      display: true,
      text: 'Drivers Vehicle Types ratio',
      color: 'white',
    },
  },
}

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend)

export default function VehicleTypeBar() {
  const [startDate, setStartDate] = useState('sixMonthsBefore')

  const { data: details } = useVehicleTypeDetails(startDate)

  const labels = details?.data?.map((t) => t.label)

  const data = {
    labels,
    datasets: [
      {
        label: '',
        data: details?.data.map((t) => t.countOfDrivers),
        borderColor: 'white',
        backgroundColor: 'rgb(194, 165, 207)',
      },
    ],
  }

  return (
    <>
      <DateSelector startDate={startDate} setStartDate={setStartDate} />
      <div className="d-flex" style={{ height: '100%' }}>
        <div className="text-dark w-100">
          {details?.data && <Bar options={options} data={data} />}
        </div>
      </div>
    </>
  )
}
