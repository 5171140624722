import { useMutation, useQuery } from '@tanstack/react-query'
import dayjs from 'dayjs'
import startCase from 'lodash/startCase'
import React, { useState } from 'react'
import { Button, Spinner } from 'react-bootstrap'
import { useForm } from 'react-hook-form'
import NumberInputField from '../../../../components/Common/Form/Fields/NumberInputField'
import fetchService from '../../../../components/utils/fetchService'

const getFormValues = (res) => {
  const obj = {}
  if (res) {
    Object.entries(res)
      .filter(([key]) => key.includes('time'))
      .forEach(([key, value]) => (obj[`${key}Total`] = value.total))
  }
  return obj
}

export default function CapacityPlanner() {
  const [selectedDate, setSelectedDate] = useState(dayjs().format('YYYY-MM-DD'))

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm()

  const { data, error, isLoading } = useQuery({
    queryKey: ['/clm/capacity-planning/overridden/date/', selectedDate],
    queryFn: () =>
      fetchService({
        url: `/clm/capacity-planning/overridden/date/${selectedDate}`,
      }),
    retry: 2,
    staleTime: 60_000 * 15,
    onSuccess: (response) => {
      reset(getFormValues(response))
    },
  })

  const { isLoading: isUpdating, mutate } = useMutation({
    mutationKey: ['save-capacity-planning'],
    mutationFn: (values) =>
      fetchService({
        url:
          values.data.id === null
            ? `/clm/capacity-planning/overridden/date/${values.data.planningDate}`
            : `/clm/capacity-planning/overridden/${values.data.id}`,
        method: values.data.id === null ? 'POST' : 'PUT',
        body: values.formValues,
      }),
    onSuccess: (res) => {
      reset(getFormValues(res))
    },
    // onSettled: () => {
    //   queryClient.invalidateQueries({
    //     exact: ['/clm/capacity-planning/overridden/date/', selectedDate],
    //   })
    // },
  })

  const onSubmit = (values) => {
    mutate({ formValues: values, data })
  }

  if (error) {
    return (
      <div className="error-container">
        <p>{error?.message}</p>
        <pre>{JSON.stringify(error, null, 2)}</pre>
      </div>
    )
  }

  return (
    <div className="capacity-planner-wrapper">
      <header>
        <h1>Capacity Planner</h1>
      </header>

      <main className="__container">
        <h3>Select a date to plan</h3>
        <input
          onChange={(e) => setSelectedDate(e.target.value)}
          value={selectedDate}
          className="form-control date-input"
          type="date"
          disabled={isLoading}
        />
        {isLoading ? (
          <div
            className="w-100 d-flex justify-content-center align-items-center"
            style={{ minHeight: '70vh' }}
          >
            <Spinner
              style={{
                color: 'blanchedalmond',
                width: '148px',
                height: '148px',
              }}
            />
          </div>
        ) : (
          <div className="mt-3 form-container fade-in-right">
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="row">
                {Object.entries(data).map(([key, value], idx) => {
                  if (!key.includes('time')) return null
                  return (
                    <div className="col-md-4" key={key}>
                      <NumberInputField
                        register={register}
                        registerOptions={{
                          min: {
                            value: 0,
                            message: "Total can't be less than zero",
                          },
                        }}
                        id={`${key}Total`}
                        errors={errors}
                        labelComponent={
                          <div className="col-md-4">
                            <label>
                              {key
                                .replace('time', '')
                                .replace(/(\d{4})(\d{4})/, '$1-$2')}
                            </label>
                            <p>
                              free {value.free}/ used {value.used}
                            </p>
                          </div>
                        }
                        // value={value.total}
                        disabled={isUpdating}
                      />
                    </div>
                  )
                })}
              </div>
              <table className="table">
                <thead>
                  <tr>
                    <th scope="col" />
                    <th scope="col">Free</th>
                    <th scope="col">Used</th>
                    <th scope="col">Total</th>
                  </tr>
                </thead>
                <tbody>
                  {['morning', 'afternoon', 'evening'].map((dTime) => (
                    <tr key={dTime}>
                      <td>{startCase(dTime)}</td>
                      <td>{data[dTime]?.free}</td>
                      <td>{data[dTime]?.used}</td>
                      <td>{data[dTime]?.total}</td>
                    </tr>
                  ))}
                  <tr>
                    <td />
                    <td />
                    <td />
                    <td>
                      <strong>{data?.totalOfADay}</strong>
                    </td>
                  </tr>
                </tbody>
              </table>
              <h3>Total {data?.totalOfADay}</h3>
              <div className="d-flex">
                <Button type="submit" className="ms-auto">
                  Save
                </Button>
              </div>
            </form>
          </div>
        )}
      </main>
    </div>
  )
}
