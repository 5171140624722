import { GoogleMap, MarkerF } from '@react-google-maps/api'
import React, { useMemo } from 'react'
import { Spinner } from 'react-bootstrap'
import { useSelector } from 'react-redux'
import { useGetWebFleetResponse } from '../../../../helper/hooks/api/allocations'
import { WarningIcon } from '../../../../icons'
import { selectSelectedDriver } from '../../../../store/slices/allocation/drivers'

export default function VehicleLocationMap() {
  const selectedDriver = useSelector(selectSelectedDriver)

  const vehicleTruckId = selectedDriver?.vehicleTruckDTO?.vehicleTruckId

  const { data, isLoading, error } = useGetWebFleetResponse(vehicleTruckId)

  const position = useMemo(() => {
    if (data && !data?.webFleetErrorCode)
      return {
        lat: data?.posLattitude / 1e6,
        lng: data?.posLongitude / 1e6,
      }

    return {
      lat: -33.865143,
      lng: 151.2099,
    }
  }, [data])

  const containerStyle = {
    width: '100%',
    height: '60vh',
  }

  return (
    <div>
      {isLoading && <Spinner />}
      {error && (
        <p className="text-danger">Error occurred while fetching location</p>
      )}
      {data?.webFleetErrorCode && (
        <p className="text-danger d-flex align-items-center gap-2">
          <WarningIcon />
          {data.webFleetErrorMsg}
        </p>
      )}
      <GoogleMap mapContainerStyle={containerStyle} center={position} zoom={15}>
        {data && !data?.webFleetErrorCode && !isLoading && (
          <MarkerF position={position} />
        )}
      </GoogleMap>
    </div>
  )
}
