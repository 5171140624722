import React, { useMemo } from 'react'
import { Container, Spinner } from 'react-bootstrap'
import { useSelector } from 'react-redux'
import Table from '../../../../components/Common/Tanstack-table/Table'
import ContainerWithHeading from '../../../../components/styles/Containers/ContainerWithHeading'
import { createTableColumnsFromObjKeys } from '../../../../components/utils/Utils'
import { useGetEdoOrders } from '../../../../helper/hooks/api/cerebro'
import { getCurrentRouteData } from '../../../../store/slices/route-slice'
import EdoInputCell from './components/EdoInputCell'
import { useForm } from 'react-hook-form'
import EdoOrdersListFiltersForm from './components/EdoOrdersListFiltersForm'

const edoOrdersFiltersFormDefault = {
  containerNo: '',
  fromDate: '',
  grossWeight: 0,
  mtDehireCode: '',
  sealNo: '',
  sizeType: '',
  toDate: '',
  vesselId: '',
  voyageNo: '',
  wharfJobType: '',
}

export default function EdoAutomationPage() {
  const form = useForm({
    defaultValues: edoOrdersFiltersFormDefault,
  })

  const { watch } = form

  const formValues = watch()

  const route = useSelector(getCurrentRouteData)
  const {
    data: responseData,
    isLoading,
    error,
    isFetching,
  } = useGetEdoOrders(formValues)

  const columns = useMemo(() => {
    if (!Array.isArray(responseData?.data) || responseData.data.length === 0)
      return []
    const columns = createTableColumnsFromObjKeys(responseData?.data)
    columns.unshift({
      header: 'EDO',
      id: 'edo-actions',
      cell: (cell) => <EdoInputCell cell={cell} />,
    })
    return columns
  }, [responseData?.data])

  if (isLoading) return <Spinner />

  if (error)
    return <p className="text-danger">Error occurred while fetching edo data</p>

  return (
    <Container fluid>
      <ContainerWithHeading heading={route?.name}>
        {isFetching && <Spinner />}
        <EdoOrdersListFiltersForm form={form} />
        {responseData?.data?.length === 0 && <p>No data found</p>}
        {Array.isArray(responseData?.data) && (
          <Table data={responseData?.data} columns={columns} />
        )}
      </ContainerWithHeading>
    </Container>
  )
}
