import { yupResolver } from '@hookform/resolvers/yup'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import capitalize from 'lodash/capitalize'
import React, { Fragment } from 'react'
import { Button, Col, Form, Spinner } from 'react-bootstrap'
import { useFieldArray, useForm } from 'react-hook-form'
import * as Yup from 'yup'
import Switch from '../../../../../components/Common/Form/Fields/Switch'
import { Info, Success } from '../../../../../components/utils/Toastify'
import fetchService from '../../../../../components/utils/fetchService'

const validationSchema = Yup.object().shape({
  rules: Yup.array().of(
    Yup.object().shape({
      rule: Yup.string().required('Rule is required'),
      enable: Yup.boolean().required(),
    })
  ),
})

const initialObj = {
  rule: '',
  enable: false,
}

export default function OtherRules({ entity, entityId }) {
  const entityIdKey = `cc${capitalize(entity)}AddressId`
  const uniqueRuleIdKey = `cc${capitalize(entity)}OtherRulesId`
  const queryClient = useQueryClient()

  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    reset,
  } = useForm({
    resolver: yupResolver(validationSchema),
    reValidateMode: 'onChange',
    defaultValues: { rules: [initialObj] },
  })

  useQuery({
    queryKey: [
      `/customercard/${entity}-other-rules?${entityIdKey}=${entityId}`,
    ],
    queryFn: async () =>
      await fetchService({
        url: `/customercard/${entity}-other-rules?${entityIdKey}=${entityId}`,
      }).then((response) => {
        if (Array.isArray(response) && response.length > 0) {
          const data = {
            rules: response,
          }
          reset(data)
          return data
        }
        return response
      }),
    refetchOnMount: 'always',
    enabled: !!entityId,
  })

  const {
    fields: rules,
    append: addRules,
    remove: removeRules,
  } = useFieldArray({
    name: 'rules',
    control,
  })

  const postMutation = useMutation({
    mutationFn: async (body) =>
      await fetchService({
        url: `/customercard/${entity}-other-rules/multi`,
        method: 'POST',
        body,
      }),
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: [
          `/customercard/${entity}-other-rules?${entityIdKey}=${entityId}`,
        ],
      })
      Success('Details saved successfully')
    },
  })

  const onSubmitFunction = (values) => {
    const body = values.rules.map((r) => ({
      ...r,
      [entityIdKey]: entityId,
    }))
    postMutation.mutate(body)
  }

  const deleteMutation = useMutation({
    mutationFn: async ({ id }) =>
      await fetchService({
        url: `/customercard/${entity}-other-rules/${id}`,
        method: 'DELETE',
        isFile: true,
      }),
    onSuccess: (response, variables) => {
      if (response.status === 200) {
        removeRules(variables.index)
      }
      Info('Rule deleted successfully.')
    },
  })

  const deleteRule = (rule, index) => {
    const id = rule?.[uniqueRuleIdKey]
    if (id) {
      let answer = window.confirm('Are you sure to delete Rule ' + id)
      if (!answer) {
        return false
      }
      deleteMutation.mutate({ id, index })
    } else {
      removeRules(index)
    }
  }

  return (
    <div>
      <h2>Other Rules</h2>
      <form autoComplete="off" onSubmit={handleSubmit(onSubmitFunction)}>
        {rules.map((field, index) => (
          <Fragment key={field.id}>
            <div className="d-flex align-items-center gap-3">
              <Form.Group
                className="mb-3"
                as={Col}
                md={6}
                controlId={`rules.${index}.rule`}
              >
                <Form.Label>Rule {index + 1}</Form.Label>
                <Form.Control
                  type="text"
                  {...register(`rules.${index}.rule`)}
                  autoComplete="off"
                />
                {errors?.rules?.[index]?.rule && (
                  <Form.Text className="text-danger">
                    {errors?.rules?.[index]?.rule.message}
                  </Form.Text>
                )}
              </Form.Group>
              <Switch
                label="Enable"
                register={register}
                errors={errors}
                name={`rules.${index}.enable`}
                formGroupProps={{ className: 'm-0' }}
              />
              <Button
                variant="danger"
                size="sm"
                onClick={() => deleteRule(field, index)}
              >
                {deleteMutation.isLoading &&
                deleteMutation.variables?.index === index ? (
                  <Spinner />
                ) : (
                  'Delete'
                )}
              </Button>
            </div>
            {index !== rules.length - 1 && <hr className="my-2" />}
          </Fragment>
        ))}
        <div className="d-flex justify-content-end mt-3">
          <Button variant="gold" onClick={() => addRules(initialObj)}>
            Add New Rule
          </Button>
        </div>
        <Button type="submit" disabled={postMutation.isLoading}>
          {postMutation.isLoading ? <Spinner /> : 'Submit'}
        </Button>
      </form>
    </div>
  )
}
