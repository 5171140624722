import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import React, { useCallback, useMemo } from 'react'
import { Button, Modal, Spinner } from 'react-bootstrap'
import isEmpty from 'lodash/isEmpty'
import startCase from 'lodash/startCase'
import { useForm } from 'react-hook-form'
import { Info, Success } from '../../../../components/utils/Toastify'
import fetchService from '../../../../components/utils/fetchService'
import Table from '../../../../components/Common/Tanstack-table/Table'
import { ICONS } from '../../../../components/Common/Icons'
import config from '../../../../config'

const { containerNotes } = config.api

const NOTE_FIELD = 'notes'

export default function LoadNotesModal({ show, handleClose, load }) {
  const queryClient = useQueryClient()

  const { data, isLoading } = useQuery({
    queryKey: [containerNotes, load?.containerStageTrackerId],
    queryFn: async () =>
      await fetchService({
        url: `${containerNotes}/${load?.containerStageTrackerId}`,
      }),
    enabled: !!load?.containerStageTrackerId && show,
    staleTime: 60_000 * 2,
  })

  const defaultValues = {
    [NOTE_FIELD]: '',
  }

  const {
    register,
    handleSubmit,
    reset,
    watch,
    formState: { errors },
  } = useForm({
    defaultValues,
  })

  const selectedContainerStageTrackerNoteId = watch(
    'containerStageTrackerNoteId'
  )

  const saveNoteMutation = useMutation({
    mutationKey: ['saveNote'],
    mutationFn: async (body) => {
      let url = containerNotes
      let method = 'POST'
      if (body?.containerStageTrackerNoteId) {
        url += `/${body?.containerStageTrackerNoteId}`
        method = 'PUT'
      }
      return await fetchService({
        url,
        method,
        body,
      })
    },
    onSuccess: async (_, body) => {
      await queryClient.refetchQueries({
        queryKey: [containerNotes, load?.containerStageTrackerId],
      })
      let msg = body?.containerStageTrackerNoteId
        ? 'Note Updated successfully'
        : 'Note Added successfully'
      Success(msg)
      reset(defaultValues)
    },
  })

  const deleteNoteMutation = useMutation({
    mutationKey: ['deleteNote'],
    mutationFn: async (containerStageTrackerNoteId) =>
      await fetchService({
        url: `${containerNotes}/${containerStageTrackerNoteId}`,
        method: 'DELETE',
        isFile: true,
      }),
    onSuccess: async () => {
      await queryClient.refetchQueries({
        queryKey: [containerNotes, load?.containerStageTrackerId],
      })
      Info('Note Deleted successfully')
      reset(defaultValues)
    },
  })

  const handleActionClick = useCallback(
    (action, row) => {
      switch (action) {
        case 'edit':
          reset({
            [NOTE_FIELD]: row.notes,
            containerStageTrackerNoteId: row.containerStageTrackerNoteId,
          })
          break

        case 'delete':
          deleteNoteMutation.mutate(row.containerStageTrackerNoteId)
          break

        default:
          break
      }
    },
    [deleteNoteMutation, reset]
  )

  const columns = useMemo(() => {
    if (!isEmpty(data)) {
      const cols = Object.keys(data[0]).map((key) => ({
        header: startCase(key),
        accessorKey: key,
        id: key,
      }))
      cols.push({
        header: 'Actions',
        id: 'actions',
        cell: ({ row }) => (
          <div className="d-flex ">
            <button
              title="edit"
              className="btn p-1 me-1"
              onClick={() => {
                handleActionClick('edit', row.original)
              }}
            >
              {ICONS.EDIT}
            </button>
            <button
              title="delete"
              className="btn p-1 me-1 lh-1"
              onClick={() => {
                handleActionClick('delete', row.original)
              }}
            >
              {ICONS.DELETE}
            </button>
          </div>
        ),
      })
      return cols
    }
    return []
  }, [data, handleActionClick])

  const onSubmit = (values) => {
    saveNoteMutation.mutate({
      ...values,
      containerStageTrackerId: load?.containerStageTrackerId,
    })
  }

  if (!load) return null
  return (
    <Modal show={show} onHide={handleClose} size="xl" centered>
      <Modal.Header closeButton>
        <Modal.Title>Add Notes</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <h5>Add New Note</h5>
        <form onSubmit={handleSubmit(onSubmit)}>
          <textarea
            {...register(NOTE_FIELD, { required: true })}
            className="form-control"
            disabled={saveNoteMutation.isLoading}
          />
          {errors?.[NOTE_FIELD] && (
            <p className="text-danger">This field is required</p>
          )}
          {selectedContainerStageTrackerNoteId ? (
            <Button
              disabled={saveNoteMutation.isLoading}
              type="button"
              onClick={() => reset(defaultValues)}
              className="mt-1 me-1"
              variant="secondary"
            >
              Cancel
            </Button>
          ) : null}

          <Button
            disabled={saveNoteMutation.isLoading}
            type="submit"
            className="mt-1"
          >
            {selectedContainerStageTrackerNoteId ? 'Update' : 'Submit'}
          </Button>
        </form>

        <hr />
        <h5>Notes History</h5>
        {isLoading && <Spinner style={{ color: 'aqua' }} />}

        {isEmpty(data) && <p className="text-info">No History found</p>}

        {!isEmpty(data) && (
          <Table
            inheritTableWidth
            showTableBottom={false}
            dontShowFilters
            columns={columns}
            data={data}
          />
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  )
}
