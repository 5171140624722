import React from 'react'
import { Spinner } from 'react-bootstrap'
import { useUpdateStageMovReqDateAndTimeFieldMutation } from '../../../../../helper/hooks/api/allocations'

export default function MovReqDateField({ cell, id, type }) {
  const {
    row: { original },
  } = cell

  const { mutate, isPending, value } =
    useUpdateStageMovReqDateAndTimeFieldMutation(original, id)

  const handleOnChange = async (e) => {
    const value = e.target.value
    mutate({ value, type })
  }

  return (
    <div className="h-100 d-flex align-items-center">
      {isPending ? (
        <Spinner />
      ) : (
        <input
          className="unset-all w-100"
          type={type}
          value={value}
          onChange={handleOnChange}
        />
      )}
    </div>
  )
}
