import { yupResolver } from '@hookform/resolvers/yup'
import axios from 'axios'
import format from 'date-fns/format'
import React, { useEffect, useState } from 'react'
import Tab from 'react-bootstrap/Tab'
import Tabs from 'react-bootstrap/Tabs'
import { useForm } from 'react-hook-form'
import { toast } from 'react-toastify'
import * as Yup from 'yup'
import Spinner from '../../../../components/Spinner/Spinner'
import { TextBox } from '../../../../components/utils/TextBox'
import config from '../../../../config'
import { slotSummaryTableCols } from './data'
import PortSlot from './components/PortSlot'
import Table from '../../../../components/Common/Tanstack-table/Table'

const OrderSlotManagement = () => {
  // document.body.setAttribute('data-class', 'data-mgmt')
  const [loading, setLoading] = useState(false)
  const [summaryData, setSummaryData] = useState([])

  const [slotDate, setSlotDate] = useState()

  const validationSchema = Yup.object().shape({
    //  hcode: Yup.string().required("Holdd code is required"),
  })

  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
    mode: 'onSubmit',
    reValidateMode: 'onChange',
  })

  const onSubmitFunction = (formData) => {}

  const fetchSummary = () => {
    setLoading(true)
    const url = config.api.baseUrl + config.api.summaryDisplay
    axios({
      method: 'POST',
      url: url,
      data: {
        maxResults: 10,
        pageNumber: 1,
        slotDate: slotDate,
      },
      headers: {
        Authorization: 'Bearer ' + localStorage.token,
      },
    })
      .then(function (response) {
        setSummaryData(response.data.data)
        if (response?.data?.data?.length === 0) {
          toast.warn('No Data found!')
        }
      })
      .catch(function (error) {
        toast.error(error?.response?.data?.error ?? 'Something went wrong!')
      })
      .finally(() => {
        setLoading(false)
      })
  }

  useEffect(() => {
    fetchSummary()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div className="container-fluid">
      {loading && <Spinner />}
      <div className="row">
        <div className="col-md-12">
          <div className="card" style={{ marginBottom: 100 }}>
            <div className="card-header py-0">
              <h2>Port Planning Portal</h2>
            </div>
            <div className="card-body p-1">
              <Tabs
                defaultActiveKey="slot"
                transition={false}
                id="noanim-tab-example"
                className="mb-3"
              >
                <Tab eventKey="summary" title="Summary Data Display">
                  <form className="search-container">
                    <label>Search By Slot Date</label>
                    <input
                      id="slot-date"
                      className="form-control"
                      placeholder="Slot Date"
                      type="date"
                      disabled={loading}
                      value={slotDate}
                      onChange={(e) => {
                        const inputDate = new Date(e.target.value)
                        const year = inputDate.getFullYear()

                        if (year.toString().length <= 4) {
                          setSlotDate(
                            e.target.value
                              ? format(inputDate, 'yyyy-MM-dd')
                              : ''
                          )
                        }
                      }}
                    />
                    <button
                      type="button"
                      className="btn btn-primary"
                      disabled={!slotDate}
                      onClick={() => {
                        fetchSummary()
                      }}
                    >
                      Search
                    </button>
                  </form>

                  <Table
                    className="table table-hover"
                    data={summaryData}
                    columns={slotSummaryTableCols}
                  />
                </Tab>
                <Tab eventKey="slot" title="Port Slot">
                  <PortSlot />
                </Tab>
                <Tab eventKey="status" title="Hold Status">
                  <form
                    autoComplete="off"
                    onSubmit={handleSubmit(onSubmitFunction)}
                  >
                    <div className="row">
                      <div className="col-md-4">
                        <label for="hcode">Hold Code :</label>
                        <TextBox
                          register={register}
                          id="hcode"
                          errors={errors}
                        />
                      </div>
                      <div className="col-md-4">
                        <label for="hdatetime">Hold Date Time :</label>
                        <TextBox
                          register={register}
                          id="hdatetime"
                          errors={errors}
                        />
                      </div>
                      <div className="col-md-4">
                        <label for="hnotes">Hold Notes :</label>
                        <TextBox
                          register={register}
                          id="hnotes"
                          errors={errors}
                        />
                      </div>
                      <div className="col-md-4">
                        <label for="husers">Hold Users :</label>
                        <TextBox
                          register={register}
                          id="husers"
                          errors={errors}
                        />
                      </div>
                      <div className="col-md-4">
                        <label for="rdate">Release Date Time :</label>
                        <TextBox
                          register={register}
                          id="rdate"
                          errors={errors}
                        />
                      </div>
                      <div className="col-md-4">
                        <label for="ruser">Release User :</label>
                        <TextBox
                          register={register}
                          id="ruser"
                          errors={errors}
                        />
                      </div>
                    </div>
                    <br />
                    <div className="row">
                      <div className="col-md-12">
                        <button type="button" class="btn btn-secondary">
                          Reset
                        </button>
                        <button type="submit" class="btn btn-primary">
                          Save
                        </button>
                      </div>
                    </div>
                  </form>
                </Tab>
              </Tabs>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
export default OrderSlotManagement
