import React from 'react'
import { ICONS } from '../../../../../components/Common/Icons'
import { useState } from 'react'
import { useMemo } from 'react'
import { createTableColumnsFromKeysArr } from '../../../../../components/utils/Utils'
import { useCallback } from 'react'
const defaultSelectedValues = {
  index: null,
  data: null,
}

export default function useTableColumnsWithEditDelete({ fields, setDetails }) {
  const [selected, setSelected] = useState(defaultSelectedValues)

  const unSelect = () => {
    setSelected(defaultSelectedValues)
  }

  const handleColumnActions = useCallback(
    (action, row) => {
      switch (action) {
        case 'edit':
          setSelected({ data: row.original, index: row.index })
          break
        case 'delete':
          setDetails((prev) => prev.toSpliced(row.index, 1))
          break

        default:
          break
      }
    },
    [setDetails]
  )

  const columns = useMemo(() => {
    const cols = createTableColumnsFromKeysArr(fields)
    cols.unshift({
      header: 'Actions',
      id: 'actions',
      cell: ({ row }) => (
        <div className="d-flex gap-2">
          <button
            className="btn btn-sm"
            onClick={() => handleColumnActions('edit', row)}
          >
            {ICONS.EDIT}
          </button>
          <button
            className="btn btn-sm"
            onClick={() => handleColumnActions('delete', row)}
          >
            {ICONS.DELETE}
          </button>
        </div>
      ),
    })
    return cols
  }, [fields, handleColumnActions])

  return [columns, selected, unSelect]
}
