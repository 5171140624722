import React, { useState, useEffect } from 'react'
import ImportCRD from './ImportCRD'
import CopyCRD from './CopyCRD'
import axios from 'axios'
import AddCRDData from './AddCRDData'
import config from '../../../config'
import Spinner from '../../Spinner/Spinner'
import AddCRD from './AddCRD'
import CRDTable from './CRDTable'
import AgreedRates from '../AgreedRates/AgreedRates'
import RateReviewDate from '../RateReviewDate/RateReviewDate'

const CRD = (props) => {
  const { lookupsList, customerCardId } = props
  const [csvArray, setCsvArray] = useState({ data: [], igniter: new Date() })
  const [crdData, setCrdData] = useState({ data: [], igniter: new Date() })
  const [triggerGetCRDList, setTriggerGetCRDList] = useState(null)
  const [showAddSuburb, setShowAddSuburb] = useState(false)
  const [showCopyZone, setShowCopyZone] = useState(false)
  const [showCopyAgreedRates, setShowCopyAgreedRates] = useState(false)
  const [showImportZone, setShowImportZone] = useState(true)
  const [enableForm, setEnableForm] = useState(false)
  const [suburbFormData, setSuburbFormData] = useState({
    wharfJobType: '',
    stageMovementType: '',
    vehicleJobType: '',
    rateCode: '',
    description: '',
    pickUpFrom: '',
    deliverTo: '',
    dimensionUnit: '',
    typeSize: '',
    sellRate: '',
    weightBar: '',
    fromRange: '',
    toRange: '',
    handling: '',
    jobWindow: '',
    consignee: '',
    wharf: '',
    emptyDehire: '',
    remarks: '',
  })

  const [profileFormType, setProfileFormType] = useState('add')
  const [profileFormId, setProfileFormId] = useState(null)

  const [selectedCRDId, setSelectedCRDId] = useState(null)

  const [enableSpinner, setEnableSpinner] = useState(false)
  const [showSuccessMsg, setShowSuccessMsg] = useState(false)
  const [showError, setShowError] = useState(false)

  const [formError, setFormError] = useState(false)
  const [consigneeData, setConsigneeData] = useState([])

  const showAgreedRates = (crdId, b, c, d) => {
    setShowCopyAgreedRates(true)
    setSelectedCRDId(crdId)
  }

  useEffect(() => {
    setShowImportZone(false)
  }, [])

  useEffect(() => {
    setShowCopyAgreedRates(true)
    setEnableSpinner(true)
    const url =
      config.api.baseUrl +
      '/customercard/rate-detail?customerCardid=' +
      customerCardId
    const requestOptions = {
      method: 'GET',
      headers: { Authorization: 'Bearer ' + localStorage.token },
    }
    fetch(url, requestOptions)
      .then((response) => response.json())
      .then((data) => {
        setCsvArray({ data: [], igniter: new Date() })
        setCrdData({ data: data, igniter: new Date() })
        setEnableSpinner(false)
      })
      .catch(() => {
        setEnableSpinner(false)
      })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [triggerGetCRDList])

  useEffect(() => {
    const url =
      config.api.baseUrl +
      '/customercard/consignee-address/?customerCardId=' +
      customerCardId
    const requestOptions = {
      method: 'GET',
      headers: { Authorization: 'Bearer ' + localStorage.token },
    }
    fetch(url, requestOptions)
      .then((response) => response.json())
      .then((data) => {
        setConsigneeData(data)
      })

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const AddMultiZones = (_e, type) => {
    setFormError(false)

    let formData = csvArray.data
    formData.forEach((_, i) => {
      formData[i].customerCardId = customerCardId
    })

    setEnableSpinner(true)

    let formattedData = []
    formData.forEach((eachRow) => {
      let eachFormattedData = {}
      Object.keys(eachRow).forEach((item) => {
        let itemFormatted = eachRow[item]
        if (itemFormatted === '') {
          itemFormatted = null //::TODO:: is this mandatory to make null
        } else if (
          itemFormatted &&
          typeof itemFormatted === String &&
          itemFormatted.startsWith('$')
        ) {
          itemFormatted = item.replace('$', '')
        } else if (itemFormatted === 'NULL') {
          itemFormatted = null //::TODO:: is this mandatory to make null
        }

        if (['fromRange', 'sellRate', 'toRange'].includes(item)) {
          itemFormatted = Number(itemFormatted)
        }

        eachFormattedData[item] = itemFormatted
      })
      formattedData.push(eachFormattedData)
    })

    const url = config.api.baseUrl + '/customercard/rate-detail/multi'
    setShowError(false)
    setShowSuccessMsg(false)
    axios({
      method: 'POST', //you can set what request you want to be
      url: url,
      data: formattedData,
      headers: {
        Authorization: 'Bearer ' + localStorage.token,
      },
    })
      .then(function () {
        setShowSuccessMsg(true)
        setEnableSpinner(false)
        setTriggerGetCRDList(new Date())
      })
      .catch(function (error) {
        if (error?.response?.data?.data) {
          setShowError(
            typeof error.response.data.data !== 'string'
              ? error.response.data.data
              : JSON.parse(error.response.data.data)
          )
        } else {
          setShowError(true)
        }
        setEnableSpinner(false)
      })
  }

  const enableAddSuburb = () => {
    setShowAddSuburb(true)
    setShowImportZone(false)
    setShowCopyZone(false)
    setCsvArray({ data: [], igniter: new Date() })
    setShowError(false)
    setShowSuccessMsg(false)
  }

  const enableImportSuburb = () => {
    setShowAddSuburb(false)
    setShowImportZone(true)
    setShowCopyZone(false)
    setCsvArray({ data: [], igniter: new Date() })
    setShowError(false)
    setShowSuccessMsg(false)
  }

  const enableCopySuburb = () => {
    setShowAddSuburb(false)
    setShowImportZone(false)
    setShowCopyZone(true)
    setShowError(false)
    setShowSuccessMsg(false)
    setCsvArray({ data: [], igniter: new Date() })
    setCrdData({ data: [], igniter: new Date() })
  }

  return (
    <div>
      {props.type !== 'view' && (
        <React.Fragment>
          <div className="row">
            <div className="col-12 mb-4">
              <button
                className="btn btn-primary mb-4 me-3 btn-block2"
                onClick={() => enableAddSuburb()}
                disabled={showAddSuburb}
              >
                Add CRD
              </button>
              <button
                className="btn btn-primary mb-4 me-3 btn-block2"
                onClick={() => enableImportSuburb()}
                disabled={showImportZone}
              >
                Import CRD
              </button>
              <button
                className="btn btn-primary mb-4 btn-block2"
                onClick={() => enableCopySuburb()}
                disabled={showCopyZone}
              >
                Copy CRD
              </button>
            </div>
            {showImportZone && (
              <div className="col-12">
                <ImportCRD
                  customerCardId={customerCardId}
                  AddMultiZones={AddMultiZones}
                  setCsvArray={setCsvArray}
                  csvArray={csvArray}
                  formError={formError}
                  showError={showError}
                  showSuccessMsg={showSuccessMsg}
                  setSuburbFormData={setSuburbFormData}
                  setEnableForm={setEnableForm}
                  setProfileFormType={setProfileFormType}
                  setProfileFormId={setProfileFormId}
                  {...props}
                />
              </div>
            )}

            {showAddSuburb && (
              <div className="col-12">
                <AddCRD
                  lookupsList={lookupsList}
                  customerCardId={customerCardId}
                  AddMultiZones={AddMultiZones}
                  setCsvArray={setCsvArray}
                  setShowSuccessMsg={setShowSuccessMsg}
                  csvArray={csvArray}
                  formError={formError}
                  showError={showError}
                  showSuccessMsg={showSuccessMsg}
                  setEnableForm={setEnableForm}
                  enableForm={enableForm}
                  suburbFormData={suburbFormData}
                  setSuburbFormData={setSuburbFormData}
                  setProfileFormId={setProfileFormId}
                  setProfileFormType={setProfileFormType}
                  showAgreedRates={showAgreedRates}
                  {...props}
                />
              </div>
            )}

            {showCopyZone && (
              <div className="col-12">
                <CopyCRD
                  customerCardId={customerCardId}
                  AddMultiZones={AddMultiZones}
                  setCsvArray={setCsvArray}
                  setCrdData={setCrdData}
                  csvArray={csvArray}
                  formError={formError}
                  showError={showError}
                  showSuccessMsg={showSuccessMsg}
                  setEnableForm={setEnableForm}
                  setProfileFormType={setProfileFormType}
                  setProfileFormId={setProfileFormId}
                  setSuburbFormData={setSuburbFormData}
                  {...props}
                />
              </div>
            )}
          </div>
        </React.Fragment>
      )}

      <div className="CRDTable-wpr">
        {crdData.data && crdData.data.length > 0 && (
          <CRDTable
            csvArray={crdData}
            setSuburbFormData={setSuburbFormData}
            setEnableForm={setEnableForm}
            setProfileFormId={setProfileFormId}
            setProfileFormType={setProfileFormType}
            setCsvArray={setCrdData}
            type={props.type}
            showAgreedRates={showAgreedRates}
          />
        )}
      </div>
      {crdData.data.length === 0 && <p>No records found for this customer</p>}

      {enableForm && (
        <AddCRDData
          lookupsList={lookupsList}
          defaultData={suburbFormData}
          setSuburbFormData={setSuburbFormData}
          csvArray={crdData}
          setCsvArray={setCrdData}
          setEnableForm={setEnableForm}
          profileFormType={profileFormType}
          profileFormId={profileFormId}
          consigneeData={consigneeData}
          {...props}
        />
      )}

      {showCopyAgreedRates && (
        <>
          <AgreedRates
            profileFormId={profileFormId}
            setEnableSpinner={setEnableSpinner}
            {...props}
            additionalInfo={{ selectedCRDId, customerCardId }}
          />
        </>
      )}

      {props.type === 'view' && <RateReviewDate {...props} />}

      {showSuccessMsg && (
        <p className="text-success my-4">Form has submitted successfully!</p>
      )}

      {enableSpinner && <Spinner />}
    </div>
  )
}

export default CRD
