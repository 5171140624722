import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import capitalize from 'lodash/capitalize'
import isEmpty from 'lodash/isEmpty'
import React, { useCallback, useMemo, useState } from 'react'
import { Spinner } from 'react-bootstrap'
import Table from '../../../../../components/Common/Tanstack-table/Table'
import { createTableColumnsFromKeysArr } from '../../../../../components/utils/Utils'
import fetchService from '../../../../../components/utils/fetchService'
import EntityDetails from './EntityDetails'
import EntityOptionsModal from './EntityOptionsModal'
import { Info } from '../../../../../components/utils/Toastify'

const defaultSelectedValues = {
  index: null,
  data: null,
}

const columnKeys = ['postCodeId', 'customerCodeConfirmation', 'suburb']

const modals = {
  edit: 'edit',
  options: 'options',
  close: '',
}

export default function AddressBookManager({ customerCardId, type, role }) {
  const entityIdKey = `cc${capitalize(role)}AddressId`
  const queryClient = useQueryClient()
  const [selected, setSelected] = useState(defaultSelectedValues)
  const [modal, setModal] = useState('')

  const { data, isLoading, error } = useQuery({
    queryKey: ['customer', `${role}-address`, customerCardId],
    queryFn: async () =>
      await fetchService({
        url: `/customercard/${role}-address/?customerCardId=${customerCardId}`,
      }),
    enabled: !!customerCardId,
  })

  const mutation = useMutation({
    mutationFn: async (body) =>
      await fetchService({
        method: 'DELETE',
        isFile: true,
        url: `/customercard/${role}-address/${body[entityIdKey]}`,
      }),
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: ['customer', `${role}-address`, customerCardId],
      })
      Info('Deleted successfully!')
    },
  })

  const handleAddNewEntity = () => {
    setModal(modals.edit)
    setSelected(defaultSelectedValues)
  }

  const handleColumnActions = useCallback(
    (action, row) => {
      switch (action) {
        case 'edit':
          setModal(modals.edit)
          setSelected({ data: row.original, index: row.index })
          break

        case 'options':
          setModal(modals.options)
          setSelected({ data: row.original, index: row.index })
          break

        case 'delete':
          const rowData = row.original

          let answer = window.confirm(
            'Are you sure to delete suburb profile id - ' + rowData[entityIdKey]
          )
          if (!answer) return false
          mutation.mutate(rowData)
          break

        default:
          break
      }
    },
    [entityIdKey, mutation]
  )

  const columns = useMemo(() => {
    const cols = createTableColumnsFromKeysArr([`${role}Name`, ...columnKeys])

    cols.unshift({
      header: 'Actions',
      id: 'actions',
      minSize: 225,
      cell: ({ row }) => (
        <div className="d-flex gap-1">
          <button
            type="button"
            className="btn btn-primary btn-sm"
            onClick={() => handleColumnActions('edit', row)}
          >
            {type === 'create' ? 'Edit' : 'View'}
          </button>
          <button
            type="button"
            className="btn btn-primary btn-sm ms-2 text-nowrap"
            onClick={() => handleColumnActions('options', row)}
          >
            Show Options
          </button>
          <button
            type="button"
            className="btn btn-secondary btn-sm ms-2"
            onClick={() => handleColumnActions('delete', row)}
          >
            Delete
          </button>
        </div>
      ),
    })

    return cols
  }, [handleColumnActions, role, type])

  const handleClose = () => {
    setSelected(defaultSelectedValues)
    setModal(modals.close)
  }

  if (error) return <pre>{JSON.stringify(error, null, 2)}</pre>

  return (
    <div>
      <h4>{capitalize(role)} Address Book</h4>
      {type !== 'view' && (
        <div className="text-end">
          <button className="btn btn-primary mb-2" onClick={handleAddNewEntity}>
            Add {role}
          </button>
        </div>
      )}
      {isLoading && <Spinner />}
      {isEmpty(data) ? (
        <p>No Data found</p>
      ) : (
        <div className="mt-3">
          <Table
            columns={columns}
            data={data}
            showTableBottom={false}
            showGlobalSearch={false}
            showGroupingIcon={false}
          />
        </div>
      )}
      {modal === modals.edit && (
        <EntityDetails
          handleClose={handleClose}
          entity={role}
          type={type}
          selectedDetail={selected.data}
          customerCardId={customerCardId}
        />
      )}
      {modal === modals.options && (
        <EntityOptionsModal
          handleClose={handleClose}
          entity={role}
          type={type}
          selectedDetail={selected.data}
          customerCardId={customerCardId}
        />
      )}
    </div>
  )
}
