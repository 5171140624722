import { useEffect } from 'react'
import { VerticalBar } from '../../../components/Common/Charts'

const YmsDashboard = () => {
  // for chart
  const labels = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
  ]
  const data = {
    labels,
    datasets: [
      {
        label: 'Capacity',
        data: labels.map(() => Math.floor(Math.random() * 1000)),
        backgroundColor: '#127EA6',
        hoverBackgroundColor: '#127EC6',
      },
    ],
  }
  useEffect(() => {
    document.body.setAttribute('data-class', 'clm-container')
    return () => {
      document.body.removeAttribute('data-class')
    }
  }, [])

  return (
    <div className="w-100">
      <div className="header">
        <h6>YMS Dashboard</h6>
      </div>
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-5 columnData m-2 text-left text-dark fs-5">
            <div className="">
              <h3>Summary</h3>
              <div className="row p-2">
                <div className="col-6 text-dark">
                  Total Number of containers(Gen)
                </div>
                <div className="col-6">20</div>
              </div>
              <div className="row p-2">
                <div className="col-6 text-dark">
                  Total Number of containers(Ref)
                </div>
                <div className="col-6">40</div>
              </div>
            </div>

            <div className="mt-5">
              <div className="row p-2">
                <div className="col-6 text-dark">
                  Import Number of containers
                </div>
                <div className="col-6">{`>3days`}</div>
              </div>
              <div className="row p-2">
                <div className="col-6 text-dark">
                  Export Number of containers(Ref)
                </div>
                <div className="col-6">{`>3days`}</div>
              </div>
            </div>
          </div>
          <div className="col-md-6">
            <VerticalBar data={data} />
          </div>
        </div>
      </div>
    </div>
  )
}

export default YmsDashboard
