import React from 'react'
import Select from 'react-select'

const TablePagination = ({
  canPreviousPage,
  canNextPage,
  pageCount,
  pageOptions,
  pageIndex,
  previousPage,
  nextPage,
  gotoPage,
  pageSize,
  setPageSize,
}) => {
  const pageSizeOptions = [200, 400, 600].map((option) => ({
    label: option,
    value: option,
  }))
  return (
    <div className="pagination">
      <button
        className="btn btn-sm btn-light"
        onClick={() => gotoPage(0)}
        disabled={!canPreviousPage}
      >
        &laquo;
      </button>
      <button
        className="btn btn-sm btn-light"
        onClick={() => previousPage()}
        disabled={!canPreviousPage}
      >
        &#8249;
      </button>
      <button
        className="btn btn-sm btn-light"
        onClick={() => nextPage()}
        disabled={!canNextPage}
      >
        &#8250;
      </button>
      <button
        className="btn btn-sm btn-light"
        onClick={() => gotoPage(pageCount - 1)}
        disabled={!canNextPage}
      >
        &raquo;
      </button>
      <span className="page-info">
        Page &nbsp;
        <strong>
          {pageIndex + 1} of {pageOptions.length || 1}
        </strong>
      </span>
      <Select
        isSearchable={false}
        className="form-searchable-select"
        classNamePrefix="form-searchable-select"
        value={pageSizeOptions?.filter((option) => option.value === pageSize)}
        onChange={(val) => {
          setPageSize(Number(val?.value))
        }}
        menuPlacement="auto"
        options={pageSizeOptions}
        getOptionLabel={(option) => `Show ${option.label}`}
      />
    </div>
  )
}

export default TablePagination
