import { useCallback, useEffect, useState } from 'react'

export default function useMediaQuery(query) {
  const [matches, setMatches] = useState(false)

  const handleResize = useCallback(() => {
    const mediaQuery = window.matchMedia(query)
    setMatches(mediaQuery.matches)
  }, [query])

  useEffect(() => {
    handleResize()
    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [handleResize, query])

  return matches
}

export const mobileQuery = '(max-width: 576px)'
