import { faXmark } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import queryString from 'query-string'
import React from 'react'
import Spinner from 'react-bootstrap/Spinner'
import { useHistory, useLocation } from 'react-router-dom'
import { useUserTemplateListData } from '../../../../../helper/hooks/api/allocations'
import { useProfileManager } from '../../context/ProfileManagerContext'

export default function ProfileHistory() {
  const { profileManager, profiles, setProfiles } = useProfileManager()
  const { data: userTemplateList, isLoading, error } = useUserTemplateListData()
  const location = useLocation()
  const query = queryString.parse(location.search)
  const currentActiveTemplate = query['template']
  const history = useHistory()

  const applyProfile = (profile) => {
    if (profile === Number(currentActiveTemplate)) {
      history.replace(location.pathname)
      return
    }
    setProfiles(profileManager.push(profile))
    history.replace(`${location.pathname}?template=${profile}`)
  }

  const removeProfile = (profile) => {
    setProfiles(profileManager.remove(profile))
    history.replace(location.pathname)
  }

  if (profiles.length === 0) return null

  if (isLoading) return <Spinner />

  if (!Array.isArray(userTemplateList) || error) return null

  const IdToProfileMap = userTemplateList.reduce(
    (acc, p) => ({ ...acc, [p.id]: p }),
    {}
  )

  return (
    <div className="d-flex gap-1 mb-2">
      {profiles.map((id) => (
        <div
          className={`d-flex px-2 profile-history-container align-items-center ${
            id === Number(currentActiveTemplate) ? 'active-profile' : ''
          }`}
        >
          <button
            className="unset-all"
            title="Apply"
            onClick={(e) => {
              e.preventDefault()
              applyProfile(id)
            }}
          >
            {IdToProfileMap[id].templateName}
          </button>
          <button
            className="unset-all ms-3"
            title="close"
            onClick={(e) => {
              e.preventDefault()
              removeProfile(id)
            }}
          >
            <FontAwesomeIcon icon={faXmark} />
          </button>
        </div>
      ))}
    </div>
  )
}
