import { useEffect, useState } from 'react'
import config from '../../../../config'

import Spinner from '../../../../components/Spinner/Spinner'

import axios from 'axios'
import { toast } from 'react-toastify'

const { api } = config

const LocationViewEdit = ({ wmsID, getWms, setShowLocationViewEditModal }) => {
  const [loading, setLoading] = useState(false)
  const [locationDetails, setLocationDetails] = useState()
  const [newLocation, setNewLocation] = useState()
  const getLocationById = async (wmsId) => {
    setLoading(true)
    const url = `${api.baseUrl}${api.wms.unpacked.getLocationDetailsById}${wmsId}`
    await axios({
      method: 'GET',
      url: url,
      headers: {
        Authorization: 'Bearer ' + localStorage.token,
      },
    })
      .then(function (response) {
        setLocationDetails(response.data)
      })
      .catch(function (error) {
        toast.error('Something went wrong!')
      })
      .finally(() => {
        setLoading(false)
      })
  }
  useEffect(() => {
    wmsID && getLocationById(wmsID)
    setNewLocation(locationDetails?.location)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const locationDisplayData = [
    { label: 'Last Location', data: locationDetails?.location },
    { label: 'Last Modified By', data: locationDetails?.lastModifiedBy },
    {
      label: 'Last Modified Date',
      data: locationDetails?.lastModifiedDate.slice(0, 10),
    },
    { label: 'Created By', data: locationDetails?.createdBy },
  ]
  const submitNewLocation = () => {
    if (!newLocation) return toast.warn('Please enter a new location')
    setLoading(true)
    const url = `${api.baseUrl}${api.wms.unpacked.setNewLocation}`
    const data = {
      location: newLocation,
      wmsDetailsId: wmsID,
      wmsLocationId: locationDetails?.wmsLocationId
        ? locationDetails.wmsLocationId
        : null,
    }
    axios({
      method: 'POST',
      url: url,
      headers: {
        Authorization: 'Bearer ' + localStorage.token,
      },
      data: { ...data },
    })
      .then(function (response) {
        toast.info('Successully Updated Location')
        setShowLocationViewEditModal && setShowLocationViewEditModal(false)
      })
      .catch(function () {
        toast.error('Something went wrong!')
      })
      .finally(() => {
        getWms('ALL')
        getWms('AWAITING_UNPACK')
        getWms('UNPACKED')
        getWms('APPROVED')
        getWms('DISPATCHED')
        setLoading(false)
      })
  }

  return (
    <>
      <div className="col">
        {loading && <Spinner />}
        <div className="columnData p-2 text">
          {locationDisplayData.map(({ label, data }) => (
            <div className="row mb-2" key={label}>
              <div className="col-6">
                <strong>{label} :</strong>
              </div>
              <div className="col-6">{data}</div>
            </div>
          ))}

          <div className="row mb-2">
            <div className="col-6">
              <strong>New Location :</strong>
            </div>
            <div className="col-6">
              <select
                name="location"
                id="location"
                value={newLocation}
                onChange={(e) => setNewLocation(e.target.value)}
                className={`form-control form-select `}
              >
                <option value="">--select--</option>
                <option value="YARD">Yard</option>
                <option value="WMS">WMS</option>
                <option value="CMS">CMS</option>
              </select>
            </div>
          </div>
        </div>
        <div className="flex-content mt-1">
          <div className=""></div>
          <button
            className="btn btn-primary"
            onClick={() => submitNewLocation()}
            disabled={newLocation === locationDetails?.location}
          >
            Save
          </button>
        </div>
      </div>
    </>
  )
}

export default LocationViewEdit
