import React, { useState } from 'react'
import { useSaveChannelMutation } from '../../../../../helper/hooks/api/allocations'
import { toast } from 'react-toastify'
import { Spinner } from 'react-bootstrap'

export default function ChannelNameCell({ cell }) {
  const [channel, setChannel] = useState(cell.getValue())
  const channelId = cell.row.original.id

  const saveChannelMutation = useSaveChannelMutation()

  const onSubmit = (e) => {
    e.preventDefault()
    e.stopPropagation()
    if (!channel) {
      toast.error('Please enter a channel name')
      return
    }
    saveChannelMutation.mutate({
      channelName: channel,
      id: channelId,
    })
  }

  return (
    <form onSubmit={onSubmit}>
      {saveChannelMutation.variables?.id === channelId &&
      saveChannelMutation.isPending ? (
        <Spinner />
      ) : (
        <input
          type="text"
          className="unset-all"
          value={channel}
          onChange={(e) => setChannel(e.target.value)}
        />
      )}
    </form>
  )
}
