const ZoneTemplate = ({
  zoneCodes,
  eachData,
  csvIndex,
  deleteRow,
  csvArray,
  addRow,
  type,
}) => {
  return (
    <>
      <tr key={eachData.uniqueId}>
        <td width="170">
          <select
            className="form-select m-1"
            defaultValue={eachData.zone}
            name={'zone'}
            disabled={type === 'view'}
          >
            <option value="">Please select</option>
            {zoneCodes &&
              zoneCodes.map((eachItem, i) => {
                return (
                  <option value={eachItem.zoneCode} key={i}>
                    {eachItem.zoneCode}
                  </option>
                )
              })}
          </select>
        </td>
        <td width="170">
          <select
            className="form-select m-1"
            defaultValue={eachData.kms}
            name={'kms'}
            disabled={type === 'view'}
          >
            <option value="">Please Select</option>
            {zoneCodes &&
              zoneCodes.map((eachItem, i) => {
                return (
                  <option value={eachItem.kmRange} key={i}>
                    {eachItem.kmRange}
                  </option>
                )
              })}
          </select>
        </td>
        <td>
          <input
            type="text"
            className="form-control"
            defaultValue={eachData.standard20FtContainer}
            name="standard20FtContainer"
            disabled={type === 'view'}
          />
        </td>
        <td>
          <input
            type="text"
            className="form-control"
            defaultValue={eachData.standard40FtContainer}
            name="standard40FtContainer"
            disabled={type === 'view'}
          />
        </td>
        <td>
          <input
            type="number"
            className="form-control"
            defaultValue={eachData.sideLoader20FtContainer}
            name="sideLoader20FtContainer"
            disabled={type === 'view'}
          />
        </td>
        <td>
          <input
            type="number"
            className="form-control"
            defaultValue={eachData.sideLoader40FtContainer}
            name="sideLoader40FtContainer"
            disabled={type === 'view'}
          />
        </td>
        <td>
          <input
            type="number"
            className="form-control"
            defaultValue={eachData.dropTrailerDeliver20FtContainer}
            name="dropTrailerDeliver20FtContainer"
            disabled={type === 'view'}
          />
        </td>
        <td>
          <input
            type="number"
            className="form-control"
            defaultValue={eachData.dropTrailerDeliver40FtContainer}
            name="dropTrailerDeliver40FtContainer"
            disabled={type === 'view'}
          />
        </td>
        <td>
          <input
            type="text"
            className="form-control"
            defaultValue={eachData.tollsPrice}
            style={{ width: '90px' }}
            name="tollsPrice"
            disabled={type === 'view'}
          />
        </td>
        {type !== 'view' && (
          <td>
            <>
              <button
                type="button"
                className="btn btn-sm btn-danger me-2"
                onClick={(e) => deleteRow(csvIndex)}
              >
                X
              </button>
              {csvArray.data.length - 1 === csvIndex && (
                <button
                  type="button"
                  className="btn btn-sm btn-primary"
                  onClick={(e) => addRow(e)}
                  style={{
                    fontSize: '25px',
                    padding: '0 8px',
                    lineHeight: 1.2,
                  }}
                >
                  +
                </button>
              )}
            </>
          </td>
        )}
      </tr>
    </>
  )
}

export default ZoneTemplate
