import React from 'react'
import startCase from 'lodash/startCase'
import { Col, Form } from 'react-bootstrap'

export default function Switch({
  name,
  label = startCase(name),
  placeholder = label,
  register,
  errors,
  md = 4,
  formGroupProps,
  ...props
}) {
  return (
    <Form.Group
      className="mb-3"
      as={Col}
      md={md}
      controlId={name}
      {...formGroupProps}
    >
      <Form.Check type="switch" {...register(name)} label={label} {...props} />
      {errors?.potentialCustomer && (
        <Form.Text className="text-danger">
          {errors?.potentialCustomer.message}
        </Form.Text>
      )}
    </Form.Group>
  )
}
