import moment from 'moment'
import React, { useEffect } from 'react'
import './OperationsDashboard.scss'

export default function OperationsDashboard() {
  useEffect(() => {
    document.body.parentElement.classList.remove('dark-theme')
    return () => {
      document.body.parentElement.classList.add('dark-theme')
    }
  }, [])

  return (
    <section className="operations-dashboard-wrapper">
      <div className="row p-4 text-dark">
        <div className="col-12 right-section thin-border rounded-3">
          <div className="p-2">
            <section className="d-flex right-section-main-header rounded-3 w-100 p-2">
              <h3>Operations Dashboard</h3>
              <div className="d-flex ms-auto">
                <div className="me-2">
                  <select className="">
                    <option value="all">all</option>
                  </select>
                  <input type="text" />
                  <button className="">
                    <img
                      alt=""
                      src="/assets/operations-dashboard/Search_ICON.png"
                    />
                  </button>
                </div>
                <div>
                  <button className="">View All</button>
                </div>
              </div>
            </section>
            <section>
              <div className="w-100 info-sections-header rounded-3 mt-3">
                <h4 className="p-2">Transport</h4>
              </div>
              <div className="row" style={{ margin: '0 20%' }}>
                {[
                  {
                    img_src:
                      '/assets/operations-dashboard/Export_Container.png',
                    text: 'Export containers Unalloted',
                  },
                  {
                    img_src:
                      '/assets/operations-dashboard/Import_Container.png',
                    text: 'Import containers Unalloted',
                  },
                  {
                    img_src:
                      '/assets/operations-dashboard/Export_Container.png',
                    text: 'Customer Deliveries Pending',
                  },
                  {
                    img_src: '/assets/operations-dashboard/LaterAV_ICON.png',
                    text: 'Import Containers Late AV DATE',
                  },
                ].map(({ img_src, text }, index) => (
                  <div
                    key={index}
                    className="col-md-6 _card"
                    style={{ border: 'none' }}
                  >
                    <div className="card-body">
                      <div className="row">
                        <div className="col-6">
                          <h5 className="card-title">100</h5>
                          <p className="card-text">{text}</p>
                        </div>
                        <div
                          className="col-6"
                          style={{
                            display: 'grid',
                            placeItems: 'center',
                          }}
                        >
                          <img src={img_src} alt="" />
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </section>
            <section>
              <div className="w-100 info-sections-header rounded-3 mt-3">
                <h4 className="p-2">Order Status</h4>
              </div>
              <div className="row" style={{ margin: '0 20%' }}>
                {[0, 1].map((_, i) => (
                  <div
                    key={i}
                    className="col-md-6 m-3 _card"
                    style={{ width: '18rem' }}
                  >
                    <h4>Order Unalloted</h4>
                    <div className="card-body">
                      <img
                        src="/assets/operations-dashboard/Layer 39 copy.png"
                        alt=""
                      />
                      <p className="card-text">Export containers Unalloted</p>
                      <p className="card-text">Export containers Unalloted</p>
                    </div>
                  </div>
                ))}
              </div>
            </section>
            <section>
              <div className="w-100 info-sections-header rounded-3 mt-3">
                <h4 className="p-2">Job Planning 10 days</h4>
              </div>
              <div className="job-planning-table-wrapper">
                <table>
                  <thead>
                    <tr>
                      {[
                        'Date',
                        'Truck',
                        'Trailer Type',
                        'Slot Alotted',
                        'Slot Used',
                        'Slot Available',
                      ].map((headerText, index) => (
                        <th key={index}>{headerText}</th>
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    {[0, 1, 2].map((_i, i) => (
                      <tr key={i}>
                        {[
                          moment().format('DD / MM / YYYY'),
                          'TR34',
                          'Side Loader',
                          '333',
                          '333',
                          '333',
                        ].map((headerText, index) => (
                          <td key={index}>{headerText}</td>
                        ))}
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </section>
          </div>
        </div>
      </div>
    </section>
  )
}
