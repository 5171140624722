// ProfileManager.js
class ProfileManager {
  constructor() {
    const storedProfiles = localStorage.getItem('profiles-history')
    this.profiles = storedProfiles ? JSON.parse(storedProfiles) : []
  }

  push(profile) {
    this.profiles = this.profiles.filter((p) => p !== Number(profile))
    this.profiles.push(Number(profile))
    this.updateLocalStorage()
    return this.profiles
  }

  remove(profile) {
    this.profiles = this.profiles.filter((p) => p !== Number(profile))
    this.updateLocalStorage()
    return this.profiles
  }

  updateLocalStorage() {
    localStorage.setItem('profiles-history', JSON.stringify(this.profiles))
  }
}

export default ProfileManager
