import { useMutation } from '@tanstack/react-query'
import React from 'react'
import fetchService from '../../../../components/utils/fetchService'
import config from '../../../../config'
import { Modal, Spinner } from 'react-bootstrap'
import { toast } from 'react-toastify'
import Map from '../../../../components/GoogleMap/GoogleMap'

export default function StageGeoLocation({ data }) {
  const url = `${config.api.getWebfleetResponse}/${data?.vehicleTruckId}`

  const mutation = useMutation({
    mutationFn: async () => await fetchService({ url }),
  })

  const handleBtnClick = () => {
    if (data?.vehicleTruckId) mutation.mutate()
    else toast.error('Vehicle truck Id not present.')
  }

  const resetMutation = () => mutation.reset()

  const webFleetData = mutation.data

  return (
    <>
      {mutation.isPending ? (
        <Spinner style={{ width: '22px', height: '22px' }} />
      ) : (
        <button
          className="btn btn-sm btn-info"
          type="button"
          onClick={handleBtnClick}
        >
          Geo
        </button>
      )}
      {webFleetData && (
        <Modal show onHide={resetMutation} size="xl" centered>
          <Modal.Header closeButton>
            <Modal.Title>Web-fleet Details</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="row">
              <div className="col-md-3 locationContainer">
                <span>Location</span>
                <p>{webFleetData?.posLocation}</p>
              </div>
              <div className="col-md-3 locationContainer">
                <span>Vehicle Type</span>
                <p>{webFleetData?.vehicleType}</p>
              </div>
              <div className="col-md-3 locationContainer">
                <span>Vehicle Name</span>
                <p>{webFleetData?.displayValue}</p>
              </div>
            </div>
            <Map
              positionProp={{
                lat: webFleetData.posLattitude / 1e6,
                lng: webFleetData.posLongitude / 1e6,
              }}
            />
          </Modal.Body>
        </Modal>
      )}
    </>
  )
}
