import React, { useState } from 'react'
import { Button, Modal } from 'react-bootstrap'

export default function AlertModal({
  message = '',
  handleConfirm,
  children,
  confirmBtnProps,
  confirmBtnText = 'Confirm',
  closeBtnTxt = 'Close',
  closeBtnClick = null,
  centered = false,
}) {
  const [isOpen, setIsOpen] = useState(false)

  const handleClose = () => {
    setIsOpen(false)
  }

  const openModal = () => {
    setIsOpen(true)
  }

  return (
    <>
      {children(openModal)}
      <Modal show={isOpen} onHide={handleClose} centered={centered}>
        <Modal.Header closeButton>
          <Modal.Title>Confirmation</Modal.Title>
        </Modal.Header>
        <Modal.Body>{message}</Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() =>
              closeBtnClick ? closeBtnClick(handleClose) : handleClose()
            }
          >
            {closeBtnTxt}
          </Button>
          <Button
            variant="primary"
            onClick={() => handleConfirm(handleClose)}
            {...confirmBtnProps}
          >
            {confirmBtnText}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}
