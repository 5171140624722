const dimension_unit = {
  name: 'Dimension Unit',
  apiUrlAppend: false,
  path: '/dataMgmt/customer/dimension_unit',
  req: '/',
  columns: 4,
  fields: [
    {
      name: 'ID',
      label: 'ID',
      type: 'text',
      validation: 'Alphabetic',
      fieldId: 'id',
      id: 'id',
      validationType: 'string',
      validations: [
        {
          type: 'required',
          params: ['ID is required'],
        },
        {
          type: 'max',
          params: [15, 'ID cannot be more than 30 characters'],
        },
        {
          type: 'min',
          params: [3, 'ID should be min 3 characters'],
        },
      ],
    },
    {
      name: 'Description',
      label: 'Description',
      type: 'textarea',
      validation: 'Alphabetic',
      fieldId: 'description',
      id: 'description',
      validationType: 'string',
      max: 30,
      validations: [
        {
          type: 'required',
          params: ['Description is required'],
        },
        {
          type: 'max',
          params: [30, "Description can't be more than 30 characters"],
        },
      ],
    },
    {
      name: 'Formula',
      label: 'Formula',
      type: 'text',
      validation: 'Alphabetic',
      fieldId: 'formula',
      id: 'formula',
      validationType: 'string',
      validations: [
        {
          type: 'required',
          params: ['Formula is required'],
        },
        {
          type: 'max',
          params: [30, 'Formula cannot be more than 30 characters'],
        },
        {
          type: 'min',
          params: [3, 'Formula should be min 3 characters'],
        },
      ],
    },
  ],
}
export default dimension_unit
