import React from 'react'
import PlacesAutocomplete, { geocodeByPlaceId } from 'react-places-autocomplete'

const options = {
  componentRestrictions: { country: 'au' },
  types: ['address'],
}
export default class LocationSearchInput extends React.Component {
  constructor(props) {
    super(props)
    this.state = { address: props.value }
  }

  handleChange = (address) => {
    this.setState({ address })
    this.props.setValue('address', '')
  }

  getItemByName = (place, name) => {
    const obj =
      place.address_components.find((c) => c.types.includes(name)) || {}
    const val = obj.short_name ? obj.short_name : ''
    return val
  }

  handleSelect = async (address, placeId) => {
    this.setState({ address })

    const [place] = await geocodeByPlaceId(placeId)

    const addressDetails = {
      address: address,
      suburb: this.getItemByName(place, 'locality'),
      stateCodeId: this.getItemByName(place, 'administrative_area_level_1'),
      postCodeId: this.getItemByName(place, 'postal_code'),
      countryCodeId: this.getItemByName(place, 'country'),
      street:
        this.getItemByName(place, 'street_number') +
        ' ' +
        this.getItemByName(place, 'route'),
    }
    this.props.onSelectAddress(addressDetails)
  }

  render() {
    return (
      <PlacesAutocomplete
        value={this.state.address ?? this.props.value ?? ''}
        onChange={this.handleChange}
        onSelect={this.handleSelect}
        searchOptions={options}
        id="testform"
      >
        {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
          <div>
            <input
              className={`form-control location-search-input input-field ${
                this.props?.errors[this.props.id] ? 'is-invalid' : ''
              }`}
              {...getInputProps({
                placeholder: 'Search Places ...',
              })}
              id="addressField"
            />
            <div className="position-relative">
              <div className="autocomplete-dropdown-container">
                {loading && <div>Loading...</div>}
                {suggestions.map((suggestion, index) => {
                  const className = suggestion.active
                    ? 'suggestion-item--active'
                    : 'suggestion-item'
                  // inline style for demonstration purpose
                  const style = suggestion.active
                    ? {
                        backgroundColor: '#fafafa',
                        cursor: 'pointer',
                        color: '#000',
                        padding: '5px 5px 5px 5px',
                        border: '1px solid #000',
                      }
                    : {
                        backgroundColor: '#ffffff',
                        cursor: 'pointer',
                        color: '#000',
                        padding: ' 5px 5px 5px 5px',
                        border: '1px solid #000',
                      }
                  return (
                    <div
                      key={index}
                      {...getSuggestionItemProps(suggestion, {
                        className,
                        style,
                      })}
                    >
                      <span>{suggestion.description}</span>
                    </div>
                  )
                })}
              </div>
            </div>
            {this.props?.errors[this.props.id] && (
              <p className="invalid-feedback">
                {this.props?.errors[this.props.id].message}
              </p>
            )}
          </div>
        )}
      </PlacesAutocomplete>
    )
  }
}
