import { faDownload } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useMutation } from '@tanstack/react-query'
import dayjs from 'dayjs'
import React, { useState } from 'react'
import { Col, Container, Form, ListGroup, Row, Spinner } from 'react-bootstrap'
import fetchService from '../../../../components/utils/fetchService'
import { downloadFileFromBlob } from '../../../../components/utils/Utils'

const reportsApiEndpointsMap = [
  {
    id: 1,
    endpoint: '/reports/consignee/active',
    format: 'CSV',
    label: 'Active Consignee',
  },
  {
    id: 2,
    endpoint: '/reports/consignee/in-active',
    format: 'CSV',
    label: 'In-active Consignee',
  },

  {
    id: 3,
    endpoint: '/reports/consignee/volume',
    format: 'PDF',
    label: 'Consignee Volume',
  },
  {
    id: 4,
    endpoint: '/reports/container/volume',
    format: 'PDF',
    label: 'Container Volume',
  },
  {
    id: 5,
    endpoint: '/reports/customer/in-active',
    format: 'PDF',
    label: 'Customer In-active',
  },
  {
    id: 6,
    endpoint: '/reports/invoice-list',
    format: 'PDF',
    label: 'Invoice List',
  },
  {
    id: 7,
    endpoint: '/reports/kpi',
    format: 'PDF',
    label: 'KPI',
  },
  {
    id: 8,
    endpoint: '/reports/merchant/active',
    format: 'PDF',
    label: 'Active Merchants',
  },
  {
    id: 9,
    endpoint: '/reports/profit',
    format: 'PDF',
    label: 'Profit',
  },
  {
    id: 10,
    endpoint: '/reports/shipper/active',
    format: 'PDF',
    label: 'Active Shipper',
  },
  {
    id: 11,
    endpoint: '/reports/shipper/in-active',
    format: 'PDF',
    label: 'In-Active Shipper',
  },
]

export default function ManagementReports() {
  const [formState, setFormState] = useState({
    startDate: dayjs().subtract(12, 'month').format('YYYY-MM-DD'),
    endDate: dayjs().format('YYYY-MM-DD'),
  })

  const [reports, setReports] = useState(reportsApiEndpointsMap)

  // const userDetails = JSON.parse(localStorage.getItem('userdetails'))
  // const customerCardId = userDetails?.userContext?.userId

  const mutation = useMutation({
    mutationFn: async (selectedReport) =>
      await fetchService({
        url: selectedReport.endpoint,
        method: 'POST',
        isFile: true,
        body: JSON.stringify({
          downloadFormat: selectedReport.format,
          ...formState,
        }),
      }),

    onSuccess: async (response, variables) => {
      if (response.ok) {
        const format =
          variables.format === 'EXCEL' ? 'xlsx' : variables.format.toLowerCase()
        downloadFileFromBlob(
          await response.blob(),
          `${variables.label}.${format}`
        )
      }
    },
  })

  const handleChange = (event) => {
    setFormState((prev) => ({ ...prev, [event.target.id]: event.target.value }))
  }

  const handleDownload = async (e) => {
    const selectedReport = JSON.parse(e.currentTarget.dataset.value)

    mutation.mutate(selectedReport)
  }

  const handleFileFormatChange = (value, index) => {
    setReports((prev) =>
      prev.toSpliced(index, 1, { ...prev[index], format: value })
    )
  }

  return (
    <Container fluid>
      <Row>
        <Form.Group as={Col} md={2}>
          <Form.Label>From</Form.Label>
          <Form.Control
            id="startDate"
            type="date"
            value={formState.startDate}
            onChange={handleChange}
          />
        </Form.Group>
        <Col md={9} />
        <Form.Group className="mt-2" as={Col} md={2}>
          <Form.Label>To</Form.Label>
          <Form.Control
            id="endDate"
            type="date"
            value={formState.endDate}
            onChange={handleChange}
            max={dayjs().format('YYYY-MM-DD')}
          />
        </Form.Group>
      </Row>
      <Row className="mt-4">
        <Col md={4}>
          <ListGroup as="ol" numbered>
            {reports.map((report, index) => (
              <ListGroup.Item
                key={report.id}
                as="li"
                className="d-flex fw-bold"
              >
                <span className="">{report.label}</span>
                <div className="ms-auto d-flex gap-3 align-items-center">
                  <Form.Group style={{ minWidth: '10rem' }}>
                    <Form.Select
                      value={report.format}
                      onChange={(e) =>
                        handleFileFormatChange(e.target.value, index)
                      }
                    >
                      <option value="" disabled>
                        Choose...
                      </option>
                      <option value="PDF">PDF</option>
                      <option value="CSV">CSV</option>
                      <option value="EXCEL">EXCEL</option>
                    </Form.Select>
                  </Form.Group>
                  {mutation.isPending &&
                  mutation.variables?.id === report.id ? (
                    <Spinner />
                  ) : (
                    <button
                      className="unset-all"
                      data-value={JSON.stringify(report)}
                      onClick={handleDownload}
                    >
                      <FontAwesomeIcon
                        icon={faDownload}
                        size="lg"
                        style={{
                          '--fa-secondary-color': '#26406d',
                          '--fa-secondary-opacity': '0.4',
                        }}
                      />
                    </button>
                  )}
                </div>
              </ListGroup.Item>
            ))}
          </ListGroup>
        </Col>
      </Row>
    </Container>
  )
}
