import React, { useMemo } from 'react'
import useDrivers from '../../hooks/useDrivers'
import { ArrayFilters } from '../../helpers/ArrayFilters'
import ResponsivePie from '../../../../../components/Common/Charts/ResponsivePie'

export const data = {
  labels: ['Red', 'Blue'],
  datasets: [
    {
      label: '# of Votes',
      data: [12, 19],
      backgroundColor: ['red', 'blue'],
      borderWidth: 0,
    },
  ],
}

/**
 * Employment type
 * Driver Group
 * Driver Type
 */

export default function LicenseExpiryPie({ setSelectedDrivers }) {
  const { data: driversData } = useDrivers()

  const licenseExpiryData = useMemo(() => {
    if (!driversData?.data) return []
    const driversClass = new ArrayFilters(driversData.data)
    const driversLicenseExpiryData = driversClass.getDataForExpiredDrivers(
      'licenseExpiryDateStr'
    )

    return {
      chartData: [
        {
          id: 'expired',
          label: 'Expired Licenses',
          value: driversLicenseExpiryData.expired.length,
          color: 'red',
        },
        {
          id: 'unexpired',
          label: 'Active Licenses',
          value: driversLicenseExpiryData.unexpired.length,
          color: 'blue',
        },
      ],
      ...driversLicenseExpiryData,
    }
  }, [driversData])

  return (
    <div className="d-flex h-100">
      <div className="h-100 w-75 text-dark">
        <h3 style={{ fontSize: '1.25rem' }} className="text-light">
          License Expiry Ratio
        </h3>
        {licenseExpiryData?.chartData && (
          <ResponsivePie
            data={licenseExpiryData?.chartData}
            endAngle={90}
            innerRadius={0.85}
            margin={{ top: 40, right: 140, bottom: 0, left: 10 }}
            cornerRadius={0}
            colors={{ datum: 'data.color' }}
            arcLinkLabelsTextColor="#fafafa"
            arcLabelsTextColor="white"
            onClick={({ id, label }) => {
              setSelectedDrivers({
                drivers: licenseExpiryData[id],
                header: label,
              })
            }}
          />
        )}
      </div>
      <div>
        <p>Expired - {licenseExpiryData?.expired?.length}</p>
        <p>Active - {licenseExpiryData?.unexpired?.length}</p>
      </div>
    </div>
  )
}
