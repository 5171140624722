import { useQuery, useQueryClient } from '@tanstack/react-query'
import startCase from 'lodash/startCase'
import React, { useCallback, useMemo, useState } from 'react'
import { Button } from 'react-bootstrap'
import Table from '../../../../../../components/Common/Tanstack-table/Table'
import { Info } from '../../../../../../components/utils/Toastify'
import { createTableColumnsFromKeysArr } from '../../../../../../components/utils/Utils'
import fetchService from '../../../../../../components/utils/fetchService'
import { crdTableColumnsKeys } from '../../data/columns'
import CopyDetailsForm from '../CopyDetailsForm'
import ImportCsv from '../ImportCsv'
import AddCRDModal from './AddCRDModal'
import AgreedRates from './AgreedRates'

const csvFields = [
  'wharfJobType',
  'stageMovementType',
  'vehicleJobType',
  'rateCode',
  'description',
  'location1Description',
  'location1AreaCode',
  'location2Description',
  'location2AreaCode',
  'dimensionUnit',
  'typeSize',
  'sellRate',
  'weightBar',
  'fromRange',
  'toRange',
  'handling',
  'jobWindow',
  'consignee',
  'wharf',
  'emptyDehire',
  'remarks',
]

export default function CRD({
  profileId,
  profileIdKey,
  queryUrl,
  uniqueProfileIdKey,
  baseUrl,
  type,
  optionsConfig = undefined,
  customerCardId,
}) {
  const queryClient = useQueryClient()
  const [showAddCrdModal, setShowAddCrdModal] = useState(false)
  const [selectedRateDetail, setSelectedRateDetail] = useState(null)
  const [showAgreedRates, setShowAgreedRates] = useState(false)
  const [currentForm, setCurrentForm] = useState('')

  const { data, error } = useQuery({
    queryKey: [queryUrl],
    queryFn: async () =>
      await fetchService({
        url: queryUrl,
      }),
    staleTime: 60000 * 5,
    enabled: !!profileId,
  })

  const handleColumnActions = useCallback(
    (action, data) => {
      switch (action) {
        case 'create':
          setSelectedRateDetail(null)
          setShowAddCrdModal(true)
          break

        case 'edit':
        case 'view':
          setSelectedRateDetail(data)
          setShowAddCrdModal(true)
          break

        case 'delete':
          fetchService({
            url: `${baseUrl}/${data[uniqueProfileIdKey]}`,
            method: 'DELETE',
            isFile: true,
          }).then((response) => {
            if (response) {
              queryClient.invalidateQueries({
                queryKey: [queryUrl],
              })
              Info('Deleted Successfully...')
            }
          })
          break

        case 'agreed-rates':
          setShowAgreedRates(true)
          setSelectedRateDetail(data)
          break
        default:
          break
      }
    },
    [baseUrl, queryClient, queryUrl, uniqueProfileIdKey]
  )

  const columns = useMemo(() => {
    const cols = createTableColumnsFromKeysArr(crdTableColumnsKeys)
    cols.unshift({
      header: 'Actions',
      id: 'actions',
      cell: ({ row }) => (
        <div className="d-flex gap-2">
          <Button
            size="sm"
            onClick={() => handleColumnActions(type, row.original)}
          >
            {startCase(type)}
          </Button>
          <Button
            variant="danger"
            size="sm"
            onClick={() => handleColumnActions('delete', row.original)}
          >
            Delete
          </Button>
          <Button
            variant="gold"
            size="sm"
            onClick={() => handleColumnActions('agreed-rates', row.original)}
          >
            Agreed Rates
          </Button>
        </div>
      ),
    })
    return cols
  }, [handleColumnActions, type])

  if (error) return <pre>{JSON.stringify(error, null, 2)}</pre>
  return (
    <div>
      <div className="d-flex gap-4">
        <Button
          onClick={() => {
            setCurrentForm('')
            handleColumnActions('create')
          }}
        >
          Add CRD
        </Button>
        <Button onClick={() => setCurrentForm('import')}>Import CRD</Button>
        <Button onClick={() => setCurrentForm('copy')}>Copy CRD</Button>
      </div>
      {currentForm === 'import' && (
        <ImportCsv
          profileId={profileId}
          profileIdKey={profileIdKey}
          csvFields={csvFields}
          postUrl={`${baseUrl}/multi`}
          invalidate={queryUrl}
          listRows={2}
          header="Import CRD Details"
          EditModal={AddCRDModal}
        />
      )}
      {currentForm === 'copy' && (
        <CopyDetailsForm
          profileId={profileId}
          profileIdKey={profileIdKey}
          colKeys={crdTableColumnsKeys}
          baseUrl={baseUrl}
          invalidateQuery={queryUrl}
          EditModalComponent={AddCRDModal}
          optionsConfig={optionsConfig}
        />
      )}
      {Array.isArray(data) && data.length ? (
        <div className="mt-4">
          <Table
            columns={columns}
            data={data}
            showTableBottom={false}
            showGlobalSearch={false}
          />
        </div>
      ) : (
        'No Records found'
      )}
      {showAddCrdModal && (
        <AddCRDModal
          profileId={profileId}
          profileIdKey={profileIdKey}
          handleClose={() => {
            setShowAddCrdModal(false)
            setSelectedRateDetail(null)
          }}
          selectedDetail={selectedRateDetail}
          queryUrl={queryUrl}
          baseUrl={baseUrl}
          customerCardId={customerCardId}
        />
      )}
      {showAgreedRates && selectedRateDetail && (
        <AgreedRates selectedRateDetail={selectedRateDetail} />
      )}
    </div>
  )
}
