import React, { useMemo } from 'react'
import { Spinner } from 'react-bootstrap'
import Table from '../../../../../components/Common/Tanstack-table/Table'
import { createTableColumnsFromKeysArr } from '../../../../../components/utils/Utils'
import { useEqPlanningData } from '../../../../../helper/hooks/apiHooks'
import DriversTable from './DriversTable'
import DriverAllocationCell from '../DriverAllocationCell'
import { eqPlanningColumnHeaders } from '../../data/eq-planning.data'
import { notesColumn } from '../../../../../components/Clm/FormData/LoadsPlanning'

export default function EqPlanner() {
  const { data: eqPlanningFetchData, isLoading, error } = useEqPlanningData()

  const columns = useMemo(() => {
    const cols = createTableColumnsFromKeysArr(eqPlanningColumnHeaders)
    cols.splice(3, 0, {
      header: 'Driver Name',
      id: 'driverName',
      accessorFn: (row) => row.driverName,
      cell: (cell) => (
        <DriverAllocationCell
          cell={cell}
          invalidateQuery={['loads-planning', '/clm/dashboard/eq-planning']}
        />
      ),
      size: 200,
    })
    cols.push(notesColumn)
    return cols
  }, [])

  if (error) return <p>Error occurred while fetching data</p>

  if (isLoading) return <Spinner />

  if (
    !Array.isArray(eqPlanningFetchData?.data) ||
    eqPlanningFetchData?.data.length === 0
  )
    return <p>No data available</p>

  return (
    <div className="unplanned-loads-tables-container">
      <div className="loads-table-container">
        <Table
          data={eqPlanningFetchData.data}
          columns={columns}
          draggable={true}
        />
      </div>
      <DriversTable
        invalidateQuery={['loads-planning', '/clm/dashboard/eq-planning']}
      />
    </div>
  )
}
