import { useState, useEffect } from 'react'

import ConsigneeAgreedRatesTable from './ConsigneeAgreedRatesTable'
import AddConsigneeAgreedRatesForm from './AddConsigneeAgreedRatesForm'
import startCase from 'lodash/startCase'
import config from '../../../../config'
import useModalContainer from '../../../../helper/hooks/useModalContainer'

const ConsigneeAgreedRates = (props) => {
  const {
    consigneeAgreedRates,
    setConsigneeAgreedRates,
    lookupsList,
    customerCardId,
    type,
  } = props
  const [consigneeData, setConsigneeData] = useState([])
  const [driverData, setDriverData] = useState([])
  const [consigneeAgreedRateData, setConsigneeAgreedRateData] = useState(null)
  const [consigneeAgreedRateIdx, setConsigneeAgreedRateIdx] = useState(null)
  const [formActionType, setFormActionType] = useState(null)

  useEffect(() => {
    const consigneeUrl =
      config.api.baseUrl +
      '/customercard/consignee-address/?customerCardId=' +
      customerCardId
    const driverUrl =
      config.api.baseUrl +
      '/customercard/consignee-address/consignee-name?customerCardId=' +
      customerCardId
    const requestOptions = {
      method: 'GET',
      headers: { Authorization: 'Bearer ' + localStorage.token },
    }
    fetch(consigneeUrl, requestOptions)
      .then((response) => response.json())
      .then((data) => {
        setConsigneeData(data)
      })

    fetch(driverUrl, requestOptions)
      .then((response) => response.json())
      .then((data) => {
        setDriverData(data)
      })
  }, [customerCardId])

  const handleAddConsigneeAgreedRates = () => {
    setFormActionType('add')
  }

  const { ModalContainer } = useModalContainer({
    text: 'Add Consignee Agreed Rate',
    onCloseCallback: () => {
      setFormActionType(null)
      setConsigneeAgreedRateIdx(null)
    },
    body: (
      <AddConsigneeAgreedRatesForm
        lookupsList={{
          ...lookupsList,
          consignee: consigneeData,
          drivers: driverData,
        }}
        setConsigneeAgreedRates={setConsigneeAgreedRates}
        defaultData={formActionType === 'import' && consigneeAgreedRateData}
        formActionType={formActionType}
        type={type}
        consigneeAgreedRateIdx={consigneeAgreedRateIdx}
      />
    ),
    btnClass: 'btn btn-primary mx-1 my-3',
    onShow: handleAddConsigneeAgreedRates,
    header: <>{startCase(formActionType)} Consignee Agreed Rates Form</>,
  })

  return (
    <div className="consignee-agreed-rates">
      {consigneeAgreedRates.length > 0 ? (
        <ConsigneeAgreedRatesTable
          csvArray={consigneeAgreedRates}
          setCsvArray={setConsigneeAgreedRates}
          setAgreedRateData={setConsigneeAgreedRateData}
          setAgreedRateIdx={setConsigneeAgreedRateIdx}
          setFormActionType={setFormActionType}
          type={type}
        />
      ) : (
        <p>No consignee agreed rates found!</p>
      )}

      {ModalContainer}
    </div>
  )
}

export default ConsigneeAgreedRates
