import React from 'react'
import { Tab, Tabs } from 'react-bootstrap'
import AdvancedSearch from '../../../components/Clm/AdvancedSearch/AdvancedSearch'
import EqPlanner from './components/eq-planner/EqPlanner'
import JobDetails from './components/job-details/JobDetails'
import PlannedJobs from './components/planned-jobs/PlannedJobs'
import UnplannedLoads from './components/unplanned-loads/UnplannedLoads'

export default function Allocations() {
  return (
    <div className="loads-planning-page-wrapper container-fluid">
      <Tabs
        defaultActiveKey="unplanned-loads"
        className="mb-3"
        mountOnEnter
        transition={false}
      >
        <Tab unmountOnExit eventKey="unplanned-loads" title="Unplanned Loads">
          <UnplannedLoads />
        </Tab>
        <Tab eventKey="planned-jobs" title="Planned Jobs">
          <PlannedJobs />
        </Tab>
        <Tab eventKey="job-details" title="Job Details">
          <JobDetails />
        </Tab>
        <Tab eventKey="advanced-search" title="Advanced Search">
          <AdvancedSearch />
        </Tab>
        <Tab eventKey="eq-planner" title="EQ Planning">
          <EqPlanner />
        </Tab>
      </Tabs>
    </div>
  )
}
