import { faUser } from '@fortawesome/free-solid-svg-icons'
import React, { useState } from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import config from '../../../../config'
import { calCriminalRecords, calDisqualificationsPer } from '../helpers/utils'
import useDrivers from '../hooks/useDrivers'
import DistanceCoveredLine from './components/DistanceCoveredLine'
import DriverGroupTypesPie from './components/DriverGroupTypesPie'
import EmployeeTypesPie from './components/EmployeeTypesPie'
import InfoCard from './components/InfoCard'
import LicenseExpiryPie from './components/LicenseExpiryPie'
import RunSheetDriversListModal from './components/RunSheetDriversListModal'
import VehicleTypeBar from './components/VehicleTypeBar'
import './drivers-dashboard.scss'
import { driversCols } from './driversColumn'
import SelectedDriversModal from './components/SelectedDriversModal'

const { runSheets } = config.api.drivers

const selectedDriversInitialState = {
  drivers: [],
  header: '',
}

export default function DriversDashboard() {
  const { data: driversData } = useDrivers()

  const [selectedDrivers, setSelectedDrivers] = useState(
    selectedDriversInitialState
  )

  const criminalRecordPercentage = calCriminalRecords(driversData)
  const driversWithDrivingDisqualificationPercentage =
    calDisqualificationsPer(driversData)

  return (
    <Container fluid className="drivers-dashboard-wrapper">
      <SelectedDriversModal
        onHide={() => setSelectedDrivers(selectedDriversInitialState)}
        selectedDrivers={selectedDrivers}
        columns={driversCols}
      />
      <Row>
        <Col md={12} className="info-cards-container">
          <InfoCard
            icon={faUser}
            label="Drivers"
            value={driversData?.data?.length}
          />
          <InfoCard icon={faUser} label="Manual Run-sheet Exists">
            <RunSheetDriversListModal
              endpoint={runSheets.manualExists}
              header="Manual Run-sheet Exists"
            />
          </InfoCard>
          <InfoCard
            icon={faUser}
            label={
              <>
                Manual Run-sheet <br />
                not Exists
              </>
            }
          >
            <RunSheetDriversListModal
              endpoint={runSheets.manualNotExists}
              header="Manual Run-sheet Exists not Exists"
            />
          </InfoCard>
          <InfoCard icon={faUser} label="Auto Run-sheet Exists">
            <RunSheetDriversListModal
              endpoint={runSheets.autoExists}
              header="Auto Run-sheet Exists"
            />
          </InfoCard>
          <InfoCard
            icon={faUser}
            label={
              <>
                Auto Run-sheet <br />
                not Exists
              </>
            }
          >
            <RunSheetDriversListModal
              endpoint={runSheets.autoNotExists}
              header="Auto Run-sheet not Exists"
            />
          </InfoCard>
        </Col>
        <Col md={12} className="mt-3">
          <Row className="">
            <Col md={3}>
              <div className="card-h d-flex flex-column gap-3">
                <div className="__container" style={{ minHeight: '40%' }}>
                  <LicenseExpiryPie setSelectedDrivers={setSelectedDrivers} />
                </div>
                <div className="__container" style={{ minHeight: '58%' }}>
                  <DriverGroupTypesPie
                    setSelectedDrivers={setSelectedDrivers}
                  />
                </div>
              </div>
            </Col>
            <Col md={3}>
              <div className="__container card-h">
                <div className="d-flex flex-column" style={{ height: '70%' }}>
                  <VehicleTypeBar />
                </div>
                <div
                  className="d-flex gap-3"
                  style={{ height: '28%', marginTop: '2%' }}
                >
                  <div className="__container w-50">
                    <div className="d-flex justify-content-center align-items-center h-100 text-center">
                      <div>
                        <h3>Criminal Records</h3>
                        <h3 className="mt-2">{criminalRecordPercentage} %</h3>
                      </div>
                    </div>
                  </div>
                  <div className="__container w-50">
                    <div className="d-flex justify-content-center align-items-center h-100 text-center">
                      <div>
                        <h3>Driver Disqualification</h3>
                        <h3 className="mt-2">
                          {driversWithDrivingDisqualificationPercentage} %
                        </h3>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
            <Col md={6}>
              <div className="card-h d-flex gap-3 flex-column">
                <div className="__container" style={{ minHeight: '48%' }}>
                  <DistanceCoveredLine />
                </div>
                <div className="d-flex gap-3 h-50">
                  <div className="__container w-50">
                    <EmployeeTypesPie setSelectedDrivers={setSelectedDrivers} />
                  </div>
                  <div className="__container w-50"></div>
                </div>
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
    </Container>
  )
}
