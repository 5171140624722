import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  routing: [],
  schedule: [],
}

const vesselSlice = createSlice({
  name: 'vessel',
  initialState,
  reducers: {
    setVesselServicesData(state, action) {
      const { controller, data } = action.payload
      state[controller] = data
    },
  },
})

export const { setVesselServicesData } = vesselSlice.actions

export const getVesselServiceDetailsByController = (state, controller) =>
  state.vessel[controller]

export default vesselSlice.reducer
