import { useEffect } from 'react'
// import '../clmStyle.scss'
import { VerticalBar } from '../../../components/Common/Charts'

const WmsDashboard = () => {
  // for chart
  const labels = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
  ]
  const data = {
    labels,
    datasets: [
      {
        label: 'Capacity',
        data: labels.map(() => Math.floor(Math.random() * 1000)),
        backgroundColor: '#127EA6',
        hoverBackgroundColor: '#127EC6',
      },
    ],
  }
  useEffect(() => {
    document.body.setAttribute('data-class', 'clm-container')
    return () => {
      document.body.removeAttribute('data-class')
    }
  }, [])

  return (
    <div className="w-100">
      <div className="header">
        <h6>WMS Dashboard</h6>
      </div>
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-5 columnData m-2 text-left text">
            <div className="">
              <div className="row  p-2 text">
                <div className="col-4"></div>
                <div className="col-3">20Ft</div>
                <div className="col-3">40Ft</div>
                <div className="col-2">{'>Days'}</div>
              </div>
              <div className="row p-2">
                <div className="col-4 text">Import Unpack Orders</div>
                <div className="col-3">10</div>
                <div className="col-3">15</div>
                <div className="col-2"></div>
              </div>
              <div className="row p-2 div-2">
                <div className="col-4 text">Export pack Orders</div>
                <div className="col-3">10</div>
                <div className="col-3">15</div>
                <div className="col-2"></div>
              </div>
            </div>

            <div className="mt-5">
              <div className="row p-2">
                <div className="col-4 text"></div>
                <div className="col-3">{'>Days'}</div>
                <div className="col-2"></div>
              </div>
              <div className="row p-2">
                <div className="col-4 text">Import Unpack Orders</div>
                <div className="col-3">10</div>
                <div className="col-2"></div>
              </div>
              <div className="row p-2 div-2">
                <div className="col-4 text">Export pack Orders</div>
                <div className="col-3">10</div>
                <div className="col-2"></div>
              </div>
            </div>

            {/* {summaryData.map((item,index) => (
                <div className="flex-content" key={index}>
                  <div></div>
                  <div>20Ft</div>
                  <div>40Ft</div>
                  <div>>Days</div>
                </div>
              ))} */}
          </div>
          <div className="col-md-6">
            <VerticalBar data={data} />
          </div>
        </div>
      </div>
    </div>
  )
}

export default WmsDashboard
