import React, { useState } from 'react'
import {
  useAssignLoadsToDriverMutation,
  useDriversDetails,
} from '../../../../helper/hooks/apiHooks'
import { toast } from 'react-toastify'
import { Spinner } from 'react-bootstrap'

export default function DriverAllocationCell({ cell, invalidateQuery }) {
  const { table } = cell
  const mutation = useAssignLoadsToDriverMutation(invalidateQuery)
  const [input, setInput] = useState('')

  const { data: drivers, error } = useDriversDetails()

  const handleOptionClick = async (driver) => {
    try {
      const currentRowData = cell.row.original
      const loads = []
      const selectedRows = table.getSelectedRowModel().rows
      if (Array.isArray(selectedRows) && selectedRows.length) {
        selectedRows.forEach((row) => {
          loads.push(row.original)
        })
      }
      if (!loads.find((l) => currentRowData.loadID === l.loadID)) {
        loads.push(currentRowData)
      }
      if (loads.some((l) => !['UNPLANNED', 'HOLD'].includes(l.status))) {
        toast.error('Loads selected with status other than UNPLANNED / HOLD')
        return
      }
      await mutation.mutateAsync({ loads, driver })
      setInput('')
    } catch (error) {
      console.log('Error occurred while assigning job', error)
    }
  }

  if (
    ['UNPLANNED', 'HOLD'].includes(cell.row.original.status) &&
    !cell.row.original.driverName
  ) {
    if (error) return '!'
    if (!Array.isArray(drivers) || !drivers.length) return ''
    if (mutation.isPending) return <Spinner />
    return (
      <div className="driver-allocation-cell-container">
        <input
          value={input}
          onChange={(e) => setInput(e.target.value)}
          type="text"
        />
        <div className="menu">
          {input.length > 0
            ? drivers
                ?.filter((d) =>
                  d?.driverName.toLowerCase().includes(input.toLowerCase())
                )
                .map((d) => (
                  <option
                    onClick={() => handleOptionClick(d)}
                    key={d?.driverCardId}
                  >{`${d?.driverName} (mob.- ${d?.contactNo})`}</option>
                ))
            : null}
        </div>
      </div>
    )
  }

  console.log(cell.row.original.driverName, cell.row.original.status)

  return cell.row.original.driverName
}
