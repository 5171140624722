import React from 'react'
import { useContext } from 'react'

export const ordersOperationsContext = React.createContext()

export function useOrdersContext() {
  const context = useContext(ordersOperationsContext)
  if (!context) {
    throw new Error('useShowFiltersDialogContext is undefined')
  }
  return context
}
