import { faUserPlus } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import { useDispatch } from 'react-redux'
import { setCreateConversationModalState } from '../../../../store/slices/enigmaChat-slice';

export default function Header() {
  const dispatch = useDispatch();
  
  const handleAddConversationClick = () => {
    dispatch(setCreateConversationModalState(true))
  }
  
  return (
    <div className="d-flex justify-content-between align-items-center headingIcon">
      <div className="user-avatar-icon">
        <img src="https://bootdey.com/img/Content/avatar/avatar1.png" alt="" />
      </div>
      <button
        type="button"
        className="btn btn-primary"
        onClick={handleAddConversationClick}
      >
        <FontAwesomeIcon icon={faUserPlus} />
      </button>
    </div>
  )
}
