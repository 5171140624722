import { yupResolver } from '@hookform/resolvers/yup'
import axios from 'axios'
import { isDate, parse } from 'date-fns'
import isNil from 'lodash/isNil'
import React, { Fragment, useEffect, useMemo, useState } from 'react'
import { Col, Spinner } from 'react-bootstrap'
import { Controller, useForm } from 'react-hook-form'
import { useSelector } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom'
import * as Yup from 'yup'
import { date } from 'yup'
import SearchableSelectField, {
  ColSelectWrapper,
} from '../../../../../components/Common/Form/Fields/SearchableSelectField'
import { ColSelect } from '../../../../../components/Common/Form/Fields/Select'
import ContainerWithHeading from '../../../../../components/styles/Containers/ContainerWithHeading'
import * as DateFun from '../../../../../components/utils/DateFun'
import { getSelectClass } from '../../../../../components/utils/Select'
import {
  TextBox,
  TextInputWithLabelField,
} from '../../../../../components/utils/TextBox'
import * as Toastify from '../../../../../components/utils/Toastify'
import { renderOptionsFromDataArr } from '../../../../../components/utils/Utils'
import config from '../../../../../config'
import { useVesselsVoyageNumbers } from '../../../../../helper/hooks/api/cerebro'
import { getLookupDataState } from '../../../../../store/slices/lookup-slice'
import { useOrdersContext } from '../../context/orderOperationsContext'
import HoldStatus from '../components/HoldStatus'
import Accounting from './components/Accounting'
import Documents from './components/Documents'
import OrderCardMonitor from './components/OrderCardMonitor'
import ServiceRates from './components/ServiceRates'
import RequiredSymbol from '../../../../../components/Common/Form/RequiredSymbol'
import { toast } from 'react-toastify'

function parseDateString(_value, originalValue) {
  return isDate(originalValue)
    ? originalValue
    : parse(originalValue, 'yyyy-MM-dd', new Date())
}
const today = new Date()

const OrdersCustomerDetails = ({
  setOperationsData,
  type,
  customerOrderData,
  setCustomerOrderData,
  accountingData,
}) => {
  let history = useHistory()
  const location = useLocation()
  const lookupsList = useSelector(getLookupDataState)
  const [customerNames, setCustomerNames] = useState([])
  const [customer, setCustomerData] = useState(null)

  const [orderCreatedBy, setOrderCreatedBy] = useState(null)
  const operationsCtxData = useOrdersContext()

  const getCustomers = () => {
    const url = config.api.baseUrl + config.api.customerNames
    const requestOptions = {
      method: 'GET',
      headers: { Authorization: 'Bearer ' + localStorage.token },
    }
    fetch(url, requestOptions)
      .then((response) => response.json())
      .then((data) => {
        setCustomerNames(data)
      })
      .catch((error) => {
        Toastify.Error(error?.response?.data?.error)
      })
  }

  const validationSchema = Yup.object().shape({
    // Customer Order Details
    createdDate: date().transform(parseDateString).nullable().max(today),
    createdBy: Yup.string().nullable().required('Order Created By is required'),
    customerId: Yup.string().nullable().required('Customer Id is reuired'),
    wharfJobType: Yup.string()
      .nullable()
      .required('Wharf Job Type is required'),
    stageMovementType: Yup.string()
      .nullable()
      .required('Stage Mmt Type is required'),
    customerRef: Yup.string().nullable().required('Customer Ref is required'),
    // .matches(/^[a-zA-Z 0-9_-]+$/, {
    //   message: 'Alphanumeric characters or underscores only',
    //   excludeEmptyString: true,
    // }),
    freightType: Yup.string().nullable().required('Freight type is required'),
    vehicleJobType: Yup.string()
      .nullable()
      .required('Vehicle Job Type is required'),

    //vessel details
    vesselId: Yup.string().nullable().optional(),
    wharfCode: Yup.string().nullable().required('Wharf Code is required'),
    voyageNo: Yup.string().nullable().optional(),

    loadingPort: Yup.string().nullable().optional(),
    dischargePort: Yup.string().when('wharfJobType', {
      is: 'IMPORT',
      then: (yup) => yup.required('Discharge Port is required'),
      otherwise: (yup) => yup.nullable(),
    }),
    // receivalsStartingDateTime: Yup.date().when('wharfJobType', {
    //   is: 'EXPORT',
    //   then: (yup) =>
    //     yup
    //       .transform(parseDateString)
    //       .required('Receivals Starting (Date and Time) is required'),
    //   otherwise: (yup) => yup.nullable().default(null),
    // }),
    // cutoffDateTime: Yup.date().when('wharfJobType', {
    //   is: 'EXPORT',
    //   then: (yup) =>
    //     yup
    //       .transform(parseDateString)
    //       .required('Cut Off Date and Time is required'),
    //   otherwise: (yup) => yup.nullable().default(null),
    // }),
    releaseNo: Yup.string().nullable().optional(),

    shippingAgent: Yup.string().nullable().optional(),
    remarks: Yup.string().nullable().optional(),
  })

  const {
    register,
    handleSubmit,
    reset,
    setValue,
    formState: { errors },
    watch,
    control,
  } = useForm({
    resolver: yupResolver(validationSchema),
    reValidateMode: 'onChange',
    defaultValues: { ...customerOrderData },
    values: customerOrderData,
  })
  const orderWharfJobType = watch('wharfJobType')
  const selectedVoyageNo = watch('voyageNo')
  const { userDetails } = JSON.parse(localStorage.data)

  const { data: voyageNumbersData, isLoading: isLoadingVoyageNumbers } =
    useVesselsVoyageNumbers(orderWharfJobType)

  const voyageOptions = voyageNumbersData?.map((v) => v.voyageNo)

  const selectedVesselDescription =
    voyageNumbersData?.find((v) => v.voyageNo === selectedVoyageNo)
      ?.vesselDescription ?? ''

  const vesselIdOptions = useMemo(() => {
    return voyageNumbersData?.find((v) => v.voyageNo === selectedVoyageNo)
      ?.vesselIds
  }, [selectedVoyageNo, voyageNumbersData])

  useEffect(() => {
    getCustomers()
  }, [])

  useEffect(() => {
    if (customerOrderData && customerOrderData.customerId) {
      setValue('customerId', customerOrderData.customerId)
      let customers = [...customerNames]
      let _customer = customers.find(
        (c) => c.id === customerOrderData?.customerId
      )
      setCustomerData(_customer)
    }
  }, [customerNames, customerOrderData, setValue])

  useEffect(() => {
    let ordCreatedBy = userDetails.firstName + ' ' + userDetails.lastName
    if (orderCreatedBy === null) {
      setOrderCreatedBy(ordCreatedBy)
    }
    setValue('createdBy', ordCreatedBy)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderCreatedBy])

  useEffect(() => {
    setValue(
      'createdDate',
      customerOrderData?.createdDate
        ? DateFun.getShortDate(customerOrderData?.createdDate)
        : DateFun.getShortDate(today)
    )

    if (location.pathname.includes('wms')) {
      setValue('modules', 'WMS')
    }

    const defaultPort = lookupsList?.portCodes.find(
      (p) => p?.portCode === 'AUSYD'
    )?.portCode

    // Set default value if value not found
    const dischargePort =
      customerOrderData?.orderVesselDetails?.dischargePort ?? defaultPort

    const loadingPort =
      customerOrderData?.orderVesselDetails?.loadingPort ?? defaultPort

    setValue('dischargePort', dischargePort)

    setValue('loadingPort', loadingPort)

    if (customerOrderData?.customerId) {
      setValue(
        'shippingAgent',
        customerOrderData?.orderVesselDetails?.shippingAgent
      )
      //order details
      setValue('customerId', customerOrderData?.customerId)
      setValue('wharfJobType', customerOrderData?.wharfJobType)
      setValue('customerRef', customerOrderData?.customerRef)
      setValue('freightType', customerOrderData?.freightType)
      setValue('oblNo', customerOrderData?.oblNo)

      setValue('createdBy', customerOrderData?.createdBy)
      setOrderCreatedBy(customerOrderData?.createdBy)

      setValue('releaseNo', customerOrderData?.orderVesselDetails?.releaseNo)
      setValue('stageMovementType', customerOrderData?.stageMovementType)
      setValue('serviceYard', customerOrderData?.serviceYard)
      setValue('wareHouseCode', customerOrderData?.wareHouseCode)

      setValue('railHead', customerOrderData?.railHead)
      setValue('mtDehire', customerOrderData?.mtDehire)
      setValue('vehicleJobType', customerOrderData?.vehicleJobType)
      setValue('modules', customerOrderData?.modules ?? '')

      //vessel details
      setValue('vesselId', customerOrderData?.orderVesselDetails?.vesselId)
      setValue('wharfCode', customerOrderData?.orderVesselDetails?.wharfCode)

      setValue('voyageNo', customerOrderData?.orderVesselDetails?.voyageNo)
      setValue(
        'cutoffDateTime',
        DateFun.getFullDate(
          customerOrderData?.orderVesselDetails?.cutoffDateTime
        )
      )
      setValue(
        'receivalsStartingDateTime',
        DateFun.getFullDate(
          customerOrderData?.orderVesselDetails?.receivalsStartingDateTime
        )
      )
      setValue('remarks', customerOrderData?.orderVesselDetails?.remarks)
    }
  }, [customerOrderData, location.pathname, lookupsList?.portCodes, setValue])

  const onSubmitOrderDetails = (formData) => {
    if (
      formData.stageMovementType.includes('WMS') &&
      !formData.modules.includes('WMS')
    ) {
      return toast.error(
        'Please select the "WMS" module before saving the details.'
      )
    }
    let orderVesselDetails = {
      orderCustomerDetailsId: operationsCtxData?.orderCustomerDetailsId, // Need to be get it from context api
      orderVesselDetailsId: customerOrderData?.orderVesselDetailsId,
      remarks: formData.remarks,
      vesselId: formData.vesselId,
      voyageNo: formData.voyageNo,
      shippingAgent: formData.shippingAgent,

      wareHouseCode: formData.wareHouseCode,
      wharfCode: formData.wharfCode,
      ...(orderWharfJobType?.toUpperCase() === 'IMPORT' && {
        dischargePort: formData.dischargePort,
      }),

      ...(orderWharfJobType?.toUpperCase() === 'EXPORT' && {
        loadingPort: formData.loadingPort,
        receivalsStartingDateTime: DateFun.getFullDate(
          formData?.receivalsStartingDateTime
        ),
        cutoffDateTime: DateFun.getFullDate(formData.cutoffDateTime),
        releaseNo: formData.releaseNo,
      }),
    }
    let options = {
      customerId: customer?.id,
      orderCustomerDetailsId:
        operationsCtxData?.orderCustomerDetailsId === 0
          ? undefined
          : operationsCtxData?.orderCustomerDetailsId,

      customerRef: formData.customerRef,
      releaseNo: formData.releaseNo,
      notes: customerOrderData?.orderReference?.notes,
      modules: formData.modules,
      serviceYard: formData.serviceYard,
      wareHouseCode: formData.wareHouseCode,
      railHead: formData.railHead,
      mtDehire: formData.mtDehire,
      stageMovementType: formData.stageMovementType,
      vehicleJobType: formData.vehicleJobType,
      wharfJobType: formData.wharfJobType,
      orderVesselDetails: orderVesselDetails,
      freightType: formData.freightType,
      oblNo: formData.oblNo,
    }

    let requestType =
      operationsCtxData && operationsCtxData.orderCustomerDetailsId
        ? 'PUT'
        : 'POST'
    let requestUrl =
      operationsCtxData && operationsCtxData.orderCustomerDetailsId
        ? config.api.baseUrl +
          config.api.draftCustomer +
          '/' +
          operationsCtxData.orderCustomerDetailsId
        : config.api.baseUrl + config.api.draftCustomer

    axios({
      method: requestType, //you can set what request you want to be
      url: requestUrl,
      data: options,
      headers: {
        Authorization: 'Bearer ' + localStorage.token,
      },
    })
      .then(function (response) {
        setCustomerOrderData(response.data)
        setOperationsData((prevVal) => ({
          ...prevVal,
          orderCustomerDetailsId: response.data.orderCustomerDetailsId,
        }))
        Toastify.Success('Customer order draft submitted successfully..!')
        history.push(`/Operations/edit/${response.data.orderCustomerDetailsId}`)
      })
      .catch(function (error) {
        error?.response?.data?.data?.forEach(({ message, key }) => {
          Toastify.Error(`${key}- ${message}`)
        })
      })
  }

  const module = watch('modules')
  const movementTypeOptions = useMemo(() => {
    if (!orderWharfJobType) return []
    let wmsOption = lookupsList?.stageMovementTypes
      .filter((t) => t.wharfJobType === orderWharfJobType)
      .map(({ transportStage, description }) => ({
        transportStage,
        description,
      }))

    // Additional filtering based on the description containing 'WMS'
    if (module.includes('WMS')) {
      wmsOption = wmsOption.filter(
        ({ description }) =>
          description.includes('WMS') || description.includes('WAREHOUSE')
      )
    }
    return wmsOption
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderWharfJobType, lookupsList?.stageMovementTypes, module])

  return (
    <ContainerWithHeading
      heading={`Customer Order Details : ${
        customerOrderData?.orderNumber ?? '_'
      }`}
      containerClassNames="p-0 pt-4"
    >
      <div className="d-flex justify-content-around mt-3 mb-3 px-3">
        <HoldStatus
          profileRecordUnqKey="orderHoldStatusId"
          heading="Order Hold Status"
          profileId={operationsCtxData?.orderCustomerDetailsId}
          profileIdKey="orderCustomerDetailsId"
          url={`/operations/order/hold-status?orderCustomerDetailsId=${operationsCtxData?.orderCustomerDetailsId}`}
        />
        <Documents contextAPIData={operationsCtxData} />
        <Accounting
          setOperationsData={setOperationsData}
          type={type}
          accountingData={accountingData}
        />
        <OrderCardMonitor contextAPIData={operationsCtxData} />
        <ServiceRates contextAPIData={operationsCtxData} />
      </div>
      <form autoComplete="off" onSubmit={handleSubmit(onSubmitOrderDetails)}>
        <div className="d-flex p-3">
          <p className="h5">Modules</p>
          <div
            className="d-flex mx-auto align-items-center"
            style={{ gap: '2.5rem' }}
          >
            <Controller
              name="modules"
              control={control}
              defaultValue=""
              render={({ field }) => {
                const modules = ['Freight', 'Yard', 'WMS', 'MT']
                let valSet = new Set(field.value.split(',').filter(Boolean))
                return modules.map((m) => (
                  <div key={m}>
                    <input
                      type="checkbox"
                      value={m}
                      checked={valSet.has(m)}
                      className="form-check-input"
                      id={m}
                      disabled={
                        type === 'view' || location.pathname.includes('wms')
                      }
                      onChange={() => {
                        if (valSet.has(m)) valSet.delete(m)
                        else valSet.add(m)

                        field.onChange(Array.from(valSet).join(','))
                      }}
                    />
                    <label className="ms-2" htmlFor={m}>
                      {m}
                    </label>
                  </div>
                ))
              }}
            />
          </div>
        </div>

        <div className="row p-3">
          {/* First Column */}
          <div className="col-md-6">
            <div className="row">
              {/* Order Date */}
              <div className="col-md-6">
                <label>Order Date :</label>
              </div>
              <div className="col-md-6 mb-2">
                {type !== 'view' && (
                  <>
                    <input
                      className={`form-control ${
                        errors?.createdDate ? 'is-invalid' : ''
                      }`}
                      id="select-date"
                      placeholder="dd:mm:yy"
                      name="createdDate"
                      type="date"
                      {...register('createdDate')}
                    />
                    {errors?.createdDate && (
                      <p className="invalid-feedback">
                        {errors?.createdDate?.message}
                      </p>
                    )}
                  </>
                )}
                {type === 'view' && (
                  <h5 className="blue-title">
                    {customerOrderData?.createdDate}
                  </h5>
                )}
              </div>

              {/* Customer */}
              <div className="col-md-6  mb-2">
                <label>
                  Customer <RequiredSymbol /> :
                </label>
              </div>
              <div className="col-md-6 mb-2">
                {type !== 'view' && isNil(customerOrderData?.customerId) && (
                  <>
                    <select
                      className={`form-select ${
                        errors?.customerId ? 'is-invalid' : ''
                      }`}
                      {...register('customerId', {
                        onChange: (e) => {
                          let _customer = customerNames.find(
                            (cust) => cust.id === +e.target.value
                          )
                          setCustomerData(_customer)
                        },
                      })}
                    >
                      <option value="">Please Select</option>
                      {customerNames.map((item) => (
                        <option key={item.id} value={item.id}>
                          {item.code}
                        </option>
                      ))}
                    </select>
                    {errors?.customerId && (
                      <p className="invalid-feedback">
                        {errors?.customerId?.message}
                      </p>
                    )}
                  </>
                )}
                {(type === 'view' || type === 'edit') && (
                  <h5 className="blue-title">
                    {customerOrderData?.customerName}
                  </h5>
                )}
              </div>

              {/* Job Type */}
              <div className="col-md-6">
                <label>
                  Job Type <RequiredSymbol /> :
                </label>
              </div>
              <div className="col-md-6 mb-2">
                {type !== 'view' && isNil(customerOrderData?.wharfJobType) && (
                  <>
                    <select
                      className={`form-select ${
                        errors?.wharfJobType ? 'is-invalid' : ''
                      }`}
                      id="wharfJobType"
                      name="wharfJobType"
                      {...register('wharfJobType')}
                    >
                      <option value="">Please Select</option>
                      {lookupsList?.wharfJobTypes.map((item) => (
                        <option key={item.id} value={item.description}>
                          {item.description}
                        </option>
                      ))}
                    </select>
                    {errors?.wharfJobType && (
                      <p className="invalid-feedback">
                        {errors?.wharfJobType?.message}
                      </p>
                    )}
                  </>
                )}
                {(type === 'view' || type === 'edit') && (
                  <h5 className="blue-title">
                    {customerOrderData?.wharfJobType}
                  </h5>
                )}
              </div>

              {/* Delivery Method */}
              <div className="col-md-6">
                <label>
                  Delivery Method <RequiredSymbol /> :
                </label>
              </div>
              <div className="col-md-6 mb-2">
                {type !== 'view' ? (
                  <>
                    <select
                      className={getSelectClass(errors?.vehicleJobType)}
                      id="vehicleJobType"
                      name="vehicleJobType"
                      {...register('vehicleJobType')}
                    >
                      <option value="">Please Select</option>
                      {lookupsList?.vehicleJobTypes.map((item) => (
                        <option key={item.id} value={item.id}>
                          {item.description}
                        </option>
                      ))}
                    </select>
                    {errors?.vehicleJobType && (
                      <p className="invalid-feedback">
                        {errors?.vehicleJobType?.message}
                      </p>
                    )}
                  </>
                ) : (
                  <h5 className="blue-title">
                    {customerOrderData?.vehicleJobType}
                  </h5>
                )}
              </div>
            </div>
          </div>

          {/* Second Column */}
          <div className="col-md-6">
            <div className="row">
              {/* Order Created By */}
              <div className="col-md-6  mb-2">
                <label>Order Created By :</label>
              </div>
              <div className="col-md-6 mb-2">
                <TextBox
                  register={register}
                  id="createdBy"
                  errors={errors}
                  value=""
                  placeholder="Order Created By"
                  maxLength="30"
                  disabled={type === 'view'}
                  viewMode={type}
                  defaultData={customerOrderData ?? {}}
                />
              </div>

              {/* Movement Type */}
              <SearchableSelectField
                name="stageMovementType"
                options={movementTypeOptions}
                control={control}
                setValue={setValue}
                SelectWrapper={ColSelectWrapper}
                optionValueKey={'transportStage'}
                required
                disabled={type === 'view'}
                type={type}
              />

              {/* Customer Ref No */}
              <div className="col-md-6">
                <label>
                  Customer Ref No <RequiredSymbol /> :
                </label>
              </div>
              <div className="col-md-6 mb-2">
                <TextBox
                  register={register}
                  id="customerRef"
                  errors={errors}
                  value=""
                  placeholder="Customer Ref"
                  maxLength="25"
                  disabled={type === 'view'}
                  viewMode={type}
                  defaultData={customerOrderData ?? {}}
                />
              </div>

              {/* Freight Type */}
              <div className="col-md-6">
                <label>
                  Freight Type <RequiredSymbol /> :
                </label>
              </div>
              <div className="col-md-6 mb-2">
                {type !== 'view' && (
                  <>
                    <select
                      className={`form-select ${
                        errors?.freightType ? 'is-invalid' : ''
                      }`}
                      {...register('freightType')}
                    >
                      <option value="">Please Select</option>
                      {renderOptionsFromDataArr(
                        lookupsList.shipmentTypes,
                        'shipmentType',
                        'description'
                      )}
                    </select>
                    {errors?.freightType && (
                      <p className="invalid-feedback">
                        {errors?.freightType?.message}
                      </p>
                    )}
                  </>
                )}
                {type === 'view' && (
                  <h5 className="blue-title">
                    {customerOrderData?.freightType}
                  </h5>
                )}
              </div>

              <TextInputWithLabelField
                name="oblNo"
                register={register}
                errors={errors}
                defaultData={customerOrderData}
                viewMode={type}
              />
            </div>
          </div>
        </div>

        <hr />
        <div className="__header p-2">
          <h3>Customer Vessel Details</h3>
        </div>
        <hr />

        <div className="row p-3">
          {/* First Column - Customer Vessel Details Fields */}
          <div className="col-md-6">
            <div className="row">
              {/* Wharf */}
              <div className="col-md-6">
                <label>
                  Wharf <RequiredSymbol /> :
                </label>
              </div>
              <div className="col-md-6 mb-2">
                {type !== 'view' && (
                  <>
                    <select
                      className={`form-select ${
                        errors?.wharfCode ? 'is-invalid' : ''
                      }`}
                      id="wharfCode"
                      name="wharfCode"
                      {...register('wharfCode')}
                    >
                      <option value="">Please Select</option>
                      {lookupsList?.wharfCodes.map((item) => (
                        <option key={item.wharfCode} value={item.wharfCode}>
                          {item.description}
                        </option>
                      ))}
                    </select>
                    {errors?.wharfCode && (
                      <p className="invalid-feedback">
                        {errors?.wharfCode?.message}
                      </p>
                    )}
                  </>
                )}
                {type === 'view' && (
                  <h5 className="blue-title">
                    {customerOrderData?.orderVesselDetails?.wharfCode}
                  </h5>
                )}
              </div>

              {/* Voyage No */}
              <Col md={6}>
                <label>Voyage No</label>
              </Col>
              <Col md={6} className="mb-2">
                {isLoadingVoyageNumbers && <Spinner />}
                {!isLoadingVoyageNumbers && Array.isArray(voyageOptions) && (
                  <SearchableSelectField
                    SelectWrapper={'div'}
                    name="voyageNo"
                    control={control}
                    dependentFieldIds={['vesselId']}
                    setValue={setValue}
                    md={12}
                    options={voyageOptions}
                    disabled={type === 'view'}
                    type={type}
                  />
                )}
              </Col>
              {selectedVesselDescription !== '' && (
                <>
                  <div className="col-md-6">
                    <label>Vessel name</label>
                  </div>
                  <div className="col-md-6 mb-2">
                    <h5 className="blue-title">{selectedVesselDescription}</h5>
                  </div>
                </>
              )}

              {/* Voyage No */}
              {isLoadingVoyageNumbers ? (
                <Col md={12}>
                  <Spinner />
                </Col>
              ) : (
                <ColSelect
                  viewMode={type}
                  name="vesselId"
                  register={register}
                  error={errors}
                  options={vesselIdOptions}
                  defaultData={customerOrderData?.orderVesselDetails}
                />
              )}

              {/* Loading Port */}
              {orderWharfJobType?.toUpperCase() === 'EXPORT' && (
                <SearchableSelectField
                  name="loadingPort"
                  options={lookupsList?.portCodes}
                  control={control}
                  setValue={setValue}
                  SelectWrapper={ColSelectWrapper}
                  optionValueKey={'portCode'}
                  disabled={type === 'view'}
                />
              )}

              {/* Shipping Agent : */}
              <SearchableSelectField
                name="shippingAgent"
                options={lookupsList?.shippingAgents}
                control={control}
                setValue={setValue}
                SelectWrapper={ColSelectWrapper}
                optionValueKey={'shippingAgentCode'}
                disabled={type === 'view'}
                type={type}
              />
            </div>
          </div>
          {/* Second Column - Customer Vessel Details Fields */}
          <div className="col-md-6">
            <div className="row">
              {/* Discharge Port : */}
              {orderWharfJobType?.toUpperCase() === 'IMPORT' && (
                <>
                  <div className="col-md-6">
                    <label>Discharge Port :</label>
                  </div>
                  <div className="col-md-6 mb-2">
                    {type !== 'view' && (
                      <>
                        <select
                          className={`form-select ${
                            errors?.dischargePort ? 'is-invalid' : ''
                          }`}
                          id="dischargePort"
                          name="dischargePort"
                          {...register('dischargePort')}
                        >
                          <option value="">Please Select</option>
                          {renderOptionsFromDataArr(
                            lookupsList?.portCodes,
                            'portCode',
                            'description'
                          )}
                        </select>
                        {errors?.dischargePort && (
                          <p className="invalid-feedback">
                            {errors?.dischargePort?.message}
                          </p>
                        )}
                      </>
                    )}
                    {type === 'view' && (
                      <h5 className="blue-title">
                        {customerOrderData?.orderVesselDetails?.dischargePort}
                      </h5>
                    )}
                  </div>
                </>
              )}

              {/* Remarks */}
              <div className="col-md-6">
                <label>Remarks :</label>
              </div>
              <div className="col-md-6 mb-2">
                {type !== 'view' && (
                  <>
                    <textarea
                      {...register('remarks')}
                      className={`form-control ${
                        errors?.remarks ? 'is-invalid' : ''
                      }`}
                      id="remarks"
                      rows="2"
                      maxLength={500}
                    />
                  </>
                )}
                {errors?.remarks && (
                  <p className="invalid-feedback">{errors?.remarks?.message}</p>
                )}
                {type === 'view' && (
                  <h5 className="blue-title">
                    {customerOrderData?.orderVesselDetails?.remarks}
                  </h5>
                )}
              </div>

              {/* Receivals Starting (Date and Time) */}
              {orderWharfJobType?.toUpperCase() === 'EXPORT' && (
                <>
                  <div className="col-md-6">
                    <label>Receivals Starting (Date and Time) :</label>
                  </div>
                  <div className="col-md-6 mb-2">
                    {type !== 'view' && (
                      <>
                        <input
                          className={`form-control ${
                            errors?.receivalsStartingDateTime
                              ? 'is-invalid'
                              : ''
                          }`}
                          id="receivalsStartingDateTime"
                          placeholder=""
                          name="receivalsStartingDateTime"
                          type="datetime-local"
                          {...register('receivalsStartingDateTime', {
                            setValueAs: (value) =>
                              value ? new Date(value) : null,
                          })}
                        />
                        {errors?.receivalsStartingDateTime && (
                          <p className="invalid-feedback">
                            {errors?.receivalsStartingDateTime?.message}
                          </p>
                        )}
                      </>
                    )}
                    {type === 'view' && (
                      <h5 className="blue-title">
                        {
                          customerOrderData?.orderVesselDetails
                            ?.receivalsStartingDateTime
                        }
                      </h5>
                    )}
                  </div>
                </>
              )}

              {/* Cut Off Date and Time */}
              {orderWharfJobType?.toUpperCase() === 'EXPORT' && (
                <>
                  <div className="col-md-6">
                    <label>Cut Off Date and Time :</label>
                  </div>
                  <div className="col-md-6 mb-2">
                    {type !== 'view' && (
                      <>
                        <input
                          className={`form-control ${
                            errors?.cutoffDateTime ? 'is-invalid' : ''
                          }`}
                          id="cutoffDateTime"
                          placeholder=""
                          name="cutoffDateTime"
                          type="datetime-local"
                          {...register('cutoffDateTime', {
                            setValueAs: (value) =>
                              value ? new Date(value) : null,
                          })}
                        />
                        {errors?.cutoffDateTime && (
                          <p className="invalid-feedback">
                            {errors?.cutoffDateTime?.message}
                          </p>
                        )}
                      </>
                    )}
                    {type === 'view' && (
                      <h5 className="blue-title">
                        {customerOrderData?.orderVesselDetails?.cutoffDateTime}
                      </h5>
                    )}
                  </div>
                </>
              )}

              {/* Release Number */}
              {orderWharfJobType?.toUpperCase() === 'EXPORT' && (
                <>
                  <div className="col-md-6">
                    <label>Release Number :</label>
                  </div>
                  <div className="col-md-6 mb-2">
                    <TextBox
                      register={register}
                      id="releaseNo"
                      errors={errors}
                      value=""
                      placeholder="Release Number"
                      maxLength="30"
                      disabled={type === 'view'}
                      viewMode={type}
                      defaultData={customerOrderData?.orderVesselDetails ?? {}}
                    />
                  </div>
                </>
              )}
            </div>
          </div>
        </div>

        {type !== 'view' && (
          <div className="d-flex mt-3 p-3">
            <div className="ms-auto">
              <button
                className="btn btn-primary me-3"
                type="reset"
                onClick={() => reset({})}
              >
                Reset
              </button>
              <button className="btn btn-primary" type="submit">
                Save
              </button>
            </div>
          </div>
        )}
      </form>
    </ContainerWithHeading>
  )
}

export default OrdersCustomerDetails
