import { faPencil } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useState } from 'react'
import {UnpackedPageForm,UplDetailsModal} from '../SubComponents'
import IndeterminateCheckbox from '../../../../components/Common/Tanstack-table/components/IndeterminateCheckbox'
import Table from '../../../../components/Common/Tanstack-table/Table'
import Spinner from '../../../../components/Spinner/Spinner'

const Unpacked = ({
  unpackedData,
  setUplDetails,
  uplDetails,
  unpackedTableColumns,
  cargoDetailsTable,
  getWms,
}) => {
  const [loading, setLoading] = useState(false)
  const [showUPLDetailsModal, setShowUPLDetailsModal] = useState(false)
  
  const showUplDetails = (details) => {
    setUplDetails(details)
    setShowUPLDetailsModal(true)
  }
  unpackedTableColumns.splice(0, 1, {
    id: 'select',
    header: ({ table }) => (
      <IndeterminateCheckbox
        {...{
          checked: table.getIsAllRowsSelected(),
          indeterminate: table.getIsSomeRowsSelected(),
          onChange: table.getToggleAllRowsSelectedHandler(),
        }}
      />
    ),
    cell: ({ row }) => (
      <div className="px-1">
        <div className="d-flex">
          <IndeterminateCheckbox
            {...{
              checked: row.getIsSelected(),
              indeterminate: row.getIsSomeSelected(),
              onChange: row.getToggleSelectedHandler(),
            }}
          />
          <button
            className="btn m-0 p-0 "
            onClick={() => {
              showUplDetails(row.original)
            }}
          >
            <FontAwesomeIcon icon={faPencil} className="ps-1" />
          </button>
        </div>
        {row.getIsExpanded() && row.original.jobNumber}
      </div>
    ),
  })

  return (
    <div className="columnData customiseTable">
      {loading && <Spinner />}
      <Table
        columns={[...unpackedTableColumns]}
        data={unpackedData}
        // draggable={false}
        nestedTableDataId="orderContainerCargoDetails"
        renderSubComponent={cargoDetailsTable}
        getRowCanExpand={() => true}
      />
      {showUPLDetailsModal && (
        <UplDetailsModal
        uplDetails={uplDetails}
        setShowUPLDetailsModal={setShowUPLDetailsModal}
      >
            <UnpackedPageForm
              wmsDetailsId={uplDetails.wmsDetailsId}
              setLoading={setLoading}
              getWms={getWms}
              setShowUPLDetailsModal={setShowUPLDetailsModal}
            />
          </UplDetailsModal>
      )}
      
    </div>
  )
}

export default Unpacked
