import {
  faCompress,
  faExpand,
  faFilter,
  faRotateRight,
  faSliders,
  faTableColumns,
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { isEmpty } from 'lodash'
import queryString from 'query-string'
import React, { useState } from 'react'
import { Form, OverlayTrigger, Popover, Row, Spinner } from 'react-bootstrap'
import { useSelector } from 'react-redux'
import { Link, useHistory, useLocation } from 'react-router-dom'
import { dropDownKey } from '../../../../../components/Clm/constant'
import Input from '../../../../../components/Common/Form/Fields/Input'
import Select from '../../../../../components/Common/Form/Fields/Select'
import IconButton from '../../../../../components/styles/Buttons/IconButton'
import {
  useAllChannels,
  useGetTemplateListByChannelName,
} from '../../../../../helper/hooks/api/allocations'
import { useAdvanceSearchDropDownOptions } from '../../../../../helper/hooks/apiHooks'
import { getLookupDataState } from '../../../../../store/slices/lookup-slice'
import { useProfileManager } from '../../context/ProfileManagerContext'

const ChannelsOptionsOverlay = () => {
  const { profileManager, setProfiles } = useProfileManager()
  const location = useLocation()
  const query = queryString.parse(location.search)
  const currentActiveTemplate = query['template']
  const history = useHistory()
  const [openInNew, setOpenInNew] = useState(false)
  const [selectedChannelName, setSelectedChannelName] = useState('')

  const { data, isLoading: isLoadingTemplateFunctions } = useAllChannels()

  const { data: templates, isLoadingTemplates } =
    useGetTemplateListByChannelName(selectedChannelName)

  const handleTemplateChange = (e) => {
    const template = e.target.value
    // Push to profile history
    setProfiles(profileManager.push(template))
    if (openInNew) {
      window.open(`#${location.pathname}?template=${template}`, '_blank')
      return
    }
    history.replace(`${location.pathname}?template=${template}`)
  }

  return (
    <OverlayTrigger
      trigger="click"
      placement="left"
      overlay={
        <Popover id="popover-basic">
          <Popover.Header>Apply Channels</Popover.Header>
          <Popover.Body>
            {isLoadingTemplateFunctions ? (
              <Spinner />
            ) : (
              <Form.Group className="mb-3">
                <Form.Label>Channels</Form.Label>
                <Form.Select
                  value={selectedChannelName}
                  onChange={(e) => setSelectedChannelName(e.target.value)}
                >
                  <option value="">Choose...</option>
                  {data?.masterTemplateFunctions.map((item) => (
                    <option
                      key={item.id + item.channelName}
                      value={item.channelName}
                    >
                      {item.channelName}
                    </option>
                  ))}
                </Form.Select>
              </Form.Group>
            )}
            {isLoadingTemplates && <Spinner />}
            {!isEmpty(templates) && (
              <Form.Group className="mb-3">
                <Form.Label>Templates</Form.Label>
                <Form.Select
                  value={currentActiveTemplate}
                  onChange={handleTemplateChange}
                >
                  <option value="">Choose...</option>
                  {Object.entries(templates).map(([code, name]) => (
                    <option key={code + name} value={code}>
                      {name}
                    </option>
                  ))}
                </Form.Select>
              </Form.Group>
            )}
            <Form.Group className="mb-3">
              <Form.Check
                type="switch"
                checked={openInNew}
                onChange={(e) => {
                  setOpenInNew(e.target.checked)
                }}
                label="Open in new tab"
              />
            </Form.Group>
          </Popover.Body>
        </Popover>
      }
    >
      <button className="unset-all p-1" title="Channels">
        <FontAwesomeIcon icon={faTableColumns} />
      </button>
    </OverlayTrigger>
  )
}

const useFullScreenFunction = () => {
  const [isFullScreen, setIsFullScreen] = useState(false)
  const fullscreen = (mode = true, el = 'html') => {
    if (!isFullScreen) {
      document.querySelector(el).requestFullscreen()
      setIsFullScreen(true)
    } else {
      document.exitFullscreen()
      setIsFullScreen(false)
    }
  }

  return [fullscreen, isFullScreen]
}

export default function UnplannedLoadsFilters({ form }) {
  const lookupsList = useSelector(getLookupDataState)
  const [fullscreen, isFullScreen] = useFullScreenFunction()

  const {
    data: QueryData,
    isLoading,
    error,
  } = useAdvanceSearchDropDownOptions()

  const loadFromOptions = QueryData?.data?.find(
    (d) => d?.key === dropDownKey.STAGE_FROM
  )?.dropdownValues

  const loadToOptions = QueryData?.data?.find(
    (d) => d?.key === dropDownKey.STAGE_TO
  )?.dropdownValues

  const {
    register,
    formState: { errors },
    reset,
  } = form

  return (
    <div className="mb-1 d-flex gap-2">
      <OverlayTrigger
        trigger="click"
        placement="left"
        overlay={
          <Popover id="popover-basic">
            <Popover.Header>Filters</Popover.Header>
            <Popover.Body>
              <Row>
                <Input
                  register={register}
                  errors={errors}
                  name="searchDate"
                  type="date"
                  md={12}
                />
                <Select
                  register={register}
                  errors={errors}
                  name="loadstatus"
                  options={lookupsList?.loadStatusEnums}
                  md={12}
                />
              </Row>
            </Popover.Body>
            <hr />
            <Popover.Header>Advance Search</Popover.Header>
            <Popover.Body>
              {isLoading && <Spinner />}
              {error && <p>Error loading stage options</p>}
              <Row>
                {Array.isArray(loadFromOptions) && (
                  <Select
                    register={register}
                    errors={errors}
                    name="loadFrom"
                    options={loadFromOptions}
                    md={12}
                  />
                )}
                {Array.isArray(loadToOptions) && (
                  <Select
                    register={register}
                    errors={errors}
                    name="loadTo"
                    options={loadToOptions}
                    md={12}
                  />
                )}
              </Row>
              <div className="d-flex">
                <IconButton
                  classes={'ms-auto'}
                  type="button"
                  onClick={() => reset()}
                >
                  <FontAwesomeIcon icon={faRotateRight} />
                </IconButton>
              </div>
            </Popover.Body>
          </Popover>
        }
      >
        <button className="unset-all p-1" title="Filters">
          <FontAwesomeIcon icon={faFilter} />
        </button>
      </OverlayTrigger>
      <ChannelsOptionsOverlay />
      <div className="d-flex ms-auto gap-1">
        <button
          onClick={fullscreen}
          className="unset-all p-1"
          title="Full screen"
        >
          {isFullScreen ? (
            <FontAwesomeIcon icon={faCompress} />
          ) : (
            <FontAwesomeIcon icon={faExpand} />
          )}
        </button>
        <Link
          to="/user-templates"
          target="_blank"
          className="unset-all p-1 ms-auto cursor-pointer"
          title="User Templates"
        >
          <FontAwesomeIcon icon={faSliders} />
        </Link>
      </div>
    </div>
  )
}
