export function getShortDate(str) {
  if (!str) return null
  let date = new Date(str),
    mnth = ('0' + (date.getMonth() + 1)).slice(-2),
    day = ('0' + date.getDate()).slice(-2)
  return [date.getFullYear(), mnth, day].join('-')
}

export function getFullDate(str) {
  if (!str) return str
  let date = new Date(str),
    mnth = ('0' + (date.getMonth() + 1)).slice(-2),
    day = ('0' + date.getDate()).slice(-2),
    hour = ('0' + date.getHours()).slice(-2),
    minutes = ('0' + date.getMinutes()).slice(-2),
    seconds = ('0' + date.getSeconds()).slice(-2)
  let strDate = [date.getFullYear(), mnth, day].join('-')

  return `${strDate} ${hour}:${minutes}:${seconds}`
}

export function getShortTime(strTime) {
  if (strTime && strTime.length > 5) {
    return strTime.substr(0, 5)
  }
  return strTime
}

export function getLongTime(strTime) {
  if (strTime && strTime.length === 5) {
    return `${strTime}:00`
  }
  return strTime
}

const dateFunctions = { getShortDate, getFullDate, getShortTime, getLongTime }
export default dateFunctions
