import AgreedRatesTable from '../AgreedRatesTable'

const headersToShow = [
  'approvedBy',
  'approvedDate',
  'cancelledBy',
  'cancelledDate',
  'consignee',
  'copiedFromModule',
  'copiedFromRateId',
  'createdBy',
  'createdDate',
  'deliverTo',
  'description',
  'dimensionUnit',
  'editedBy',
  'editedDate',
  'effectiveDate',
  'handling',
  'id',
  'isActive',
  'jobWindow',
  'mtdehire',
  'pickUpFrom',
  'rateCode',
  'rateModuleName',
  'rateProfileName',
  'rateStatus',
  'rateType',
  'remarks',
  'sellRate',
  'stageMovementType',
  'typeSize',
  'vehicleJobType',
  'weightBar',
  'wharf',
  'wharfJobType',
]

const CustomerAgreedRatesTable = (props) => {
  const {
    csvArray,
    setCsvArray,
    setAgreedRateData,
    setAgreedRateIdx,
    setFormActionType,
    type,
  } = props

  const transformTableBody = () =>
    csvArray.map((item) => {
      item.weightBar = {
        render: () => (
          <div className="d-flex">
            <div className="p-3">
              <h6>From Range</h6>
              <p>{item.fromRange}</p>
            </div>
            <div className="p-3">
              <h6>To Range</h6>
              <p>{item.toRange}</p>
            </div>
          </div>
        ),
      }
      return item
    })

  return (
    <AgreedRatesTable
      agreedRatesArray={{ data: transformTableBody() }}
      setAgreedRatesArray={setCsvArray}
      agreedRatesUrl="/customercard/customer-agreed-rates/"
      setEnableForm={() => {}}
      setAgreedRateFormData={setAgreedRateData}
      setProfileFormId={setAgreedRateIdx}
      setProfileFormType={setFormActionType}
      deleteRow={() => {}}
      type={type}
      headersToShow={headersToShow}
    />
  )
}

export default CustomerAgreedRatesTable
