import React, { createContext, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Route, HashRouter as Router, Switch } from 'react-router-dom'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import 'reactjs-popup/dist/index.css'
import AuthChecker from './AuthChecker'
import RenderComponent from './RenderComponent'
import { LoadingProvider } from './components/Clm/context'
import ErrorPage from './components/ErrorPage'
import Header from './components/Header/Header'
import LoginForm from './components/Login/Login'
import SideNavbar from './components/SideNavbar/SideNavbar'
import './index.css'
import { routeConfigWpr } from './routes'
import { getLookupData } from './store/actions/lookup-actions'
import { getIsLookupDataEmpty } from './store/slices/lookup-slice'
import './styles/theme.scss'
import './theme/js/index.esm'

export const themeContext = createContext()
function App() {
  const dispatch = useDispatch()
  const [isLogin, updateIsLogin] = useState(false)
  const [theme] = useState(undefined)
  const isLookupDataEmpty = useSelector(getIsLookupDataEmpty)

  useEffect(() => {
    if (localStorage.token) {
      AuthChecker()
      updateIsLogin(true)
    }
  }, [])

  useEffect(() => {
    if (isLookupDataEmpty && isLogin) dispatch(getLookupData())
  }, [isLogin, isLookupDataEmpty, dispatch])

  return (
    <div className="App">
      <ToastContainer
        position="bottom-left"
        autoClose={2000}
        hideProgressBar
        newestOnTop
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
        limit={2}
      />
      <Router>
        <SideNavbar />
        <Switch>
          <Route strict exact path="/">
            <LoginForm updateIsLogin={updateIsLogin} />
          </Route>
          <themeContext.Provider value={theme}>
            <LoadingProvider>
              <img
                className="bg-cube-img"
                src="/assets/background/cube.png"
                alt="bg-cube"
              />

              <Header />
              {isLogin &&
                routeConfigWpr.map((eachRoute, index) => (
                  <Route
                    strict
                    exact
                    path={eachRoute.path}
                    key={index}
                    render={(props) => (
                      <RenderComponent {...props}>
                        <eachRoute.component
                          {...props}
                          {...eachRoute.staticProps}
                        />
                      </RenderComponent>
                    )}
                  />
                ))}
            </LoadingProvider>
          </themeContext.Provider>
          <Route>
            <ErrorPage />
          </Route>
        </Switch>
      </Router>
    </div>
  )
}

export default App
