import lowerFirst from 'lodash/lowerFirst'
import startCase from 'lodash/startCase'
import React, { useCallback, useMemo } from 'react'
import {
  notesColumn,
  selectColumn,
} from '../../../../components/Clm/FormData/LoadsPlanning'
import { createTableColumnsFromObjKeys } from '../../../../components/utils/Utils'
import {
  queryKeys,
  useActiveTemplate,
  useDefaultTemplate,
  useUnplannedLoadsQuery,
} from '../../../../helper/hooks/apiHooks'
import DriverAllocationCell from '../components/DriverAllocationCell'
import has from 'lodash/has'
import MovReqDateField from '../components/unplanned-loads/MovReqDateField'

const hiddenCols = ['loadID', 'latestNotes', 'driverName']

const filterLoadsTableColsOnTemplate = (fields) => {
  const tableFields = []
  fields.forEach(({ fieldName }) => {
    if (!hiddenCols.includes(lowerFirst(fieldName)))
      tableFields.push({
        header: startCase(fieldName),
        accessorKey: lowerFirst(fieldName),
        id: lowerFirst(fieldName),
      })
  })

  return tableFields
}

const keysToColumnsMap = {
  hold: (cell) => (
    <span style={{ color: cell.getValue() ? 'orange' : 'green' }}>
      {cell.getValue() ?? 'No Hold'}
    </span>
  ),
  movReqDate: (cell) => (
    <MovReqDateField cell={cell} id="movReqDate" type="date" />
  ),
  movReqTime: (cell) => (
    <MovReqDateField cell={cell} id="movReqTime" type="time" />
  ),
}

export default function useUnplannedLoadsTableColumn(pagination) {
  const { data: defaultTemplateData } = useDefaultTemplate()

  const { data: activeTemplateData } = useActiveTemplate()

  const { data: unplannedLoadsQueryData } = useUnplannedLoadsQuery(pagination)

  const driverNameCol = useMemo(
    () => ({
      header: 'Driver Name',
      id: 'driverName',
      accessorFn: (row) => row.driverName,
      cell: (cell) => (
        <DriverAllocationCell
          cell={cell}
          invalidateQuery={queryKeys.allocationPlanner.allocations.unplannedLoads(
            pagination.pageIndex,
            pagination.pageSize
          )}
        />
      ),
      size: 200,
    }),
    [pagination.pageIndex, pagination.pageSize]
  )

  const addActionCols = useCallback(
    (cols) => {
      // Add select
      cols.unshift(selectColumn)

      cols = cols.map((col) => {
        const key = col.id
        if (has(keysToColumnsMap, key)) {
          return {
            ...col,
            cell: keysToColumnsMap[key],
          }
        }
        return col
      })

      // Add Notes column
      cols.push(notesColumn)

      if (cols.length > 4) cols.splice(3, 0, driverNameCol)
      else cols.push(driverNameCol)

      return cols
    },
    [driverNameCol]
  )

  return useMemo(() => {
    // if template is present in url
    if (activeTemplateData?.userTemplateFields) {
      const cols = filterLoadsTableColsOnTemplate(
        activeTemplateData.userTemplateFields
      )

      return addActionCols([...cols])
    }

    // If the user has a default template than use that template
    else if (defaultTemplateData?.userTemplateFields) {
      const cols = filterLoadsTableColsOnTemplate(
        defaultTemplateData.userTemplateFields
      )
      return addActionCols([...cols])
    }

    // If no default template is present
    else if (
      Array.isArray(unplannedLoadsQueryData?.data) &&
      unplannedLoadsQueryData.data.length
    ) {
      const cols = createTableColumnsFromObjKeys(
        unplannedLoadsQueryData.data,
        hiddenCols
      )

      return addActionCols([...cols])
    }

    return []
  }, [
    activeTemplateData?.userTemplateFields,
    defaultTemplateData?.userTemplateFields,
    unplannedLoadsQueryData?.data,
    addActionCols,
  ])
}
