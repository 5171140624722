import React, { useState, useEffect, useRef } from 'react'
import axios from 'axios'
import { toast } from 'react-toastify'
import { format } from 'date-fns'

import { Form } from '../../Common/Form'
import Spinner from '../../Spinner/Spinner'

import { useRouter } from '../../../helper/hooks/useRouter'
import config from '../../../config'

import { driverFormData } from './DriversFormData'

import { b64toBlob } from '../../../helper/b64toBlobConverter'
import { useSelector } from 'react-redux'
import { getLookupDataState } from '../../../store/slices/lookup-slice'

const getLookUpOptions = (data, field, id, label) => {
  let Options = []
  data?.[field.loadLookUp]?.forEach((val) => {
    let option = {}
    option.label = val[label]
    option.id = val[id]
    Options.push(option)
  })
  field.options = Options
}

const Drivers = (props) => {
  const baseEntityUrl = '/transport/drivers'
  const { mode } = props
  const router = useRouter()
  const currentDriverId = router.match.params['id']

  const currentActiveTab = router.query['activeTab']
  const [activeTab, setActiveTab] = useState(currentActiveTab ?? 'general')

  const currentDetailId = router.query['rid']

  const showEditForm = router.query['edit']
  const [showForm, setShowForm] = useState(false)

  const lookupData = useSelector(getLookupDataState)
  const [formFields, setFormFields] = useState({})
  const [loading, setLoading] = useState(false)
  const [data, setData] = useState({})
  const [detailsByTab, setDetailsByTab] = useState([])
  const [detailsById, setDetailsById] = useState({})
  const [showNotes, setShowNotes] = useState(false)
  const [error, setError] = useState()

  const [profilePic, setProfilePic] = useState()
  const profilePicRef = useRef()

  useEffect(() => {
    if (currentActiveTab !== activeTab) {
      setActiveTab(currentActiveTab ?? 'general')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentActiveTab])

  useEffect(() => {
    if (currentDetailId) {
      getDetailsByIdAndActiveTab(currentDetailId)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentDetailId])

  useEffect(() => {
    setShowForm(Boolean(showEditForm) ?? false)
  }, [showEditForm])

  useEffect(() => {
    setData(null)
    setDetailsByTab([])
    setDetailsById(null)
    ;(async () => {
      if (mode !== 'create' && activeTab === 'general') {
        await fetchDriverData(
          `transports/driver/card?driverCardId=${currentDriverId}`
        )
      }
      if (activeTab !== 'general') {
        await fetchDriverDetailsByActiveTab()
      }
    })()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeTab, currentDriverId, mode])

  useEffect(() => {
    ;(async () => {
      if (
        mode === 'view' &&
        activeTab === 'general' &&
        data?.imagePath &&
        !profilePic
      ) {
        await getProfilePic()
      }
    })()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeTab, data])

  useEffect(() => {
    if (activeTab) {
      setLookupFields(
        driverFormData.find((tab) => tab.tabId === activeTab),
        lookupData
      )
    }
  }, [activeTab, lookupData])

  useEffect(() => {
    if (mode === 'create') {
      router.push(router.location.pathname)
    }
  }, [mode, router])

  const setLookupFields = async (formData, lookupData) => {
    if (lookupData) {
      formData.formFields?.forEach((field) => {
        if (field.loadLookUp && field.loadLookUp === 'vendorCodes') {
          getLookUpOptions(lookupData, field, 'vendorCode', 'description')
        }
        if (field.loadLookUp && field.loadLookUp === 'driverShiftEnums') {
          field.options = lookupData[field.loadLookUp].map((driverShift) => ({
            id: driverShift,
            label: driverShift,
          }))
        }
      })
    }
    setFormFields(formData.formFields)
  }

  const getProfilePic = async () => {
    const url = `${config.api.baseUrl}/transports/driver/card/profile-pic/${currentDriverId}`
    const requestOptions = {
      method: 'GET',
      headers: { Authorization: 'Bearer ' + localStorage.token },
    }
    setLoading(true)
    await fetch(url, requestOptions)
      .then((response) => {
        if (response.status === 200) {
          return response.text()
        } else {
          throw response
        }
      })
      .then((data) => {
        setProfilePic(data)
      })
      .catch((error) => {
        console.error('fetch error', error)
      })
      .finally(() => {
        setLoading(false)
      })
  }

  const handleProfilePicChange = async (e) => {
    const file = e.target.files[0]
    if (file) {
      setLoading(true)
      const formData = new FormData()
      formData.append('profilepic', file)
      setLoading(true)
      axios({
        method: 'POST',
        url: `${config.api.baseUrl}/transports/driver/card/profile-pic/${currentDriverId}`,
        headers: {
          Authorization: 'Bearer ' + localStorage.token,
          'Content-Type': 'multipart/form-data',
        },
        data: formData,
      })
        .then(async (response) => {
          await getProfilePic()
          setLoading(false)
        })
        .catch(function (error) {
          setLoading(false)
        })
    }
  }

  const fetchDriverData = async (resourceUrl) => {
    const url = `${config.api.baseUrl}/${resourceUrl}`
    const requestOptions = {
      method: 'GET',
      headers: { Authorization: 'Bearer ' + localStorage.token },
    }
    setLoading(true)
    await fetch(url, requestOptions)
      .then((response) => response.json())
      .then((data) => {
        setData(data)
      })
      .catch((error) => {
        console.error('fetch error', error)
        toast.error(error?.response?.data?.message ?? 'Something went wrong!')
      })
      .finally(() => {
        setLoading(false)
      })
  }

  const fetchDriverDetailsByActiveTab = async () => {
    const activeTabData = driverFormData.find((t) => t.tabId === activeTab)
    const url = `${config.api.baseUrl}/${activeTabData.url}?driverCardId=${currentDriverId}`
    const requestOptions = {
      method: 'GET',
      headers: { Authorization: 'Bearer ' + localStorage.token },
    }
    setLoading(true)
    await fetch(url, requestOptions)
      .then((response) => response.json())
      .then((data) => {
        setDetailsByTab(data)
      })
      .catch((error) => {
        console.error('fetch error', error)
        toast.error(error?.response?.data?.message ?? 'Something went wrong!')
      })
      .finally(() => {
        setLoading(false)
      })
  }

  const getDetailsByIdAndActiveTab = async (id) => {
    const activeTabFormData = driverFormData.find((t) => t.tabId === activeTab)
    const url = `${config.api.baseUrl}/${activeTabFormData.url}/${id}`
    const requestOptions = {
      method: 'GET',
      headers: { Authorization: 'Bearer ' + localStorage.token },
    }
    setLoading(true)
    await fetch(url, requestOptions)
      .then((response) => response.json())
      .then((data) => {
        setDetailsById(data)
      })
      .catch((error) => {
        console.error('fetch error', error)
        toast.error(error?.response?.data?.message ?? 'Something went wrong!')
      })
      .finally(() => {
        setLoading(false)
      })
  }

  const handleDetailDelete = async (id) => {
    const activeTabFormData = driverFormData.find((t) => t.tabId === activeTab)
    axios({
      method: 'DELETE',
      url: `${config.api.baseUrl}/${activeTabFormData?.url}/${id}`,
      headers: {
        Authorization: 'Bearer ' + localStorage.token,
        'Content-Type': 'multipart/form-data',
      },
    })
      .then(async () => {
        setLoading(false)
        router.replace(
          `${router.location.pathname}${
            router.location.search?.split('&rid')[0]
          }`
        )
        await fetchDriverDetailsByActiveTab()
        toast.success('Successfully deleted')
      })
      .catch(function (error) {
        setLoading(false)
        toast.error(error?.response?.data?.message ?? 'Something went wrong!')
      })
  }

  const onAddEditDriver = async (values) => {
    let method = 'POST'
    let toastMessage = 'Driver created successfully'
    if (mode === 'edit') {
      toastMessage = 'Successfully updated'
      values['driverCardId'] = currentDriverId
      values['imagePath'] = data['imagePath']
      values['imageFileName'] = data['imageFileName']
      method = 'PUT'
    }

    formFields.forEach((field) => {
      if (field.type === 'date') {
        values[field.fieldId] = format(
          new Date(values[field.fieldId]),
          'yyyy-MM-dd'
        )
      }
    })

    const profilePic = values.profilePic?.[0]
    delete values.profilePic
    const formData = new FormData()
    if (profilePic) {
      formData.append('profilepic', profilePic)
    }
    formData.append('driverCardJson', JSON.stringify(values))

    setLoading(true)
    axios({
      method,
      url: `${config.api.baseUrl}/transports/driver/card`,
      headers: {
        Authorization: 'Bearer ' + localStorage.token,
        'Content-Type': 'multipart/form-data',
      },
      data: formData,
    })
      .then((response) => {
        setData(response.data)
        setLoading(false)
        if (mode === 'edit') {
          router.push(`${baseEntityUrl}/view/${currentDriverId}`)
        } else {
          router.push(
            `${baseEntityUrl}/edit/${response.data?.driverCardId}?activeTab=${driverFormData[0].tabId}`
          )
        }
        window.scrollTo(0, 0)
        toast.success(toastMessage)
      })
      .catch(function (error) {
        setLoading(false)
        setError(error?.response?.data)
        toast.error(error?.response?.data?.message ?? 'Something went wrong!')
      })
  }

  const onAddEditDriverDetails = async (values) => {
    values['driverCardId'] = currentDriverId

    const files = Array.from(values.files ?? [])
    const formData = new FormData()
    if (files?.length > 0) {
      files?.forEach((attachment, i) => {
        formData.append('files', attachment)
      })
    }
    delete values.files
    formData.append('jsonString', JSON.stringify(values))

    const activeTabData = driverFormData.find((t) => t.tabId === activeTab)

    setLoading(true)
    axios({
      method: 'POST',
      url: `${config.api.baseUrl}${activeTabData?.url}`,
      headers: {
        Authorization: 'Bearer ' + localStorage.token,
        'Content-Type': 'multipart/form-data',
      },
      data: formData,
    })
      .then((response) => {
        setLoading(false)
        router.push(
          `${baseEntityUrl}/view/${currentDriverId}?activeTab=${
            activeTabData?.tabId
          }&rid=${
            response?.data[activeTabData['uniqueReferenceKey']]
          }&view=true`
        )
        toast.success('Successfully saved')
      })
      .catch(function (error) {
        setLoading(false)
        setError(error?.response?.data)
        toast.error('Something went wrong!')
      })
  }

  const onSubmit = async (values) => {
    setError(null)
    if (activeTab === 'general') {
      await onAddEditDriver(values)
    } else {
      await onAddEditDriverDetails(values)
    }
  }

  const handleDownloadFile = async (id, attachment) => {
    const activeTabFormData = driverFormData.find((t) => t.tabId === activeTab)

    setLoading(true)
    try {
      const { data } = await axios({
        method: 'GET',
        url: `${config.api.baseUrl}/${activeTabFormData.url}/attachments/1?attachmentId=${id}`,
        headers: {
          Authorization: 'Bearer ' + localStorage.token,
        },
      })

      const b64data = data.replace(/-/g, '+').replace(/_/g, '/')
      const dataUri = URL.createObjectURL(
        b64toBlob(b64data, attachment.mimeType)
      )
      const flink = document.createElement('a')
      flink.setAttribute('download', attachment.fileName)
      flink.setAttribute('href', dataUri)
      flink.setAttribute('style', 'display:none !important;')
      document.body.appendChild(flink)
      flink.click()
      document.body.removeChild(flink)
      setLoading(false)
    } catch (error) {
      setLoading(false)
    }
  }

  const handleDeleteAttachment = async (id) => {
    const activeTabFormData = driverFormData.find((t) => t.tabId === activeTab)
    setLoading(true)
    try {
      await axios({
        method: 'DELETE',
        url: `${config.api.baseUrl}/${activeTabFormData.url}/attachments/1?attachmentId=${id}`,
        headers: {
          Authorization: 'Bearer ' + localStorage.token,
        },
      })
      toast.success('Successfully deleted')
      setLoading(false)
      await getDetailsByIdAndActiveTab(currentDetailId)
    } catch (error) {
      toast.error(error?.response?.data?.error ?? 'Something went wrong!')
      setLoading(false)
    }
  }

  const renderProfilePic = () => (
    <div className="profile-pic">
      <img
        src={
          profilePic
            ? `data:image/jpeg;base64,${profilePic}`
            : './assets/user-avatar.png'
        }
        alt="avatar-img"
      />
      <div className="edit-image-wrapper">
        <button onClick={() => profilePicRef.current.click()}>
          edit image
        </button>
        <input
          ref={profilePicRef}
          type="file"
          accept="image/*"
          onChange={handleProfilePicChange}
        />
      </div>
    </div>
  )

  const renderHeaderByTab = (tab) => (
    <div className="col-12">
      <div className="module-header">
        {!tab.isParentTab &&
          ((!showForm && mode === 'edit') || mode === 'view') && (
            <button
              className="btn btn-primary"
              onClick={() => {
                router.push(
                  `${baseEntityUrl}/edit/${currentDriverId}?activeTab=${currentActiveTab}&edit=true`
                )
              }}
            >
              {`Add ${tab.displayName}`}
            </button>
          )}
      </div>
    </div>
  )

  const renderFormByTab = (tab) => {
    const activeTabFormData = driverFormData.find((t) => t.tabId === activeTab)

    const attachments =
      detailsById?.[activeTabFormData['attachmentFieldKeyName']] ?? []
    return (
      <Form
        onSubmit={onSubmit}
        formData={formFields}
        columns={4}
        uniqueReferenceKey={tab.uniqueReferenceKey}
        formMode={mode}
        defaultValues={activeTab === 'general' ? data : detailsById}
        loading={loading}
      >
        <div className="col-12">
          {mode === 'view' &&
            activeTab !== 'general' &&
            formFields.find((f) => f.name === 'Attachments') && (
              <div className="row">
                <div className="col-md-2">
                  <label>Attachments</label>
                </div>
                <div className="col-md-6">
                  {attachments.length > 0 ? (
                    <ol className="attachment-list">
                      {attachments?.map((attachment, i) => (
                        <li key={i}>
                          <div>
                            <h4
                              onClick={() =>
                                handleDownloadFile(
                                  attachment[
                                    activeTabFormData['attachmentFieldId']
                                  ],
                                  attachment
                                )
                              }
                            >
                              {attachment.fileName}
                            </h4>
                            <span
                              onClick={() =>
                                handleDeleteAttachment(
                                  attachment[
                                    activeTabFormData['attachmentFieldId']
                                  ]
                                )
                              }
                            >
                              &#x2716;
                            </span>
                          </div>
                        </li>
                      ))}
                    </ol>
                  ) : (
                    <h4>-</h4>
                  )}
                </div>
              </div>
            )}
          <div className="module-footer">
            <div style={{ maxWidth: '400px', color: 'red' }}>
              <ul>
                {error?.data?.map((d, i) => (
                  <li key={i}>{`${d?.key} - ${d?.message}`}</li>
                ))}
              </ul>
            </div>
            <div>
              {mode === 'edit' && (
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={() => {
                    router.history.goBack()
                  }}
                >
                  Cancel
                </button>
              )}
              {activeTab === 'general' && mode === 'view' && (
                <button
                  type="button"
                  className={
                    showNotes
                      ? 'btn btn-secondary mx-2'
                      : 'btn btn-primary mx-2'
                  }
                  onClick={() => {
                    setShowNotes(!showNotes)
                  }}
                >
                  Notes
                </button>
              )}
              {mode !== 'edit' && (
                <>
                  <button
                    type="button"
                    className="btn btn-primary"
                    onClick={() => {
                      router.history.goBack()
                    }}
                  >
                    Back
                  </button>
                </>
              )}
              {mode === 'create' && activeTab === 'general' && (
                <>
                  <button type="submit" className="btn btn-primary ms-2">
                    Create Driver
                  </button>
                </>
              )}
              {mode === 'edit' && (
                <button type="submit" className="btn btn-primary ms-2">
                  Save
                </button>
              )}
              {mode === 'view' && (
                <button
                  type="button"
                  className="btn btn-primary ms-2"
                  onClick={() => {
                    if (activeTab === 'general') {
                      router.push(`${baseEntityUrl}/edit/${currentDriverId}`)
                    } else {
                      router.push(
                        `${baseEntityUrl}/edit/${currentDriverId}?activeTab=${activeTab}&rid=${currentDetailId}&edit=true`
                      )
                    }
                  }}
                >
                  Edit
                </button>
              )}
            </div>
          </div>
        </div>
      </Form>
    )
  }

  const renderDetailsByTab = (tab) => {
    const getDetails = (details, field) => {
      if (field.type === 'checkbox')
        return details[field.fieldId] ? 'YES' : 'NO'

      return details[field.fieldId] || '-'
    }

    return (
      <div className="col-12">
        <div className="module-header">
          {!tab.isParentTab && (
            <div className="col-md-12">
              <div className="table-responsive">
                <table className="table table-hover">
                  <thead>
                    <tr>
                      {tab.formFields
                        ?.filter((field) => !field.hideInTableMode)
                        ?.map((field, i) => (
                          <th key={i}>{field.label}</th>
                        ))}
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {detailsByTab.map((details, i) => {
                      return (
                        <tr key={i}>
                          {tab.formFields
                            ?.filter((field) => !field.hideInTableMode)
                            ?.map((field, i) => (
                              <td
                                style={{
                                  whiteSpace:
                                    field.type !== 'textarea'
                                      ? 'nowrap'
                                      : 'wrap',
                                }}
                                key={i}
                              >
                                {getDetails(details, field)}
                              </td>
                            ))}
                          <td
                            width={160}
                            style={{
                              whiteSpace: 'nowrap',
                            }}
                          >
                            <button
                              type="button"
                              className="btn btn-primary btn-sm"
                              onClick={() => {
                                router.push(
                                  `${router.location.pathname?.replace(
                                    'edit',
                                    'view'
                                  )}${
                                    router.location.search.split('&rid=')[0]
                                  }&rid=${
                                    details?.[tab.uniqueReferenceKey]
                                  }&view=true`
                                )
                              }}
                            >
                              View
                            </button>
                            <button
                              type="button"
                              className="btn btn-secondary btn-sm ms-2"
                              onClick={() => {
                                router.push(
                                  `${router.location.pathname?.replace(
                                    'view',
                                    'edit'
                                  )}${
                                    router.location.search.split('&rid=')[0]
                                  }&rid=${
                                    details?.[tab.uniqueReferenceKey]
                                  }&edit=true`
                                )
                              }}
                            >
                              Edit
                            </button>
                            <button
                              type="button"
                              className="btn btn-gold btn-sm ms-2"
                              onClick={() =>
                                handleDetailDelete(
                                  details[tab.uniqueReferenceKey]
                                )
                              }
                            >
                              Delete
                            </button>
                          </td>
                        </tr>
                      )
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          )}
        </div>
      </div>
    )
  }

  return (
    <div className="container-fluid">
      <div className="card transparent-card" style={{ marginBottom: 100 }}>
        <div className="card-body">
          <div className="row">
            <div className="col-8">
              <div className="card light-glow white-bg-card">
                <div className="card-body">
                  <nav className="mb-4">
                    <div className="nav nav-tabs" id="nav-tab" role="tablist">
                      {driverFormData.map((tab) => (
                        <button
                          key={tab.tabId}
                          className={
                            'nav-link ' + (activeTab === tab.tabId && 'active')
                          }
                          id="nav-home-tab"
                          data-bs-toggle="tab"
                          type="button"
                          disabled={
                            mode === 'create' && activeTab !== tab.tabId
                          }
                          onClick={() => {
                            router.push(
                              `${router.location.pathname.replace(
                                'edit',
                                'view'
                              )}?activeTab=${tab.tabId}`
                            )
                            setActiveTab(tab.tabId)
                          }}
                        >
                          {tab.displayName}
                        </button>
                      ))}
                    </div>
                  </nav>
                  {driverFormData.map(
                    (tab) =>
                      activeTab === tab.tabId && (
                        <div
                          className="tab-content"
                          id="nav-tabContent"
                          key={tab.tabId}
                        >
                          <div role="tabpanel" aria-labelledby="nav-home-tab">
                            {renderHeaderByTab(tab)}
                            {activeTab === 'general' &&
                              mode === 'view' &&
                              renderProfilePic()}
                            {formFields?.length > 0 &&
                              (mode === 'edit' ||
                                mode === 'create' ||
                                (mode === 'view' && activeTab === 'general') ||
                                (mode === 'view' && !!currentDetailId)) &&
                              renderFormByTab(tab)}
                            {detailsByTab.length > 0 && renderDetailsByTab(tab)}
                          </div>
                        </div>
                      )
                  )}
                </div>
              </div>
            </div>
            <div className="col-4">
              <div
                className="card light-glow white-bg-card"
                style={{ cursor: 'pointer' }}
                onClick={() => {
                  router.push(baseEntityUrl)
                }}
              >
                <div className="card-body">
                  <h1>Drivers</h1>
                </div>
              </div>
              {showNotes && (
                <div
                  className="card light-glow white-bg-card mt-3"
                  style={{ cursor: 'pointer' }}
                  onClick={() => {}}
                >
                  <div className="card-body mb-0 pb-0">
                    <h1>Notes</h1>
                  </div>
                  <div className="table-responsive">
                    <table className="table table-hover m-2">
                      <thead>
                        <tr>
                          <th>SI No</th>
                          <th>Job No</th>
                          <th>Notes</th>
                        </tr>
                      </thead>
                      <tbody>
                        {data?.jobNotesDtos?.map((note, i) => {
                          return (
                            <tr key={i}>
                              <td>{note?.siNo}</td>
                              <td>{note?.jobNo}</td>
                              <td>{note?.notes}</td>
                            </tr>
                          )
                        })}
                      </tbody>
                    </table>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      {loading && <Spinner />}
    </div>
  )
}

export default Drivers
