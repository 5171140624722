import React from 'react'
import { Accordion } from 'react-bootstrap'
import PlStatusBar from '../../components/PlStatusBar'
import CargoDetails from './CargoDetails'
// import CargoVerifyModal from './CargoVerifyModal'
const UplDetails = ({ uplDetails }) => {
  const detailsArray = [
    { label: 'Order NO', component: uplDetails.orderNumber },
    { label: 'Container Number', component: uplDetails.containerNumber },
    { label: 'UPL No', component: uplDetails.uplNo },
    { label: 'Wharf Job Type', component: uplDetails.wharfJobType },
    { label: 'Truck No', component: uplDetails.truckNo },
    { label: 'L / F', component: uplDetails.fullorLess },
    { label: 'Consignee Name', component: uplDetails.consigneeName },
    { label: 'Delivery Address', component: uplDetails.deliveryAddress },
    { label: 'UPL Status', component: uplDetails.uplStatus },
    { label: 'Customer REF', component: uplDetails.customerRef },
    {
      label: 'Expected Date Client Date Time',
      component: uplDetails.expClientDate,
    },
    { label: 'UPL Date', component: uplDetails.uplDate },
    { label: 'Shipper Name', component: uplDetails.shipperName },
    { label: 'Size', component: uplDetails.sizeType },
    { label: 'Remarks', component: uplDetails.remarks },
    { label: 'ETA', component: uplDetails.eta },
    { label: 'Dispatched Date', component: uplDetails.dispatchedDate },
  ]
  return (
    <div className="columnData text">
      <div className="fs-3 text-info">UPL No - {uplDetails?.uplNo}</div>
      <div className="row p-3">
        {detailsArray.map((item, index) => (
          <div
            key={index}
            className="col-md-6 me-auto "
            style={{ borderRight: '1px dashed #333' }}
          >
            <div className="flex-content px-2">
              <i className="d-inline-block">{item.label}:</i>{' '}
              <strong className="d-inline-block">{item.component}</strong>
            </div>
          </div>
        ))}
        <div className="col-md-12 my-3">
          <Accordion>
            {uplDetails?.orderContainerCargoDetails.map((cargo, index) => (
              <Accordion.Item eventKey={index} key={index}>
                <Accordion.Header>Cargo - {cargo.cargoNumber}</Accordion.Header>
                <Accordion.Body>
                  <CargoDetails
                    cargoDetails={cargo}
                    defaultValues={{
                      palletsNo: cargo.palletsOnWms,
                      comments: cargo.outturnComments,
                    }}
                  />
                </Accordion.Body>
              </Accordion.Item>
            ))}
          </Accordion>
        </div>
      </div>
      <PlStatusBar statusIndex={uplDetails?.wmsContainerStatusNo} />
    </div>
  )
}

export default UplDetails
