import React, { useMemo, useState } from 'react'
import { Spinner } from 'react-bootstrap'
import Table from '../../../../components/Common/Tanstack-table/Table'
import stageTrackerColumns from '../../../../components/Container/StageTracker/StageTrackerColumn.config'
import ContainerWithHeading from '../../../../components/styles/Containers/ContainerWithHeading'
import {
  useContainersStageDetails,
  useUpdateContainerStageMutation,
} from '../../../../helper/hooks/apiHooks'
import StageDetailsModalForm from './StageDetailsModalForm'
import StageGeoLocation from './StageGeoLocation'

const EditIcon = () => (
  <img
    style={{
      width: '20px',
      height: '20px ',
      backgroundColor: 'transparent',
    }}
    src="./assets/icons8-edit.svg"
    alt="edit"
  />
)

export default function ContainerStages({ queryData, queryKeyToInvalidate }) {
  const { data, isLoading, error } = useContainersStageDetails(queryData)
  const { onSubmit } = useUpdateContainerStageMutation(queryKeyToInvalidate)
  const [selectedContainerStage, setSelectedContainerStage] = useState(null)

  const handleClose = () => {
    setSelectedContainerStage(null)
  }

  const columns = useMemo(
    () => [
      {
        header: 'Action',
        id: 'action',
        cell: ({ row }) => (
          <div className="d-flex align-items-center gap-2">
            <button
              className="btn btn-sm"
              type="button"
              onClick={(e) => {
                setSelectedContainerStage(row.original)
              }}
            >
              <EditIcon />
            </button>
            <StageGeoLocation data={row.original} />
          </div>
        ),
      },
      ...stageTrackerColumns,
    ],
    []
  )

  if (isLoading) return <Spinner />

  if (error)
    return <p>Error occurred while fetching container stage tracker details</p>

  if (!Array.isArray(data) || !data.length)
    return <p>No container stage tracker details found!</p>

  return (
    <ContainerWithHeading heading="Order's Container Stage Tracker">
      <Table data={data} columns={columns} />
      {selectedContainerStage && (
        <StageDetailsModalForm
          onSubmit={onSubmit}
          handleClose={handleClose}
          selectedContainerStage={selectedContainerStage}
        />
      )}
    </ContainerWithHeading>
  )
}
