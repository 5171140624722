import React from 'react'
import { ListGroup } from 'react-bootstrap'
import ContainerWithHeading from '../../../../components/styles/Containers/ContainerWithHeading'

export default function RecentActivities() {
  return (
    <ContainerWithHeading heading="Recent Activity">
      <ListGroup>
        <ListGroup.Item>Track Call from Allocator</ListGroup.Item>
        <ListGroup.Item>Status</ListGroup.Item>
        <ListGroup.Item>Last Location</ListGroup.Item>
        <ListGroup.Item>Date</ListGroup.Item>
        <ListGroup.Item>Time</ListGroup.Item>
        <ListGroup.Item>Message</ListGroup.Item>
      </ListGroup>
    </ContainerWithHeading>
  )
}
