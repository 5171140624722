const areaCode = {
  name: 'Area Code',
  path: '/dataMgmt/customer/areaCode',
  req: '/',
  columns: 4,
  fields: [
    {
      name: 'ID',
      label: 'ID',
      type: 'text',
      validation: 'Alphabetic',
      fieldId: 'areaCode',
      id: 'areaCode',
      validationType: 'string',
      validations: [
        {
          type: 'required',
          params: ['this field is required'],
        },
        {
          type: 'max',
          params: [30, 'ID cannot be more than 30 characters'],
        },
      ],
    },
    {
      name: 'km',
      label: 'Km',
      type: 'number',
      validation: 'Numeric',
      fieldId: 'km',
      id: 'km',
      validationType: 'number',
      validations: [
        {
          type: 'required',
          params: ['this field is required'],
        },
      ],
    },
    {
      name: 'Air Port',
      label: 'Air Port',
      type: 'checkbox',
      validation: 'Alphabetic',
      fieldId: 'airPort',
      id: 'airPort',
      validationType: 'boolean',
    },
    {
      name: 'Zone Code',
      label: 'Zone Code',
      type: 'select',
      loadLookUp: 'zoneCodes',
      validation: 'Alphabetic',
      fieldId: 'zoneCode',
      id: 'zoneCode',
      validationType: 'string',
      validations: [
        {
          type: 'required',
          params: ['this field is required'],
        },
        {
          type: 'max',
          params: [50, 'Zone Code cannot be more than 20 characters'],
        },
      ],
      options: [],
    },
    {
      name: 'Sea Port',
      label: 'Sea Port',
      type: 'checkbox',
      validation: 'Alphabetic',
      fieldId: 'seaPort',
      id: 'seaPort',
      validationType: 'boolean',
    },
    {
      name: 'Post Code',
      label: 'Post Code',
      type: 'select',
      loadLookUp: 'postCodes',
      validation: 'Alphabetic',
      fieldId: 'postCode',
      id: 'postCode',
      validationType: 'string',
      validations: [
        {
          type: 'required',
          params: ['this field is required'],
        },
        {
          type: 'max',
          params: [30, 'Post Code cannot be more than 30 characters'],
        },
        {
          type: 'min',
          params: [3, 'Field should be min 3 characters'],
        },
      ],
      options: [],
    },
    {
      name: 'Haulage',
      label: 'Haulage',
      type: 'checkbox',
      validation: 'Alphabetic',
      fieldId: 'haulage',
      id: 'haulage',
      validationType: 'boolean',
    },
    {
      name: 'Local',
      label: 'Local',
      type: 'checkbox',
      validation: 'Alphabetic',
      fieldId: 'local',
      id: 'local',
      validationType: 'boolean',
    },
    {
      name: 'Transport',
      label: 'Transport',
      type: 'checkbox',
      validation: 'Alphabetic',
      fieldId: 'transport',
      id: 'transport',
      validationType: 'boolean',
    },
    {
      name: 'Description',
      label: 'Description',
      type: 'textarea',
      validation: 'Alphabetic',
      fieldId: 'description',
      id: 'description',
      validationType: 'string',
      validations: [
        {
          type: 'required',
          params: ['this field is required'],
        },
        {
          type: 'max',
          params: [100, 'Description cannot be more than 30 characters'],
        },
      ],
    },
  ],
}

export default areaCode
