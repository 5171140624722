const stageTrackerColumns = [
  {
    header: 'Order No.',
    accessorKey: 'orderNumber',
    id: 'orderNumber',
  },
  {
    header: 'Stage',
    accessorKey: 'stage',
    id: 'stage',
  },
  {
    header: 'Load Status',
    accessorKey: 'loadStatus',
    id: 'loadStatus',
  },
  {
    header: 'Pickup Location',
    accessorKey: 'pickupLocation',
    id: 'pickupLocation',
  },
  {
    header: 'Pickup Address',
    accessorKey: 'pickupAddress',
    id: 'pickupAddress',
    size: 250,
  },
  {
    header: 'Delivery Address',
    accessorKey: 'deliveryAddress',
    id: 'deliveryAddress',
    size: 250,
  },

  {
    header: 'Drop Location',
    accessorKey: 'dropLocation',
    id: 'dropLocation',
  },
  {
    header: 'Delivery Method',
    accessorKey: 'vehicleJobType',
    id: 'vehicleJobType',
  },
  {
    header: 'Client del Date & Time',
    accessorKey: 'clientDeliveryDateAndTime',
    id: 'clientDeliveryDateAndTime',
    size: 250,
  },
  {
    header: 'Collection Date Time',
    accessorKey: 'collectionDateTime',
    id: 'collectionDateTime',
    size: 250,
  },
  {
    header: 'Mov Req Date',
    accessorKey: 'movReqDate',
    id: 'movReqDate',
    size: 250,
  },
  {
    header: 'Mov Req Time',
    accessorKey: 'movReqTime',
    id: 'movReqTime',
    size: 250,
  },
  {
    header: 'Gate In Date Time',
    accessorKey: 'gateInDateTime',
    id: 'gateInDateTime',
    size: 250,
  },
  {
    header: 'Gate Out Date Time',
    accessorKey: 'gateOutDateTime',
    id: 'gateOutDateTime',
    size: 250,
  },
  {
    header: 'Port Slot',
    accessorKey: 'slotDateTime',
    id: 'slotDateTime',
  },
  {
    header: 'Truck No.',
    accessorKey: 'truckBatNo',
    id: 'truckBatNo',
  },
  {
    header: 'Driver',
    accessorKey: 'driverName',
    id: 'driverName',
  },
  {
    header: 'Cont. Mov Type',
    accessorKey: 'containerMovementType',
    id: 'containerMovementType',
  },
  {
    header: 'Door Position',
    accessorKey: 'doorPosition',
    id: 'doorPosition',
  },
  // {
  //   header: "Cont. No.",
  //   accessorKey: "containerNumber",
  //   id: "containerNumber",
  // },
  // {
  //   header: "Cont. Job No.",
  //   accessorKey: "containerJobNumber",
  //   id: "containerJobNumber",
  // },
  // {
  //   header: "Stage",
  //   accessorKey: "stage",
  //   id: "stage",
  // },
  // {
  //   header: "Trackmile Status",
  //   accessorKey: "trackmileStatus",
  //   id: "trackmileStatus",
  // },
  // {
  //   header: "Vehicle Job Type",
  //   accessorKey: "vehicleJobType",
  //   id: "vehicleJobType",
  // },
  // {
  //   header: "Truck Rego",
  //   accessorKey: "truckRego",
  //   id: "truckRego",
  // },
  // {
  //   header: "Trailer Number",
  //   accessorKey: "vehicleTrailerIds",
  //   id: "vehicleTrailerIds",
  // },
  // {
  //   header: "Fleet No",
  //   accessorKey: "truckFleetNo",
  //   id: "truckFleetNo",
  // },
  // {
  //   header: "Driver ID",
  //   accessorKey: "driverId",
  //   id: "driverId",
  // },
  // {
  //   header: "Booked Slot Date",
  //   accessorKey: "bookedSlotDate",
  //   id: "bookedSlotDate",
  //   filter: (rows, id, filterValues) => {
  //     let sd = filterValues?.[0] && new Date(filterValues?.[0]);
  //     let ed = filterValues?.[1] && new Date(filterValues?.[1]);
  //     return rows.filter((row) => {
  //       var time = new Date(row.values?.[id]);
  //       return (!sd || time >= sd) && (!ed || time <= ed);
  //     });
  //   },
  // },
  // {
  //   header: "Booked Slot Time",
  //   accessorKey: "bookedSlotTime",
  //   id: "bookedSlotTime",
  // },
  // {
  //   header: "User",
  //   accessorKey: "user",
  //   id: "user",
  // },
  // {
  //   header: "Updated Date Time",
  //   accessorKey: "updatedDateTime",
  //   id: "updatedDateTime",
  // },
]

export default stageTrackerColumns
