import React from 'react'
import { Form, ListGroup, Spinner } from 'react-bootstrap'
import {
  useDeleteTemplateMutation,
  useLazyGetTemplateData,
  useUpdateUserDefaultTemplateMutation,
  useUserTemplateListData,
} from '../../../../helper/hooks/api/allocations'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPen, faTrash } from '@fortawesome/free-solid-svg-icons'
import AlertModal from '../../../../components/Common/Modal/AlertModal'

export default function UserTemplatesList({ form }) {
  const { data: userTemplateList, error, isLoading } = useUserTemplateListData()

  const mutation = useUpdateUserDefaultTemplateMutation()

  const deleteTemplateMutation = useDeleteTemplateMutation()

  const fetchTemplateMutation = useLazyGetTemplateData()

  const handleEditClick = (templateId) => {
    fetchTemplateMutation.mutate(templateId, {
      onSuccess: (response) => {
        form.reset({
          selectedFields: response?.userTemplateFields.map((f) => ({
            fieldName: f.fieldName,
            fieldId: f.masterTemplateFieldId,
          })),
          templateName: response?.templateName,
          selectedChannel: response?.masterTemplateFunctionId,
          editingTemplateId: response?.id,
          editingTemplateName: response?.templateName,
          editingTemplate: response?.userTemplateFields,
        })
      },
    })
  }

  const editingTemplateId = form.watch('editingTemplateId')

  if (isLoading) return <Spinner />

  if (!Array.isArray(userTemplateList) || error) return null

  return (
    <ListGroup
      as="ol"
      numbered
      style={{
        maxHeight: 'calc(100vh - 120px)',
        overflow: 'auto',
      }}
    >
      {userTemplateList.map((template) => (
        <ListGroup.Item
          key={template.id}
          as="li"
          className="d-flex justify-content-between align-items-start"
          style={
            editingTemplateId === template.id
              ? {
                  backgroundColor: 'aqua',
                }
              : {}
          }
        >
          <div className="ms-2 me-auto">
            <div className="fw-bold">{template?.templateName}</div>
          </div>
          <div className="d-flex justify-content-around align-items-center gap-3">
            {fetchTemplateMutation.isPending &&
            fetchTemplateMutation.variables === template.id ? (
              <Spinner />
            ) : (
              <button
                disabled={
                  mutation.isPending || editingTemplateId === template.id
                }
                type="button"
                className="unset-all"
                onClick={() => handleEditClick(template.id)}
              >
                <FontAwesomeIcon
                  icon={faPen}
                  style={{ color: 'var(--primary-color)' }}
                />
              </button>
            )}
            {mutation.variables?.templateId === template.id &&
            mutation.isPending ? (
              <Spinner />
            ) : (
              <Form.Check
                checked={template.default}
                disabled={mutation.isPending}
                onChange={(e) => {
                  mutation.mutate({
                    templateId: template.id,
                    makeDefault: e.target.checked,
                  })
                }}
              />
            )}
            {deleteTemplateMutation.variables?.templateId === template.id ? (
              <Spinner />
            ) : (
              <AlertModal
                handleConfirm={(handleClose) => {
                  handleClose()
                  deleteTemplateMutation.mutate({ templateId: template.id })
                }}
                message={`Are you sure you want to delete - ${template?.templateName}`}
              >
                {(openConfirmModal) => (
                  <button
                    disabled={
                      mutation.isPending || editingTemplateId === template.id
                    }
                    type="button"
                    onClick={openConfirmModal}
                    className="unset-all"
                  >
                    <FontAwesomeIcon icon={faTrash} style={{ color: 'red' }} />
                  </button>
                )}
              </AlertModal>
            )}
          </div>
        </ListGroup.Item>
      ))}
    </ListGroup>
  )
}
