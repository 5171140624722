import React, { useEffect } from 'react'
import SuburbTable from './SuburbTable'
import fieldsListEmpty from './suburbDataRef.json'

const AddSuburb = (props) => {
  const {
    csvArray,
    setCsvArray,
    showError,
    showSuccessMsg,
    AddMultiZones,
    setEnableForm,
    setSuburbFormData,
    setProfileFormId,
    setProfileFormType,
  } = props

  useEffect(() => {
    setCsvArray({ data: [], igniter: new Date() })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const submitAddZone = () => {
    AddMultiZones(csvArray.data)
  }

  const openSuburbForm = () => {
    setEnableForm(true)
    setProfileFormType('add')
    setSuburbFormData(fieldsListEmpty)
  }

  return (
    <div>
      <h4>Add Suburb</h4>

      <button
        className="btn btn-sm btn-primary"
        onClick={() => openSuburbForm()}
      >
        Add New
      </button>

      {csvArray.data && csvArray.data.length > 0 && (
        <div>
          <SuburbTable
            csvArray={csvArray}
            setSuburbFormData={setSuburbFormData}
            setEnableForm={setEnableForm}
            setProfileFormId={setProfileFormId}
            setProfileFormType={setProfileFormType}
            // deleteRow={deleteRow}
            setCsvArray={setCsvArray}
          />

          <div className="text-end mt-4">
            <button onClick={() => submitAddZone()} className="btn btn-primary">
              Submit Suburb profile
            </button>
          </div>

          {showError && (
            <div className="text-danger my-4">
              <p>There is an issue in form submission. please try later.</p>
              {showError.length &&
                showError.map((eachError, i) => {
                  return (
                    <p key={i}>
                      {eachError.key} - {eachError.message}
                    </p>
                  )
                })}
            </div>
          )}

          {showSuccessMsg && (
            <p className="text-success my-4">
              Form has submitted successfully!
            </p>
          )}
        </div>
      )}
    </div>
  )
}

export default AddSuburb
