import React from 'react'
import ZoneTableForm from './ZoneTableForm'

const AddZone = (props) => {
  const {
    csvArray,
    zoneCodes,
    formError,
    showError,
    showSuccessMsg,
    addRow,
    deleteRow,
    type,
  } = props

  const submitAddZone = (e) => {
    e.preventDefault()
    props.AddMultiZones(e)
  }
  return (
    <div>
      <form onSubmit={submitAddZone}>
        <div className="row">
          <div className="col-md-9">
            <input type="hidden" value="1" id="customerCardId" />
            <h4>Add Zone profile</h4>
          </div>
          <div className="col-md-2 mb-2">
            <select className="form-select" id="zoneType" name="zone">
              <option value="">Please Select</option>
              <option value="ZONE10KM">Zone 10KM</option>
              <option value="ZONE15KM">Zone 15KM</option>
              <option value="out">Suburb</option>
            </select>
            <p className="invalid-feedback">this field is required</p>
          </div>
        </div>
        <ZoneTableForm
          zoneCodes={zoneCodes}
          deleteRow={deleteRow}
          csvArray={csvArray}
          addRow={addRow}
          type={type}
        />

        {formError && (
          <p className="text-danger my-4">
            Please check the error messages and submit the form again
          </p>
        )}

        <button type="submit" className="btn btn-primary">
          Submit
        </button>
        <button type="reset" className="btn btn-secondary ms-3">
          Reset
        </button>
      </form>
      {showError && (
        <div className="text-danger my-4">
          <p>There is an issue in form submission. please try later.</p>
          {showError.length &&
            showError.map((eachError, i) => {
              return (
                <p key={i}>
                  {eachError.key} - {eachError.message}
                </p>
              )
            })}
        </div>
      )}

      {showSuccessMsg && (
        <p className="text-success my-4">Form has submitted successfully!</p>
      )}
    </div>
  )
}

export default AddZone
