import { yupResolver } from '@hookform/resolvers/yup'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import React from 'react'
import { Button, Col, Form, Modal } from 'react-bootstrap'
import { useForm } from 'react-hook-form'
import { useSelector } from 'react-redux'
import * as Yup from 'yup'
import Input from '../../../../../../components/Common/Form/Fields/Input'
import InputNumber from '../../../../../../components/Common/Form/Fields/InputNumber'
import Select from '../../../../../../components/Common/Form/Fields/Select'
import { Success } from '../../../../../../components/utils/Toastify'
import fetchService from '../../../../../../components/utils/fetchService'
import config from '../../../../../../config'
import { getLookupDataState } from '../../../../../../store/slices/lookup-slice'

const defaultValues = {
  wharfJobType: '',
  stageMovementType: '',
  vehicleJobType: '',
  rateCode: '',
  description: '',
  location1Description: '',
  location1AreaCode: '',
  location2Description: '',
  location2AreaCode: '',
  dimensionUnit: '',
  sellRate: null,
  fromRange: null,
  toRange: null,
  handling: '',
  jobWindow: '',
  consignee: '',
  wharf: '',
  mtdehire: '',
  remarks: '',

  zone: '',
  rateType: '',

  // Not used
  typeSize: '',
  weightBar: '',
}

const validationSchema = Yup.object().shape({
  wharfJobType: Yup.string().required('Wharf Job Type is required'),
  stageMovementType: Yup.string().nullable(true),
  vehicleJobType: Yup.string(),
  rateCode: Yup.string(), //??autocomplete
  description: Yup.string(),
  location1AreaCode: Yup.string().required().nullable(),
  location1Description: Yup.string().required().nullable(),
  location2AreaCode: Yup.string().required().nullable(),
  location2Description: Yup.string().required().nullable(),
  dimensionUnit: Yup.string(),
  typeSize: Yup.string().nullable(true),
  sellRate: Yup.number().nullable(),
  weightBar: Yup.string().min(0).nullable(true),
  fromRange: Yup.number().min(0).nullable(true),
  toRange: Yup.number()
    .positive()
    .nullable(true)
    .transform((v) => (v === '' || Number.isNaN(v) ? null : v)),
  handling: Yup.string(),
  jobWindow: Yup.string(),
  consignee: Yup.string(),
  wharf: Yup.string().nullable(true),
  mtdehire: Yup.string(),
  remarks: Yup.string(),

  zone: Yup.string().required().nullable(true),
  rateType: Yup.string().required().nullable(true),
})

export default function AddCRDModal({
  profileId,
  profileIdKey,
  queryUrl,
  baseUrl,
  handleClose,
  selectedDetail,
  handleOnSubmit,
  customerCardId,
}) {
  const lookupsList = useSelector(getLookupDataState)
  const queryClient = useQueryClient()

  const { data: consignees } = useQuery({
    queryKey: [
      config.api.rates.getConsigneeAddressByCustomerCardId(customerCardId),
    ],
    queryFn: async () =>
      await fetchService({
        url: config.api.rates.getConsigneeAddressByCustomerCardId(
          customerCardId
        ),
      }),
    staleTime: 60000 * 5,
    enabled: !!customerCardId,
  })

  const {
    register,
    handleSubmit,
    formState: { errors },
    setError,
  } = useForm({
    resolver: yupResolver(validationSchema),
    reValidateMode: 'onChange',
    defaultValues: selectedDetail ?? defaultValues,
  })

  const mutation = useMutation({
    mutationFn: async (body) => {
      return await fetchService({
        url: `${baseUrl}/multi`,
        method: selectedDetail ? 'PUT' : 'POST',
        body,
      })
    },
    onError: (error) => {
      error?.data?.forEach(({ message, key }) => {
        setError(key, { type: 'custom', message })
      })
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [queryUrl],
      })
      if (selectedDetail) Success('CRD details updated Successfully')
      else Success('CRD created Successfully')
      handleClose()
    },
  })

  const submitAddZone = (formData) => {
    formData[profileIdKey] = profileId
    if (handleOnSubmit) handleOnSubmit(formData)
    else mutation.mutate([formData])
  }

  return (
    <Modal show={true} onHide={handleClose} size="lg" centered>
      <Modal.Header closeButton>
        <Modal.Title>CRD Form</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={handleSubmit(submitAddZone)}>
          <div className="row">
            <Col md={12}>
              <h3>General Info</h3>
            </Col>
            <Select
              name="wharfJobType"
              register={register}
              options={lookupsList?.wharfJobTypeEnums}
              errors={errors}
              required
            />
            <Select
              name="jobWindow"
              register={register}
              options={lookupsList?.jobWindows}
              errors={errors}
            />
            <Select
              name="vehicleJobType"
              register={register}
              options={lookupsList?.vehicleJobTypes}
              setOptionLabel={(o) => `${o.id} - ${o.trailerType}`}
              optionValueKey="id"
              errors={errors}
            />

            <hr className="my-2" />
            <Col md={12}>
              <h3>Container Info</h3>
            </Col>
            <Select
              name="dimensionUnit"
              register={register}
              options={lookupsList?.dimensionUnits}
              setOptionLabel={(o) => `${o.id} - ${o.description}`}
              optionValueKey="id"
              errors={errors}
            />

            <InputNumber name="fromRange" register={register} errors={errors} />
            <InputNumber name="toRange" register={register} errors={errors} />

            <Select
              name="handling"
              register={register}
              options={lookupsList?.distinctConducts}
              optionValueKey="id"
              errors={errors}
            />

            <hr className="my-2" />
            <Col md={12}>
              <h3>Route Details</h3>
            </Col>
            <Select
              name="stageMovementType"
              register={register}
              options={lookupsList?.stageMovementTypes}
              optionValueKey="transportStage"
              errors={errors}
            />
            <Select
              name="wharf"
              register={register}
              options={lookupsList?.wharfCodes}
              optionValueKey="wharfCode"
              errors={errors}
            />
            <Select
              name="mtdehire"
              register={register}
              options={lookupsList?.mtdehires}
              optionValueKey="code"
              errors={errors}
            />
            <Input name="remarks" register={register} errors={errors} />
            <Select
              name="zone"
              register={register}
              options={lookupsList?.zoneCodes}
              optionValueKey="zoneCode"
              optionLabelKey="zoneCode"
              errors={errors}
              required
            />

            <hr className="my-2" />
            <Col md={12}>
              <h3>Location Details</h3>
            </Col>
            <Select
              name="location1AreaCode"
              register={register}
              options={lookupsList?.areaCodes}
              optionValueKey="areaCode"
              errors={errors}
              required
              md={6}
            />
            <Input
              name="location1Description"
              md={6}
              register={register}
              errors={errors}
              required
            />
            <Select
              name="location2AreaCode"
              register={register}
              options={lookupsList?.areaCodes}
              optionValueKey="areaCode"
              errors={errors}
              required
              md={6}
            />
            <Input
              name="location2Description"
              md={6}
              register={register}
              errors={errors}
              required
            />

            <hr className="my-2" />
            <Col md={12}>
              <h3>Rate Details</h3>
            </Col>
            <Select
              name="rateType"
              label="Rates"
              register={register}
              options={Object.keys(lookupsList?.rateTypeEnums)}
              required
              errors={errors}
            />
            <Select
              name="rateCode"
              register={register}
              options={lookupsList?.rateCodes}
              setOptionLabel={(o) => `${o.rateCode} - ${o.description}`}
              errors={errors}
            />
            <Input
              name="description"
              md={6}
              register={register}
              errors={errors}
            />
            <InputNumber name="sellRate" register={register} errors={errors} />
            <Select
              name="consignee"
              register={register}
              options={consignees}
              errors={errors}
              optionValueKey="ccConsigneeAddressId"
              optionLabelKey="consigneeName"
            />
          </div>
          <Button type="submit">{selectedDetail ? 'Update' : 'Submit'}</Button>
        </Form>
      </Modal.Body>
    </Modal>
  )
}
