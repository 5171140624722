import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import omit from 'lodash/omit'
import { routeConfigWpr } from './routes'
import { setCurrentRoute } from './store/slices/route-slice'
import { getIsLookupDataEmpty } from './store/slices/lookup-slice'
import Spinner from './components/Spinner/Spinner'

export default function RenderComponent(props) {
  const { path } = props.match
  const dispatch = useDispatch()
  const isLookupDataEmpty = useSelector(getIsLookupDataEmpty)

  useEffect(() => {
    const currentRoute = routeConfigWpr.find((route) => route.path === path)
    dispatch(setCurrentRoute(omit(currentRoute, 'component')))
  }, [dispatch, path])

  return isLookupDataEmpty ? <Spinner /> : props.children
}
