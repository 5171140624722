import Accordion from '../../../../../../components/Common/Accordion/Accordion'
import './RateCardInfo.scss'

const RateCardInfo = (props) => {
  const advancedLinks = [
    { name: 'Copy Rate Card', event: 'copy-crd' },
    { name: 'Edit Sell Rate', event: 'edit-sale' },
    { name: 'Edit Percentage Change', event: 'edit-per-chng' },
  ]
  return (
    <div className="card">
      <div className="card-header d-flex justify-content-between align-items-center">
        <h3>Rate Card Info</h3>
      </div>
      <div className="card-body">
        <form>
          <Accordion title="Advanced Settings">
            <ol className="advanced-settings m-0">
              {advancedLinks.map((link, index) => (
                <li
                  className="advanced-settings__item"
                  key={index}
                  onClick={props.$emitEvents.bind(this, link)}
                >
                  {link.name}
                </li>
              ))}
            </ol>
          </Accordion>
        </form>
      </div>
    </div>
  )
}
export default RateCardInfo
