import { yupResolver } from '@hookform/resolvers/yup'
import { useQuery } from '@tanstack/react-query'
import axios from 'axios'
import isEmpty from 'lodash/isEmpty'
import React, { useCallback, useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import * as Yup from 'yup'
import { ColSelect } from '../../../../../components/Common/Form/Fields/Select'
import FormErrorText from '../../../../../components/Common/Form/FormErrorText'
import { ICONS } from '../../../../../components/Common/Icons'
import ContainerWithHeading from '../../../../../components/styles/Containers/ContainerWithHeading'
import * as DateFun from '../../../../../components/utils/DateFun'
import {
  TextBox,
  TextInputWithLabelField,
} from '../../../../../components/utils/TextBox'
import * as Toastify from '../../../../../components/utils/Toastify'
import { renderOptionsFromDataArr } from '../../../../../components/utils/Utils'
import fetchService from '../../../../../components/utils/fetchService'
import config from '../../../../../config'
import { getLookupDataState } from '../../../../../store/slices/lookup-slice'
import { useOrdersContext } from '../../context/orderOperationsContext'
import { getConsigneesNames } from '../api'
import HoldStatus from '../components/HoldStatus'
import ContainersList from './ContainersList'
import ContainerServiceRates from './components/ContainerServiceRates'
import DistinctConduct from './components/DistinctConduct'
import PortSlot from './components/PortSlot'
import SpecialServicesField from './components/SpecialServicesField'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCirclePlus } from '@fortawesome/free-solid-svg-icons/faCirclePlus'

export const OrderContainer = ({
  setOperationsData,
  type,
  containerDataList,
  getContainerData,
  setContainerDetailsId,
  customerOrderData,
}) => {
  const lookupsList = useSelector(getLookupDataState)
  const [addContainer, setAddContainerState] = useState(false)
  const [containerData, setContainerData] = useState({
    orderContainerEDO: {},
  })
  const [localContainerDataList, setLocalContainerData] = useState(null)

  const operationsCtxData = useOrdersContext()

  const orderStageMovementType = customerOrderData?.stageMovementType
  const orderWharfJobType = customerOrderData?.wharfJobType
  const orderModules = customerOrderData?.modules
  const orderWharfCode = customerOrderData?.orderVesselDetails?.wharfCode

  const { data: consigneeNames } = useQuery({
    queryKey: [
      '/customercard/consignee-address/consignee-name',
      operationsCtxData.customerId,
    ],
    queryFn: () => getConsigneesNames(operationsCtxData?.customerId),
    enabled: !!operationsCtxData?.customerId,
    staleTime: 60_000 * 15,
  })
  const { data: shipperNames } = useQuery({
    queryKey: [
      '/customercard/shipper-address/shipper-name',
      operationsCtxData.customerId,
    ],
    queryFn: async () =>
      await fetchService({
        url: `${config.api.shipperNames}${operationsCtxData?.customerId}`,
      }),
    enabled: !!operationsCtxData?.customerId,
    staleTime: 60_000 * 15,
  })

  const shouldShowMtPark = () => {
    return (
      orderStageMovementType?.toUpperCase().includes('MT') ||
      orderStageMovementType?.toUpperCase().includes('MTP_E') ||
      orderStageMovementType?.toUpperCase().includes('MP')
    )
  }

  const shouldShowWareHouseCode = () => {
    return (
      orderStageMovementType?.toUpperCase().includes('CF') ||
      orderStageMovementType?.toUpperCase().includes('CFS') ||
      orderStageMovementType?.toUpperCase().includes('WF') ||
      orderStageMovementType?.toUpperCase().includes('WMS')
    )
  }

  const isWareHouseCodeMandatory = orderStageMovementType
    ?.toUpperCase()
    .includes('WMS')

  const shouldShowServiceYard = () => {
    return (
      orderStageMovementType?.toUpperCase().includes('SY') ||
      orderStageMovementType?.toUpperCase().includes('SYD') ||
      orderStageMovementType?.toUpperCase().includes('SVYD')
    )
  }

  const shouldShowRailHead = () => {
    return orderStageMovementType?.toUpperCase().includes('RL')
  }

  const validationSchema = Yup.object().shape({
    ...(orderWharfJobType?.toUpperCase() === 'EXPORT' && {
      ccShipperAddressId: Yup.string()
        .nullable()
        .required('Please select a Shipper Code'),
      shipperName: Yup.string().required('Shipper Name is required'),
    }),

    ...(orderWharfJobType?.toUpperCase() === 'IMPORT' && {
      ccConsigneeAddressId: Yup.string()
        .nullable()
        .required('Please select a Consignee Code'),
      consigneeName: Yup.string().required('Consignee Name is required'),
    }),

    ...(shouldShowMtPark() && {
      mtDehire: Yup.string().nullable().required('mtDehire is required'),
    }),

    ...(shouldShowServiceYard() && {
      serviceYard: Yup.string().nullable().required('serviceYard is required'),
    }),

    ...(isWareHouseCodeMandatory && {
      wareHouseCode: Yup.string()
        .nullable()
        .required('Warehouse Code is required'),
    }),

    ...(shouldShowRailHead() && {
      railHead: Yup.string().nullable().required('Rail Head is required'),
    }),

    containerNumber: Yup.string()
      .nullable()
      .required('Container No is required')
      .matches(/^[a-zA-Z 0-9_-]+$/, {
        message: 'Alphanumeric characters or underscores only',
        excludeEmptyString: true,
      }),

    sealNo: Yup.string()
      .nullable()
      .matches(/^[a-zA-Z 0-9_-]+$/, {
        message: 'Alphanumeric characters or underscores only',
        excludeEmptyString: true,
      })
      .required('Seal No is required'),

    sizeType: Yup.string().nullable().required('Size Type is required'),
    cargoType: Yup.string().nullable().required('Cargo Type is required'),
    deliveryAddress: Yup.string()
      .nullable()
      .required('Delivery Address is required'),

    temperature: Yup.string()
      .notRequired()
      .when('cargoType', {
        is: (value) => value?.includes('REF'),
        then: (yup) => yup.required('Temperature is required').min(1),
        otherwise: (yup) => yup.nullable(),
      }),

    grossWeight: Yup.string().nullable().required('Gross Weight is required'),
    tareWeight: Yup.number().nullable().required('Tare Weight is required'),
    pickupAddress: Yup.string()
      .nullable()
      .required('Pickup Address is required'),
    dimension: Yup.string().nullable().optional(),
    stageMovementType: Yup.string()
      .nullable()
      .required('Stage Movement Type is required'),
    specialServiceMap: Yup.array(
      Yup.object({
        serviceId: Yup.string().required('Please select a service'),
        depot: Yup.string().required('Please select a depot'),
      })
    ).optional(),

    /**
     * Optional Fields
     * * eidopin
     * * specialHandling
     * * deliveryInstructions
     *
     * * differentShipperAddress
     * * emptyCollectionInstruction
     * * dehireInstructions
     */
    /**
     * ! Removes Fields
     * * differentConsigneeAddress
     */
  })

  const form = useForm({
    resolver: yupResolver(validationSchema),
    reValidateMode: 'onChange',
    defaultValues: { ...containerData },
  })

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    clearErrors,
  } = form

  useEffect(() => {
    if (containerData) {
      if (!isEmpty(containerData?.specialServiceMap)) {
        setValue(
          'specialServiceMap',
          Object.entries(containerData?.specialServiceMap).map(
            ([key, value]) => ({ serviceId: key, depot: value })
          )
        )
      }
      setValue('ccShipperAddressId', containerData.ccShipperAddressId)
      setValue('shipperName', containerData?.shipperName)
      setValue('containerNumber', containerData.containerNumber)
      setValue('containerRef', containerData.containerRef)
      setValue('sizeType', containerData.sizeType)
      setValue('sealNo', containerData.sealNo)
      setValue('deliveryAddress', containerData.deliveryAddress)
      setValue('cargoType', containerData.cargoType)
      setValue('specialHandling', containerData.specialHandling)
      setValue('imoCode', containerData.imoCode)
      setValue('unCode', containerData.unCode)
      setValue('doorPosition', containerData.doorPosition)
      setValue(
        'edoReceivedDateAndTime',
        DateFun.getFullDate(containerData.edoReceivedDateAndTime)
      )
      setValue('temperature', containerData.temperature)
      setValue('deliveryInstructions', containerData.deliveryInstructions)
      setValue('shippingLine', containerData.shippingLine)
      setValue('grossWeight', containerData.grossWeight)
      setValue(
        'clientDeliveryDateAndTime',
        DateFun.getFullDate(containerData.clientDeliveryDateAndTime)
      )
      setValue(
        'collectionDateTime',
        DateFun.getFullDate(containerData.collectionDateTime)
      )
      setValue('dehireInstructions', containerData.dehireInstructions)
      setValue(
        'emptyCollectionInstruction',
        containerData.emptyCollectionInstruction
      )
      setValue('tareWeight', containerData.tareWeight)
      setValue('pickupAddress', containerData.pickupAddress)
      setValue('eidopin', containerData.eidopin)
      setValue('dimension', containerData.dimension)
      setValue(
        'expectedDateClient',
        DateFun.getShortDate(containerData.expectedDateClient)
      )
      setValue('ccConsigneeAddressId', containerData.ccConsigneeAddressId)
      setValue('consigneeName', containerData?.consigneeName)

      setValue('differentShipperAddress', containerData.differentShipperAddress)
      setValue('stageMovementType', containerData.stageMovementType)
      setDefaultPrimaryYard(containerData)
      setValue('wareHouseCode', containerData.wareHouseCode)
      setValue('serviceYard', containerData.serviceYard)
      setValue('railHead', containerData.railHead)
      setValue('mtDehire', containerData.mtDehire)

      // Removed Fields
      // setValue('differentConsigneeAddress',containerData.differentConsigneeAddress)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [containerData])

  const getContainerListData = (orderCustomerDetailsId) => {
    const url =
      config.api.baseUrl +
      '/operations/order/container?orderCustomerDetailsId=' +
      orderCustomerDetailsId
    const requestOptions = {
      method: 'GET',
      headers: { Authorization: 'Bearer ' + localStorage.token },
    }
    fetch(url, requestOptions)
      .then((response) => response.json())
      .then((data) => {
        setLocalContainerData(data)
      })
      .catch((error) => {
        Toastify.Error(error?.response?.data?.error)
      })
  }

  const setDefaultPrimaryYard = (data = {}) => {
    if (data?.primaryYard) setValue('primaryYard', data?.primaryYard)
    else {
      let defaultYardCode = lookupsList?.yards.find(
        (yard) => yard.description === 'SWIFT ENFIELD description'
      )?.code
      setValue('primaryYard', defaultYardCode)
    }
  }

  const setAddContainerTrue = () => {
    setAddContainerState(true)
    let _containerListData =
      localContainerDataList?.length > 0
        ? localContainerDataList
        : containerDataList

    setValue(
      'shippingLine',
      customerOrderData?.orderVesselDetails?.shippingAgent
    )
    setValue(
      'dimension',
      lookupsList?.dimensionUnits.find((dimension) => dimension.id === 'CONT')
        .id
    )

    const _address = lookupsList?.wharfCodes.find(
      (wharf) => wharf.wharfCode === orderWharfCode
    )?.address

    if (orderWharfJobType?.toUpperCase() === 'IMPORT')
      setValue('pickupAddress', _address)
    else if (orderWharfJobType?.toUpperCase() === 'EXPORT')
      setValue('deliveryAddress', _address)

    setValue('stageMovementType', customerOrderData.stageMovementType)
    if (_containerListData && _containerListData.length > 0) {
      let _containerData = _containerListData[0]
      setValue('ccShipperAddressId', _containerData.ccShipperAddressId)
      setValue('shipperName', _containerData?.shipperName)
      setDefaultPrimaryYard(_containerData)
      setValue('deliveryAddress', _containerData.deliveryAddress)
      setValue('pickupAddress', _containerData.pickupAddress)
    } else {
      setDefaultPrimaryYard()
    }
  }

  const onSubmitOrderContainer = (formData) => {
    let options = {
      orderCustomerDetailsId: operationsCtxData.orderCustomerDetailsId,
      orderContainerDetailsId: containerData?.orderContainerDetailsId,
      containerNumber: formData.containerNumber,
      containerRef: formData?.containerRef,
      sizeType: formData.sizeType,
      sealNo: formData.sealNo,
      imoCode: formData?.imoCode,
      unCode: formData?.unCode,
      doorPosition: formData?.doorPosition,
      deliveryAddress: formData.deliveryAddress,
      cargoType: formData.cargoType,
      specialHandling: formData.specialHandling,
      edoReceivedDateAndTime: DateFun.getFullDate(
        formData.edoReceivedDateAndTime
      ),
      temperature: formData.temperature,
      differentShipperAddress: formData.differentShipperAddress,
      shippingLine: formData.shippingLine,
      grossWeight: formData.grossWeight,
      clientDeliveryDateAndTime: DateFun.getFullDate(
        formData.clientDeliveryDateAndTime
      ),
      collectionDateTime: DateFun.getFullDate(formData.collectionDateTime),
      dehireInstructions: formData.dehireInstructions,
      emptyCollectionInstruction: formData.emptyCollectionInstruction,
      tareWeight: formData.tareWeight,
      pickupAddress: formData.pickupAddress,
      dimension: formData.dimension,
      eidopin: formData.eidopin,
      isStageModified: false,
      ccConsigneeAddressId: formData.ccConsigneeAddressId,
      deliveryInstructions: formData.deliveryInstructions,
      ccShipperAddressId: formData.ccShipperAddressId,
      wharfCode: orderWharfCode,
      stageMovementType: formData.stageMovementType,
      primaryYard: formData.primaryYard,
      wareHouseCode: formData.wareHouseCode,
      serviceYard: formData.serviceYard,
      railHead: formData.railHead,
      mtDehire: formData.mtDehire,
      // Removed Fields
      // differentConsigneeAddress: formData.differentConsigneeAddress,
    }

    if (
      !isEmpty(formData?.specialServiceMap) &&
      Array.isArray(formData?.specialServiceMap)
    ) {
      options.specialServiceMap = {}
      formData.specialServiceMap.forEach((service) => {
        options.specialServiceMap[service.serviceId] = service.depot
      })
    }

    let requestType = containerData?.orderContainerDetailsId ? 'PUT' : 'POST'
    let requestUrl =
      operationsCtxData && operationsCtxData.orderContainerDetailsId
        ? config.api.baseUrl +
          config.api.container +
          '/' +
          operationsCtxData.orderContainerDetailsId
        : config.api.baseUrl + config.api.container

    axios({
      method: requestType, //you can set what request you want to be
      url: requestUrl,
      data: options,
      headers: {
        Authorization: 'Bearer ' + localStorage.token,
      },
    })
      .then(function (response) {
        getContainerListData(response.data.orderCustomerDetailsId)
        setAddContainerState(false)
        setContainerData({})
        Toastify.Success('Container details submitted successfuly..!')
      })
      .catch(function (error) {
        error?.response?.data?.data?.map(({ key, message }) =>
          Toastify.Error(`${key} - ${message}`)
        )
      })
  }

  useEffect(() => {
    setAddContainerState(false)
  }, [operationsCtxData])

  useEffect(() => {
    if (containerData) {
      setContainerDetailsId(containerData.orderContainerDetailsId)

      setOperationsData({
        ...operationsCtxData,
        orderContainerDetailsId: containerData.orderContainerDetailsId,
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [containerData])

  let containerListData =
    localContainerDataList?.length > 0
      ? localContainerDataList
      : containerDataList

  const renderWharf = () => {
    const { wharfCodes } = lookupsList

    return (
      <>
        <div className="col-md-2">
          <label>Wharf :</label>
        </div>
        <div className="col-md-2 mb-2">
          {type !== 'view' && (
            <>
              <select value={orderWharfCode} className="form-select" disabled>
                {renderOptionsFromDataArr(wharfCodes, 'wharfCode', 'wharfCode')}
              </select>
            </>
          )}
          {type === 'view' && <h5 className="blue-title">{orderWharfCode}</h5>}
        </div>
        <div className="col-md-2">
          <label>Wharf description :</label>
        </div>
        <div className="col-md-2">
          <h5 className="blue-title">
            {
              wharfCodes.find((wharf) => wharf.wharfCode === orderWharfCode)
                ?.description
            }
          </h5>
        </div>
      </>
    )
  }

  const renderMovementType = () => {
    return (
      <>
        <div className="col-md-2">
          <label>Movement Type :</label>
        </div>
        <div className="col-md-2 mb-2">
          {type !== 'view' && (
            <>
              <select
                className={`form-select ${
                  errors?.stageMovementType ? 'is-invalid' : ''
                }`}
                id="stageMovementType"
                name="stageMovementType"
                {...register('stageMovementType')}
                disabled
              >
                <option value="">Please Select</option>
                {lookupsList?.stageMovementTypes.map((item) => (
                  <option key={item.transportStage} value={item.transportStage}>
                    {item.description}
                  </option>
                ))}
              </select>
              {errors?.stageMovementType && (
                <p className="invalid-feedback">
                  {errors?.stageMovementType?.message}
                </p>
              )}
            </>
          )}
          {type === 'view' && (
            <h5 className="blue-title">{containerData?.stageMovementType}</h5>
          )}
        </div>
        {/* Primary Yard */}
        <div className="col-md-2">
          <label>Primary Yard :</label>
        </div>
        <div className="col-md-2 mb-2">
          {type !== 'view' && (
            <>
              <select
                className={`form-select ${
                  errors?.primaryYard ? 'is-invalid' : ''
                }`}
                id="primaryYard"
                name="primaryYard"
                {...register('primaryYard')}
              >
                <option value="">Please Select</option>
                {lookupsList?.yards?.map((item) => (
                  <option key={item.code} value={item.code}>
                    {item.description}
                  </option>
                ))}
              </select>
              {errors?.primaryYard && (
                <p className="invalid-feedback">
                  {errors?.primaryYard.message}
                </p>
              )}
            </>
          )}
          {type === 'view' && (
            <h5 className="blue-title">{containerData?.primaryYard}</h5>
          )}
        </div>
      </>
    )
  }

  const renderMovementTypeDependentFields = () => {
    const getErrorClassName = (id) => {
      return errors?.[id] ? 'is-invalid' : ''
    }
    return (
      <>
        {/* Mt Park */}
        {shouldShowMtPark() ? (
          <>
            <div className="col-md-2">
              <label>MT Park :</label>
            </div>
            <div className="col-md-2 mb-2">
              {type !== 'view' && (
                <>
                  <select
                    className={`form-select ${getErrorClassName('mtDehire')}`}
                    id="mtDehire"
                    name="mtDehire"
                    {...register('mtDehire')}
                  >
                    <option value="">Please Select</option>
                    {renderOptionsFromDataArr(
                      lookupsList?.mtdehires,
                      'code',
                      'description'
                    )}
                  </select>
                  {errors?.mtDehire && (
                    <p className="invalid-feedback">
                      {errors?.mtDehire?.message}
                    </p>
                  )}
                </>
              )}
              {type === 'view' && (
                <h5 className="blue-title">{containerData?.mtDehire}</h5>
              )}
            </div>
          </>
        ) : (
          <div className="col-md-4" />
        )}

        {/* WareHouse Code */}
        {shouldShowWareHouseCode() ? (
          <>
            <div className="col-md-2">
              <label>WareHouse Code :</label>
            </div>
            <div className="col-md-2 mb-2">
              {type !== 'view' && (
                <>
                  <select
                    className={`form-select ${getErrorClassName(
                      'wareHouseCode'
                    )}`}
                    id="wareHouseCode"
                    name="wareHouseCode"
                    {...register('wareHouseCode')}
                  >
                    <option value="">Please Select</option>
                    {renderOptionsFromDataArr(
                      lookupsList?.wareHouses,
                      'code',
                      'description'
                    )}
                  </select>
                  {errors?.wareHouseCode && (
                    <p className="invalid-feedback">
                      {errors?.wareHouseCode?.message}
                    </p>
                  )}
                </>
              )}
              {type === 'view' && (
                <h5 className="blue-title">{containerData?.wareHouseCode}</h5>
              )}
            </div>
          </>
        ) : (
          <div className="col-md-4" />
        )}

        <div className="col-md-8" />

        {/* Service Yard */}
        {shouldShowServiceYard() ? (
          <>
            <div className="col-md-2">
              <label>Service Yard :</label>
            </div>
            <div className="col-md-2 mb-2">
              {type !== 'view' && (
                <>
                  <select
                    className={`form-select ${getErrorClassName(
                      'serviceYard'
                    )}`}
                    id="serviceYard"
                    name="serviceYard"
                    {...register('serviceYard')}
                  >
                    <option value="">Please Select</option>
                    {renderOptionsFromDataArr(
                      lookupsList?.serviceYards,
                      'code',
                      'description'
                    )}
                  </select>
                  {errors?.serviceYard && (
                    <p className="invalid-feedback">
                      {errors?.serviceYard?.message}
                    </p>
                  )}
                </>
              )}
              {type === 'view' && (
                <h5 className="blue-title">{containerData?.serviceYard}</h5>
              )}
            </div>
          </>
        ) : (
          <div className="col-md-4" />
        )}

        {/* Rail Head */}
        {shouldShowRailHead() ? (
          <>
            <div className="col-md-2">
              <label>Rail Head :</label>
            </div>
            <div className="col-md-2 mb-2">
              {type !== 'view' && (
                <>
                  <select
                    className={`form-select ${getErrorClassName('railHead')}`}
                    id="railHead"
                    name="railHead"
                    {...register('railHead')}
                  >
                    <option value="">Please Select</option>
                    {renderOptionsFromDataArr(
                      lookupsList?.railHeads,
                      'code',
                      'description'
                    )}
                  </select>
                  {errors?.railHead && (
                    <p className="invalid-feedback">
                      {errors?.railHead?.message}
                    </p>
                  )}
                </>
              )}
              {type === 'view' && (
                <h5 className="blue-title">{containerData?.railHead}</h5>
              )}
            </div>
          </>
        ) : (
          <div className="col-md-4" />
        )}
      </>
    )
  }

  const renderDeliveryAddress = () => {
    /* Delivery Address */
    return (
      <TextInputWithLabelField
        name="deliveryAddress"
        register={register}
        errors={errors}
        viewMode={type}
        defaultData={containerData}
        ratio={[2, 2]}
      />
    )
  }
  const renderPickupAddress = () => {
    /* Pickup Address */
    return (
      <TextInputWithLabelField
        name="pickupAddress"
        register={register}
        errors={errors}
        viewMode={type}
        defaultData={containerData}
        ratio={[2, 2]}
      />
    )
  }

  const handleEditClicks = useCallback((container) => {
    setContainerData(container)
    setAddContainerState(true)
  }, [])

  const handleAddContainerClick = (e) => {
    e.preventDefault()
    setAddContainerTrue(true)
  }

  return (
    <>
      <ContainersList
        containerListData={containerListData}
        type={type}
        handleEditClicks={handleEditClicks}
        orderModules={orderModules}
        getContainerData={getContainerData}
      />
      {!addContainer &&
      operationsCtxData?.orderCustomerDetailsId &&
      type !== 'view' &&
      containerData?.orderContainerDetailsId === undefined ? (
        <ContainerWithHeading
          Wrapper="div"
          HeaderComponent="div"
          headerClassName="m-3"
          headerContainerZIndex={1}
          heading={
            <button
              className="btn btn-primary"
              onClick={handleAddContainerClick}
              type="button"
            >
              Add Container
            </button>
          }
        />
      ) : null}

      {operationsCtxData?.orderCustomerDetailsId &&
      (addContainer || containerData?.orderContainerDetailsId) ? (
        <ContainerWithHeading
          Wrapper="div"
          wrapperProps={{ className: 'mb-3' }}
          heading="Container Delivery Details:"
          containerClassNames="p-0 pb-3"
        >
          <div className="d-flex py-2">
            <div className="d-flex ms-auto gap-4 me-2">
              <HoldStatus
                profileRecordUnqKey="orderContainerHoldStatusId"
                heading="Container Hold Status"
                profileId={operationsCtxData?.orderContainerDetailsId}
                profileIdKey="orderContainerDetailsId"
                url={`/operations/order/container/hold-status?orderContainerDetailsId=${operationsCtxData?.orderContainerDetailsId}`}
              />
              <PortSlot
                orderContainerDetailsId={
                  operationsCtxData?.orderContainerDetailsId
                }
                defaultWharfCode={orderWharfCode}
              />
              <DistinctConduct contextAPIData={operationsCtxData} />

              <ContainerServiceRates
                type={type}
                controllerData={operationsCtxData}
                controller="order"
              />
            </div>
          </div>
          <hr />
          <form
            autoComplete="off"
            onSubmit={handleSubmit(onSubmitOrderContainer)}
          >
            <SpecialServicesField form={form} type={type} />
            <hr />
            {/* Container Delivery Details */}
            <div className="p-3">
              <div className="row mb-5 form-wrapper">
                {/* MovementType And Primary Yard */}
                {renderMovementType()}
                {/* Wharf */}
                {renderWharf()}
                {orderWharfJobType?.toUpperCase() === 'EXPORT' && (
                  <>
                    {/* Shipper Code */}
                    <div className="col-md-2">
                      <label>Shipper Code :</label>
                    </div>
                    <div className="col-md-2 mb-2">
                      {type !== 'view' && (
                        <>
                          <div className="d-flex">
                            <select
                              className={`form-select ${
                                errors?.ccShipperAddressId ? 'is-invalid' : ''
                              }`}
                              id="ccShipperAddressId"
                              name="ccShipperAddressId"
                              {...register('ccShipperAddressId', {
                                onChange: (e) => {},
                              })}
                              onChange={(e) => {
                                const { value } = e.target
                                const shipper = shipperNames?.find(
                                  (shipper) => shipper?.id === +value
                                )
                                if (shipper) {
                                  setValue('shipperName', shipper.name)
                                  setValue('pickupAddress', shipper.address)
                                  clearErrors(['shipperName', 'pickupAddress'])
                                }
                              }}
                            >
                              <option value="">Please Select</option>
                              {shipperNames?.map((item) => (
                                <option key={item.id} value={item.id}>
                                  {item.code}
                                </option>
                              ))}
                            </select>

                            <Link
                              target="_blank"
                              style={{
                                width: '25px',
                                marginLeft: '10px',
                              }}
                              to={{
                                pathname: '/Customer/create/shipper',
                                state: {
                                  preSelectedCustomerId:
                                    customerOrderData?.customerId,
                                },
                              }}
                            >
                              {ICONS.ADD}
                            </Link>
                          </div>
                          <FormErrorText
                            errors={errors}
                            name="ccShipperAddressId"
                          />
                        </>
                      )}
                    </div>

                    {/* Shipper Name */}
                    <TextInputWithLabelField
                      name="shipperName"
                      register={register}
                      viewMode={type}
                      errors={errors}
                      defaultData={containerData}
                      ratio={[2, 2]}
                    />
                    {renderPickupAddress()}

                    {/* Delivery Address */}
                    <TextInputWithLabelField
                      name="deliveryAddress"
                      register={register}
                      errors={errors}
                      viewMode={type}
                      defaultData={containerData}
                      ratio={[2, 2]}
                    />
                  </>
                )}

                {orderWharfJobType?.toUpperCase() === 'IMPORT' && (
                  <>
                    {renderPickupAddress()}
                    {/* Consignee Code */}
                    <div className="col-md-2">
                      <label>Consignee Code :</label>
                    </div>
                    <div className="col-md-2 mb-2">
                      {type !== 'view' && (
                        <>
                          <div className="d-flex">
                            <select
                              className={`form-select ${
                                errors?.ccConsigneeAddressId ? 'is-invalid' : ''
                              }`}
                              id="ccConsigneeAddressId"
                              name="ccConsigneeAddressId"
                              {...register('ccConsigneeAddressId', {
                                onChange: (e) => {
                                  let consignee = consigneeNames.find(
                                    (cust) => cust.id === +e.target.value
                                  )
                                  if (consignee) {
                                    setValue('consigneeName', consignee.name)
                                    setValue(
                                      'deliveryAddress',
                                      consignee.address
                                    )
                                    clearErrors([
                                      'consigneeName',
                                      'deliveryAddress',
                                    ])
                                  }
                                },
                              })}
                            >
                              <option value="">Please Select</option>
                              {renderOptionsFromDataArr(
                                consigneeNames,
                                'id',
                                'code'
                              )}
                            </select>
                            <Link
                              target="_blank"
                              style={{
                                width: '25px',
                                marginLeft: '10px',
                              }}
                              to={{
                                pathname: '/Customer/create/consignee',
                                state: {
                                  preSelectedCustomerId:
                                    customerOrderData?.customerId,
                                },
                              }}
                            >
                              <FontAwesomeIcon
                                icon={faCirclePlus}
                                style={{ color: '#FFD43B', fontSize: '1.2rem' }}
                              />
                            </Link>
                          </div>
                          <FormErrorText
                            errors={errors}
                            name="ccConsigneeAddressId"
                          />
                        </>
                      )}
                      {type === 'view' && (
                        <h5 className="blue-title">
                          {containerData?.ccConsigneeAddressId}
                        </h5>
                      )}
                    </div>

                    {/* Consignee Name */}
                    <TextInputWithLabelField
                      name="consigneeName"
                      register={register}
                      viewMode={type}
                      errors={errors}
                      defaultData={containerData}
                      ratio={[2, 2]}
                    />

                    {/* Delivery Address */}
                    <TextInputWithLabelField
                      name="deliveryAddress"
                      register={register}
                      errors={errors}
                      viewMode={type}
                      defaultData={containerData}
                      ratio={[2, 2]}
                    />
                  </>
                )}

                {orderWharfJobType?.toUpperCase() === 'LOCAL' && (
                  <>
                    {renderPickupAddress()}
                    {renderDeliveryAddress()}
                    <div className="col-md-4" />
                  </>
                )}

                {/* Warehouse code, Railhead, Service Yard,  MT Park */}
                {renderMovementTypeDependentFields()}
                <br />
              </div>
            </div>
            {/* Container Details */}
            <>
              <hr />
              <div className="p-2 __header">
                <h3>Container Details</h3>
              </div>
              <hr />

              <div className="row mb-2 p-3">
                {/* First Column */}
                <div className="col-md-4">
                  <div className="row h-100 form-wrapper me-1">
                    {/* Container No : */}
                    <div className="col-md-6">
                      <label>Container No :</label>
                    </div>
                    <div className="col-md-6 mb-2">
                      <TextBox
                        register={register}
                        id="containerNumber"
                        errors={errors}
                        value=""
                        placeholder="Container No"
                        maxLength="20"
                        disabled={type === 'view'}
                        viewMode={type}
                        defaultData={containerData ?? {}}
                      />
                    </div>

                    {/* Container Ref */}
                    <TextInputWithLabelField
                      name="containerRef"
                      register={register}
                      errors={errors}
                      viewMode={type}
                      defaultData={containerData}
                    />

                    {/* Different Shipper / Consignee */}
                    {orderWharfJobType?.toUpperCase() === 'EXPORT' && (
                      <>
                        <div className="col-md-6">
                          <label>Different Shipper :</label>
                        </div>
                        <div className="col-md-6 mb-2">
                          <TextBox
                            register={register}
                            id="differentShipperAddress"
                            errors={errors}
                            value=""
                            placeholder="Different Shipper"
                            maxLength="20"
                            disabled={type === 'view'}
                            viewMode={type}
                            defaultData={containerData ?? {}}
                          />
                        </div>
                      </>
                    )}

                    {/* Seal No. */}
                    <div className="col-md-6">
                      <label>Seal No :</label>
                    </div>
                    <div className="col-md-6 mb-2">
                      <TextBox
                        register={register}
                        id="sealNo"
                        errors={errors}
                        value=""
                        placeholder="Seal No"
                        maxLength="20"
                        disabled={type === 'view'}
                        viewMode={type}
                        defaultData={containerData ?? {}}
                      />
                    </div>

                    {/* Cargo Type */}
                    <div className="col-md-6">
                      <label>Cargo Type :</label>
                    </div>
                    <div className="col-md-6 mb-2">
                      {type !== 'view' && (
                        <>
                          <select
                            className={`form-select ${
                              errors?.cargoType ? 'is-invalid' : ''
                            }`}
                            id="cargoType"
                            name="cargoType"
                            {...register('cargoType')}
                          >
                            <option value="">Please Select</option>
                            {lookupsList?.cargoTypes.map((item) => (
                              <option
                                key={item.cargoType}
                                value={item.cargoType}
                              >
                                {item.description}
                              </option>
                            ))}
                          </select>
                          {errors?.cargoType && (
                            <p className="invalid-feedback">
                              {errors?.cargoType?.message}
                            </p>
                          )}
                        </>
                      )}
                      {type === 'view' && (
                        <h5 className="blue-title">
                          {containerData && containerData.cargoType}
                        </h5>
                      )}
                    </div>

                    {/* Dimensions */}
                    <div className="col-md-6">
                      <label>Dimension :</label>
                    </div>
                    <div className="col-md-6 mb-2">
                      {type !== 'view' && (
                        <>
                          <select
                            className={`form-select ${
                              errors?.dimension ? 'is-invalid' : ''
                            }`}
                            id="dimension"
                            name="dimension"
                            {...register('dimension')}
                          >
                            <option value="">Please Select</option>
                            {lookupsList?.dimensionUnits.map((item) => (
                              <option key={item.id} value={item.id}>
                                {item.description}
                              </option>
                            ))}
                          </select>
                          {errors?.dimension && (
                            <p className="invalid-feedback">
                              {errors?.dimension?.message}
                            </p>
                          )}
                        </>
                      )}
                      {type === 'view' && (
                        <h5 className="blue-title">
                          {containerData && containerData.dimension}
                        </h5>
                      )}
                    </div>

                    {/* Special Handling */}
                    <div className="col-md-6">
                      <label>Special Handling :</label>
                    </div>
                    <div className="col-md-6 mb-2">
                      {type !== 'view' && (
                        <>
                          <select
                            className={`form-select ${
                              errors?.specialHandling ? 'is-invalid' : ''
                            }`}
                            id="specialHandling"
                            name="specialHandling"
                            {...register('specialHandling')}
                          >
                            <option value="">Please Select</option>
                            {renderOptionsFromDataArr(
                              lookupsList?.distinctConducts,
                              'id',
                              'description'
                            )}
                          </select>
                          {errors?.specialHandling && (
                            <p className="invalid-feedback">
                              {errors?.specialHandling?.message}
                            </p>
                          )}
                        </>
                      )}
                      {type === 'view' && (
                        <h5 className="blue-title">
                          {containerData && containerData.sizeType}
                        </h5>
                      )}
                    </div>
                    <TextInputWithLabelField
                      name="imoCode"
                      register={register}
                      errors={errors}
                      viewMode={type}
                      defaultData={containerData}
                    />
                    <TextInputWithLabelField
                      name="unCode"
                      register={register}
                      errors={errors}
                      viewMode={type}
                      defaultData={containerData}
                    />
                    <ColSelect
                      name="doorPosition"
                      register={register}
                      errors={errors}
                      options={lookupsList?.doorPositionEnums}
                      viewMode={type}
                      defaultData={containerData}
                    />
                  </div>
                </div>

                {/* Second Column */}
                <div className="col-md-4">
                  <div className="row h-100 form-wrapper me-1">
                    {/* Shipping Line */}
                    <div className="col-md-6">
                      <label>Shipping Line :</label>
                    </div>
                    <div className="col-md-6 mb-2">
                      {type === 'edit' ? (
                        <input
                          type="text"
                          className="form-control"
                          value={`${
                            customerOrderData?.orderVesselDetails?.shippingAgent
                          } - ${
                            lookupsList?.shippingAgents?.find(
                              (agent) =>
                                agent.shippingAgentCode ===
                                customerOrderData?.orderVesselDetails
                                  ?.shippingAgent
                            )?.description
                          }`}
                          disabled
                          placeholder="Shipping Line"
                        />
                      ) : (
                        <h5 className="blue-title">
                          {`${
                            customerOrderData?.orderVesselDetails?.shippingAgent
                          } - ${
                            lookupsList?.shippingAgents?.find(
                              (agent) =>
                                agent.shippingAgentCode ===
                                customerOrderData?.orderVesselDetails
                                  ?.shippingAgent
                            )?.description
                          }`}
                        </h5>
                      )}
                    </div>

                    {/* Temperature */}
                    <div className="col-md-6">
                      <label>Temperature :</label>
                    </div>
                    <div className="col-md-6 mb-2">
                      <TextBox
                        type="number"
                        register={register}
                        id="temperature"
                        errors={errors}
                        value=""
                        placeholder="Temperature"
                        maxLength="20"
                        disabled={type === 'view'}
                        viewMode={type}
                        defaultData={containerData}
                      />
                    </div>

                    {/* Size Type */}
                    <div className="col-md-6">
                      <label>Size Type :</label>
                    </div>
                    <div className="col-md-6 mb-2">
                      {type !== 'view' && (
                        <>
                          <select
                            className={`form-select ${
                              errors?.sizeType ? 'is-invalid' : ''
                            }`}
                            id="sizeType"
                            name="sizeType"
                            {...register('sizeType')}
                          >
                            <option value="">Please Select</option>
                            {lookupsList?.equipmentSizes.map((item) => (
                              <option key={item.size} value={item.size}>
                                {item.sizeType}
                              </option>
                            ))}
                          </select>
                          {errors?.sizeType && (
                            <p className="invalid-feedback">
                              {errors?.sizeType?.message}
                            </p>
                          )}
                        </>
                      )}
                      {type === 'view' && (
                        <h5 className="blue-title">
                          {containerData && containerData.sizeType}
                        </h5>
                      )}
                    </div>

                    {/* Gross Weight */}
                    <div className="col-md-6">
                      <label>Gross Weight (kg) :</label>
                    </div>
                    <div className="col-md-6 mb-2">
                      <TextBox
                        register={register}
                        id="grossWeight"
                        errors={errors}
                        value=""
                        placeholder="Gross Weight"
                        maxLength="20"
                        disabled={type === 'view'}
                        viewMode={type}
                        defaultData={containerData ?? {}}
                      />
                    </div>

                    {/* Tare Weight */}
                    <div className="col-md-6">
                      <label>Tare Weight (kg) :</label>
                    </div>
                    <div className="col-md-6 mb-2">
                      <TextBox
                        register={register}
                        id="tareWeight"
                        errors={errors}
                        value=""
                        placeholder="Tare Weight"
                        maxLength="8"
                        disabled={type === 'view'}
                        viewMode={type}
                        defaultData={containerData ?? {}}
                      />
                    </div>

                    {/* EIDO Pin */}
                    <div className="col-md-6">
                      <label>EIDO Pin :</label>
                    </div>
                    <div className="col-md-6 mb-2">
                      <TextBox
                        register={register}
                        id="eidopin"
                        errors={errors}
                        value=""
                        placeholder="EIDO Pin"
                        maxLength="20"
                        disabled={type === 'view'}
                        viewMode={type}
                        defaultData={containerData ?? {}}
                      />
                    </div>

                    {/* EDO Recvd Date & Time */}
                    <div className="col-md-6">
                      <label>EDO Recvd Date & Time :</label>
                    </div>
                    <div className="col-md-6 mb-2">
                      {type !== 'view' && (
                        <>
                          <input
                            className={`form-control ${
                              errors?.edoReceivedDateAndTime ? 'is-invalid' : ''
                            }`}
                            id="edoReceivedDateAndTime"
                            placeholder="dd:mm:yy"
                            name="Edo Received Date And Time"
                            type="datetime-local"
                            {...register('edoReceivedDateAndTime')}
                          />
                          {errors?.edoReceivedDateAndTime && (
                            <p className="invalid-feedback">
                              {errors?.edoReceivedDateAndTime?.message}
                            </p>
                          )}
                        </>
                      )}
                      {type === 'view' && (
                        <h5 className="blue-title">
                          {containerData?.edoReceivedDateAndTime}
                        </h5>
                      )}
                    </div>

                    {/* Client Delivery Date & Time */}
                    <div className="col-md-6">
                      <label>Client Delivery Date & Time :</label>
                    </div>
                    <div className="col-md-6 mb-2">
                      {type !== 'view' && (
                        <>
                          <input
                            className={`form-control ${
                              errors?.clientDeliveryDateAndTime
                                ? 'is-invalid'
                                : ''
                            }`}
                            id="select-date"
                            placeholder="dd:mm:yy"
                            name="Client Delivery Date And Time"
                            type="datetime-local"
                            {...register('clientDeliveryDateAndTime')}
                          />
                          {errors?.clientDeliveryDateAndTime && (
                            <p className="invalid-feedback">
                              {errors?.clientDeliveryDateAndTime?.message}
                            </p>
                          )}
                        </>
                      )}
                      {type === 'view' && (
                        <h5 className="blue-title">
                          {containerData &&
                            containerData.clientDeliveryDateAndTime}
                        </h5>
                      )}
                    </div>

                    {/* Collection Date & Time */}
                    <TextInputWithLabelField
                      register={register}
                      errors={errors}
                      name="collectionDateTime"
                      type="datetime-local"
                      defaultData={containerData}
                      viewMode={type}
                    />
                  </div>
                </div>

                {/* Third Column */}
                <div className="col-md-4">
                  <div className="row h-100 form-wrapper">
                    {/* Delivery Instructions */}
                    <div className="col-md-6">
                      <label>Delivery Instructions :</label>
                    </div>
                    <div className="col-md-6">
                      {type !== 'view' && (
                        <>
                          <textarea
                            {...register('deliveryInstructions')}
                            className={`form-control ${
                              errors?.deliveryInstructions ? 'is-invalid' : ''
                            }`}
                            id="deliveryInstructions"
                            rows="2"
                            maxLength={200}
                          />
                        </>
                      )}
                    </div>

                    {(orderWharfJobType?.toUpperCase() === 'IMPORT' ||
                      orderWharfJobType?.toUpperCase() === 'LOCAL') && (
                      <>
                        {/* Empty Collection Instructions */}
                        <div className="col-md-6">
                          <label>Empty Collection Instructions :</label>
                        </div>
                        <div className="col-md-6">
                          {type !== 'view' && (
                            <>
                              <textarea
                                {...register('emptyCollectionInstruction')}
                                className={`form-control ${
                                  errors?.emptyCollectionInstruction
                                    ? 'is-invalid'
                                    : ''
                                }`}
                                id="emptyCollectionInstruction"
                                rows="2"
                                maxLength={200}
                              />
                            </>
                          )}
                        </div>

                        {/* Dehire Instructions */}
                        <div className="col-md-6">
                          <label>Dehire Instructions :</label>
                        </div>
                        <div className="col-md-6 mb-2">
                          {type !== 'view' && (
                            <>
                              <textarea
                                {...register('dehireInstructions')}
                                className={`form-control ${
                                  errors?.dehireInstructions ? 'is-invalid' : ''
                                }`}
                                id="dehireInstructions"
                                rows="2"
                                maxLength={200}
                              />
                            </>
                          )}
                        </div>
                      </>
                    )}

                    <div className="col-md-12" />
                    <div className="col-md-12" />
                    <div className="col-md-12" />
                    <div className="col-md-12" />
                  </div>
                </div>
              </div>

              {type !== 'view' && (
                <div className="row me-3">
                  <div className="col-md-22 text-end">
                    <button className="btn btn-primary me-3" type="submit">
                      Save
                    </button>
                    <button
                      className="btn btn-danger"
                      onClick={(e) => {
                        e.preventDefault()
                        setAddContainerState(false)
                        setContainerData({
                          orderContainerEDO: {},
                        })
                      }}
                    >
                      Exit
                    </button>
                  </div>
                </div>
              )}
            </>
          </form>
        </ContainerWithHeading>
      ) : null}
    </>
  )
}
