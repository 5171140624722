const holdCode = {
  name: 'Hold Code',
  path: '/dataMgmt/customer/holdCode',
  req: '/',
  columns: 4,
  fields: [
    {
      name: 'ID',
      label: 'ID',
      type: 'text',
      validation: 'Alphabetic',
      fieldId: 'id',
      id: 'id',
      validationType: 'string',
      validations: [
        {
          type: 'required',
          params: ['ID is required'],
        },
        {
          type: 'max',
          params: [15, "ID can't be more than 15 characters"],
        },
        {
          type: 'min',
          params: [3, 'Field should be min 3 characters'],
        },
      ],
    },
    {
      name: 'Container',
      label: 'Container',
      type: 'checkbox',
      validation: 'Alphabetic',
      fieldId: 'container',
      id: 'container',
      validationType: 'boolean',
    },
    {
      name: 'Description',
      label: 'Description',
      type: 'textarea',
      validation: 'Alphabetic',
      fieldId: 'description',
      id: 'description',
      validationType: 'string',
      max: 30,
      validations: [
        {
          type: 'required',
          params: ['Description is required'],
        },
        {
          type: 'max',
          params: [30, "Description can't be more than 30 characters"],
        },
      ],
    },
    {
      name: 'Cargo',
      label: 'Cargo',
      type: 'checkbox',
      validation: 'Alphabetic',
      fieldId: 'cargo',
      id: 'cargo',
      validationType: 'boolean',
    },
    {
      name: 'Special Service',
      label: 'Special Service',
      type: 'select',
      loadLookUp: 'specialServices',
      fieldId: 'specialService',
      id: 'specialService',
    },
    {
      name: 'Block Unpack',
      label: 'Block Unpack',
      type: 'checkbox',
      validation: 'Alphabetic',
      fieldId: 'blockUnpack',
      id: 'blockUnpack',
      validationType: 'boolean',
    },
    {},
    {
      name: 'Block Movement Start',
      label: 'Block Movement Start',
      type: 'checkbox',
      validation: 'Alphabetic',
      fieldId: 'blockMovementStart',
      id: 'blockMovementStart',
      validationType: 'boolean',
    },
  ],
}
export default holdCode
