import React, { useEffect } from 'react'
import { yupResolver } from '@hookform/resolvers/yup'
import * as Yup from 'yup'
import axios from 'axios'
import { useForm } from 'react-hook-form'
import config from '../../../config'
import { TextBox } from '../../utils/TextBox'
import { Select } from '../../utils/Select'

const AgreedRatesForm = (props) => {
  const {
    agreedRatesArray,
    setAgreedRatesArray,
    type,
    lookupsList,
    defaultData,
    profileFormType,
    profileFormId,
    setEnableForm,
  } = props

  const validationSchema = Yup.object().shape({
    wharfJobType: Yup.string().required('Wharf Job Type is required'),
    stageMovementType: Yup.string(), //??autocomplete
    vehicleJobType: Yup.string(),
    rateCode: Yup.string(), //??autocomplete
    description: Yup.string(),
    pickUpFrom: Yup.string(),
    deliverTo: Yup.string(),
    dimensionUnit: Yup.string(),
    typeSize: Yup.string(),
    sellRate: Yup.string(),
    weightBar: Yup.string(),
    fromRange: Yup.number()
      .positive()
      .nullable(true)
      .transform((v) => (v === '' || Number.isNaN(v) ? null : v)),
    toRange: Yup.number()
      .positive()
      .nullable(true)
      .transform((v) => (v === '' || Number.isNaN(v) ? null : v)),
    handling: Yup.string(),
    jobWindow: Yup.string(),
    consignee: Yup.string(),
    wharf: Yup.string(),
    emptyDehire: Yup.string(),
    remarks: Yup.string(),

    // isActive: Yup.string(),
  })

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm({
    resolver: yupResolver(validationSchema),
    reValidateMode: 'onChange',
    defaultValues: defaultData,
  })

  useEffect(() => {
    setValue('quoteDate', new Date())
  }, [setValue])

  const submitAddZone = (formData) => {
    let newArray = agreedRatesArray.data || []
    if (profileFormType === 'edit_Add') {
      newArray.splice(profileFormId, 1, formData)
      setAgreedRatesArray({ data: newArray, igniter: new Date() })
      setEnableForm(false)
      return false
    }
    if (type === 'create') {
      if (profileFormType === 'add') {
        newArray.push(formData)
        setAgreedRatesArray({ data: newArray, igniter: new Date() })
        setEnableForm(false)
      } else if (profileFormType === 'import') {
        newArray.splice(profileFormId, 1, formData)
        setAgreedRatesArray({ data: newArray, igniter: new Date() })
        setEnableForm(false)
      }
    } else {
      const getSuburbObj = agreedRatesArray.data[profileFormId]
      const formID = getSuburbObj.ccSuburbProfileId
      let newFormData = formData
      newFormData.ccSuburbProfileId = formID

      let eachFormattedData = {}
      Object.keys(newFormData).forEach((item) => {
        let itemFormatted = newFormData[item]
        if (itemFormatted === '') {
          itemFormatted = null
        } else if (
          itemFormatted &&
          typeof itemFormatted === String &&
          itemFormatted.startsWith('$')
        ) {
          itemFormatted = item.replace('$', '')
        } else if (itemFormatted === 'NULL') {
          itemFormatted = null
        }
        eachFormattedData[item] = itemFormatted
      })

      axios({
        method: 'POST',
        url:
          config.api.baseUrl + '/customercard/consignee-agreed-rates/' + formID,
        data: eachFormattedData,
        headers: {
          Authorization: 'Bearer ' + localStorage.token,
        },
      })
    }
  }

  return (
    <div className="add-suburb-item">
      <div className="add-suburb-wpr">
        <h4>Agreed Rates</h4>
        <span className="close" onClick={() => setEnableForm(false)}>
          X
        </span>
        <div className="card2">
          <div className="card-body2">
            <form onSubmit={handleSubmit(submitAddZone)}>
              <div>
                <div className="bg-gray">
                  <div className="row">
                    <article className="col-md-4">
                      <div className="row">
                        <div className="col-md-4">
                          <label>Wharf Job Type</label>
                        </div>
                        <div className="col-md-8 mb-4">
                          <Select
                            register={register}
                            data={lookupsList?.wharfJobTypes}
                            id="wharfJobType"
                            errors={errors}
                            type={type}
                            displayKey="description"
                            valueKey="id"
                          />
                        </div>
                      </div>
                    </article>

                    <article className="col-md-4">
                      <div className="row">
                        <div className="col-md-4">
                          <label>Stage Movement Type</label>
                        </div>
                        <div className="col-md-8 mb-4">
                          <select
                            className={`form-select ${
                              errors?.stageMovementType ? 'is-invalid' : ''
                            }`}
                            id="stageMovementType"
                            name="stageMovementType"
                            {...register('stageMovementType')}
                            disabled={type === 'view'}
                          >
                            <option value="">Please Select</option>
                            {lookupsList?.stageMovementTypes?.map((item, i) => (
                              <option key={i} value={item.transportStage}>
                                {item.description}
                              </option>
                            ))}
                          </select>
                          {errors?.stageMovementType && (
                            <p className="invalid-feedback">
                              {errors?.stageMovementType?.message}
                            </p>
                          )}
                        </div>
                      </div>
                    </article>

                    <article className="col-md-4">
                      <div className="row">
                        <div className="col-md-4">
                          <label>Vehicle Job Type</label>
                        </div>
                        <div className="col-md-8 mb-4">
                          <select
                            className={`form-select ${
                              errors?.vehicleJobType ? 'is-invalid' : ''
                            }`}
                            id="vehicleJobType"
                            name="vehicleJobType"
                            {...register('vehicleJobType')}
                            disabled={type === 'view'}
                          >
                            <option value="">Please Select</option>
                            {lookupsList?.vehicleJobTypes?.map((item, i) => (
                              <option key={i} value={item.id}>
                                {item.id} - {item.trailerType}
                              </option>
                            ))}
                          </select>
                          {errors?.vehicleJobType && (
                            <p className="invalid-feedback">
                              {errors?.vehicleJobType?.message}
                            </p>
                          )}
                        </div>
                      </div>
                    </article>

                    <article className="col-md-4">
                      <div className="row">
                        <div className="col-md-4">
                          <label>Rate Code</label>
                        </div>
                        <div className="col-md-8 mb-4">
                          <select
                            className={`form-select ${
                              errors?.rateCode ? 'is-invalid' : ''
                            }`}
                            id="rateCode"
                            name="rateCode"
                            {...register('rateCode')}
                            disabled={type === 'view'}
                          >
                            <option value="">Please Select</option>
                            {lookupsList?.rateCodes?.map((item, i) => (
                              <option key={i} value={item.rateCode}>
                                {item.rateCode} - {item.description}
                              </option>
                            ))}
                          </select>
                          {errors?.rateCode && (
                            <p className="invalid-feedback">
                              {errors?.rateCode?.message}
                            </p>
                          )}
                        </div>
                      </div>
                    </article>

                    <article className="col-md-4">
                      <div className="row">
                        <div className="col-md-4">
                          <label>Pick Up From</label>
                        </div>
                        <div className="col-md-8 mb-4">
                          <Select
                            register={register}
                            data={lookupsList?.zoneCodes}
                            id="pickUpFrom"
                            errors={errors}
                            type={type}
                            displayKey="zoneCode"
                            valueKey="zoneCode"
                          />
                        </div>
                      </div>
                    </article>

                    <article className="col-md-4">
                      <div className="row">
                        <div className="col-md-4">
                          <label>Deliver To</label>
                        </div>
                        <div className="col-md-8 mb-4">
                          <Select
                            register={register}
                            data={lookupsList?.zoneCodes}
                            id="deliverTo"
                            errors={errors}
                            type={type}
                            displayKey="zoneCode"
                            valueKey="zoneCode"
                          />
                        </div>
                      </div>
                    </article>
                  </div>
                </div>

                <div className="odd-row">
                  <div className="row">
                    <article className="col-md-4">
                      <div className="row">
                        <div className="col-md-4">
                          <label>Dimension Unit</label>
                        </div>
                        <div className="col-md-8 mb-4">
                          <select
                            className={`form-select ${
                              errors?.dimensionUnit ? 'is-invalid' : ''
                            }`}
                            id="dimensionUnit"
                            name="dimensionUnit"
                            {...register('dimensionUnit')}
                            disabled={type === 'view'}
                          >
                            <option value="">Please Select</option>
                            {lookupsList?.dimensionUnits?.map((item, i) => (
                              <option key={i} value={item.id}>
                                {item.id} - {item.description}
                              </option>
                            ))}
                          </select>
                          {errors?.dimensionUnit && (
                            <p className="invalid-feedback">
                              {errors?.dimensionUnit?.message}
                            </p>
                          )}
                        </div>
                      </div>
                    </article>

                    <article className="col-md-4">
                      <div className="row">
                        <div className="col-md-4">
                          <label>Type Size</label>
                        </div>
                        <div className="col-md-8 mb-4">
                          <Select
                            register={register}
                            data={lookupsList?.equipmentTypes}
                            id="typeSize"
                            errors={errors}
                            type={type}
                            displayKey="description"
                            valueKey="id"
                          />
                        </div>
                      </div>
                    </article>

                    <article className="col-md-4">
                      <div className="row">
                        <div className="col-md-4">
                          <label>Sell Rate</label>
                        </div>
                        <div className="col-md-8 mb-4">
                          <TextBox
                            register={register}
                            id="sellRate"
                            errors={errors}
                            placeholder="Sell Rate"
                            maxLength="20"
                            type="number"
                          />
                        </div>
                      </div>
                    </article>

                    <article className="col-md-4">
                      <div className="row">
                        <div className="col-md-4">
                          <label>Weight Bar</label>
                        </div>
                        <div className="col-md-8 mb-4">
                          <TextBox
                            register={register}
                            id="weightBar"
                            errors={errors}
                            placeholder="Weight Bar"
                            maxLength="30"
                          />
                        </div>
                      </div>
                    </article>

                    <article className="col-md-4">
                      <div className="row">
                        <div className="col-md-4">
                          <label>From Range</label>
                        </div>
                        <div className="col-md-8 mb-4">
                          <TextBox
                            register={register}
                            id="fromRange"
                            type="number"
                            errors={errors}
                            placeholder="From Range"
                            maxLength="10"
                          />
                        </div>
                      </div>
                    </article>
                    <article className="col-md-4">
                      <div className="row">
                        <div className="col-md-4">
                          <label>To Range</label>
                        </div>
                        <div className="col-md-8 mb-4">
                          <TextBox
                            register={register}
                            id="toRange"
                            errors={errors}
                            placeholder="To Range"
                            maxLength="10"
                          />
                        </div>
                      </div>
                    </article>
                  </div>
                </div>

                <div className="bg-gray">
                  <div className="row">
                    <article className="col-md-4">
                      <div className="row">
                        <div className="col-md-4">
                          <label>Handling</label>
                        </div>
                        <div className="col-md-8 mb-4">
                          <Select
                            register={register}
                            data={lookupsList?.distinctConducts}
                            id="handling"
                            errors={errors}
                            type={type}
                            displayKey="description"
                            valueKey="id"
                          />
                        </div>
                      </div>
                    </article>

                    <article className="col-md-4">
                      <div className="row">
                        <div className="col-md-4">
                          <label>Job Window</label>
                        </div>
                        <div className="col-md-8 mb-4">
                          <Select
                            register={register}
                            data={lookupsList?.jobWindows}
                            id="jobWindow"
                            errors={errors}
                            type={type}
                            displayKey="description"
                            valueKey="jobWindow"
                          />
                        </div>
                      </div>
                    </article>

                    <article className="col-md-4">
                      <div className="row">
                        <div className="col-md-4">
                          <label>Consignee</label>
                        </div>
                        <div className="col-md-8 mb-4">
                          <Select
                            register={register}
                            data={lookupsList?.consignee}
                            id="consignee"
                            errors={errors}
                            type={type}
                            displayKey="label"
                            valueKey="value"
                          />
                        </div>
                      </div>
                    </article>

                    <article className="col-md-4">
                      <div className="row">
                        <div className="col-md-4">
                          <label>Wharf</label>
                        </div>
                        <div className="col-md-8 mb-4">
                          {/* <TextBox
                            register={register}
                            id="wharf"
                            errors={errors}
                            placeholder="Wharf"
                            maxLength="30"
                            type="text"
                          /> */}
                          <Select
                            register={register}
                            data={lookupsList?.wharfCodes}
                            id="wharf"
                            errors={errors}
                            type={type}
                            displayKey="description"
                            valueKey="wharfCode"
                          />
                        </div>
                      </div>
                    </article>
                    <article className="col-md-4">
                      <div className="row">
                        <div className="col-md-4">
                          <label>Empty Dehire</label>
                        </div>
                        <div className="col-md-8 mb-4">
                          <Select
                            register={register}
                            data={lookupsList?.emptyDehires}
                            id="emptyDehire"
                            errors={errors}
                            type={type}
                            displayKey="description"
                            valueKey="code"
                          />
                        </div>
                      </div>
                    </article>
                    <article className="col-md-4">
                      <div className="row">
                        <div className="col-md-4">
                          <label>Remarks</label>
                        </div>
                        <div className="col-md-8 mb-4">
                          <TextBox
                            register={register}
                            id="remarks"
                            errors={errors}
                            placeholder="Remarks"
                            maxLength="100"
                            type="text"
                          />
                        </div>
                      </div>
                    </article>
                  </div>
                </div>

                <section className="odd-row">
                  <div className="row">
                    <article className="col-md-4">
                      <div className="row">
                        <div className="col-md-4">
                          <label>Description</label>
                        </div>
                        <div className="col-md-8 mb-4">
                          <TextBox
                            register={register}
                            id="description"
                            errors={errors}
                            placeholder="description"
                            maxLength="100"
                          />
                        </div>
                      </div>
                    </article>
                  </div>
                </section>

                <div className="row">
                  <div className="col-12 text-right">
                    <button className="btn btn-primary" type="submit">
                      {type === 'create' ? 'Add' : 'Update'}
                    </button>
                    <button className="btn btn-secondary ms-2" type="reset">
                      Reset
                    </button>
                    <button
                      className="btn btn-outline-dange ms-2 float-end"
                      type="button"
                      onClick={() => setEnableForm(false)}
                    >
                      Close
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  )
}

export default AgreedRatesForm
