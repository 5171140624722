import { yupResolver } from '@hookform/resolvers/yup'
import { useQuery } from '@tanstack/react-query'
import React, { useEffect, useMemo, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useSelector } from 'react-redux'
import { Link, useHistory, useParams } from 'react-router-dom'
import * as Yup from 'yup'
import config from '../../config'
import { getLookupDataState } from '../../store/slices/lookup-slice'
import SearchableSelectField from '../Common/Form/Fields/SearchableSelectField'
import Spinner from '../Spinner/Spinner'
import fetchService from '../utils/fetchService'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons'
import { useSaveUserDetailsMutation } from '../../helper/hooks/api/cerebro'
import FormErrorText from '../Common/Form/FormErrorText'

const FILE_SIZE = 10000000
const SUPPORTED_FORMATS = ['image/jpg', 'image/jpeg', 'image/gif', 'image/png']

const globalSchema = Yup.object().shape({
  firstName: Yup.string()
    .required('first name is required')
    .matches(/^[a-zA-Z ]+$/, {
      message: 'Letters and spaces only',
      excludeEmptyString: true,
    })
    .max(50, 'First name must be at most 50 characters'),
  lastName: Yup.string()
    .nullable()
    .matches(/^[a-zA-Z ]+$/, {
      message: 'Letters and spaces only',
      excludeEmptyString: true,
    })
    .max(50, 'Last name must be at most 50 characters'),
  email: Yup.string().required('Email is required').email('Email is invalid'),

  roles: Yup.array(),

  imgtype: Yup.string(),
  profilePhoto: Yup.mixed()
    .notRequired()
    .test(
      'FILE_SIZE',
      'Image file is too big.',
      (value) =>
        !value || (value && value.length > 0 && value[0].size <= FILE_SIZE)
    )
    .test(
      'FILE_FORMAT',
      'Image file has unsupported format.',
      (value) =>
        !value ||
        (value && value.length > 0 && SUPPORTED_FORMATS.includes(value[0].type))
    ),
  profileGroup: Yup.string().required('profile group is required'),
  userName: Yup.string()
    .required('User name is required')
    .min(3, 'User name must be at least 3 characters')
    .max(30, 'User name must not exceed 40 characters'),
})

const passwordValidators = Yup.object().shape({
  password: Yup.string()
    .required('Password is required')
    .min(6, 'Password must be at least 6 characters')
    .max(30, 'Password must not exceed 40 characters'),
  confirmPassword: Yup.string()
    .required('Confirm Password is required')
    .oneOf([Yup.ref('password'), null], 'Confirm Password does not match'),
})

export default function CreateUser(props) {
  const { type } = props
  const { id } = useParams()
  let history = useHistory()
  const lookup = useSelector(getLookupDataState)
  const [userNameAvailability, setUserNameAvailability] = useState(false)
  const [userPhoto, setUserPhoto] = useState(false)
  const [userDetails, setUserDetails] = useState({})
  const [showPassword, setShowPassword] = useState(false)

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword)
  }

  const { data: profilePicBase64, isLoading } = useQuery({
    queryKey: ['/user/profile-pic', id],
    queryFn: async () =>
      await fetchService({
        url: `/user/profile-pic/${id}`,
        returnRaw: true,
      }),
    enabled: !!id,
  })

  const [userData, setUserData] = useState({
    userName: '',
    password: '',
    firstName: '',
    lastName: '',
    email: '',
    profileGroup: '',
    profilePhoto: '',
    roles: [],
  })

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
    setValue,
    getValues,
    unregister,
    control,
  } = useForm({
    resolver: yupResolver(
      type === 'create' ? globalSchema.concat(passwordValidators) : globalSchema
    ),
    defaultValues: userData,
  })

  const saveUserDetailsMutation = useSaveUserDetailsMutation()

  const onSubmitFunction = async (formData) => {
    if (!userNameAvailability || type === 'edit') {
      let newPayload = {
        userName: formData.userName,
        password: formData.password,
        firstName: formData.firstName,
        lastName: formData.lastName,
        email: formData.email,
        roles: formData.roles,
        // "profileType": formData.profileGroup,
        profileGroup: formData.profileGroup,
        profilePhoto: userPhoto ? appendBase64(userPhoto) : null,
      }
      if (type && type === 'edit') {
        newPayload.id = Number(id)
      }

      await saveUserDetailsMutation.mutateAsync(newPayload)
      history.push('/User')
    } else {
      alert('please check the user name availability')
    }
  }

  const validateUserName = (event) => {
    let val = event.target.value

    if (val.length >= 3) {
      const url = config.api.baseUrl + config.api.availability + val
      const requestOptions = {
        method: 'GET',
        headers: { Authorization: 'Bearer ' + localStorage.token },
      }

      fetch(url, requestOptions)
        .then((response) => response.json())
        .then((data) => {
          if (data === true) {
            setUserNameAvailability(false)
          } else {
            setUserNameAvailability(true)
          }
        })
        .catch((error) => {
          setUserNameAvailability(true)
        })
    }
  }

  const base64Tweak = (imgSrc) => {
    if (imgSrc.startsWith('data:image/png;base64')) {
      return imgSrc.replace('data:image/png;base64,', '')
    } else if (imgSrc.startsWith('data:image/jpeg;base64')) {
      return imgSrc.replace('data:image/jpeg;base64,', '')
    }
    return imgSrc
  }

  const appendBase64 = (imgSrc) => {
    if (
      !imgSrc.startsWith('data:image/png;base64') &&
      !imgSrc.startsWith('data:image/jpeg;base64')
    ) {
      return 'data:image/png;base64,' + imgSrc
    }
    return imgSrc
  }

  const readURL = (Event) => {
    const input = Event.currentTarget
    if (input.files && input.files[0]) {
      let reader = new FileReader()
      let imgSrc = ''
      reader.onload = function (e) {
        imgSrc = e.target.result

        setUserPhoto(imgSrc)
      }
      reader.readAsDataURL(input.files[0]) // convert to base64 string
    }
  }

  useEffect(() => {
    if ((type === 'view' || type === 'edit') && id) {
      if (type === 'edit') {
        setUserNameAvailability(false)
      }
      unregister('profilePhoto')

      const url = config.api.baseUrl + config.api.uniqueUser + id
      const requestOptions = {
        method: 'GET',
        headers: { Authorization: 'Bearer ' + localStorage.token },
      }

      fetch(url, requestOptions)
        .then((response) => response.json())
        .then((data) => {
          if (data.status === 500) {
            alert(
              `Unable to get the user details for the id: ${id}, please try later`
            )
            return false
          }
          setUserDetails(data)
          setUserPhoto(data.profilePhoto)

          if (type === 'edit') {
            setUserData({
              userName: data.userName,
              firstName: data.firstName,
              lastName: data.lastName,
              email: data.email,
              profileGroup: data.profileGroup,
              profilePhoto: '',
              roles: data.roles,
            })

            const newData = {
              userName: data.userName,
              firstName: data.firstName,
              lastName: data.lastName,
              email: data.email,
              profileGroup: data.profileGroup,
              profilePhoto: '',
              roles: data.roles,
            }

            reset(newData)
          }
        })
        .catch((error) => {
          console.error('fetch error', error)
          alert(
            `Unable to get the user details for this ${id} please try later`
          )
        })
    } else {
      setValue('imgtype', 'path')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [type])

  const deleteUser = () => {
    let answer = window.confirm(
      'Are you sure to delete ' +
        userDetails.firstName +
        ' ' +
        userDetails.lastName +
        ' ' +
        id
    )
    if (!answer) {
      return false
    }

    const url = config.api.baseUrl + config.api.uniqueUser + id
    const requestOptions = {
      method: 'DELETE',
      headers: { Authorization: 'Bearer ' + localStorage.token },
    }

    fetch(url, requestOptions)
      .then((response) => response.json())
      .then((data) => {
        alert('User id ' + id + ' has deleted successfully')
        history.push('/user')
      })
      .catch((error) => {
        console.error('fetch error', error)
        alert('Unable to delete the user now please try after some time!')
      })
  }

  const editPhoto = () => {
    setUserPhoto(false)
    register('profilePhoto', new File([], ''))
    const [firstName, lastName, userName, email, profileGroup, roles] =
      getValues([
        'firstName',
        'lastName',
        'userName',
        'email',
        'profileGroup',
        'roles',
      ])

    reset({
      userName,
      firstName,
      lastName,
      email,
      profileGroup,
      profilePhoto: '',
      password: getValues('password'),
      confirmPassword: getValues('confirmPassword'),
      imgtype: 'path',
      roles,
    })
  }

  const userImg = useMemo(() => {
    if (isLoading) return <Spinner />
    return (
      <img
        className="user-profile"
        src={
          userPhoto
            ? `data:${userDetails?.mimeType};base64,${profilePicBase64}`
            : '/assets/user-icon-placeholder.png'
        }
        alt="profile-pic"
        width={350}
        height={350}
      />
    )
  }, [isLoading, profilePicBase64, userDetails?.mimeType, userPhoto])

  return (
    <div className="user-container">
      <div className="user-wpr mt-4">
        <form
          className="row"
          autoComplete="off"
          onSubmit={handleSubmit(onSubmitFunction)}
        >
          <input type="hidden" autoComplete="false" />
          <div className="col-md-6">
            <div className="user-photo-wpr">
              {type !== 'view' ? (
                <label htmlFor="user-profile-img">
                  <img
                    className="user-profile edit"
                    width={350}
                    height={350}
                    src={
                      userPhoto
                        ? `data:image/jpeg;base64,${base64Tweak(userPhoto)}`
                        : '/assets/user-icon-placeholder.png'
                    }
                    alt=""
                  />
                  {!userPhoto && (
                    <>
                      <input
                        type="file"
                        accept="image/jpeg, image/png"
                        {...register('profilePhoto')}
                        onChange={readURL}
                        className={`form-control ${
                          errors.profilePhoto && 'is-invalid'
                        } d-none`}
                        id="user-profile-img"
                      />
                      <FormErrorText errors={errors} name="profilePhoto" />
                      <p>Upload your Profile</p>
                    </>
                  )}
                </label>
              ) : (
                userImg
              )}
            </div>
          </div>
          <div className="col-md-6">
            <div className="user-form white-bg-card">
              <div className="mb-3 row">
                <label htmlFor="firstName" className="col-sm-4 col-form-label">
                  First Name<sup className="required">*</sup>
                </label>
                <div className="col-sm-8">
                  {type !== 'view' ? (
                    <div>
                      <input
                        type="text"
                        id="firstName"
                        {...register('firstName')}
                        className={`form-control ${
                          errors.firstName && 'is-invalid'
                        }`}
                        placeholder="Please Enter Name"
                      />
                      <FormErrorText errors={errors} name="firstName" />
                    </div>
                  ) : (
                    <h4 className="mt-1 mb-0">{userDetails.firstName}</h4>
                  )}
                </div>
              </div>

              <div className="mb-3 row">
                <label htmlFor="lastName" className="col-sm-4 col-form-label">
                  Last Name
                </label>
                <div className="col-sm-8">
                  {type !== 'view' ? (
                    <div>
                      <input
                        type="text"
                        id="lastName"
                        name="lastName"
                        {...register('lastName')}
                        className={`form-control ${
                          errors.lastName && 'is-invalid'
                        }`}
                        placeholder="Please Last Name"
                      />
                      <FormErrorText errors={errors} name="lastName" />
                    </div>
                  ) : (
                    <h4 className="mt-1 mb-0">{userDetails.lastName}</h4>
                  )}
                </div>
              </div>

              <div className="mb-3 row">
                <label htmlFor="email" className="col-sm-4 col-form-label">
                  Email<sup className="required">*</sup>
                </label>
                <div className="col-sm-8">
                  {type !== 'view' ? (
                    <div>
                      <input
                        type="text"
                        id="email"
                        name="email"
                        {...register('email')}
                        className={`form-control ${
                          errors.email && 'is-invalid'
                        }`}
                        maxLength="50"
                        placeholder="Please Enter Email"
                      />
                      <FormErrorText errors={errors} name="email" />
                    </div>
                  ) : (
                    <h4 className="mt-1 mb-0">{userDetails.email}</h4>
                  )}
                </div>
              </div>
              {(type === 'create' || type === 'edit') && (
                <div className="mb-3 row">
                  <label htmlFor="password" className="col-sm-4 col-form-label">
                    Password
                    {type === 'create' && <sup className="required">*</sup>}
                  </label>
                  <div className="col-sm-8">
                    <div className="form-control password-input">
                      <input
                        type={showPassword ? 'text' : 'password'}
                        id="password"
                        name="password"
                        {...register('password')}
                        autoComplete="new-password"
                        className={`${errors.password ? 'is-invalid' : ''}`}
                        maxLength="30"
                        placeholder="Please Enter Password"
                      />
                      <span onClick={togglePasswordVisibility}>
                        {showPassword ? (
                          <FontAwesomeIcon icon={faEyeSlash} />
                        ) : (
                          <FontAwesomeIcon icon={faEye} />
                        )}
                      </span>
                    </div>
                    <FormErrorText errors={errors} name="password" />
                  </div>
                </div>
              )}
              {(type === 'create' || type === 'edit') && (
                <div className="mb-3 row">
                  <label
                    htmlFor="confirmPassword"
                    className="col-sm-4 col-form-label"
                  >
                    Confirm Password
                    {type === 'create' && <sup className="required">*</sup>}
                  </label>
                  <div className="col-sm-8">
                    <input
                      type="password"
                      id="confirmPassword"
                      name="confirmPassword"
                      autoComplete="new-password"
                      {...register('confirmPassword')}
                      className={`form-control ${
                        errors.confirmPassword ? 'is-invalid' : ''
                      }`}
                      maxLength="30"
                      placeholder="Please Enter Confirm Password"
                    />
                    <FormErrorText errors={errors} name="confirmPassword" />
                  </div>
                </div>
              )}
              <div>
                <input type="hidden" {...register('imgtype')} value="src" />
              </div>

              <div className="mb-3 row">
                <label htmlFor="userName" className="col-sm-4 col-form-label">
                  User Name<sup className="required">*</sup>
                </label>
                <div className="col-sm-8">
                  {type !== 'view' && type !== 'edit' ? (
                    <div>
                      <input
                        type="text"
                        id="userName"
                        autoComplete="off"
                        placeholder="Please Enter User Name"
                        {...register('userName')}
                        className={
                          (errors.userName || userNameAvailability
                            ? ' form-control is-invalid '
                            : 'form-control ') +
                          (!userNameAvailability
                            ? ' form-control is-valid '
                            : ' form-control ')
                        }
                        maxLength="30"
                        onChange={validateUserName}
                        defaultValue={
                          type === 'edit' ? userDetails.userName : ''
                        }
                        disabled={type === 'edit'}
                      />
                      <FormErrorText errors={errors} name="userName" />
                      {!errors?.userName && userNameAvailability && (
                        <p className="invalid-feedback">
                          Please enter a valid user
                        </p>
                      )}
                    </div>
                  ) : (
                    <h4 className="mt-1 mb-0">{userDetails.userName}</h4>
                  )}
                </div>
              </div>

              <div className="mb-3 row">
                <label htmlFor="email" className="col-sm-4 col-form-label">
                  Profile Group<sup className="required">*</sup>
                </label>
                <div className="col-sm-8">
                  {type !== 'view' ? (
                    <div>
                      <select
                        className={`form-select ${
                          errors.profileGroup && 'is-invalid'
                        }`}
                        {...register('profileGroup')}
                        defaultValue={
                          type === 'edit' ? userDetails.profileGroup : ''
                        }
                        aria-label="Default select example"
                      >
                        <option value="">Please select option</option>
                        <option value="Accounts">Accounts</option>
                        <option value="Admin">Admin</option>
                        <option value="CFS">CFS</option>
                        <option value="Operations">Operations</option>
                        <option value="Transport">Transport</option>
                        <option value="Yard">Yard</option>
                        <option value="Sales">Sales</option>
                      </select>
                      <FormErrorText errors={errors} name="profileGroup" />
                    </div>
                  ) : (
                    <h4 className="mt-1 mb-0">{userDetails.profileGroup}</h4>
                  )}
                </div>
              </div>

              <div className="mb-3 row">
                <label htmlFor="email" className="col-sm-4 col-form-label">
                  Role
                </label>
                <div className="col-sm-8">
                  {type !== 'view' ? (
                    <SearchableSelectField
                      isMulti
                      options={lookup.roles}
                      optionValueKey="id"
                      optionLabelKey="name"
                      name="roles"
                      control={control}
                      setValue={setValue}
                      SelectWrapper={'div'}
                    />
                  ) : (
                    <h4 className="mt-1 mb-0">
                      {userDetails.roles &&
                        userDetails.roles.map((x, i) => (
                          <span>
                            {x.name}{' '}
                            {userDetails.roles.length - 1 !== i && ', '}
                          </span>
                        ))}
                    </h4>
                  )}
                </div>
              </div>
              {type !== 'view' && (
                <div className={`row mb-3 ${!userPhoto ? 'd-none' : ''}`}>
                  <label className="col-sm-4 col-form-label">
                    Change photo
                  </label>
                  <div className="col-sm-8">
                    <span
                      className="btn btn-sm btn-secondary"
                      onClick={editPhoto}
                    >
                      Change photo
                    </span>
                  </div>
                </div>
              )}

              <div className="col-12 clearfix">
                {type !== 'view' ? (
                  <button
                    type="submit"
                    className="btn btn-primary float-end mt-3"
                  >
                    {type === 'edit' ? 'Update' : 'Submit'}
                  </button>
                ) : (
                  <div>
                    <Link
                      className="btn btn-primary me-3"
                      tabIndex="0"
                      role="button"
                      to={'/user/edit/' + id}
                    >
                      Edit
                    </Link>
                    <span
                      className="btn btn-secondary"
                      tabIndex="0"
                      role="button"
                      onClick={deleteUser}
                    >
                      Delete
                    </span>
                    <span
                      className="btn btn-info ms-3"
                      tabIndex="0"
                      role="button"
                      onClick={() => history.goBack()}
                    >
                      Back
                    </span>
                  </div>
                )}
              </div>
            </div>
          </div>
        </form>

        {saveUserDetailsMutation.isPending && <Spinner />}
      </div>
    </div>
  )
}
