import './Spinner.scss'
export default function Spinner() {
  return (
    <div className="spinner">
      <div className="pacman">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </div>
  )
}
