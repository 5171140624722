export const cerebroList = [
  {
    sectionHeader: 'Users',
    sectionHeaderImgSrc: '/assets/dashboard/user-icon.png',
    imgRedirectTo: '/User',
    liS: [
      { liText: 'Create User', pathTo: '/User/create' },
      { liText: 'User List', pathTo: '/User' },
      { liText: 'Profile Access', pathTo: '' },
    ],
  },
  {
    sectionHeader: 'Customers',
    sectionHeaderImgSrc: '/assets/customer-icon.png',
    imgRedirectTo: '/Customer',
    liS: [
      { liText: 'Create Customer', pathTo: '/Customer/create' },
      {
        liText: 'Create Consignee',
        pathTo: '/Customer/create/consignee',
      },
      { liText: 'Create Shipper', pathTo: '/Customer/create/shipper' },
      { liText: 'Customer Rate Card', pathTo: '/Customer/rate-card' },
      { liText: 'Customer List', pathTo: '/Customer' },
      { liText: 'Data Management', pathTo: '/customer/dataManagement' },
      { liText: 'Reports', pathTo: '' },
      { liText: 'Zone Profile', pathTo: '' },
    ],
  },
  {
    sectionHeader: 'Orders',
    sectionHeaderImgSrc: '/assets/orders-icon.png',
    imgRedirectTo: '/Operations',
    liS: [
      { liText: 'Create Orders', pathTo: '/Operations/create' },
      {
        liText: 'Port Planning Portal',
        pathTo: '/Operations/orders-slot',
      },
      { liText: 'Orders List', pathTo: '/Operations/list' },
      { liText: 'EDO', pathTo: '/orders/edo/automation' },
    ],
  },
  {
    sectionHeader: 'Container',
    sectionHeaderImgSrc: '/assets/dashboard/container-icon.png',
    imgRedirectTo: '#',
    liS: [
      {
        liText: 'Container Stage Tracker',
        pathTo: '/container/stage-tracker',
      },
      { liText: 'Container Pickup', pathTo: '/container/pickup' },
      { liText: 'Container Delivery', pathTo: '/container/delivery' },
      { liText: 'Container Alert', pathTo: '/container/controller/alert' },
    ],
  },
  {
    sectionHeader: 'Transport',
    sectionHeaderImgSrc: '/assets/dashboard/transport-icon.png',
    imgRedirectTo: '/transport/drivers',
    liS: [
      { liText: 'Drivers', pathTo: '/transport/drivers' },
      { liText: 'Drivers Dashboard', pathTo: '/transport/drivers/dashboard' },
      { liText: 'Vehicles', pathTo: '/transport/vehicles' },
      {
        liText: 'Service Provider',
        pathTo: '/transport/service-provider',
      },
      { liText: 'Documents', pathTo: '/transport/documents' },
    ],
  },
  {
    sectionHeader: 'Invoice',
    sectionHeaderImgSrc: '/assets/dashboard/invoice-icon.png',
    imgRedirectTo: '/invoice',
    liS: [
      { liText: 'Order Financial Card', pathTo: '/invoice-viewAllOFCs' },
      { liText: 'Invoice', pathTo: '/invoice' },
      {
        liText: 'Create Invoice',
        pathTo: '/invoice-page-not-found?fallbackUrl=/invoice',
      },
      { liText: 'Credit Notes', pathTo: '/invoice/creditNotes' },
    ],
  },
  {
    sectionHeader: 'Vessel',
    sectionHeaderImgSrc: '/assets/dashboard/container-icon.png',
    imgRedirectTo: '/vessel/schedule',
    liS: [
      { liText: 'Schedule', pathTo: '/vessel/schedule' },
      { liText: 'Routing', pathTo: '/vessel/routing' },
    ],
  },
  {
    sectionHeader: 'Capacity Planner',
    sectionHeaderImgSrc: '/assets/dashboard/container-icon.png',
    imgRedirectTo: '/capacity-planner/dashboard',
    liS: [{ liText: 'Default', pathTo: '/capacity-planner/default-planner' }],
  },
  {
    sectionHeader: 'Reports',
    sectionHeaderImgSrc: '/assets/dashboard/container-icon.png',
    imgRedirectTo: '/reports/management',
    liS: [{ liText: 'Management', pathTo: '/reports/management' }],
  },
]
export const wmsList = [
  {
    sectionHeader: 'Dashboard',
    sectionHeaderImgSrc: './assets/Dashboard_Icon.png',
    imgRedirectTo: '/wms-dashboard',
  },
  {
    sectionHeader: 'Packing List',
    sectionHeaderImgSrc: './assets/packing.png',
    imgRedirectTo: '/wms-packing-list',
  },
  {
    sectionHeader: 'Unpacking',
    sectionHeaderImgSrc: './assets/unpacking.png',
    imgRedirectTo: '/wms-unpacking-list',
    liS: [
      { liText: 'Unpacking List', pathTo: '/wms-unpacking-list' },
      { liText: 'Create WMS', pathTo: '/wms/operations/create' },
    ],
  },
  {
    sectionHeader: 'Reports',
    sectionHeaderImgSrc: './assets/reports.png',
    imgRedirectTo: '/wms-reports',
  },
]
export const ymsList = [
  {
    sectionHeader: 'Dashboard',
    sectionHeaderImgSrc: './assets/Dashboard_Icon.png',
    imgRedirectTo: '/yms-dashboard',
  },
  {
    sectionHeader: 'Truck Incoming',
    sectionHeaderImgSrc: './assets/packing.png',
    imgRedirectTo: '/yms-truck-incoming',
  },
  {
    sectionHeader: 'Truck Outgoing',
    sectionHeaderImgSrc: './assets/unpacking.png',
    imgRedirectTo: '/yms-truck-outgoing',
  },
  {
    sectionHeader: 'Gate Incoming',
    sectionHeaderImgSrc: './assets/reports.png',
    imgRedirectTo: '/yms-gate-incoming',
  },
  {
    sectionHeader: 'Gate Outgoing',
    sectionHeaderImgSrc: './assets/reports.png',
    imgRedirectTo: '/yms-gate-outgoing',
  },
]

export const clmList = [
  {
    sectionHeader: 'Dashboard',
    sectionHeaderImgSrc: './assets/Dashboard_Icon.png',
    imgRedirectTo: '/clm-dashboard',
  },
  {
    sectionHeader: 'Allocations',
    sectionHeaderImgSrc: './assets/Assets_icon.png',
    imgRedirectTo: '/loads-planning',
  },
  {
    sectionHeader: 'Drivers',
    sectionHeaderImgSrc: './assets/Drivers_icon.png',
    imgRedirectTo: '/driver-dashboard',
  },
  // {
  //   sectionHeader: 'Customers',
  //   sectionHeaderImgSrc: './assets/Customers_icon.png',
  //   imgRedirectTo: '/clm-dashboard',
  // },
  // {
  //   sectionHeader: 'Locations',
  //   sectionHeaderImgSrc: './assets/Location_icon.png',
  //   imgRedirectTo: '/clm-dashboard',
  // },
  {
    sectionHeader: 'Enigma Chat',
    sectionHeaderImgSrc: '/assets/customer-icon.png',
    imgRedirectTo: '/enigma-chat',
  },
  // {
  //   sectionHeader: 'Assets',
  //   sectionHeaderImgSrc: '/assets/.png',
  //   imgRedirectTo: '/enigma-chat',
  // },
  // {
  //   sectionHeader: 'Financials',
  //   sectionHeaderImgSrc: './assets/Financials_icon.png',
  //   imgRedirectTo: '/clm-dashboard',
  // },
  // {
  //   sectionHeader: 'Documents',
  //   sectionHeaderImgSrc: './assets/Document_icon.png',
  //   imgRedirectTo: '/clm-dashboard',
  // },
  // {
  //   sectionHeader: 'Reports',
  //   sectionHeaderImgSrc: './assets/Reports_icon.png',
  //   imgRedirectTo: '/clm-dashboard',
  // },
  // {
  //   sectionHeader: 'Yard',
  //   sectionHeaderImgSrc: './assets/containers-icon.png',
  //   imgRedirectTo: '/container/stage-tracker',
  // },
  // {
  //   sectionHeader: 'Settings',
  //   sectionHeaderImgSrc: './assets/Settings_icon.png',
  //   imgRedirectTo: '/clm-dashboard',
  // },
]

/* A list of modules that are available in the application. */
export const moduleNameList = [
  { title: 'Cerebro', link: 'dashboard' },
  { title: 'Allocation Planner', link: 'clm-dashboard' },
  { title: 'WMS', link: 'wms-dashboard' },
  { title: 'YMS', link: 'yms-dashboard' },
]
