import React from 'react'
import { ResponsivePie as NivoResponsivePie } from '@nivo/pie'

export default function ResponsivePie({ data, id = 'id', ...props }) {
  return (
    <NivoResponsivePie
      data={data}
      id={id}
      margin={{ top: 40, right: 60, bottom: 40, left: 60 }}
      innerRadius={0.7}
      padAngle={0.7}
      cornerRadius={10}
      activeOuterRadiusOffset={8}
      colors={{ scheme: 'purple_orange' }}
      borderWidth={1}
      borderColor={{ theme: 'background' }}
      arcLinkLabel="label"
      arcLinkLabelsSkipAngle={10}
      arcLinkLabelsTextColor={{ from: 'color', modifiers: [] }}
      arcLinkLabelsThickness={2}
      arcLinkLabelsColor={{ from: 'color' }}
      arcLabelsSkipAngle={10}
      arcLabelsTextColor={{
        from: 'color',
        modifiers: [['darker', 3]],
      }}
      legends={[]}
      {...props}
    />
  )
}
