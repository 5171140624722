import { faRotateRight } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useState } from 'react'
import { Col, Row, Spinner } from 'react-bootstrap'
import { useForm } from 'react-hook-form'
import { toast } from 'react-toastify'
import Input from '../../../../../components/Common/Form/Fields/Input'
import Table from '../../../../../components/Common/Tanstack-table/Table'
import IconButton from '../../../../../components/styles/Buttons/IconButton'
import {
  useDispatchLoadMutation,
  useJobsByStatus,
} from '../../../../../helper/hooks/api/allocations'
import useJobDetailsColumns from '../../hooks/useJobDetailsColumns'

export default function PlannedJobs() {
  const {
    watch,
    register,
    formState: { errors },
    reset,
  } = useForm({
    mode: 'all',
    defaultValues: {
      searchDate: '',
    },
  })

  const [selectedJob, setSelectedJob] = useState([])

  const formValues = watch()

  const {
    data: response,
    isLoading,
    error,
  } = useJobsByStatus('PLANNED', formValues)

  const { mutate } = useDispatchLoadMutation()

  const columns = useJobDetailsColumns(response)

  const dispatchJobs = () => {
    if (!selectedJob.length) {
      return toast.info('Please select loads for dispatch!')
    }
    mutate(selectedJob)
  }

  if (error) return <p>Error occurred while fetching data</p>

  if (isLoading) return <Spinner />

  return (
    <section>
      <Row>
        <Input
          register={register}
          errors={errors}
          name="searchDate"
          type="date"
          md={3}
        />
        <Col md={1} className="d-flex align-items-center">
          <IconButton title="Reset" type="button" onClick={() => reset()}>
            <FontAwesomeIcon icon={faRotateRight} />
          </IconButton>
        </Col>
        <Col md={8} className="d-flex">
          <button
            type="button"
            onClick={() => dispatchJobs()}
            className="btn btn-info ms-auto"
          >
            Load Dispatch
          </button>
        </Col>
      </Row>
      {isLoading && <Spinner />}
      {!Array.isArray(response.data) || response.data.length === 0 ? (
        <p style={{ flexGrow: 1 }}>
          No data available. Try changing search filters.
        </p>
      ) : (
        <Table
          columns={columns}
          data={response.data}
          setSelectedRows={setSelectedJob}
        />
      )}
    </section>
  )
}
