import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  fetchChatConversations,
  getSelectedChat,
} from '../../../../store/slices/enigmaChat-slice'
import {
  ParticipantsNames,
  ParticipantsProfilePics,
  getFormattedTimestampForMessage,
} from '../utils'

export default function ConversationItem({ conversation }) {
  const {
    lastMessageCreatedDate,
    lastMessage,
    conversationCreatedDate,
    chatParticipantResponseDTOS,
    conversationSid,
  } = conversation
  const dispatch = useDispatch()
  const selectedChat = useSelector(getSelectedChat)

  const handleConversationOnClick = () => {
    if (
      selectedChat === null ||
      conversationSid !== selectedChat.conversationSid
    )
      dispatch(fetchChatConversations(conversationSid))
  }

  return (
    <div
      className="d-flex justify-content-between conversation-item"
      style={
        conversationSid === selectedChat?.conversationSid
          ? {
              backgroundColor: 'cyan',
            }
          : {}
      }
      onClick={handleConversationOnClick}
    >
      <div className="d-flex text-center p-0 ms-3">
        <ParticipantsProfilePics
          chatParticipantResponseDTOS={chatParticipantResponseDTOS}
          className="avatar-icon"
        />
        <div className="p-2 ms-4 text-left">
          <p className="name-meta m-0">
            <ParticipantsNames
              chatParticipantResponseDTOS={chatParticipantResponseDTOS}
            />
          </p>
          <p className="name-meta">{lastMessage}</p>
        </div>
      </div>
      <div className="p-2">
        <p className="time">
          {getFormattedTimestampForMessage(
            lastMessageCreatedDate ?? conversationCreatedDate
          )}
        </p>
      </div>
    </div>
  )
}
