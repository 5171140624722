import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

export default function InfoCard({ icon, label, value, children }) {
  return (
    <div className="__container info-card">
      <div className="d-flex justify-content-around align-items-center w-100">
        <div className="d-flex flex-column gap-3 mt-3">
          <strong className="value">{children || value}</strong>
          <p className="">
            - <i>{label}</i>
          </p>
        </div>
        <div className="icon">
          <FontAwesomeIcon icon={icon} size="4x" />
        </div>
      </div>
    </div>
  )
}
