import React from 'react'
import YmsTemplate from '../Template'
import config from '../../../config'
const GateOutgoing = () => {
  return (
    <YmsTemplate
      Header="Gate Outgoing"
      url={`${config.api.baseUrl}${config.api.yms.getGateOutgoingDto}`}
    />
  )
}


export default GateOutgoing