import startCase from 'lodash/startCase'
import { useMemo } from 'react'
import { checkValidColValue } from '../../../../components/utils/Utils'

export default function useJobDetailsColumns(response) {
  const jobDetailsColumnsKeys = useMemo(() => {
    if (!response) return []
    const data = response?.data
    if (!Array.isArray(data) || data.length === 0) return []

    if (
      !Array.isArray(data[0]?.containerLoadSearchResponses) ||
      data[0].containerLoadSearchResponses.length === 0
    )
      return []

    const obj = data[0].containerLoadSearchResponses[0]

    return Object.keys(obj).filter((k) => checkValidColValue(obj[k]))
  }, [response])

  return useMemo(() => {
    const columns = jobDetailsColumnsKeys.map((key) => ({
      header: startCase(key),
      accessorFn: (row) => row.containerLoadSearchResponses[0][key],
      id: key,
    }))

    columns.unshift(
      {
        header: 'Job Number',
        id: 'jobNumber',
        accessorKey: 'jobNumber',
      },
      {
        header: 'Job Status',
        id: 'jobDriverStatus',
        accessorKey: 'jobDriverStatus',
      },
      {
        header: 'Truck Rego',
        id: 'truckRego',
        accessorKey: 'truckRego',
      }
    )

    return columns
  }, [jobDetailsColumnsKeys])
}
