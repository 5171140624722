import React from 'react'
import {
  useTable,
  useRowSelect,
  useFilters,
  useGlobalFilter,
  usePagination,
} from 'react-table'
import TablePagination from '../../Common/Table/TablePagination'
import isEmpty from 'lodash/isEmpty'
// Define a default UI for filtering
export const DefaultColumnFilter = ({
  column: { filterValue, preFilteredRows, setFilter },
}) => {
  const count = preFilteredRows.length

  return (
    <input
      value={filterValue || ''}
      onChange={(e) => {
        setFilter(e.target.value || undefined)
      }}
      style={{
        fontSize: '16px',
        borderRadius: '5px',
        paddingLeft: '8px',
      }}
      placeholder={`Search ${count} records...`}
    />
  )
}

export const SelectColumnFilter = ({
  column: { filterValue, setFilter, preFilteredRows, id },
}) => {
  const options = React.useMemo(() => {
    const options = new Set()
    preFilteredRows.forEach((row) => {
      options.add(row.values[id])
    })
    return [...options.values()]
  }, [id, preFilteredRows])

  // Render a multi-select box
  return (
    <select
      value={filterValue}
      onChange={(e) => {
        setFilter(e.target.value || undefined)
      }}
    >
      <option value="">All</option>
      {options.map((option, i) => (
        <option key={i} value={option}>
          {option}
        </option>
      ))}
    </select>
  )
}

export const DateRangeColumnFilter = ({
  column: { filterValue = [], preFilteredRows, setFilter, id },
}) => {
  const [min, max] = React.useMemo(() => {
    let min = new Date(preFilteredRows[0].values[id])
    let max = new Date(preFilteredRows[0].values[id])
    preFilteredRows.forEach((row) => {
      min = new Date(row.values[id]) <= min ? new Date(row.values[id]) : min
      max = new Date(row.values[id]) >= max ? new Date(row.values[id]) : max
    })
    return [min, max]
  }, [id, preFilteredRows])
  return (
    <div
      style={{
        display: 'flex',
      }}
    >
      <input
        value={filterValue[0] || ''}
        type="date"
        min={min.toISOString().slice(0, 10)}
        onChange={(e) => {
          const val = e.target.value
          setFilter((old = []) => [val ? val : undefined, old[1]])
        }}
        style={{
          width: '170px',
          marginRight: '0.5rem',
        }}
      />
      to
      <input
        value={filterValue[1] || ''}
        type="date"
        max={max.toISOString().slice(0, 10)}
        onChange={(e) => {
          const val = e.target.value
          setFilter((old = []) => [old[0], val ? val : undefined])
        }}
        style={{
          width: '170px',
          marginLeft: '0.5rem',
        }}
      />
    </div>
  )
}

export const dateBetweenFilterFn = (rows, id, filterValues) => {
  let sd = new Date(filterValues[0])
  let ed = new Date(filterValues[1])
  return rows.filter((r) => {
    let time = new Date(r.values[id])
    if (filterValues.length === 0) return rows
    return time >= sd && time <= ed
  })
}

dateBetweenFilterFn.autoRemove = (val) => !val

const FilteredTable = ({
  columns,
  data,
  setSelection,
  isColumnFilter,
  onModalClose,
  getTrProps = (props) => props,
  draggable,
  style,
}) => {
  const filterTypes = React.useMemo(
    () => ({
      dateBetween: dateBetweenFilterFn,
      text: (rows, id, filterValue) => {
        return rows.filter((row) => {
          const rowValue = row.values[id]
          return rowValue !== undefined
            ? String(rowValue)
                .toLowerCase()
                .startsWith(String(filterValue).toLowerCase())
            : true
        })
      },
    }),
    []
  )

  const defaultColumn = React.useMemo(
    () => ({
      // Let's set up our default Filter UI
      Filter: DefaultColumnFilter,
    }),
    []
  )

  const IndeterminateCheckbox = React.forwardRef(
    ({ indeterminate, ...rest }, ref) => {
      const defaultRef = React.useRef()
      const resolvedRef = ref || defaultRef

      React.useEffect(() => {
        resolvedRef.current.indeterminate = indeterminate
      }, [resolvedRef, indeterminate])

      return <input type="checkbox" ref={resolvedRef} {...rest} />
    }
  )

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    selectedFlatRows,
    canPreviousPage,
    canNextPage,
    pageOptions,
    nextPage,
    previousPage,
    gotoPage,
    setPageSize,
    pageCount,
    allColumns,
    getToggleHideAllColumnsProps,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data,
      defaultColumn,
      filterTypes,
      autoResetSelectedRows: false,
      initialState: {
        pageIndex: 0,
        pageSize: 200,
        hiddenColumns: setSelection
          ? ['containerStageTrackerId']
          : ['containerStageTrackerId', 'selection'],
      },
    },
    useFilters,
    useGlobalFilter,
    usePagination,
    useRowSelect,
    (hooks) => {
      hooks.visibleColumns.push((columns) => [
        {
          id: 'selection',
          Header: ({ getToggleAllPageRowsSelectedProps }) => (
            <div>
              <IndeterminateCheckbox {...getToggleAllPageRowsSelectedProps()} />
            </div>
          ),
          Cell: ({ row }) => (
            <div>
              <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />
            </div>
          ),
        },
        ...columns,
      ])
    }
  )

  React.useEffect(() => {
    setSelection && setSelection(selectedFlatRows.map((row) => row.original))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedFlatRows.length])

  const dragStart = (event, rowData) => {
    if (isEmpty(selectedFlatRows))
      event.dataTransfer.setData('data', JSON.stringify([rowData]))
    else
      event.dataTransfer.setData(
        'data',
        JSON.stringify(selectedFlatRows.map((row) => row.original))
      )
  }

  return (
    <>
      <div className="table-responsive" style={style}>
        {isColumnFilter && (
          <div className="row">
            <div>
              <IndeterminateCheckbox {...getToggleHideAllColumnsProps()} />{' '}
              Toggle All
            </div>
            {allColumns.map((column) => (
              <div className="col-md-3" key={column.id}>
                <label>
                  <input type="checkbox" {...column.getToggleHiddenProps()} />{' '}
                  {column.id}
                </label>
              </div>
            ))}
          </div>
        )}

        <table className="table table-hover" {...getTableProps()}>
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <th {...column.getHeaderProps()}>
                    {column.render('Header')}
                    <div>
                      {column.canFilter ? column.render('Filter') : null}
                    </div>
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {page.map((row, i) => {
              prepareRow(row)
              return (
                <tr
                  {...row.getRowProps()}
                  {...getTrProps(row)}
                  id="daaa"
                  draggable={draggable}
                  onDragStart={(event) => dragStart(event, row.original)}
                  onClick={row.toggleRowSelected}
                >
                  {row.cells.map((cell) => {
                    return (
                      <td className="text-nowrap" {...cell.getCellProps()}>
                        {cell.render('Cell')}
                      </td>
                    )
                  })}
                </tr>
              )
            })}
          </tbody>
        </table>
      </div>
      <br />
      <TablePagination
        pageIndex={pageIndex}
        pageOptions={pageOptions}
        previousPage={previousPage}
        canPreviousPage={canPreviousPage}
        nextPage={nextPage}
        canNextPage={canNextPage}
        gotoPage={gotoPage}
        pageSize={pageSize}
        setPageSize={setPageSize}
        pageCount={pageCount}
      />
    </>
  )
}

export default FilteredTable
