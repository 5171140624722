import React, { useState, Fragment } from 'react'
import Tabs from 'react-bootstrap/Tabs'
import Tab from 'react-bootstrap/Tab'
import { createColumnHelper } from '@tanstack/react-table'
import Table from '../../../../components/Common/Tanstack-table/Table'
import { useForm } from 'react-hook-form'

const INVOICE_DETAILS = 'invoice-details'
const INVOICE_DOCS = 'invoice-documents'
const INVOICE_RECEIPENTS = 'invoice-receipents'
const INVOICE_BODY = 'invoice-body'
const SEND_INVOICE = 'send-invoice'

export default function EmailInvoiceCustomerTab() {
  const [activeTab, setActiveTab] = useState(INVOICE_DETAILS)
  const columnHelper = createColumnHelper()
  const { register, handleSubmit } = useForm()

  const onSubmit = (data) => console.log(data)

  const invoiceDetailsTab = () => {
    const columns = [
      columnHelper.accessor('Invoice', {
        id: 'Invoice',
        header: () => <span>Invoice</span>,
        filterFn: 'fuzzy',
      }),
      columnHelper.accessor('invoiceDate', {
        id: 'invoiceDate',
        header: () => <span>Invoice Date</span>,
      }),
      columnHelper.accessor('invoiceTotal', {
        id: 'invoiceTotal',
        header: () => <span>Invoice Total</span>,
      }),
      columnHelper.accessor('Terms', {
        id: 'Terms',
        header: () => <span>Terms</span>,
      }),
    ]
    return (
      <div className="w-50">
        <Table
          columns={columns}
          data={{}}
          showTableBottom={false}
          dontShowFilters
          showGlobalSearch={false}
        />
      </div>
    )
  }

  const invoiceBodyTab = () => {
    return (
      <Fragment>
        <label for="body-subject" class="form-label">
          Invoice Email Subject
        </label>
        <input
          type="text"
          className="form-control w-25"
          placeholder="Invoice Email Subject"
          id="body-subject"
          {...register('body-subject')}
        />
        <label for="body-text" class="form-label mt-5">
          Invoice Email Subject
        </label>
        <textarea
          className="form-control w-25"
          placeholder="Invoice Email Body"
          id="body-text"
          rows="5"
          {...register('body')}
        />
        <div className="d-flex justify-content-end w-25 mt-3">
          <button className="btn btn-info me-1">Preview</button>
          <button className="btn btn-primary" type="submit">
            Submit
          </button>
        </div>
      </Fragment>
    )
  }
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Tabs
        className="actions-tab"
        defaultActiveKey={activeTab}
        onSelect={(key) => setActiveTab(key)}
      >
        <Tab eventKey={INVOICE_DETAILS} title="Invoice Details">
          {invoiceDetailsTab()}
        </Tab>
        <Tab eventKey={INVOICE_DOCS} title="Docs"></Tab>
        <Tab eventKey={INVOICE_RECEIPENTS} title="Receipents"></Tab>
        <Tab eventKey={INVOICE_BODY} title="Body">
          {invoiceBodyTab()}
        </Tab>
        <Tab eventKey={SEND_INVOICE} title="Send Email"></Tab>
      </Tabs>
    </form>
  )
}
