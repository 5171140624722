import config from '../../config'
import { jsPDF } from 'jspdf'
import html2canvas from 'html2canvas'
import * as Toastify from './Toastify'
import { b64toBlob } from '../../helper/b64toBlobConverter'
import isEmpty from 'lodash/isEmpty'
import startCase from 'lodash/startCase'

export const fetchGetData = (urlRef) => {
  const url = config.api.baseUrl + urlRef
  const requestOptions = {
    method: 'GET',
    headers: { Authorization: 'Bearer ' + localStorage.token },
  }
  fetch(url, requestOptions)
    .then((response) => response.json())
    .then((data) => {
      return data
    })
    .catch((error) => {
      console.error('fetch error', error)
    })
}

export const renderOptionsFromDataArr = (
  data,
  optionValuesKeyName,
  keyNameToDisplay
) => {
  return data?.map((item) => (
    <option
      key={item?.[optionValuesKeyName] ?? item}
      value={item?.[optionValuesKeyName] ?? item}
    >
      {item?.[keyNameToDisplay] ?? item}
    </option>
  ))
}

export const getLookUpOptions = (data, field, id, label) => {
  let Options = []
  data[field.loadLookUp].forEach((val) => {
    let option = {}
    option.label = val[label]
    option.id = val[id]
    Options.push(option)
  })
  field.options = Options
}

export const generatePDF = (id, name) => {
  const input = document.getElementById(id)
  html2canvas(input).then((canvas) => {
    const imgData = canvas.toDataURL('image/png')
    const pdf = new jsPDF({
      orientation: 'l',
      unit: 'px',
      format: [input.offsetHeight / 2 - 32, input.offsetWidth / 2 - 56],
    })
    pdf.addImage(imgData, 'JPEG', 0, 0)
    // pdf.output('dataurlnewwindow');
    pdf.save(`${name}.pdf`)
  })
}

export const handleFetchError = (
  error,
  msg = 'Some error occured while fetching data'
) => {
  console.error(msg, { error })
  if (error.message === '500') {
    return Toastify.Error('Internal Server Error')
  }
  Toastify.Error(error.message ?? msg)
}

export const downloadFileFromReadableStream = (data, file) => {
  const b64data = data.replace(/-/g, '+').replace(/_/g, '/')
  downloadFileFromBlob(b64toBlob(b64data, file.mimeType), file.fileName)
}

export const downloadFileFromBlob = (blob, fileName) => {
  const file = window.URL.createObjectURL(blob)
  const link = document.createElement('a')
  link.target = '_blank'
  link.href = file
  link.download = fileName
  // Append to html link element page
  document.body.appendChild(link)
  // Start download
  link.click()
  link.parentNode.removeChild(link)
  // clean up Url
  window.URL.revokeObjectURL(file)
}

export const checkValidColValue = (value) =>
  value === null || typeof value !== 'object' || value instanceof Date

export const createTableColumnsFromObjKeys = (response, excludeKeys = []) => {
  if (isEmpty(response)) return []

  return Object.keys(response[0])
    .filter((key) => {
      const value = response[0][key]

      // Simplified filter: Include keys with null values and exclude keys with object values that are not date objects
      return checkValidColValue(value)
    })
    .filter((key) => !excludeKeys.includes(key))
    .map((key) => ({
      header: startCase(key),
      accessorKey: key,
      id: key,
    }))
}

export const createTableColumnsFromKeysArr = (arr) => {
  return arr.map((key) => ({
    header: startCase(key),
    accessorKey: key,
    id: key,
  }))
}

/**
 * Used to display data in tables
 * @param {*} data An array of objects
 * @returns true boolean values as 'Yes' and false values as 'No'
 */
export const convertBooleanValuesToString = (data) => {
  return data.map((el) => {
    for (let key in el) {
      if (el[key] === true) el[key] = 'YES'
      else if (el[key] === false) el[key] = 'NO'
    }
    return el
  })
}
