import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { useRouter } from '../../../helper/hooks/useRouter'
import config from '../../../config'
import Spinner from '../../Spinner/Spinner'
import axios from 'axios'
import { toast } from 'react-toastify'
import Table from '../../Common/Table/Table'
import { CONTAINERLOAD } from '../FormData/Vehicle'
import './LiveLoadTracking.scss'

const LiveLoadTracking = () => {
  const router = useRouter()
  const [loading, setLoading] = useState(false)
  const [splitJob, setSplitJob] = useState()
  const [driverDetails, setDriverDetails] = useState()
  const [containerLoadDTOs, setContainerLoadDTOs] = useState()
  const containerStageTrackId = router.query['containerStageTrackerId']

  const getSplitJobDetails = () => {
    setLoading(true)
    axios({
      method: 'GET',
      url: `${config.api.baseUrl}${config.api.getSplitJobDetails}${containerStageTrackId}`,
      headers: {
        Authorization: 'Bearer ' + localStorage.token,
      },
    })
      .then((response) => {
        setSplitJob(response?.data.data)
      })
      .catch((error) => {
        toast.error('Something went wrong!')
      })
      .finally(() => {
        setLoading(false)
      })
  }

  const getContainerLoadDTOs = () => {
    setLoading(true)
    axios({
      method: 'GET',
      url: `${config.api.baseUrl}${config.api.getContainerLoadDTOs}${containerStageTrackId}`,
      headers: {
        Authorization: 'Bearer ' + localStorage.token,
      },
    })
      .then((response) => {
        setContainerLoadDTOs(response?.data.data)
      })
      .catch((error) => {
        toast.error('Something went wrong!')
      })
      .finally(() => {
        setLoading(false)
      })
  }

  const getDriverDetails = () => {
    setLoading(true)
    axios({
      method: 'GET',
      url: `${config.api.baseUrl}${config.api.getDriverDetails}${containerStageTrackId}`,
      headers: {
        Authorization: 'Bearer ' + localStorage.token,
      },
    })
      .then((response) => {
        setDriverDetails(response?.data.data)
      })
      .catch((error) => {
        toast.error('Something went wrong!')
      })
      .finally(() => {
        setLoading(false)
      })
  }

  useEffect(() => {
    getSplitJobDetails()
    getContainerLoadDTOs()
    getDriverDetails()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      <div className="header">
        <h6>Live Load Tracking</h6>
      </div>
      <div className="container-fluid">
        <div className="row">
          {loading && <Spinner />}
          <div className=" col-md-5">
            <section className="live-load-section">
              <main className="live-load-main">
                <div className="live-load-tracking">
                  <div className="schedules">
                    <div className="schedule-header">
                      <div className="schedule-header__content">
                        <div className="load-id">
                          <span className="load-id-label">Load ID:</span>
                          <span className="load-id-value">#5173</span>
                        </div>
                        <div className="driver-name">
                          <span className="driver-name-label">Driver:</span>
                          <span className="driver-name-value">John David</span>
                        </div>
                      </div>
                      <button className="btn show-route__button">
                        Show Route
                      </button>
                    </div>
                    <div className="actions">
                      <div className="actions-header">
                        <h2>Stops</h2>
                        <div className="actions-list">
                          <button className="btn add-pickup">Add Pickup</button>
                          <button className="btn add-delivery">
                            Add Delivery
                          </button>
                          <button className="btn add-stops">
                            Add Other Stops
                          </button>
                        </div>
                      </div>
                    </div>
                    <div className="columnData table-responsive">
                      {containerLoadDTOs && (
                        <>
                          <Table
                            className="table table-hover"
                            data={containerLoadDTOs?.map((rowsData) => ({
                              ...rowsData,
                            }))}
                            columns={[...CONTAINERLOAD]}
                          />
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </main>
            </section>
            <br />
            <br />
            <div className="col-md-12">
              <img
                src="./assets/Live Load Tracking/Tracking IMAGE.png"
                alt=""
                className="img-fluid"
              />
            </div>
          </div>
          <div className="col-md-3">
            <img src="./assets/Live Load Tracking/Trackmile Map.png" alt="" />
            <div className="list-group diverCard">
              <div className="row ">
                <div className="col-md-2">
                  <img
                    src="../../../assets/user-avatar.png"
                    width="30px"
                    alt=""
                  />
                </div>
                <div className="col-md-10 driverDetails">
                  <h5>
                    {driverDetails?.driverName}{' '}
                    <em>{driverDetails?.driverCardId}</em>
                  </h5>
                  <p>
                    Distance Mileage:
                    <span>{driverDetails?.distanceMileage}</span>
                  </p>
                  <p>
                    Calculate Mileage:
                    <span>{driverDetails?.calculateMileage}</span>
                  </p>
                  <p>
                    Job Allocated:
                    <span>{driverDetails?.jobAllocatedAgo}</span>
                  </p>
                  <p>
                    Phone:
                    <span>{driverDetails?.phoneNumber}</span>
                  </p>
                  <p>
                    Email:
                    <span>{driverDetails?.emailAddress}</span>
                  </p>
                  {/* <FontAwesomeIcon className="fa" icon={faPhone} />
                                    <FontAwesomeIcon className="fa pencilContainer" icon={faEnvelope} /> */}
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-2">
            <h5>Recent Activity</h5>
            <div className="columnData">
              <div className="list-group">
                <h5>Track Call from Allocator</h5>
                <Link
                  to="/loads-planning"
                  className="list-group-item list-group-item-action"
                >
                  Status
                </Link>
                <Link
                  to="/driver-dashboard"
                  className="list-group-item list-group-item-action"
                >
                  Last Location
                </Link>
                <Link
                  to="/clm-dashboard"
                  className="list-group-item list-group-item-action"
                >
                  Date
                </Link>
                <Link
                  to="/clm-dashboard"
                  className="list-group-item list-group-item-action"
                >
                  Time
                </Link>
                <Link
                  to="/clm-dashboard"
                  className="list-group-item list-group-item-action"
                >
                  Message
                </Link>
              </div>
            </div>
          </div>
          <div className="col-md-2">
            <div className="list-group columnData">
              <h5>Split Job Details</h5>
              <ul className="list-group splitJobContainer pe-0">
                {splitJob && (
                  <>
                    <li class="list-group-item">
                      Order No: <p>{splitJob.orderNo}</p>
                    </li>
                    <li class="list-group-item">
                      Billing Customer: <p>{splitJob.billingCustomer}</p>
                    </li>
                    <li class="list-group-item">
                      Job No Sent: <p>{splitJob.jobNosent}</p>
                    </li>
                    <li class="list-group-item">
                      Container Number: <p>{splitJob.containerNumber}</p>
                    </li>
                    <li class="list-group-item">
                      Size: <p>{splitJob.size}</p>
                    </li>
                    <li class="list-group-item">
                      BAT: <p>{splitJob.bat}</p>
                    </li>
                    <li class="list-group-item">
                      Rego: <p>{splitJob.rego}</p>
                    </li>
                    <li class="list-group-item">
                      Vehicle Type: <p>{splitJob.vehicleType}</p>
                    </li>
                    <li class="list-group-item">
                      Trailer Type: <p>{splitJob.trailerType}</p>
                    </li>
                    <li class="list-group-item">
                      Trailer No: <p>{splitJob.trailerNo}</p>
                    </li>
                    <li class="list-group-item">
                      Transport Job Type: <p>{splitJob.transportJobType}</p>
                    </li>
                    <li class="list-group-item">
                      Total Distance in Miles:{' '}
                      <p>{splitJob.totalDistanceinMiles}</p>
                    </li>
                  </>
                )}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default LiveLoadTracking
