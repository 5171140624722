import { useMemo } from 'react'

const DefaultFilter = ({ column }) => {
  const { filterValue, setFilter } = column
  return (
    <span>
      <input
        value={filterValue || ''}
        onChange={(e) => setFilter(e.target.value)}
        placeholder="Filter data"
        className="form-control"
      />
    </span>
  )
}

const SelectFilter = ({ column }) => {
  const { filterValue, setFilter, preFilteredRows, id } = column
  const options = useMemo(() => {
    const options = new Set()
    preFilteredRows.forEach((row) => {
      options.add(row.values[id])
    })
    return [...options.values()]
  }, [id, preFilteredRows])

  return (
    <select
      value={filterValue}
      onChange={(e) => {
        setFilter(e.target.value)
      }}
    >
      <option value="">All</option>
      {options.map((option, i) => (
        <option key={i} value={option}>
          {option}
        </option>
      ))}
    </select>
  )
}

const DateBetweenFilter = ({ column }) => {
  const { setFilter, filterValue } = column
  return (
    <div className="d-flex">
      <input
        value={filterValue?.[0] || ''}
        type="date"
        onChange={(e) => {
          const val = e.target.value
          setFilter((old) => [val, old?.[1]])
        }}
        className="me-2"
      />
      to
      <input
        value={filterValue?.[1] || ''}
        type="date"
        onChange={(e) => {
          const val = e.target.value
          setFilter((old) => [old?.[0], val])
        }}
        className="ms-2"
      />
    </div>
  )
}

export { DefaultFilter, SelectFilter, DateBetweenFilter }
