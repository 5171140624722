import isEmpty from 'lodash/isEmpty'
import fetchService from '../../../components/utils/fetchService'
import config from '../../../config'
import * as Toastify from '../../../components/utils/Toastify'

export const updateOfcStatus = async (ofcIds, status) => {
  try {
    const res = await fetchService({
      url: config.api.invoice.updateOfcStatus,
      method: 'PUT',
      body: {
        ofcIds: ofcIds,
        ofcStatusEnum: status,
      },
    })
    if (!isEmpty(res)) {
      res.forEach((ofc) => {
        if (ofc.status === status)
          Toastify.Success(
            `OFC status updated to ${status} successfully for ${ofc.ofcId}`
          )
        else
          Toastify.Error(
            `OFC status for ${ofc.ofcId} couldn't be updated to ${status}`
          )
      })
      return res
    }
  } catch (err) {
    console.error(
      'Some error occurred while updating Order Financial Card status.',
      err
    )
    Toastify.Error(
      'Some error occurred while updating Order Financial Card status.'
    )
    return false
  }
}
