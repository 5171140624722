import React, { useEffect, useImperativeHandle, useState } from 'react'
import { useForm, Controller } from 'react-hook-form'
import Select from 'react-select'
import { yupResolver } from '@hookform/resolvers/yup'
import * as Yup from 'yup'
import { createYupSchema } from '../../Common/yupValidatorCreator'
import { format } from 'date-fns'

export function GenerateFormField(props) {
  const { data, control, register, err, formMode, watch, setValue } = props
  const id = data.fieldId
  const allowNumeric = (event) => {
    event.target.value = event.target.value
      .replace(/[^0-9.]/g, '')
      .replace(/(\..*)\./g, '$1')
  }

  const disabled = formMode === 'view'

  return (
    <React.Fragment>
      {data.type === 'text' && (
        <div>
          <input
            className={`form-control ${
              err[data.fieldId]?.message ? 'is-invalid' : ''
            }`}
            id={id}
            placeholder={data.name}
            {...register(id)}
            type="text"
            disabled={disabled}
          />
          {err[data.fieldId]?.message && (
            <p className="invalid-feedback">{err[data.fieldId]?.message}</p>
          )}
        </div>
      )}
      {data.type === 'number' && (
        <div>
          <input
            className={`form-control ${
              err[data.fieldId]?.message ? 'is-invalid' : ''
            }`}
            id={id}
            placeholder={data.name}
            {...register(id)}
            type="text"
            onInput={(event) => {
              allowNumeric(event)
            }}
            disabled={disabled}
          />
          {err[data.fieldId]?.message && (
            <p className="invalid-feedback">{err[data.fieldId]?.message}</p>
          )}
        </div>
      )}

      {data.type === 'date' && (
        <div>
          <input
            className={`form-control ${
              err[data.fieldId]?.message ? 'is-invalid' : ''
            }`}
            id={id}
            placeholder={data.name}
            {...register(id)}
            type="date"
            disabled={disabled}
            max={data.max ? data.max : undefined}
            min={data.min ? data.min : undefined}
          />
          {err[data.fieldId]?.message && (
            <p className="invalid-feedback">{err[data.fieldId]?.message}</p>
          )}
        </div>
      )}

      {data.type === 'time' && (
        <div>
          <input
            className={`form-control ${
              err[data.fieldId]?.message ? 'is-invalid' : ''
            }`}
            id={id}
            placeholder={data.name}
            {...register(id)}
            type="time"
            disabled={disabled}
            step={1}
          />
          {err[data.fieldId]?.message && (
            <p className="invalid-feedback">{err[data.fieldId]?.message}</p>
          )}
        </div>
      )}

      {data.type === 'datetime' && (
        <div>
          <Controller
            control={control}
            name={data.fieldId}
            render={({ field: { onChange, value, ...rest } }) => (
              <input
                className={`form-control ${
                  err[data.fieldId]?.message ? 'is-invalid' : ''
                }`}
                id={id}
                placeholder={data.name}
                type="datetime-local"
                disabled={disabled}
                step={1}
                value={
                  value
                    ? format(new Date(value), "yyyy-MM-dd'T'HH:mm:ss")
                    : null
                }
                onChange={(e) => {
                  onChange(
                    format(new Date(e.target?.value), 'yyyy-MM-dd HH:mm:ss')
                  )
                }}
                {...rest}
              />
            )}
          />
          {err[data.fieldId]?.message && (
            <p className="invalid-feedback">{err[data.fieldId]?.message}</p>
          )}
        </div>
      )}

      {data.type === 'textarea' && (
        <div>
          <textarea
            className={`form-control ${
              err[data.fieldId]?.message ? 'is-invalid' : ''
            }`}
            id={id}
            placeholder={data.name}
            {...register(id)}
            disabled={disabled}
          />
          {err[data.fieldId]?.message && (
            <p className="invalid-feedback">{err[data.fieldId]?.message}</p>
          )}
        </div>
      )}

      {data.type === 'checkbox' && (
        <div>
          <label
            className={`switch ${
              err[data.fieldId]?.message ? ' is-invalid' : ''
            }`}
          >
            <input
              className={` ${err[data.fieldId]?.message ? 'is-invalid' : ''}`}
              id={id}
              placeholder={data.name}
              {...register(id)}
              type="checkbox"
              disabled={disabled}
            />
            <div>
              <span></span>
            </div>
          </label>
          {err[data.fieldId]?.message && (
            <p className="invalid-feedback">{err[data.fieldId]?.message}</p>
          )}
        </div>
      )}

      {data.type === 'select' && (
        <div>
          <select
            className={`form-select ${
              err[data.fieldId]?.message ? 'is-invalid' : ''
            }`}
            id={id}
            placeholder={data.name}
            defaultValue=""
            disabled={disabled}
            {...register(id)}
            onChange={(e) => {
              register(id)?.onChange(e)
              if (data?.dependentFieldIds?.length > 0) {
                data?.dependentFieldIds.forEach((fieldId) => {
                  setValue(fieldId, '')
                })
              }
            }}
          >
            <option value="" disabled>
              Please select
            </option>
            {data.options &&
              data.options
                ?.filter((option) =>
                  data?.dependsOnFieldId
                    ? option[data?.dependsOnFieldId] ===
                      watch(data?.dependsOnFieldId)
                    : true
                )
                .map((eachOption, index) => (
                  <option value={eachOption.label} key={index}>
                    {eachOption.label}
                  </option>
                ))}
            disabled={disabled}
          </select>
          {err[data.fieldId]?.message && (
            <p className="invalid-feedback">{err[data.fieldId]?.message}</p>
          )}
        </div>
      )}

      {data.type === 'searchable-select' &&
        (data.multiple ? (
          <div>
            {(() => {
              const options = data.options?.filter((option) =>
                data?.dependsOnFieldId
                  ? option[data?.dependsOnFieldId] ===
                    watch(data?.dependsOnFieldId)
                  : true
              )
              return (
                <Controller
                  control={control}
                  name={data.fieldId}
                  render={({ field: { onChange, value, ...rest } }) => {
                    const initVal = options?.filter((option) =>
                      value?.find((val) => val === option?.id)
                    )
                    return (
                      <Select
                        value={initVal && initVal.length ? initVal : value}
                        className={`form-searchable-select ${
                          err[data.fieldId]?.message ? 'is-invalid' : ''
                        }`}
                        classNamePrefix="form-searchable-select"
                        placeholder={data.name}
                        isClearable
                        isSearchable
                        isMulti={data.multiple}
                        disabled={disabled}
                        onChange={(val) => {
                          if (data?.dependentFieldIds?.length > 0) {
                            data?.dependentFieldIds.forEach((fieldId) => {
                              setValue(fieldId, null)
                            })
                          }
                          onChange(val ?? [])
                        }}
                        options={options}
                        getOptionLabel={(option) => option.label}
                        getOptionValue={(option) => option.id}
                        {...rest}
                      />
                    )
                  }}
                />
              )
            })()}

            {err[data.fieldId]?.message && (
              <p className="invalid-feedback">{err[data.fieldId]?.message}</p>
            )}
          </div>
        ) : (
          <div>
            {(() => {
              const options = data.options?.filter((option) =>
                data?.dependsOnFieldId
                  ? option[data?.dependsOnFieldId] ===
                    watch(data?.dependsOnFieldId)
                  : true
              )
              return (
                <Controller
                  control={control}
                  name={data.fieldId}
                  render={({ field: { onChange, value, ...rest } }) => (
                    <Select
                      className={`form-searchable-select ${
                        err[data.fieldId]?.message ? 'is-invalid' : ''
                      }`}
                      classNamePrefix="form-searchable-select"
                      placeholder={data.name}
                      isClearable
                      isSearchable
                      isMulti={data.multiple}
                      disabled={disabled}
                      value={
                        options?.find((option) => option?.id === value) ?? null
                      }
                      onChange={(val) => {
                        if (data?.dependentFieldIds?.length > 0) {
                          data?.dependentFieldIds.forEach((fieldId) => {
                            setValue(fieldId, null)
                          })
                        }
                        if (data.onChange) data.onChange(val, setValue)
                        if (!val) {
                          setValue(data.fieldId, null)
                          return
                        }
                        onChange(val?.id)
                      }}
                      options={options}
                      getOptionLabel={(option) => option.label}
                      getOptionValue={(option) => option.id}
                      {...rest}
                    />
                  )}
                />
              )
            })()}
            {err[data.fieldId]?.message && (
              <p className="invalid-feedback">{err[data.fieldId]?.message}</p>
            )}
          </div>
        ))}

      {data.type === 'file' && (
        <div>
          <input
            className={`form-control ${
              err[data.fieldId]?.message ? 'is-invalid' : ''
            }`}
            id={id}
            placeholder={data.name}
            {...register(id)}
            type="file"
            disabled={disabled}
            accept={data?.fileType}
            multiple={data?.multiple}
          />
          {err[data.fieldId]?.message && (
            <p className="invalid-feedback">{err[data.fieldId]?.message}</p>
          )}
        </div>
      )}
    </React.Fragment>
  )
}

const ViewOnlyField = ({
  eachField,
  register,
  watch,
  errors,
  formMode,
  defaultValue,
}) => {
  const [value, setValue] = useState(null)

  const dependentValue =
    eachField?.dependsOnFieldId &&
    watch(eachField.dependsOnFieldId, defaultValue)

  useEffect(() => {
    if (eachField?.dependsOnFieldId && eachField?.transformValue)
      eachField.transformValue(dependentValue, setValue)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dependentValue])

  return (
    <>
      {eachField.type === 'checkbox' && defaultValue ? (
        <GenerateFormField
          data={eachField}
          register={register}
          err={errors}
          formMode={formMode}
          disabled
        />
      ) : (
        <h4 data-field-id={eachField.fieldId}>
          {value || defaultValue || '-'}
        </h4>
      )}
    </>
  )
}

export const Form = React.forwardRef(
  (
    {
      defaultValues = {},
      children,
      onSubmit,
      formData,
      uniqueReferenceKey,
      formMode,
    },
    ref
  ) => {
    let newValidations = formData.reduce(createYupSchema, {})
    const validationSchema = Yup.object().shape(newValidations)

    const {
      handleSubmit,
      register,
      formState: { errors, dirtyFields },
      reset,
      getValues,
      watch,
      setValue,
      control,
    } = useForm({
      resolver: yupResolver(validationSchema),
      defaultValues: defaultValues,
      mode: 'onSubmit',
      reValidateMode: 'onChange',
    })

    useImperativeHandle(ref, () => ({
      resetForm() {
        reset({
          ...defaultValues,
        })
      },
    }))

    useEffect(() => {
      const formFieldKeys = new Set(formData.map((f) => f.fieldId))
      const values = { ...defaultValues }
      for (let prop of Object.keys(values)) {
        if (!formFieldKeys.has(prop)) {
          delete values[prop]
        }
      }
      const dirtyFieldValues = {}
      for (const dirtyField of Object.keys(dirtyFields)) {
        dirtyFieldValues[dirtyField] = getValues()[dirtyField]
      }
      if (formMode === 'edit') {
        reset({
          ...values,
          ...dirtyFieldValues,
        })
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [defaultValues])

    return (
      <form onSubmit={handleSubmit(onSubmit)} ref={ref}>
        <div className="row">
          {formData
            ?.filter((field) =>
              field.hide || formMode === 'view'
                ? !field?.hideInViewMode
                : field.fieldId !== uniqueReferenceKey
            )
            ?.map((eachField, index) => (
              <React.Fragment key={index}>
                <div className="col-md-2">
                  <label>{eachField.name}</label>
                </div>
                <div
                  className={
                    eachField.width === 'full' && formMode === 'view'
                      ? 'col-md-8 mb-4'
                      : 'col-md-2 mb-4'
                  }
                >
                  {formMode === 'view' || eachField.disabled ? (
                    <ViewOnlyField
                      eachField={eachField}
                      register={register}
                      watch={watch}
                      errors={errors}
                      formMode={formMode}
                      defaultValue={defaultValues?.[eachField.fieldId]}
                    />
                  ) : (
                    <GenerateFormField
                      data={eachField}
                      register={register}
                      control={control}
                      err={errors}
                      formMode={formMode}
                      watch={watch}
                      setValue={setValue}
                      disabled={eachField?.disabled}
                      reset={reset}
                    />
                  )}
                </div>
              </React.Fragment>
            ))}

          {children}
        </div>
      </form>
    )
  }
)
