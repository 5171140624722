import React from 'react'
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js'
import { Line } from 'react-chartjs-2'
import useTotalKMDistanceCovered from '../../hooks/useTotalKMDistanceCovered'
import { Spinner } from 'react-bootstrap'

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
)

export default function DistanceCoveredLine() {
  const {
    data: rawDistanceData,
    isLoading,
    error,
  } = useTotalKMDistanceCovered()

  const distanceData = rawDistanceData ? JSON.parse(rawDistanceData) : undefined

  const options = {
    elements: {
      point: {
        radius: 0,
      },
    },
    responsive: true,
    maintainAspectRatio: false,
    layout: {
      autoPadding: true,
    },
    scales: {
      y: {
        type: 'linear',
        display: true,
        // position: 'right',
        ticks: {
          color: 'white',
        },
      },
      x: {
        ticks: {
          color: '#fff',
        },
      },
    },
    plugins: {
      title: {
        display: true,
        text: 'Total Distance covered',
        color: 'white',
      },
      tooltip: {
        intersect: false,
        mode: 'index',
        // bodySpacing: 12,
        callbacks: {
          label: function (context) {
            return `${context.formattedValue} km covered`
          },
        },
      },
    },
  }

  if (isLoading) return <Spinner />

  if (error || !distanceData?.data) return <div>No data found</div>

  const labels = distanceData?.data.map((d) => d.startLocalDate)

  const data = {
    labels,
    datasets: [
      {
        label: '',
        data: distanceData?.data?.map((d) => d.totalKMDistanceCovered),
        borderColor: 'rgb(194, 165, 207)',
        backgroundColor: 'rgb(194, 165, 207)',
      },
    ],
  }
  return (
    <div className="h-100">
      <Line options={options} data={data} />
    </div>
  )
}
