import { faUpRightFromSquare } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import * as Menubar from '@radix-ui/react-menubar'
import React, { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { Link, useHistory } from 'react-router-dom'
import {
  cerebroList,
  clmList,
  wmsList,
  ymsList,
} from '../../lib/navigation.list'
import { getCurrentRouteData } from '../../store/slices/route-slice'
import '../../styles/navigation-menu.scss'

const listMap = {
  'clm-dashboard': clmList,
  'wms-dashboard': wmsList,
  'yms-dashboard': ymsList,
}

export default function NavMenu() {
  const route = useSelector(getCurrentRouteData)
  const history = useHistory()

  const currentList = useMemo(() => {
    if (route?.showSideBarOf) return listMap[route.showSideBarOf]
    return cerebroList
  }, [route?.showSideBarOf])

  return (
    <div className="MenubarContainer">
      <Menubar.Root className="MenubarRoot">
        {currentList.map((list) => {
          const { sectionHeader } = list
          const liS = list?.liS
          const isListEmpty = !Array.isArray(liS) || liS.length === 0

          const headerImgStyles = {
            height: '16px',
            width: '16px',
            marginRight: '4px',
          }

          return (
            <Menubar.Menu key={sectionHeader}>
              {isListEmpty ? (
                <Link to={list?.imgRedirectTo} className="MenubarTrigger">
                  <img
                    style={headerImgStyles}
                    src={list?.sectionHeaderImgSrc}
                    alt=""
                  />
                  {sectionHeader}
                </Link>
              ) : (
                <Menubar.Trigger
                  className="MenubarTrigger"
                  // asChild={isListEmpty}
                  onDoubleClick={() => {
                    if (list?.imgRedirectTo) {
                      history.push(list.imgRedirectTo)
                    }
                  }}
                >
                  <span>
                    <img
                      style={headerImgStyles}
                      src={list?.sectionHeaderImgSrc}
                      alt=""
                    />
                    {sectionHeader}
                  </span>
                </Menubar.Trigger>
              )}
              {Array.isArray(liS) && liS.length && (
                <Menubar.Portal>
                  <Menubar.Content
                    className="MenubarContent"
                    align="start"
                    sideOffset={5}
                    alignOffset={-3}
                  >
                    {liS.map(({ liText, pathTo }) => (
                      <Menubar.Item className="MenubarItem" asChild>
                        <Link to={pathTo}>
                          {liText}
                          <div className="RightSlot">
                            <FontAwesomeIcon icon={faUpRightFromSquare} />
                          </div>
                        </Link>
                      </Menubar.Item>
                    ))}
                  </Menubar.Content>
                </Menubar.Portal>
              )}
            </Menubar.Menu>
          )
        })}
      </Menubar.Root>
    </div>
  )
}
