import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import {
  getSelectedChat,
  postMessage,
} from '../../../../../store/slices/enigmaChat-slice'

export default function Input() {
  const { userDetails } = JSON.parse(localStorage.data)
  const conversation = useSelector(getSelectedChat)
  const [message, setMessage] = useState('')
  const dispatch = useDispatch()

  /**
   * It takes the message from the input field and sends it to the backend.
   */
  const handleSendMessage = (e) => {
    e.preventDefault();
    if (!message) return toast.warn('Please enter a message!')
    const author = userDetails.fullName
    const body = {
      body: message,
      author: author,
      senderId: userDetails.id,
      conversationSid: conversation.conversationSid,
      messageSid: '',
    }
    dispatch(postMessage(body))
    setMessage('')
  }

  return (
    <form onSubmit={handleSendMessage}>
      <div className="row feed-input-container">
        <div className="col-sm-10 col-xs-10 input-wrapper">
          <input
            className="form-control"
            rows="1"
            id="comment"
            onChange={(e) => setMessage(e.target.value)}
            value={message}
          />
        </div>
        <div className="col-sm-1">
          <button type="submit" className="btn btn-primary send-btn">
            Send
          </button>
        </div>
      </div>
    </form>
  )
}
