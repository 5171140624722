import React from 'react'
import { Button, Modal } from 'react-bootstrap'

const list = [
  { displayName: 'Load Id', name: 'loadID' },
  { displayName: 'Container Job Number', name: 'containerJobNumber' },
  { displayName: 'Container Number', name: 'containerNumber' },
  { displayName: 'Customer', name: 'customer' },
  { displayName: 'Actual Delivery Date Time', name: 'actualDeliveryDateTime' },
  { displayName: 'Actual PickUp Date Time', name: 'actualPickUpDateTime' },
  { displayName: 'Cargo Type', name: 'cargoType' },
  { displayName: 'Collection Date', name: 'collectionDate' },
  { displayName: 'Collection Remarks', name: 'collectionRemarks' },
  { displayName: 'Collection Time', name: 'collectionTime' },
  { displayName: 'Consignee', name: 'consignee' },
  { displayName: 'Container Delivery Type', name: 'containerDeliveryType' },
  { displayName: 'ContainerRef', name: 'containerRef' },
  { displayName: 'Container Remarks', name: 'containerRemarks' },
  {
    displayName: 'Container Stage Tracker Id',
    name: 'containerStageTrackerId',
  },
  { displayName: 'Customer Ref', name: 'customerRef' },
  { displayName: 'Delivery Location', name: 'deliveryLocation' },
  { displayName: 'Deliver Area Or Zone', name: 'deliveryAreaOrZone' },
  { displayName: 'Deliver Suburb', name: 'deliverySuburb' },
  { displayName: 'Distinct Conduct', name: 'distinctConduct' },
  { displayName: 'Do Received Date', name: 'doReceivedDate' },
  { displayName: 'Doors Position', name: 'doorsPosition' },
  { displayName: 'Driver ID', name: 'driverID' },
  { displayName: 'Driver Name', name: 'driverName' },
  { displayName: 'Eido Pin', name: 'eidoPin' },
  { displayName: 'Empty Delivery', name: 'emptyDelivery' },
  { displayName: 'Empty Pickup', name: 'emptyPickup' },
  { displayName: 'Est Start Date', name: 'estStartDate' },
  { displayName: 'Est Start Time', name: 'estStartTime' },
  { displayName: 'Eta Date', name: 'etaDate' },
  { displayName: 'Etd Date', name: 'etdDate' },
  { displayName: 'Exp AV Date', name: 'expAVDate' },
  { displayName: 'Exp Closing Time', name: 'expClosingTime' },
  { displayName: 'First AV Date', name: 'firstAVDate' },
  { displayName: 'Grade', name: 'grade' },
  { displayName: 'Weight', name: 'weight' },
  { displayName: 'Hold', name: 'hold' },
  { displayName: 'Imo Code', name: 'imoCode' },
  { displayName: 'Imp AV Date', name: 'impAVDate' },
  { displayName: 'Job Window', name: 'jobWindow' },
  { displayName: 'Status', name: 'status' },
  { displayName: 'Mt Dehire Days', name: 'mtDehireDays' },
  { displayName: 'Mt Dehire Ref', name: 'mtDehireRef' },
  { displayName: 'Mt Dehire Slot', name: 'mtDehireSlot' },
  { displayName: 'Mt Pickup Or Dehire Date', name: 'mtPickupOrDehireDate' },
  { displayName: 'Mt Pickup Slot', name: 'mtPickupSlot' },
  { displayName: 'Obl No', name: 'oblNo' },
  {
    displayName: 'Order Container Details Id',
    name: 'orderContainerDetailsId',
  },
  { displayName: 'Order No', name: 'orderNo' },
  { displayName: 'PickUp Area Or Zone', name: 'pickUpAreaOrZone' },
  { displayName: 'PickUp Location', name: 'pickUpLocation' },
  { displayName: 'PickUp Suburb', name: 'pickUpSuburb' },
  { displayName: 'Planned By', name: 'plannedBy' },
  { displayName: 'Port Ref', name: 'portRef' },
  { displayName: 'Refer Temperature', name: 'reeferTemperature' },
  { displayName: 'Release NO', name: 'releaseNO' },
  {
    displayName: 'Scheduled Delivery Date Time',
    name: 'scheduledDeliveryDateTime',
  },
  {
    displayName: 'Scheduled PickUp Date Time',
    name: 'scheduledPickUpDateTime',
  },
  { displayName: 'Shipping Agent', name: 'shippingAgent' },
  { displayName: 'SR', name: 'sr' },
  { displayName: 'Stage', name: 'stage' },
  { displayName: 'Stage Movement', name: 'stageMovement' },
  { displayName: 'Storage Start Date', name: 'storageStartDate' },
  { displayName: 'Trailer ID', name: 'trailerID' },
  { displayName: 'Trailer Type', name: 'trailerType' },
  { displayName: 'Transport End Date', name: 'transportEndDate' },
  { displayName: 'Transport End Time', name: 'transportEndTime' },
  { displayName: 'Transport Job Type', name: 'transportJobType' },
  { displayName: 'Transport Start Date', name: 'transportStartDate' },
  { displayName: 'Transport Start Time', name: 'transportStartTime' },
  { displayName: 'Truck Fleet No', name: 'truckFleetNo' },
  { displayName: 'Truck ID', name: 'truckID' },
  { displayName: 'Truck Rego', name: 'truckRego' },
  { displayName: 'Type Size', name: 'typeSize' },
  { displayName: 'UN Code', name: 'unCode' },
  { displayName: 'Vessel Name', name: 'vesselName' },
  { displayName: 'Voyage No', name: 'voyageNo' },
  { displayName: 'Wait', name: 'wait' },
  { displayName: 'Job Type', name: 'jobType' },
]

export default function ViewLoadModal({ show, handleClose, data }) {
  if (!data) return null

  return (
    <Modal show={show} onHide={handleClose} size="xl" centered>
      <Modal.Header closeButton>
        <Modal.Title>View Load Details</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="row">
          {list.map(({ displayName, name }) => (
            <div key={name} className="col-md-2 spacer">
              <span className="">{displayName} : </span>
              <p>{data?.[name]}</p>
            </div>
          ))}
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  )
}
