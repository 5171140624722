import {
  getCoreRowModel,
  getExpandedRowModel,
  getFacetedMinMaxValues,
  getFacetedRowModel,
  getFacetedUniqueValues,
  getFilteredRowModel,
  getGroupedRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table'
import isEmpty from 'lodash/isEmpty'
import React, { Fragment, useEffect, useMemo, useState } from 'react'
import { ContextMenuTrigger } from 'react-contextmenu'
import ColumnHeader from './components/ColumnHeader'
import DebouncedInput from './components/DebouncedInput'
import TableBottom from './components/TableBottom'
import TableRow from './components/TableRow'
import { fuzzyFilter } from './utils'
import { toast } from 'react-toastify'

export const columnResizeMode = 'onChange' // onEnd

/**
 * unique-column-ids
 * https://tanstack.com/table/v8/docs/guide/column-defs#unique-column-ids
 */

export default function Table({
  columns,
  data,
  tableId,
  renderSubComponent,
  getRowCanExpand = () => true,
  showGroupingIcon = true,
  setSelectedRows,
  nestedTableDataId,
  draggable = false,
  onContextMenu,
  dontShowFilters = false,
  showGlobalSearch = true,
  showTableBottom = true,
  onDrop,
  inheritTableWidth = false,
  showOnlyPagination = false,
  CustomPaginationComponent,
  cellsContextMenuTriggerComponentId = '',
  manualPagination = false,
  pagination = undefined,
  setPagination = undefined,
  rowCount,
  isFetching = false,
  tableRowProps,
}) {
  const [columnFilters, setColumnFilters] = useState([])
  const [globalFilter, setGlobalFilter] = useState('')
  const [rowSelection, setRowSelection] = useState({})
  const [columnOrder, setColumnOrder] = useState(
    // must start out with populated columnOrder so we can splice
    // This is required for column DND
    columns.map((column) => column.id)
  )
  const [grouping, setGrouping] = useState([])

  const getColumns = useMemo(() => {
    return columns
  }, [columns])

  useEffect(() => {
    /**
     * Look in the localStorage to find column order for the table Id.
     * If found set it as the default column order.
     */
    if (tableId) {
      const order = JSON.parse(localStorage.getItem(`${tableId}-column-order`))
      if (Array.isArray(order) && order.length) {
        /**
         * This condition is necessary when a template (profile) is applied in unplanned-loads
         * It insures that length of column order in localStorage and length of
         */
        if (order.length < columns.length) {
          setColumnOrder(columns.map((column) => column.id))
        } else {
          setColumnOrder(order)
        }
      } else setColumnOrder(columns.map((column) => column.id))
    } else setColumnOrder(columns.map((column) => column.id))
  }, [columns, tableId])

  const saveColumnOrder = () => {
    localStorage.setItem(`${tableId}-column-order`, JSON.stringify(columnOrder))
    toast.info('Column order saved successfully!')
  }

  const getData = useMemo(() => data, [data])

  const resetOrder = () => setColumnOrder(columns.map((column) => column.id))
  const resetFilters = () => setColumnFilters([])

  const table = useReactTable({
    data: getData,
    columns: getColumns,
    getRowCanExpand,
    filterFns: {
      fuzzy: fuzzyFilter,
    },
    initialState: {
      pagination: {
        pageSize: 500,
      },
    },
    state: {
      grouping,
      columnOrder,
      columnFilters,
      globalFilter,
      rowSelection,
      ...(manualPagination && {
        pagination,
      }),
    },
    onGroupingChange: setGrouping,
    getGroupedRowModel: getGroupedRowModel(),
    getExpandedRowModel: getExpandedRowModel(),
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    onRowSelectionChange: setRowSelection,
    onColumnFiltersChange: setColumnFilters,
    onGlobalFilterChange: setGlobalFilter,
    globalFilterFn: fuzzyFilter,
    columnResizeMode,
    onColumnOrderChange: setColumnOrder,
    getSortedRowModel: getSortedRowModel(),
    getFacetedRowModel: getFacetedRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
    getFacetedMinMaxValues: getFacetedMinMaxValues(),
    ...(manualPagination && {
      manualPagination: true,
      onPaginationChange: setPagination,
      rowCount,
    }),
    // debugTable: true,
    // debugHeaders: true,
    // debugColumns: false,
  })

  useEffect(() => {
    if (setSelectedRows) {
      if (!isEmpty(rowSelection))
        setSelectedRows(
          table.getSelectedRowModel().flatRows.map((row) => row.original)
        )
      else setSelectedRows([])
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rowSelection, setSelectedRows])

  return (
    <div className="mb-3">
      <div className="d-flex">
        {showGlobalSearch && (
          <DebouncedInput
            value={globalFilter ?? ''}
            onChange={(value) => setGlobalFilter(String(value))}
            className="form-control my-1 px-2"
            style={{ width: '20rem' }}
            placeholder="Search all columns..."
          />
        )}
        {tableId && (
          <button onClick={saveColumnOrder} className="btn btn-primary ms-auto">
            Save column order
          </button>
        )}
      </div>
      <div className="table-responsive mb-2">
        <div
          {...{
            style: inheritTableWidth
              ? {}
              : {
                  width: table.getCenterTotalSize(),
                },
          }}
          className="table table-hover mb-0"
        >
          {data && (
            <Fragment>
              <div className="thead">
                {table.getHeaderGroups().map((headerGroup) => (
                  <div key={headerGroup.id} className="tr">
                    {headerGroup.headers.map((header) => (
                      <ColumnHeader
                        key={header.id}
                        header={header}
                        table={table}
                        dontShowFilters={dontShowFilters}
                        showGroupingIcon={showGroupingIcon}
                      />
                    ))}
                  </div>
                ))}
              </div>

              <ContextMenuTrigger id={cellsContextMenuTriggerComponentId}>
                <div className="tbody">
                  {table.getRowModel().rows.map((row, idx) => (
                    <TableRow
                      table={table}
                      rowSelection={rowSelection}
                      key={idx}
                      row={row}
                      draggable={draggable}
                      renderSubComponent={renderSubComponent}
                      nestedTableDataId={nestedTableDataId}
                      onContextMenu={onContextMenu}
                      onDrop={onDrop}
                      {...tableRowProps}
                    />
                  ))}
                </div>
              </ContextMenuTrigger>
              {/* <tfoot>
              {table.getFooterGroups().map((footerGroup) => (
                <tr key={footerGroup.id}>
                  {footerGroup.headers.map((header) => (
                    <th key={header.id}>
                      {header.isPlaceholder
                        ? null
                        : flexRender(
                            header.column.columnDef.footer,
                            header.getContext()
                          )}
                    </th>
                  ))}
                </tr>
              ))}
            </tfoot> */}
            </Fragment>
          )}
        </div>
      </div>
      <div style={{ maxWidth: table.getCenterTotalSize() }}>
        {data && showTableBottom && !CustomPaginationComponent ? (
          <TableBottom
            isFetching={isFetching}
            showOnlyPagination={showOnlyPagination}
            resetOrder={resetOrder}
            resetFilters={resetFilters}
            table={table}
          />
        ) : CustomPaginationComponent ? (
          <CustomPaginationComponent
            resetOrder={resetOrder}
            resetFilters={resetFilters}
            table={table}
          />
        ) : null}
      </div>
    </div>
  )
}
