import { createSlice } from '@reduxjs/toolkit'
const initialState = {}

const routeSlice = createSlice({
  name: 'route',
  initialState,
  reducers: {
    setCurrentRoute(state, action) {
      state.route = action.payload
    },
  },
})

export const { setCurrentRoute } = routeSlice.actions

export const getCurrentRouteData = (state) => state.route.route

export default routeSlice.reducer
