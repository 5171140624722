import React from 'react'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import isNull from 'lodash/isNull'
import { getInvoiceDetailsFromInvoiceNo } from '../../../store/slices/invoice-slice'
import { fetchInvoiceDetails } from '../../../store/actions/invoice-actions'
import Table from '../../../components/Common/Tanstack-table/Table'
import { createColumnHelper } from '@tanstack/react-table'
import { generatePDF } from '../../../components/utils/Utils'

export default function InvoicePdfView() {
  const { invoiceNo } = useParams()
  const dispatch = useDispatch()
  const columnHelper = createColumnHelper()
  const invoiceDetails = useSelector((state) =>
    getInvoiceDetailsFromInvoiceNo(state, invoiceNo)
  )

  useEffect(() => {
    if (isNull(invoiceDetails)) dispatch(fetchInvoiceDetails(invoiceNo))
  }, [dispatch, invoiceDetails, invoiceNo])

  /**
createdBy(pin):"amir"
createdDate(pin):"2022-12-05 21:51:28"
lastModifiedBy(pin):"amir"
lastModifiedDate(pin):"2022-12-05 21:51:28"
customerInvoiceOrderId(pin):11
orderNo(pin):"LOC001"
customerRef(pin):"57485"
releaseNo(pin):null
oceanBlNo(pin):""
customerCode(pin):"ShoAU06448"
customerName(pin):"Shoeb Merchant"
containerOrderNo(pin):"LOC001-CON007"
containerNo(pin):"CONT234343"
containerRef(pin):"CONT234343"
containerLoadNo(pin):"LOC001-CON007CU-CU"
size(pin):"20BO"
rateCode(pin):null
description(pin):null
quantity(pin):0
billingUnit(pin):null
unitPrice(pin):0
amount(pin):0
taxAmount(pin):0
remarks(pin):null
active(pin):false
     */

  const customerInvoiceOrdersTableCols = [
    columnHelper.accessor('orderNo', {
      id: 'orderNo',
      header: () => <span>Order No</span>,
    }),
    columnHelper.accessor('loadNo', {
      id: 'loadNo',
      header: () => <span>Load No</span>,
    }),
    columnHelper.accessor('containerNo', {
      id: 'containerNo',
      header: () => <span>Container No</span>,
    }),
    columnHelper.accessor('size', {
      id: 'size',
      header: () => <span>Size</span>,
    }),
    columnHelper.accessor('rateCode', {
      id: 'rateCode',
      header: () => <span>Rate Code</span>,
    }),
    columnHelper.accessor('description', {
      id: 'description',
      header: () => <span>Description</span>,
    }),
    columnHelper.accessor('quantity', {
      id: 'quantity',
      header: () => <span>Qty</span>,
    }),
    columnHelper.accessor('billingUnit', {
      id: 'billingUnit',
      header: () => <span>Billing Unit</span>,
    }),
    columnHelper.accessor('unitPrice', {
      id: 'unitPrice',
      header: () => <span>Unit Price</span>,
    }),
    columnHelper.accessor('amount', {
      id: 'amount',
      header: () => <span>Amount(Inc)</span>,
    }),
    columnHelper.accessor('taxAmount', {
      id: 'taxAmount',
      header: () => <span>Tax Amount</span>,
    }),
    columnHelper.accessor('remarks', {
      id: 'remarks',
      header: () => <span>Remarks</span>,
    }),
  ]

  return (
    <div className="w-100 h-100">
      <div id="invoice-pdf-view-page" className="invoice-pdf-view-wrapper">
        <header>
          <img src="/assets/swift-logo.svg" alt="logo" />
          <h1>Invoice</h1>
        </header>
        <main>
          <div className="d-flex justify-content-between">
            <div>
              <h3>Bill To</h3>
              <h3>Customer Address</h3>
            </div>
            <div>
              <h3>Swift Transport Pty Ltd</h3>
              <div className="customer-address">
                {invoiceDetails?.customerAddress.split(',').map((line) => (
                  <p>{line}</p>
                ))}
              </div>
              <div className="invoice-meta-data">
                <p>Invoice #{invoiceDetails?.invoiceNo}</p>
                <p>Date {invoiceDetails?.invoiceDate}</p>
                <p>Invoice Due Date {invoiceDetails?.invoiceDueDate}</p>
              </div>
            </div>
          </div>
          <Table
            showGroupingIcon={false}
            showGlobalSearch={false}
            columns={customerInvoiceOrdersTableCols}
            data={invoiceDetails?.customerInvoiceOrders}
            dontShowFilters={true}
            showTableBottom={false}
            inheritTableWidth
          />
          <div className="invoice-bottom">
            <div className="left">
              <hr />
              <h4>Note</h4>
              <p>This is to notify to the client that</p>
              <p>new rates are being added</p>
            </div>
            <div className="right">
              <p>SUB TOTAL : {invoiceDetails?.subTotal}</p>
              <p>TOTAL GST : {invoiceDetails?.totalGst}</p>
              <strong>Total : {invoiceDetails?.total}</strong>
              <hr />
            </div>
          </div>
        </main>
        <footer>Swift Transport Pty Ltd</footer>
      </div>
      <button
        type="button"
        className="btn btn-primary"
        onClick={() => generatePDF('invoice-pdf-view-page', invoiceNo)}
      >
        Print
      </button>
    </div>
  )
}
