import React from 'react'
import { Button } from 'react-bootstrap'
import { useHistory, useLocation } from 'react-router-dom'

export default function NotFoundPage() {
  const history = useHistory()
  const query = new URLSearchParams(useLocation().search)

  return (
    <div className="p-3 bg-white">
      <Button
        onClick={() => {
          history.push(query.get('fallbackUrl'))
        }}
      >
        Go Back
      </Button>
      <h2 className="mt-3">The Page you are looking for was not found</h2>
    </div>
  )
}
