import startCase from 'lodash/startCase'
import React, { Fragment } from 'react'
import { Form } from 'react-bootstrap'
import Col from 'react-bootstrap/Col'
import RequiredSymbol from '../RequiredSymbol'

const isString = (value) => typeof value === 'string' || value instanceof String

const Options = ({
  optionLabelKey,
  optionValueKey,
  options,
  setOptionLabel,
}) => {
  if (Array.isArray(options) && options.length) {
    if (isString(options[0])) {
      return options.map((option) => (
        <option value={option} key={option}>
          {option}
        </option>
      ))
    }
    return options?.map((option, i) => (
      <option key={i} value={option[optionValueKey]}>
        {setOptionLabel ? setOptionLabel(option) : option[optionLabelKey]}
      </option>
    ))
  }
  return null
}

export default function Select({
  name,
  label = startCase(name),
  placeholder = label,
  register,
  options,
  optionValueKey = name,
  optionLabelKey = 'description',
  setOptionLabel,
  md = 4,
  errors,
  as = Col,
  required = false,
  ...props
}) {
  return (
    <Form.Group className="mb-3" as={as} md={md} controlId={name}>
      <Form.Label>
        {label} {required && <RequiredSymbol />}
      </Form.Label>
      <Form.Select {...register(name)} {...props}>
        <option value="">Choose...</option>

        <Options
          options={options}
          optionValueKey={optionValueKey}
          optionLabelKey={optionLabelKey}
          setOptionLabel={setOptionLabel}
        />
      </Form.Select>
      {errors?.[name] && (
        <Form.Text className="text-danger">{errors?.[name].message}</Form.Text>
      )}
    </Form.Group>
  )
}

export const ColSelect = ({
  name,
  label = startCase(name),
  placeholder = label,
  register,
  registerOptions,
  viewMode,
  options,
  optionValueKey = name,
  optionLabelKey = 'description',
  setOptionLabel,
  ratio = [6, 6],
  errors,
  Wrapper = Fragment,
  defaultData,
  ...props
}) => {
  return (
    <Wrapper>
      <Col md={ratio[0]}>
        <label htmlFor="name">{label}</label>
      </Col>
      <Col md={ratio[1]} className="mb-2">
        {viewMode !== 'view' ? (
          <>
            <Form.Select {...register(name, registerOptions)} {...props}>
              <option value="">Choose...</option>

              <Options
                options={options}
                optionValueKey={optionValueKey}
                optionLabelKey={optionLabelKey}
                setOptionLabel={setOptionLabel}
              />
            </Form.Select>
            {errors?.[name] && (
              <Form.Text className="text-danger">
                {errors?.[name].message}
              </Form.Text>
            )}
          </>
        ) : (
          <h5 className="m-0">{defaultData?.[name]}</h5>
        )}
      </Col>
    </Wrapper>
  )
}
