import { toast } from 'react-toastify'

export const Success = (msg) => {
  toast.success(msg, {
    position: toast.POSITION.TOP_CENTER,
    autoClose: 10000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  })
}

export const Info = (msg, prop) => {
  toast.info(msg, {
    position: toast.POSITION.BOTTOM_CENTER,
    autoClose: 10000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    ...prop,
  })
}

export const Error = (msg) => {
  let errmsg = msg ?? 'Something went wrong!'
  toast.error(errmsg, {
    position: toast.POSITION.TOP_LEFT,
    autoClose: 10000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  })
}

export const Warning = (msg) => {
  toast.warn(msg, {
    position: toast.POSITION.BOTTOM_LEFT,
    autoClose: 10000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  })
}

const toasts = { Success, Info, Error, Warning }
export default toasts
