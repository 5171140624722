import React, { useState } from 'react'
import { Spinner } from 'react-bootstrap'
import { useForm } from 'react-hook-form'
import Table from '../../../../../components/Common/Tanstack-table/Table'
import { useUnplannedLoadsQuery } from '../../../../../helper/hooks/apiHooks'
import ContainerServiceRates from '../../../../cerebro/orders/ordersOperations/OrdersContainers/components/ContainerServiceRates'
import LoadNotesModal from '../../Modals/LoadNotesModal'
import ViewLoadModal from '../../Modals/ViewLoadModal'
import { ProfileManagerProvider } from '../../context/ProfileManagerContext'
import {
  CREATE_JOB_MODAL,
  LOAD_NOTES_MODAL,
  SERVICE_RATE_MODAL,
  STAGE_TRACKER_MODAL,
  VIEW_LOAD_MODAL,
  unplannedLoadsFiltersFormDefaultValues,
} from '../../data/allocation-planning.constants'
import useKeyBoardsShortcuts from '../../hooks/useKeyBoardsShortcuts'
import useUnplannedLoadsTableColumn from '../../hooks/useUnplannedLoadsTableColumn'
import CreateJobModal from '../CreateJobModal'
import LoadStageDetails from '../LoadStageDetails'
import DriversTable from '../eq-planner/DriversTable'
import Menu from './Menu'
import ProfileHistory from './ProfileHistory'
import UnplannedLoadsFilters from './UnplannedLoadsFilters'

const initialPaginationState = {
  pageIndex: 0,
  pageSize: 100,
}

export default function UnplannedLoads() {
  const [selectedUnplannedLoads, setSelectedUnplannedLoads] = useState([])
  const [modal, setModal] = useState('')
  const [selectedRow, setSelectedRow] = useState(null)
  const [pagination, setPagination] = useState(initialPaginationState)

  const form = useForm({
    mode: 'all',
    defaultValues: unplannedLoadsFiltersFormDefaultValues,
  })

  const setInitialPaginationState = () => setPagination(initialPaginationState)

  const { watch } = form

  React.useEffect(() => {
    const subscription = watch(() => setInitialPaginationState())
    return () => subscription.unsubscribe()
  }, [watch])

  const formValues = watch()

  const {
    data: unplannedLoadsQueryData,
    isLoading,
    error,
    isFetching,
  } = useUnplannedLoadsQuery(pagination, formValues)

  const loadsTableCols = useUnplannedLoadsTableColumn(pagination)

  useKeyBoardsShortcuts(selectedUnplannedLoads, setSelectedRow, setModal)

  const closeModal = () => setModal('')

  if (error) return <p>Error occurred while fetching data</p>

  if (isLoading) return <Spinner />

  return (
    <ProfileManagerProvider>
      <div className="">
        <div className="unplanned-loads-tables-container">
          {!Array.isArray(unplannedLoadsQueryData?.data) ||
          unplannedLoadsQueryData?.data.length === 0 ? (
            <p style={{ flexGrow: 1 }}>
              No data available. Try changing search filters.
            </p>
          ) : (
            <div className="loads-table-container">
              <ProfileHistory />
              <Table
                tableId="unplanned-loads"
                data={unplannedLoadsQueryData.data}
                columns={loadsTableCols}
                draggable={true}
                manualPagination
                pagination={pagination}
                setPagination={setPagination}
                rowCount={unplannedLoadsQueryData.totalResults}
                setSelectedRows={setSelectedUnplannedLoads}
                onContextMenu={(row) => {
                  setSelectedRow(row.original)
                }}
                cellsContextMenuTriggerComponentId="row-click-data"
                isFetching={isFetching}
              />
            </div>
          )}
          <div>
            <UnplannedLoadsFilters form={form} />
            <DriversTable
              invalidateQuery={['allocation-planner', 'unplanned-loads']}
            />
          </div>
        </div>
        <Menu selectedRow={selectedRow} setModal={setModal} />
        {modal === STAGE_TRACKER_MODAL && (
          <LoadStageDetails handleClose={closeModal} load={selectedRow} />
        )}
        {modal === CREATE_JOB_MODAL && (
          <CreateJobModal
            selectedRows={selectedUnplannedLoads}
            handleClose={closeModal}
            show
            pagination={pagination}
          />
        )}
        {modal === VIEW_LOAD_MODAL && (
          <ViewLoadModal show handleClose={closeModal} data={selectedRow} />
        )}
        {modal === LOAD_NOTES_MODAL && (
          <LoadNotesModal show handleClose={closeModal} load={selectedRow} />
        )}
        {modal === SERVICE_RATE_MODAL && (
          <ContainerServiceRates
            type={'edit'}
            controllerData={selectedRow}
            controller="stage"
            setShowServiceRateModal={setModal}
          />
        )}
      </div>
    </ProfileManagerProvider>
  )
}
