import { faCar } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'

export default function VehicleIcon({ vehicleType }) {
  switch (vehicleType) {
    case 'DOUBLE_MAX_60':
      return (
        <img
          src="/assets/trucks/Double-Max-60.png"
          width="50px"
          title={vehicleType}
          height="15px"
          alt=""
        />
      )
    case 'DOUBLE_MAX_80':
      return (
        <img
          src="../../../assets/trucks/Double-Max-80.png"
          width="50px"
          title={vehicleType}
          height="15px"
          alt=""
        />
      )
    case 'SIDELOADER_MAX_40':
      return (
        <img
          src="../../../assets/trucks/Sideloader-Max-40.png"
          width="50px"
          title={vehicleType}
          height="15px"
          alt=""
        />
      )
    case 'SEMITRAILER_SINGLE_MAX_20':
      return (
        <img
          src="../../../assets/trucks/Semi-Trailer-Single-Max-20.png"
          title={vehicleType}
          width="50px"
          height="15px"
          alt=""
        />
      )
    case 'RIGID_MAX_20':
      return (
        <img
          src="../../../assets/trucks/Rigid-MAX-Standerd.png"
          width="50px"
          height="15px"
          alt=""
        />
      )
    default:
      return <FontAwesomeIcon size="lg" icon={faCar} />
  }
}
