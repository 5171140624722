import axios from 'axios'
import { useEffect, useState } from 'react'
import Select from 'react-select'
import { toast } from 'react-toastify'
import Table from '../../../../../components/Common/Tanstack-table/Table'
import config from '../../../../../config'

const RateCards = ({ rateNameList, loadingRateNames }) => {
  const [rateName, setRateName] = useState('')
  const [crdList, setCrdList] = useState([])
  const [columns, setColumns] = useState([])

  useEffect(() => {
    const {
      api: { baseUrl },
    } = config
    const dashboardUrl = `${baseUrl}/customercard/rate-detail/dashboard`
    axios({
      url: dashboardUrl,
      method: 'POST',
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
        'Content-Type': 'application/json',
      },
      data: rateName.length > 0 ? { crdName: rateName } : {},
    })
      .then((response) => {
        setCrdList(response.data.data)
      })
      .catch((err) => {
        toast.error(err.response.data.data[0].key)
      })
  }, [rateName])

  useEffect(() => {
    const options = Object.keys(crdList?.[0] ?? {}).map((key) => ({
      accessorKey: key,
      header: key,
      id: key,
    }))
    setColumns(options)
  }, [crdList])

  return (
    <div className="card">
      <div className="card-header d-flex justify-content-between align-items-center">
        <div className="d-flex gap-2">
          <button className="btn-primary btn rounded ps-3 pe-3 pt-1 pb-1">
            Rate Card
          </button>
          <button className="btn-primary btn rounded ps-3 pe-3 pt-1 pb-1">
            Zone Listing
          </button>
        </div>
        <button className="btn-primary btn pt-1 pb-1 ps-3 pe-3 rounded">
          New Rate
        </button>
      </div>
      <div className="card-body">
        <div className="d-flex gap-4 align-items-center">
          <h4 className="col-md-2">Rate Name</h4>
          <Select
            className="form-control bg-transparent border-0 p-0"
            isClearable
            isSearchable
            isLoading={loadingRateNames}
            isDisabled={loadingRateNames}
            options={rateNameList?.map(({ value }) => ({
              label: value,
              value: value,
            }))}
            onChange={(e) => setRateName(e?.value || '')}
          />
        </div>
        {crdList?.length > 0 && columns?.length > 0 && (
          <div className="mt-3" style={{ maxWidth: '86vw' }}>
            <Table
              showGlobalSearch={false}
              showOnlyPagination
              data={crdList}
              columns={columns}
            />
          </div>
        )}
      </div>
    </div>
  )
}

export default RateCards
