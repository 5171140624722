import React from 'react'
import { Col, Form } from 'react-bootstrap'
import startCase from 'lodash/startCase'
import RequiredSymbol from '../RequiredSymbol'

export default function InputNumber({
  name,
  label = startCase(name),
  placeholder = label,
  register,
  md = 4,
  errors,
  as = Col,
  required = false,
  ...props
}) {
  const handleBlur = (event) => {
    event.target.value = Number(event.target.value)
  }

  return (
    <Form.Group className="mb-3" as={as} md={md} controlId={name}>
      <Form.Label>
        {label} {required && <RequiredSymbol />}
      </Form.Label>
      <Form.Control
        type="text"
        placeholder={placeholder}
        {...register(name, {
          onBlur: handleBlur,
          valueAsNumber: true,
        })}
        onInput={(event) => {
          event.target.value = event.target.value
            .replace(/[^0-9.]/g, '')
            .replace(/(\..*)\./g, '$1')
        }}
        autoComplete="off"
        {...props}
      />
      {errors?.[name] && (
        <Form.Text className="text-danger">{errors?.[name].message}</Form.Text>
      )}
    </Form.Group>
  )
}
