const specialService = {
  name: 'Special Services',
  apiUrlAppend: false,
  columns: 4,
  apiUrlRepelaceUnderscore: true,
  fields: [
    {
      name: 'Id',
      label: 'id',
      type: 'text',
      validation: 'Alphabetic',
      fieldId: 'id',
      id: 'id',
      validationType: 'string',
      validations: [
        {
          type: 'required',
          params: ['Id is required'],
        },
      ],
    },
    {
      name: 'Description',
      label: 'Description',
      type: 'textarea',
      validation: 'Alphabetic',
      fieldId: 'description',
      id: 'description',
      validationType: 'string',
      max: 30,
      validations: [
        {
          type: 'required',
          params: ['Description is required'],
        },
        {
          type: 'max',
          params: [30, "Description can't be more than 30 characters"],
        },
      ],
    },
    {
      name: 'stageName',
      label: 'Stage Name',
      type: 'text',
      validation: 'Alphabetic',
      fieldId: 'stageName',
      id: 'stageName',
      validationType: 'string',
      validations: [
        {
          type: 'required',
          params: ['Stage Name is required'],
        },
      ],
    },
  ],
}

export default specialService
