import React, { useEffect, useState } from 'react'
import { yupResolver } from '@hookform/resolvers/yup'
import * as Yup from 'yup'
import { useForm } from 'react-hook-form'
import ZoneTableForm from './ZoneTableForm'
import config from '../../../config'

const CopyZone = (props) => {
  const [customersData, setCustomerData] = useState([])
  const url = config.api.baseUrl + config.api.customerCardsList

  const {
    csvArray,
    setCsvArray,
    formError,
    showError,
    showSuccessMsg,
    addRow,
    deleteRow,
  } = props

  const [noCopyZones, setNoCopyZones] = useState(false)

  const validationSchema = Yup.object().shape({
    zoneCodeCategory: Yup.string().required('This field is required'),
    fromCustomer: Yup.string().required('This field is required'),
  })

  const {
    register,
    handleSubmit,
    formState: { errors },
    getValues,
  } = useForm({
    resolver: yupResolver(validationSchema),
    reValidateMode: 'onChange',
  })

  useEffect(() => {
    const requestOptions = {
      method: 'GET',
      headers: { Authorization: 'Bearer ' + localStorage.token },
    }

    fetch(url + '?active=true', requestOptions)
      .then((response) => response.json())
      .then((data) => {
        setCustomerData(data)
      })
  }, [url])

  const getZoneDetailsByClient = (formData) => {
    setNoCopyZones(false)
    const requestOptions = {
      method: 'POST',
      headers: { Authorization: 'Bearer ' + localStorage.token },
    }
    let apiUrl =
      config.api.baseUrl +
      '/customercard/zone/copy/' +
      formData.fromCustomer +
      '/' +
      formData.zoneCodeCategory

    fetch(apiUrl, requestOptions)
      .then((response) => response.json())
      .then((data) => {
        data.forEach((eachDataObj, i) => {
          data[i].uniqueId = (new Date().getTime() * Math.random()).toFixed()
        })
        setCsvArray({ data: data, igniter: new Date() })
      })
      .catch((error) => {
        console.error('fetch error', error)
        setNoCopyZones(true)
      })
  }

  const copyZoneProfile = (e) => {
    e.preventDefault()
    props.AddMultiZones(e, 'copy', getValues('zoneCodeCategory'))
  }

  return (
    <div>
      <h4>Copy Zone profile</h4>
      <form onSubmit={handleSubmit(getZoneDetailsByClient)}>
        <div className="row">
          <div className="col-md-2">
            <label>Customer</label>
          </div>
          <div className="col-md-3 mb-4">
            <select
              className={`form-select ${
                errors.fromCustomer ? 'is-invalid' : ''
              }`}
              id="customerCardId"
              name="fromCustomer"
              {...register('fromCustomer')}
            >
              <option value="">Please Select</option>
              {customersData.map((eachCustomer) => (
                <option
                  value={eachCustomer.customerCardId}
                  key={eachCustomer.customerCardId}
                >
                  {eachCustomer.customerName}
                </option>
              ))}
            </select>
            {errors?.fromCustomer && (
              <p className="invalid-feedback">
                {errors?.fromCustomer?.message}
              </p>
            )}
          </div>
          <div className="col-md-2">
            <label>Zone Profile</label>
          </div>
          <div className="col-md-3 mb-4">
            <select
              className={`form-select ${
                errors.zoneCodeCategory ? 'is-invalid' : ''
              }`}
              id="zoneType"
              name="zoneCodeCategory"
              {...register('zoneCodeCategory')}
            >
              <option value="">Please Select</option>
              <option value="ZONE10KM">Zone 10KM</option>
              <option value="ZONE15KM">Zone 15KM</option>
              <option value="OUTMETRO">Out Metro</option>
            </select>
            {errors?.zoneCodeCategory && (
              <p className="invalid-feedback">
                {errors?.zoneCodeCategory?.message}
              </p>
            )}
          </div>
          <div className="col-2">
            <button className="btn btn-primary mb-4" type="submit">
              Copy
            </button>
          </div>
        </div>
      </form>
      {noCopyZones && <p className="text-danger">No Zones found</p>}
      {csvArray.data && csvArray.data.length > 0 && (
        <form onSubmit={copyZoneProfile}>
          <div>
            <ZoneTableForm
              zoneCodes={props.zoneCodes}
              deleteRow={deleteRow}
              csvArray={csvArray}
              addRow={addRow}
              type="copy"
            />
            {formError && (
              <p className="text-danger my-4">
                Please check the error messages and submit the form again
              </p>
            )}
            <div className="my-4">
              <button type="submit" className="btn btn-primary">
                Submit
              </button>
              <button type="reset" className="btn btn-secondary ms-3">
                Reset
              </button>
            </div>
            {showSuccessMsg && (
              <p className="text-primary">Copied the Zones successfully</p>
            )}
            <div className="col-12">
              {showError && (
                <div className="text-danger my-4">
                  <p>There is an issue in form submission. please try later.</p>
                  {showError.length &&
                    showError.map((eachError, i) => {
                      return (
                        <p key={i}>
                          {eachError.key} - {eachError.message}
                        </p>
                      )
                    })}
                </div>
              )}
            </div>
          </div>
        </form>
      )}
    </div>
  )
}

export default CopyZone
