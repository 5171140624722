import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { getLookupDataState } from '../../../store/slices/lookup-slice'
import RateDetailing from '../RateDetailing/RateDetailing'
import SuburbProfile from '../Suburb/SuburbProfile'
import ZoneProfile from '../Zone/ZoneProfile'
import CartageRatesTab from './CartageRatesTab'
import './rate-styles.scss'

const TabBtn = ({ displayName = '', activeTabState, name }) => {
  const [activeTab, setActiveTab] = activeTabState
  return (
    <button
      className={`nav-link ${activeTab === name ? 'active' : ''}`}
      data-bs-toggle="tab"
      type="button"
      role="tab"
      onClick={() => setActiveTab(name)}
    >
      {displayName}
    </button>
  )
}

const tabsList = [
  { displayName: 'Zone Profile', name: 'zone' },
  { displayName: 'Suburb Profile', name: 'suburb' },
  { displayName: 'Rate Detailing', name: 'crd' },
  { displayName: 'Cartage rates', name: 'cartageRates' },
]

const Rates = (props) => {
  const { customerCardId, type } = useParams()
  const lookupsList = useSelector(getLookupDataState)
  const activeTabState = useState('zone')
  const [activeTab] = activeTabState

  return (
    <div className="p-3 w-100 rate-card-container">
      <h3>Rates</h3>

      <div className="white-bg-card p-3" style={{ borderRadius: '15px' }}>
        <nav className="mb-4">
          <div className="nav nav-tabs" id="nav-tab" role="tablist">
            {tabsList.map(({ displayName, name }) => (
              <TabBtn
                key={name}
                displayName={displayName}
                name={name}
                activeTabState={activeTabState}
              />
            ))}
          </div>
        </nav>
        <div className="tab-content" id="nav-tabContent">
          <div role="tabpanel" aria-labelledby="nav-home-tab">
            {activeTab === 'zone' && (
              <ZoneProfile
                lookupsList={lookupsList}
                customerCardId={customerCardId}
                type={type}
                {...props}
              />
            )}
          </div>

          <div role="tabpanel" aria-labelledby="nav-profile-tab">
            {activeTab === 'suburb' && (
              <SuburbProfile
                lookupsList={lookupsList}
                customerCardId={customerCardId}
                type={type}
                {...props}
              />
            )}
          </div>
          <div role="tabpanel" aria-labelledby="nav-contact-tab">
            {activeTab === 'crd' && (
              <RateDetailing
                lookupsList={lookupsList}
                customerCardId={customerCardId}
                type={type}
                {...props}
                parentRef="rates"
              />
            )}
          </div>
          {activeTab === 'cartageRates' && (
            <div role="tabpanel" aria-labelledby="nav-contact-tab">
              <CartageRatesTab customerCardId={customerCardId} />
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default Rates
