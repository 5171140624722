import React from 'react'
import ZoneTemplate from './ZoneTemplate'
const ZoneTableForm = (props) => {
  const { csvArray, zoneCodes, deleteRow, addRow, type } = props
  return (
    <div className="overflow-auto">
      <table width="100%" className="table mt-4 zone-form">
        <thead className="zone-heading">
          <tr>
            <th></th>
            <th></th>
            <th colSpan="2">Standard</th>
            <th colSpan="2">Side Loader</th>
            <th colSpan="2">Drop Trailer Delivery</th>
            <th>Toll</th>
            {type === 'create' && <th width="100"></th>}
          </tr>
          <tr>
            <th>Zone</th>
            <th>Kms</th>
            <th>20's</th>
            <th>40's</th>
            <th>20'&gt; 21' Tonne</th>
            <th>40'&gt;21' Tonne</th>
            <th>20's</th>
            <th>40's</th>
            <th>Price</th>
            {type === 'create' && <th></th>}
          </tr>
        </thead>
        <tbody>
          {csvArray.data.map((each, i) => (
            <ZoneTemplate
              zoneCodes={zoneCodes}
              eachData={each}
              key={each.uniqueId}
              csvIndex={i}
              deleteRow={deleteRow}
              csvArray={csvArray}
              addRow={addRow}
              type={type}
            />
          ))}
        </tbody>
      </table>
    </div>
  )
}

export default ZoneTableForm
