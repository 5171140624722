import { faPencil } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import IndeterminateCheckbox from '../../../../components/Common/Tanstack-table/components/IndeterminateCheckbox'
import Table from '../../../../components/Common/Tanstack-table/Table'
import UplDetailsModal from '../SubComponents/UplDetailsModal'
import config from '../../../../config'
import { toast } from 'react-toastify'
import Spinner from '../../../../components/Spinner/Spinner'
import fetchService from '../../../../components/utils/fetchService.js'

const Dispatched = ({
  dispatchedData,
  setUplDetails,
  uplDetails,
  unpackedTableColumns,
  getWms,
  cargoDetailsTable,
}) => {
  const [loading, setLoading] = React.useState(false)
  const [showUPLDetailsModal, setShowUPLDetailsModal] = React.useState(false)

  /**
   * It returns true if all the items in the cargoList array have a check property that is true
   * @returns A boolean value.
   */

  const canUpdateToCompleted = (cargoList, check) => {
    let canComplete = true
    cargoList.forEach((item) => {
      if (item[check] === false) canComplete = false
      // console.log(item[check])
    })
    return canComplete
  }
  const tableData = uplDetails?.orderContainerCargoDetails

  const updateToCompleted = () => {
    if (!canUpdateToCompleted(tableData, 'verified')) {
      toast.warn('All Cargo Should be Completed!');
      return;
    }
    setLoading(true)
    fetchService({
      method: 'PUT',
      url: `${config.api.wms.unpacked.updateToComplete}${uplDetails?.wmsDetailsId}`,
    })
      .then(() => {
        getWms('DISPATCHED')
        toast.info('Successully Updated')
      })
      .catch((error) => {
        if (
          error.data &&
          Array.isArray(error.data) &&
          error.data.length > 0
        ) {
          error.data.forEach((errorItem) => toast.error(errorItem.message))
          return;
        }
        toast.error('Something went wrong!s');
      })
      .finally(() => {
        setShowUPLDetailsModal(false)
        setLoading(false)
      })
  }

  const showUplDetails = (details) => {
    setUplDetails(details)
    setShowUPLDetailsModal(true)
  }

  unpackedTableColumns.splice(0, 1, {
    id: 'select',
    header: ({ table }) => (
      <IndeterminateCheckbox
        {...{
          checked: table.getIsAllRowsSelected(),
          indeterminate: table.getIsSomeRowsSelected(),
          onChange: table.getToggleAllRowsSelectedHandler(),
        }}
      />
    ),
    cell: ({ row }) => (
      <div className="px-1">
        <div className="d-flex">
          <IndeterminateCheckbox
            {...{
              checked: row.getIsSelected(),
              indeterminate: row.getIsSomeSelected(),
              onChange: row.getToggleSelectedHandler(),
            }}
          />
          <button
            className="btn m-0 p-0 "
            onClick={() => {
              showUplDetails(row.original)
            }}
          >
            <FontAwesomeIcon icon={faPencil} className="ps-1" />
          </button>
        </div>
        {row.getIsExpanded() && row.original.jobNumber}
      </div>
    ),
  })

  return (
    <div>
      {loading && <Spinner />}
      <div className="columnData customiseTable">
        <Table
          columns={[...unpackedTableColumns]}
          data={dispatchedData}
          // setSelectedRows={setSelectedUnplannedLoads}
          draggable={false}
          nestedTableDataId="orderContainerCargoDetails"
          renderSubComponent={cargoDetailsTable}
          getRowCanExpand={() => true}
        />
      </div>
      {showUPLDetailsModal && (
        <UplDetailsModal
          uplDetails={uplDetails}
          setShowUPLDetailsModal={setShowUPLDetailsModal}
        >
          <div className="flex-content p-2">
            <div className=""></div>
            <button onClick={updateToCompleted} className="btn btn-primary">
              Completed
            </button>
          </div>
        </UplDetailsModal>
      )}
    </div>
  )
}

export default Dispatched
