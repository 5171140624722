import YmsTemplate from '../Template'
import config from '../../../config'
const TruckIncoming = () => {
  return (
    <YmsTemplate
      Header="Truck Incoming"
      url={`${config.api.baseUrl}${config.api.yms.getTruckIncomingDto}`}
    />
  )
}


export default TruckIncoming