import React, { useEffect, useState } from 'react'
import AgreedRatesForm from './AgreedRatesForm'
import AgreedRatesTable from './AgreedRatesTable'
import AgreedRatesRefObj from './AgreedRatesRefObj.json'
import config from '../../../config'
import axios from 'axios'

const AgreedRates = (props) => {
  const { setEnableSpinner, type, additionalInfo, submitUrl, getDataUrl } =
    props
  const [enableForm, setEnableForm] = useState(false)
  const [formError, setFormError] = useState(false)
  const [showSuccessMsg, setShowSuccessMsg] = useState(false)
  const [showError, setShowError] = useState(false)
  const [profileFormType, setProfileFormType] = useState('add')
  const [agreedRatesArray, setAgreedRatesArray] = useState({
    data: [],
    igniter: new Date(),
  })
  const [agreedRateFormData, setAgreedRateFormData] =
    useState(AgreedRatesRefObj)

  const { selectedCRDId } = additionalInfo

  const submitAgreedZone = (e, type) => {
    setFormError(false)

    let formData = agreedRatesArray.data
    formData.forEach((_, i) => {
      formData[i] = { ...formData[i], ...additionalInfo }
    })

    setEnableSpinner(true)

    let formattedData = []
    formData.forEach((eachRow, i) => {
      let eachFormattedData = {}
      Object.keys(eachRow).forEach((item) => {
        let itemFormatted = eachRow[item]
        if (itemFormatted === '') {
          itemFormatted = null
        }

        if (['fromRange', 'sellRate', 'toRange', 'typeSize'].includes(item)) {
          itemFormatted = Number(itemFormatted)
        }

        eachFormattedData[item] = itemFormatted
      })
      formattedData.push(eachFormattedData)
    })

    const url =
      config.api.baseUrl + (submitUrl ?? '/customercard/crd-agreed-rates/multi')
    setShowError(false)
    setShowSuccessMsg(false)
    axios({
      method: 'POST',
      url: url,
      data: formattedData,
      headers: {
        Authorization: 'Bearer ' + localStorage.token,
      },
    })
      .then(function (response) {
        setShowSuccessMsg(true)
        setEnableSpinner(false)
      })
      .catch(function (error) {
        if (error?.response?.data?.data) {
          setShowError(
            typeof error.response.data.data !== 'string'
              ? error.response.data.data
              : JSON.parse(error.response.data.data)
          )
        } else {
          setShowError(true)
        }
        setEnableSpinner(false)
      })
  }

  useEffect(() => {
    if ((type === 'view' || type === 'edit') && selectedCRDId) {
      const url =
        config.api.baseUrl +
        (getDataUrl ?? '/customercard/crd-agreed-rates?crdId=') +
        selectedCRDId
      const requestOptions = {
        method: 'GET',
        headers: { Authorization: 'Bearer ' + localStorage.token },
      }
      fetch(url, requestOptions)
        .then((response) => response.json())
        .then((data) => {
          setAgreedRatesArray({ data: data, igniter: new Date() })
        })
        .catch((error) => {
          console.error('fetch error', error)
        })
    }
  }, [getDataUrl, type, selectedCRDId])

  return (
    <div>
      {(type === 'create' || type === 'edit') && selectedCRDId && (
        <div className="text-end">
          <button
            className="btn btn-gold btn-sm mt-4"
            onClick={() => {
              setEnableForm(true)
              setAgreedRateFormData(AgreedRatesRefObj)
              setProfileFormType('add')
            }}
          >
            Add Agreed Rates for CRD id - {selectedCRDId}
          </button>
        </div>
      )}
      {enableForm && (
        <AgreedRatesForm
          profileFormId={props.profileFormId}
          setEnableForm={setEnableForm}
          lookupsList={props.lookupsList}
          agreedRatesArray={agreedRatesArray}
          setAgreedRatesArray={setAgreedRatesArray}
          type={!props.profileFormId ? 'create' : 'edit'}
          profileFormType={profileFormType}
          defaultData={agreedRateFormData}
          setAgreedRateFormData={setAgreedRateFormData}
          selectedCRDId={selectedCRDId}
        />
      )}

      <div>
        {agreedRatesArray?.data && agreedRatesArray?.data?.length > 0 && (
          <>
            <AgreedRatesTable
              agreedRatesArray={agreedRatesArray}
              setAgreedRateFormData={setAgreedRateFormData}
              setEnableForm={setEnableForm}
              setAgreedRatesArray={setAgreedRatesArray}
              type={props.type}
              setProfileFormType={setProfileFormType}
              profileFormType={profileFormType}
            />
            <div className="text-end mt-4">
              <button
                onClick={() => submitAgreedZone()}
                className="btn btn-primary"
              >
                Submit Agreed Rates
              </button>
            </div>
            <div className="col-12">
              {showError && (
                <div className="text-danger my-4">
                  <p>There is an issue in form submission. please try later.</p>
                  {showError.length &&
                    showError.map((eachError, i) => {
                      return (
                        <p key={i}>
                          {eachError.key} - {eachError.message}
                        </p>
                      )
                    })}
                </div>
              )}
            </div>
          </>
        )}
      </div>
      {agreedRatesArray?.data &&
        agreedRatesArray?.data?.length === 0 &&
        selectedCRDId && (
          <div className="text-danger">
            No Agreed rate records found for CRD ID - {selectedCRDId}
          </div>
        )}

      {formError && (
        <p className="text-danger my-4">
          Please check the error messages and submit the form again
        </p>
      )}

      {showSuccessMsg && (
        <p className="text-success my-4">Form has submitted successfully!</p>
      )}
    </div>
  )
}

export default AgreedRates
