import React, { createContext, useState } from 'react'
import Spinner from '../../Spinner/Spinner'
import { useSelector } from 'react-redux'
import { getEnigmaChatLoadingState } from '../../../store/slices/enigmaChat-slice'

export const LoadingContext = createContext({
  loading: false,
  setLoading: () => undefined,
})

export const LoadingProvider = ({ children }) => {
  const [loading, setLoading] = useState(false)
  const enigmaChatLoadingState = useSelector(getEnigmaChatLoadingState)

  const loadingHelper = {
    loading,
    setLoading,
  }

  return (
    <LoadingContext.Provider value={loadingHelper}>
      {children}
      {(loading || enigmaChatLoadingState) && <Spinner />}
    </LoadingContext.Provider>
  )
}
