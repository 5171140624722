import { useMutation, useQuery } from '@tanstack/react-query'
import React from 'react'
import { Button, Col, Row, Spinner } from 'react-bootstrap'
import { useForm } from 'react-hook-form'
import InputNumber from '../../../../components/Common/Form/Fields/InputNumber'
import ContainerWithHeading from '../../../../components/styles/Containers/ContainerWithHeading'
import fetchService from '../../../../components/utils/fetchService'
import { Success } from '../../../../components/utils/Toastify'

const getPlansByDays = (response) => {
  return response.reduce((acc, plan) => {
    acc[plan.dayEnum] = plan
    return acc
  }, {})
}

const getDefaultPlannerData = async () =>
  await fetchService({
    url: '/clm/capacity-planning/default',
    isFile: true,
  })
    .then((response) => response.text())
    .then((response) => {
      const data = JSON.parse(response)
      return getPlansByDays(data)
    })

const DefaultPlansForm = ({ plans }) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: plans,
  })

  const mutation = useMutation({
    mutationFn: async (body) =>
      await fetchService({
        url: '/clm/capacity-planning/default',
        method: 'POST',
        body,
      }),
  })

  const onSubmit = async (values) => {
    const body = Object.keys(values).map((key) => values[key])
    const response = await mutation.mutateAsync(body)
    if (response?.length > 0) {
      Success('Details Updated Successfully')
    }
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="d-flex mb-3">
        <Button disabled={mutation.isLoading} type="submit" className="ms-auto">
          {mutation.isLoading ? <Spinner /> : 'Submit'}
        </Button>
      </div>
      <div className="d-flex gap-3">
        <Row className="gap-3">
          {Object.keys(plans['SUNDAY']).map((time) => {
            if (time.includes('day'))
              return (
                <Col md={12} key={time}>
                  <h3>HOUR</h3>
                </Col>
              )
            return (
              <Col md={12} key={time}>
                <span>{`${time.slice(4, 8)} - ${time.slice(8, 12)}`}</span>
              </Col>
            )
          })}
        </Row>
        {Object.entries(plans).map(([day, plan]) => (
          <Row key={day} className="gap-3">
            {Object.keys(plan).map((time) => {
              if (time.includes('day'))
                return (
                  <Col md={12} key={`${day}.${time}`}>
                    <h3>{plan[time]}</h3>
                  </Col>
                )
              return (
                <InputNumber
                  md={12}
                  key={`${day}.${time}`}
                  name={`${day}.${time}`}
                  label=""
                  disabled={mutation.isLoading}
                  errors={errors}
                  register={register}
                />
              )
            })}
          </Row>
        ))}
      </div>
    </form>
  )
}

export default function DefaultPlanner() {
  const {
    data: plans,
    error,
    isLoading,
  } = useQuery({
    queryFn: getDefaultPlannerData,
    queryKey: ['/clm/capacity-planning/default'],
  })

  if (error) return <pre>{JSON.stringify(error, null, 2)}</pre>

  if (isLoading || !plans) return <Spinner />

  return (
    <ContainerWithHeading
      heading="Default Planner"
      Wrapper="div"
      wrapperProps={{
        className: 'mx-2 mb-3 w-100',
      }}
      containerClassNames="py-4 px-3"
    >
      <DefaultPlansForm plans={plans} />
    </ContainerWithHeading>
  )
}
