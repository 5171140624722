import { useCallback, useEffect, useRef } from 'react'
import { useDispatch } from 'react-redux'
import { pushMessage, setLoading } from '../../../store/slices/enigmaChat-slice'
import ConversationsWrapper from './Conversations/ConversationsWrapper'
import FeedWrapper from './Feed/FeedWrapper'

const EnigmaChat = () => {
  const stompClient = useRef()
  const dispatch = useDispatch()

  const connect = useCallback(async () => {
    dispatch(setLoading(true))
    const { userDetails } = JSON.parse(localStorage.data)
    const Stomp = require('stompjs')
    let SockJS = require('sockjs-client')
    SockJS = new SockJS(`${window.location.origin}/api/ws`)
    stompClient.current = Stomp.over(SockJS)
    console.log('reached Stomp over')
    stompClient.current.connect(
      {},
      (res) => {
        console.log('🚀 ~ connect ~ res:', res)
        /**
         * Subscribe to new message event when
         * connection is successful.
         */
        dispatch(setLoading(false))
        if (stompClient.current?.connected)
          stompClient.current.subscribe(
            '/user/cerebro-' + userDetails.id + '/queue/messages',
            (msg) => {
              const newMessage = JSON.parse(msg.body)
              dispatch(pushMessage(newMessage))
            }
          )
      },
      (err) => {
        /**
         * If error occurs while establishing connection
         * Try to re-connect in 3 secs
         */
        setTimeout(() => {
          connect()
        }, 3000)
      }
    )
  }, [dispatch])

  useEffect(() => {
    connect()
  }, [dispatch, connect])

  return (
    <div className="enigma-chat-container">
      <header className="header">
        <h6>Enigma Chat</h6>
      </header>

      <main className="container-fluid app">
        <div className="row app-one">
          <ConversationsWrapper />
          <FeedWrapper />
        </div>
      </main>
    </div>
  )
}

export default EnigmaChat
