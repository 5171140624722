import { useEffect, useState } from 'react'
import { Modal, Tab, Tabs } from 'react-bootstrap'
import axios from 'axios'
import { toast } from 'react-toastify'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLocationPin } from '@fortawesome/free-solid-svg-icons'

import IndeterminateCheckbox from '../../../components/Common/Tanstack-table/components/IndeterminateCheckbox'
import Table from '../../../components/Common/Tanstack-table/Table'
import Spinner from '../../../components/Spinner/Spinner'
import config from '../../../config'

import { Unpacked, AwaitingUnpack, Approved, Dispatched } from './TabPages'
import Completed from './TabPages/Completed'
import {
  UplDetailsModal,
  CargoVerifyModal,
  LocationViewEdit,
} from './SubComponents'
import './UnpackedList.scss'

const UnpackedList = () => {
  const [loading, setLoading] = useState(false)

  const [allWmsData, setAllWmsData] = useState([])
  const [awaitingUnpackData, setAwaitingUnpackData] = useState([])
  const [unpackedData, setUnpackedData] = useState([])
  const [approvedData, setApprovedData] = useState([])
  const [dispatchedData, setDispatchedData] = useState([])
  const [completeData, setCompleteData] = useState([])

  const [uplDetails, setUplDetails] = useState()
  const [showUPLDetailsModal, setShowUPLDetailsModal] = useState(false)

  const [cargoDetails, setCargoDetails] = useState(0)
  const [showCargoVerifyModal, setShowCargoVerifyModal] = useState(false)

  const [showLocationViewEditModal, setShowLocationViewEditModal] =
    useState(false)

  const [activeTab, setActiveTab] = useState('all')

  useEffect(() => {
    document.body.setAttribute('data-class', 'clm-container')
    return () => document.body.removeAttribute('data-class')
  }, [])

  /**
   * It's a function that takes a status as a parameter, sets the loading state to true, sets the url
   * to the api endpoint, and then makes a GET request to the api endpoint
   */
  const getWms = (status) => {
    setLoading(true)
    // const url = `${config.api.baseUrl}/wms/unpacked/byUplStatus?uplStatusEnum=${status}`
    const url = `${config.api.baseUrl}${config.api.wms.unpacked.getListByUplStatus}${status}`
    axios({
      method: 'GET',
      url: url,
      headers: {
        Authorization: 'Bearer ' + localStorage.token,
      },
      data: {},
    })
      .then(function (response) {
        switch (status) {
          case 'ALL':
            setAllWmsData(response.data)
            break
          case 'AWAITING_UNPACK':
            setAwaitingUnpackData(response.data)
            break
          case 'UNPACKED':
            setUnpackedData(response.data)
            break
          case 'APPROVED':
            setApprovedData(response.data)
            break
          case 'DISPATCHED':
            setDispatchedData(response.data)
            break
          case 'COMPLETED':
            setCompleteData(response.data)
            break
          default:
        }
      })
      .catch(function () {
        toast.error('Something went wrong!')
      })
      .finally(() => {
        setLoading(false)
      })
  }

  useEffect(() => {
    switch (activeTab) {
      case 'all':
        getWms('ALL')
        break
      case 'awaiting-unpack':
        getWms('AWAITING_UNPACK')
        break
      case 'unpacked':
        getWms('UNPACKED')
        break
      case 'approved':
        getWms('APPROVED')
        break
      case 'dispatched':
        getWms('DISPATCHED')
        break
      case 'completed':
        getWms('COMPLETED')
        break
      default:
    }
  }, [activeTab])

  const showVerifyCargo = (details) => {
    setCargoDetails(details)
    setShowCargoVerifyModal(true)
  }
  const showLocationBox = (details) => {
    setUplDetails(details)
    setShowLocationViewEditModal(true)
  }
  const cargoDetailsTable = (tableData, cols) => {
    return (
      <div className="fold-content w-100 ps-5 bg-gray">
        {loading && <Spinner />}
        <div className="d-flex">
          <h5 className="text-primary me-2">Cargo Details</h5>
        </div>
        <div className="cargo-table">
          <Table
            columns={[...cargoTableColumns]}
            data={tableData}
            showGlobalSearch={false}
            nestedTableDataId="orderContainerCargoDetails"
            dontShowFilters
          />
        </div>
      </div>
    )
  }
  const unpackedTableColumns = [
    {
      id: 'select',
      header: ({ table }) => (
        <IndeterminateCheckbox
          {...{
            checked: table.getIsAllRowsSelected(),
            indeterminate: table.getIsSomeRowsSelected(),
            onChange: table.getToggleAllRowsSelectedHandler(),
          }}
        />
      ),
      cell: ({ row }) => (
        <div className="px-1">
          <IndeterminateCheckbox
            {...{
              checked: row.getIsSelected(),
              indeterminate: row.getIsSomeSelected(),
              onChange: row.getToggleSelectedHandler(),
            }}
          />
          {row.getIsExpanded() && row.original.jobNumber}
        </div>
      ),
    },
    {
      header: 'UPL No',
      accessorKey: 'uplNo',
      id: 'uplNo',
    },
    {
      header: 'UPL Date',
      type: 'text',
      accessorKey: 'uplDate',
      id: 'uplDate',
    },
    {
      header: 'Customer Ref',
      type: 'time',
      accessorKey: 'customerRef',
      id: 'customerRef',
    },
    {
      header: 'Location',
      type: 'text',
      accessorKey: 'location',
      id: 'location',
      cell: (info) => (
        <div className="row">
          <div className="col-sm-6">{info.getValue()}</div>
          <div className="col-sm-6">
            <FontAwesomeIcon
              onClick={() => showLocationBox(info.row.original)}
              size="lg"
              style={{
                margin: '0 1rem ',
                color: 'red',
                cursor: 'pointer',
              }}
              icon={faLocationPin}
            />
          </div>
        </div>
      ),
    },
    {
      header: 'Container Number',
      type: 'text',
      accessorKey: 'containerNumber',
      id: 'containerNumber',
    },
    {
      header: 'Order No',
      type: 'text',
      accessorKey: 'orderNumber',
      id: 'orderNumber',
    },
    {
      header: 'UPL Status',
      type: 'text',
      accessorKey: 'uplStatus',
      id: 'uplStatus',
    },
    {
      header: 'Date Unpacked',
      type: 'date',
      accessorKey: 'actualDeliveryDateTime',
      id: 'actualDeliveryDateTime',
    },
    {
      header: 'Exp Client Date',
      type: 'date',
      accessorKey: 'expClientDate',
      id: 'expClientDate',
    },
    {
      header: 'Dispatched Date',
      type: 'date',
      accessorKey: 'dispatchedDate',
      id: 'dispatchedDate',
    },
    {
      header: 'Wharf Job Type',
      type: 'text',
      accessorKey: 'wharfJobType',
      id: 'wharfJobType',
    },
    {
      header: 'Size Type',
      type: 'text',
      accessorKey: 'sizeType',
      id: 'sizeType',
    },
    {
      header: 'Truck Number',
      type: 'text',
      accessorKey: 'truckNo',
      id: 'truckNo',
    },
    {
      header: 'Remarks',
      type: 'text',
      accessorKey: 'remarks',
      id: 'remarks',
    },
    {
      header: 'Estimated Time Arrival',
      type: 'text',
      accessorKey: 'eta',
      id: 'eta',
    },
  ]
  const cargoTableColumns = [
    {
      id: 'select',
      header: ({ table }) => (
        <IndeterminateCheckbox
          {...{
            checked: table.getIsAllRowsSelected(),
            indeterminate: table.getIsSomeRowsSelected(),
            onChange: table.getToggleAllRowsSelectedHandler(),
          }}
        />
      ),
      cell: ({ row }) => (
        <div className="px-1">
          <IndeterminateCheckbox
            {...{
              checked: row.getIsSelected(),
              indeterminate: row.getIsSomeSelected(),
              onChange: row.getToggleSelectedHandler(),
            }}
          />
          {row.getIsExpanded() && row.original.jobNumber}
        </div>
      ),
    },
    {
      header: 'Cargo No',
      accessorKey: 'cargoNumber',
      id: 'cargoNumber',
    },
    {
      header: 'Cargo Type',
      accessorKey: 'cargoType',
      id: 'cargoType',
    },
    {
      header: 'Cargo Line No',
      accessorKey: 'cargoLineNo',
      id: 'cargoLineNo',
    },
    {
      header: 'Cargo Status',
      accessorKey: 'verified',
      cell: (info) => {
        return (
          <button
            onClick={() => showVerifyCargo(info.row.original)}
            className={`btn ${info.getValue() ? 'btn-success' : 'btn-primary'}`}
          >
            {info.getValue() ? 'Verified' : 'Verify'}
          </button>
        )
      },
      id: 'verified',
    },
    {
      header: 'Quantity',
      accessorKey: 'quantity',
      id: 'quantity',
    },
    {
      header: 'Measuring Unit',
      accessorKey: 'mu',
      id: 'mu',
    },

    {
      header: 'Cargo Description',
      accessorKey: 'cargoDescription',
      id: 'cargoDescription',
    },
    {
      header: 'Stock Room',
      accessorKey: 'stockRoom',
      id: 'stockRoom',
    },
  ]

  return (
    <div className="w-100 overflow-auto">
      <div className="header">
        <h6>Unpacked List</h6>
      </div>
      <div className="container-fluid">
        <div className="row">
          {loading && <Spinner />}
          <div className=" col-md-12">
            <Tabs
              defaultActiveKey={activeTab}
              transition={false}
              className="mb-3"
              style={{ marginLeft: '0px' }}
              onSelect={(key) => setActiveTab(key)}
            >
              <Tab eventKey="all" title="All">
                <div className="columnData customiseTable">
                  <Table
                    columns={[...unpackedTableColumns]}
                    data={allWmsData}
                  />
                </div>
              </Tab>
              <Tab eventKey="awaiting-unpack" title="Awaiting Unpack">
                <AwaitingUnpack
                  awaitingUnpackData={awaitingUnpackData}
                  setUplDetails={setUplDetails}
                  unpackedTableColumns={unpackedTableColumns}
                  uplDetails={uplDetails}
                  cargoDetailsTable={cargoDetailsTable}
                  getWms={getWms}

                  // cargoTableColumns={cargoTableColumns}
                  // showCargoVerifyModal={showCargoVerifyModal}
                  // setShowCargoVerifyModal={setShowCargoVerifyModal}
                />
              </Tab>
              <Tab eventKey="unpacked" title="Unpacked">
                <Unpacked
                  unpackedData={unpackedData}
                  setUplDetails={setUplDetails}
                  unpackedTableColumns={unpackedTableColumns}
                  cargoDetailsTable={cargoDetailsTable}
                  uplDetails={uplDetails}
                  getWms={getWms}
                />
              </Tab>
              <Tab eventKey="approved" title="Approved">
                <Approved
                  approvedData={approvedData}
                  setUplDetails={setUplDetails}
                  cargoDetailsTable={cargoDetailsTable}
                  unpackedTableColumns={unpackedTableColumns}
                  getWms={getWms}
                  uplDetails={uplDetails}
                />
              </Tab>
              <Tab eventKey="dispatched" title="Dispatched">
                <Dispatched
                  dispatchedData={dispatchedData}
                  setUplDetails={setUplDetails}
                  unpackedTableColumns={unpackedTableColumns}
                  uplDetails={uplDetails}
                  cargoDetailsTable={cargoDetailsTable}
                  getWms={getWms}
                />
              </Tab>
              <Tab eventKey="completed" title="Completed">
                <Completed
                  completeData={completeData}
                  setUplDetails={setUplDetails}
                  unpackedTableColumns={unpackedTableColumns}
                  uplDetails={uplDetails}
                  getWms={getWms}
                />
              </Tab>
            </Tabs>
          </div>
        </div>
      </div>
      {showCargoVerifyModal && (
        <CargoVerifyModal
          cargoDetails={cargoDetails}
          setShowCargoVerifyModal={setShowCargoVerifyModal}
          defaultValues={{
            palletsNo: cargoDetails.palletsOnWms,
            comments: cargoDetails.outturnComments,
          }}
          getWms={getWms}
        />
      )}
      {showUPLDetailsModal && (
        <UplDetailsModal
          uplDetails={uplDetails}
          setShowUPLDetailsModal={setShowUPLDetailsModal}
        />
      )}
      {showLocationViewEditModal && (
        <Modal
          show={true}
          onHide={() => setShowLocationViewEditModal(false)}
          size="sm"
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title>
              {<div className="fs-3 text-primary">Location</div>}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <LocationViewEdit
              wmsID={uplDetails.wmsDetailsId}
              getWms={getWms}
              setShowLocationViewEditModal={setShowLocationViewEditModal}
            />
          </Modal.Body>
        </Modal>
      )}
    </div>
  )
}

export default UnpackedList
