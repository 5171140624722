import {
  faBell,
  faCar,
  faComment,
  faEnvelope,
  faEye,
  faLocationPin,
  faMoon,
  faPencil,
  faPhone,
  faPhoneAlt,
  faSun,
  faTrash,
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { createColumnHelper } from '@tanstack/react-table'
import { Device } from '@twilio/voice-sdk'
import axios from 'axios'
import $ from 'jquery'
import isEmpty from 'lodash/isEmpty'
import React, { Fragment, Suspense, useEffect, useState } from 'react'
import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'
import BootStrapSpinner from 'react-bootstrap/Spinner'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { toast } from 'react-toastify'
import ConfirmModal from '../../../components/Common/Modal/ConfirmModal'
import Table from '../../../components/Common/Tanstack-table/Table'
import { tooltip } from '../../../components/Common/tooltip'
import Map from '../../../components/GoogleMap/GoogleMap'
import Spinner from '../../../components/Spinner/Spinner'
import ContainerWithHeading from '../../../components/styles/Containers/ContainerWithHeading'
import { Success, Warning } from '../../../components/utils/Toastify'
import config from '../../../config'
import { useGetWebFleetResponseMutation } from '../../../helper/hooks/api/allocations'
import { useRouter } from '../../../helper/hooks/useRouter'
import { getLookupDataState } from '../../../store/slices/lookup-slice'

const JobNotesModal = React.lazy(() => import('./modals/JobNotesModal'))

const { goLiveMonitoring } = config.api

export default function DriverDashboard() {
  const { history } = useRouter()
  const [loading, setLoading] = useState(true)
  const [showTwilioLogs, setShowTwilioLogs] = useState(false)
  const [drivers, setDrivers] = useState([])
  const [stageDetail, setStageDetail] = useState('0')
  const [containerLoadNumb, setContainerLoadNumb] = useState()
  const [driverId, setDriverId] = useState()
  const [jobsDetailsDtoByDriverCardId, setJobsDetailsDTOByDriverCardId] =
    useState([])
  const lookupsList = useSelector(getLookupDataState)
  const [showDriverAssign, setShowDriverAssign] = useState(false)
  const [draggableData, setDraggableData] = useState({})
  const [driverToAssign, setDriverToAssign] = useState(null)
  const [jobNum, setJobobNumber] = useState()
  const [token, setToken] = useState()
  const [currentPhone, setCurrentPhone] = useState()
  const [selectedJob, setSelectedJob] = useState(null)
  const columnHelper = createColumnHelper()

  const mutation = useGetWebFleetResponseMutation()

  const resetMutation = () => mutation.reset()

  const driverTableColumns = [
    columnHelper.accessor('driverId', {
      id: 'driverId',
      header: 'Driver Id',
      cell: (info) => (
        <p
          style={{ marginBottom: '0px', color: '#84db03' }}
          onClick={() => rowClick(info.row.original)}
        >
          {info.getValue()}
        </p>
      ),
    }),
    columnHelper.accessor('driverName', {
      id: 'driverName',
      cell: (info) => info.renderValue(),
      header: 'Driver Name',
    }),
    columnHelper.accessor('location', {
      id: 'location',
      header: 'Location',
      cell: ({ row: { original } }) => (
        <FontAwesomeIcon
          onClick={() => {
            resetMutation()
            mutation.mutate(original?.vehicleTruckDTO?.vehicleTruckId)
          }}
          style={{
            display: 'block',
            margin: '0 auto',
            color: 'red',
            cursor: 'pointer',
          }}
          className="truckIcon"
          icon={faLocationPin}
        />
      ),
    }),
    columnHelper.accessor('contactNo', {
      id: 'contactNo',
      header: 'Contact No',
      cell: (info) => (
        <FontAwesomeIcon
          onClick={() => getTwilioVoiceCallNumber(info.getValue())}
          style={{
            display: 'block',
            margin: '0 auto',
            color: 'blue',
            cursor: 'pointer',
          }}
          className="truckIcon"
          icon={faPhoneAlt}
        />
      ),
    }),
    columnHelper.accessor('chat', {
      id: 'chat',
      header: 'Chat',
      cell: () => (
        <FontAwesomeIcon
          onClick={() => history.push('./enigma-chat')}
          style={{
            display: 'block',
            margin: '0 auto',
            color: 'blue',
            cursor: 'pointer',
          }}
          className="truckIcon"
          icon={faComment}
        />
      ),
    }),
    columnHelper.accessor('vehicleType', {
      id: 'vehicleType',
      header: 'Vehicle',
      cell: ({ row: { original } }) => {
        const { base, loginEndpoint, secret, userName } = goLiveMonitoring

        return (
          <span
            className="d-flex justify-content-around"
            data-tip
            data-for={`driver-id-${original.driverId}`}
          >
            <span
              type="button"
              className=""
              onClick={() =>
                window.open(
                  `#/truck-dashboard?vehicleType=TRUCK&driverCardId=${original.driverCardId}`,
                  '_blank'
                )
              }
            >
              {vehicleIcon(original)}
            </span>
            <a
              href={`${base}${loginEndpoint}${secret}/${btoa(
                `${original.vehicleTruckDTO?.licensePlatNo}-${original.vehicleTruckDTO?.batNo}`
              )}/${btoa(userName)}`}
              target="_blank"
              rel="noreferrer"
              style={{ lineHeight: '2.2' }}
            >
              {original.vehicleTruckDTO?.licensePlatNo}
            </a>
            {original.vehicleTruckDTO?.licensePlatNo &&
              tooltip(
                <div className="cursor-pointer h4">
                  <ol>
                    {original.vehicleTruckDTO?.vehicleTrailerDTOs?.map(
                      (dto) => (
                        <li key={dto?.vehicleTrailerId}>{dto.displayValue}</li>
                      )
                    )}
                  </ol>
                </div>,
                `driver-id-${original.driverId}`,
                'bottom'
              )}
          </span>
        )
      },
    }),
  ]

  const getDriverCardsByStatus = () => {
    setLoading(true)
    axios({
      method: 'GET',
      url: `${config.api.baseUrl}${config.api.driverWithoutStatus}`,
      headers: {
        Authorization: 'Bearer ' + localStorage.token,
      },
    })
      .then((response) => {
        setDrivers(response?.data)
        setDriverId(response?.data[0].driverCardId)
        jobsDetailsDTOByDriverCardId(response?.data[0].driverCardId)
      })
      .catch((error) => {
        toast.error(error?.response?.data?.error ?? 'Something went wrong!')
      })
      .finally(() => {
        setLoading(false)
      })
  }

  const getDriverCardsWithoutStatus = (status) => {
    if (status === 'All') {
      return getDriverCardsByStatus()
    }
    setLoading(true)
    axios({
      method: 'GET',
      url: `${config.api.baseUrl}${config.api.driverWithStatus}${status}`,
      headers: {
        Authorization: 'Bearer ' + localStorage.token,
      },
    })
      .then((response) => {
        setDrivers(response?.data)
        setDriverId(response?.data[0].driverCardId)
        jobsDetailsDTOByDriverCardId(response?.data[0].driverCardId)
      })
      .catch((error) => {
        toast.error(error?.response?.data?.error ?? 'No Data Found')
      })
      .finally(() => {
        setLoading(false)
      })
  }

  const jobsDetailsDTOByDriverCardId = (driverId) => {
    setLoading(true)
    axios({
      method: 'GET',
      url: `${config.api.baseUrl}${config.api.jobsDetailsDTOByDriverCardId}${driverId}`,
      headers: {
        Authorization: 'Bearer ' + localStorage.token,
      },
    })
      .then((response) => {
        setJobsDetailsDTOByDriverCardId(response?.data.data || [])
        setJobobNumber(response?.data.data[0].jobNumber)
      })
      .catch((error) => {
        toast.error(error?.response?.data?.error ?? 'No Data Found!')
      })
      .finally(() => {
        setLoading(false)
      })
  }

  useEffect(() => {
    getDriverCardsByStatus()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const rowClick = (rowData) => {
    jobsDetailsDTOByDriverCardId(rowData.driverCardId)
    setDriverId(rowData.driverCardId)
  }

  const deleteDriver = (id) => {
    const answer = window.confirm('Are you sure to delete?')
    if (!answer) return
    const url = `${config.api.baseUrl}/transports/driver/card`
    setLoading(true)
    axios({
      method: 'DELETE',
      url: `${url}/${id}`,
      headers: {
        Authorization: 'Bearer ' + localStorage.token,
      },
    })
      .then(function (response) {
        getDriverCardsByStatus()
        toast.success('Succesfully Assigned!')
      })
      .catch(function (error) {
        toast.error(
          error?.response?.data?.data?.[0]?.message ?? 'Something went wrong!'
        )
      })
      .finally(() => {
        setShowDriverAssign(false)
      })
  }

  const assignJobToDriver = () => {
    setLoading(true)
    const url = `${config.api.baseUrl}${config.api.assignDriverVehicleToStage}`
    axios({
      method: 'POST',
      url: url,
      headers: {
        Authorization: 'Bearer ' + localStorage.token,
      },
      data: {
        containerStageTrackerIds: draggableData.map(
          (row) => row.containerStageTrackerId
        ),
        driverCardId: driverToAssign.driverCardId,
      },
    })
      .then(() => {
        toast.success('Successfully assigned!')
        getDriverCardsByStatus()
      })
      .catch((err) => {
        toast.error(err.response?.data?.data[0].message)
      })
      .finally(() => {
        setLoading(false)
      })
    setShowDriverAssign(false)
  }

  const drop = (event, driver) => {
    const draggedData = JSON.parse(event.dataTransfer.getData('row'))
    if (driver?.vehicleTruckDTO?.vehicleTruckId) {
      setShowDriverAssign(true)
    } else {
      toast.info('The job can not be assigned - vehicle not assigned to driver')
    }
    setDraggableData(draggedData)
    setDriverToAssign(driver)
  }
  const { userDetails } = JSON.parse(localStorage.data)
  const handleChatJobConversation = (jobNo) => {
    setLoading(true)
    axios({
      method: 'GET',
      url: `${config.api.baseUrl}${config.api.enigmaChat.getCerebroJobConversations}?jobNumber=${jobNo}`,
      headers: {
        Authorization: 'Bearer ' + localStorage.token,
      },
    })
      .then((response) => {
        if (response.data.data.length > 0) {
          // set current conversation
          Warning('Conversation already exists')
        } else {
          // create new job conversation
          axios({
            method: 'POST',
            url: `${config.api.baseUrl}${config.api.enigmaChat.createJobConversation}`,
            params: {
              senderAdminId: userDetails.id,
              jobNumber: jobNo,
            },
            headers: {
              Authorization: 'Bearer ' + localStorage.token,
            },
          })
            .then((response) => {
              Success('Conversation Created Successfully')
            })
            .catch((error) => {
              toast.error(error?.response?.data?.error ?? 'No Data Found')
            })
            .finally(() => {
              setLoading(false)
              // history.push('./enigma-chat')
            })
        }
      })
      .catch((error) => {
        toast.error(error?.response?.data?.error ?? 'No Data Found')
      })
      .finally(() => {
        setLoading(false)
        // history.push('./enigma-chat')
      })
  }

  const vehicleIcon = (rowsData) => {
    switch (rowsData.vehicleTruckDTO?.vehicleType) {
      case 'DOUBLE_MAX_60':
        return (
          <img
            src="../../../assets/trucks/Double-Max-60.png"
            width="50px"
            title={rowsData.vehicleTruckDTO?.vehicleType}
            height="15px"
            alt=""
          />
        )
      case 'DOUBLE_MAX_80':
        return (
          <img
            src="../../../assets/trucks/Double-Max-80.png"
            width="50px"
            title={rowsData.vehicleTruckDTO?.vehicleType}
            height="15px"
            alt=""
          />
        )
      case 'SIDELOADER_MAX_40':
        return (
          <img
            src="../../../assets/trucks/Sideloader-Max-40.png"
            width="50px"
            title={rowsData.vehicleTruckDTO?.vehicleType}
            height="15px"
            alt=""
          />
        )
      case 'SEMITRAILER_SINGLE_MAX_20':
        return (
          <img
            src="../../../assets/trucks/Semi-Trailer-Single-Max-20.png"
            title={rowsData.vehicleTruckDTO?.vehicleType}
            width="50px"
            height="15px"
            alt=""
          />
        )
      case 'RIGID_MAX_20':
        return (
          <img
            src="../../../assets/trucks/Rigid-MAX-Standerd.png"
            width="50px"
            height="15px"
            alt=""
          />
        )
      default:
        return <FontAwesomeIcon className=" truckIcon" icon={faCar} />
    }
  }

  const jobRowClick = (data) => {
    setJobobNumber(data)
  }

  useEffect(() => {
    try {
      setLoading(true)
      $.getJSON('https://brass-sloth-3818.twil.io/capability-token').done(
        function (data) {
          setToken(data.token)
        }
      )
    } finally {
      setLoading(false)
    }
  }, [])

  const getTwilioVoiceCall = async () => {
    const device = new Device(token)
    try {
      setLoading(true)
      await device.connect({
        params: {
          To: currentPhone,
        },
      })
    } finally {
      document.getElementById('button-hangup').onclick = () => {
        setShowTwilioLogs(false)
        setCurrentPhone('')
        device.disconnectAll()
      }
      setLoading(false)
    }
  }

  const getTwilioVoiceCallNumber = (number) => {
    setShowTwilioLogs(true)
    setCurrentPhone(number)
  }

  const containerLoadsColumns = [
    columnHelper.accessor('containerLoadNumber', {
      id: 'containerLoadNumber',
      header: 'Container Load No',
    }),
    columnHelper.accessor('pickupAddress', {
      id: 'pickupAddress',
      header: 'Pick Up From ',
    }),
    columnHelper.accessor('deliveryAddress', {
      id: 'deliveryAddress',
      header: 'Deliver To',
    }),
    columnHelper.accessor('loadStatus', {
      id: 'loadStatus',
      header: 'Load Status',
    }),
    columnHelper.accessor('containerSize', {
      id: 'containerSize',
      header: 'Container Size',
    }),
    columnHelper.accessor('grossWeight', {
      id: 'grossWeight',
      header: 'Container Weight ',
    }),
    columnHelper.accessor('chat', {
      id: 'chat',
      header: 'Chat',
      cell: ({ row }) => {
        // console.log(row.original)
        return (
          <FontAwesomeIcon
            onClick={() => handleChatJobConversation(row.original.jobNo)}
            style={{
              display: 'block',
              margin: '0 auto',
              color: 'blue',
              cursor: 'pointer',
            }}
            className="truckIcon"
            icon={faComment}
          />
        )
      },
    }),
  ]

  return (
    <div className="w-100">
      <Suspense fallback={<div>Loading...</div>}>
        <JobNotesModal
          show={!!selectedJob}
          handleClose={() => setSelectedJob(null)}
          job={selectedJob}
        />
      </Suspense>
      <ContainerWithHeading
        heading="Drivers Jobs Planning"
        Wrapper="div"
        wrapperProps={{
          className: 'container-fluid',
        }}
      >
        {loading && <Spinner />}
        <div className="row p-2">
          <div className="col-md-6">
            <div className="row mb-3">
              <div className="col-md-4">
                <select
                  className="form-select"
                  onChange={(e) => getDriverCardsWithoutStatus(e.target.value)}
                >
                  <option value="All">All</option>
                  {lookupsList?.driverStatusEnums.map((item) => (
                    <option key={item} value={item}>
                      {item}
                    </option>
                  ))}
                </select>
              </div>
              <div className="col-md-8">
                <div className="d-flex gap-2 justify-content-end align-items-center h-100">
                  <Link to="/transport/drivers" target="_blank">
                    Driver Details
                  </Link>
                  <Link to="/transport/vehicles" target="_blank">
                    Vehicle Details
                  </Link>
                </div>
              </div>
            </div>
            <Table
              inheritTableWidth
              data={drivers}
              columns={driverTableColumns}
              onDrop={drop}
              showOnlyPagination
              // showTableBottom={false}
            />
          </div>
          <div className="col-md-6">
            <div className="row">
              <div className="col-md-9">
                {mutation.isPending && <BootStrapSpinner />}
                {mutation.isIdle && (
                  <p>Click on location Icon to view the location on map</p>
                )}
                <div className="columnData mapContainer">
                  {mutation?.data && (
                    <Map
                      positionProp={{
                        lat: mutation.data?.posLattitude / 1e6,
                        lng: mutation.data?.posLongitude / 1e6,
                      }}
                    />
                  )}
                  {mutation.error && (
                    <p className="text-danger">Error occurred</p>
                  )}
                </div>
              </div>
              <div className="col-md-3">
                <ContainerWithHeading heading="Recent Activity">
                  <ul class="list-group">
                    <li className="list-group-item list-group-item-primary">
                      Track Call from Allocator
                    </li>
                    <Link
                      to="/loads-planning"
                      className="list-group-item list-group-item-action"
                    >
                      Status
                    </Link>
                    <Link
                      to="/driver-dashboard"
                      className="list-group-item list-group-item-action"
                    >
                      Last Location
                    </Link>
                    <Link
                      to="/clm-dashboard"
                      className="list-group-item list-group-item-action"
                    >
                      Date
                    </Link>
                    <Link
                      to="/clm-dashboard"
                      className="list-group-item list-group-item-action"
                    >
                      Time
                    </Link>
                    <Link
                      to="/clm-dashboard"
                      className="list-group-item list-group-item-action"
                    >
                      Message
                    </Link>
                  </ul>
                </ContainerWithHeading>
                <ContainerWithHeading
                  Wrapper="div"
                  wrapperProps={{ className: 'mt-3' }}
                  heading="Driver Alert"
                >
                  <div
                    className="d-flex justify-content-center align-items-center"
                    style={{ height: '12rem' }}
                  >
                    <FontAwesomeIcon className="fa truckIcon" icon={faBell} />
                  </div>
                </ContainerWithHeading>
              </div>
            </div>
          </div>
          {jobsDetailsDtoByDriverCardId.length && (
            <div className="col-md-12">
              <div className="row">
                <div className="col-md-6">
                  <ContainerWithHeading
                    heading="Jobs Details"
                    containerClassNames="px-0"
                  >
                    {jobsDetailsDtoByDriverCardId?.map((job) => (
                      <Fragment>
                        <div className="p-2">
                          <div className="d-flex align-items-center justify-content-between my-2">
                            <h5
                              className="cursor-pointer m-0"
                              onClick={() => jobRowClick(job.jobNumber)}
                            >
                              {job.jobNumber}
                            </h5>
                            <button
                              className="btn btn-info btn-sm"
                              onClick={() => setSelectedJob(job)}
                            >
                              Notes
                            </button>
                          </div>
                          {!isEmpty(job.containerLoads) && (
                            <Table
                              inheritTableWidth
                              columns={containerLoadsColumns}
                              data={Object.values(job.containerLoads)}
                              showTableBottom={false}
                              showGlobalSearch={false}
                              dontShowFilters={true}
                            />
                          )}
                        </div>
                        <hr />
                      </Fragment>
                    ))}
                  </ContainerWithHeading>
                </div>
                <div
                  style={{
                    borderRight: '1px solid gainsboro',
                  }}
                  className="col-md-2"
                >
                  <h5>Load List</h5>
                  <div className="row">
                    {jobsDetailsDtoByDriverCardId?.map(
                      (rowsData) =>
                        rowsData.jobNumber === jobNum &&
                        Object.keys(rowsData.containerLoads).map(function (
                          key,
                          index
                        ) {
                          return (
                            <>
                              <div className="col-md-12">
                                <ul className="pe-0">
                                  <li>
                                    <p
                                      onClick={() => {
                                        setStageDetail(index)
                                        setContainerLoadNumb(
                                          rowsData.containerLoads[key]
                                            .containerLoadNumber
                                        )
                                      }}
                                    >
                                      Load{' '}
                                      {
                                        rowsData.containerLoads[key]
                                          .containerLoadNumber
                                      }{' '}
                                    </p>
                                  </li>
                                </ul>
                              </div>
                            </>
                          )
                        })
                    )}
                  </div>
                  <div className="row">
                    <h5>Load {containerLoadNumb} Details</h5>
                    {jobsDetailsDtoByDriverCardId?.map(
                      (rowsData) =>
                        rowsData.jobNumber === jobNum &&
                        Object.keys(rowsData.containerLoads).map(function (
                          key,
                          index
                        ) {
                          return (
                            <>
                              {index === stageDetail && (
                                <>
                                  <div className="col-md-4 ">
                                    <p>
                                      {' '}
                                      <span>Job No:</span>
                                      <br />{' '}
                                      {rowsData.containerLoads[key].jobNo}
                                    </p>
                                    <p>
                                      {' '}
                                      <span>Cust Ref:</span>
                                      <br />{' '}
                                      {
                                        rowsData.containerLoads[key]
                                          .customerReferenceNO
                                      }
                                    </p>
                                    <p>
                                      {' '}
                                      <span>Billing Cust:</span>
                                      <br />{' '}
                                      {
                                        rowsData.containerLoads[key]
                                          .billingCustomer
                                      }
                                    </p>
                                    <p>
                                      {' '}
                                      <span>Release No: </span>
                                      <br />{' '}
                                      {rowsData.containerLoads[key].releaseNO}
                                    </p>
                                  </div>
                                  <div className="col-md-4 ">
                                    <p>
                                      {' '}
                                      <span>Order No:</span>
                                      <br />{' '}
                                      {rowsData.containerLoads[key].orderNo}
                                    </p>
                                    <p>
                                      {' '}
                                      <span>Consignee Name:</span>
                                      <br />{' '}
                                      {
                                        rowsData.containerLoads[key]
                                          .consigneeName
                                      }
                                    </p>
                                    <p>
                                      {' '}
                                      <span>Container No:</span>
                                      <br />{' '}
                                      {rowsData.containerLoads[key].containerNO}
                                    </p>
                                    <p>
                                      {' '}
                                      <span>Pick Up Address: </span>
                                      <br />{' '}
                                      {
                                        rowsData.containerLoads[key]
                                          .pickupAddress
                                      }
                                    </p>
                                  </div>
                                  <div className="col-md-4 ">
                                    <p>
                                      {' '}
                                      <span>Grade:</span>
                                      <br />{' '}
                                      {rowsData.containerLoads[key].grade}
                                    </p>
                                    <p>
                                      {' '}
                                      <span>Gross Weight:</span>
                                      <br />{' '}
                                      {rowsData.containerLoads[key].grossWeight}
                                    </p>
                                    <p>
                                      {' '}
                                      <span>Container Size:</span>
                                      <br />{' '}
                                      {
                                        rowsData.containerLoads[key]
                                          .containerSize
                                      }
                                    </p>
                                    <p>
                                      {' '}
                                      <span>Delivery Address: </span>
                                      <br />{' '}
                                      {
                                        rowsData.containerLoads[key]
                                          .deliveryAddress
                                      }
                                    </p>
                                  </div>
                                </>
                              )}
                            </>
                          )
                        })
                    )}
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="row">
                    <div className="col-md-8">
                      <h5>Vehicle Detail</h5>
                    </div>
                    <div className="col-md-4">
                      {jobsDetailsDtoByDriverCardId?.map(
                        (rowsData) =>
                          rowsData.jobNumber === jobNum && (
                            <span
                              style={{
                                cursor: 'pointer',
                              }}
                              onClick={() =>
                                window.open(
                                  `#/transport/vehicles/view/${rowsData.jobVehicleTruckDTO.vehicleTruckId}`,
                                  '_blank'
                                )
                              }
                            >
                              {vehicleIcon(rowsData)}
                            </span>
                          )
                      )}
                    </div>
                  </div>
                  <div className="row">
                    {jobsDetailsDtoByDriverCardId?.map(
                      (rowsData) =>
                        rowsData.jobNumber === jobNum && (
                          <>
                            <div className="col-md-6 ">
                              <p>
                                <span>Transport Job Type:</span> <br />
                                {rowsData.jobVehicleTruckDTO.transportJobType}
                              </p>
                              <p>
                                <span>Truck Type:</span>
                                <br /> {rowsData.jobVehicleTruckDTO.truckType}
                              </p>
                              <p>
                                <span>Truck Size:</span>
                                <br />{' '}
                                {rowsData.jobVehicleTruckDTO?.truckSize || ''}
                              </p>
                            </div>
                            <div className="col-md-6 ">
                              <p>
                                <span>Truck Rego:</span>
                                <br /> {rowsData.jobVehicleTruckDTO.truckRego}
                              </p>
                              <p>
                                <span>Truck BAT No:</span>
                                <br />{' '}
                                {rowsData.jobVehicleTruckDTO?.truckBat || ''}
                              </p>
                            </div>
                          </>
                        )
                    )}
                    {jobsDetailsDtoByDriverCardId?.map(
                      (rowsData) =>
                        rowsData.jobNumber === jobNum &&
                        Object.keys(
                          rowsData.jobVehicleTruckDTO.vehicleTrailers
                        ).map(function (key, index) {
                          return (
                            <>
                              <div className="col-md-12 ">
                                <p>
                                  <span>Trailer No {index + 1}:</span>{' '}
                                  {
                                    rowsData.jobVehicleTruckDTO.vehicleTrailers[
                                      key
                                    ]
                                  }{' '}
                                </p>
                              </div>
                            </>
                          )
                        })
                    )}
                    <h5>Driver Details</h5>
                    {jobsDetailsDtoByDriverCardId?.map(
                      (rowsData) =>
                        rowsData.jobNumber === jobNum && (
                          <>
                            <div className="col-md-4 ">
                              <p>
                                <span>Driver Id:</span>
                                <br /> {rowsData.jobDriverDTO.driverId}{' '}
                              </p>
                            </div>
                            <div className="col-md-5 ">
                              <p>
                                <span>Driver Name:</span>
                                <br /> {rowsData.jobDriverDTO.driverName}{' '}
                              </p>
                            </div>
                            <div className="col-md-3">
                              <img
                                src={
                                  rowsData.jobDriverDTO.profilePic
                                    ? `data:image/jpeg;base64,${rowsData.jobDriverDTO.profilePic}`
                                    : '../../../assets/user-avatar.png'
                                }
                                width="30px"
                                alt=""
                              />
                            </div>
                          </>
                        )
                    )}
                    <br />
                    <div className="col-md-12">
                      <h5>Notes</h5>
                      <textarea className="form-control" type="textarea" />
                    </div>
                    <div className="col-md-12 mt-3">
                      <FontAwesomeIcon icon={faPhone} />
                      <FontAwesomeIcon
                        className="pencilContainer"
                        icon={faEnvelope}
                      />
                      {jobsDetailsDtoByDriverCardId?.map(
                        (rowsData) =>
                          rowsData.jobNumber === jobNum &&
                          rowsData.jobDriverDTO.shift === 'DAY' && (
                            <FontAwesomeIcon icon={faSun} />
                          )
                      )}
                      {jobsDetailsDtoByDriverCardId?.map(
                        (rowsData) =>
                          rowsData.jobNumber === jobNum &&
                          rowsData.jobDriverDTO.shift !== 'DAY' && (
                            <FontAwesomeIcon icon={faMoon} />
                          )
                      )}
                      <FontAwesomeIcon
                        className="pencilContainer"
                        onClick={() => deleteDriver(driverId)}
                        icon={faTrash}
                      />
                      <FontAwesomeIcon
                        onClick={() =>
                          history.push('transport/drivers/edit/' + driverId)
                        }
                        icon={faPencil}
                      />
                      <FontAwesomeIcon
                        className=" pencilContainer"
                        onClick={() =>
                          history.push('transport/drivers/view/' + driverId)
                        }
                        icon={faEye}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </ContainerWithHeading>

      {showDriverAssign && (
        <ConfirmModal
          closeModal={setShowDriverAssign.bind(this, false)}
          message="Are you sure you want to assign?"
          confirmAction={assignJobToDriver}
        />
      )}
      <Modal
        className="calling-modal"
        show={showTwilioLogs}
        onHide={() => setShowTwilioLogs(false)}
      >
        <Modal.Header closeButton>
          <Modal.Title>Calling to {currentPhone}</Modal.Title>
        </Modal.Header>
        <Modal.Body>Your device is ready to make a call</Modal.Body>
        <Modal.Footer>
          <Button
            variant="primary"
            className="callButon"
            onClick={() => getTwilioVoiceCall()}
          >
            Make a Call Now
          </Button>
          <Button
            variant="primary"
            className="disconnectButon"
            id="button-hangup"
          >
            Disconnect
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  )
}
