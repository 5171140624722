import { useQuery } from '@tanstack/react-query'
import dayjs from 'dayjs'
import fetchService from '../../../../components/utils/fetchService'
import formatDate from '../helpers/formatDate'

export default function useTotalKMDistanceCovered(
  startDate = 'sixMonthsBefore'
) {
  const endDate = dayjs().format('YYYY-MM-DD')
  const formattedStartDate = formatDate(startDate)

  const url = `/dashboard/driver/total-km-distance-covered`

  return useQuery({
    queryKey: ['transport', url, startDate],
    queryFn: async () =>
      await fetchService({
        url,
        method: 'post',
        returnRaw: true,
        body: {
          endDate: endDate,
          periodType: 'MONTH',
          startDate: formattedStartDate,
        },
      }),
  })
}
