import DataFormTable from '../../modules/cerebro/customer/data-management/components/DataFormTable/DataFormTable'

const userDataMgmt = [
  {
    name: 'DataMgmt',
    path: '/DataManagement/user/profileType',
    component: DataFormTable,
    staticProps: {
      formName: 'User Profile Type',
      module: 'user',
      subModule: 'profileType',
      uniqueReferenceKey: 'recId',
    },
    includeHeaderFooter: true,
  },
  {
    name: 'DataMgmt',
    path: '/DataManagement/user/profileGroup',
    component: DataFormTable,
    staticProps: {
      formName: 'User Profile Group',
      module: 'user',
      subModule: 'profileGroup',
      uniqueReferenceKey: 'recId',
    },
    includeHeaderFooter: true,
  },
]

export default userDataMgmt
