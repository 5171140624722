import config from '../../config'
import { setCustomerNames, setLookupData } from '../slices/lookup-slice'
import * as Toastify from '../../components/utils/Toastify'
import isEmpty from 'lodash/isEmpty'

export const getLookupData = () => {
  return async (dispatch) => {
    const fetchData = async () => {
      const url = config.api.baseUrl + config.api.lookups
      const requestOptions = {
        method: 'GET',
        headers: { Authorization: 'Bearer ' + localStorage.token },
      }
      const response = await fetch(url, requestOptions)

      if (!response.ok) {
        throw new Error('Some error occurred while fetching lookup data.')
      }
      return response.json()
    }

    const processData = (data) => {
      // Sort Movement Type based on Priority
      if (!isEmpty(data?.stageMovementTypes))
        data.stageMovementTypes.sort((a, b) => {
          if (a.priority < b.priority) return -1
          if (a.priority > b.priority) return 1
          return 0
        })
    }

    try {
      const lookupData = await fetchData()
      processData(lookupData)
      dispatch(setLookupData(lookupData))
    } catch (err) {
      Toastify.Error('Some error occurred while fetching lookup data.')
    }
  }
}

export const fetchCustomerNames = () => {
  return async (dispatch) => {
    const fetchData = async () => {
      const url = config.api.baseUrl + config.api.customerNames
      const requestOptions = {
        method: 'GET',
        headers: { Authorization: 'Bearer ' + localStorage.token },
      }
      const response = await fetch(url, requestOptions)

      if (!response.ok) {
        throw new Error('Some error occurred while fetching lookup data.')
      }
      return response.json()
    }

    try {
      const customerNames = await fetchData()
      dispatch(setCustomerNames(customerNames))
    } catch (err) {
      Toastify.Error('Some error occurred while fetching Customer Names.')
    }
  }
}
