export const dropDownKey = {
  LOAD_STATUS_ENUM: 'LOAD_STATUS_ENUM',
  VEHICLE_JOB_TYPE: 'VEHICLE_JOB_TYPE',
  WHARF_JOB_TYPE: 'WHARF_JOB_TYPE',
  SHIPMENT_TYPE: 'SHIPMENT_TYPE',
  STAGE_MOVEMENT_TYPE: 'STAGE_MOVEMENT_TYPE',
  STAGE_FROM: 'STAGE_FROM',
  STAGE_TO: 'STAGE_TO',
  DRIVER_JOB_STATUS_ENUM: 'DRIVER_JOB_STATUS_ENUM',
}
