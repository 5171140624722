import { Link } from 'react-router-dom'
import EditPortSlotCell from '../components/EditPortSlotCell'
import TransitModeOptionCell from '../components/TransitModeOptionCell'

export const slotSummaryTableCols = [
  {
    header: 'Order No',
    id: 'orderNo',
    accessorKey: 'orderNo',
  },
  {
    header: 'Job Seq No',
    id: 'jobSeqNo',
    accessorKey: 'jobSeqNo',
  },
  {
    header: 'Customer Name',
    id: 'customerName',
    accessorKey: 'customerName',
  },
  {
    header: 'Container No',
    id: 'containerNo',
    accessorKey: 'containerNo',
  },
  {
    header: 'Size Type',
    id: 'sizeType',
    accessorKey: 'sizeType',
  },
  {
    header: 'OBL',
    id: 'obl',
    accessorKey: 'obl',
  },
  {
    header: 'Release No',
    id: 'releaseNo',
    accessorKey: 'releaseNo',
  },
  {
    header: 'Port Ref',
    id: 'portRef',
    accessorKey: 'portRef',
  },
  {
    header: 'Port Slot Date',
    id: 'portSlotDate',
    accessorKey: 'portSlotDate',
  },
  {
    header: 'Port Slot Time',
    id: 'portSlotTime',
    accessorKey: 'portSlotTime',
  },
]

export const portSlotKeyToColDefMap = {
  transitMode: (cell) => <TransitModeOptionCell cell={cell} />,

  orderNumber: ({ row: { original } }) => (
    <Link
      target="_blank"
      className="table-link"
      to={`/Operations/view/${original?.orderCustomerDetailsId}`}
    >
      {original?.orderNumber}
    </Link>
  ),

  portSlotDate: (cell) => <EditPortSlotCell cell={cell} />,

  portSlotTime: (cell) => <EditPortSlotCell cell={cell} />,
}

export const portSlotColumns = [
  {
    header: 'Transit Mode',
    id: 'transitMode',
    accessorKey: 'transitMode',
    cell: (cell) => <TransitModeOptionCell cell={cell} />,
  },
  {
    header: 'Order No',
    id: 'orderNumber',
    accessorKey: 'orderNumber',
    cell: ({ row: { original } }) => (
      <Link
        target="_blank"
        className="table-link"
        to={`/Operations/view/${original?.orderCustomerDetailsId}`}
      >
        {original?.orderNumber}
      </Link>
    ),
  },
  {
    header: 'Order Received Date',
    id: 'orderReceivedDate',
    accessorKey: 'orderReceivedDate',
  },
  {
    header: 'Customer Name',
    id: 'customerName',
    accessorKey: 'customerName',
  },
  {
    header: 'Container No',
    id: 'containerNumber',
    accessorKey: 'containerNumber',
  },
  {
    header: 'Container Reference',
    id: 'containerReference',
    accessorKey: 'containerReference',
  },
  {
    header: 'Stage Movement Type',
    id: 'stageMovementType',
    accessorKey: 'stageMovementType',
  },
  {
    header: 'Wharf Job Type',
    id: 'wharfJobType',
    accessorKey: 'wharfJobType',
  },
  {
    header: 'Shipment Type',
    id: 'shipmentType',
    accessorKey: 'shipmentType',
  },
  {
    header: 'OBL No',
    id: 'oblNo',
    accessorKey: 'oblNo',
  },
  {
    header: 'Release No',
    id: 'releaseNo',
    accessorKey: 'releaseNo',
  },
  {
    header: 'Shipping Agent',
    id: 'shippingAgent',
    accessorKey: 'shippingAgent',
  },
  {
    header: 'Wharf',
    id: 'wharf',
    accessorKey: 'wharf',
  },
  {
    header: 'Planner Remarks',
    id: 'plannerRemarks',
    accessorKey: 'plannerRemarks',
  },
  {
    header: 'Voyage',
    id: 'voyage',
    accessorKey: 'voyage',
  },
  {
    header: 'Vessel',
    id: 'vessel',
    accessorKey: 'vessel',
  },
  {
    header: 'Port Name',
    id: 'portName',
    accessorKey: 'portName',
  },
  {
    header: 'Port Reference',
    id: 'portReference',
    accessorKey: 'portReference',
  },
  {
    header: 'Port Slot Date',
    id: 'portSlotDate',
    accessorKey: 'portSlotDate',
    cell: (cell) => <EditPortSlotCell cell={cell} />,
  },
  {
    header: 'Port Slot Time',
    id: 'portSlotTime',
    accessorKey: 'portSlotTime',
    cell: (cell) => <EditPortSlotCell cell={cell} />,
  },
  {
    header: 'Shipper/Consignee',
    id: 'consigneeName',
    accessorKey: 'consigneeName',
  },
  {
    header: 'Wharf Slot Date',
    id: 'wharfSlotDate',
    accessorKey: 'wharfSlotDate',
  },
  {
    header: 'Expected Date Clint',
    id: 'expectedDateClient',
    accessorKey: 'expectedDateClient',
  },
  {
    header: 'EIDO Pin',
    id: 'eidoPIN',
    accessorKey: 'eidoPIN',
  },
  {
    header: 'Pickup Location',
    id: 'pickUpLocation',
    accessorKey: 'pickUpLocation',
  },
  {
    header: 'Delivery Location',
    id: 'deliverLocation',
    accessorKey: 'deliverLocation',
  },
  {
    header: 'Yard Code',
    id: 'yardCode',
    accessorKey: 'yardCode',
  },
  {
    header: 'Dehire Code',
    id: 'dehireCode',
    accessorKey: 'dehireCode',
  },
  {
    header: 'Gate In No',
    id: 'gateInNo',
    accessorKey: 'gateInNo',
  },
  {
    header: 'Gate In Date',
    id: 'gateInDate',
    accessorKey: 'gateInDate',
  },
  {
    header: 'Gate In Time',
    id: 'gateInTime',
    accessorKey: 'gateInTime',
  },
  {
    header: 'First Availability',
    id: 'firstAvaibility',
    accessorKey: 'firstAvaibility',
  },
  {
    header: 'Imp Available',
    id: 'impAvailable',
    accessorKey: 'impAvailable',
  },
  {
    header: 'Storage Start Date',
    id: 'storageStartDate',
    accessorKey: 'storageStartDate',
  },
  {
    header: 'Exp Available',
    id: 'expAvailable',
    accessorKey: 'expAvailable',
  },
  {
    header: 'Closing Date',
    id: 'closingDate',
    accessorKey: 'closingDate',
  },
  {
    header: 'Cargo Type',
    id: 'cargoType',
    accessorKey: 'cargoType',
  },
  {
    header: 'Gross Weight',
    id: 'grossWeight',
    accessorKey: 'grossWeight',
  },
  {
    header: 'Size',
    id: 'size',
    accessorKey: 'size',
  },
  {
    header: 'Pack / Unpack Date',
    id: 'packedDate',
    accessorKey: 'packedDate',
  },
  {
    header: 'Collection Date',
    id: 'collectionDate',
    accessorKey: 'collectionDate',
  },
  {
    header: 'Collection Ref',
    id: 'collectionRef',
    accessorKey: 'collectionRef',
  },
  {
    header: 'ETA',
    id: 'eta',
    accessorKey: 'eta',
  },
  {
    header: 'ETD',
    id: 'etd',
    accessorKey: 'etd',
  },
  {
    header: 'Dispatched',
    id: 'dispatched',
    accessorKey: 'dispatched',
  },
  {
    header: 'Order Remarks',
    id: 'orderRemarks',
    accessorKey: 'orderRemarks',
  },
  {
    header: 'Container Remarks',
    id: 'containerRemarks',
    accessorKey: 'containerRemarks',
  },
  {
    header: 'Hold Status',
    id: 'holdStatus',
    accessorKey: 'holdStatus',
  },
  {
    header: 'Held By',
    id: 'holdBy',
    accessorKey: 'holdBy',
  },
  {
    header: 'Hold Date Time',
    id: 'holdDateTime',
    accessorKey: 'holdDateTime',
  },
  {
    header: 'Remarks Hold',
    id: 'remarksHold',
    accessorKey: 'remarksHold',
  },
  {
    header: 'Release By',
    id: 'releaseBy',
    accessorKey: 'releaseBy',
  },
  {
    header: 'Release Date Time',
    id: 'releaseDateTime',
    accessorKey: 'releaseDateTime',
  },
  {
    header: 'Remarks Release',
    id: 'remarksRelease',
    accessorKey: 'remarksRelease',
  },
]

export const transitModeOptions = ['Road', 'Rail', 'Empty Park', 'None']
