// import WMSDashboard from "../components/WMS/Dashboard";
import WMSDashboard from '../modules/WMS/Dashboard'
import UnpackedList from '../modules/WMS/UnpackedList'
import packedList from '../modules/WMS/PackedList'
import Reports from '../modules/WMS/Reports'
import OrdersOperations from '../modules/cerebro/orders/ordersOperations/OrdersOperations'
const wmsRoutes = [
  {
    name: 'dashboard',
    path: '/wms-dashboard',
    component: WMSDashboard,
    includeHeaderFooter: true,
    showSideBarOf: 'wms-dashboard',
    headerFooterOf: 'wms-dashboard',
  },
  {
    name: 'packing-list',
    path: '/wms-packing-list',
    component: packedList,
    includeHeaderFooter: true,
    showSideBarOf: 'wms-dashboard',
    headerFooterOf: 'wms-dashboard',
  },
  {
    name: 'unpacking-list',
    path: '/wms-unpacking-list',
    component: UnpackedList,
    includeHeaderFooter: true,
    showSideBarOf: 'wms-dashboard',
    headerFooterOf: 'wms-dashboard',
  },
  {
    name: 'reports',
    path: '/wms-reports',
    component: Reports,
    includeHeaderFooter: true,
    showSideBarOf: 'wms-dashboard',
    headerFooterOf: 'wms-dashboard',
  },
  {
    name: 'reports',
    path: '/wms/operations/create',
    component: OrdersOperations,
    includeHeaderFooter: true,
    showSideBarOf: 'wms-dashboard',
    headerFooterOf: 'wms-dashboard',
  },
]
export default wmsRoutes
