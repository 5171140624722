import { useQuery } from '@tanstack/react-query'
import { createColumnHelper } from '@tanstack/react-table'
import axios from 'axios'
import React, { useCallback, useMemo, useState } from 'react'
import { Modal } from 'react-bootstrap'
import Dropzone from 'react-dropzone'
import Table from '../../../../../components/Common/Tanstack-table/Table'
import { getRowDataFromEvent } from '../../../../../components/Common/Tanstack-table/utils'
import ContainerWithHeading from '../../../../../components/styles/Containers/ContainerWithHeading'
import * as Toastify from '../../../../../components/utils/Toastify'
import config from '../../../../../config'
import { queryKeys } from '../../../../../helper/hooks/apiHooks'
import useModalContainer from '../../../../../helper/hooks/useModalContainer'
import ContainerStages from '../../../containers/stage-tracker/ContainerStages'
import { useOrdersContext } from '../../context/orderOperationsContext'
import { getConsigneesNames } from '../api'
import CargoDetailsModal from './components/CargoDetailsModal'

const EdoModal = ({ container, handleFileUpload, show, handleClose }) => {
  return (
    <Modal show={show} size="xl" onHide={handleClose}>
      <Modal.Header closeButton>Upload EDO Document</Modal.Header>
      <Modal.Body>
        <Dropzone
          noDragEventsBubbling
          onDrop={(acceptedFile) => {
            handleFileUpload(acceptedFile, container.orderContainerDetailsId)
          }}
          multiple={false}
        >
          {({ getRootProps, getInputProps }) => (
            <div className="drop-zone-container" {...getRootProps()}>
              <input {...getInputProps()} />
              <h4>Drag a file here</h4>
            </div>
          )}
        </Dropzone>
      </Modal.Body>
    </Modal>
  )
}

export default function ContainersList({
  containerListData,
  type,
  handleEditClicks,
  orderModules,
  getContainerData,
}) {
  const columnHelper = createColumnHelper()
  const operationsCtxData = useOrdersContext()
  const [selectedContainerId, setSelectedContainerId] = useState(null)
  const [showCstModal, setShowCstModal] = useState(false)

  const [selectedContainerForEdo, setSelectedContainerForEdo] = useState(null)
  const handleOpen = (container) => {
    setSelectedContainerForEdo(container)
  }

  const { data: consigneeNames } = useQuery({
    queryKey: [
      '/customercard/consignee-address/consignee-name',
      operationsCtxData.customerId,
    ],
    queryFn: () => getConsigneesNames(operationsCtxData?.customerId),
    enabled: !!operationsCtxData?.customerId,
    staleTime: 60_000 * 15,
  })

  const openCstModal = useCallback((container) => {
    setShowCstModal(true)
    setSelectedContainerId(container.orderContainerDetailsId)
  }, [])

  const deleteContainerById = useCallback(
    (id) => {
      axios({
        method: 'DELETE',
        url: config.api.baseUrl + '/operations/order/container/' + id,
        headers: {
          Authorization: 'Bearer ' + localStorage.token,
        },
      })
        .then(() => {
          Toastify.Success('Container deleted successfully..!')
          getContainerData(operationsCtxData.orderCustomerDetailsId)
        })
        .catch((error) => {
          Toastify.Error(error?.response?.data?.error)
        })
    },
    [getContainerData, operationsCtxData.orderCustomerDetailsId]
  )

  const handleFileUpload = useCallback(
    (files, id) => {
      try {
        // orderContainerEDO - field gives the current state of file in the container
        const formData = new FormData()
        formData.append('file', files[0])
        axios(
          `${config.api.baseUrl}/operations/order/container/edo/upload?orderContainerDetailsId=${id}`,
          {
            method: 'POST',
            headers: {
              Authorization: 'Bearer ' + localStorage.token,
              'Content-Type': 'multipart/form-data',
            },
            data: formData,
          }
        )
          .then(() => {
            Toastify.Success('File submitted successfully!')
            return getContainerData(operationsCtxData.orderCustomerDetailsId)
          })
          .catch((error) => {
            Toastify.Error(
              error?.response?.data?.error || 'Something went wrong!'
            )
          })
      } catch (error) {
        console.error(error)
      } finally {
        setSelectedContainerForEdo(null)
      }
    },
    [getContainerData, operationsCtxData.orderCustomerDetailsId]
  )

  const { ModalContainer, setShow: showCargoModal } = useModalContainer({
    header: 'Cargo Details',
    btnClass: 'btn btn-primary btn-sm ms-2',
    body: (
      <CargoDetailsModal
        type={type}
        consigneeNames={consigneeNames}
        containerId={selectedContainerId}
      />
    ),
  })

  const enableCargoSection = useCallback(
    (cargoData) => {
      showCargoModal(true)
      setSelectedContainerId(cargoData.orderContainerDetailsId)
    },
    [showCargoModal]
  )

  const columns = useMemo(
    () => [
      columnHelper.accessor('containerOrderNumber', {
        id: 'containerOrderNumber',
        header: () => <span>Con.Ord.No</span>,
        filterFn: 'fuzzy',
      }),
      columnHelper.accessor('containerNumber', {
        id: 'containerNumber',
        header: () => <span>Con.No</span>,
        filterFn: 'fuzzy',
      }),
      columnHelper.accessor('sizeType', {
        id: 'sizeType',
        header: () => 'Size',
        filterFn: 'fuzzy',
      }),
      columnHelper.accessor('grossWeight', {
        id: 'grossWeight',
        header: () => <span>Weight</span>,
      }),
      columnHelper.accessor('stageMovementType', {
        id: 'stageMovementType',
        header: () => <span>Movement</span>,
        filterFn: 'fuzzy',
      }),
      columnHelper.accessor('slotDateTime', {
        id: 'slotDateTime',
        header: () => <span>Slot</span>,
        filterFn: 'fuzzy',
      }),
      columnHelper.accessor(
        (row) => row.orderContainerEDO?.lastModifiedDate || null,
        {
          id: 'lastModifiedDate',
          header: () => <span>EDO</span>,
          cell: (info) => info.getValue(),
          filterFn: 'fuzzy',
        }
      ),
      columnHelper.accessor('', {
        id: 'action',
        header: () => <span>Action</span>,
        minSize: 220,
        cell: (col) => {
          const container = col.row.original
          return (
            <div className="d-flex">
              <button
                className="btn btn-primary btn-sm ms-2"
                onClick={(e) => {
                  e.preventDefault()
                  handleEditClicks(container)
                }}
              >
                {type === undefined || type === 'edit' ? 'Edit' : 'View'}
              </button>

              {(type === undefined || type === 'edit') && (
                <button
                  className="btn btn-sm btn-danger ms-2"
                  onClick={(e) => {
                    e.preventDefault()
                    deleteContainerById(container?.orderContainerDetailsId)
                  }}
                >
                  Delete
                </button>
              )}
              <button
                type="button"
                className="btn btn-sm btn-danger ms-2"
                onClick={(e) => {
                  e.preventDefault()
                  openCstModal(container)
                }}
              >
                CST
              </button>
              {container.orderContainerEDO !== null ? (
                <button
                  className="btn btn-danger btn-sm ms-2"
                  onClick={(e) => {
                    e.preventDefault()
                    Toastify.Error('EDO Document already exists...!')
                  }}
                >
                  EDO
                </button>
              ) : (
                <button
                  onClick={(e) => {
                    e.preventDefault()
                    e.stopPropagation()
                    handleOpen(container)
                  }}
                  type="button"
                  className="btn btn-primary btn-sm ms-2 flex-direction-column justify-content-center"
                >
                  EDO
                </button>
              )}

              {orderModules?.includes('WMS') && (
                <button
                  className="btn btn-primary btn-sm ms-2"
                  onClick={() => enableCargoSection(container)}
                >
                  Cargo
                </button>
              )}
            </div>
          )
        },
      }),
    ],
    [
      columnHelper,
      deleteContainerById,
      enableCargoSection,
      handleEditClicks,
      openCstModal,
      orderModules,
      type,
    ]
  )

  return (
    <>
      <EdoModal
        show={!!selectedContainerForEdo}
        container={selectedContainerForEdo}
        handleFileUpload={handleFileUpload}
        handleClose={() => setSelectedContainerForEdo(null)}
      />
      <ContainerWithHeading
        heading="Containers List"
        Wrapper="div"
        wrapperProps={{ className: 'mb-3' }}
      >
        <Table
          columns={columns}
          data={containerListData}
          showTableBottom={false}
          tableRowProps={{
            onClick: (e) => {
              e.preventDefault()
              e.stopPropagation()
              const row = getRowDataFromEvent(e)
              handleEditClicks(row.original)
            },
          }}
        />
      </ContainerWithHeading>
      {ModalContainer}
      {selectedContainerId && showCstModal && (
        <Modal
          show
          onHide={() => {
            setShowCstModal(false)
            setSelectedContainerId(null)
          }}
          size="xl"
          centered
        >
          <ContainerStages
            queryData={{
              key: queryKeys.cerebro.order.container.stageTracker(
                selectedContainerId
              ),
              url: `/container/stage-tracker?orderContainerDetailsById=${selectedContainerId}`,
            }}
            queryKeyToInvalidate={queryKeys.cerebro.order.container.stageTracker(
              selectedContainerId
            )}
          />
        </Modal>
      )}
    </>
  )
}
