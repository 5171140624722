import React, { useState } from 'react'
import { useMutation } from '@tanstack/react-query'
import config from '../../../config'
import fetchService from '../../utils/fetchService'
import { Success } from '../../utils/Toastify'

const { containerNotes } = config.api

const LoadNote = ({ load }) => {
  const [note, setNote] = useState(load?.latestNotes)

  const saveNoteMutation = useMutation({
    mutationKey: ['saveNote'],
    mutationFn: async (body) => {
      let url = containerNotes
      let method = 'POST'
      if (body?.containerStageTrackerNoteId) {
        url += `/${body?.containerStageTrackerNoteId}`
        method = 'PUT'
      }
      return await fetchService({
        url,
        method,
        body,
      })
    },
    onSuccess: async (_, body) => {
      let msg =
        load?.latestNotes !== ''
          ? 'Note Updated successfully'
          : 'Note Added successfully'
      Success(msg)
    },
  })

  const onSubmit = (e) => {
    e.preventDefault()
    saveNoteMutation.mutate({
      notes: note,
      containerStageTrackerNoteId: load?.containerStageTrackerNoteId,
      containerStageTrackerId: load?.containerStageTrackerId,
    })
  }

  if (!load) return null
  return (
    <form onSubmit={onSubmit}>
      <input
        type="text"
        className="unset-all"
        value={note}
        onChange={(e) => setNote(e.target.value)}
      />
    </form>
  )
}

export default LoadNote
