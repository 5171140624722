import React, { useCallback, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'
import config from '../../config'
import { getLookupDataState } from '../../store/slices/lookup-slice'
import ContainerWithHeading from '../styles/Containers/ContainerWithHeading'
import Accounting from './Accounting'
import AddressBook from '../../modules/cerebro/customer/customerDetails/AddressBook/AddressBook'
import CargoRules from './CargoRules'
// import './CommonStyle.scss'
import CustomerInitialData from './CustomerInitialData'
import Misc from './Misc/Misc'
import { Col, Row } from 'react-bootstrap'
import { getAccountingDetails } from '../../store/slices/customer-slice'

const CustomerCard = (props) => {
  const { type } = props
  const history = useHistory()

  const { id: customerCardId } = useParams()
  const lookupsList = useSelector(getLookupDataState)
  const [customerData, setCustomerData] = useState({
    address: '',
    operationContactDetails: [],
    accountContactDetails: [],
    countryCodeId: '',
    createdBy: '',
    createdDate: '',
    customerCardId: null,
    customerCodeConfirmation: '',
    customerId: '',
    customerName: '',
    customerSince: '',
    isManual: false,
    lastModifiedBy: '',
    lastModifiedDate: '',
    notes: '',
    postCodeId: '',
    potentialCustomer: true,
    stateCodeId: '',
    suburb: '',
  })

  const accountingData = useSelector(getAccountingDetails)

  const getCustomerData = useCallback((Id) => {
    const url = config.api.baseUrl + config.api.customerCard + '/' + Id
    const requestOptions = {
      method: 'GET',
      headers: { Authorization: 'Bearer ' + localStorage.token },
    }

    fetch(url, requestOptions)
      .then((response) => response.json())
      .then((data) => {
        setCustomerData(data)
      })
      .catch((error) => {
        alert(`Unable to get the user details for this ${Id} please try later`)
      })
  }, [])

  useEffect(() => {
    if ((type === 'view' || type === 'edit') && customerCardId) {
      getCustomerData(customerCardId)
    }
  }, [customerCardId, getCustomerData, type])

  const makeEditForm = () => {
    window.location = '/#/Customer/edit/' + customerCardId
  }

  const DeleteCustomer = () => {
    let answer = window.confirm(
      'Are you sure to delete customer ' + customerCardId
    )
    if (!answer) {
      return false
    }

    const url = config.api.baseUrl + '/customercard/' + customerCardId
    const requestOptions = {
      method: 'DELETE',
      headers: { Authorization: 'Bearer ' + localStorage.token },
    }

    fetch(url, requestOptions)
      .then((response) => response.json())
      .then((data) => {
        alert('customer card  ' + customerCardId + ' has deleted successfully')
        history.push('/customer')
      })
      .catch((error) => {
        console.error('fetch error', error)
        alert(
          'Unable to delete the customer card data now please try after some time!'
        )
      })
  }

  const typeIsCreate = type === 'create'

  return (
    <div className="container-fluid customer-card">
      <h1 className="my-1 p-0">Customer Details</h1>
      <Row>
        <div className="col-md-5">
          <CustomerInitialData
            customerData={customerData}
            type={type}
            customerCardId={customerCardId}
          />
        </div>
        {!typeIsCreate && (
          <div className="col-md-7 ps-0">
            <Row>
              <Col className="card-body" md={12}>
                <AddressBook customerCardId={customerCardId} type={type} />
              </Col>
              <div className="card-body col-md-6 position-relative">
                <ContainerWithHeading
                  heading="Miscellaneous"
                  containerClassNames="misc-wpr p-2"
                >
                  <Misc
                    {...props}
                    lookupsList={lookupsList}
                    customerCardId={customerCardId}
                  />
                </ContainerWithHeading>
                <div className="mt-3">
                  <ContainerWithHeading
                    headerContainerZIndex={1}
                    heading={
                      <a
                        target="_blank"
                        rel="noreferrer"
                        href={`#/Customer/action/rates/${type}/${customerCardId}`}
                      >
                        Rates
                      </a>
                    }
                    containerClassNames="misc-wpr p-2"
                  />
                </div>
              </div>
              <div className="card-body col-md-6 position-relative">
                <ContainerWithHeading
                  heading="Accounting"
                  containerClassNames="p-3"
                >
                  <Accounting
                    lookupsList={lookupsList}
                    customerCardId={customerCardId}
                    type={type}
                  />
                  {accountingData?.ccAccountingId && (
                    <CargoRules
                      {...props}
                      setEnableSpinner={() => ''}
                      isAddressBook={false}
                      controller="customer"
                    />
                  )}
                </ContainerWithHeading>
              </div>
            </Row>
          </div>
        )}

        {!typeIsCreate && (
          <Col md={12}>
            <div className="d-flex mt-5 justify-content-end">
              {type === 'view' && (
                <button
                  className="btn btn-gold me-2"
                  onClick={() => makeEditForm()}
                >
                  Edit
                </button>
              )}
              <button
                className="btn btn-secondary"
                onClick={() => DeleteCustomer()}
              >
                Delete Customer Card
              </button>
            </div>
          </Col>
        )}
      </Row>
    </div>
  )
}

export default CustomerCard
