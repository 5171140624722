import axios from 'axios'
import { format } from 'date-fns'
import { remove } from 'lodash'
import isEmpty from 'lodash/isEmpty'
import isNil from 'lodash/isNil'
import React, { useEffect, useMemo, useState } from 'react'
import { Accordion, Modal } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { toast } from 'react-toastify'
import Table from '../../../../../../components/Common/Tanstack-table/Table'
import { Form } from '../../../../../../components/DataMgmt/Form'
import Spinner from '../../../../../../components/Spinner/Spinner'
import * as Toastify from '../../../../../../components/utils/Toastify'
import { getLookUpOptions } from '../../../../../../components/utils/Utils'
import config from '../../../../../../config'
import CONSTANTS from '../../../../../../constants.json'
import {
  customerDataMgmt,
  datMgmtFields,
  operationsDataMgmt,
  transportDataMgmt,
  userDataMgmt,
} from '../../../../../../routes'
import { getLookupData as setLookupDataInRdxState } from '../../../../../../store/actions/lookup-actions'
import { getLookupDataState } from '../../../../../../store/slices/lookup-slice'

export default function DataFormTable(params) {
  const { formName, module, subModule, uniqueReferenceKey, lookupKeyName } =
    params
  const dispatch = useDispatch()
  const lookUpData = useSelector(getLookupDataState)
  const [tableHeaderData, setTableHeaderData] = useState([])
  const [tableBodyData, setTableBodyData] = useState([])
  const [errorReason, setErrorReason] = useState([])

  const [defaultData, setDefaultData] = useState({})

  const [formId, setFormId] = useState(null)

  const [enableSpinner, setEnableSpinner] = useState(false)
  const [showModal, setShowModal] = useState(false)

  const [submitStatus, setSubmitStatus] = useState(null)
  const [uniqueId, setUniqueId] = useState(0)

  let url = config.api.baseUrl + '/' + subModule

  const formDataOnLoad = datMgmtFields[module][subModule]
  if (formDataOnLoad?.apiUrlRepelaceUnderscore === true) {
    url = url.replaceAll('_', '-')
  }

  useEffect(() => {
    document.body.setAttribute('data-class', 'data-mgmt')
    return () => {
      document.body.removeAttribute('data-class')
    }
  }, [])

  const formFields = useMemo(() => {
    if (!isEmpty(lookUpData)) {
      const formData = datMgmtFields[module][subModule]

      formData.fields.forEach((field) => {
        if (field.loadLookUp && field.loadLookUp === 'zoneCodes') {
          getLookUpOptions(lookUpData, field, 'zoneCode', 'zoneCode')
        }
        if (field.loadLookUp && field.loadLookUp === 'areaCodes') {
          getLookUpOptions(lookUpData, field, 'areaCode', 'areaCode')
        }
        if (field.loadLookUp && field.loadLookUp === 'postCodes') {
          getLookUpOptions(lookUpData, field, 'postCode', 'postCode')
        }
        if (field.loadLookUp && field.loadLookUp === 'wharfJobTypes') {
          getLookUpOptions(lookUpData, field, 'id', 'description')
        }
        if (field.loadLookUp && field.loadLookUp === 'shipmentTypes') {
          getLookUpOptions(lookUpData, field, 'shipmentType', 'description')
        }
        if (field.loadLookUp && field.loadLookUp === 'countryCodes') {
          getLookUpOptions(lookUpData, field, 'countryCode', 'description')
        }
        if (field.loadLookUp && field.loadLookUp === 'rateGroups') {
          getLookUpOptions(lookUpData, field, 'rateGroupCode', 'description')
        }
        if (field.loadLookUp && field.loadLookUp === 'rateCodes') {
          getLookUpOptions(lookUpData, field, 'rateCode', 'description')
        }
        if (field.loadLookUp && field.loadLookUp === 'specialServices') {
          getLookUpOptions(lookUpData, field, 'id', 'description')
        }
        if (field?.loadLookUp === 'rateRuleEnums') {
          field.options = lookUpData['rateRuleEnums'].map((rateRule) => ({
            label: rateRule,
            id: rateRule,
          }))
        }
      })
      return formData
    }
  }, [module, subModule, lookUpData])

  const setHeadersFromKeys = (keys) => {
    const headers = []
    keys.forEach((key) => {
      let hdrObj = {
        header: CONSTANTS[key] ?? key,
        accessorKey: key,
        className: key,
        id: key,
      }

      if (key === 'editedDate') {
        hdrObj.Cell = ({ value }) => {
          return format(new Date(value), 'dd/MM/yy')
        }
      }
      headers.push(hdrObj)
    })
    return headers
  }

  const transFormTableData = (data) => {
    let keys = Object.keys(data[0])

    let metaDataKeys = [
      'createdBy',
      'createdDate',
      'lastModifiedBy',
      'lastModifiedDate',
    ]
    /**
     * Removing this keys because they are in random order in our data object
     * and they should be the last cols in our Table
     */
    remove(keys, (key) => metaDataKeys.includes(key))

    let headers = setHeadersFromKeys([...keys, ...metaDataKeys])

    // Adding metadata back to keys at end
    keys = [...keys, ...metaDataKeys]

    headers.unshift({
      width: 200,
      header: 'Actions',
      accessorKey: 'name',
      id: 'actions',
      cell: ({ row }) => {
        const { id } = row.original
        return (
          <span style={{ minWidth: '120px', display: 'block' }}>
            <button
              value={id}
              type="button"
              title="Edit"
              style={{
                backgroundColor: 'transparent',
                border: 'none',
              }}
              onClick={editTable.bind(
                row.original[uniqueReferenceKey],
                row.original[uniqueReferenceKey]
              )}
            >
              <img
                style={{ width: '20px' }}
                src="/assets/Edit.png"
                alt="View"
              />
            </button>
            <button
              value={id}
              type="button"
              title="Delete"
              style={{
                backgroundColor: 'transparent',
                border: 'none',
              }}
              onClick={deleteRow.bind(
                row.original[uniqueReferenceKey],
                row.original[uniqueReferenceKey]
              )}
            >
              <img
                style={{ width: '20px' }}
                src="/assets/Delete.png"
                alt="Delete"
              />
            </button>
          </span>
        )
      },
    })

    setTableHeaderData(headers)
    let newData = []
    data.forEach((eachObj) => {
      let newObj = {}
      keys.forEach((each) => {
        if (eachObj[each] === true) {
          newObj[each] = 'YES'
        } else if (eachObj[each] === false) {
          newObj[each] = 'NO'
        } else {
          newObj[each] = eachObj[each]
        }
        newObj.class = 'each'
      })

      newData.push(newObj)
    })

    setTableBodyData(newData)
  }

  useEffect(() => {
    if (!isEmpty(lookUpData)) {
      const { name, apiUrlAppend } = datMgmtFields[module][subModule]

      const requestOptions = {
        method: 'GET',
        headers: { Authorization: 'Bearer ' + localStorage.token },
      }

      if (!isNil(lookupKeyName)) {
        let data = lookUpData[lookupKeyName]
        if (!isEmpty(data)) transFormTableData(data)
        return
      }

      let _url = apiUrlAppend === false ? url : `${url}s`

      fetch(_url, requestOptions)
        .then((response) => response.json())
        .then((data) => {
          if (!isEmpty(data)) transFormTableData(data)
          if (isEmpty(data)) Toastify.Error(`No Data found for ${name}`)
        })
        .catch((error) => {
          Toastify.Error(`Some error occured while fetching data for ${name}`)
          throw new Error(error.message)
        })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formName, formFields, uniqueId, lookUpData])

  const editTable = (id) => {
    setEnableSpinner(true)
    setSubmitStatus(null)
    setErrorReason([])
    setFormId(id)
    const requestOptions = {
      method: 'GET',
      headers: { Authorization: 'Bearer ' + localStorage.token },
    }

    fetch(url + '/' + id, requestOptions)
      .then((response) => response.json())
      .then((data) => {
        setDefaultData(data)
        setShowModal(true)
        setEnableSpinner(false)
      })
      .catch(() => {
        setEnableSpinner(false)
        setDefaultData({})
      })
  }

  const onSubmit = (data, event) => {
    const formType = event.target.getAttribute('data-form-type')
    let submissionType = 'POST'
    let payload = {
      ...data,
      ...(data.rateRules && {
        rateRules: data.rateRules.map((rateRule) => rateRule.id),
      }),
    }
    setErrorReason([])

    const userDetails = JSON.parse(localStorage.data).userDetails

    if (subModule === 'stageMovementType') {
      payload.editedBy = userDetails.userName
      payload.editedDate = new Date().toISOString()
    }

    if (subModule === 'areaCode') {
      payload.modifiedBy = userDetails.userName
    }

    if (subModule === 'jobWindow') {
      payload.startTime = payload.startTime + ':00'
      payload.endTime = payload.endTime + ':00'

      if (payload.startTime > payload.endTime) {
        event.target.querySelector('#endTime').classList.add('is-invalid')
        return false
      }
    }

    if (formType === 'EDIT') {
      submissionType = 'PUT'
      data[uniqueReferenceKey] = formId
    } else {
      payload = [payload]
    }

    setEnableSpinner(true)
    axios({
      method: submissionType, //you can set what request you want to be
      url: url,
      data: payload,
      headers: {
        Authorization: 'Bearer ' + localStorage.token,
      },
    })
      .then(function () {
        setSubmitStatus(formType === 'EDIT' ? 'updated' : 'success')
        toast.success(formType === 'EDIT' ? 'updated' : 'success')
        setEnableSpinner(false)
        setUniqueId(Math.random())
        dispatch(setLookupDataInRdxState())
      })
      .catch(function (error) {
        if (error.response && error.response.data && error.response.data.data) {
          let errData = error.response.data.data
          setErrorReason(errData)
        }
        toast.success(
          error?.message || (formType === 'EDIT' ? 'errorInEdit' : 'error')
        )
        setSubmitStatus(formType === 'EDIT' ? 'errorInEdit' : 'error')
        setEnableSpinner(false)
      })
  }

  const ErrorsList = () => {
    return (
      <div>
        <h6 className="text-danger">Error Reasons</h6>
        <ul className="text-danger">
          {errorReason.map((item, i) => (
            <li key={i}>
              {item.key} - {item.message}
            </li>
          ))}
        </ul>
      </div>
    )
  }

  const deleteRow = (id) => {
    const answer = window.confirm('Are you sure to delete?')
    if (!answer) {
      return false
    }
    setEnableSpinner(true)
    const requestOptions = {
      method: 'DELETE',
      headers: { Authorization: 'Bearer ' + localStorage.token },
    }

    fetch(url + '/' + id, requestOptions)
      .then((response) => response.json())
      .then((data) => {
        setDefaultData(data)
        setEnableSpinner(false)
        setUniqueId(Math.random())
        alert(id + ' has deleted successfully')
        dispatch(setLookupDataInRdxState())
      })
      .catch(() => {
        setDefaultData({})
        setEnableSpinner(false)
        alert('Unable to delete the. Please try after some time!')
      })
  }

  return (
    <>
      <div className="data-mgmt-wpr container-fluid mt-3">
        <div className="row">
          <div className="col-md-10">
            <div className="card mb-4 white-bg-card">
              <div className="card-body">
                <h3 className="mb-4">{formFields?.name}</h3>
                {formFields?.fields.length > 0 && (
                  <Form
                    onSubmit={onSubmit}
                    formData={formFields?.fields}
                    columns={formFields?.columns}
                    uniqueReferenceKey={uniqueReferenceKey}
                  >
                    <div className="col-12">
                      <button className="btn btn-primary mb-4" type="submit">
                        Add
                      </button>
                      <button
                        className="btn btn-primary ms-4 mb-4"
                        type="reset"
                      >
                        Reset
                      </button>
                      {submitStatus === 'success' && (
                        <p className="text-success">Submitted successfully</p>
                      )}
                      {submitStatus === 'error' && (
                        <p className="text-danger">
                          Unable to submit please try again!
                        </p>
                      )}

                      {errorReason.length > 0 && <ErrorsList />}
                    </div>
                  </Form>
                )}
              </div>
            </div>

            <div className="card mb-xl white-bg-card">
              <div className="card-body">
                {tableBodyData.length > 0 ? (
                  <Table columns={tableHeaderData} data={tableBodyData} />
                ) : (
                  <p>No Data to display!</p>
                )}
              </div>
            </div>
          </div>
          <div className="col-md-2">
            <Accordion alwaysOpen>
              <Accordion.Item eventKey="0">
                <Accordion.Header>User Data</Accordion.Header>
                <Accordion.Body>
                  <ol>
                    {userDataMgmt.map((eachItem, index) => (
                      <li key={index}>
                        <Link to={eachItem.path}>
                          {eachItem.staticProps.formName}
                        </Link>
                      </li>
                    ))}
                  </ol>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="1">
                <Accordion.Header>Customer Data Management</Accordion.Header>
                <Accordion.Body>
                  <ol>
                    {customerDataMgmt.map((eachItem, index) => (
                      <li key={index}>
                        <Link to={eachItem.path}>
                          {eachItem.staticProps.formName}
                        </Link>
                      </li>
                    ))}
                  </ol>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="2">
                <Accordion.Header>Operations Data</Accordion.Header>
                <Accordion.Body>
                  <ol>
                    {operationsDataMgmt.map((eachItem, index) => (
                      <li key={index}>
                        <Link to={eachItem.path}>
                          {eachItem.staticProps.formName}
                        </Link>
                      </li>
                    ))}
                  </ol>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="3">
                <Accordion.Header>Transport Data</Accordion.Header>
                <Accordion.Body>
                  <ol>
                    {transportDataMgmt.map((eachItem, index) => (
                      <li key={index}>
                        <Link to={eachItem.path}>
                          {eachItem.staticProps.formName}
                        </Link>
                      </li>
                    ))}
                  </ol>
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          </div>
        </div>
        <Modal
          show={showModal}
          onHide={() => setShowModal(false)}
          size="lg"
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title>
              Edit {formFields?.name} - {formId}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {formFields?.fields.length > 0 && (
              <Form
                onSubmit={onSubmit}
                reference="editForm"
                formData={formFields?.fields}
                defaultValues={defaultData}
                uniqueReferenceKey={uniqueReferenceKey}
              >
                <div className="col-12">
                  <input type="hidden" id="formId" value={formId} />
                  <button className="btn btn-primary mb-4" type="submit">
                    Update
                  </button>
                  <button className="btn btn-primary ms-4 mb-4" type="reset">
                    Reset
                  </button>

                  {submitStatus === 'updated' && (
                    <p className="text-success">Updated successfully</p>
                  )}
                  {submitStatus === 'errorInEdit' && (
                    <p className="text-danger">
                      Unable to update please try again!
                    </p>
                  )}
                </div>
              </Form>
            )}
          </Modal.Body>
        </Modal>

        {enableSpinner && <Spinner />}
      </div>
    </>
  )
}
