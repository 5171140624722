import startCase from 'lodash/startCase'
import React from 'react'
import { Badge, Button, Col, Row, Spinner } from 'react-bootstrap'
import Input from '../../../../components/Common/Form/Fields/Input'
import Select from '../../../../components/Common/Form/Fields/Select'
import FormErrorText from '../../../../components/Common/Form/FormErrorText'
import {
  useMasterTemplate,
  useSaveTemplateMutation,
} from '../../../../helper/hooks/api/allocations'
import { templateFormDefaultValues } from '../UserTemplates'
import AddChannelModal from './AddChannelModal/AddChannelModal'
import CopyTemplateField from './CopyTemplateField'

export default function TemplateForm({ form }) {
  const { isLoading: isLoadingMasterTemplate, data: masterData } =
    useMasterTemplate()

  const saveTemplateMutation = useSaveTemplateMutation()

  const {
    register,
    formState: { errors },
  } = form

  const selectedFields = form.watch('selectedFields')

  const editingTemplateId = form.watch('editingTemplateId')

  const handleSubmit = (v) => {
    saveTemplateMutation.mutate(v, {
      onSuccess: () => {
        form.reset(templateFormDefaultValues)
      },
    })
  }

  return (
    <form onSubmit={form.handleSubmit(handleSubmit)}>
      <Row>
        {isLoadingMasterTemplate && <Spinner />}
        <Select
          md={8}
          register={register}
          errors={errors}
          name="selectedChannel"
          options={masterData?.masterTemplateFunctions}
          optionValueKey="id"
          label="Select your channel"
          optionLabelKey="channelName"
        />
        <Col className="d-flex mb-3">
          <AddChannelModal />
        </Col>
        <CopyTemplateField form={form} />
        <Input md={8} register={register} errors={errors} name="templateName" />
        <FormErrorText errors={errors} name="selectedFields" />
      </Row>
      <div className="d-flex flex-wrap gap-1">
        {selectedFields.map((f) => (
          <Badge bg="info" text="dark">
            {startCase(f.fieldName)}
          </Badge>
        ))}
      </div>
      {Boolean(editingTemplateId) && (
        <Button
          className="mt-3 me-3"
          type="button"
          variant="secondary"
          onClick={() => form.reset(templateFormDefaultValues)}
        >
          Cancel
        </Button>
      )}
      <Button
        type="submit"
        style={{ width: '12rem' }}
        className="mt-3 justify-content-center"
        disabled={saveTemplateMutation.isPending}
      >
        {saveTemplateMutation.isPending && <Spinner />}
        {editingTemplateId ? 'Update' : 'Create'}
      </Button>
    </form>
  )
}
