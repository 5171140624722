import {
  keepPreviousData,
  useMutation,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query'
import { useParams } from 'react-router-dom'
import { getShortDate } from '../../../components/utils/DateFun'
import * as Toastify from '../../../components/utils/Toastify'
import fetchService from '../../../components/utils/fetchService'
import getValidValues from '../../../components/utils/getValidValues'
import config from '../../../config'

export const cerebroQueryKeys = {
  portSlot: (form) => ['portSlot', 'list', form],
  orders: {
    getEdoOrders: ['edo-orders'],
    getEdoOrdersFilters: (form) => ['edo-orders', form],
    getSpecialServiceYards: (id) => ['special-service', 'yards', id],
  },
}

export const useGetSpecialServiceYards = (serviceId) => {
  const url = `/service-yard/special-service/${serviceId}`

  return useQuery({
    queryKey: cerebroQueryKeys.orders.getSpecialServiceYards(serviceId),
    queryFn: async () => await fetchService({ url }),
    enabled: !!serviceId,
  })
}

export const useSearchPort = (formValues) => {
  const validFormValues = getValidValues(formValues)

  return useQuery({
    queryKey: cerebroQueryKeys.portSlot(validFormValues),
    queryFn: async () =>
      await fetchService({
        url: config.api.getAllPortSlotDetails,
        body: validFormValues,
        method: 'POST',
      }),
    placeholderData: keepPreviousData,
  })
}

export const useVesselsVoyageNumbers = (wharfJobType) => {
  return useQuery({
    queryKey: ['/operations/order/customer/vessel-voyagenos', wharfJobType],
    queryFn: async () =>
      await fetchService({
        url: `/operations/order/customer/vessel-voyagenos?wharfJobType=${wharfJobType}`,
      }),
    enabled: !!wharfJobType,
  })
}

export const useSaveUserDetailsMutation = () => {
  const url = config.api.createUser
  const { id } = useParams()
  const queryClient = useQueryClient()

  const type = !!id ? 'PUT' : 'POST'

  return useMutation({
    mutationFn: async (body) => {
      return await fetchService({ url, method: type, body })
    },
    onSuccess: async (data) => {
      alert(
        type !== 'PUT'
          ? 'User has been created'
          : 'User details have been modified successfully'
      )
    },
    onError: async (error) => {
      alert(
        type === 'POST'
          ? 'Unable to create the user. Please try again later!'
          : 'Unable to update the user. Please try again later.'
      )
    },
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: ['users'],
      })
    },
  })
}

export const useGetEdoOrders = (formValues) => {
  const validFormValues = getValidValues(formValues)
  if (validFormValues?.fromDate)
    validFormValues.fromDate = getShortDate(validFormValues.fromDate)
  if (validFormValues?.toDate)
    validFormValues.toDate = getShortDate(validFormValues.toDate)

  return useQuery({
    queryKey: cerebroQueryKeys.orders.getEdoOrdersFilters(validFormValues),
    queryFn: async () =>
      await fetchService({
        url: '/operations/order/customer/dashboard/edo',
        method: 'POST',
        body: validFormValues,
      }),
    placeholderData: keepPreviousData,
  })
}

export const useUploadOrderEdoMutation = () => {
  const url = '/operations/order/document/upload'

  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: async (body) =>
      await fetchService({
        url,
        method: 'POST',
        isFile: true,
        removeContentType: true,
        body,
        returnRaw: true,
      }),
    onSuccess: async (response) => {
      Toastify.Success(response)
      await queryClient.invalidateQueries({
        queryKey: cerebroQueryKeys.orders.getEdoOrders,
      })
    },
    onError: (error) => {
      console.log('🚀 ~ useUploadOrderEdoMutation ~ error:', error)
    },
  })
}
