import isEmpty from 'lodash/isEmpty'
import React, { useEffect } from 'react'
import { Spinner } from 'react-bootstrap'
import Table from '../../../../components/Common/Tanstack-table/Table'
import ContainerWithHeading from '../../../../components/styles/Containers/ContainerWithHeading'
import { useJobsByDriver } from '../../../../helper/hooks/api/allocations'
import jobContainerColumns from '../data/jobContainerColumns'
import JobNotesModal from '../modals/JobNotesModal'
import { useDispatch, useSelector } from 'react-redux'
import {
  selectSelectedDriver,
  selectSelectedJob,
  setSelectedJob,
} from '../../../../store/slices/allocation/drivers'

const JobNumber = ({ job }) => {
  const dispatch = useDispatch()

  const selectedJob = useSelector(selectSelectedJob)

  const handleClick = () => {
    dispatch(setSelectedJob(job))
  }

  const isSelected = selectedJob?.jobNumber === job.jobNumber

  return (
    <h5
      style={
        isSelected
          ? {
              textDecoration: 'underline',
              color: 'var(--bs-yellow)',
            }
          : {}
      }
      className="cursor-pointer m-0"
      onClick={handleClick}
    >
      {job.jobNumber}
    </h5>
  )
}

export default function DriversJobsList() {
  const selectedDriver = useSelector(selectSelectedDriver)
  const selectedJob = useSelector(selectSelectedJob)
  const dispatch = useDispatch()

  const {
    data: response,
    isLoading,
    error,
  } = useJobsByDriver(selectedDriver?.driverCardId)

  /**
   * When selected driver changes response changes which triggers this hook
   * When setSelectedDriver action is called selectedJob is set to null
   * And we check if selectedJob is a falsy value and then set the first element from the data as the default selected job
   */
  useEffect(() => {
    if (response?.data?.length && !selectedJob && !error) {
      dispatch(setSelectedJob(response.data[0]))
    } else if (!response?.data || response?.data?.length === 0) {
      dispatch(setSelectedJob(null))
    }
  }, [dispatch, error, response, selectedJob])

  const jobs = response?.data

  return (
    <div>
      {isLoading && <Spinner />}
      {error && (
        <p className="text-danger">
          Error occurred while fetching jobs by driver
        </p>
      )}
      {jobs?.length === 0 && <p className="text-danger">No Jobs found!</p>}
      {Array.isArray(jobs) && jobs?.length > 0 && (
        <ContainerWithHeading
          heading="Jobs List"
          containerClassNames="px-0 pb-3"
          containerProps={{
            style: {
              maxHeight: 'calc(45vh - 130px)',
              overflowY: 'scroll',
            },
          }}
        >
          {jobs?.map((job, index) => (
            <div>
              <div className="p-2">
                <div className="d-flex align-items-center justify-content-between my-2">
                  <JobNumber job={job} />
                  <JobNotesModal job={job} />
                </div>
                {!isEmpty(job.containerLoads) && (
                  <Table
                    inheritTableWidth
                    columns={jobContainerColumns}
                    data={Object.values(job.containerLoads)}
                    showTableBottom={false}
                    showGlobalSearch={false}
                    dontShowFilters={true}
                  />
                )}
              </div>
              {jobs.length - 1 !== index && <hr />}
            </div>
          ))}
        </ContainerWithHeading>
      )}
    </div>
  )
}
