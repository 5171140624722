import React, { useState } from 'react'
import { Button, Offcanvas, Spinner } from 'react-bootstrap'
import Select from '../../../../../components/Common/Form/Fields/Select'
import Input from '../../../../../components/Common/Form/Fields/Input'
import { getLookupDataState } from '../../../../../store/slices/lookup-slice'
import { useSelector } from 'react-redux'
import InputNumber from '../../../../../components/Common/Form/Fields/InputNumber'
import useGetVesselAndVoyageOptions from '../../../hooks/useGetVesselAndVoyageOptions'
import SearchableSelectField from '../../../../../components/Common/Form/Fields/SearchableSelectField'

export default function EdoOrdersListFiltersForm({ form }) {
  const lookupsList = useSelector(getLookupDataState)

  const {
    register,
    formState: { errors },
    reset,
    control,
    setValue,
  } = form

  const { vesselIdOptions, voyageOptions, isLoadingVoyageNumbers } =
    useGetVesselAndVoyageOptions(form, 'wharfJobType', 'voyageNo')

  const [show, setShow] = useState(false)

  const handleClose = () => setShow(false)
  const toggleShow = () => setShow((s) => !s)

  return (
    <div className="d-flex">
      <Button
        variant="primary"
        onClick={toggleShow}
        style={{ marginBottom: '-50px' }}
        className="me-2 ms-auto"
      >
        Filters
      </Button>
      <Offcanvas show={show} onHide={handleClose} scroll placement="end">
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>Filters</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <div className="row">
            <Input
              md={12}
              name="containerNo"
              register={register}
              errors={errors}
            />
            <Input
              md={12}
              name="fromDate"
              type="date"
              register={register}
              errors={errors}
            />
            <Input
              md={12}
              name="toDate"
              type="date"
              register={register}
              errors={errors}
            />
            <InputNumber
              name="grossWeight"
              md={12}
              register={register}
              errors={errors}
            />
            <Input
              md={12}
              name="mtDehireCode"
              register={register}
              errors={errors}
            />
            <Input md={12} name="sealNo" register={register} errors={errors} />
            <Input
              md={12}
              name="sizeType"
              register={register}
              errors={errors}
            />
            <Select
              name="wharfJobType"
              label="Job Type"
              register={register}
              errors={errors}
              options={lookupsList?.wharfJobTypes}
              optionValueKey="id"
              md={12}
            />
            {isLoadingVoyageNumbers && <Spinner />}
            {!isLoadingVoyageNumbers && Array.isArray(voyageOptions) && (
              <SearchableSelectField
                name="voyageNo"
                control={control}
                dependentFieldIds={['vesselId']}
                setValue={setValue}
                md={12}
                options={voyageOptions}
              />
            )}
            <Select
              name="vesselId"
              register={register}
              errors={errors}
              options={vesselIdOptions}
              md={12}
            />
          </div>
          <Button variant="secondary" className="mb-2" onClick={() => reset()}>
            Reset
          </Button>
        </Offcanvas.Body>
      </Offcanvas>
    </div>
  )
}
