import CapacityPlanner from '../modules/cerebro/capacity-planner/Planner/CapacityPlanner'
import DefaultPlanner from '../modules/cerebro/capacity-planner/default-planner/DefaultPlanner'

const routes = [
  {
    name: 'invoice',
    path: '/capacity-planner/dashboard',
    component: CapacityPlanner,
    staticProps: {},
  },
  {
    name: 'invoice',
    path: '/capacity-planner/default-planner',
    component: DefaultPlanner,
    staticProps: {},
  },
]
export default routes
