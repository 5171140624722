import { yupResolver } from '@hookform/resolvers/yup'
import { useQueryClient } from '@tanstack/react-query'
import axios from 'axios'
import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import { useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import * as Yup from 'yup'
import * as DateFun from '../../../../../../components/utils/DateFun'
import { TextBox } from '../../../../../../components/utils/TextBox'
import * as Toastify from '../../../../../../components/utils/Toastify'
import config from '../../../../../../config'
import useModalContainer from '../../../../../../helper/hooks/useModalContainer'
import { getLookupDataState } from '../../../../../../store/slices/lookup-slice'
import { queryKeys } from '../../../../../../helper/hooks/apiHooks'

const PortSlot = ({
  orderContainerDetailsId,
  btnProps,
  btnIcon,
  defaultWharfCode,
}) => {
  const queryClient = useQueryClient()
  const lookupsList = useSelector(getLookupDataState)
  const validationSchema = Yup.object().shape({
    wharfName: Yup.string().required('Wharf Name Type is required'),
    portReference: Yup.string().required('Port Reference is required'),
    portSlotDate: Yup.string().required('Port Slot Date is required'),
    portSlotTime: Yup.string().required('Port Slot Time is required'),
    plannerRemarks: Yup.string().nullable().optional(),
  })

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    reset,
  } = useForm({
    resolver: yupResolver(validationSchema),
    reValidateMode: 'onChange',
  })

  const [portSlotData, setPortSlotData] = useState([])

  const setPortSlotDataOnEdit = (portSlot) => {
    setValue('portReference', portSlot.portReference)
    setValue(
      'portSlotDate',
      DateFun.getShortDate(portSlot.portSlotDate ?? new Date().toDateString())
    )
    setValue('portSlotTime', DateFun.getShortTime(portSlot.portSlotTime))
    setValue('plannerRemarks', portSlot?.plannerRemarks)
    if (!portSlot.wharfName) setValue('wharfName', defaultWharfCode)
    else setValue('wharfName', portSlot.wharfName)

    setPortSlotData(portSlot)
  }

  const getPortSlotData = (containerOrderId) => {
    const url =
      config.api.baseUrl +
      '/operations/order/container/port-slots?orderContainerDetailsId=' +
      containerOrderId
    const requestOptions = {
      method: 'GET',
      headers: { Authorization: 'Bearer ' + localStorage.token },
    }
    fetch(url, requestOptions)
      .then((response) => response.json())
      .then((data) => {
        setPortSlotDataOnEdit(data)
      })
      .catch((error) => {
        Toastify.Error(error?.response?.data?.error)
      })
  }

  const submitPortSlot = (formData) => {
    let options = {
      // orderContainerDetailsId: 4,
      orderContainerDetailsId: orderContainerDetailsId,
      orderContainerPortSlotId:
        portSlotData && portSlotData.orderContainerPortSlotId,
      portReference: formData.portReference,
      portSlotDate: DateFun.getShortDate(formData.portSlotDate),
      portSlotTime: DateFun.getLongTime(formData.portSlotTime),
      plannerRemarks: formData?.plannerRemarks,
      wharfName: formData.wharfName,
    }

    let requestType = portSlotData?.orderContainerPortSlotId ? 'PUT' : 'POST'
    let requestUrl = portSlotData?.orderContainerPortSlotId
      ? '/operations/order/container/port-slots/' +
        portSlotData.orderContainerPortSlotId
      : '/operations/order/container/port-slots'

    axios({
      method: requestType, //you can set what request you want to be
      url: config.api.baseUrl + requestUrl,
      data: options,
      headers: {
        Authorization: 'Bearer ' + localStorage.token,
      },
    })
      .then(async () => {
        await queryClient.invalidateQueries({
          queryKey: ['portSlot', 'list'],
        })
        await queryClient.invalidateQueries({
          queryKey: queryKeys.cerebro.order.container.stageTracker(
            orderContainerDetailsId
          ),
        })
        Toastify.Success('Port slot submitted successfully..!')
        setShow(false)
      })
      .catch(function (error) {
        Toastify.Error(error?.response?.data?.error)
      })
  }

  const handleSubmitWithoutPropagation = (e) => {
    e.preventDefault()
    e.stopPropagation()
    handleSubmit(submitPortSlot)(e)
  }

  const { ModalContainer, setShow } = useModalContainer({
    text: (
      <button
        type="button"
        className="unset-all d-flex flex-column align-items-center"
        onClick={(e) => {
          e.preventDefault()
          if (orderContainerDetailsId > 0) {
            getPortSlotData(orderContainerDetailsId)
            setShow(true)
          } else toast.error('Order Container Details Id not found!')
        }}
        {...btnProps}
      >
        {btnIcon ?? (
          <>
            <img
              title="Port Slot"
              src="./assets/customer-order/port-slot.png"
              alt="PortSlot"
              width="40px"
              height="40px"
              className="img"
            />
            Port Slot
          </>
        )}
      </button>
    ),
    header: 'Port Slot',
    body: (
      <form onSubmit={handleSubmitWithoutPropagation}>
        <div>
          <div className="row">
            <div className="col-md-1" />
            <div className="col-md-3">
              <h5>Wharf Name :</h5>
            </div>
            <div className="col-md-4 mb-4">
              <select
                className={`form-select ${
                  errors?.wharfName ? 'is-invalid' : ''
                }`}
                id="wharfName"
                name="wharfName"
                {...register('wharfName')}
              >
                <option value="">Please Select</option>
                {lookupsList?.wharfCodes.map((item) => (
                  <option key={item.wharfCode} value={item.wharfCode}>
                    {item.description}
                  </option>
                ))}
              </select>
              {errors?.wharfName && (
                <p className="invalid-feedback">{errors?.wharfName?.message}</p>
              )}
            </div>
          </div>

          <div className="row">
            <div className="col-md-1" />
            <div className="col-md-3">
              <h5>Port Reference :</h5>
            </div>
            <div className="col-md-4 mb-4">
              <TextBox
                register={register}
                id="portReference"
                errors={errors}
                value=""
                placeholder="Port Reference"
                maxLength="10"
              />
            </div>
          </div>

          <div className="row">
            <div className="col-md-1" />
            <div className="col-md-3">
              <h5>Port Slot Date :</h5>
            </div>
            <div className="col-md-4 mb-4">
              <input
                className={`form-control ${
                  errors?.portSlotDate ? 'is-invalid' : ''
                }`}
                id="portSlotDate"
                placeholder=""
                name="Customer Since Date"
                type="date"
                {...register('portSlotDate')}
              />
              {errors?.portSlotDate && (
                <p className="invalid-feedback">
                  {errors?.portSlotDate?.message}
                </p>
              )}
            </div>
          </div>

          <div className="row">
            <div className="col-md-1" />
            <div className="col-md-3">
              <h5>Port Slot Time :</h5>
            </div>
            <div className="col-md-4 mb-4">
              <input
                className="form-control "
                id="portSlotTime"
                name="portSlotTime"
                type="time"
                {...register('portSlotTime')}
              />
            </div>
          </div>

          <div className="row">
            <div className="col-md-1" />
            <div className="col-md-3">
              <h5>Planner Remarks :</h5>
            </div>
            <div className="col-md-4 mb-4">
              <TextBox
                register={register}
                id="plannerRemarks"
                errors={errors}
                placeholder="Planner Remarks"
                maxLength="100"
              />
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-md-1" />
          <div className="col-7 text-right">
            <button className="btn btn-primary float-end ms-3" type="submit">
              Save
            </button>
            <button
              onClick={() =>
                reset({
                  plannerRemarks: '',
                  portReference: '',
                  portSlotDate: '',
                  portSlotTime: '',
                })
              }
              className="btn btn-secondary float-end"
              type="button"
            >
              Reset
            </button>
          </div>
        </div>
      </form>
    ),
  })

  return ModalContainer
}

export default PortSlot
