import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import dayjs from 'dayjs'
import startCase from 'lodash/startCase'
import { useState } from 'react'
import { toast } from 'react-toastify'
import useLoading from '../../../components/Clm/hooks/useLoading'
import dateFunctions from '../../../components/utils/DateFun'
import * as Toastify from '../../../components/utils/Toastify'
import fetchService from '../../../components/utils/fetchService'
import config from '../../../config'
import { Device } from '@twilio/voice-sdk'
import { error } from 'jquery'
// import TWILIO from '../../../constants/twilio'

export const allocationKeys = {
  masterTemplate: ['templates', 'master-template'],
  userTemplates: ['templates', 'user-template'],
  jobsByDriver: (driverCardId) => ['jobs-by-driver', driverCardId],
  twilio: {
    device: ['twilio-device'],
    token: ['twilio-token'],
    // connection: ['twilio-device', 'connection'],
    // state: ['twilio-device', 'state'],
  },
}

export const useCreateJobConversationMutation = () => {
  const { userDetails } = JSON.parse(localStorage.data)

  return useMutation({
    mutationFn: async (jobNo) => {
      const response = await fetchService({
        url: `${config.api.enigmaChat.getCerebroJobConversations}?jobNumber=${jobNo}`,
      })

      if (response?.data?.length > 0) {
        const message = 'Conversation already exists'
        Toastify.Error(message)
        throw new Error(message)
      }

      return await fetchService({
        url: `${config.api.enigmaChat.createJobConversation}?senderAdminId=${userDetails.id}&jobNumber=${jobNo}`,
      })
    },
    onSuccess: () => {
      Toastify.Success('Conversation Created Successfully')
    },
    onError: (response) => {
      if (response?.error) {
        response?.data?.forEach?.((error) => {
          Toastify.Error(error?.message)
        })
        throw new Error(response.error)
      }
      console.log(error)
    },
  })
}

const TWILIO_BASE_URL = 'https://twilio-dev.swifttrackmile.codes'

export const useTwilioDevice = (identity = 'swift') => {
  // const queryClient = useQueryClient()

  const url = `${TWILIO_BASE_URL}/voice/token?identity=${encodeURIComponent(
    identity
  )}`

  // const setConnection = (connection) => {
  //   queryClient.setQueryData(allocationKeys.twilio.connection, connection)
  // }

  // const setDeviceState = (state) => {
  //   queryClient.setQueryData(allocationKeys.twilio.state, state)
  // }

  return useQuery({
    queryKey: allocationKeys.twilio.device,
    queryFn: async () => {
      const response = await fetch(url).then((r) => r.json())
      const token = response?.token
      const device = new Device(token)

      // device.on('error', (twilioError, call) => {
      //   console.log('An error has occurred: ', twilioError)
      // })

      // device.on('ready', () => {
      //   console.log('🚀 ~ device.on ~ ready:', true)
      //   setDeviceState(TWILIO.states.READY)
      // })
      // device.on('connect', (connection) => {
      //   console.log("🚀 ~ device.on ~ connect:", connection)
      //   setConnection(connection)
      //   setDeviceState(TWILIO.states.ON_CALL)
      // })
      // device.on('disconnect', () => {
      //   setDeviceState(TWILIO.states.READY)
      //   setConnection(null)
      // })
      // device.on('incoming', (connection) => {
      //   setDeviceState(TWILIO.states.INCOMING)
      //   setConnection(connection)

      //   connection.on('reject', () => {
      //     setDeviceState(TWILIO.states.READY)
      //     setConnection(null)
      //   })
      // })
      // device.on('cancel', () => {
      //   setDeviceState(TWILIO.states.READY)
      //   setConnection(null)
      // })
      // device.on('reject', () => {
      //   setDeviceState(TWILIO.states.READY)
      //   setConnection(null)
      // })

      return device
    },
  })
}

// export const useGetTwilioDeviceState = () => {
//   const queryClient = useQueryClient()

//   return queryClient.getQueryData(allocationKeys.twilio.state)
// }

// export const useGetTwilioDeviceConnection = () => {
//   const queryClient = useQueryClient()

//   return queryClient.getQueryData(allocationKeys.twilio.connection)
// }

export const useDeleteDriverMutation = () => {
  const queryClient = useQueryClient()
  const url = '/transports/driver/card'

  return useMutation({
    mutationFn: async (id) =>
      await fetchService({
        url: `${url}/${id}`,
        method: 'DELETE',
        returnRaw: true,
      }),
    onSuccess: async (response) => {
      if (response === 'true') {
        toast.success('Driver deleted successfully.')
        await queryClient.invalidateQueries({
          queryKey: ['drivers-vehicles'],
        })
      }
    },
  })
}

export const useJobsByDriver = (driverCardId) => {
  const url = `${config.api.jobsDetailsDTOByDriverCardId}${driverCardId}`

  return useQuery({
    queryKey: allocationKeys.jobsByDriver(driverCardId),
    queryFn: async () => await fetchService({ url }),
    enabled: !!driverCardId,
  })
}

export const useMasterTemplate = () => {
  const url = `${config.api.getTemplateFunctionByFunctionName}*`

  return useQuery({
    queryKey: allocationKeys.masterTemplate,
    queryFn: async () => await fetchService({ url }),
  })
}

export const useAllChannels = () => {
  return useQuery({
    queryKey: ['masterTemplate-function', '*'],
    queryFn: async () =>
      await fetchService({
        url: `${config.api.getTemplateFunctionByFunctionName}*`,
      }),
  })
}

export const useGetTemplateListByChannelName = (channel) => {
  const userData = JSON.parse(localStorage.data)
  const url = `${config.api.getTemplate}${channel}&userId=${userData.userDetails.id}`

  return useQuery({
    queryKey: [url],
    queryFn: async () => await fetchService({ url }),
    enabled: !!channel,
  })
}

export const useUserTemplateListData = () => {
  const userData = JSON.parse(localStorage.data)
  const url = `${config.api.getUserTemplateList}${userData.userDetails.id}`

  return useQuery({
    queryKey: allocationKeys.userTemplates,
    queryFn: async () => await fetchService({ url }),
    enabled: !!userData?.userDetails?.id,
  })
}

export const useJobsByStatus = (status = '', formValues) => {
  const body = {
    jobDriverStatus: status,
  }

  if (formValues?.searchDate)
    body.searchDate = dayjs(formValues.searchDate).format('YYYY-MM-DD')

  const url = `${config.api.plannedDashboard}jobs-status-criteria`

  return useQuery({
    queryKey: ['allocation-planner', 'jobs-list', body],
    queryFn: async () => await fetchService({ url, method: 'POST', body }),
  })
}

export const useDispatchLoadMutation = () => {
  const { setLoading } = useLoading()
  const queryClient = useQueryClient()
  const url = config.api.loadDispatch

  return useMutation({
    mutationFn: async (jobs) =>
      await fetchService({
        url,
        method: 'POST',
        body: jobs.map((j) => j.containerStageJobDriverId),
      }),
    onMutate: () => setLoading(true),
    onSuccess: async () => {
      toast.success('Successfully dispatched!')
      await queryClient.invalidateQueries({
        queryKey: ['allocation-planner', 'jobs-list'],
      })
    },
    onError: (error) => {
      if (Array.isArray(error?.data)) {
        error.data.forEach(({ message }) => {
          toast.error(message)
        })
      }
    },
    onSettled: () => setLoading(false),
  })
}

export const useUpdateUserDefaultTemplateMutation = () => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: async ({ templateId, makeDefault }) =>
      await fetchService({
        method: 'PUT',
        url: `${config.api.defaultTemplate}/${templateId}?makeDefault=${makeDefault}`,
      }),
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: allocationKeys.userTemplates,
      })
      toast.success('Default Template updated successfully.')
    },
  })
}

export const useSaveTemplateMutation = () => {
  const queryClient = useQueryClient()
  const userDetails = JSON.parse(localStorage.data)

  return useMutation({
    mutationFn: async (values) => {
      const isEditing = Boolean(values?.editingTemplateId)
      const url = isEditing
        ? `${config.api.userTemplateFunction}/${values?.editingTemplateId}`
        : config.api.userTemplateFunction

      const body = {
        templateName: values.templateName,
        userId: userDetails.userDetails.id,
        masterTemplateFunctionId: values.selectedChannel,
        userTemplateFields: values.selectedFields.map((field) => ({
          fieldName: field.fieldName,
          masterTemplateFieldId: field.fieldId,
        })),
      }

      if (
        isEditing &&
        Array.isArray(values?.editingTemplate) &&
        values?.editingTemplate.length
      ) {
        const { editingTemplate } = values
        body.userTemplateFields = values.selectedFields.map((field) => {
          const userTemplateFieldId = editingTemplate.find(
            (f) => f.masterTemplateFieldId === field.fieldId
          )?.userTemplateFieldId

          return {
            fieldName: field.fieldName,
            masterTemplateFieldId: field.fieldId,
            ...(userTemplateFieldId && {
              userTemplateFieldId,
            }),
          }
        })
      }

      return await fetchService({
        url,
        method: isEditing ? 'PUT' : 'POST',
        body,
      })
    },
    onSuccess: async (_, variables) => {
      const isEditing = Boolean(variables?.editingTemplateId)
      await queryClient.invalidateQueries({
        queryKey: allocationKeys.userTemplates,
      })
      toast.success(
        `Template ${isEditing ? 'updated' : 'created'} successfully.`
      )
    },
    onError: (error) => {
      console.log(error)
    },
  })
}

export const useDeleteTemplateMutation = () => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: async ({ templateId }) => {
      const url = `${config.api.userTemplateFunction}/${templateId}`
      return await fetchService({
        method: 'DELETE',
        url,
        returnRaw: true,
      })
    },
    onSuccess: async (response) => {
      if (Boolean(response)) {
        await queryClient.invalidateQueries({
          queryKey: allocationKeys.userTemplates,
        })
        toast.info('Template deleted successfully.')
      }
    },
    onError: (error) => {
      console.error(error)
      toast.error(error?.message)
    },
  })
}

export const useLazyGetTemplateData = () => {
  return useMutation({
    mutationFn: async (templateId) =>
      await fetchService({
        url: `${config.api.userTemplateFunction}/${templateId}`,
      }),
  })
}

export const useSaveChannelMutation = () => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: async (values) => {
      const isEditing = Boolean(values?.id)

      const url = isEditing
        ? `/mastertemplate/function/${values.id}?channelName=${values.channelName}`
        : `${config.api.saveMasterTemplateFunction}${values.channelName}`

      return await fetchService({
        url,
        method: isEditing ? 'PUT' : 'POST',
        body: '',
      })
    },
    onSuccess: async (_, variables) => {
      await queryClient.invalidateQueries({
        queryKey: allocationKeys.masterTemplate,
      })
      const isEditing = Boolean(variables?.id)
      toast.success(
        `Channel ${isEditing ? 'name updated' : 'created'} successfully`
      )
    },
    onError: (error) => {
      if (error?.data?.length) {
        error.data.forEach(({ message }) => {
          Toastify.Error(message)
        })
      } else Error(error.message)
    },
  })
}

export const useDeleteChannelMutation = () => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: async (id) =>
      await fetchService({
        url: `/mastertemplate/function/${id}`,
        method: 'delete',
        returnRaw: true,
      }),
    onSuccess: async (response) => {
      if (Boolean(response)) {
        await queryClient.invalidateQueries({
          queryKey: allocationKeys.masterTemplate,
        })
        toast.info('Channel deleted successfully.')
      }
    },
    onError: (error) => {
      console.error(error)
      if (error?.data?.length) {
        error.data.forEach(({ message }) => {
          Toastify.Error(message)
        })
      } else Error(error.message)
    },
  })
}

export const useGetWebFleetResponseMutation = () => {
  return useMutation({
    mutationFn: async (id) =>
      await fetchService({
        url: `${config.api.getWebfleetResponse}/${id}`,
      }),
  })
}

export const useGetWebFleetResponse = (vehicleTruckId) => {
  return useQuery({
    queryKey: [config.api.getWebfleetResponse, vehicleTruckId],
    queryFn: async () =>
      await fetchService({
        url: `${config.api.getWebfleetResponse}/${vehicleTruckId}`,
      }),
    enabled: !!vehicleTruckId,
  })
}

export const useUpdateStageMovReqDateAndTimeFieldMutation = (data, id) => {
  const queryClient = useQueryClient()

  const url = `/container/stage-tracker/${data?.containerStageTrackerId}`

  const [value, setValue] = useState(data[id])

  const mutation = useMutation({
    mutationFn: async ({ value, type }) =>
      await fetchService({
        url,
        method: 'PUT',
        body: {
          [id]:
            type === 'date'
              ? dayjs(value).format('YYYY-MM-DD')
              : dateFunctions.getLongTime(value),
        },
      }),
    onMutate: ({ value }) => {
      setValue(value)
    },
    onSuccess: async () => {
      try {
        await queryClient.invalidateQueries({
          queryKey: ['allocation-planner', 'unplanned-loads'],
        })
      } catch (err) {
        console.log('🚀 ~ onSuccess: ~ err:', err)
      }
    },
    onError: (error) => {
      console.log('🚀 ~ MovReqDateField ~ error:', error)
      setValue('')
      toast.error(`${startCase(id)} cannot be updated`)
    },
  })

  return { ...mutation, value }
}
