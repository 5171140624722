import React, { useMemo } from 'react'
import { useTable, usePagination, useFilters } from 'react-table'

import TablePagination from './TablePagination'

import './Table.scss'
import { DefaultFilter } from './ColumnFilters'

const Table = ({
  className,
  data,
  columns,
  onRowClick,
  cellConfigs,
  styleConfig,
  isPagination = true,
  paginationSize,
  getTrProps = (props) => props,
}) => {
  data = useMemo(() => data, [data])
  columns = useMemo(
    () =>
      columns?.map((column) => ({
        Header: column.label,
        accessor: column.id,
        Filter: DefaultFilter,
      })),
    [columns]
  )

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    nextPage,
    previousPage,
    gotoPage,
    setPageSize,
    pageCount,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0, pageSize: 200 },
    },
    useFilters,
    usePagination
  )

  const headRow = () => {
    return headerGroups.map((headerGroup) => (
      <tr {...headerGroup.getHeaderGroupProps()}>
        {headerGroup.headers.map((column) => (
          <th
            {...column.getHeaderProps()}
            style={{
              whiteSpace: 'nowrap',
            }}
          >
            {column.render('Header')}
            <div>{column.render('Filter')}</div>
          </th>
        ))}
      </tr>
    ))
  }

  const DefaultStyle = ({ cell }) => (
    <td
      style={{
        whiteSpace: 'nowrap',
        textAlign: cell.value ? 'unset' : 'center',
      }}
      {...cell.getCellProps()}
    >
      {cell.value ? cell.render('Cell') : '-'}
    </td>
  )

  const tableData = () => {
    return page.map((row) => {
      const rowData = { ...row?.original }
      prepareRow(row)
      const rowProps = { ...row.getRowProps(), ...(rowData.props ?? {}) }
      return (
        <tr
          {...rowProps}
          {...getTrProps(row)}
          onClick={() => (onRowClick ? onRowClick(row.original) : '')}
        >
          {row.cells.map((cell) => {
            const dependencies = {}
            cellConfigs?.[cell.column.id]?.forEach(
              (dependency) => (dependencies[dependency] = rowData[dependency])
            )
            const Component = styleConfig?.[cell.column.id] ?? DefaultStyle
            return (
              <Component
                key={`${cell.column.id}-${cell.row.id}`}
                cell={cell}
                dependencies={dependencies}
              />
            )
          })}
        </tr>
      )
    })
  }

  return (
    <>
      <div className="table-responsive">
        <table
          {...getTableProps()}
          className={`table table-hover ${className}`}
        >
          <thead>{headRow()}</thead>
          <tbody {...getTableBodyProps()}>{tableData()}</tbody>
        </table>
      </div>
      {isPagination && (
        <TablePagination
          pageIndex={pageIndex}
          pageOptions={pageOptions}
          previousPage={previousPage}
          canPreviousPage={canPreviousPage}
          nextPage={nextPage}
          canNextPage={canNextPage}
          gotoPage={gotoPage}
          pageSize={paginationSize ? paginationSize : pageSize}
          setPageSize={setPageSize}
          pageCount={pageCount}
        />
      )}
    </>
  )
}

export default Table
