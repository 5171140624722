/**
 * Each Row must contain only 3 objects
 */
export const formData = [
  // First Row
  {
    name: 'Container No :',
    label: 'Container Number',
    type: 'text',
    validation: 'Alphabetic',
    fieldId: 'containerNumber',
    id: 'containerNumber',
    disabled: true,
  },
  {
    name: 'Truck Rego :',
    label: 'Truck Rego',
    type: 'searchable-select',
    loadLookUp: 'truckRegos',
    validation: 'Alphabetic',
    fieldId: 'truckRego',
    id: 'truckRego',
    validationType: 'number',
    validations: [
      {
        type: 'optional',
        params: ['##FIELD_NAME## is required'],
        nullable: true,
      },
    ],
    disabled: true,
  },
  {
    name: 'Pickup Address :',
    label: 'Pickup Address',
    type: 'textarea',
    validation: 'Alphabetic',
    fieldId: 'pickupAddress',
    id: 'pickupAddress',
    validationType: 'string',
    validations: [
      {
        type: 'optional',
        params: [],
        nullable: true,
      },
    ],
  },
  // Second Row
  {
    name: 'Container Order No :',
    label: 'Order No',
    type: 'text',
    validation: 'Alphabetic',
    fieldId: 'orderNumber',
    id: 'orderNumber',
    disabled: true,
  },
  {
    name: 'Driver ID :',
    label: 'Driver ID',
    type: 'searchable-select',
    loadLookUp: 'drivers',
    validation: 'Alphabetic',
    fieldId: 'driverName',
    id: 'driverName',
    // validationType: "number",
    // dependentFieldIds: ["vehicleTruckId", "truckFleetNo", "vehicleTrailerIds"],
    // validations: [
    //   {
    //     type: "optional",
    //     params: [],
    //     nullable: true,
    //   },
    // ],
    disabled: true,
  },
  {
    name: 'Pickup Arrival Date Time :',
    label: 'Pickup Arrival Time',
    type: 'date',
    validation: 'Alphabetic',
    fieldId: 'transportStart',
    id: 'transportStart',
    disabled: true,
  },

  // Third Row
  {
    name: 'Container Load No :',
    label: 'Container Load No',
    type: 'text',
    validation: 'Alphabetic',
    fieldId: 'containerLoadNumber',
    id: 'containerLoadNumber',
    disabled: true,
  },
  {
    name: 'Stage :',
    label: 'Stage',
    type: 'text',
    validation: 'Alphabetic',
    fieldId: 'stage',
    id: 'stage',
    // disabled: true,
    validationType: 'string',
    validations: [
      {
        type: 'optional',
        params: ['##FIELD_NAME## is required'],
        nullable: true,
      },
    ],
    disabled: true,
  },
  {
    name: 'Pickup departure Date Time :',
    label: 'Pickup departure Date Time',
    type: 'date',
    validation: 'Alphabetic',
    fieldId: 'pickupDepartureTime',
    id: 'pickupDepartureTime',
    disabled: true,
  },

  // Fourth Row
  {
    name: 'Delivery Method :',
    label: 'Delivery Method',
    type: 'searchable-select',
    loadLookUp: 'vehicleJobTypes',
    validation: 'Alphabetic',
    fieldId: 'vehicleJobType',
    id: 'vehicleJobType',
    validationType: 'string',
    validations: [
      {
        type: 'optional',
        params: ['##FIELD_NAME## is required'],
      },
    ],
    disabled: true,
  },
  {
    name: 'Trailer Number :',
    label: 'Trailer Number',
    type: 'text',
    validation: 'Alphabetic',
    fieldId: 'trailerNumber', // [TODO]: difference between vehicle trailer id and trailer number
    id: 'trailerNumber',
    validationType: 'Alphabetic',
    // dependsOnFieldId: "vehicleTruckId",
    validations: [
      {
        type: 'optional',
        params: [],
        nullable: true,
      },
    ],
    disabled: true,
  },
  {
    name: 'Pickup Comments :',
    label: 'Pickup Comments',
    type: 'text',
    validation: 'Alphabetic',
    fieldId: 'pickupComments',
    id: 'pickupComments',
    disabled: true,
  },

  // Fifth Row
  {
    name: 'Load Status :',
    label: 'Load Status',
    loadLookUp: 'loadStatusEnums',
    type: 'searchable-select',
    validation: 'Alphabetic',
    fieldId: 'loadStatus',
    id: 'loadStatus',
    validationType: 'string',
    validations: [
      {
        type: 'optional',
        params: ['##FIELD_NAME## is required'],
        nullable: true,
      },
    ],
    disabled: true,
  },
  {
    name: 'Bat No :',
    label: 'Bat No',
    type: 'text',
    validation: 'Alphabetic',
    fieldId: 'truckBatNo',
    id: 'truckBatNo',
    // dependsOnFieldId: "vehicleTruckId",
    disabled: true,
  },
  {
    name: 'Delivery Address :',
    label: 'Delivery Address',
    type: 'textarea',
    validation: 'Alphabetic',
    fieldId: 'deliveryAddress',
    id: 'deliveryAddress',
    validationType: 'string',
    validations: [
      {
        type: 'optional',
        params: [],
        nullable: true,
      },
    ],
  },

  // Sixth Row
  {
    name: 'User :',
    label: 'User',
    type: 'text',
    validation: 'Alphabetic',
    fieldId: 'user',
    id: 'user',
    disabled: true,
  },
  {
    name: 'Container Delivery Type :',
    label: 'Container Delivery Type',
    type: 'textarea',
    validation: 'Alphabetic',
    fieldId: 'containerMovementType',
    id: 'containerMovementType',
    disabled: true,
  },
  {
    name: 'Delivery Arrival Date Time:',
    label: 'Delivery Arrival Date Time',
    type: 'date',
    validation: 'Alphabetic',
    fieldId: 'transportEnd',
    id: 'transportEnd',
    validationType: 'string',
    validations: [
      {
        type: 'optional',
        params: [],
        nullable: true,
      },
    ],
    disabled: true,
  },

  // Seventh Row
  {
    name: 'Updated Date Time :',
    label: 'Updated Date Time',
    type: 'date',
    validation: 'Alphabetic',
    fieldId: 'updatedDateTime',
    id: 'updatedDateTime',
    disabled: true,
  },
  {
    name: 'Door Position :',
    label: 'Door Position',
    type: 'select',
    loadLookUp: 'doorPositionEnums',
    validation: 'Alphabetic',
    fieldId: 'doorPosition',
    id: 'doorPosition',
    validationType: 'string',
    validations: [
      {
        type: 'optional',
        params: ['##FIELD_NAME## is required'],
        nullable: true,
      },
    ],
  },
  {
    name: 'Delivery Departure Date Time :',
    label: 'Delivery Departure Date Time',
    type: 'time',
    validation: 'Alphabetic',
    fieldId: 'deliverDepartureTime',
    id: 'deliverDepartureTime',
    disabled: true,
  },

  // Eigth Row
  {},
  {
    name: 'Booked Slot Date Time:',
    label: 'Booked Slot Date Time',
    type: 'date',
    validation: 'Alphabetic',
    fieldId: 'slotDateTime',
    id: 'slotDateTime',
    // validationType: "string",
    // validations: [
    //   {
    //     type: "optional",
    //     params: [],
    //     nullable: true,
    //   },
    // ],
    disabled: true,
  },
  {
    name: 'Delivery Comments :',
    label: 'Delivery Comments',
    type: 'text',
    validation: 'Alphabetic',
    fieldId: 'deliverComments',
    id: 'deliverComments',
    disabled: true,
  },
  {
    name: 'Collection Date',
    label: 'Collection Date',
    type: 'date',
    validation: 'Alphabetic',
    fieldId: 'collectionDate',
    id: 'collectionDate',
  },
  {
    name: 'Collection Remarks',
    label: 'Collection Remarks',
    type: 'text',
    validation: 'Alphabetic',
    fieldId: 'collectionRemarks',
    id: 'collectionRemarks',
  },
  {
    name: 'Collection Time',
    label: 'Collection Time',
    type: 'time',
    validation: 'Alphabetic',
    fieldId: 'collectionTime',
    id: 'collectionTime',
  },
  {
    name: 'Customer Request Date Time',
    label: 'Customer Request Date Time',
    type: 'datetime',
    validation: 'Alphabetic',
    fieldId: 'customerRequestDateTime',
    id: 'customerRequestDateTime',
  },
  {
    name: 'Mov Req Date',
    label: 'Mov Req Date',
    type: 'date',
    validation: 'Alphabetic',
    fieldId: 'movReqDate',
    id: 'movReqDate',
  },
  {
    name: 'Mov Req Time',
    label: 'Mov Req Time',
    type: 'time',
    validation: 'Alphabetic',
    fieldId: 'movReqTime',
    id: 'movReqTime',
  },
]
