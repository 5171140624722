import React, { useState } from 'react'
import Spinner from '../../Spinner/Spinner'
import Correspondence from '../Correspondence'
import UploadDocs from './UploadDocs'

const Misc = (props) => {
  const [enableSpinner, setEnableSpinner] = useState(false)
  const [activeTab, setActiveTab] = useState('docsUpload')
  return (
    <>
      <nav className="mb-4">
        <div className="nav nav-tabs" id="nav-tab" role="tablist">
          <button
            className={'nav-link ' + (activeTab === 'docsUpload' && 'active')}
            id="nav-home-tab"
            data-bs-toggle="tab"
            type="button"
            onClick={() => {
              setActiveTab('docsUpload')
            }}
          >
            Documents Upload
          </button>
          <button
            className={
              'nav-link ' + (activeTab === 'correspondence' && 'active')
            }
            id="nav-profile-tab"
            data-bs-toggle="tab"
            type="button"
            onClick={() => {
              setActiveTab('correspondence')
            }}
          >
            Correspondence
          </button>
        </div>
      </nav>
      <div
        className="tab-content white-bg-card p-3"
        style={{ borderRadius: '15px' }}
        id="nav-tabContent"
      >
        {activeTab === 'docsUpload' && (
          <div role="tabpanel" aria-labelledby="nav-home-tab">
            <UploadDocs {...props} setEnableSpinner={setEnableSpinner} />
          </div>
        )}

        {activeTab === 'correspondence' && (
          <div role="tabpanel" aria-labelledby="nav-home-tab">
            <Correspondence customerCardId={props.customerCardId} />
          </div>
        )}
        {activeTab === 'container' && (
          <div role="tabpanel" aria-labelledby="nav-home-tab">
            {/* <CargoRules
              {...props}
              setEnableSpinner={setEnableSpinner}
              isAddressBook={false}
            /> */}
          </div>
        )}
      </div>

      {enableSpinner && <Spinner />}
    </>
  )
}

export default Misc
