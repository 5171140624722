import React, { useState } from 'react'
import { useSaveChannelMutation } from '../../../../../helper/hooks/api/allocations'
import { toast } from 'react-toastify'
import { Spinner } from 'react-bootstrap'

export default function AddChannelForm() {
  const [channel, setChannel] = useState('')

  const saveChannelMutation = useSaveChannelMutation()

  const isLoading =
    saveChannelMutation.isPending && !saveChannelMutation.variables?.id

  const onSubmit = (e) => {
    e.preventDefault()
    e.stopPropagation()
    if (!channel) {
      toast.error('Please enter a channel name')
      return
    }
    saveChannelMutation.mutate(
      {
        channelName: channel,
      },
      {
        onSuccess: () => {
          setChannel('')
        },
      }
    )
  }

  return (
    <form onSubmit={onSubmit}>
      <h3>Add New Channel</h3>
      <div className="d-flex align-items-center">
        <input
          type="text"
          className="form-control w-25"
          placeholder="New channel 2"
          value={channel}
          onChange={(e) => setChannel(e.target.value)}
        />
        <button
          type="submit"
          className="ms-2 btn btn-primary"
          disabled={isLoading}
        >
          {isLoading ? <Spinner /> : 'Add'}
        </button>
      </div>
    </form>
  )
}
