const package_Type = {
  name: 'Package Type',
  apiUrlAppend: false,
  path: '/dataMgmt/customer/packageType',
  req: '/',
  columns: 4,
  fields: [
    {
      name: 'ID',
      label: 'ID',
      type: 'text',
      validation: 'Alphabetic',
      fieldId: 'id',
      id: 'id',
      validationType: 'string',
      validations: [
        {
          type: 'required',
          params: ['ID is required'],
        },
        {
          type: 'max',
          params: [10, 'ID cannot be more than 30 characters'],
        },
        {
          type: 'min',
          params: [2, 'ID should be min 2 characters'],
        },
      ],
    },
    {
      name: 'Description',
      label: 'Description',
      type: 'textarea',
      validation: 'Alphabetic',
      fieldId: 'description',
      id: 'description',
      validationType: 'string',
      max: 20,
      validations: [
        {
          type: 'required',
          params: ['Description is required'],
        },
        {
          type: 'max',
          params: [20, "Description can't be more than 20 characters"],
        },
      ],
    },
    {
      name: 'EDI Mapping',
      label: 'EDI Mapping',
      type: 'text',
      validation: 'Alphabetic',
      fieldId: 'ediMapping',
      id: 'ediMapping',
      validationType: 'string',
      validations: [],
    },
  ],
}
export default package_Type
