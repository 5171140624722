import { yupResolver } from '@hookform/resolvers/yup'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { format, isDate, parse } from 'date-fns'
import capitalize from 'lodash/capitalize'
import isNull from 'lodash/isNull'
import React, { useMemo } from 'react'
import { Col, Form, Modal, Row } from 'react-bootstrap'
import { useForm } from 'react-hook-form'
import { useSelector } from 'react-redux'
import * as Yup from 'yup'
import Input from '../../../../../components/Common/Form/Fields/Input'
import SearchableSelectField from '../../../../../components/Common/Form/Fields/SearchableSelectField'
import Select from '../../../../../components/Common/Form/Fields/Select'
import Switch from '../../../../../components/Common/Form/Fields/Switch'
import LocationSearchInput from '../../../../../components/Customer/LocationSearchInput'
import toasts, { Success } from '../../../../../components/utils/Toastify'
import fetchService from '../../../../../components/utils/fetchService'
import { getLookupDataState } from '../../../../../store/slices/lookup-slice'
import EntityRates from '../../Rates/EntityRates/EntityRates'
import entityData from '../../Rates/EntityRates/entity.data'
import RequiredSymbol from '../../../../../components/Common/Form/RequiredSymbol'

function parseDateString(_value, originalValue) {
  if (originalValue === '') return null
  return isDate(originalValue)
    ? originalValue
    : parse(originalValue, 'yyyy-MM-dd', new Date())
}

const defaultValues = {
  address: '',
  areaCodeId: '',
  // consigneeName: '',
  // shipperName: '',
  customerCodeConfirmation: '',
  customerSince: 'yyyy-MM-dd',
  isManual: true,
  notes: '',
  postCodeId: '',
  potentialCustomer: true,
  stateCodeId: 'NSW',
  suburb: '',
}

/**
 * Entity means - Consignee or shipper
 */
export default function EntityDetails({
  handleClose,
  entity,
  type,
  selectedDetail,
  customerCardId,
}) {
  const modeIsView = type === 'view'
  const isUpdating = !isNull(selectedDetail)
  const mode = isUpdating ? 'update' : 'create'
  const entityIdKey = `cc${capitalize(entity)}AddressId`

  const queryClient = useQueryClient()
  const lookupsList = useSelector(getLookupDataState)

  const mutation = useMutation({
    mutationFn: async (data) =>
      await fetchService({
        url: `/customercard/${entity}-address`,
        body: data,
        method: data[entityIdKey] ? 'PUT' : 'POST',
      }),
    onSuccess: (_data, variables) => {
      queryClient.invalidateQueries({
        queryKey: ['customer', `${entity}-address`, customerCardId],
      })
      if (variables[entityIdKey])
        Success(`${capitalize(entity)} details updated successfully.`)
      else Success(`${capitalize(entity)} created successfully.`)
      handleClose()
    },
    onError: (error) => {
      console.log(error)
      if (error?.data && Array.isArray(error.data)) {
        error.data.forEach((entry) => {
          if (
            typeof entry?.message === 'string' &&
            entry.message.length > 100
          ) {
            toasts.Error(entry?.key)
          } else {
            toasts.Error(entry?.message)
          }
        })
      }
    },
  })

  const schema = useMemo(() => {
    const today = new Date()
    return Yup.object().shape({
      address: Yup.string().required('Please enter a valid address'),
      // currencyCodeId: Yup.string().required('currency  is required'),
      suburb: Yup.string().required('suburb is required'),
      areaCodeId: Yup.string().required('Area Code is required'),
      stateCodeId: Yup.string().required('State is required'),
      postCodeId: Yup.string().required('postcode is required'),
      potentialCustomer: Yup.boolean(),
      isManual: Yup.boolean(),
      customerSince: Yup.date()
        .nullable()
        .transform(parseDateString)
        .typeError('Customer Since Date is required')
        .required('Customer Since Date is required')
        .max(today),
      [`${entity}Name`]: Yup.string()
        .max(200, "name can't be more 200 char")
        .required('Consignee Name is required'),
    })
  }, [entity])

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    watch,
    clearErrors,
    control,
  } = useForm({
    resolver: yupResolver(schema),
    reValidateMode: 'onChange',
    defaultValues: selectedDetail ?? defaultValues,
  })

  const isManualEntry = watch('isManual')

  const onSubmitFunction = (values) => {
    const data = { ...values }
    data.customerCardId = customerCardId
    data.active = true
    data.customerSince = format(data.customerSince, 'yyyy-MM-dd')
    mutation.mutate(data)
  }

  const getAddressDetails = (address) => {
    setValue('address', address.address)
    setValue('stateCodeId', address.stateCodeId)
    clearErrors(['address', 'stateCodeId'])
  }

  return (
    <Modal show onHide={handleClose} size="lg" centered>
      <Modal.Header closeButton>
        <Modal.Title>{capitalize(entity)}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={handleSubmit(onSubmitFunction)}>
          <Row>
            <Input
              register={register}
              name={`${entity}Name`}
              errors={errors}
              md={6}
              required
            />
            <Input
              register={register}
              name="customerCodeConfirmation"
              errors={errors}
              disabled
              md={6}
            />
            <Input
              register={register}
              name={`${entity}Id`}
              errors={errors}
              disabled
              md={6}
            />
            <Col md={6} />
            {/* <Select
              register={register}
              name="currencyCodeId"
              errors={errors}
              options={lookupsList.currencyCodes}
              optionValueKey="currencyCode"
              setOptionLabel={(o) => `${o.description} - ${o.currencyCode}`}
              md={6}
            /> */}

            <Switch
              register={register}
              errors={errors}
              name="isManual"
              md={12}
            />

            {!isManualEntry && (
              <Form.Group className="mb-3" as={Col} md={6} controlId="address">
                <Form.Label>
                  Search Location <RequiredSymbol />
                </Form.Label>
                {type !== 'view' && (
                  <LocationSearchInput
                    onSelectAddress={getAddressDetails}
                    id="address"
                    errors={errors}
                    register={register}
                    setValue={setValue}
                  />
                )}
              </Form.Group>
            )}
            <Input
              name="address"
              register={register}
              errors={errors}
              disabled={!isManualEntry || modeIsView}
              md={6}
              required
            />

            <hr className="my-3" />
            <Select
              register={register}
              name="areaCodeId"
              errors={errors}
              options={lookupsList.areaCodes}
              optionValueKey="areaCode"
              optionLabelKey="areaCode"
              md={6}
              required
            />
            <Select
              options={lookupsList?.stateCodes}
              optionValueKey="stateCode"
              setOptionLabel={(o) => `${o.description} - ${o.stateCode}`}
              register={register}
              errors={errors}
              name="stateCodeId"
              disabled={!isManualEntry || modeIsView}
              md={6}
            />

            <SearchableSelectField
              options={lookupsList?.postCodes}
              optionValueKey="postCode"
              optionLabelKey="postCode"
              name="postCodeId"
              control={control}
              setValue={setValue}
              getOptionLabel={(o) => `${o.postCode} - ${o.citySuburb}`}
              md={6}
              onChange={(val) => {
                clearErrors(['postCodeId', 'suburb'])
                setValue('postCodeId', val?.postCode || null)
                setValue('suburb', val?.citySuburb || null)
              }}
              required
            />
            <Input
              disabled
              name="suburb"
              md={6}
              register={register}
              errors={errors}
              required
            />

            <Switch
              register={register}
              errors={errors}
              name="potentialCustomer"
              md={12}
            />
            <Input
              register={register}
              errors={errors}
              name="customerSince"
              type="date"
              required
            />

            <Input
              register={register}
              errors={errors}
              name="notes"
              as="textarea"
              md={12}
            />
          </Row>
          <div className="d-flex">
            <div className="ms-auto d-flex gap-2">
              {!modeIsView && (
                <button type="submit" className="btn btn-primary">
                  {capitalize(mode)} {capitalize(entity)}
                </button>
              )}
              {mode === 'create' && (
                <button type="reset" className="btn btn-secondary ms-2">
                  Reset
                </button>
              )}
              <button
                type="button"
                className="btn btn-secondary float-end"
                onClick={handleClose}
              >
                Close
              </button>
            </div>
          </div>
        </Form>
        <hr className="mt-3" />
        <div className="mt-3">
          <h3>{capitalize(entity)} Rates Setup</h3>
          <EntityRates
            customerCardId={customerCardId}
            type={type}
            entity={entityData[entity]}
          />
        </div>
      </Modal.Body>
    </Modal>
  )
}
