import React from 'react'
import YmsTemplate from '../Template'
import config from '../../../config'
const GateIncoming = () => {
  return (
    <YmsTemplate
      Header="Gate Incoming"
      url={`${config.api.baseUrl}${config.api.yms.getGateIncomingDto}`}
    />
  )
}


export default GateIncoming