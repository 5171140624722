import moment from 'moment'

export const ParticipantsProfilePics = ({
  chatParticipantResponseDTOS,
  className,
}) => {
  const {
    userDetails: { id: userId },
  } = JSON.parse(localStorage.data)
  return (
    <div className={className}>
      {chatParticipantResponseDTOS.map(
        ({ profilePicURL, cerebroUserId, participantSid }) => {
          if (cerebroUserId === userId) return null
          if (profilePicURL === null)
            return (
              <img
                loading="lazy"
                key={participantSid}
                src="https://bootdey.com/img/Content/avatar/avatar1.png"
                alt="avatar"
              />
            )
          return (
            <img
              loading="lazy"
              key={participantSid}
              src={profilePicURL}
              alt="avatar"
            />
          )
        }
      )}
    </div>
  )
}

export const ParticipantsNames = ({ chatParticipantResponseDTOS }) => {
  const {
    userDetails: { id: userId },
  } = JSON.parse(localStorage.data)

  return chatParticipantResponseDTOS.map(
    ({ userName, cerebroUserId }, index) => {
      if (cerebroUserId === userId) return null
      if (chatParticipantResponseDTOS.length === 2) return userName
      if (index !== chatParticipantResponseDTOS.length - 1)
        return `${userName}, `
      return userName
    }
  )
}

export const getFormattedTimestampForMessage = (time) => {
  // Convert createdAt to Moment.js object
  const messageTime = moment(time)

  // Get the current date and time
  const now = moment()

  // Calculate the difference between now and message time
  const diffInDays = now.diff(messageTime, 'days')

  // Format timestamp based on how long ago the message was sent
  let formattedTimestamp
  if (diffInDays === 0) {
    formattedTimestamp = messageTime.format('HH:mm A') // 12:00
  } else if (diffInDays === 1) {
    formattedTimestamp = messageTime.format('[Yesterday at] HH:mm') // Yesterday at 12:00
  } else if (diffInDays < 7 && now.week() === messageTime.week()) {
    formattedTimestamp = messageTime.format('ddd [at] HH:mm') // Mon at 12:00
  } else if (diffInDays < 365) {
    formattedTimestamp = messageTime.format('MMM D [at] HH:mm') // Apr 23 at 12:00
  } else {
    formattedTimestamp = messageTime.format('MMM D YYYY [at] HH:mm') // April 23 2022 at 12:00
  }

  return formattedTimestamp
}

export const filterConversationsByParticipantsName = (conversations, name) => {
  const {
    userDetails: { id },
  } = JSON.parse(localStorage.data)

  return conversations.filter((conversation) => {
    const { chatParticipantResponseDTOS } = conversation
    let found = false

    chatParticipantResponseDTOS.forEach((participant) => {
      if (
        participant.cerebroUserId !== id &&
        participant.userName.toLowerCase().includes(name.toLowerCase())
      )
        found = true
    })
    return found
  })
}

const getDateToSort = (conversation) =>
  conversation.lastMessageCreatedDate === null
    ? new Date(conversation.conversationCreatedDate).valueOf()
    : new Date(conversation.lastMessageCreatedDate).valueOf()

export const sortConversationsByDate = (conversations) =>
  [...conversations].sort((a, b) => {
    const aDate = getDateToSort(a)
    const bDate = getDateToSort(b)

    return bDate - aDate
  })
