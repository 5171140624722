import { yupResolver } from '@hookform/resolvers/yup'
import { auto } from '@popperjs/core'
import axios from 'axios'
import { isDate, parse } from 'date-fns'
import React, { Fragment, useEffect, useMemo, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useSelector } from 'react-redux'
import * as Yup from 'yup'
import { date } from 'yup'
import { renderOptionsFromDataArr } from '../../../../../../components/utils/Utils'
import config from '../../../../../../config'
import { useOrdersContext } from '../../../context/orderOperationsContext'
import { getLookupDataState } from '../../../../../../store/slices/lookup-slice'
import * as DateFun from '../../../../../../components/utils/DateFun'
import { TextBox } from '../../../../../../components/utils/TextBox'
import * as Toastify from '../../../../../../components/utils/Toastify'
import Table from '../../../../../../components/Common/Tanstack-table/Table'
import capitalize from 'lodash/capitalize'

function parseDateString(_, originalValue) {
  return isDate(originalValue)
    ? originalValue
    : parse(originalValue, 'yyyy-MM-dd', new Date())
}

const CargoDetailsModal = ({ type, containerId, consigneeNames }) => {
  const lookupsList = useSelector(getLookupDataState)
  const [consigneeName, setConsigneeName] = useState(null)
  const [quantity, setQuantity] = useState(null)
  const [numberOfPallet, setNumberOdPallet] = useState(null)
  const [cargoData, setCargoData] = useState({})
  const [cargoDataList, setCargoDataList] = useState(null)
  const [addCargo, setAddCargoState] = useState(false)
  const operationsCtxData = useOrdersContext()
  let numbersArray = []
  for (let i = 1; i < 10000; i++) {
    numbersArray.push(i)
  }

  const handleConsigneeChange = (e) => {
    let consignee = consigneeNames.find((cust) => cust.id === +e.target.value)
    setConsigneeName(consignee?.name ?? '')
  }

  const handleQuantityChange = (e) => {
    let _quantity = numbersArray.find((num) => num === +e.target.value)
    if (_quantity) {
      setQuantity(_quantity)
    }
  }

  const handleNumberOfPalletChange = (e) => {
    let numberOdPallet = numbersArray.find((num) => num === +e.target.value)
    if (numberOdPallet) {
      setNumberOdPallet(numberOdPallet)
    }
  }

  useEffect(() => {
    getCargoListData(containerId)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const validationSchema = Yup.object().shape({
    cargoTypeId: Yup.string().required('Cargo Type is required'),
    imoOrUnTypeId: Yup.string().required('IMO/UN Type is required'),
    // disConductCodeId: Yup.string().required("Dis Conduct Code is required"),
    hblNo: Yup.string().required('HBL NO is required'),
    consigneeCode: Yup.string().required('Consignee Code is required'),
    productCode: Yup.string().required('Product Code is required'),
    cargoDescription: Yup.string().required('Cargo Description is required'),
    quantity: Yup.string().required('Quantity is required'),
    noOfPallet: Yup.number().required('No Of Pallet is required'),
    consignmentNo: Yup.string().required('Consignment Number is required'),
    mu: Yup.string().required('MU is required'),
    batchNo: Yup.string().required('Batch No is required'),
    poNo: Yup.string().required('P/O Nois required'),
    expiryDate: date()
      .transform(parseDateString)
      .required('Expiry Date is required'),
    volume: Yup.string().required('Volume is  required'),
    weight: Yup.string().required('Weight is required'),
    cargoLineNo: Yup.number().required('Cargo Line No is required'),
    stockRoom: Yup.string().required('Stock Room No is required'),
    marksNumbers: Yup.string().required('Marks and Numbers is required'),
    remarks: Yup.string().required('Remarks is required'),
    eidoPIN: Yup.string().required('Eido Pin is required'),
    orderReceivedDate: date()
      .transform(parseDateString)
      .required('Order Received Date is required'),
    expectedTimeClient: Yup.string().required(
      'Order Received Time is required'
    ),
    dimensionUnitId: Yup.string().required('Dimension Unit is required'),
  })

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
    setValue,
  } = useForm({
    resolver: yupResolver(validationSchema),
    reValidateMode: 'onChange',
    defaultValues: { ...cargoData },
  })

  useEffect(() => {
    if (cargoData) {
      setValue('cargoTypeId', cargoData.cargoType)
      setValue('imoOrUnTypeId', cargoData.imoCode)
      setValue('hblNo', cargoData.hblNo)
      setValue('consigneeCode', cargoData.ccConsigneeAddressId)
      setValue('consigneeName', cargoData.consigneeName)
      setConsigneeName(cargoData.consigneeName)
      setValue('productCode', cargoData.productCode)
      setValue('cargoDescription', cargoData.cargoDescription)
      setValue('quantity', cargoData.quantity)
      setQuantity(cargoData.quantity)
      setValue('noOfPallet', cargoData.noOfPallet)
      setNumberOdPallet(cargoData.noOfPallet)
      setValue('consignmentNo', cargoData.consignmentNo)
      setValue('mu', cargoData.mu)
      setValue('batchNo', cargoData.batchNo)
      setValue('poNo', cargoData.poNo)
      setValue('expiryDate', DateFun.getShortDate(cargoData.expiryDate))
      setValue('volume', cargoData.volume)
      setValue('weight', cargoData.weight)
      setValue('cargoLineNo', cargoData.cargoLineNo)
      setValue('stockRoom', cargoData.stockRoom)
      setValue('marksNumbers', cargoData.marksNumbers)
      setValue('eidoPIN', cargoData.eidoPIN)
      setValue(
        'orderReceivedDate',
        DateFun.getShortDate(cargoData.orderReceivedDate)
      )
      setValue(
        'expectedTimeClient',
        DateFun.getShortTime(cargoData.orderReceivedTime)
      )
      setValue('dimensionUnitId', cargoData.dimensionUnit)
      setValue('numberId', cargoData.number)
      setValue('remarks', cargoData.remarks)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cargoData])

  // Step5 Data
  const getCargoListData = (orderContainerDetailsId) => {
    const url =
      config.api.baseUrl +
      '/operations/order/container/cargodetails?orderContainerDetailsId=' +
      orderContainerDetailsId
    const requestOptions = {
      method: 'GET',
      headers: { Authorization: 'Bearer ' + localStorage.token },
    }
    fetch(url, requestOptions)
      .then((response) => response.json())
      .then((data) => {
        setCargoDataList(data)
      })
      .catch((error) => {
        Toastify.Error(error?.response?.data?.error)
      })
  }

  const onSubmitFunction = (formData) => {
    let options = {
      customerId: operationsCtxData.customerId, // Need to get customer id from step1
      orderContainerDetailsId: containerId, // Need to get it from step 4
      cargoDetailsId: cargoData?.cargoDetailsId,
      batchNo: formData.batchNo,
      cargoDescription: formData.cargoDescription,
      cargoType: formData.cargoTypeId,
      cargoLineNo: formData.cargoLineNo,
      cargoNumber: formData.cargoNumber,
      consigneeName: consigneeName,
      ccConsigneeAddressId: formData.consigneeCode,
      consignmentNo: formData.consignmentNo,
      dimensionUnit: formData.dimensionUnitId,
      eidoPIN: formData.eidoPIN,
      expiryDate: DateFun.getShortDate(formData.expiryDate),
      hblNo: formData.hblNo,
      imoCode: formData.imoOrUnTypeId,
      marksNumbers: formData.marksNumbers,
      mu: formData.mu,
      noOfPallet: formData.noOfPallet,
      number: formData.numberId,
      orderReceivedDate: DateFun.getShortDate(formData.orderReceivedDate),
      orderReceivedTime: DateFun.getLongTime(formData.expectedTimeClient),
      poNo: formData.poNo,
      productCode: formData.productCode,
      quantity: formData.quantity,
      remarks: formData.remarks,
      stockRoom: formData.stockRoom,
      unCode: formData.imoOrUnTypeId,
      volume: formData.volume,
      weight: formData.weight,
      dimension: formData.dimension,
    }

    const url = config.api.baseUrl + config.api.cargo
    axios({
      method: 'POST', //you can set what request you want to be
      url: url,
      data: options,
      headers: {
        Authorization: 'Bearer ' + localStorage.token,
      },
    })
      .then(function (response) {
        getCargoListData(response.data.orderContainerDetailsId)
        setAddCargoState(false)
        Toastify.Success('Cargo details submitted successfuly..!')
        reset({})
        setCargoData({})
      })
      .catch(function (error) {
        Toastify.Error(error?.response?.data?.error)
      })
  }

  const addNewCargo = () => {
    setAddCargoState(true)
    setCargoData({})
    setConsigneeName(null)
    setNumberOdPallet(null)
    setQuantity(null)
  }

  const columns = useMemo(() => {
    let cols = [
      'cargoType',
      'consignmentNo',
      'consigneeName',
      'number',
      'quantity',
      'cargoNumber',
      'eidoPIN',
      'createdBy',
      'createdDate',
    ]

    cols = cols.map((col) => ({
      header: capitalize(col),
      id: col,
      accessorKey: col,
    }))

    cols.unshift({
      header: 'Actions',
      id: 'action',
      cell: ({ row }) => (
        <button
          className="btn btn-sm btn-primary"
          onClick={(e) => {
            e.preventDefault()
            setCargoData(row.original)
          }}
        >
          {type && 'edit'}
        </button>
      ),
    })

    return cols
  }, [type])

  return (
    <div className="container-form-wrapper">
      {type !== 'view' && (
        <button
          className="btn btn-sm btn-primary d-flex ms-auto my-2"
          onClick={(e) => {
            e.preventDefault()
            e.stopPropagation()
            addNewCargo()
          }}
        >
          Add Cargo
        </button>
      )}
      <div className="row" style={{ overflowX: auto }}>
        <div className="col-md-12">
          <Table
            showTableBottom={false}
            data={cargoDataList}
            columns={columns}
            dontShowFilters
          />
        </div>
      </div>
      <form autoComplete="off" onSubmit={handleSubmit(onSubmitFunction)}>
        {((cargoData && cargoData.cargoDetailsId) || addCargo) && (
          <Fragment>
            <div className="row">
              {/* First Column */}
              <div className="col-md-4">
                <div className="row h-100 me-1 form-wrapper">
                  {/* Consignee Code */}
                  <Fragment>
                    <div className="col-md-6">
                      <label>Consignee Code :</label>
                    </div>
                    <div className="col-md-5 mb-2">
                      {type !== 'view' && (
                        <>
                          <select
                            className={`form-select ${
                              errors?.consigneeCode ? 'is-invalid' : ''
                            }`}
                            id="consigneeCode"
                            name="consigneeCode"
                            {...register('consigneeCode')}
                            onChange={(e) => handleConsigneeChange(e)}
                          >
                            <option value="">Please Select</option>
                            {consigneeNames.map((item) => (
                              <option key={item.id} value={item.id}>
                                {item.id}
                              </option>
                            ))}
                          </select>
                          {errors?.consigneeCode && (
                            <p className="invalid-feedback">
                              {errors?.consigneeCode?.message}
                            </p>
                          )}
                        </>
                      )}
                      {type === 'view' && (
                        <h4 className="blue-title">
                          {cargoData && cargoData.ccConsigneeAddressId}
                        </h4>
                      )}
                    </div>
                    <div className="col-md-1" title="Add New Consignee">
                      <img
                        src="./assets/add-button.png"
                        alt="Add New"
                        width="25px"
                        height="25px"
                      />
                    </div>
                  </Fragment>

                  {/* Cargo Type */}
                  <Fragment>
                    <div className="col-md-6">
                      <label>Cargo Type :</label>
                    </div>
                    <div className="col-md-6 mb-2">
                      {type !== 'view' && (
                        <>
                          <select
                            className={`form-select ${
                              errors?.cargoTypeId ? 'is-invalid' : ''
                            }`}
                            id="cargoTypeId"
                            name="cargoTypeId"
                            {...register('cargoTypeId')}
                          >
                            <option value="">Please Select</option>
                            {lookupsList?.cargoTypes.map((item) => (
                              <option
                                key={item.cargoType}
                                value={item.cargoType}
                              >
                                {item.description}
                              </option>
                            ))}
                          </select>
                          {errors?.cargoTypeId && (
                            <p className="invalid-feedback">
                              {errors?.cargoTypeId?.message}
                            </p>
                          )}
                        </>
                      )}
                      {type === 'view' && (
                        <h4 className="blue-title">
                          {cargoData && cargoData.cargoType}
                        </h4>
                      )}
                    </div>
                  </Fragment>

                  {/* IMO/UN Type */}
                  <Fragment>
                    <div className="col-md-6">
                      <label>IMO/UN Type :</label>
                    </div>
                    <div className="col-md-6 mb-2">
                      {type !== 'view' && (
                        <>
                          <select
                            className={`form-select ${
                              errors?.imoOrUnTypeId ? 'is-invalid' : ''
                            }`}
                            id="imoOrUnTypeId"
                            name="imoOrUnTypeId"
                            {...register('imoOrUnTypeId')}
                          >
                            <option value="">Please Select</option>
                            {lookupsList?.imoCodes.map((item) => (
                              <option key={item.imoCode} value={item.imoCode}>
                                {item.description}
                              </option>
                            ))}
                          </select>
                          {errors?.imoOrUnTypeId && (
                            <p className="invalid-feedback">
                              {errors?.imoOrUnTypeId?.message}
                            </p>
                          )}
                        </>
                      )}
                      {type === 'view' && (
                        <h4 className="blue-title">
                          {cargoData && cargoData.imoCode}
                        </h4>
                      )}
                    </div>
                  </Fragment>

                  {/* HBL NO */}
                  <Fragment>
                    <div className="col-md-6">
                      <label>HBL NO :</label>
                    </div>
                    <div className="col-md-6 mb-2">
                      <TextBox
                        register={register}
                        id="hblNo"
                        errors={errors}
                        value=""
                        placeholder="HBL NO"
                        maxLength="25"
                        disabled={type === 'view'}
                        viewMode={type}
                        defaultData={cargoData}
                      />
                    </div>
                  </Fragment>

                  {/* Expiry Date */}
                  <Fragment>
                    <div className="col-md-6">
                      <label>Expiry Date :</label>
                    </div>
                    <div className="col-md-6 mb-2">
                      {type !== 'view' && (
                        <>
                          <input
                            className={`form-control ${
                              errors?.expiryDate ? 'is-invalid' : ''
                            }`}
                            id="expiryDate"
                            placeholder="dd:mm:yy"
                            name="Customer Since Date"
                            type="date"
                            {...register('expiryDate')}
                          />
                          {errors?.expiryDate && (
                            <p className="invalid-feedback">
                              {errors?.expiryDate?.message}
                            </p>
                          )}
                        </>
                      )}
                      {type === 'view' && (
                        <h4 className="blue-title">
                          {cargoData && cargoData.expiryDate}
                        </h4>
                      )}
                    </div>
                  </Fragment>

                  {/* Volume */}
                  <Fragment>
                    <div className="col-md-6">
                      <label>Volume :</label>
                    </div>
                    <div className="col-md-6 mb-2">
                      <TextBox
                        register={register}
                        id="volume"
                        errors={errors}
                        value=""
                        placeholder="Volume"
                        maxLength="18,4"
                        disabled={type === 'view'}
                        viewMode={type}
                        defaultData={cargoData}
                      />
                    </div>
                  </Fragment>

                  {/* Weight */}
                  <Fragment>
                    <div className="col-md-6">
                      <label>Weight:</label>
                    </div>
                    <div className="col-md-6 mb-2">
                      <TextBox
                        register={register}
                        id="weight"
                        errors={errors}
                        value=""
                        placeholder="Weight"
                        maxLength="18,4"
                        disabled={type === 'view'}
                        viewMode={type}
                        defaultData={cargoData}
                      />
                    </div>
                  </Fragment>

                  {/* Cargo Line No */}
                  <Fragment>
                    <div className="col-md-6">
                      <label>Cargo Line No :</label>
                    </div>
                    <div className="col-md-6 mb-2">
                      <TextBox
                        register={register}
                        id="cargoLineNo"
                        errors={errors}
                        value=""
                        placeholder="Cargo Line No"
                        maxLength="4"
                        disabled={type === 'view'}
                        viewMode={type}
                        defaultData={cargoData}
                      />
                    </div>
                  </Fragment>

                  {/* Order Received Date */}
                  <Fragment>
                    <div className="col-md-6">
                      <label>Order Received Date :</label>
                    </div>
                    <div className="col-md-6 mb-2">
                      {type !== 'view' && (
                        <>
                          <input
                            className={`form-control ${
                              errors?.orderReceivedDate ? 'is-invalid' : ''
                            }`}
                            id="orderReceivedDate"
                            placeholder=""
                            name="Customer Since Date"
                            type="date"
                            {...register('orderReceivedDate')}
                          />
                          {errors?.orderReceivedDate && (
                            <p className="invalid-feedback">
                              {errors?.orderReceivedDate?.message}
                            </p>
                          )}
                        </>
                      )}
                      {type === 'view' && (
                        <h4 className="blue-title">
                          {cargoData && cargoData.orderReceivedDate}
                        </h4>
                      )}
                    </div>
                  </Fragment>
                </div>
              </div>

              {/* Second Column */}
              <div className="col-md-4">
                <div className="row h-100 me-1 form-wrapper">
                  {/* Consignee Name */}
                  <Fragment>
                    <div className="col-md-6">
                      <label>Consignee Name :</label>
                    </div>
                    <div className="col-md-6 mb-2">
                      {type !== 'view' && (
                        <>
                          <input
                            className="form-control"
                            id="consigneeName"
                            value={consigneeName}
                            name="consigneeName"
                            disabled
                            type="text"
                            maxlength="50"
                          />
                        </>
                      )}
                      {type === 'view' && (
                        <h4 className="blue-title">
                          {cargoData && cargoData.consigneeName}
                        </h4>
                      )}
                    </div>
                  </Fragment>
                  {/* Product Code */}
                  <Fragment>
                    <div className="col-md-6">
                      <label>Product Code :</label>
                    </div>
                    <div className="col-md-6 mb-2">
                      <TextBox
                        register={register}
                        id="productCode"
                        errors={errors}
                        value=""
                        placeholder="Product Code"
                        maxLength="50"
                        disabled={type === 'view'}
                        viewMode={type}
                        defaultData={cargoData}
                      />
                    </div>
                  </Fragment>
                  {/* Cargo Description */}
                  <Fragment>
                    <div className="col-md-6">
                      <label>Cargo Description :</label>
                    </div>
                    <div className="col-md-6 mb-2">
                      <TextBox
                        register={register}
                        id="cargoDescription"
                        errors={errors}
                        value=""
                        placeholder="Cargo Description"
                        maxLength="50"
                        disabled={type === 'view'}
                        viewMode={type}
                        defaultData={cargoData}
                      />
                    </div>
                  </Fragment>
                  {/* Quantity */}
                  <Fragment>
                    <div className="col-md-6">
                      <label>Quantity :</label>
                    </div>
                    <div className="col-md-3 mb-2">
                      {type !== 'view' && (
                        <>
                          <input
                            className="form-control"
                            id="quantity"
                            value={quantity}
                            name="quantity"
                            type="text"
                            maxlength="4"
                            disabled={true}
                          />
                        </>
                      )}
                      {type === 'view' && (
                        <h4 className="blue-title">
                          {cargoData && cargoData.quantity}
                        </h4>
                      )}
                    </div>
                    <div className="col-md-3 mb-2">
                      {type !== 'view' && (
                        <>
                          <select
                            className={`form-select ${
                              errors?.quantity ? 'is-invalid' : ''
                            }`}
                            id="quantity"
                            name="quantity"
                            {...register('quantity')}
                            onChange={(e) => handleQuantityChange(e)}
                          >
                            <option value=""></option>
                            {renderOptionsFromDataArr(numbersArray)}
                          </select>
                          {errors?.quantity && (
                            <p className="invalid-feedback">
                              {errors?.quantity?.message}
                            </p>
                          )}
                        </>
                      )}
                    </div>
                  </Fragment>
                  {/* P/O No */}
                  <Fragment>
                    <div className="col-md-6">
                      <label>P/O No :</label>
                    </div>
                    <div className="col-md-6 mb-2">
                      <TextBox
                        register={register}
                        id="poNo"
                        errors={errors}
                        value=""
                        placeholder="P/O No"
                        maxLength="50"
                        disabled={type === 'view'}
                        viewMode={type}
                        defaultData={cargoData}
                      />
                    </div>
                  </Fragment>
                  {/* Stock Room */}
                  <Fragment>
                    <div className="col-md-6">
                      <label>Stock Room :</label>
                    </div>
                    <div className="col-md-6 mb-2">
                      {type !== 'view' && (
                        <>
                          <select
                            className={`form-select ${
                              errors?.stockRoom ? 'is-invalid' : ''
                            }`}
                            id="stockRoom"
                            name="stockRoom"
                            {...register('stockRoom')}
                          >
                            <option value=""></option>
                            {lookupsList?.stockRooms.map((item) => (
                              <option key={item.id} value={item.id}>
                                {item.description}
                              </option>
                            ))}
                          </select>
                          {errors?.stockRoom && (
                            <p className="invalid-feedback">
                              {errors?.stockRoom?.message}
                            </p>
                          )}
                        </>
                      )}
                      {type === 'view' && (
                        <h4 className="blue-title">
                          {cargoData && cargoData.stockRoom}
                        </h4>
                      )}
                    </div>
                  </Fragment>
                  {/* Marks and Numbers */}
                  <Fragment>
                    <div className="col-md-6">
                      <label>Marks and Numbers :</label>
                    </div>
                    <div className="col-md-6 mb-2">
                      <TextBox
                        register={register}
                        id="marksNumbers"
                        errors={errors}
                        value=""
                        placeholder="Marks and Numbers"
                        maxLength="250"
                        disabled={type === 'view'}
                        viewMode={type}
                        defaultData={cargoData}
                      />
                    </div>
                  </Fragment>
                  {/* EIDO Pin */}
                  <Fragment>
                    <div className="col-md-6">
                      <label>EIDO Pin :</label>
                    </div>
                    <div className="col-md-6 mb-2">
                      <TextBox
                        register={register}
                        id="eidoPIN"
                        errors={errors}
                        value=""
                        placeholder="EIDO Pin"
                        maxLength="20"
                        disabled={type === 'view'}
                        viewMode={type}
                        defaultData={cargoData}
                      />
                    </div>
                  </Fragment>
                </div>
              </div>

              {/* Third Column */}
              <div className="col-md-4">
                <div className="row h-100 form-wrapper">
                  {/* No Of Pallet */}
                  <Fragment>
                    <div className="col-md-6">
                      <label>No Of Pallet :</label>
                    </div>
                    <div className="col-md-3 mb-2">
                      {type !== 'view' && (
                        <>
                          <input
                            className="form-control"
                            id="numberOfPallet"
                            value={numberOfPallet}
                            name="numberOfPallet"
                            type="text"
                            maxlength="4"
                            disabled={true}
                          />
                        </>
                      )}
                      {type === 'view' && (
                        <h4 className="blue-title">
                          {cargoData && cargoData.noOfPallet}
                        </h4>
                      )}
                    </div>
                    <div className="col-md-3 mb-2">
                      {type !== 'view' && (
                        <>
                          <select
                            className={`form-select ${
                              errors?.noOfPallet ? 'is-invalid' : ''
                            }`}
                            id="noOfPallet"
                            name="noOfPallet"
                            {...register('noOfPallet')}
                            onChange={(e) => handleNumberOfPalletChange(e)}
                          >
                            <option value=""></option>
                            {renderOptionsFromDataArr(numbersArray)}
                          </select>
                          {errors?.noOfPallet && (
                            <p className="invalid-feedback">
                              {errors?.noOfPallet?.message}
                            </p>
                          )}
                        </>
                      )}
                    </div>
                  </Fragment>

                  {/* Consignment Number */}
                  <Fragment>
                    <div className="col-md-6">
                      <label>Consignment Number :</label>
                    </div>
                    <div className="col-md-6 mb-2">
                      <TextBox
                        register={register}
                        id="consignmentNo"
                        errors={errors}
                        value=""
                        placeholder="Consignment Number"
                        maxLength="50"
                        disabled={type === 'view'}
                        viewMode={type}
                        defaultData={cargoData}
                      />
                    </div>
                  </Fragment>

                  {/* MU */}
                  <Fragment>
                    <div className="col-md-6">
                      <label>MU :</label>
                    </div>
                    <div className="col-md-6 mb-2">
                      <TextBox
                        register={register}
                        id="mu"
                        errors={errors}
                        value=""
                        placeholder="MU"
                        maxLength="10"
                        disabled={type === 'view'}
                        viewMode={type}
                        defaultData={cargoData}
                      />
                    </div>
                  </Fragment>

                  {/* Batch No */}
                  <Fragment>
                    <div className="col-md-6">
                      <label>Batch No :</label>
                    </div>
                    <div className="col-md-6 mb-2">
                      <TextBox
                        register={register}
                        id="batchNo"
                        errors={errors}
                        value=""
                        placeholder="Batch No"
                        maxLength="50"
                        disabled={type === 'view'}
                        viewMode={type}
                        defaultData={cargoData}
                      />
                    </div>
                  </Fragment>

                  {/* Order Received Time */}
                  <Fragment>
                    <div className="col-md-6">
                      <label>Order Received Time :</label>
                    </div>
                    <div className="col-md-6 mb-2">
                      {type !== 'view' && (
                        <>
                          <input
                            className="form-control "
                            id="expectedTimeClient"
                            placeholder="Start Time"
                            name="startTime"
                            type="time"
                            {...register('expectedTimeClient')}
                          />
                        </>
                      )}
                      {type === 'view' && (
                        <h4 className="blue-title">
                          {cargoData && cargoData.orderReceivedTime}
                        </h4>
                      )}
                    </div>
                  </Fragment>

                  {/* Dimension Unit */}
                  <Fragment>
                    <div className="col-md-6">
                      <label>Dimension Unit :</label>
                    </div>
                    <div className="col-md-6 mb-2">
                      {type !== 'view' && (
                        <>
                          <select
                            className={`form-select ${
                              errors?.dimensionUnitId ? 'is-invalid' : ''
                            }`}
                            id="dimensionUnitId"
                            name="dimensionUnitId"
                            {...register('dimensionUnitId')}
                          >
                            <option value="">Please Select</option>
                            {renderOptionsFromDataArr(
                              lookupsList?.dimensionUnits,
                              'id',
                              'description'
                            )}
                          </select>
                          {errors?.dimensionUnitId && (
                            <p className="invalid-feedback">
                              {errors?.dimensionUnitId?.message}
                            </p>
                          )}
                        </>
                      )}
                      {type === 'view' && (
                        <h4 className="blue-title">
                          {cargoData && cargoData.dimensionUnit}
                        </h4>
                      )}
                    </div>
                  </Fragment>

                  {/* Number */}
                  <Fragment>
                    <div className="col-md-6">
                      <label>Number :</label>
                    </div>
                    <div className="col-md-6 mb-2">
                      {type !== 'view' && (
                        <>
                          <select
                            className={`form-select ${
                              errors?.numberId ? 'is-invalid' : ''
                            }`}
                            id="number"
                            name="number"
                            {...register('numberId')}
                          >
                            <option value="">Please Select</option>
                            {lookupsList?.containerCargoNumberEnums.map(
                              (item) => (
                                <option key={item} value={item}>
                                  {item}
                                </option>
                              )
                            )}
                          </select>
                          {errors?.numberId && (
                            <p className="invalid-feedback">
                              {errors?.numberId?.message}
                            </p>
                          )}
                        </>
                      )}
                      {type === 'view' && (
                        <h4 className="blue-title">
                          {cargoData && cargoData.number}
                        </h4>
                      )}
                    </div>
                  </Fragment>

                  {/* Remarks */}
                  <Fragment>
                    <div className="col-md-6">
                      <label>Remarks :</label>
                    </div>
                    <div className="col-md-6 mb-2">
                      {type !== 'view' && (
                        <>
                          <textarea
                            {...register('remarks')}
                            className={`form-control ${
                              errors?.remarks ? 'is-invalid' : ''
                            }`}
                            id="remarks"
                            rows="2"
                            maxLength={250}
                          />
                        </>
                      )}
                      {type === 'view' && (
                        <h4 className="blue-title">
                          {cargoData && cargoData.remarks}
                        </h4>
                      )}
                    </div>
                  </Fragment>
                </div>
              </div>
            </div>

            {type !== 'view' && (
              <div className="row mt-3">
                <div className="col-md-12 text-end">
                  {cargoData?.cargoDetailsId && (
                    <button
                      type="button"
                      className="btn btn-info me-3"
                      onClick={(e) => {
                        e.preventDefault()
                        setCargoData({})
                      }}
                    >
                      Cancel
                    </button>
                  )}
                  <span
                    className="btn btn-primary me-3"
                    onClick={() => reset({})}
                  >
                    Reset
                  </span>
                  <button className="btn btn-primary me-3" type="submit">
                    Save
                  </button>
                </div>
              </div>
            )}
          </Fragment>
        )}
      </form>
    </div>
  )
}

export default CargoDetailsModal
