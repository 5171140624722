import React, { useState } from 'react'
import { Col } from 'react-bootstrap'
import { greenTruck, redTruck } from '../../../../images/PlStatusTrucks'
import './plStatusEditBar.scss'
const PlStatusBar = ({ statusIndex }) => {
  const [statusList] = useState([
    {
      title: 'Container in yard waiting to be transferred to the warehouse',
      // status: false,
    },
    {
      title: 'Cargo in Warehouse',
      // status: false,
    },
    {
      title: 'Cargo ready to be Unpacked',
      // status: false,
    },
    {
      title: 'Unpacked and Verified',
      // status: false,
    },
    {
      title: 'Approved',
      // status: false,
    },
    {
      title: 'Container Dispatched',
      // status: false,
    },
    {
      title: 'Pod Received',
      // status: false,
    },
    {
      title: 'Job Completed',
      // status: false,
    },
    {
      title: 'Container Moved to Yard',
      // status: false,
    },
    {
      title: 'Container Moved to the Park',
      // status: false,
    },
    {
      title: 'Container De-hired',
      // status: false,
    },
  ])

  /* A function that is called when the user clicks on a status. It takes the index of the status that
  was clicked and sets the status of all the previous statuses to true and the rest to false. */
  // const handleChangeStatus = (index) => {
  //   setStatusList((prev) =>
  //     prev.map((item, itemIndex) => {
  //       return {
  //         ...item,
  //         status: itemIndex <= index ? true : false,
  //       }
  //     })
  //   )
  // }

  /* Mapping through the statusList array and returning a Col component for each element in the array. */
  const plStatusBar = statusList.map((ele, index) => (
    // <Col key={index} className="StatusCard" onClick={() => handleChangeStatus(index)}>
    <Col key={index} className="StatusCard">
      <div className="truckImgContainer">
        <img
          src={index <= statusIndex ? greenTruck : redTruck}
          alt="truck"
          className="truckImg"
        />
      </div>
      <div
        className={`${
          index <= statusIndex ? 'text-success' : 'text-danger'
        } title `}
      >
        {ele.title}
      </div>
    </Col>
  ))

  return (
    <div className="card-body">
      <div className="plStatusBarContainer">{plStatusBar}</div>
    </div>
  )
}

export default PlStatusBar
