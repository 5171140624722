import React, { useEffect } from 'react'
import CRDTable from './CRDTable'
import fieldsListEmpty from './CRDDataRef.json'

const AddCRD = (props) => {
  const {
    csvArray,
    setCsvArray,
    showError,
    setShowSuccessMsg,
    AddMultiZones,
    setProfileFormId,
    setEnableForm,
    setSuburbFormData,
    setProfileFormType,
  } = props

  useEffect(() => {
    setCsvArray({ data: [], igniter: new Date() })
    setProfileFormType('add')
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const submitAddZone = () => {
    AddMultiZones(csvArray.data)
  }

  const openCRDForm = () => {
    setEnableForm(true)
    setProfileFormType('add')
    setSuburbFormData(fieldsListEmpty)
    setShowSuccessMsg(false)
  }

  return (
    <div>
      <div className="text-end">
        <button className="btn btn-primary" onClick={() => openCRDForm()}>
          Add New
        </button>
      </div>

      {csvArray.data && csvArray.data.length > 0 && (
        <div className="AddCRD-comp">
          <CRDTable
            csvArray={csvArray}
            setSuburbFormData={setSuburbFormData}
            setEnableForm={setEnableForm}
            setProfileFormId={setProfileFormId}
            setProfileFormType={setProfileFormType}
            setCsvArray={setCsvArray}
            mode="add"
            type={props.type}
            showAgreedRates={props.showAgreedRates}
          />

          <div className="text-end mt-4">
            <button onClick={() => submitAddZone()} className="btn btn-primary">
              Submit CRD
            </button>
          </div>

          {showError && (
            <div className="text-danger my-4">
              <p>There is an issue in form submission. please try later.</p>
              {showError.length &&
                showError.map((eachError, i) => {
                  return (
                    <p key={i}>
                      {eachError.key} - {eachError.message}
                    </p>
                  )
                })}
            </div>
          )}
        </div>
      )}
    </div>
  )
}

export default AddCRD
