import React, { useEffect, useState } from 'react'
import { TableBuilder } from '../../DataMgmt/Table'
import CONSTANTS from '../../../constants.json'
import { format } from 'date-fns'
import config from '../../../config'

const suburbObjMini = [
  'wharfJobType',
  'stageMovementType',
  'vehicleJobType',
  'rateCode',
  'description',
  'location1AreaCode',
  'location2AreaCode',
  'customerRateDetailId',
]

const CRDTable = (props) => {
  const {
    csvArray,
    setCsvArray,
    setEnableForm,
    setSuburbFormData,
    setProfileFormId,
    setProfileFormType,
    type,
    showAgreedRates,
  } = props
  const [tableHeaderData, setTableHeaderData] = useState([])
  const [tableBodyData, setTableBodyData] = useState([])

  useEffect(() => {
    if (csvArray.data.length > 0) {
      let miniDataArray = []
      csvArray.data.forEach((item) => {
        let a = {}
        suburbObjMini.forEach((eachKey) => {
          a[eachKey] = item[eachKey]
        })
        miniDataArray.push(a)
      })
      transFormTableData(miniDataArray)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [csvArray])

  const transFormTableData = (data) => {
    let keys = Object.keys(data[0])
    let headers = []
    let tbodyData = []
    keys.forEach((each, i) => {
      let hdrObj = {
        Header: CONSTANTS[each] ? CONSTANTS[each] : each,
        accessor: each,
        className: each,
      }

      if (each === 'editedDate') {
        hdrObj.Cell = ({ value }) => {
          return format(new Date(value), 'dd/MM/yy')
        }
      }
      headers.push(hdrObj)
    })
    headers.push({
      width: 200,
      Header: 'Actions',
      accessor: 'name',

      Cell: ({ cell, row }) => (
        <span style={{ minWidth: '220px', display: 'block' }}>
          <button
            value={cell.row.values.id}
            type="button"
            className="btn btn-primary btn-sm"
            onClick={editTable.bind(cell.row.values.recId, row.index)}
          >
            {type !== 'view' ? 'Edit' : 'View'}
          </button>
          <button
            value={cell.row.values.id}
            type="button"
            className="btn btn-secondary btn-sm ms-2"
            onClick={deleteItem.bind(cell.row.values.recId, row.index)}
          >
            Delete
          </button>
          {cell.row.values.customerRateDetailId && (
            <button
              value={cell.row.values.customerRateDetailId}
              type="button"
              className="btn btn-gold btn-sm ms-2"
              onClick={() =>
                showAgreedRates(
                  cell.row.values.customerRateDetailId,
                  row.index,
                  cell.row.values
                )
              }
            >
              Agreed Rates
            </button>
          )}
        </span>
      ),
    })

    setTableHeaderData(headers)
    setTableBodyData(tbodyData)
    let newData = []
    data.forEach((eachObj, index) => {
      let newObj = {}
      keys.forEach((each, i) => {
        if (eachObj[each] === true) {
          newObj[each] = 'YES'
        } else if (eachObj[each] === false) {
          newObj[each] = 'NO'
        } else {
          newObj[each] = eachObj[each]
        }
        newObj.class = 'each'
      })

      newData.push(newObj)
    })

    setTableBodyData(newData)
  }

  const deleteItem = (id) => {
    const crdID = csvArray.data[id].customerRateDetailId
    if (!crdID) {
      let newArray = csvArray.data || []
      newArray.splice(id, 1)
      setCsvArray({ data: newArray, igniter: new Date() })
    } else {
      let profileObj = csvArray.data[id]
      let answer = window.confirm(
        'Are you sure to delete crd of ' + profileObj.customerRateDetailId
      )
      if (!answer) {
        return false
      }

      const url =
        config.api.baseUrl +
        '/customercard/rate-detail/' +
        profileObj.customerRateDetailId
      const requestOptions = {
        method: 'DELETE',
        headers: { Authorization: 'Bearer ' + localStorage.token },
      }

      fetch(url, requestOptions)
        .then((response) => response.json())
        .then((data) => {
          alert(
            'CRD profile id ' +
              profileObj.customerRateDetailId +
              ' has deleted successfully'
          )
          let newArray = csvArray.data || []
          newArray.splice(id, 1)
          setCsvArray({ data: newArray, igniter: new Date() })
        })
        .catch(() =>
          alert(
            'Unable to delete the CRD profile now please try after some time!'
          )
        )
    }
  }

  const editTable = (id) => {
    setEnableForm(false)
    setTimeout(() => {
      const getSuburbObj = csvArray.data[id]
      setSuburbFormData(getSuburbObj)
      setProfileFormId(id)
      setProfileFormType('editForm')
      setEnableForm(true)
    }, 100)
  }

  return (
    <div>
      <div className="row">
        <div className="col-md-9">
          <h4>CRD data</h4>
        </div>
      </div>
      {tableBodyData.length > 0 && (
        <TableBuilder
          tableBodyData={tableBodyData}
          tableHeaderData={tableHeaderData}
          url={'../'}
          editTable={editTable}
        />
      )}
    </div>
  )
}

export default CRDTable
