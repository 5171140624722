const distinct_Conduct = {
  name: 'Special Handling',
  apiUrlAppend: false,
  path: '/dataMgmt/customer/distinct_Conduct',
  req: '/',
  columns: 1,
  fields: [
    {
      name: 'ID',
      label: 'ID',
      type: 'text',
      validation: 'Alphabetic',
      fieldId: 'id',
      id: 'id',
      validationType: 'string',
      validations: [
        {
          type: 'required',
          params: ['ID is required'],
        },
        {
          type: 'max',
          params: [15, 'ID cannot be more than 20 characters'],
        },
      ],
    },
    {
      name: 'Description',
      label: 'Description',
      type: 'textarea',
      validation: 'Alphabetic',
      fieldId: 'description',
      id: 'description',
      validationType: 'string',
      validations: [
        {
          type: 'required',
          params: ['Description is required'],
        },
        {
          type: 'max',
          params: [30, 'Description cannot be more than 30 characters'],
        },
      ],
    },
  ],
}
export default distinct_Conduct
