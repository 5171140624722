import ManagementReports from '../../modules/cerebro/reports/management'

const routes = [
  {
    name: 'Management Reports',
    path: '/reports/management',
    component: ManagementReports,
    includeHeaderFooter: true,
    staticProps: {},
  },
]

export default routes
