import { faDownload, faPen } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { yupResolver } from '@hookform/resolvers/yup'
import axios from 'axios'
import React, { Fragment, useState } from 'react'
import Dropzone from 'react-dropzone'
import { useForm } from 'react-hook-form'
import * as Yup from 'yup'
import { TextBox } from '../../../../../../components/utils/TextBox'
import * as Toastify from '../../../../../../components/utils/Toastify'
import { downloadFileFromBlob } from '../../../../../../components/utils/Utils'
import * as downloadFile from '../../../../../../components/utils/downloadFile'
import fetchService from '../../../../../../components/utils/fetchService'
import config from '../../../../../../config'
import useModalContainer from '../../../../../../helper/hooks/useModalContainer'

const Documents = ({ contextAPIData }) => {
  const [document, setDocument] = useState([])
  const [documents, setDocuments] = useState([])
  const [edoDocuments, setEDODocuments] = useState([])
  const [filePath, setFilePath] = useState('')
  const [files, setFiles] = useState([])
  const [podList, setPodList] = useState([])

  const validationSchema = Yup.object().shape({
    fileName: Yup.string().required('fileName is required'),
  })

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    reset,
  } = useForm({
    resolver: yupResolver(validationSchema),
    reValidateMode: 'onChange',
  })

  const setDocumentData = (_document) => {
    setValue('fileName', _document.fileName)
    setDocument(_document)
  }

  const downloadDocument = async (
    _document,
    key,
    apiEndpoint,
    returnRaw = true,
    downloadFromBlob = false
  ) => {
    try {
      const response = await fetchService({
        isFile: true,
        returnRaw: returnRaw,
        url: `${apiEndpoint}${_document[key]}`,
      })

      if (downloadFromBlob) {
        const fileName = response.headers.get('Content-Disposition').split('; ')
        downloadFileFromBlob(await response.blob(), fileName)
      } else if (typeof response === 'string') {
        downloadFile.fileDownload(response)
      }
    } catch (error) {
      console.log(error)
    }
  }

  const getDocuments = () => {
    const url =
      config.api.baseUrl +
      '/operations/order/document?orderCustomerDetailsId=' +
      contextAPIData.orderCustomerDetailsId
    const requestOptions = {
      method: 'GET',
      headers: { Authorization: 'Bearer ' + localStorage.token },
    }
    fetch(url, requestOptions)
      .then((response) => response.json())
      .then((data) => {
        setDocuments(data)
      })
      .catch((error) => {
        Toastify.Error(error?.response?.data?.error)
      })
  }

  const getEDODocuments = () => {
    const url =
      config.api.baseUrl +
      '/operations/order/container/edo/by-order-id?orderCustomerDetailsId=' +
      contextAPIData.orderCustomerDetailsId
    const requestOptions = {
      method: 'GET',
      headers: { Authorization: 'Bearer ' + localStorage.token },
    }
    fetch(url, requestOptions)
      .then((response) => response.json())
      .then((data) => {
        setEDODocuments(data)
      })
  }

  const getPODList = () => {
    const url =
      config.api.baseUrl +
      '/operations/order/customer/container-load/pod/list?orderCustomerDetailsId=' +
      contextAPIData.orderCustomerDetailsId
    const requestOptions = {
      method: 'POST',
      headers: { Authorization: 'Bearer ' + localStorage.token },
    }
    fetch(url, requestOptions)
      .then((response) => response.json())
      .then((data) => {
        setPodList(data)
      })
  }

  const submitDocumentData = (data) => {
    const formData = new FormData()
    if (files?.length > 0) {
      files?.forEach((attachment) => {
        formData.append('file', attachment)
      })
    }

    let requestType = document && document.orderDocumentId ? 'PUT' : 'POST'
    if (requestType === 'POST' && files?.length < 1) {
      Toastify.Error('Please upload a document first!')
      return
    }
    let requestUrl =
      document && document.orderDocumentId
        ? '/operations/order/document/update'
        : '/operations/order/document/upload'

    if (document.orderDocumentId) {
      formData.append('id', document.orderDocumentId)
    } else {
      formData.append(
        'orderCustomerDetailsId',
        contextAPIData.orderCustomerDetailsId
      )
    }
    formData.append('newFileName', data.fileName)

    axios({
      method: requestType,
      url: config.api.baseUrl + requestUrl,
      headers: {
        Authorization: 'Bearer ' + localStorage.token,
        'Content-Type': 'multipart/form-data',
      },
      data: formData,
    })
      .then(function () {
        Toastify.Success('Document Details Uploaded successfully..!')
        setShow(false)
      })
      .catch((error) => {
        if (Array.isArray(error?.response?.data?.data)) {
          error?.response?.data?.data.forEach(({ message }) => {
            Toastify.Error(message)
          })
        }
      })
  }

  const handleAcceptedFiles = (_files) => {
    setFilePath(_files[0].path)
    if (_files?.length > 0) {
      setFiles(_files)
    }
  }

  const handleSubmitWithoutPropagation = (e) => {
    e.preventDefault()
    e.stopPropagation()
    handleSubmit(submitDocumentData)(e)
  }

  const resetForm = () => {
    reset()
    setDocument([])
    setFilePath('')
    setFiles([])
  }

  const { ModalContainer, setShow } = useModalContainer({
    text: (
      <button
        onClick={() => {
          if (contextAPIData?.orderCustomerDetailsId > 0) {
            getDocuments()
            getEDODocuments()
            getPODList()
            setShow(true)
          } else {
            Toastify.Warning('Order Customer Details Id is not available.')
          }
        }}
        className="unset-all d-flex flex-column align-items-center"
        title="Documents"
      >
        <img
          src="./assets/customer-order/documents.png"
          alt="Documents"
          width="40px"
          height="40px"
          className="img"
        />
        Documents
      </button>
    ),
    header: '',
    body: (
      <Fragment>
        <div className="card-body">
          <form onSubmit={handleSubmitWithoutPropagation}>
            <div>
              <div>
                <div className="col-7">
                  <h4>Document Upload :</h4>
                </div>
                <div className="row mb-3">
                  <div className="col-md-12">
                    <Dropzone
                      onDrop={(acceptedFiles) =>
                        handleAcceptedFiles(acceptedFiles)
                      }
                      multiple={false}
                      accept={{
                        'text/csv': [],
                        'application/pdf': ['.pdf'],
                        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
                          [],
                      }}
                    >
                      {({ getRootProps, getInputProps }) => (
                        <div
                          className="drop-zone-container"
                          {...getRootProps()}
                        >
                          <input {...getInputProps()} />
                          <h4>Drag a file here</h4>

                          <p>{filePath}</p>
                        </div>
                      )}
                    </Dropzone>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-1" />
                <div className="col-md-3">
                  <h5>File Name :</h5>
                </div>
                <div className="col-md-4 mb-4">
                  <TextBox
                    register={register}
                    id="fileName"
                    errors={errors}
                    placeholder="fileName"
                    maxLength="50"
                  />
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-1" />
              <div className="col-7 text-right">
                <button
                  className="btn btn-primary float-end ms-3"
                  type="submit"
                >
                  Save
                </button>
                <button
                  className="btn btn-primary float-end"
                  type="button"
                  onClick={resetForm}
                >
                  Reset
                </button>
              </div>
            </div>
          </form>
        </div>
        <hr />
        <div className="card-body">
          <div className="col-7">
            <h4 className="blue-title">View Documents :</h4>
          </div>
          <div className="row">
            <div className="col-md-12">
              <div className="">
                <table className="table table-hover">
                  <thead>
                    <tr>
                      <th>File Name</th>
                      <th>Uploaded By</th>
                      <th>Uploaded Date</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {documents?.map?.((_document, i) => {
                      return (
                        <tr key={i}>
                          <td>{_document?.fileName}</td>
                          <td>{_document?.createdBy}</td>
                          <td>{_document?.createdDate}</td>
                          <td>
                            {' '}
                            <button
                              className="btn btn-sm btn-gold ms-2"
                              onClick={(e) => {
                                e.preventDefault()
                                setDocumentData(_document)
                              }}
                            >
                              <FontAwesomeIcon icon={faPen} />
                            </button>
                            <button
                              className="btn btn-sm btn-info ms-2"
                              onClick={(e) => {
                                e.preventDefault()
                                downloadDocument(
                                  _document,
                                  'orderDocumentId',
                                  '/operations/order/document/download/'
                                )
                              }}
                            >
                              <FontAwesomeIcon icon={faDownload} />
                            </button>
                          </td>
                        </tr>
                      )
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        <hr />
        <div className="card-body">
          <div className="col-7">
            <h4 className="blue-title">EDO Documents :</h4>
          </div>
          <div className="row">
            <div className="col-md-12">
              <div className="">
                <table className="table table-hover">
                  <thead>
                    <tr>
                      <th>File Name</th>
                      <th>Container Number</th>
                      <th>Uploaded By</th>
                      <th>Uploaded Date</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {edoDocuments?.map?.((_document, i) => {
                      return (
                        <tr key={i}>
                          <td>{_document?.fileName}</td>
                          <td>{_document?.containerNumber}</td>
                          <td>{_document?.createdBy}</td>
                          <td>{_document?.createdDate}</td>
                          <td>
                            {' '}
                            <button
                              className="btn btn-sm btn-info ms-2"
                              onClick={(e) => {
                                e.preventDefault()
                                downloadDocument(
                                  _document,
                                  'orderContainerEDOId',
                                  '/operations/order/container/edo/download/'
                                )
                              }}
                            >
                              <FontAwesomeIcon icon={faDownload} />
                              {/* Download Document */}
                            </button>
                          </td>
                        </tr>
                      )
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        <hr />
        <div className="card-body">
          <div className="col-7">
            <h4 className="blue-title">POD Documents :</h4>
          </div>
          <div className="row">
            <div className="col-md-12">
              <div className="">
                <table className="table table-hover">
                  <thead>
                    <tr>
                      <th>File Name</th>
                      <th>Container Load Number</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {podList?.map?.((_document, i) => {
                      return (
                        <tr key={i}>
                          <td>{_document?.fileName}</td>
                          <td>{_document?.containerLoadNo}</td>
                          <td>
                            {' '}
                            <button
                              className="btn btn-sm btn-info ms-2"
                              onClick={(e) => {
                                e.preventDefault()
                                downloadDocument(
                                  _document,
                                  'cerebroCstID',
                                  '/operations/order/customer/container-load/pod/url?cerebroCSTId=',
                                  false,
                                  true
                                )
                              }}
                            >
                              <FontAwesomeIcon icon={faDownload} />
                              {/* Download Document */}
                            </button>
                          </td>
                        </tr>
                      )
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    ),
    onCloseCallback: resetForm,
  })

  return ModalContainer
}

export default Documents
