import React, { useEffect, useState } from 'react'
import { yupResolver } from '@hookform/resolvers/yup'
import * as Yup from 'yup'
import axios from 'axios'
import { useForm } from 'react-hook-form'
import config from '../../../config'
import { TextBox } from '../../utils/TextBox'
import { getSelectClass, Select } from '../../utils/Select'
import { Modal } from 'react-bootstrap'
import TextInputField from '../../Common/Form/Fields/TextInputField'

const AddCRDData = (props) => {
  const {
    csvArray,
    setCsvArray,
    type,
    lookupsList,
    defaultData,
    profileFormType,
    profileFormId,
    setEnableForm,
    consigneeData,
    customerCardId,
  } = props

  const [formType, setFormType] = useState('add')
  const [formSuccess, setFormSuccess] = useState(false)
  const [formSubmitError, setFormSubmitError] = useState(false)

  const viewMode = type === 'view'

  useEffect(() => {
    if (defaultData.customerRateDetailId) {
      setFormType('edit')
    }
  }, [defaultData.customerRateDetailId])

  const validationSchema = Yup.object().shape({
    wharfJobType: Yup.string().required('Wharf Job Type is required'),
    vehicleJobType: Yup.string(),
    rateCode: Yup.string(), //??autocomplete
    rateType: Yup.string(),
    description: Yup.string(),
    dimensionUnit: Yup.string(),
    typeSize: Yup.string().nullable(true),
    sellRate: Yup.string(),
    fromRange: Yup.number()
      .min(0)
      .nullable(true)
      .transform((v) => (v === '' || Number.isNaN(v) ? null : v)), //.number({ accept: ["", undefined, null] }),
    toRange: Yup.number()
      .positive()
      .nullable(true)
      .transform((v) => (v === '' || Number.isNaN(v) ? null : v)),
    handling: Yup.string(),
    jobWindow: Yup.string(),
    consignee: Yup.string(),
    // Route Details
    stageMovementType: Yup.string().nullable(true), //??autocomplete
    wharf: Yup.string().nullable(true),
    emptyDehire: Yup.string(),
    remarks: Yup.string(),
    zone: Yup.string().nullable(true),
    location1AreaCode: Yup.string().nullable(),
    location1Description: Yup.string().nullable(),
    location2AreaCode: Yup.string().nullable(),
    location2Description: Yup.string().nullable(),
  })

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm({
    resolver: yupResolver(validationSchema),
    reValidateMode: 'onChange',
    defaultValues: defaultData,
  })

  useEffect(() => {
    setValue('quoteDate', new Date())
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const submitAddZone = (formData) => {
    let newArray = csvArray.data || []
    if (profileFormType === 'edit_Add') {
      newArray.splice(profileFormId, 1, formData)
      setCsvArray({ data: newArray, igniter: new Date() })
      setEnableForm(false)
      return false
    }
    if (profileFormType === 'add') {
      newArray.push(formData)
      setCsvArray({ data: newArray, igniter: new Date() })
      setEnableForm(false)
    } else if (profileFormType === 'editForm') {
      newArray[profileFormId] = formData
      setCsvArray({ data: newArray, igniter: new Date() })
      setEnableForm(false)
    }
    let newFormData = formData
    if (profileFormType !== 'add') {
      const getSuburbObj = newArray[profileFormId]
      const formID = getSuburbObj.customerRateDetailId
      newFormData.customerRateDetailId = formID
    }
    newFormData.customerCardId = parseInt(customerCardId)

    let eachFormattedData = {}
    Object.keys(newFormData).forEach((item) => {
      let itemFormatted = newFormData[item]
      if (itemFormatted === '') {
        itemFormatted = null //::TODO:: is this mandatory to make null
      } else if (
        itemFormatted &&
        typeof itemFormatted === String &&
        itemFormatted.startsWith('$')
      ) {
        itemFormatted = item.replace('$', '')
      } else if (itemFormatted === 'NULL') {
        itemFormatted = null //::TODO:: is this mandatory to make null
      }
      eachFormattedData[item] = itemFormatted
    })
    setFormSuccess(false)
    setFormSubmitError(false)

    let method = 'GET'
    if (profileFormType === 'add') method = 'POST'
    else if (profileFormType === 'editForm') method = 'PUT'

    axios({
      method, //you can set what request you want to be
      url: config.api.baseUrl + '/customercard/rate-detail/multi',
      data: [eachFormattedData],
      headers: {
        Authorization: 'Bearer ' + localStorage.token,
        'Content-Type': 'application/json',
      },
    })
      .then(function () {
        setFormSuccess(true)
      })
      .catch(function () {
        setFormSubmitError(true)
      })
  }

  return (
    <Modal show={true} onHide={() => setEnableForm(false)} size="lg" centered>
      <Modal.Header closeButton>
        <Modal.Title>CRD Form</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="card2">
          <div className="card-body2">
            <form onSubmit={handleSubmit(submitAddZone)}>
              <div>
                <h5>General Info</h5>
                <div className="bg-gray">
                  <div className="row">
                    <article className="col-md-4">
                      <div className="row">
                        <div className="col-md-4">
                          <label>Wharf Job Type</label>
                        </div>
                        <div className="col-md-8 mb-4">
                          {
                            <Select
                              register={register}
                              data={lookupsList?.wharfJobTypes}
                              id="wharfJobType"
                              errors={errors}
                              type={type}
                              displayKey="description"
                              valueKey="id"
                              viewMode={viewMode}
                              defaultData={defaultData}
                            />
                          }
                        </div>
                      </div>
                    </article>

                    <article className="col-md-4">
                      <div className="row">
                        <div className="col-md-4">
                          <label>Job Window</label>
                        </div>
                        <div className="col-md-8 mb-4">
                          <Select
                            register={register}
                            data={lookupsList?.jobWindows}
                            id="jobWindow"
                            errors={errors}
                            type={type}
                            displayKey="description"
                            valueKey="jobWindow"
                            defaultData={defaultData}
                            viewMode={viewMode}
                          />
                        </div>
                      </div>
                    </article>

                    <article className="col-md-4">
                      <div className="row">
                        <div className="col-md-4">
                          <label>Vehicle Job Type</label>
                        </div>
                        <div className="col-md-8 mb-4">
                          {!viewMode ? (
                            <>
                              <select
                                className={getSelectClass(
                                  errors?.vehicleJobType
                                )}
                                id="vehicleJobType"
                                name="vehicleJobType"
                                {...register('vehicleJobType')}
                                disabled={type === 'view'}
                              >
                                <option value="">Please Select</option>
                                {lookupsList?.vehicleJobTypes?.map(
                                  (item, i) => (
                                    <option key={i} value={item.id}>
                                      {item.id} - {item.trailerType}
                                    </option>
                                  )
                                )}
                              </select>
                              {errors?.vehicleJobType && (
                                <p className="invalid-feedback">
                                  {errors?.vehicleJobType?.message}
                                </p>
                              )}{' '}
                            </>
                          ) : (
                            <h5>{defaultData.vehicleJobType}</h5>
                          )}
                        </div>
                      </div>
                    </article>
                  </div>
                </div>

                <div className="odd-row">
                  <h5>Container Info</h5>
                  <div className="row">
                    <article className="col-md-4">
                      <div className="row">
                        <div className="col-md-4">
                          <label>Dimension Unit</label>
                        </div>
                        <div className="col-md-8 mb-4">
                          {!viewMode ? (
                            <>
                              <select
                                className={getSelectClass(
                                  errors?.dimensionUnit
                                )}
                                id="dimensionUnit"
                                name="dimensionUnit"
                                {...register('dimensionUnit')}
                                disabled={type === 'view'}
                              >
                                <option value="">Please Select</option>
                                {lookupsList?.dimensionUnits?.map((item, i) => (
                                  <option key={i} value={item.id}>
                                    {item.id} - {item.description}
                                  </option>
                                ))}
                              </select>
                              {errors?.dimensionUnit && (
                                <p className="invalid-feedback">
                                  {errors?.dimensionUnit?.message}
                                </p>
                              )}
                            </>
                          ) : (
                            <h5>{defaultData.dimensionUnit}</h5>
                          )}
                        </div>
                      </div>
                    </article>

                    <article className="col-md-4">
                      <div className="row">
                        <div className="col-md-4">
                          <label>Type Size</label>
                        </div>
                        <div className="col-md-8 mb-4">
                          <Select
                            register={register}
                            data={lookupsList?.equipmentSizes}
                            id="typeSize"
                            errors={errors}
                            type={type}
                            displayKey="description"
                            valueKey="size"
                            defaultData={defaultData}
                            viewMode={viewMode}
                          />
                        </div>
                      </div>
                    </article>

                    <article className="col-md-8">
                      <h6>Weight Bar</h6>
                      <div className="row">
                        <div className="col-6">
                          <div className="row">
                            <div className="col-md-4">
                              <label>From Range</label>
                            </div>
                            <div className="col-md-8 mb-4">
                              <TextBox
                                register={register}
                                id="fromRange"
                                type="number"
                                errors={errors}
                                placeholder="From Range"
                                maxLength="10"
                                defaultData={defaultData}
                                viewMode={viewMode}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-6">
                          <div className="row">
                            <div className="col-md-4">
                              <label>To Range</label>
                            </div>
                            <div className="col-md-8 mb-4">
                              <TextBox
                                register={register}
                                id="toRange"
                                errors={errors}
                                placeholder="To Range"
                                maxLength="10"
                                defaultData={defaultData}
                                viewMode={viewMode}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </article>

                    <article className="col-md-4" style={{ marginTop: 22 }}>
                      <div className="row">
                        <div className="col-md-4">
                          <label>Handling</label>
                        </div>
                        <div className="col-md-8 mb-4">
                          <Select
                            register={register}
                            data={lookupsList?.distinctConducts}
                            // data={lookupsList?.cargoHandlings}
                            id="handling"
                            errors={errors}
                            type={type}
                            displayKey="description"
                            valueKey="id"
                            defaultData={defaultData}
                            viewMode={viewMode}
                          />
                        </div>
                      </div>
                    </article>
                  </div>
                </div>

                <div className="bg-gray">
                  <h5>Route Details</h5>
                  <div className="row">
                    <article className="col-md-4">
                      <div className="row">
                        <div className="col-md-4">
                          <label>Stage Movement Type</label>
                        </div>
                        <div className="col-md-8 mb-4">
                          {!viewMode ? (
                            <>
                              <select
                                className={getSelectClass(
                                  errors?.stageMovementType
                                )}
                                id="stageMovementType"
                                name="stageMovementType"
                                {...register('stageMovementType')}
                                disabled={type === 'view'}
                              >
                                <option value="">Please Select</option>
                                {lookupsList?.stageMovementTypes?.map(
                                  (item, i) => (
                                    <option key={i} value={item.transportStage}>
                                      {item.description}
                                    </option>
                                  )
                                )}
                              </select>
                              {errors?.stageMovementType && (
                                <p className="invalid-feedback">
                                  {errors?.stageMovementType?.message}
                                </p>
                              )}{' '}
                            </>
                          ) : (
                            <h5>{defaultData.stageMovementType}</h5>
                          )}
                        </div>
                      </div>
                    </article>

                    <article className="col-md-4">
                      <div className="row">
                        <div className="col-md-4">
                          <label>Wharf</label>
                        </div>
                        <div className="col-md-8 mb-4">
                          <Select
                            register={register}
                            data={lookupsList?.wharfCodes}
                            id="wharf"
                            errors={errors}
                            type={type}
                            displayKey="description"
                            valueKey="wharfCode"
                            defaultData={defaultData}
                            viewMode={viewMode}
                          />
                        </div>
                      </div>
                    </article>

                    <article className="col-md-4">
                      <div className="row">
                        <div className="col-md-4">
                          <label>Empty Dehire</label>
                        </div>
                        <div className="col-md-8 mb-4">
                          <Select
                            register={register}
                            data={lookupsList?.emptyDehires}
                            id="emptyDehire"
                            errors={errors}
                            type={type}
                            displayKey="description"
                            valueKey="code"
                            viewMode={viewMode}
                            defaultData={defaultData}
                          />
                        </div>
                      </div>
                    </article>

                    <article className="col-md-4">
                      <div className="row">
                        <div className="col-md-4">
                          <label>Remarks</label>
                        </div>
                        <div className="col-md-8 mb-4">
                          <TextBox
                            register={register}
                            id="remarks"
                            errors={errors}
                            placeholder="Remarks"
                            maxLength="100"
                            type="text"
                            defaultData={defaultData}
                            viewMode={viewMode}
                          />
                        </div>
                      </div>
                    </article>

                    {/* Zone */}
                    <article className="col-md-4">
                      <TextInputField
                        title="Zone"
                        id="zone"
                        register={register}
                        error={errors}
                        placeholder="Zone"
                        labelClassName=""
                      />
                    </article>
                  </div>
                </div>

                <section className="odd-row">
                  <h5>Location Details</h5>
                  <div className="row">
                    <article className="col-md-6">
                      <TextInputField
                        title="Location 1 Area Code"
                        id="location1AreaCode"
                        register={register}
                        error={errors}
                        labelClassName=""
                      />
                    </article>
                    <article className="col-md-6">
                      <TextInputField
                        title="Location 1 Description"
                        id="location1Description"
                        register={register}
                        error={errors}
                        labelClassName=""
                      />
                    </article>
                    <article className="col-md-6">
                      <TextInputField
                        title="Location 2 Area Code"
                        id="location2AreaCode"
                        register={register}
                        error={errors}
                        labelClassName=""
                      />
                    </article>
                    <article className="col-md-6">
                      <TextInputField
                        title="Location 2 Description"
                        id="location2Description"
                        register={register}
                        error={errors}
                        labelClassName=""
                      />
                    </article>
                  </div>
                </section>

                <section className="odd-row">
                  <h4>Rate Detail</h4>
                  <div className="row">
                    <article className="col-md-4">
                      <div className="row">
                        <div className="col-md-4">
                          <label>Rates</label>
                        </div>
                        <div className="col-md-8 mb-4">
                          {!viewMode ? (
                            <>
                              <select
                                className={getSelectClass(errors?.rateType)}
                                id="rateType"
                                name="rateType"
                                {...register('rateType')}
                                disabled={type === 'view'}
                              >
                                <option value="">Please Select</option>
                                {Object.keys(lookupsList?.rateTypeEnums)?.map(
                                  (item, i) => (
                                    <option key={i} value={item}>
                                      {lookupsList?.rateTypeEnums[item]}
                                    </option>
                                  )
                                )}
                              </select>
                              {errors?.rateType && (
                                <p className="invalid-feedback">
                                  {errors?.rateType?.message}
                                </p>
                              )}
                            </>
                          ) : (
                            <h5>{defaultData.rateType}</h5>
                          )}
                        </div>
                      </div>
                    </article>

                    <article className="col-md-4">
                      <div className="row">
                        <div className="col-md-4">
                          <label>Rate Code</label>
                        </div>
                        <div className="col-md-8 mb-4">
                          {!viewMode ? (
                            <>
                              <select
                                className={getSelectClass(errors?.rateCode)}
                                id="rateCode"
                                name="rateCode"
                                {...register('rateCode')}
                                disabled={type === 'view'}
                              >
                                <option value="">Please Select</option>
                                {lookupsList?.rateCodes?.map((item, i) => (
                                  <option key={i} value={item.rateCode}>
                                    {item.rateCode} - {item.description}
                                  </option>
                                ))}
                              </select>
                              {errors?.rateCode && (
                                <p className="invalid-feedback">
                                  {errors?.rateCode?.message}
                                </p>
                              )}
                            </>
                          ) : (
                            <h5>{defaultData.rateCode}</h5>
                          )}
                        </div>
                      </div>
                    </article>

                    <article className="col-md-4">
                      <div className="row">
                        <div className="col-md-4">
                          <label>Description</label>
                        </div>
                        <div className="col-md-8 mb-4">
                          <TextBox
                            register={register}
                            id="description"
                            errors={errors}
                            placeholder="description"
                            maxLength="100"
                            viewMode={viewMode}
                            defaultData={defaultData}
                          />
                        </div>
                      </div>
                    </article>

                    <article className="col-md-4">
                      <div className="row">
                        <div className="col-md-4">
                          <label>Sell Rate</label>
                        </div>
                        <div className="col-md-8 mb-4">
                          <TextBox
                            register={register}
                            id="sellRate"
                            errors={errors}
                            placeholder="Sell Rate"
                            maxLength="20"
                            type="number"
                            defaultData={defaultData}
                            viewMode={viewMode}
                          />
                        </div>
                      </div>
                    </article>
                    <article className="col-md-4">
                      <div className="row">
                        <div className="col-md-4">
                          <label>Consignee</label>
                        </div>
                        <div className="col-md-8 mb-4">
                          <Select
                            register={register}
                            data={consigneeData}
                            id="consignee"
                            errors={errors}
                            type={type}
                            displayKey="consigneeName"
                            valueKey="ccConsigneeAddressId"
                            defaultData={defaultData}
                            viewMode={viewMode}
                          />
                        </div>
                      </div>
                    </article>
                  </div>
                </section>

                {!viewMode && (
                  <div className="row">
                    <div className="col-12 text-right">
                      <button className="btn btn-primary" type="submit">
                        {formType === 'add' && profileFormType !== 'editForm'
                          ? 'Add'
                          : 'Update'}
                      </button>
                      <button className="btn btn-secondary ms-2" type="reset">
                        Reset
                      </button>

                      <button
                        className="btn btn-outline-danger ms-2 float-end"
                        type="button"
                        onClick={() => setEnableForm(false)}
                      >
                        Close
                      </button>
                    </div>
                  </div>
                )}
                {formSuccess && (
                  <div className="text-success">
                    Form has submitted successfully
                  </div>
                )}

                {formSubmitError && (
                  <div className="text-danger">Unable to submit the form</div>
                )}
              </div>
            </form>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  )
}

export default AddCRDData
