import { yupResolver } from '@hookform/resolvers/yup'
import React from 'react'
import { useForm } from 'react-hook-form'
import * as Yup from 'yup'
import { GenerateFormField } from '../../../../../components/Common/Form'
import { createYupSchema } from '../../../../../components/Common/yupValidatorCreator'

const CRCEdit = ({ formData, onFormSubmit, children }) => {
  let newValidations = formData.reduce(createYupSchema, {})
  const validationSchema = Yup.object().shape(newValidations)

  const {
    handleSubmit,
    register,
    formState: { errors },
    getValues,
    watch,
    setValue,
    control,
  } = useForm({
    resolver: yupResolver(validationSchema),
    mode: 'onSubmit',
    reValidateMode: 'onChange',
  })

  return (
    <form onSubmit={handleSubmit(onFormSubmit)}>
      <div className="row">
        {formData.map((field) => (
          <React.Fragment key={field.fieldId}>
            <div className="col-md-2 mb-3">
              <label>{field.name}</label>
            </div>
            <div className="col-md-10 mb-3">
              <GenerateFormField
                data={field}
                control={control}
                register={register}
                err={errors}
                watch={watch}
                setValue={setValue}
                getValues={getValues}
              />
            </div>
          </React.Fragment>
        ))}
        {children}
      </div>
    </form>
  )
}

export default CRCEdit
