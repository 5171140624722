import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import * as Toastify from '../../../../components/utils/Toastify'
import config from '../../../../config'
import { queryKeys } from '../../../../helper/hooks/apiHooks'
import ContainerStages from '../../containers/stage-tracker/ContainerStages'
import { ordersOperationsContext } from '../context/orderOperationsContext'
import { OrderContainer } from './OrdersContainers/OrderContainer'
import OrdersCustomerDetails from './OrdersCustomerDetails/OrdersCustomerDetails'
import AvailabilityAlerts from './OrdersCustomerDetails/components/AvailabilityAlerts'
import AcceptOrderButton from './components/AcceptOrderButton'

const OrdersOperations = (props) => {
  const { type } = props
  const [customerOrderData, setCustomerOrderData] = useState(null)
  const [containerDataList, setContainerData] = useState({})
  const [accountingData, setAccountingData] = useState({})
  const [operationsData, setOperationsData] = useState({
    customerId: 0,
    orderCustomerDetailsId: 0,
    orderContainerDetailsId: 0,
    orderContainerDeliveryDetailsId: 0,
    orderContainerDehireDetailsId: 0,
    orderContainerPickupDetailsId: 0,
    orderContainerVehicleDetailsId: 0,
    orderAccountingId: 0,
  })
  const [containerDetailsId, setContainerDetailsId] = useState(null)

  const { id } = useParams()

  const getContainerData = (orderCustomerDetailsId) => {
    const url =
      config.api.baseUrl +
      '/operations/order/container?orderCustomerDetailsId=' +
      orderCustomerDetailsId
    const requestOptions = {
      method: 'GET',
      headers: { Authorization: 'Bearer ' + localStorage.token },
    }
    fetch(url, requestOptions)
      .then((response) => response.json())
      .then((data) => {
        setContainerData(data)
      })
      .catch((error) => {
        Toastify.Error(error?.response?.data?.error)
      })
  }

  const getAccountingData = async (orderCustomerDetailsId) => {
    const url =
      config.api.baseUrl +
      '/operations/order/accounting?orderCustomerDetailsId=' +
      orderCustomerDetailsId
    const requestOptions = {
      method: 'GET',
      headers: { Authorization: 'Bearer ' + localStorage.token },
    }
    await fetch(url, requestOptions)
      .then((response) => response.json())
      .then((data) => {
        setAccountingData(data)
        setOperationsData((prevVal) => ({
          ...prevVal,
          orderAccountingId: data.orderAccountingId,
        }))
      })
      .catch((error) => {
        Toastify.Error(error?.response?.data?.error)
      })
  }

  useEffect(() => {
    if (id && (type === 'view' || type === 'edit')) {
      const getCustomerOrderData = async (orderCustomerDetailsId) => {
        const url =
          config.api.baseUrl +
          '/operations/order/customer/' +
          orderCustomerDetailsId
        const requestOptions = {
          method: 'GET',
          headers: { Authorization: 'Bearer ' + localStorage.token },
        }
        await fetch(url, requestOptions)
          .then((response) => response.json())
          .then((data) => {
            setCustomerOrderData(data)
            setOperationsData((prevVal) => ({
              ...prevVal,
              customerId: data?.customerId,
              orderCustomerDetailsId:
                data?.orderVesselDetails?.orderCustomerDetailsId,
              orderVesselDetailsId:
                data?.orderVesselDetails?.orderVesselDetailsId,
            }))
            getAccountingData(id)
          })
          .catch((error) => {
            Toastify.Error(error?.response?.data?.error)
          })
      }
      getCustomerOrderData(id)
      getContainerData(id)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id])

  return (
    <main className="container-fluid pb-4">
      <ordersOperationsContext.Provider value={operationsData}>
        <div className="row">
          <div className="col-md-4">
            <OrdersCustomerDetails
              setOperationsData={setOperationsData}
              type={type}
              customerOrderData={customerOrderData}
              setCustomerOrderData={setCustomerOrderData}
              accountingData={accountingData}
            />
            <AvailabilityAlerts customerOrderData={customerOrderData} />
            <AcceptOrderButton
              customerOrderData={customerOrderData}
              setCustomerOrderData={setCustomerOrderData}
            />
          </div>
          <div className="col-md-8">
            <OrderContainer
              setOperationsData={setOperationsData}
              type={type}
              containerDataList={containerDataList}
              getContainerData={getContainerData}
              setContainerDetailsId={setContainerDetailsId}
              customerOrderData={customerOrderData}
            />
            {containerDetailsId && (
              <div className="mt-3" style={{ maxWidth: '95vw' }}>
                <ContainerStages
                  queryData={{
                    key: queryKeys.cerebro.order.container.stageTracker(
                      containerDetailsId
                    ),
                    url: `/container/stage-tracker?orderContainerDetailsById=${containerDetailsId}`,
                  }}
                  queryKeyToInvalidate={queryKeys.cerebro.order.container.stageTracker(
                    containerDetailsId
                  )}
                />
              </div>
            )}
          </div>
        </div>
      </ordersOperationsContext.Provider>
    </main>
  )
}

export default OrdersOperations
