import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  getSelectedChat,
  setSelectedChat,
} from '../../../../../store/slices/enigmaChat-slice'
import { ParticipantsNames, ParticipantsProfilePics } from '../../utils'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons'
import useMediaQuery, {
  mobileQuery,
} from '../../../../../helper/hooks/useMediaQuery'

export default function Header() {
  const isMobile = useMediaQuery(mobileQuery)
  const dispatch = useDispatch()
  const conversation = useSelector(getSelectedChat)
  const { chatParticipantResponseDTOS, chatIdentity } = conversation

  const handleBackClick = () => {
    dispatch(setSelectedChat(null))
  }

  return (
    <div className="d-flex feed-header">
      <ParticipantsProfilePics
        chatParticipantResponseDTOS={chatParticipantResponseDTOS}
        className="heading-avatar-icon"
      />
      <div className="ms-4">
        <span className="heading-name-meta">
          <ParticipantsNames
            chatParticipantResponseDTOS={chatParticipantResponseDTOS}
          />
        </span>
        <span className="text-dark">{chatIdentity}</span>
      </div>
      {isMobile ? (
        <button
          title="Back"
          onClick={handleBackClick}
          className="btn ms-auto d-flex align-items-center h-auto"
        >
          <FontAwesomeIcon
            style={{ color: '#0B252B' }}
            className="fa"
            icon={faChevronLeft}
          />
        </button>
      ) : null}
    </div>
  )
}
