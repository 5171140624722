import config from '../../config'

const baseURL = config.api.baseUrl

const defaultHeader = {
  Authorization: 'Bearer ' + localStorage.token,
}

const getRequestOptions = {
  method: 'GET',
  headers: defaultHeader,
}

export async function fetchTrucks() {
  const response = await fetch(
    `${baseURL}${config.api.getVehicleCards}`,
    getRequestOptions
  )
  return await response.json()
}

export async function getVehicleByStatus() {
  const response = await fetch(
    `${baseURL}${config.api.getVehicleCards}?vehicleType=TRUCK&vehicleStageStatus=AVAILABLE&vehicleDriverStatus=UN_ASSIGNED`,
    getRequestOptions
  )
  return await response.json()
}

export async function assignVehicleToDriver(driverCardId, vehicleTruckId) {
  const response = await fetch(
    `${baseURL}${config.api.assignVehicleTruckToDriver}?driverCardId=${driverCardId}&vehicleTruckId=${vehicleTruckId}`,
    {
      method: 'POST',
      headers: defaultHeader,
      body: {},
    }
  )

  if (!response.ok) throw await response.json()

  return await response.json()
}
