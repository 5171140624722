const zoneCode = {
  name: 'Zone Code',
  path: '/customer/dataManagement/zoneCode',
  req: '/',
  columns: 2,
  fields: [
    {
      name: 'Zone ID',
      label: 'Zone ID',
      type: 'text',
      validation: 'Alphabetic',
      fieldId: 'zoneCode',
      id: 'zoneCode',
      validationType: 'string',
      validations: [
        {
          type: 'required',
          params: ['this field is required'],
        },
        {
          type: 'max',
          params: [30, 'Zone ID cannot be more than 30 characters'],
        },
      ],
    },
    {
      name: 'KM Range',
      label: 'KM Range',
      type: 'text',
      validation: 'Alphabetic',
      fieldId: 'kmRange',
      id: 'kmRange',
      validationType: 'string',
      validations: [
        {
          type: 'required',
          params: ['this field is required'],
        },
        {
          type: 'max',
          params: [20, 'KM Range cannot be more than 20 characters'],
        },
      ],
    },
    {
      name: 'Category Name',
      label: 'Category Name',
      type: 'text',
      validation: 'Alphabetic',
      fieldId: 'categoryName',
      id: 'categoryName',
      validationType: 'string',
      validations: [
        {
          type: 'required',
          params: ['this field is required'],
        },
        {
          type: 'max',
          params: [50, 'Description cannot be more than 50 characters'],
        },
      ],
    },
  ],
}
export default zoneCode
