import React from 'react'
import { ContextMenu, MenuItem } from 'react-contextmenu'
import { useRouter } from '../../../../../helper/hooks/useRouter'
import {
  CREATE_JOB_MODAL,
  LOAD_NOTES_MODAL,
  SERVICE_RATE_MODAL,
  STAGE_TRACKER_MODAL,
  VIEW_LOAD_MODAL,
} from '../../data/allocation-planning.constants'

export default function Menu({ selectedRow, setModal }) {
  const { history } = useRouter()

  return (
    <ContextMenu id="row-click-data">
      <MenuItem onClick={() => setModal(CREATE_JOB_MODAL)}>
        Create Planned Jobs
      </MenuItem>
      <MenuItem
        onClick={() =>
          history.push(
            `/live-load-tracking?containerStageTrackerId=${selectedRow.containerStageTrackerId}`
          )
        }
      >
        Live Load Tracking
      </MenuItem>
      <MenuItem
        onClick={() =>
          history.push(`/Operations/view/${selectedRow.orderCustomerDetailsId}`)
        }
      >
        View Order
      </MenuItem>
      <MenuItem>Live Load Order</MenuItem>
      <MenuItem onClick={() => setModal(VIEW_LOAD_MODAL)}>View Load</MenuItem>
      <MenuItem onClick={() => setModal(LOAD_NOTES_MODAL)}>Notes</MenuItem>

      <MenuItem onClick={() => setModal(SERVICE_RATE_MODAL)}>
        Service Rates
      </MenuItem>
      <MenuItem onClick={() => setModal(STAGE_TRACKER_MODAL)}>
        Stage Tracker
      </MenuItem>
      <MenuItem>Edit Load</MenuItem>
      <MenuItem>Dispatch Bulk Loads</MenuItem>
      <MenuItem>Copy Load</MenuItem>
      <MenuItem>Send Tracking Message</MenuItem>
      <MenuItem>Enable Autotracking</MenuItem>
      <MenuItem>Send Message</MenuItem>
      <MenuItem>Log Track Call</MenuItem>
      <MenuItem>Load Log Map</MenuItem>
      <MenuItem>Send to Accounting</MenuItem>
      <MenuItem>View Load Docs</MenuItem>
      <MenuItem>Send Load Docs</MenuItem>
      <MenuItem>Request Driver Docs</MenuItem>
      <MenuItem>Archive Load</MenuItem>
      <MenuItem>Cancel Load</MenuItem>
    </ContextMenu>
  )
}
