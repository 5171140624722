import React from 'react'
import { getLookupDataState } from '../../../../store/slices/lookup-slice'
import { useDispatch, useSelector } from 'react-redux'
import {
  selectStatus,
  setStatus,
} from '../../../../store/slices/allocation/drivers'

export default function DriverStatusOptions() {
  const status = useSelector(selectStatus)

  const dispatch = useDispatch()

  const lookupsList = useSelector(getLookupDataState)

  return (
    <select
      className="form-select mb-2"
      value={status}
      onChange={(e) => dispatch(setStatus(e.target.value))}
    >
      <option value="">All</option>
      {lookupsList?.driverStatusEnums.map((item) => (
        <option key={item} value={item}>
          {item}
        </option>
      ))}
    </select>
  )
}
