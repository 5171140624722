import React from 'react'
import axios from 'axios'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { toast } from 'react-toastify'
import * as Yup from 'yup'

import { TextBox } from '../../../../components/utils/TextBox'
import config from '../../../../config'

const { api } = config

const ApprovedPageForm = ({
  defaultValues,
  wmsDetailsId,
  setLoading,
  getWms,
  setShowUPLDetailsModal,
}) => {
  const validationSchema = Yup.object().shape({
    clientExpDate: Yup.string().required('Client Expiry Date is required'),
  })
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: { ...defaultValues },
    reValidateMode: 'onChange',
  })
  const handleDispatch = (formData) => {
    setLoading(true)
    const url = `${api.baseUrl}${api.wms.unpacked.updateToDispatch}${wmsDetailsId}`
    const data = { clientExpDate: `${formData.clientExpDate}T00:00:00` }
    axios({
      method: 'PUT',
      url: url,
      headers: {
        Authorization: 'Bearer ' + localStorage.token,
      },
      params: data,
    })
      .then(function (response) {
        reset()
        toast.info('Successully Updated')
        getWms('APPROVED')
      })
      .catch(function (error) {
        toast.error('Something went wrong!')
      })
      .finally(() => {
        setLoading(false)
        setShowUPLDetailsModal(false)
      })
  }
  return (
    <form
      autoComplete="off"
      className="p-3"
      onSubmit={handleSubmit(handleDispatch)}
    >
      <div className="fs-5">
        <div className="d-flex p-2">
          <div className="col-md-4">Expected Client Expiry Date:</div>
          <div className="col-md-4">
            <div className="">
              <TextBox
                register={register}
                id="clientExpDate"
                errors={errors}
                type="date"
              />
            </div>
          </div>
        </div>
      </div>
      <div className="flex-content p-2">
        <div className=""></div>
        <button className="btn btn-primary">Dispatch</button>
      </div>
    </form>
  )
}

export default ApprovedPageForm
