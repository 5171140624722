import {
  faArrowsRotate,
  faRightFromBracket,
  faSliders,
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import isEmpty from 'lodash/isEmpty'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useHistory } from 'react-router-dom'
import { moduleNameList } from '../../lib/navigation.list'
import { getCurrentRouteData } from '../../store/slices/route-slice'
import { toggleSidebar } from '../../store/slices/ui-slice'
import IconButton from '../styles/Buttons/IconButton'
import NavMenu from './NavMenu'

const ModuleLinks = () => {
  const route = useSelector(getCurrentRouteData)

  return (
    <div
      className="d-flex align-items-center gap-4 justify-content-center"
      style={{ flexGrow: 1 }}
    >
      {!isEmpty(route) &&
        moduleNameList.map((moduleDashboard) => {
          const { showSideBarOf = 'dashboard' } = route

          const isActive = showSideBarOf === moduleDashboard.link

          return (
            <Link
              key={moduleDashboard.link}
              to={`/${moduleDashboard.link}`}
              target="_blank"
              title={moduleDashboard.title}
              style={{ textDecoration: 'none' }}
            >
              <span
                className={`module-name ${isActive ? 'active-module' : ''}`}
              >
                {moduleDashboard.title}
              </span>
            </Link>
          )
        })}
    </div>
  )
}

const Header = () => {
  const dispatch = useDispatch()

  const { userDetails } = JSON.parse(localStorage.data)
  let history = useHistory()

  const logoutApp = () => {
    localStorage.clear()
    history.push('/')
  }

  return (
    <div className="db-header">
      <div className="dashboard_logo">
        <button className="unset-all" onClick={() => dispatch(toggleSidebar())}>
          <FontAwesomeIcon size="1x" className="fa" icon={faSliders} />
        </button>
      </div>
      <NavMenu />

      <ModuleLinks />

      <div className="header-right-part">
        <div className="admin-nameId-pick">
          <span className="admin">
            {userDetails.profileGroup.toUpperCase()}
          </span>
          <span className="uname">
            {userDetails.firstName} {userDetails.lastName.toUpperCase()}
          </span>
        </div>
        <div className="uid">DL : {userDetails.id}</div>

        <IconButton
          onClick={() => window.location.reload(false)}
          title="Reload"
        >
          <FontAwesomeIcon size="1x" icon={faArrowsRotate} />
        </IconButton>
        <IconButton onClick={logoutApp} title="logout from the application">
          <FontAwesomeIcon size="1x" icon={faRightFromBracket} />
        </IconButton>
      </div>
    </div>
  )
}

export default Header
