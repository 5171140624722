import moment from 'moment/moment'

export const MAX_DATE_STRING = moment('2100-01-01').format('YYYY-MM-DD')
export const MAX_DATE_OBJECT = new Date(2100, 11, 31)

const VEHICLE_TYPES = {
  TRAILER: 'trailer',
  TRUCK: 'truck',
  FORKLIFT: 'forklift',
  MISCELLANEOUS: 'miscellaneous',
}

const headers = {}

headers[VEHICLE_TYPES.TRUCK] = {
  vehicleTruckId: 'ID',
  licensePlatNo: 'License Plate No',
  truckType: 'Truck Type',
  vehicleSize: 'Truck Size',
  colour: 'Colour',
  model: 'Model',
  registrationRenewalDate: 'Registration Renewal Date',
}

headers[VEHICLE_TYPES.TRAILER] = {
  vehicleTrailerId: 'ID',
  licensePlatNo: 'License Plate No',
  trailerType: 'Trailer Type',
  vehicleSize: 'Trailer Size',
  colour: 'Colour',
  model: 'Model',
  registrationRenewalDate: 'Registration Renewal Date',
}

headers[VEHICLE_TYPES.FORKLIFT] = {
  vehicleForkLiftId: 'ID',
  licensePlatNo: 'License Plate No',
  manufacturedDate: 'Manufactured Date',
  vehicleSize: 'ForkLift Size',
  colour: 'Colour',
  model: 'Model',
  registrationRenewalDate: 'Registration Renewal Date',
}

headers[VEHICLE_TYPES.MISCELLANEOUS] = {
  vehicleMiscellaneousId: 'ID',
  licensePlatNo: 'License Plate No',
  manufacturedDate: 'Manufactured Date',
  vehicleSize: 'Size',
  colour: 'Colour',
  model: 'Model',
  registrationRenewalDate: 'Registration Renewal Date',
}

const VEHICLE_TYPES_UNIQUE_IDS = {
  [VEHICLE_TYPES.TRUCK]: 'vehicleTruckId',
  [VEHICLE_TYPES.TRAILER]: 'vehicleTrailerId',
  [VEHICLE_TYPES.FORKLIFT]: 'vehicleForkLiftId',
  [VEHICLE_TYPES.MISCELLANEOUS]: 'vehicleMiscellaneousId',
}

export { VEHICLE_TYPES, headers, VEHICLE_TYPES_UNIQUE_IDS }
