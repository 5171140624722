import React from 'react'
import {
  useLazyGetTemplateData,
  useMasterTemplate,
  useUserTemplateListData,
} from '../../../../helper/hooks/api/allocations'
import { Button, Spinner } from 'react-bootstrap'
import SearchableSelectField from '../../../../components/Common/Form/Fields/SearchableSelectField'

export default function CopyTemplateField({ form }) {
  const { control, setValue, watch, getValues } = form
  const selectedTemplateIdToCopy = watch('copyFromTemplateId')
  const { data: userTemplateList, error, isLoading } = useUserTemplateListData()
  const { isLoading: isLoadingMasterTemplate, data: masterData } =
    useMasterTemplate()
  const fetchTemplateMutation = useLazyGetTemplateData()

  const copyTemplate = () => {
    const templateId = getValues('copyFromTemplateId')
    fetchTemplateMutation.mutate(templateId, {
      onSuccess: (response) => {
        setValue(
          'selectedFields',
          response?.userTemplateFields.map((f) => ({
            fieldName: f.fieldName,
            fieldId: f.masterTemplateFieldId,
          }))
        )
      },
    })
  }

  return (
    <div className="mb-3">
      {(isLoading || isLoadingMasterTemplate) && <Spinner />}
      {!error && Array.isArray(userTemplateList) && (
        <SearchableSelectField
          md={8}
          control={control}
          name="copyFromTemplateId"
          label="Copy Template"
          setValue={setValue}
          options={userTemplateList}
          optionValueKey="id"
          getOptionLabel={(option) =>
            `${
              masterData?.masterTemplateFunctions?.find(
                (channel) => channel.id === option.masterTemplateFunctionId
              )?.channelName
            } - ${option.templateName}`
          }
          disabled={fetchTemplateMutation.isPending}
        />
      )}
      <Button
        type="button"
        disabled={!selectedTemplateIdToCopy || fetchTemplateMutation.isPending}
        onClick={copyTemplate}
      >
        {fetchTemplateMutation.isPending && <Spinner />}
        Copy
      </Button>
    </div>
  )
}
