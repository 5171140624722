import DataFormTable from '../../modules/cerebro/customer/data-management/components/DataFormTable/DataFormTable'

const operationsDataMgmt = [
  {
    name: 'Cargo Handling',
    path: '/customer/dataManagement/cargoHandling',
    component: DataFormTable,
    staticProps: {
      formName: 'Cargo Handling',
      module: 'customer',
      subModule: 'cargoHandling',
      uniqueReferenceKey: 'id',
      lookupKeyName: 'cargoHandlings',
    },
    includeHeaderFooter: true,
  },
  {
    name: 'Cargo Type',
    path: '/customer/dataManagement/cargoType',
    component: DataFormTable,
    staticProps: {
      formName: 'Cargo Type',
      module: 'customer',
      subModule: 'cargoType',
      uniqueReferenceKey: 'cargoType',
      lookupKeyName: 'cargoTypes',
    },
    includeHeaderFooter: true,
  },
  {
    name: 'Container Category',
    path: '/customer/dataManagement/containerCategory',
    component: DataFormTable,
    staticProps: {
      formName: 'Container Category',
      module: 'customer',
      subModule: 'containerCategory',
      uniqueReferenceKey: 'id',
      lookupKeyName: 'containerCategories',
    },
    includeHeaderFooter: true,
  },
  {
    name: 'Container Status',
    path: '/customer/dataManagement/containerStatus',
    component: DataFormTable,
    staticProps: {
      formName: 'Container Status',
      module: 'customer',
      subModule: 'containerStatus',
      uniqueReferenceKey: 'id',
      lookupKeyName: 'containerStatus',
    },
    includeHeaderFooter: true,
  },
  {
    name: 'Equipment Size',
    path: '/customer/dataManagement/equipment_size',
    component: DataFormTable,
    staticProps: {
      formName: 'Equipment Size',
      module: 'customer',
      subModule: 'equipment_size',
      uniqueReferenceKey: 'size',
      lookupKeyName: 'equipmentSizes',
    },
    includeHeaderFooter: true,
  },
  {
    name: 'Hold Code',
    path: '/customer/dataManagement/holdCode',
    component: DataFormTable,
    staticProps: {
      formName: 'Hold Code',
      module: 'customer',
      subModule: 'holdCode',
      uniqueReferenceKey: 'id',
      lookupKeyName: 'holdCodes',
    },
    includeHeaderFooter: true,
  },
  {
    name: 'IMO Code',
    path: '/customer/dataManagement/IMOCode',
    component: DataFormTable,
    staticProps: {
      formName: 'IMO Code',
      module: 'customer',
      subModule: 'IMOCode',
      uniqueReferenceKey: 'imoCode',
      lookupKeyName: 'imoCodes',
    },
    includeHeaderFooter: true,
  },
  {
    name: 'Package Type',
    path: '/customer/dataManagement/package_Type',
    component: DataFormTable,
    staticProps: {
      formName: 'Package Type',
      module: 'customer',
      subModule: 'package_Type',
      uniqueReferenceKey: 'id',
      lookupKeyName: 'packageTypes',
    },
    includeHeaderFooter: true,
  },
  {
    name: 'Document Type',
    path: '/customer/dataManagement/document_type',
    component: DataFormTable,
    staticProps: {
      formName: 'Document Type',
      module: 'customer',
      subModule: 'document_type',
      uniqueReferenceKey: 'id',
      lookupKeyName: 'documentTypes',
    },
    includeHeaderFooter: true,
  },
  {
    name: 'Distinct Conduct',
    path: '/customer/dataManagement/distinct_Conduct',
    component: DataFormTable,
    staticProps: {
      formName: 'Special Handling',
      module: 'customer',
      subModule: 'distinct_Conduct',
      uniqueReferenceKey: 'id',
      lookupKeyName: 'distinctConducts',
    },
    includeHeaderFooter: true,
  },
  {
    name: 'Stock Room',
    path: '/customer/dataManagement/stockRoom',
    component: DataFormTable,
    staticProps: {
      formName: 'Stock Room',
      module: 'customer',
      subModule: 'stockRoom',
      uniqueReferenceKey: 'id',
      lookupKeyName: 'stockRooms',
    },
    includeHeaderFooter: true,
  },
  {
    name: 'Dimension Unit',
    path: '/customer/dataManagement/dimension_unit',
    component: DataFormTable,
    staticProps: {
      formName: 'Dimension Unit',
      module: 'customer',
      subModule: 'dimension_unit',
      uniqueReferenceKey: 'id',
      lookupKeyName: 'dimensionUnits',
    },
    includeHeaderFooter: true,
  },
  {
    name: 'Vessel Details',
    path: '/customer/dataManagement/vesselDetails',
    component: DataFormTable,
    staticProps: {
      formName: 'Vessel Details',
      module: 'customer',
      subModule: 'vesselDetails',
      uniqueReferenceKey: 'vesselid',
      lookupKeyName: 'vesselDetails',
    },
    includeHeaderFooter: true,
  },
  {
    name: 'Special Services',
    path: '/customer/dataManagement/special_service',
    component: DataFormTable,
    staticProps: {
      formName: 'Special Services',
      module: 'customer',
      subModule: 'special_service',
      uniqueReferenceKey: 'id',
      lookupKeyName: 'specialServices',
    },
    includeHeaderFooter: true,
  },
]

export { operationsDataMgmt }
