import toasts from './Toastify'

export const fileDownload = function (response) {
  try {
    const link = window.document.createElement('a')
    link.style.display = 'none'
    link.href = response
    link.setAttribute('target', '_blank')
    link.setAttribute('download', true)
    window.document.body.appendChild(link)
    link.click()
    window.document.body.removeChild(link)
  } catch (err) {
    toasts.Error(err?.message || 'Some error occurred while downloading file')
  }
}
