import { yupResolver } from '@hookform/resolvers/yup'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import isEmpty from 'lodash/isEmpty'
import React, { useState } from 'react'
import { Button, Col, Form, Row, Spinner } from 'react-bootstrap'
import { useForm } from 'react-hook-form'
import * as Yup from 'yup'
import Select from '../../../../../components/Common/Form/Fields/Select'
import Table from '../../../../../components/Common/Tanstack-table/Table'
import { Success } from '../../../../../components/utils/Toastify'
import fetchService from '../../../../../components/utils/fetchService'
import config from '../../../../../config'
import useTableColumnsWithEditDelete from '../hooks/useTableColumnsWithEditDelete'

const defaultOptionsConfig = {
  url: `${config.api.customerCardsList}?active=true`,
  labelKey: 'customerName',
  valueKey: 'customerCardId',
}

/**
 * Represents a form component for copying details.
 *
 * @typedef {Object} CopyDetailsFormProps
 * @property {Array<string>} colKeys - An array of column keys.
 * @property {Object} [optionsConfig=defaultOptionsConfig] - Configuration options for the form. Defaults to `defaultOptionsConfig`.
 * @property {string} url - The URL to post and copy from. Base url
 * @property {string} profileIdKey - The key for the profile ID.
 * @property {string} profileId - The profile ID.
 * @property {function} invalidateQuery - key to invalidate a query.
 * @property {React.Component} EditModalComponent - The Edit Modal component.
 *
 * @param {CopyDetailsFormProps} props - The properties passed to the component.
 * @returns {JSX.Element} The rendered form component.
 */
export default function CopyDetailsForm({
  colKeys,
  optionsConfig = defaultOptionsConfig,
  baseUrl,
  profileIdKey,
  profileId,
  invalidateQuery,
  EditModalComponent,
}) {
  const queryClient = useQueryClient()
  const [details, setDetails] = useState([])
  const [columns, selected, unSelect] = useTableColumnsWithEditDelete({
    fields: colKeys,
    setDetails,
  })

  const { data: options, isLoading } = useQuery({
    queryKey: [optionsConfig.url],
    queryFn: async () =>
      await fetchService({
        url: optionsConfig.url,
      }),
  })

  const validationSchema = Yup.object().shape({
    from: Yup.string().required('This field is required'),
  })

  const {
    register,
    handleSubmit,
    formState: { errors },
    setError,
  } = useForm({
    resolver: yupResolver(validationSchema),
    reValidateMode: 'onChange',
  })

  const getRateDetailsById = (values) => {
    const { from } = values
    fetchService({
      url: `${baseUrl}/copy/${from}`,
      method: 'POST',
      body: {},
      onSuccessCallback: (response) => {
        setDetails(response.map((c) => ({ ...c, [profileIdKey]: profileId })))
      },
    }).catch((err) => {
      console.log(err)
      if (Array.isArray(err?.data) && err.data[0])
        setError('from', {
          type: 'custom',
          message: err.data[0].message,
        })
      setDetails([])
    })
  }

  const mutation = useMutation({
    mutationFn: async (body) => {
      return await fetchService({
        url: `${baseUrl}/multi`,
        method: 'POST',
        body,
      })
    },
    onError: (error) => {
      if (error?.data && Array.isArray(error.data)) setError(error.data)
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [invalidateQuery],
      })
      Success('Copied Successfully')
      setDetails([])
    },
  })

  const copyZoneProfile = () => {
    mutation.mutate(details)
  }

  return (
    <div className="my-3">
      <h3>Copy CRD profile</h3>
      <Form onSubmit={handleSubmit(getRateDetailsById)}>
        <Row>
          {isLoading ? (
            <Col>
              <Spinner />
            </Col>
          ) : (
            <Select
              register={register}
              name="from"
              optionLabelKey={optionsConfig.labelKey}
              optionValueKey={optionsConfig.valueKey}
              errors={errors}
              options={options}
            />
          )}
        </Row>
        <Button type="submit">Copy</Button>
      </Form>
      {!isEmpty(details) && (
        <div className="my-3">
          <Table
            showGlobalSearch={false}
            showTableBottom={false}
            columns={columns}
            data={details}
          />
          {!isEmpty(mutation.error) &&
            mutation.error.data?.map(({ key, message }, i) => (
              <p key={`${key}-${i}`} className="text-danger">
                {key} - {message}
              </p>
            ))}
          <div className="d-flex my-3">
            <Button onClick={copyZoneProfile}>Submit CRD</Button>
          </div>
          <hr className="my-3" />
        </div>
      )}
      {selected.data ? (
        <EditModalComponent
          handleClose={() => {
            unSelect()
          }}
          selectedDetail={selected.data}
          profileId={profileId}
          profileIdKey={profileIdKey}
          handleOnSubmit={(formData) => {
            setDetails((prev) => prev.toSpliced(selected.index, 1, formData))
            unSelect()
          }}
        />
      ) : null}
    </div>
  )
}
