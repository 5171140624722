import React from 'react'
import { GoogleMap, MarkerF } from '@react-google-maps/api'

const Map = (positionProp) => {
  const containerStyle = {
    width: '100%',
    height: '300px',
  }

  return (
    <GoogleMap
      mapContainerStyle={containerStyle}
      center={positionProp.positionProp}
      zoom={15}
    >
      <MarkerF position={positionProp.positionProp} />
    </GoogleMap>
  )
}

export default Map
