import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import capitalize from 'lodash/capitalize'
import isEmpty from 'lodash/isEmpty'
import React, { useCallback, useMemo, useState } from 'react'
import { Form, Spinner } from 'react-bootstrap'
import { useLocation } from 'react-router-dom'
import Table from '../../../../components/Common/Tanstack-table/Table'
import { createTableColumnsFromKeysArr } from '../../../../components/utils/Utils'
import fetchService from '../../../../components/utils/fetchService'
import config from '../../../../config'
import EntityDetails from '../customerDetails/AddressBook/EntityDetails'

const columnKeys = ['postCodeId', 'customerCodeConfirmation', 'suburb']

const defaultSelectedValues = {
  index: null,
  data: null,
}

/**
 * Profiles, entity - shipper / consignee
 */
export default function CreatePrinciple({ profile }) {
  const entityIdKey = `cc${capitalize(profile)}AddressId`
  const queryClient = useQueryClient()
  const { state } = useLocation()
  const [selected, setSelected] = useState(defaultSelectedValues)
  const [selectedCustomerId, setSelectedCustomerId] = useState(
    state?.preSelectedCustomerId ?? ''
  )
  const [showEntityDetailsModal, setShowEntityDetailsModal] = useState(false)

  const {
    data: customers,
    isLoading: isLoadingCustomers,
    error: customersError,
  } = useQuery({
    queryKey: [config.api.customerNames],
    queryFn: async () =>
      await fetchService({
        url: config.api.customerNames,
      }),
  })

  const {
    data: profileData,
    isInitialLoading: isLoadingProfileData,
    error: profileError,
  } = useQuery({
    queryKey: ['customer', `${profile}-address`, selectedCustomerId],
    queryFn: async () =>
      await fetchService({
        url: `/customercard/${profile}-address/?customerCardId=${selectedCustomerId}`,
      }),
    enabled: !!selectedCustomerId,
  })

  const mutation = useMutation({
    mutationFn: async (body) =>
      await fetchService({
        method: 'DELETE',
        isFile: true,
        url: `/customercard/${profile}-address/${body[entityIdKey]}`,
      }),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['customer', `${profile}-address`, selectedCustomerId],
      })
    },
  })

  const handleColumnActions = useCallback(
    (action, row) => {
      switch (action) {
        case 'edit':
          setShowEntityDetailsModal(true)
          setSelected({ data: row.original, index: row.index })
          break

        case 'delete':
          const rowData = row.original
          let answer = window.confirm(
            'Are you sure to delete suburb profile id - ' + rowData[entityIdKey]
          )
          if (!answer) return false
          mutation.mutate(rowData)
          break

        default:
          break
      }
    },
    [entityIdKey, mutation]
  )

  const columns = useMemo(() => {
    const cols = createTableColumnsFromKeysArr([
      `${profile}Id`,
      `${profile}Name`,
      ...columnKeys,
    ])

    cols.unshift({
      header: 'Actions',
      id: 'actions',
      cell: ({ row }) => (
        <div className="d-flex gap-1">
          <button
            type="button"
            className="btn btn-primary btn-sm"
            onClick={() => handleColumnActions('edit', row)}
          >
            Edit
          </button>
          <button
            type="button"
            className="btn btn-secondary btn-sm ms-2"
            onClick={() => handleColumnActions('delete', row)}
          >
            Delete
          </button>
        </div>
      ),
    })

    return cols
  }, [handleColumnActions, profile])

  const handleClose = () => {
    setSelected(defaultSelectedValues)
    setShowEntityDetailsModal(false)
  }

  const handleAddNewEntity = () => {
    setShowEntityDetailsModal(true)
    setSelected(defaultSelectedValues)
  }

  if (customersError)
    return <pre>{JSON.stringify(customersError, null, 2)}</pre>

  return (
    <div className="p-4 m-4 w-100">
      {isLoadingCustomers && <Spinner />}
      {isEmpty(customers) && <p>No Customers found</p>}

      {!isEmpty(customers) && Array.isArray(customers) && (
        <div>
          <div className="d-flex align-items-center">
            <Form.Group>
              <Form.Select
                value={selectedCustomerId}
                onChange={(e) => setSelectedCustomerId(e.target.value)}
              >
                <option value="">Select Customer</option>
                {customers.map((customer) => (
                  <option key={customer.id} value={customer.id}>
                    {customer.name}
                  </option>
                ))}
              </Form.Select>
            </Form.Group>
            {selectedCustomerId && (
              <button
                className="btn btn-primary ms-3 h-auto"
                onClick={handleAddNewEntity}
              >
                Create {capitalize(profile)}
              </button>
            )}
          </div>
          {isLoadingProfileData && <Spinner className="mt-3" />}
          {profileError && <pre>{JSON.stringify(profileError, null, 2)}</pre>}
          {isEmpty(profileData) && selectedCustomerId && (
            <p className="mt-3">No Data found</p>
          )}
          {!isEmpty(profileData) &&
            Array.isArray(profileData) &&
            !profileError && (
              <div className="mt-3">
                <Table
                  columns={columns}
                  data={profileData}
                  showTableBottom={false}
                  showGlobalSearch={false}
                  showGroupingIcon={false}
                />
              </div>
            )}
        </div>
      )}
      {showEntityDetailsModal && (
        <EntityDetails
          handleClose={handleClose}
          entity={profile}
          type="edit"
          selectedDetail={selected.data}
          customerCardId={selectedCustomerId}
        />
      )}
    </div>
  )
}
