const config = {
  api: {
    baseUrl:
      process.env.NODE_ENV === 'development'
        ? 'https://tms-dev.swifttrackmile.codes/api'
        : '/api',
    goLiveMonitoring: {
      base: 'https://login.golivemonitoring.com',
      loginEndpoint: '/?/Login/procLogin/',
      secret: 'Project2024',
      userName: 'manisha@swifttrans.com.au',
    },
    enigmaChat: {
      chatBaseUrl: 'https://dev.trackmile.com.au/api',
      getAllUsers: '/enigma-chat/conversation/all/active/users',
      getCerebroJobConversations: '/enigma-chat/conversation/all/by-job',
      getUserConversations: '/enigma-chat/conversation/all/by-user',
      createJobConversation: '/enigma-chat/conversation/create/job',
      createPersonalConversation: '/enigma-chat/conversation/create/personal',
      getConversation: '/enigma-chat/conversation/get',
      sendMessage: '/enigma-chat/conversation/participant/publish-chat-message',
    },
    login: '/authenticate',
    validate: '/validate',
    createUser: '/user',
    availability: '/user/availability/',
    allUsers: '/users',
    uniqueUser: '/user/',
    rateGroups: '/rateGroups',
    customerCard: '/customercard',
    customerCardsList: '/customercards',
    lookups: '/lookups',
    zoneUpload: '/customercard/zone/upload',
    customerNames: '/customer-name',
    shipperNames: '/customercard/shipper-address/shipper-name?customerCardId=',
    consigneeNames:
      '/customercard/consignee-address/consignee-name?customerCardId=',
    consigneeName: '/consignee-name',
    draftCustomer: '/operations/order/customer',
    order: '/operations/order/customer/draft/refernce-job',
    vessel: '/operations/order/customer/submit',
    container: '/operations/order/container',
    cargo: '/operations/order/container/cargodetails',
    shipping: '/operations/order/container/vehicle-pickup-delivery-dehire',
    accunting: '/operations/order/accounting',
    getTemplateFunctionByFunctionName: '/mastertemplate/function?functionName=',
    saveMasterTemplateFunction: '/mastertemplate/function?channelName=',
    userTemplateFunction: '/usertemplate/function',
    getUserTemplateList: '/usertemplate/function/template/',
    plannedDashboard: '/clm/dashboard/',
    summaryDisplay: '/operations/search/order/summary-display',
    getAllPortSlotDetails: '/operations/search/order/port-slot',
    confirmOrderContainer: '/operations/search/order/confirm',
    getWebfleetResponse: '/clm/webfleet/vehicle-truck',
    defaultTemplate: '/usertemplate/function/template/default',
    getTemplate: '/usertemplate/function/template/names/dropdown?channelName=',
    getDriverCardsByStatus: '/transports/driver/card/dashboard/status?status=',
    loadDispatch: '/clm/dashboard/dispatch',
    getVehicleCards: '/transports/vehicle/dashboard/all',
    getVehicleTrucksByStatus:
      '/transports/vehicle/truck/dashboard/status?status=',
    getSplitJobDetails:
      '/clm/liveloadtracking/split-job-details?containerStageTrackerId=',
    getContainerLoadDTOs:
      '/clm/liveloadtracking/container-load?containerStageTrackerId=',
    getDriverDetails:
      '/clm/liveloadtracking/driver-details?containerStageTrackerId=',
    assignVehicleTruckToDriver: '/transports/driver/card/assign/vehicle/',
    assignDriverVehicleToStage: '/clm/dashboard/job/assign-driver-truck',
    driverWithoutStatus: '/transports/driver/card/all/driver-vehicles',
    driverWithStatus: '/transports/driver/card/all/driver-vehicles?status=',
    jobsDetailsDTOByDriverCardId: '/clm/dashboard/jobs-by-driver?driverCardId=',
    getDropdownValues: '/clm/advanced-search/dropdown-values',
    loadSearch: '/clm/advanced-search/load',
    jobSearch: '/clm/advanced-search/job',
    wms: {
      unpacked: {
        getListByUplStatus: '/wms/unpacked/byUplStatus?uplStatusEnum=',
        getLocationDetailsById: '/wms/unpacked/location?wmsDetailsId=',
        setNewLocation: '/wms/unpacked/location',
        verifyCargoById: '/wms/unpacked/verifyCargo?cargoId=',
        updateToUnpacked: '/wms/unpacked/updateStatusToUnpacked/',
        updateToApproved: '/wms/unpacked/updateStatusToApproved/',
        updateToDispatch: '/wms/unpacked/updateStatusToDispatch/',
        updateToComplete: '/wms/unpacked/updateStatusToCompleted/',
      },
    },
    yms: {
      getGateIncomingDto: '/container/yms/gateIncoming',
      getGateOutgoingDto: '/container/yms/gateOutgoing',
      getTruckIncomingDto: '/container/yms/truckIncoming',
      getTruckOutgoingDto: '/container/yms/truckOutgoing',
    },
    invoice: {
      orderFinancials: '/operations/order/financial',
      ofcContainerFinancials:
        '/operations/order/financial/container-financials/',
      updateOfcStatus: '/operations/order/financial/update-status',
      invoiceDriver: '/operations/order/invoice/manual',
      invoices: '/operations/order/invoice/all',
      invoiceDetails: '/operations/order/invoice/by-invoice-no/',
      fetchInvoiceDocs: '/operations/order/invoice/document/customer-invoice/',
      uploadDoc: '/operations/order/invoice/document/upload?customerInvoiceId=',
      downloadDoc: '/operations/order/invoice/document/download/',
      deleteDoc: '/operations/order/invoice/document/',
      creditNotes: '/operations/order/invoice/credit-note',
      newCreditNotes: '/operations/order/invoice/credit-note',
    },
    oneStop: {
      routing: '/one-stop/vessel/routing/csv-data',
      schedule: '/one-stop/vessel/schedule/csv-data',
    },
    containerAlerts: '/container/vessel/alert/search',
    vehicles: {
      base: '/transports/vehicle',
    },
    containerNotes: '/container/stage-tracker/notes',
    jobNotes: '/clm/dashboard/stage-notes-history/',
    containerStagesOptions: '/container/stage-tracker/stage-array/',
    customerRateLogic: '/customercard/rate/logic',
    correspondence: '/customercard/correspondence',
    rates: {
      getCustomerRateDetailsByCustomerCardId: (id) =>
        `/customercard/rate-detail?customerCardid=${id}`,
      getConsigneeAddressByCustomerCardId: (id) =>
        `/customercard/consignee-address/?customerCardId=${id}`,
      getZonesByCustomerCardId: (id) =>
        `/customercard/zone?customerCardId=${id}`,
    },
    drivers: {
      runSheets: {
        baseURL: '/dashboard/driver/logging-details',
        manualExists: '/manual-runsheet/exists',
        manualNotExists: '/manual-runsheet/not-exists',
        autoExists: '/auto-runsheet/exists',
        autoNotExists: '/auto-runsheet/not-exists',
        getDateParams: (start, end) => `?start-date=${start}&end-date=${end}`,
      },
    },
  },
  modules: {
    USERS: {
      icon: './assets/user-icon.png',
      dbicon: './assets/dashboard/user-icon.png',
      path: '/User',
      background: 'linear-gradient(104.65deg, #569EBA 1.61%, #296278 99.92%)',
    },
    CUSTOMER: {
      icon: './assets/customer-icon.png',
      dbicon: './assets/dashboard/customer-icon.png',
      path: '/Customer',
      background: 'linear-gradient(104.65deg, #569EBA 1.61%, #296278 99.92%)',
    },
    OPERATIONS: {
      icon: './assets/orders-icon.png',
      dbicon: './assets/dashboard/operations-icon.png',
      path: '/Operations',
      background: 'linear-gradient(104.65deg, #569EBA 1.61%, #296278 99.92%)',
    },
    TRANSPORT: {
      icon: './assets/transport-icon.png',
      dbicon: './assets/dashboard/transport-icon.png',
      path: '/transport/drivers',
      background: 'linear-gradient(104.65deg, #569EBA 1.61%, #296278 99.92%)',
    },
    INVOICE: {
      icon: './assets/invoice-icon.png',
      dbicon: './assets/dashboard/invoice-icon.png',
      path: '/invoice',
      background: 'linear-gradient(104.65deg, #569EBA 1.61%, #296278 99.92%)',
    },
    YARD: {
      icon: './assets/containers-icon.png',
      dbicon: './assets/dashboard/container-icon.png',
      path: '/container',
      background: 'linear-gradient(104.65deg, #569EBA 1.61%, #296278 99.92%)',
    },
    CONTAINER: {
      icon: './assets/container-icon.png',
      dbicon: './assets/dashboard/container-icon.png',
      path: '/container',
      background: 'linear-gradient(104.65deg, #569EBA 1.61%, #296278 99.92%)',
    },
  },
  clmModules: {
    DASHBOARD: {
      icon: './assets/Dashboard_Icon.png',
      path: '/clm-dashboard',
    },
    LOADSPLANNING: {
      icon: './assets/Assets_icon.png',
      path: '/loads-planning',
    },
    DRIVERS: {
      icon: './assets/Drivers_icon.png',
      path: '/driver-dashboard',
    },
    CUSTOMERS: {
      icon: './assets/Customers_icon.png',
      path: '/clm-dashboard',
    },
    LOCATIONS: {
      icon: './assets/Location_icon.png',
      path: '/clm-dashboard',
    },
    FINANCIALS: {
      icon: './assets/Financials_icon.png',
      path: '/clm-dashboard',
    },
    DOCUMENTS: {
      icon: './assets/Document_icon.png',
      path: '/clm-dashboard',
    },
    REPORTS: {
      icon: './assets/Reports_icon.png',
      path: '/clm-dashboard',
    },
    YARD: {
      icon: './assets/containers-icon.png',
      path: '/container/stage-tracker',
    },
    SETTINGS: {
      icon: './assets/Settings_icon.png',
      path: '/clm-dashboard',
    },
  },
  containerModule: {
    stageTracker: {
      confirm: '/stage-tracker/confirm',
    },
  },
  rateDetailing: {
    baseURL: '/customercard/rate-detail',
    allRates: {
      baseURL: '/all-rates',
      queryParam: 'customerCardid',
    },
    CRD: {
      addCRD: '/multi',
      copy: '/copy',
      import: '',
    },
  },
  customerAgreedRates: {
    baseURL: '/customercard/crd-agreed-rates',
    addRate: {
      baseURL: '/multi',
    },
  },
  consigneeAgreedRates: {
    baseURL: '/ccConsigneecard/consignee-agreed-rates',
    addRate: {
      baseURL: '/multi',
    },
  },
  shipperAgreedRates: {
    baseURL: '/customercard/shipper-agreed-rates',
    addRate: {
      baseURL: '/multi',
    },
  },
}

export default config
