import axios from 'axios'
import React from 'react'
import * as Toastify from '../../../../../components/utils/Toastify'
import config from '../../../../../config'
import { useOrdersContext } from '../../context/orderOperationsContext'

export default function AcceptOrderButton({
  customerOrderData,
  setCustomerOrderData,
}) {
  const operationsCtxData = useOrdersContext()

  const handleAcceptClick = (e) => {
    e.preventDefault()
    const url =
      config.api.baseUrl +
      `/operations/order/customer/accept/${operationsCtxData?.orderCustomerDetailsId}`
    axios({
      method: 'PUT',
      url,
      headers: {
        Authorization: 'Bearer ' + localStorage.token,
      },
    })
      .then((res) => {
        Toastify.Success('Order Accepted successfully..!')
        setCustomerOrderData(res.data)
      })
      .catch((error) => {
        Toastify.Error(error?.response?.data?.data[0]?.message)
      })
  }

  return (
    <div>
      <button
        type="button"
        className="btn btn-primary w-100 d-flex justify-content-center mt-2"
        onClick={handleAcceptClick}
        disabled={customerOrderData?.accepted}
      >
        {customerOrderData?.accepted ? 'ACCEPTED' : 'Accept'}
      </button>
    </div>
  )
}
