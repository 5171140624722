import React, { useMemo } from 'react'
import { useSelector } from 'react-redux'
import {
  getSearchTextState,
  getUserConversations
} from '../../../../store/slices/enigmaChat-slice'
import {
  filterConversationsByParticipantsName,
  sortConversationsByDate,
} from '../utils'
import ConversationItem from './ConversationItem'

export default function ConversationsList() {
  const conversations = useSelector(getUserConversations)
  const searchText = useSelector(getSearchTextState)

  const filteredConversation = useMemo(() => {
    if (searchText === '') return sortConversationsByDate(conversations)

    return filterConversationsByParticipantsName(conversations, searchText)
  }, [conversations, searchText])

  return (
    <div className="list-container">
      {filteredConversation.map((conversation) => (
        <ConversationItem
          key={conversation.conversationSid}
          conversation={conversation}
        />
      ))}
    </div>
  )
}
