import React, { useEffect, useMemo } from 'react'
import { useParams } from 'react-router-dom'
import isEmpty from 'lodash/isEmpty'
import Table from '../Tanstack-table/Table'
import useLoading from '../../Clm/hooks/useLoading'
import { useDispatch, useSelector } from 'react-redux'
import { fetchVesselServices } from '../../../store/actions/vessel-actions'
import { getVesselServiceDetailsByController } from '../../../store/slices/vessel-slice'
import { getContainerAlerts } from '../../../store/slices/container-slice'
import { fetchContainerAlerts } from '../../../store/actions/container-actions'
import { selectCreditNotes } from '../../../store/slices/invoice-slice'
import { fetchCreditNotes } from '../../../store/actions/invoice-actions'
import CreditNotesHeader from '../../../Pages/Invoice/CreditNotes/index.jsx';
import ContainerWithHeading from '../../styles/Containers/ContainerWithHeading'

const controllerOperations = {
  schedule: {
    select: getVesselServiceDetailsByController,
    fetch: fetchVesselServices,
    header: 'Vessel Schedule Services',
    subHeader: (<></>)
  },
  routing: {
    select: getVesselServiceDetailsByController,
    fetch: fetchVesselServices,
    header: 'Vessel Routing Services',
    subHeader: (<></>)
  },
  alert: {
    select: getContainerAlerts,
    fetch: fetchContainerAlerts,
    header: 'Container Alerts',
    subHeader: (<></>)
  },
  creditNotes: {
    select: selectCreditNotes,
    fetch: fetchCreditNotes,
    header: 'Credit Notes',
    subHeader: (<CreditNotesHeader></CreditNotesHeader>)
  },
}

/**
 * To use this page a controller in params in required
 * Controller must have it's fetch and select redux actions
 * @returns A component to be rendered as a page
 */
export default function TablePage() {
  const { controller } = useParams()
  const { setLoading } = useLoading()
  const dispatch = useDispatch()
  const data = useSelector((state) =>
    controllerOperations[controller].select(state, controller)
  )

  useEffect(() => {
    if (isEmpty(data))
      dispatch(controllerOperations[controller].fetch(controller, setLoading))
  }, [controller, dispatch, setLoading, data])

  const columns = useMemo(() => {
    if (!isEmpty(data))
      return Object.keys(data[0]).map((key) => ({
        header: key,
        accessorKey: key,
        id: key,
      }))
    return []
  }, [data])

  return (
    <div className="w-100 px-1">
      <ContainerWithHeading heading={controllerOperations[controller].header}>
        <main>
          {controllerOperations[controller].subHeader}
          <Table inheritTableWidth columns={columns} data={data} />
        </main>
      </ContainerWithHeading>
    </div>
  )
}
