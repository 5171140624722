import { yupResolver } from '@hookform/resolvers/yup'
import axios from 'axios'
import React, { useCallback, useEffect, useState } from 'react'
import { Col, Form } from 'react-bootstrap'
import { useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import * as Yup from 'yup'
import config from '../../config'
import { fetchAccountingDetails } from '../../store/actions/customer-action'
import {
  getAccountingDetails,
  setAccountingDetails,
} from '../../store/slices/customer-slice'
import InputNumber from '../Common/Form/Fields/InputNumber'
import Select from '../Common/Form/Fields/Select'
import Switch from '../Common/Form/Fields/Switch'
import Spinner from '../Spinner/Spinner'
import { Success } from '../utils/Toastify'
import LocationSearchInput from './LocationSearchInput'
import RequiredSymbol from '../Common/Form/RequiredSymbol'

const Accounting = (props) => {
  const dispatch = useDispatch()
  const { customerCardId, type, lookupsList } = props
  const [enableSpinner, setEnableSpinner] = useState(false)
  const [, setIsFetching] = useState(false)
  const accountData = useSelector(getAccountingDetails)
  const [showError, setShowError] = useState(false)
  const [isExists, setIsExists] = useState(false)
  const validationSchema = Yup.object().shape({
    address: Yup.string().required('Billing Address is required'),
    creditLimit: Yup.number()
      .required('Credit limit is required')
      .max(2_000_000, 'Credit Limit must be less than 2,000,000')
      .typeError('Credit limit is required')
      .min('1', 'Credit Limit must be more than 1'),
    creditTerms: Yup.string().required('credit Terms  is required'),
    tradingOrAccountHold: Yup.boolean().default(false).nullable(),
  })

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    clearErrors,
    getValues,
  } = useForm({
    resolver: yupResolver(validationSchema),
    reValidateMode: 'onChange',
    defaultValues: accountData,
  })

  useEffect(() => {
    return () => {
      dispatch(setAccountingDetails({}))
    }
  }, [dispatch])

  const getAccounting = useCallback(() => {
    if (customerCardId) {
      setIsFetching(true)
      dispatch(
        fetchAccountingDetails(
          customerCardId,
          (data) => {
            setValue('address', data.billingAddress)
            setValue('creditLimit', data.creditLimit)
            setValue('creditTerms', data.creditTerms)
            setValue('tradingOrAccountHold', data.tradingOrAccountHold)
            setIsExists(data.ccAccountingId !== null)
            setIsFetching(false)
          },
          () => setIsFetching(false)
        )
      )
    }
  }, [customerCardId, dispatch, setIsFetching, setValue])

  useEffect(() => {
    getAccounting()
  }, [customerCardId, dispatch, getAccounting])

  const onSubmitFunction = (formData) => {
    formData.customerCardId = customerCardId
    formData.creditLimit = Number(formData.creditLimit)
    formData.tradingOrAccountHold = formData.tradingOrAccountHold ?? false
    formData.billingAddress = formData.address
    delete formData.address
    setEnableSpinner(true)
    let dataURL = '/customercard/accounting'
    if (isExists) {
      dataURL = dataURL + '/' + accountData.ccAccountingId
      formData.ccAccountingId = accountData.ccAccountingId
      formData.customerCardId = customerCardId
    }
    const url = config.api.baseUrl + dataURL
    setShowError(false)
    const submitType = isExists ? 'PUT' : 'POST'
    axios({
      method: submitType, //you can set what request you want to be
      url: url,
      data: formData,
      headers: {
        Authorization: 'Bearer ' + localStorage.token,
      },
    })
      .then(() => {
        if (isExists) Success('Accounts details updated successfully')
        else Success('Accounts details created successfully')
        getAccounting()
      })
      .catch(function (error) {
        setShowError(true)
      })
      .finally(() => {
        setEnableSpinner(false)
      })
  }

  const getAddressDetails = (address) => {
    setValue('address', address.address)
    clearErrors(['address'])
  }

  return (
    <>
      <form
        className="row"
        autoComplete="off"
        onSubmit={handleSubmit(onSubmitFunction)}
      >
        <Switch
          name="tradingOrAccountHold"
          register={register}
          errors={errors}
          md={12}
        />
        <Select
          name="creditTerms"
          register={register}
          errors={errors}
          md={12}
          options={lookupsList.creditTermsTypes}
          optionValueKey="creditTerm"
          required
        />

        <InputNumber
          register={register}
          errors={errors}
          md={12}
          name="creditLimit"
          required
        />

        <Form.Group className="my-3" as={Col} md={12} controlId="address">
          <Form.Label>
            Billing Address <RequiredSymbol />
          </Form.Label>
          {type !== 'view' && (
            <LocationSearchInput
              onSelectAddress={getAddressDetails}
              id="address"
              errors={errors}
              register={register}
              setValue={setValue}
              value={getValues('address')}
            />
          )}
        </Form.Group>
        <div className="col-12">
          {showError && (
            <div className="text-danger my-4">
              <p>There is an issue in form submission. please try later.</p>
              {showError.length &&
                showError.map((eachError, i) => {
                  return (
                    <p key={i}>
                      {eachError.key} - {eachError.message}
                    </p>
                  )
                })}
            </div>
          )}
        </div>
        {props.type !== 'view' && (
          <div className="col-12 my-2">
            <button className="btn btn-primary" type="submit">
              {isExists ? 'Update' : 'Submit'}
            </button>
          </div>
        )}
      </form>

      {enableSpinner && <Spinner />}
    </>
  )
}

export default Accounting
