import { faXmark } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import { Button, Col, Row, Spinner } from 'react-bootstrap'
import { useFieldArray, useWatch } from 'react-hook-form'
import { useSelector } from 'react-redux'
import SearchableSelectField from '../../../../../../components/Common/Form/Fields/SearchableSelectField'
import { useGetSpecialServiceYards } from '../../../../../../helper/hooks/api/cerebro'
import { getLookupDataState } from '../../../../../../store/slices/lookup-slice'

const initialObj = {
  serviceId: '',
  depot: '',
}

const fieldName = 'specialServiceMap'

const Service = ({ form, index, remove, type }) => {
  const lookupsList = useSelector(getLookupDataState)

  const { setValue, control } = form

  const specialServices = useWatch({
    name: fieldName,
    control,
  })
  const { serviceId } = specialServices?.[index]
  const { data, isLoading, error } = useGetSpecialServiceYards(serviceId)

  return (
    <Row>
      <Col md={1} className="d-flex align-items-center justify-content-center">
        {index + 1}.
      </Col>
      <SearchableSelectField
        options={lookupsList.specialServices}
        control={control}
        name={`${fieldName}.${index}.serviceId`}
        label="Special Service"
        setValue={setValue}
        optionValueKey="id"
        md={4}
        getError={(errors) => errors?.[fieldName]?.[index]?.['serviceId']}
        type={type}
      />
      {isLoading && (
        <Col
          md={4}
          className="d-flex align-items-center justify-content-center"
        >
          <Spinner size="sm" />
        </Col>
      )}
      {error && (
        <Col md={4} className="text-danger d-flex align-items-center">
          Error occurred while fetching depot options
        </Col>
      )}
      {data?.length > 0 && (
        <SearchableSelectField
          options={data}
          control={control}
          name={`${fieldName}.${index}.depot`}
          label="Depot "
          required
          setValue={setValue}
          optionValueKey="code"
          optionLabelKey="description"
          md={4}
          getError={(errors) => errors?.[fieldName]?.[index]?.['depot']}
          type={type}
        />
      )}
      {data?.length === 0 && (
        <Col className="d-flex align-items-center" md={4}>
          No Data found
        </Col>
      )}
      {type === 'edit' && (
        <Col md={1} className="d-flex align-items-center">
          <button className="unset-all" onClick={remove}>
            <FontAwesomeIcon icon={faXmark} style={{ color: '#ff0000' }} />
          </button>
        </Col>
      )}
    </Row>
  )
}

export default function SpecialServicesField({ form, type }) {
  const { control } = form

  const {
    fields: specialServices,
    append: addSpecialService,
    remove: removeSpecialService,
  } = useFieldArray({
    name: fieldName,
    control,
  })

  return (
    <div className="p-3">
      {specialServices?.map((service, index) => (
        <Service
          key={service.id}
          form={form}
          index={index}
          remove={() => removeSpecialService(index)}
          type={type}
        />
      ))}
      {type === 'edit' && (
        <Button type="button" onClick={() => addSpecialService(initialObj)}>
          Add VAS
        </Button>
      )}
    </div>
  )
}
