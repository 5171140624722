import config from '../../config'
const BASE_URL = config.api.baseUrl

export default async function fetchService({
  url,
  method = 'GET',
  body,
  headers = {
    Authorization: 'Bearer ' + localStorage.token,
    'Content-Type': 'application/json',
  },
  isFile = false,
  onSuccessCallback,
  returnRaw = false,
  removeContentType = false,
}) {
  const URL = BASE_URL + url

  if (removeContentType) delete headers['Content-Type']

  const requestOptions = {
    method,
    headers,
    body: isFile ? body : JSON.stringify(body),
  }
  const response = await fetch(URL, requestOptions)
  if (!response.ok) {
    throw await response.json()
  }
  if (response.status === 200 && onSuccessCallback) {
    onSuccessCallback(await response.json())
    return
  }

  if (returnRaw) return await response.text()
  if (isFile) return response
  return response.json()
}
