import { useQuery } from '@tanstack/react-query'
import React, { useMemo } from 'react'
import { Link } from 'react-router-dom'
import Table from '../../../../components/Common/Tanstack-table/Table'
import ContainerWithHeading from '../../../../components/styles/Containers/ContainerWithHeading'
import fetchService from '../../../../components/utils/fetchService'
import config from '../../../../config'
import { Spinner } from 'react-bootstrap'

export default function CustomersList() {
  const {
    data: customers,
    error,
    isLoading,
  } = useQuery({
    queryKey: [config.api.customerCardsList, 'active'],
    queryFn: async () =>
      await fetchService({
        url: `${config.api.customerCardsList}?active=true`,
      }),
  })

  const columns = useMemo(
    () => [
      { header: 'S.No', accessorKey: 'customerCardId', id: 'customerCardId' },
      {
        header: 'CCC',
        accessorKey: 'customerCodeConfirmation',
        id: 'customerCodeConfirmation',
      },
      {
        header: 'Customer Name',
        accessorKey: 'customerName',
        id: 'customerName',
      },
      { header: 'ABN', accessorKey: 'abn', id: 'abn' },
      {
        header: 'address',
        accessorKey: 'address',
        id: 'address',
        size: 300,
      },
      { header: 'isActive', accessorKey: 'active', id: 'active' },
      {
        header: 'Actions',
        id: 'actions',
        minSize: 120,
        cell: ({ row: { original } }) => (
          <div className="d-flex gap-1">
            <Link title="View" to={'/Customer/view/' + original.customerCardId}>
              <img src="/assets/vIEW.png" alt="" />
            </Link>
            <Link
              title="Edit"
              className=""
              to={'/Customer/edit/' + original.customerCardId}
            >
              <img src="/assets/Edit.png" alt="" />
            </Link>
          </div>
        ),
      },
    ],
    []
  )

  return (
    <section className="w-100 px-2 pb-5">
      <ContainerWithHeading
        heading="Customer Card Dashboard"
        containerClassNames=""
      >
        {(error || customers?.length === 0) && <p>No data found</p>}
        {isLoading && (
          <div className="d-flex justify-content-center align-items-center">
            <Spinner />
          </div>
        )}
        {Array.isArray(customers) && customers.length > 0 && (
          <div>
            <Table columns={columns} data={customers} />
          </div>
        )}
      </ContainerWithHeading>
    </section>
  )
}
