import React, { Fragment } from 'react'
import { useForm } from 'react-hook-form'
import { tooltip } from '../../../components/Common/tooltip'

export default function SentStatusTooltip({ col }) {
  const { register, handleSubmit } = useForm()
  const onSubmit = (data) => console.log(data)
  return (
    <Fragment>
      <div
        data-tip
        data-for={`invoice-sent-status-${col.getValue()}`}
        className="cursor-pointer"
        style={{ width: '100%' }}
      >
        {col.getValue()}
      </div>
      {tooltip(
        <div className="send-status-tooltip">
          <form onSubmit={handleSubmit(onSubmit)}>
            <div>
              <input {...register('action')} type="checkbox" value="A" />
              Send To Customer
            </div>
            <div>
              <input {...register('action')} type="checkbox" value="B" />
              Not send to Customer
            </div>
            <div>
              <input {...register('action')} type="checkbox" value="C" />
              On Hold
            </div>
            <div className="d-flex">
              <button className="btn btn-sm btn-primary" type="submit">
                Submit
              </button>
              <button className="btn btn-sm btn-gold ms-auto" type="submit">
                Reset
              </button>
            </div>
          </form>
        </div>,
        `invoice-sent-status-${col.getValue()}`,
        'bottom'
      )}
    </Fragment>
  )
}
