import React, { useEffect, useState } from 'react'
import Dropzone from 'react-dropzone'
import Papa from 'papaparse'
import CRDTable from './CRDTable'
import CRDObj from './CRDDataRef.json'

const ImportSuburb = (props) => {
  const {
    csvArray,
    setCsvArray,
    formError,
    showError,
    showSuccessMsg,
    setSuburbFormData,
    setEnableForm,
    setProfileFormType,
    setProfileFormId,
  } = props
  const [filePath, setFilePath] = useState('')
  const [fileError, setFileError] = useState(false)

  useEffect(() => {
    setProfileFormType('import')
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleAcceptedFiles = (files) => {
    setFilePath(files[0].path)

    const reader = new FileReader()
    reader.onload = async ({ target }) => {
      Papa.parse(target.result, {
        header: false,
        complete: ({ data }) => {
          if (data.length < 2) {
            setFileError(true)
            return false
          }
          setFileError(null)

          let clonedData = data
          clonedData.splice(0, 1)
          let formattedData = []
          clonedData.forEach((eachRow, i) => {
            let eachFormattedData = []
            eachRow.forEach((item) => {
              let itemFormatted = item
              if (item === '') {
                itemFormatted = ''
              } else if (item.startsWith('$')) {
                itemFormatted = item.replace('$', '')
              } else if (item === 'NULL') {
                itemFormatted = ''
              }
              eachFormattedData.push(itemFormatted)
            })
            formattedData.push(eachFormattedData)
          })

          let newData = []
          formattedData.forEach((eachArray) => {
            let newSuburbObj = {}
            Object.keys(CRDObj).forEach((eachKey, i) => {
              newSuburbObj[eachKey] = eachArray[i]
            })
            newData.push(newSuburbObj)
          })

          setCsvArray({ data: newData, igniter: new Date() })
        },
      })
      // parse(reader.result, (err, data) => {
      //   if (err) {
      //     alert('please upload a valid csv file')
      //     setFileError(true)
      //     return false
      //   }
      // })
    }

    reader.readAsText(files[0])
  }

  const submitImportZone = () => {
    props.AddMultiZones(csvArray.data)
  }

  return (
    <div>
      <div className="row">
        <div className="col-md-9">
          <input type="hidden" value="1" id="customerCardId" />
          <h4>Import suburb profile</h4>
        </div>
      </div>

      <Dropzone
        onDrop={(acceptedFiles) => handleAcceptedFiles(acceptedFiles)}
        multiple={false}
        accept={{
          'text/csv': [],
        }}
      >
        {({ getRootProps, getInputProps }) => (
          <div className="dropzone">
            <div
              className={
                (fileError ? 'error-file' : '') + ' dz-message needsclick mt-2 '
              }
              {...getRootProps()}
            >
              <input {...getInputProps()} name="uploadfile" />
              <div className="mb-3">
                <i className="display-4 text-muted ri-upload-cloud-2-line"></i>
              </div>
              <h4>Drag a file here</h4>
              <ul>
                <li>{filePath}</li>
              </ul>
            </div>
          </div>
        )}
      </Dropzone>

      {fileError && (
        <p className="text-danger">Please upload a valid CSV file</p>
      )}

      {formError && (
        <p className="text-danger my-4">
          Please check the error messages and submit the form again
        </p>
      )}

      {csvArray.data && csvArray.data.length > 0 && (
        <div className="import-crd-comp">
          <CRDTable
            csvArray={csvArray}
            setSuburbFormData={setSuburbFormData}
            setEnableForm={setEnableForm}
            setProfileFormId={setProfileFormId}
            setCsvArray={setCsvArray}
            setProfileFormType={setProfileFormType}
          />

          <div className="text-end mt-4">
            <button
              onClick={() => submitImportZone()}
              className="btn btn-primary"
            >
              Submit CRD
            </button>
          </div>
        </div>
      )}

      {showError && (
        <div className="text-danger my-4">
          <p>There is an issue in form submission. please try later.</p>
          {showError.length &&
            showError.map((eachError, i) => {
              return (
                <p key={i}>
                  {eachError.key} - {eachError.message}
                </p>
              )
            })}
        </div>
      )}

      {showSuccessMsg && (
        <p className="text-success my-4">Form has submitted successfully!</p>
      )}
    </div>
  )
}

export default ImportSuburb
