import React, { useMemo } from 'react'
import { Button, FormSelect, Spinner } from 'react-bootstrap'
import { useSelector } from 'react-redux'
import { getLookupDataState } from '../../../store/slices/lookup-slice'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import fetchService from '../../utils/fetchService'
import { useForm } from 'react-hook-form'
import config from '../../../config'
import { Success } from '../../utils/Toastify'

const { customerRateLogic } = config.api

export default function CartageRatesTab({ customerCardId }) {
  const queryClient = useQueryClient()
  const rateOptions = useSelector(getLookupDataState)?.rateOptionEnums

  const { data: CustomerRateLogicData, isLoading } = useQuery({
    queryKey: [customerRateLogic, customerCardId],
    queryFn: async () =>
      await fetchService({
        url: `${customerRateLogic}/${customerCardId}`,
      }),
    enabled: !!customerCardId,
    staleTime: 60_000 * 5,
  })

  const mutation = useMutation({
    mutationKey: ['/customercard/rate', 'logic'],
    mutationFn: async (body) =>
      await fetchService({
        url: body?.customerRateLogicId
          ? `${customerRateLogic}/${body.customerRateLogicId}`
          : customerRateLogic,
        body,
        method: body?.customerRateLogicId ? 'PUT' : 'POST',
      }),
    onSuccess: (response) => {
      queryClient.setQueryData([customerRateLogic, customerCardId], response)
      Success('Customer Rate Logic Updated successfully')
    },
  })

  const defaultValues = {
    customerCardId: '',
    rateOption: '',
  }

  const values = useMemo(
    () => ({
      customerCardId: customerCardId,
      rateOption: CustomerRateLogicData?.rateOption || '',
      ...(CustomerRateLogicData?.customerRateLogicId && {
        customerRateLogicId: CustomerRateLogicData?.customerRateLogicId,
      }),
    }),
    [customerCardId, CustomerRateLogicData]
  )

  const {
    register,
    handleSubmit,
    formState: { errors, isDirty },
  } = useForm({
    defaultValues,
    values,
  })

  const onSubmit = (values) => {
    mutation.mutate(values)
  }

  return (
    <div>
      <h1>Select Rate Type</h1>
      <form onSubmit={handleSubmit(onSubmit)}>
        {isLoading ? (
          <Spinner style={{ color: 'aqua' }} />
        ) : (
          <FormSelect
            disabled={isLoading}
            {...register('rateOption', { required: true })}
          >
            <option disabled value="">
              Please select a rate
            </option>
            {rateOptions?.map((rate) => (
              <option key={rate} value={rate}>
                {rate}
              </option>
            ))}
          </FormSelect>
        )}
        {errors?.rateOption && (
          <p className="text-danger">This field is required</p>
        )}
        <Button
          disabled={mutation.isLoading || !isDirty}
          type="submit"
          className="mt-1"
        >
          Update Rate
        </Button>
      </form>
    </div>
  )
}
