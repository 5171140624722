import React from 'react'

export default function DriversTablePagination({
  resetOrder,
  resetFilters,
  table,
}) {
  return (
    <div className="">
      <div className="d-flex justify-content-center">
        <button
          className="btn btn-sm border tanstack-table-hook-pg-navigation-btn"
          onClick={() => table.previousPage()}
          disabled={!table.getCanPreviousPage()}
        >
          {'<'}
        </button>
        <button
          className="btn btn-sm border tanstack-table-hook-pg-navigation-btn"
          onClick={() => table.nextPage()}
          disabled={!table.getCanNextPage()}
        >
          {'>'}
        </button>
      </div>
      <span className="d-flex text-dark mx-2">
        <div>Page </div>
        <strong>
          {table.getState().pagination.pageIndex + 1} of {table.getPageCount()}
        </strong>
      </span>
    </div>
  )
}
