const countryCode = {
  name: 'Country Code',
  path: '/dataMgmt/user/countryCode',
  req: '/',
  columns: 2,
  fields: [
    {
      name: 'Country Code',
      label: 'Country Code',
      type: 'text',
      validation: 'Alphabetic',
      fieldId: 'countryCode',
      id: 'countryCode',
      validationType: 'string',
      validations: [
        {
          type: 'required',
          params: ['this field is required'],
        },
        {
          type: 'max',
          params: [30, 'Country Code cannot be more than 30 characters'],
        },
      ],
    },
    {
      name: 'Description',
      label: 'Description',
      type: 'text',
      validation: 'Alphabetic',
      fieldId: 'description',
      id: 'description',
      validationType: 'string',
      validations: [
        {
          type: 'required',
          params: ['this field is required'],
        },
        {
          type: 'max',
          params: [50, 'Description cannot be more than 50 characters'],
        },
      ],
    },
  ],
}
export default countryCode
