import { yupResolver } from '@hookform/resolvers/yup'
import React from 'react'
import { Button, Col, Form, Modal, Row, Spinner } from 'react-bootstrap'
import { useForm } from 'react-hook-form'
import * as yup from 'yup'
import Input from '../../../../components/Common/Form/Fields/Input'
import InputNumber from '../../../../components/Common/Form/Fields/InputNumber'
import Select from '../../../../components/Common/Form/Fields/Select'
import { useContainerStageOptions } from '../../../../helper/hooks/apiHooks'

const validationSchema = yup.object().shape({})

export default function StageDetailsModalForm({
  selectedContainerStage,
  handleClose,
  onSubmit,
}) {
  const { data: stageOptions, isLoading: isLoadingStageOptions } =
    useContainerStageOptions(selectedContainerStage.orderContainerDetailsId)

  const {
    register,
    handleSubmit,
    formState: { errors },
    // setError,
  } = useForm({
    resolver: yupResolver(validationSchema),
    reValidateMode: 'onChange',
    defaultValues: selectedContainerStage,
  })

  const areEditableFieldsDisabled = ![
    'PLANNED',
    'UNPLANNED',
    'LOAD',
    'HOLD',
  ].includes(selectedContainerStage.loadStatus)

  return (
    <Modal show onHide={handleClose} size="lg" centered>
      <Modal.Header closeButton>
        <Modal.Title>Stage Details Form</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Row>
            <Col md={4}>
              <Input
                name="containerNumber"
                md={12}
                register={register}
                errors={errors}
                disabled
                placeholder=""
              />
              <Input
                name="orderNumber"
                md={12}
                register={register}
                errors={errors}
                placeholder=""
                disabled
              />
              <Input
                name="containerLoadNumber"
                md={12}
                register={register}
                errors={errors}
                placeholder=""
                disabled
              />
              <Input
                name="vehicleJobType"
                md={12}
                label="Delivery Method"
                register={register}
                placeholder=""
                errors={errors}
                disabled
              />
              <Input
                name="loadStatus"
                md={12}
                register={register}
                placeholder=""
                errors={errors}
                disabled
              />
              <Input
                placeholder=""
                name="user"
                md={12}
                register={register}
                errors={errors}
                disabled
              />
              <Input
                name="updatedDateTime"
                md={12}
                register={register}
                errors={errors}
                placeholder=""
                disabled
              />
              <Input
                label="Booked Slot Date Time"
                name="slotDateTime"
                md={12}
                register={register}
                placeholder=""
                errors={errors}
                disabled
              />
              <Input
                name="collectionRemarks"
                placeholder=""
                md={12}
                register={register}
                errors={errors}
                as="textarea"
                disabled={areEditableFieldsDisabled}
                style={{ height: '4rem' }}
              />
            </Col>
            <Col md={4}>
              <InputNumber
                name="truckRego"
                register={register}
                md={12}
                errors={errors}
                placeholder=""
                disabled
              />
              <Input
                name="driverName"
                md={12}
                register={register}
                errors={errors}
                placeholder=""
                disabled
              />
              <Input
                name="vehicleTruckId"
                md={12}
                register={register}
                errors={errors}
                placeholder=""
                disabled
              />
              {isLoadingStageOptions ? (
                <Spinner />
              ) : (
                <Select
                  name="stage"
                  register={register}
                  md={12}
                  errors={errors}
                  options={stageOptions}
                  disabled={areEditableFieldsDisabled}
                />
              )}
              <Input
                name="trailerNumber"
                md={12}
                register={register}
                placeholder=""
                errors={errors}
                disabled
              />
              <Input
                name="truckBatNo"
                register={register}
                md={12}
                placeholder=""
                errors={errors}
                disabled
              />
              <Input
                name="containerMovementType"
                register={register}
                md={12}
                placeholder=""
                errors={errors}
                disabled
              />
              <Input
                name="deliverComments"
                placeholder=""
                md={12}
                register={register}
                errors={errors}
                disabled
                as="textarea"
                style={{ height: '4rem' }}
              />
              <Input
                name="movReqDate"
                placeholder=""
                type="date"
                md={12}
                register={register}
                errors={errors}
                disabled={areEditableFieldsDisabled}
              />
              <Input
                name="movReqTime"
                placeholder=""
                type="time"
                md={12}
                register={register}
                errors={errors}
                disabled={areEditableFieldsDisabled}
              />
            </Col>
            <Col md={4}>
              <Input
                name="pickupAddress"
                register={register}
                errors={errors}
                md={12}
                placeholder=""
                as="textarea"
                style={{ height: '4rem' }}
                disabled={areEditableFieldsDisabled}
              />

              <Input
                name="transportStart"
                label="Pickup Arrival Time"
                register={register}
                md={12}
                errors={errors}
                placeholder=""
                disabled
              />

              <Input
                name="pickupDepartureTime"
                register={register}
                md={12}
                placeholder=""
                errors={errors}
                disabled
              />

              <Input
                name="pickupComments"
                register={register}
                md={12}
                placeholder=""
                errors={errors}
                disabled
              />

              <Input
                name="deliveryAddress"
                as="textarea"
                md={12}
                placeholder=""
                register={register}
                style={{ height: '4rem' }}
                errors={errors}
                disabled={areEditableFieldsDisabled}
              />

              <Input
                name="transportEnd"
                label="Delivery Arrival Date Time"
                md={12}
                type="datetime"
                placeholder=""
                register={register}
                errors={errors}
                disabled
              />

              <Input
                name="deliverDepartureTime"
                register={register}
                md={12}
                placeholder=""
                type="time"
                errors={errors}
                disabled
              />

              <Input
                name="customerRequestDateTime"
                md={12}
                placeholder=""
                type="datetime-local"
                register={register}
                disabled={areEditableFieldsDisabled}
                errors={errors}
              />
              <Input
                name="gateInDateTime"
                md={12}
                placeholder=""
                type="datetime-local"
                register={register}
                disabled={areEditableFieldsDisabled}
                errors={errors}
              />
              <Input
                name="gateOutDateTime"
                md={12}
                placeholder=""
                type="datetime-local"
                register={register}
                disabled={areEditableFieldsDisabled}
                errors={errors}
              />
            </Col>
          </Row>
          <div className="d-flex justify-content-end">
            <Button type="submit" className="mt-3">
              Update
            </Button>
          </div>
        </Form>
      </Modal.Body>
    </Modal>
  )
}
