import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  getSearchTextState,
  setSearchText,
} from '../../../../store/slices/enigmaChat-slice'

export default function Search() {
  const dispatch = useDispatch()
  const searchText = useSelector(getSearchTextState)

  return (
    <div className="form-control search-box">
      <input
        value={searchText}
        onChange={(e) => dispatch(setSearchText(e.target.value))}
        type="text"
        className="form-control"
        placeholder="Search"
      />
    </div>
  )
}
