import React, { useEffect, useState } from 'react'
import { TableBuilder } from '../../DataMgmt/Table'
import CONSTANTS from '../../../constants.json'
import { format } from 'date-fns'

const suburbObjMini = [
  'approvedBy',
  'approvedDate',
  'cancelledBy',
  'cancelledDate',
  'consignee',
  'copiedFromModule',
  'copiedFromRateId',
  'createdBy',
  'createdDate',
  'deliverTo',
  'description',
  'dimensionUnit',
  'editedBy',
  'editedDate',
  'effectiveDate',
  'fromRange',
  'handling',
  'id',
  'isActive',
  'jobWindow',
  'mtdehire',
  'pickUpFrom',
  'rateCode',
  'rateModuleName',
  'rateProfileName',
  'rateStatus',
  'rateType',
  'remarks',
  'sellRate',
  'stageMovementType',
  'toRange',
  'typeSize',
  'vehicleJobType',
  'weightBar',
  'wharf',
  'wharfJobType',
]

const RateDetailTable = (props) => {
  const { csvArray } = props
  const [tableHeaderData, setTableHeaderData] = useState([])
  const [tableBodyData, setTableBodyData] = useState([])

  useEffect(() => {
    if (csvArray.length > 0) {
      let miniDataArray = []
      csvArray.forEach((item) => {
        let a = {}
        suburbObjMini.forEach((eachKey) => {
          a[eachKey] = item[eachKey]
        })
        miniDataArray.push(a)
      })
      transFormTableData(miniDataArray)
    }
  }, [csvArray])

  const transFormTableData = (data) => {
    let keys = Object.keys(data[0])
    let headers = []
    let tbodyData = []
    keys.forEach((each) => {
      let hdrObj = {
        Header: CONSTANTS[each] ? CONSTANTS[each] : each,
        accessor: each,
        className: each,
      }

      if (each === 'editedDate') {
        hdrObj.Cell = ({ value }) => {
          return format(new Date(value), 'dd/MM/yy')
        }
      }
      headers.push(hdrObj)
    })

    setTableHeaderData(headers)
    setTableBodyData(tbodyData)
    let newData = []
    data.forEach((eachObj, index) => {
      let newObj = {}
      keys.forEach((each, i) => {
        if (eachObj[each] === true) {
          newObj[each] = 'YES'
        } else if (eachObj[each] === false) {
          newObj[each] = 'NO'
        } else {
          newObj[each] = eachObj[each]
        }
        newObj.class = 'each'
      })

      newData.push(newObj)
    })

    setTableBodyData(newData)
  }

  return (
    <div>
      {tableBodyData.length > 0 && (
        <TableBuilder
          tableBodyData={tableBodyData}
          tableHeaderData={tableHeaderData}
          url={'../'}
        />
      )}
    </div>
  )
}

export default RateDetailTable
