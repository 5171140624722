import React from 'react'
import { Form } from 'react-bootstrap'

export default function FormErrorText({ errors, name }) {
  if (errors?.[name])
    return (
      <Form.Text className="text-danger">{errors?.[name].message}</Form.Text>
    )

  return null
}
