import React from 'react'

export default function Notes() {
  return (
    <div className="mt-3">
      <h5>Notes</h5>
      <textarea
        style={{ minHeight: '6rem' }}
        className="form-control"
        type="textarea"
      />
    </div>
  )
}
