import { yupResolver } from '@hookform/resolvers/yup'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import capitalize from 'lodash/capitalize'
import React from 'react'
import { Button, Col, Form, Spinner } from 'react-bootstrap'
import { useFieldArray, useForm } from 'react-hook-form'
import * as Yup from 'yup'
import { Info, Success } from '../../../../../components/utils/Toastify'
import fetchService from '../../../../../components/utils/fetchService'

const validationSchema = Yup.object().shape({
  ancillaries: Yup.array().of(
    Yup.object().shape({
      customerRateCode: Yup.string().required('Customer Rate Code is required'),
      description: Yup.string().required('Description is required'),
      rate: Yup.string().required('Rate is required'),
    })
  ),
})

const initialObj = {
  customerRateCode: '',
  description: '',
  rate: '',
}

export default function Ancillaries({ entity, entityId, type }) {
  const entityIdKey = `cc${capitalize(entity)}AddressId`
  const uniqueAncillaryIdKey = `cc${capitalize(entity)}AnciliiaryId`
  const queryClient = useQueryClient()

  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    reset,
  } = useForm({
    resolver: yupResolver(validationSchema),
    reValidateMode: 'onChange',
    defaultValues: { ancillaries: [initialObj] },
  })

  useQuery({
    queryKey: [`/customercard/${entity}-anciliiary?${entityIdKey}=${entityId}`],
    queryFn: async () =>
      await fetchService({
        url: `/customercard/${entity}-anciliiary?${entityIdKey}=${entityId}`,
      }).then((response) => {
        if (Array.isArray(response) && response.length > 0) {
          const data = {
            ancillaries: response,
          }
          reset(data)
          return data
        }
        return response
      }),
    refetchOnMount: 'always',
    enabled: !!entityId,
  })

  const {
    fields: ancillaries,
    append: addAncillaries,
    remove: removeAncillaries,
  } = useFieldArray({
    name: 'ancillaries',
    control,
  })

  const deleteMutation = useMutation({
    mutationFn: async ({ id }) =>
      await fetchService({
        url: `/customercard/${entity}-anciliiary/${id}`,
        method: 'DELETE',
        isFile: true,
      }),
    onSuccess: (response, variables) => {
      if (response.status === 200) {
        removeAncillaries(variables.index)
      }
      Info('Ancillary deleted successfully.')
    },
  })

  const deleteAncillary = (ancillary, index) => {
    const id = ancillary?.[uniqueAncillaryIdKey]
    if (id) {
      let answer = window.confirm('Are you sure to delete Ancillary ' + id)
      if (!answer) {
        return false
      }
      // Fetch API to delete
      deleteMutation.mutate({ id, index })
    } else {
      removeAncillaries(index)
    }
  }

  const postMutation = useMutation({
    mutationFn: async (body) =>
      await fetchService({
        url: `/customercard/${entity}-anciliiary/multi`,
        method: 'POST',
        body,
      }),
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: [
          `/customercard/${entity}-anciliiary?${entityIdKey}=${entityId}`,
        ],
      })
      Success('Details saved successfully')
    },
  })

  const onSubmitFunction = (values) => {
    const body = values.ancillaries.map((a) => ({
      ...a,
      [entityIdKey]: entityId,
    }))
    postMutation.mutate(body)
  }

  return (
    <div>
      <h2>Ancillaries</h2>
      <form autoComplete="off" onSubmit={handleSubmit(onSubmitFunction)}>
        <table className="table table-border">
          <thead>
            <tr>
              <th>S.No</th>
              <th>Customer Rate Code</th>
              <th>Description</th>
              <th>Rate</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {ancillaries.map((field, index) => (
              <tr key={field.id}>
                <td>{index + 1}</td>
                <td>
                  <Form.Group
                    className="p-1"
                    as={Col}
                    md={12}
                    controlId={`ancillaries.${index}.customerRateCode`}
                  >
                    <Form.Control
                      type="text"
                      {...register(`ancillaries.${index}.customerRateCode`)}
                      autoComplete="off"
                    />
                    {errors?.ancillaries?.[index]?.customerRateCode && (
                      <Form.Text className="text-danger">
                        {errors?.ancillaries?.[index]?.customerRateCode.message}
                      </Form.Text>
                    )}
                  </Form.Group>
                </td>
                <td>
                  <Form.Group
                    className="p-1"
                    as={Col}
                    md={12}
                    controlId={`ancillaries.${index}.description`}
                  >
                    <Form.Control
                      type="text"
                      {...register(`ancillaries.${index}.description`)}
                      autoComplete="off"
                    />
                    {errors?.ancillaries?.[index]?.description && (
                      <Form.Text className="text-danger">
                        {errors?.ancillaries?.[index]?.description.message}
                      </Form.Text>
                    )}
                  </Form.Group>
                </td>
                <td>
                  <Form.Group
                    className="p-1"
                    as={Col}
                    md={12}
                    controlId={`ancillaries.${index}.rate`}
                  >
                    <Form.Control
                      type="text"
                      {...register(`ancillaries.${index}.rate`)}
                      autoComplete="off"
                    />
                    {errors?.ancillaries?.[index]?.rate && (
                      <Form.Text className="text-danger">
                        {errors?.ancillaries?.[index]?.rate.message}
                      </Form.Text>
                    )}
                  </Form.Group>
                </td>
                <td>
                  {type !== 'view' && (
                    <div className="d-flex gap-2 justify-content-center align-items-center h-100">
                      <button
                        className="btn btn-danger btn-sm"
                        type="button"
                        disabled={deleteMutation.isLoading}
                        onClick={() => deleteAncillary(field, index)}
                      >
                        {deleteMutation.isLoading &&
                        deleteMutation.variables?.index === index ? (
                          <Spinner />
                        ) : (
                          'Delete'
                        )}
                      </button>
                    </div>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        <div className="d-flex justify-content-end">
          <button
            className="btn bg-gold btn-sm"
            type="button"
            onClick={() => addAncillaries(initialObj)}
          >
            Add
          </button>
        </div>
        <Button disabled={postMutation.isLoading} type="submit">
          {postMutation.isLoading ? <Spinner /> : 'Submit'}
        </Button>
      </form>
    </div>
  )
}
