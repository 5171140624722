export const eqPlanningColumnHeaders = [
  'orderNo',
  'driverID',
  'movement',
  'movReqDate',
  'jobType',
  'hold',
  'deliveryMethod',
  'clientDeliveryDateAndTime',
  'status',
  'customer',
  'container#',
  'typeSize',
  'cargoType',
  'weight',
  'truckID',
  'movReqTime',
  'pickUpLocation',
  'deliveryLocation',
  'doorPosition',
  'consignee',
  'deliverySuburb',
  'pickUpSuburb',
  'shipper',
  'portRef',
  'eidoPin',
  'shippingAgent',
  'emptyDelivery',
  'sealNo',
  'trailerID',
  'trailerType',
  'releaseNO',
  'emptyPickup',
  'etaDate',
  'etaTime',
  'vesselName',
  'voyageNo',
  'firstAVDate',
  'storageStartDate',
  'expClosingTime',
  'containerRemarks',
  'containerRef',
  'doReceivedDate',
  'imoCode',
  'unCode',
  'dimension',
  'collectionDate',
  'collectionTime',
  'collectionRemarks',
  'impAVDate',
  'expAVDate',
  'estStartDate',
  'estStartTime',
  'transportStartDate',
  'transportStartTime',
  'transportEndDate',
  'transportEndTime',
  'jobWindow',
  'mtDehireRef',
  'mtDehireSlot',
  'mtPickupSlot',
  'slotDate',
  'slotTime',
  'mtPickupOrDehireDate',
  'mtDehireDays',
  'pickUpAreaOrZone',
  'deliveryAreaOrZone',
  'scheduledDeliveryDateTime',
  'scheduledPickUpDateTime',
  'actualPickUpDateTime',
  'actualDeliveryDateTime',
  'transportJobType',
  'oblNo',
]
