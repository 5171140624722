import { createColumnHelper } from '@tanstack/react-table'
import { MessageIcon } from '../../../../icons'
import { useCreateJobConversationMutation } from '../../../../helper/hooks/api/allocations'
import { Spinner } from 'react-bootstrap'

const columnHelper = createColumnHelper()

const ChatColumnCell = ({ data }) => {
  const mutation = useCreateJobConversationMutation()

  const handleCreateConversation = () => {
    mutation.mutate(data.jobNo)
  }

  return (
    <div
      className="d-flex justify-content-center"
      onClick={handleCreateConversation}
    >
      <button className="unset-all" disabled={mutation.isPending}>
        {mutation.isPending ? <Spinner size="sm" /> : <MessageIcon />}
      </button>
    </div>
  )
}

const jobContainerColumns = [
  columnHelper.accessor('containerLoadNumber', {
    id: 'containerLoadNumber',
    header: 'Container Load No',
    size: 200,
  }),
  columnHelper.accessor('pickupAddress', {
    id: 'pickupAddress',
    header: 'Pick Up From ',
  }),
  columnHelper.accessor('deliveryAddress', {
    id: 'deliveryAddress',
    header: 'Deliver To',
  }),
  columnHelper.accessor('loadStatus', {
    id: 'loadStatus',
    header: 'Load Status',
  }),
  columnHelper.accessor('containerSize', {
    id: 'containerSize',
    header: 'Container Size',
    size: 170,
  }),
  columnHelper.accessor('grossWeight', {
    id: 'grossWeight',
    header: 'Container Weight ',
    size: 200,
  }),
  columnHelper.accessor('chat', {
    id: 'chat',
    header: 'Chat',
    cell: ({ row: { original } }) => <ChatColumnCell data={original} />,
  }),
]

export default jobContainerColumns
