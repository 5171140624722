import config from './config'
import axios from 'axios'

const AuthChecker = () => {
  const token = localStorage.token
  const userDetails = JSON.parse(localStorage.data)
  if (token) {
    const url = config.api.baseUrl + config.api.validate

    const newPayload = {
      jwtToken: token,
      userName: userDetails.userDetails.userName,
    }

    axios({
      method: 'POST', //you can set what request you want to be
      url: url,
      data: newPayload,
      headers: {
        Authorization: 'Bearer ' + token,
      },
    }).catch(() => {
      alert('Your session has either expired or terminated. Please login again')
      localStorage.clear()
      window.location.href = './'
    })
  } else {
    localStorage.clear()
    window.location.href = './'
  }
}

export default AuthChecker
