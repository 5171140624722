import React, { useState } from 'react'
import { yupResolver } from '@hookform/resolvers/yup'
import * as Yup from 'yup'
import axios from 'axios'
import { useForm } from 'react-hook-form'
import config from '../../../../../../config'
import { TextBox } from '../../../../../../components/utils/TextBox'
import * as Toastify from '../../../../../../components/utils/Toastify'
import useModalContainer from '../../../../../../helper/hooks/useModalContainer'
import { useSelector } from 'react-redux'
import { getLookupDataState } from '../../../../../../store/slices/lookup-slice'

const DistinctConduct = ({ contextAPIData }) => {
  const lookupsList = useSelector(getLookupDataState)
  const validationSchema = Yup.object().shape({
    directionNo: Yup.string().required('Direction No is required'),
    serviceYard: Yup.string().required('Service Yard is required'),
  })

  const [fumigationData, setFumigationData] = useState([])

  const {
    register,
    handleSubmit,
    // reset,
    formState: { errors },
    setValue,
  } = useForm({
    resolver: yupResolver(validationSchema),
    reValidateMode: 'onChange',
  })

  const setDistinctConductData = (distinctConduct) => {
    setValue('directionNo', distinctConduct.directionNo)
    setValue('inspectAfterFumigation', distinctConduct.isInspectAfterFumigation)
    setValue('isServiceYard', distinctConduct.isServiceYard)
    setValue('loa', distinctConduct.isLoa)
    setValue(
      'quarantineUnpackedInspect',
      distinctConduct.isQuarantineUnpackedInspect
    )
    setValue('sealIntactInspection', distinctConduct.isSealIntactInspection)
    setValue('serviceYard', distinctConduct.serviceYard)

    setFumigationData(distinctConduct)
  }

  const getDistinctConductData = (orderContainerDetailsId) => {
    const url =
      config.api.baseUrl +
      '/operations/order/container/distinct-conducts-fumigation?orderContainerDetailsId=' +
      orderContainerDetailsId
    const requestOptions = {
      method: 'GET',
      headers: { Authorization: 'Bearer ' + localStorage.token },
    }
    fetch(url, requestOptions)
      .then((response) => response.json())
      .then((data) => {
        if (data.length > 0) {
          setDistinctConductData(data[0])
        }
      })
      .catch((error) => {
        Toastify.Error(error?.response?.data?.error)
      })
  }

  const submitFumigation = (formData) => {
    let options = {
      orderContainerDetailsId: contextAPIData.orderContainerDetailsId,
      orderContainerFumigationId:
        fumigationData && fumigationData.orderContainerFumigationId,
      directionNo: formData.directionNo,
      isInspectAfterFumigation: formData.inspectAfterFumigation,
      isServiceYard: formData.isServiceYard,
      isLoa: formData.loa,
      isQuarantineUnpackedInspect: formData.quarantineUnpackedInspect,
      isSealIntactInspection: formData.sealIntactInspection,
      serviceYard: formData.serviceYard,
    }

    let requestType =
      fumigationData && fumigationData.orderContainerFumigationId
        ? 'PUT'
        : 'POST'
    let requestUrl =
      fumigationData && fumigationData.orderContainerFumigationId
        ? '/operations/order/container/distinct-conducts-fumigation/' +
          fumigationData.orderContainerFumigationId
        : '/operations/order/container/distinct-conducts-fumigation'

    axios({
      method: requestType, //you can set what request you want to be
      url: config.api.baseUrl + requestUrl,
      data: options,
      headers: {
        Authorization: 'Bearer ' + localStorage.token,
      },
    })
      .then(function () {
        Toastify.Success(
          'Distinct Conduct - Fumigation data saved successfully..!'
        )
        setShow(false)
      })
      .catch(function (error) {
        Toastify.Error(error?.response?.data?.error)
      })
  }

  const handleSubmitWithoutPropagation = (e) => {
    e.preventDefault()
    e.stopPropagation()
    handleSubmit(submitFumigation)(e)
  }

  const { ModalContainer, setShow } = useModalContainer({
    text: (
      <button
        type="button"
        className="unset-all d-flex flex-column align-items-center"
        onClick={(e) => {
          e.preventDefault()
          if (contextAPIData?.orderContainerDetailsId > 0) {
            getDistinctConductData(contextAPIData.orderContainerDetailsId)
            setShow(true)
          }
        }}
      >
        <img
          title="Fumigation"
          src="./assets/customer-order/distinct-conduct.png"
          alt="Fumigation"
          width="40px"
          height="40px"
          className="img"
        />
        Fumigation
      </button>
    ),
    body: (
      <form onSubmit={handleSubmitWithoutPropagation}>
        <div>
          <div className="row">
            <div className="col-md-1" />
            <div className="col-md-3">
              <h5>Direction No :</h5>
            </div>
            <div className="col-md-4 mb-4">
              <TextBox
                register={register}
                id="directionNo"
                errors={errors}
                value=""
                placeholder="Direction No"
                maxLength="30"
              />
            </div>
          </div>

          <div className="row">
            <div className="col-md-1" />
            <div className="col-md-3">
              <h5>Service Yard :</h5>
            </div>
            <div className="col-md-4 mb-4">
              <select
                className={`form-select ${
                  errors?.serviceYard ? 'is-invalid' : ''
                }`}
                id="serviceYard"
                name="serviceYard"
                {...register('serviceYard')}
              >
                <option value="">Please Select</option>
                {lookupsList?.serviceYards.map((item) => (
                  <option key={item.code} value={item.code}>
                    {item.description}
                  </option>
                ))}
              </select>
              {errors?.serviceYard && (
                <p className="invalid-feedback">
                  {errors?.serviceYard?.message}
                </p>
              )}
            </div>
          </div>

          <div className="row">
            <div className="col-md-2" />
            <div className="col-md-2 mb-4">
              <input
                className="form-check-input"
                {...register('loa')}
                type="checkbox"
                id="loa"
              />
            </div>
            <div className="col-md-4">
              <h5
                className="form-check-label"
                htmlFor="loa"
                style={{ marginTop: -5, marginLeft: 10 }}
              >
                LOA
              </h5>
            </div>
          </div>

          <div className="row">
            <div className="col-md-2" />
            <div className="col-md-2 mb-4">
              <input
                className="form-check-input"
                {...register('isServiceYard')}
                type="checkbox"
                id="isServiceYard"
              />
            </div>
            <div className="col-md-4">
              <h5
                className="form-check-label"
                htmlFor="isServiceYard"
                style={{ marginTop: -5, marginLeft: 10 }}
              >
                Service Yard
              </h5>
            </div>
          </div>

          <div className="row">
            <div className="col-md-2" />
            <div className="col-md-2 mb-4">
              <input
                className="form-check-input"
                {...register('quarantineUnpackedInspect')}
                type="checkbox"
                id="quarantineUnpackedInspect"
              />
            </div>
            <div className="col-md-4">
              <h5
                className="form-check-label "
                htmlFor="quarantineUnpackedInspect"
                style={{ marginTop: -5, marginLeft: 10 }}
              >
                Quarantine Unpacked Inspect
              </h5>
            </div>
          </div>

          <div className="row">
            <div className="col-md-2" />
            <div className="col-md-2 mb-4">
              <input
                className="form-check-input"
                {...register('sealIntactInspection')}
                type="checkbox"
                id="sealIntactInspection"
              />
            </div>
            <div className="col-md-4">
              <h5
                className="form-check-label "
                htmlFor="sealIntactInspection"
                style={{ marginTop: -5, marginLeft: 10 }}
              >
                Seal Intact Inspection
              </h5>
            </div>
          </div>

          <div className="row">
            <div className="col-md-2" />
            <div className="col-md-2 mb-4">
              <input
                className="form-check-input"
                {...register('inspectAfterFumigation')}
                type="checkbox"
                id="inspectAfterFumigation"
              />
            </div>
            <div className="col-md-3">
              <h5
                className="form-check-label "
                htmlFor="inspectAfterFumigation"
                style={{ marginTop: -5, marginLeft: 10 }}
              >
                Inspect After Fumigation
              </h5>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-8 text-right">
            <button className="btn btn-primary float-end ms-3" type="submit">
              Save
            </button>
            <button className="btn btn-secondary float-end" type="reset">
              Reset
            </button>
          </div>
        </div>
      </form>
    ),
    header: 'Distinct Conduct',
  })

  return ModalContainer
}

export default DistinctConduct
