import React from 'react'
import { Form } from 'react-bootstrap'

export default function DateSelector({ startDate, setStartDate }) {
  return (
    <Form.Select
      className="mb-3 ms-auto w-50"
      defaultValue={startDate}
      onChange={(e) => setStartDate(e.target.value)}
    >
      <option value="weekBefore">Last Week</option>
      <option value="monthBefore">Last Month</option>
      <option value="sixMonthsBefore">Last 6 Months</option>
      <option value="yearBefore">Last Year</option>
    </Form.Select>
  )
}
