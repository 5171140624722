import React, { useState } from 'react'
import useModalContainer from '../../helper/hooks/useModalContainer'

const DateRangFile = ({ header, handleSubmit }) => {

  // State variables to store the start and end dates
  const [startDate, setStartDate] = useState('')
  const [endDate, setEndDate] = useState('')

  // Event handler for updating the start date
  const handleStartDateChange = (event) => {
    setStartDate(event.target.value)
  }

  // Event handler for updating the end date
  const handleEndDateChange = (event) => {
    setEndDate(event.target.value)
  }

  // Function to handle form submission
  const handleDownload = async (event) => {
    event.preventDefault();
    handleSubmit(startDate, endDate);
  }

  // Callback function to clear dates on modal close
  const handleModalClose = () => {
    setStartDate('');
    setEndDate('');
  };

  const { ModalContainer: downloadInvoiceModal } = useModalContainer({
    header: header,
    body: (
      <form onSubmit={handleDownload}>
        <div className="row">
          <div className="col-md-2">
            <label>Start Date:</label>
          </div>
          <div className="col-md-2 mb-2">
            <input
              className="form-control"
              id="start-date"
              placeholder="yy:mm:dd"
              name="startDate"
              type="date"
              value={startDate}
              onChange={handleStartDateChange}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-md-2">
            <label>End Date:</label>
          </div>
          <div className="col-md-2 mb-2">
            <input
              className="form-control"
              id="end-date"
              placeholder="yy:mm:dd"
              name="endDate"
              type="date"
              value={endDate}
              onChange={handleEndDateChange}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-md-2">
            <button type="submit" className="btn btn-primary">
              Download
            </button>
          </div>
        </div>
      </form>
    ),
    text: header,
    btnClass: 'btn btn-primary',
    openTriggerBtnProps: { type: 'submit' },
    onCloseCallback: handleModalClose,
  })

  return (
    <div>
      {downloadInvoiceModal}
    </div>
  );
};

export default DateRangFile;