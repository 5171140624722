import { createColumnHelper } from '@tanstack/react-table'

const columnHelper = createColumnHelper()

const customerInvoiceOrdersTableCols = [
  columnHelper.accessor('orderNo', {
    id: 'orderNo',
    header: () => <span>Order No</span>,
  }),
  columnHelper.accessor('loadNo', {
    id: 'loadNo',
    header: () => <span>Load No</span>,
  }),
  columnHelper.accessor('containerNo', {
    id: 'containerNo',
    header: () => <span>Container No</span>,
  }),
  columnHelper.accessor('size', {
    id: 'size',
    header: () => <span>Size</span>,
  }),
  columnHelper.accessor('rateCode', {
    id: 'rateCode',
    header: () => <span>Rate Code</span>,
  }),
  columnHelper.accessor('description', {
    id: 'description',
    header: () => <span>Description</span>,
  }),
  columnHelper.accessor('quantity', {
    id: 'quantity',
    header: () => <span>Qty</span>,
  }),
  columnHelper.accessor('billingUnit', {
    id: 'billingUnit',
    header: () => <span>Billing Unit</span>,
  }),
  columnHelper.accessor('unitPrice', {
    id: 'unitPrice',
    header: () => <span>Unit Price</span>,
  }),
  columnHelper.accessor('amount', {
    id: 'amount',
    header: () => <span>Amount(Inc)</span>,
  }),
  columnHelper.accessor('taxAmount', {
    id: 'taxAmount',
    header: () => <span>Tax Amount</span>,
  }),
  columnHelper.accessor('remarks', {
    id: 'remarks',
    header: () => <span>Remarks</span>,
  }),
]

export default customerInvoiceOrdersTableCols
