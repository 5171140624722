import startCase from 'lodash/startCase'
import React from 'react'
import { Button, Col, Form, Row } from 'react-bootstrap'
import Accordion from 'react-bootstrap/Accordion'
import { useFieldArray } from 'react-hook-form'
import RequiredSymbol from '../../../../../components/Common/Form/RequiredSymbol'
import IconButton from '../../../../../components/styles/Buttons/IconButton'

const initialObj = {
  email: '',
  name: '',
  mobile: '',
  telephone: '',
}

export default function ContactDetails({ form, type }) {
  const {
    register,
    formState: { errors },
    control,
  } = form

  const {
    fields: operations,
    append: addOperation,
    remove: removeOperation,
  } = useFieldArray({
    name: 'operationContactDetails',
    control,
  })

  const {
    fields: accounts,
    append: addAccounts,
    remove: removeAccounts,
  } = useFieldArray({
    name: 'accountContactDetails',
    control,
  })

  const modeIsView = type === 'view'

  return (
    <Accordion>
      <Accordion.Item>
        <Accordion.Header>Contact Details</Accordion.Header>
        <Accordion.Body>
          <Row>
            <Col md={6}>
              <h3>Operation</h3>
              {operations.map((field, index) => (
                <div className="__container mt-2" key={field.id}>
                  <Row>
                    {['name', 'telephone', 'mobile', 'email'].map((key) => (
                      <Form.Group
                        key={`${key}-${field.id}`}
                        className="mb-3"
                        as={Col}
                        md={12}
                        controlId={`operationContactDetails.${index}.${key}`}
                      >
                        <Form.Label>
                          {startCase(key)} <RequiredSymbol />
                        </Form.Label>
                        <Form.Control
                          type="text"
                          placeholder={key}
                          {...register(
                            `operationContactDetails.${index}.${key}`
                          )}
                        />
                        {errors?.operationContactDetails?.[index]?.[key] && (
                          <Form.Text className="text-danger">
                            {
                              errors?.operationContactDetails?.[index][key]
                                .message
                            }
                          </Form.Text>
                        )}
                      </Form.Group>
                    ))}
                    {!modeIsView && (
                      <div className="d-flex">
                        <IconButton
                          classes="ms-auto"
                          onClick={() => removeOperation(index)}
                        >
                          <img
                            style={{ width: '30px', height: '30px' }}
                            className="w-100"
                            src="./assets/delete-red.svg"
                            alt="delete"
                          />
                        </IconButton>
                      </div>
                    )}
                  </Row>
                </div>
              ))}
              {!modeIsView && (
                <div className="d-flex justify-content-center mt-3">
                  <Button onClick={() => addOperation(initialObj)}>
                    <span className="h3">+</span> Add
                  </Button>
                </div>
              )}
            </Col>
            <Col md={6}>
              <h3>Accounts</h3>
              {accounts.map((field, index) => (
                <div className="__container mt-2" key={field.id}>
                  <Row>
                    {['name', 'telephone', 'mobile', 'email'].map((key) => (
                      <Form.Group
                        key={`${key}-${field.id}`}
                        className="mb-3"
                        as={Col}
                        md={12}
                        controlId={`accountContactDetails.${index}.${key}`}
                      >
                        <Form.Label>
                          {startCase(key)} <RequiredSymbol />
                        </Form.Label>
                        <Form.Control
                          type="text"
                          placeholder={key}
                          {...register(`accountContactDetails.${index}.${key}`)}
                          autoComplete="off"
                        />
                        {errors?.accountContactDetails?.[index]?.[key] && (
                          <Form.Text className="text-danger">
                            {
                              errors?.accountContactDetails?.[index][key]
                                .message
                            }
                          </Form.Text>
                        )}
                      </Form.Group>
                    ))}
                    {!modeIsView && (
                      <div className="d-flex">
                        <IconButton
                          classes="ms-auto"
                          onClick={() => removeAccounts(index)}
                        >
                          <img
                            style={{ width: '30px', height: '30px' }}
                            className="w-100"
                            src="./assets/delete-red.svg"
                            alt="delete"
                          />
                        </IconButton>
                      </div>
                    )}
                  </Row>
                </div>
              ))}
              {!modeIsView && (
                <div className="d-flex justify-content-center mt-3">
                  <Button onClick={() => addAccounts(initialObj)}>
                    <span className="h3">+</span> Add
                  </Button>
                </div>
              )}
            </Col>
          </Row>
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
  )
}
