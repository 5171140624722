import React, { useState } from 'react'
import Tabs from 'react-bootstrap/Tabs'
import Tab from 'react-bootstrap/Tab'
import EmailInvoiceCustomerTab from './sub-components/EmailInvoiceCustomerTab'

const EMAIL_INVOICE = 'email'
const PREVIEW_INVOICE = 'prev-email'

export default function EmailInvoice() {
  const [activeTab, setActiveTab] = useState(EMAIL_INVOICE)

  return (
    <div className="email-invoice-wrapper">
      <header>
        <h1>Email Invoice</h1>
      </header>
      <main>
        <Tabs
          defaultActiveKey={activeTab}
          className="mb-3"
          style={{ marginLeft: '0px' }}
          onSelect={(key) => setActiveTab(key)}
        >
          <Tab eventKey={EMAIL_INVOICE} title="Email invoice for customers">
            <EmailInvoiceCustomerTab />
          </Tab>
          <Tab eventKey={PREVIEW_INVOICE} title="Preview Invoice"></Tab>
        </Tabs>
      </main>
    </div>
  )
}
