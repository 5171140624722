import axios from 'axios'
import { format } from 'date-fns'
import isEmpty from 'lodash/isEmpty'
import React, { useEffect, useState } from 'react'
import Dropzone from 'react-dropzone'
import config from '../../../config'
import CONSTANTS from '../../../constants.json'
import { TableBuilder } from '../../DataMgmt/Table'
import { Error } from '../../utils/Toastify'

// const tableDataRef = ['fileName', 'createdBy', 'ccDocumentId', 'createdDate']

const UploadDocs = (props) => {
  const { customerCardId, type } = props
  const [filesList, setFilesList] = useState({
    data: [],
    igniter: new Date(),
  })
  const [showSuccessMsg, setShowSuccessMsg] = useState(false)
  const [showError, setShowError] = useState(false)
  const [tableHeaderData, setTableHeaderData] = useState([])
  const [tableBodyData, setTableBodyData] = useState([])

  const [files, setFiles] = React.useState([])
  const onDrop = React.useCallback((acceptedFiles) => {
    setFiles(() => [...acceptedFiles])
  }, [])

  const headersToShow = ['fileName', 'createdBy', 'active']

  const submitImportZone = () => {
    let formData = new FormData()

    const url =
      config.api.baseUrl +
      '/customercard/document/upload?customerCardId=' +
      customerCardId

    if (isEmpty(files)) {
      Error('Please upload a file!!!')
      return
    }
    formData.append('file', files[0])
    setShowSuccessMsg(false)
    setShowError(false)
    axios
      .post(url, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: 'Bearer ' + localStorage.token,
        },
      })
      .then(function (response) {
        props.setEnableSpinner(false)
        setShowSuccessMsg(true)
        setFilesList({
          data: [...filesList.data, response.data],
          igniter: new Date(),
        })
        document.getElementById('uploadDoc').value = ''
        setFiles([])
      })
      .catch(function (error) {
        props.setEnableSpinner(false)
        if (error?.response?.data?.data) {
          setShowError(error?.response?.data?.data)
        } else {
          setShowError(true)
        }
      })
  }

  useEffect(() => {
    if (filesList.data.length > 0) {
      transFormTableData(filesList.data)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filesList])

  useEffect(() => {
    const url =
      config.api.baseUrl +
      '/customercard/document?customerCardId=' +
      customerCardId
    const requestOptions = {
      method: 'GET',
      headers: { Authorization: 'Bearer ' + localStorage.token },
    }
    if (customerCardId)
      fetch(url, requestOptions)
        .then((response) => response.json())
        .then((data) => {
          setFilesList({ data: data, igniter: new Date() })
        })
        .catch((error) => {
          console.error('fetch error', error)
        })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const transFormTableData = (data) => {
    let keys = Object.keys(data[0])
    let headers = []
    let tbodyData = []
    keys.forEach((each) => {
      if (headersToShow.includes(each)) {
        let hdrObj = {
          Header: CONSTANTS[each] ? CONSTANTS[each] : each,
          accessor: each,
          className: each,
        }

        if (each === 'lastModifiedDate') {
          hdrObj.Cell = ({ value }) => {
            return format(new Date(value), 'dd/MM/yy')
          }
        }
        headers.push(hdrObj)
      }
    })
    headers.push({
      width: 200,
      Header: 'Actions',
      accessor: 'name',

      Cell: ({ cell, row }) => (
        <span className="d-flex">
          <button
            value={cell.row.values.id}
            type="button"
            style={{ width: '40px', height: '40px' }}
            className="btn btn-sm btn-primary"
            onClick={viewItem.bind(cell.row.values.id, row.index, row.original)}
          >
            <img src="../assets/download.svg" alt="download" />
          </button>

          <button
            value={cell.row.values.id}
            style={{ width: '40px', height: '40px' }}
            type="button"
            className="btn btn-sm btn-secondary ms-2"
            onClick={deleteItem.bind(
              cell.row.values.id,
              row.index,
              cell.row.original
            )}
          >
            <img src="../assets/delete.svg" alt="download" />
          </button>
        </span>
      ),
    })

    setTableHeaderData(headers)
    setTableBodyData(tbodyData)
    let newData = []
    data.forEach((eachObj) => {
      let newObj = {}
      keys.forEach((each) => {
        if (eachObj[each] === true) {
          newObj[each] = 'YES'
        } else if (eachObj[each] === false) {
          newObj[each] = 'NO'
        } else {
          newObj[each] = eachObj[each]
        }
        newObj.class = 'each'
      })

      newData.push(newObj)
    })
    setTableBodyData(newData)
  }

  const deleteItem = (index, recData, event) => {
    let answer = window.confirm(
      'Are you sure to delete suburb profile of ' + recData.fileName
    )
    if (!answer) {
      return false
    }

    const url =
      config.api.baseUrl + '/customercard/document/' + recData.ccDocumentId
    const requestOptions = {
      method: 'DELETE',
      headers: { Authorization: 'Bearer ' + localStorage.token },
    }

    fetch(url, requestOptions)
      .then((response) => response.text())
      .then((data) => {
        alert(recData.fileName + ' has deleted successfully')
        let newArray = filesList.data || []
        newArray.splice(index, 1)
        setFilesList({ data: newArray, igniter: new Date() })
      })
      .catch((error) => {
        console.error('fetch error', error)
        alert('Unable to delete file please try after some time!')
      })
  }

  const viewItem = (index, recData, _event) => {
    const url =
      config.api.baseUrl +
      '/customercard/document/download/' +
      recData.ccDocumentId
    const requestOptions = {
      method: 'GET',
      headers: { Authorization: 'Bearer ' + localStorage.token },
    }
    fetch(url, requestOptions)
      .then((response) => response.blob())
      .then((blob) => {
        let _url = window.URL.createObjectURL(blob)
        let a = document.createElement('a')
        a.href = _url
        a.download = recData.fileName
        document.body.appendChild(a) // we need to append the element to the dom -> otherwise it will not work in firefox
        a.click()
        a.remove() //afterwards we remove the element again
      })
      .catch((error) => {
        console.error('fetch error', error)
      })
  }

  const fileList = files.map((file) => (
    <li className="file-info" key={file.path}>
      {file.path} - {file.size} bytes
    </li>
  ))

  return (
    <div className="">
      {/* <form onSubmit={uploadCSV}> */}
      <div className="row">
        <div className="col-md-9">
          <input type="hidden" value="1" id="customerCardId" />
          <h4>Upload Documents</h4>
        </div>
      </div>

      {type !== 'view' && (
        <>
          <Dropzone
            onDrop={onDrop}
            multiple={false}
            accept={{
              'text/csv': [],
              'application/pdf': ['.pdf'],
              'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
                [],
            }}
          >
            {({ getRootProps, getInputProps }) => (
              <div className="drop-zone-container" {...getRootProps()}>
                <input {...getInputProps()} id="uploadDoc" />
                <h4>Drag a file here</h4>

                <p>{fileList}</p>
              </div>
            )}
          </Dropzone>
          <div className="text-end my-2">
            <button
              className="btn btn-primary rounded-3"
              style={{ maxWidth: '100px' }}
              onClick={() => submitImportZone()}
            >
              <img
                className="w-100 p-2"
                src="../assets/Upload_Blue.png"
                alt="upload"
              />
            </button>
          </div>

          {showError && (
            <div className="text-danger my-4">
              <p>There is an issue in form submission. please try later.</p>
              {showError.length &&
                showError.map((eachError, i) => {
                  return (
                    <p key={i}>
                      {eachError.key} - {eachError.message}
                    </p>
                  )
                })}
            </div>
          )}

          {showSuccessMsg && (
            <p className="text-success my-4">
              Form has submitted successfully!
            </p>
          )}
        </>
      )}
      {filesList.data.length === 0 && (
        <p className="my-5">No Files has been uploaded for this customer</p>
      )}
      {tableBodyData.length > 0 && (
        <TableBuilder
          tableBodyData={tableBodyData}
          tableHeaderData={tableHeaderData}
          url={'../'}
        />
      )}
    </div>
  )
}

export default UploadDocs
