import React, { Fragment } from 'react'

export default function ContainerWithHeading({
  heading,
  containerClassNames = '',
  children,
  Wrapper = Fragment,
  wrapperProps,
  HeaderComponent = 'h3',
  headerClassName = 'my-1',
  headerComponentProps = {},
  headerContainerZIndex = -1,
  containerProps = {},
}) {
  return (
    <Wrapper {...wrapperProps}>
      <div
        className="__container-header"
        style={{ zIndex: headerContainerZIndex }}
      >
        <HeaderComponent className={headerClassName} {...headerComponentProps}>
          {heading}
        </HeaderComponent>
      </div>
      {children && (
        <div
          className={`__no-top-border ${containerClassNames}`}
          {...containerProps}
        >
          {children}
        </div>
      )}
    </Wrapper>
  )
}
