import React, { useState, useEffect, useRef } from 'react'
import axios from 'axios'
import { toast } from 'react-toastify'

import { Form } from '../../Common/Form'
import Spinner from '../../Spinner/Spinner'
import config from '../../../config'
import { useRouter } from '../../../helper/hooks/useRouter'

import { formData } from './PickupFormData'
import Table from '../../Common/Table/Table'
import { useSelector } from 'react-redux'
import { getLookupDataState } from '../../../store/slices/lookup-slice'

const Pickup = (props) => {
  document.body.setAttribute('data-class', 'container')
  const { mode } = props
  const router = useRouter()
  const currentRecordId = router.match.params['id']

  const lookupData = useSelector(getLookupDataState)
  const [formFields, setFormFields] = useState({})
  const [loading, setLoading] = useState(false)
  const [listData, setListData] = useState([])
  const [detail, setDetail] = useState(null)
  const [error, setError] = useState()
  const displayFormRef = useRef()
  const formRef = useRef()

  const setLookupFields = (formData, lookupData) => {
    if (lookupData) {
      formData?.forEach(async (field) => {
        if (field.loadLookUp && field.loadLookUp === 'loadStatusEnums') {
          getLookUpOptionsFromArray(field)
        }
        if (field.loadLookUp === 'areaCodes') {
          getLookUpOptionsFromArray(field, 'areaCode', 'description', true)
        }
      })
    }
    setFormFields(formData)
  }

  const getLookUpOptionsFromArray = (field, id, label, combine = false) => {
    let options = []
    lookupData[field.loadLookUp].forEach((val) => {
      let option = {
        id: val[id] ?? val,
        label: combine ? `${val[id]} (${val[label]})` : val[label] ?? val,
      }
      options.push(option)
    })
    field.options = options
  }

  const onSubmit = (values) => {
    setError(null)
    setLoading(true)
    axios({
      method: 'PUT',
      url: `${config.api.baseUrl}/container/collection/${currentRecordId}`,
      headers: {
        Authorization: 'Bearer ' + localStorage.token,
      },
      data: values,
    })
      .then(async () => {
        toast.success('Successfully saved')
        await fetchList()
        await fetchDetailById()
      })
      .catch((error) => {
        toast.error('Something went wrong!')
        setError(error?.response?.data)
      })
      .finally(() => {
        setLoading(false)
      })
  }

  const onReset = () => {
    formRef.current?.resetForm()
  }

  const fetchList = async () => {
    setError(null)
    setLoading(true)
    axios({
      method: 'GET',
      url: `${config.api.baseUrl}/container/collection`,
      headers: {
        Authorization: 'Bearer ' + localStorage.token,
      },
    })
      .then((response) => {
        setListData(response?.data)
      })
      .catch((error) => {
        console.error('fetch error', error)
        toast.error(error?.response?.data?.error ?? 'Something went wrong!')
      })
      .finally(() => {
        setLoading(false)
      })
  }

  const fetchDetailById = async (id) => {
    setError(null)
    setLoading(true)
    axios({
      method: 'GET',
      url: `${config.api.baseUrl}/container/collection/${currentRecordId}`,
      headers: {
        Authorization: 'Bearer ' + localStorage.token,
      },
    })
      .then((response) => {
        setDetail(response?.data)
        displayFormRef.current?.scrollIntoView()
      })
      .catch((error) => {
        setError(error?.response?.data)
        toast.error(
          error?.response?.data?.error ??
            error?.response?.data?.data?.[0]?.message ??
            'Something went wrong!'
        )
      })
      .finally(() => {
        setLoading(false)
      })
  }

  useEffect(() => {
    setLookupFields(formData, lookupData)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lookupData])

  useEffect(() => {
    fetchList()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (currentRecordId && lookupData) {
      fetchDetailById()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentRecordId, lookupData])

  return (
    <div className="container-fluid">
      {loading && <Spinner />}
      <div className="card white-bg-card" style={{ marginBottom: 100 }}>
        <div className="card-body">
          <div className="row">
            <h2>Container Pickup</h2>
            <div className="col-md-12 mt-3">
              <Table
                className="table"
                data={listData?.map((r) => ({
                  ...r,
                  pickUpSuburbCode: r.pickUpSuburbCode
                    ? `${r.pickUpSuburbCode} (${
                        lookupData?.['areaCodes']?.find(
                          (a) => a.areaCode === r.pickUpSuburbCode
                        )?.description
                      })`
                    : null,
                  deliverySuburbCode: r.deliverySuburbCode
                    ? `${r.deliverySuburbCode} (${
                        lookupData?.['areaCodes']?.find(
                          (a) => a.areaCode === r.deliverySuburbCode
                        )?.description
                      })`
                    : null,
                  actions: (
                    <button
                      className="btn btn-primary btn-sm"
                      onClick={() => {
                        router.push(
                          `${
                            (router.location.pathname?.includes('pickup')
                              ? router.location.pathname
                              : `${router.location.pathname}/pickup`
                            ).split('/edit')[0]
                          }/edit/${r['containerPickupId']}`
                        )
                      }}
                    >
                      Edit
                    </button>
                  ),
                }))}
                columns={[...formData, { label: '', id: 'actions' }]}
              />
            </div>
          </div>

          {mode === 'edit' && !!detail && (
            <div className="row mt-2 display-form" ref={displayFormRef}>
              <h2 className="blue-title">Display Form</h2>
              <div className="col-md-12 mt-4">
                {formFields?.length > 0 && lookupData && (
                  <Form
                    ref={formRef}
                    onSubmit={onSubmit}
                    formData={formFields}
                    columns={4}
                    uniqueReferenceKey={''}
                    formMode={mode}
                    defaultValues={detail}
                    loading={loading}
                  >
                    <div className="col-12 my-4">
                      <div className="module-footer">
                        <div
                          style={{
                            maxWidth: '400px',
                            color: 'red',
                          }}
                        >
                          <ul>
                            {error?.data?.map((d, i) => (
                              <li key={i}>{`${d?.key} - ${d?.message}`}</li>
                            ))}
                          </ul>
                        </div>
                        <div>
                          <button
                            type="button"
                            className="btn btn-primary"
                            onClick={onReset}
                          >
                            Reset
                          </button>
                          <button
                            type="submit"
                            className="btn btn-primary ms-2"
                          >
                            Submit
                          </button>
                          <button
                            type="button"
                            className="btn btn-danger ms-2"
                            onClick={() => {
                              router.push(
                                `${router.location.pathname.split('/edit')[0]}`
                              )
                            }}
                          >
                            Exit
                          </button>
                        </div>
                      </div>
                    </div>
                  </Form>
                )}
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default Pickup
