const vesselDetails = {
  name: 'Vessel Details',
  apiUrlAppend: false,
  path: '/dataMgmt/customer/vesselDetails',
  req: '/',
  columns: 2,
  fields: [
    {
      name: 'Vessel ID',
      label: 'Vessel ID',
      type: 'text',
      validation: 'Alphabetic',
      fieldId: 'vesselid',
      id: 'vesselid',
      validationType: 'string',
      validations: [
        {
          type: 'required',
          params: ['Vessel ID is required'],
        },
        {
          type: 'max',
          params: [30, 'Vessel ID cannot be more than 20 characters'],
        },
        {
          type: 'min',
          params: [3, 'Vessel ID should be min 3 characters'],
        },
      ],
    },
    {
      name: 'Vessel Name',
      label: 'Vessel Name',
      type: 'text',
      validation: 'Alphabetic',
      fieldId: 'vesselname',
      id: 'vesselname',
      validationType: 'string',
      validations: [
        {
          type: 'required',
          params: ['Vessel Name is required'],
        },
        {
          type: 'max',
          params: [50, 'Vessel Name cannot be more than 50 characters'],
        },
        {
          type: 'min',
          params: [3, 'Vessel Name should be min 3 characters'],
        },
      ],
    },
    {
      name: 'Lloyds No',
      label: 'Lloyds No',
      type: 'text',
      validation: 'Alphabetic',
      fieldId: 'lloydsno',
      id: 'lloydsno',
      validationType: 'string',
      validations: [
        {
          type: 'required',
          params: ['Lloyds No is required'],
        },
        {
          type: 'max',
          params: [50, 'Lloyds No cannot be more than 50 characters'],
        },
        {
          type: 'min',
          params: [3, 'Lloyds No should be min 3 characters'],
        },
      ],
    },
    {
      name: 'Country Code',
      label: 'Country Code',
      type: 'select',
      loadLookUp: 'countryCodes',
      validation: 'Alphabetic',
      fieldId: 'countrycode',
      id: 'countrycode',
      validationType: 'string',
      validations: [],
    },
  ],
}
export default vesselDetails
