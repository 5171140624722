import { faPen } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import PortSlot from '../../ordersOperations/OrdersContainers/components/PortSlot'

export default function EditPortSlotCell({ cell }) {
  return (
    <div className="d-flex align-items-center h-100 px-1">
      {cell.getValue()}
      <PortSlot
        orderContainerDetailsId={cell.row.original?.orderContainerDetailsId}
        btnProps={{
          className: 'btn p-0 border-0 ms-auto',
        }}
        defaultWharfCode={cell.row.original.wharf}
        btnIcon={<FontAwesomeIcon icon={faPen} style={{ color: 'darkblue' }} />}
      />
    </div>
  )
}
