import { useState, useEffect } from 'react'
import axios from 'axios'

import AgreedRatesForm from '../AgreedRatesFormV2'
import formData from './CustomerAgreedRatesFormData'

import config from '../../../../config'

const AddCustomerAgreedRatesForm = (props) => {
  const {
    lookupsList,
    setCustomerAgreedRates,
    customerAgreedRateIdx,
    defaultData,
    customerCardId,
  } = props
  const [formFields, setFormFields] = useState([])

  const url = '/customercard/crd-agreed-rates/multi'

  useEffect(() => {
    setLookupFields(formData, lookupsList)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lookupsList])

  const setLookupFields = async (_formData, lookupData) => {
    if (lookupData) {
      _formData.forEach(async (fieldGroup) => {
        fieldGroup.fields.forEach((field) => {
          if (field.fieldId === 'rateType') {
            const options = []
            for (const [key, value] of Object.entries(
              lookupData[field.loadLookUp]
            )) {
              options.push({
                id: key,
                label: value,
              })
            }
            field.options = options
          } else if (field.loadLookUp) {
            getLookUpOptionsFromArray(lookupData, field)
          }
        })
      })
    }
    setFormFields([..._formData])
  }

  const getLookUpOptionsFromArray = (data, field) => {
    let options = []
    data[field.loadLookUp].forEach((val) => {
      let option = {
        id:
          field.validationType === 'number'
            ? parseInt(val?.[field.valueId])
            : val?.[field.valueId],
        label: val?.[field.displayId],
      }
      options.push(option)
    })
    field.options = options
  }

  const submitForm = (data) => {
    const getSuburbObj = { ...data }
    const formID = getSuburbObj.customerRateDetailId
    let newFormData = { ...data }
    newFormData.customerRateDetailId = formID

    let eachFormattedData = { ...defaultData, customerCardId }
    eachFormattedData.ccConsigneeAgreedRatesId = defaultData?.id // needed when creating new rate?
    delete eachFormattedData.id

    Object.keys(newFormData).forEach((item) => {
      let itemFormatted = newFormData[item]
      const numericField = formData.find((formGroup) =>
        formGroup.fields.find(
          (field) => field.validationType === 'number' && field.fieldId === item
        )
      )
      if (numericField && itemFormatted) {
        itemFormatted = parseInt(itemFormatted)
      }
      if (itemFormatted === '') {
        itemFormatted = null //::TODO:: is this mandatory to make null
      } else if (
        itemFormatted &&
        typeof itemFormatted === String &&
        itemFormatted.startsWith('$')
      ) {
        itemFormatted = item.replace('$', '')
      } else if (itemFormatted === 'NULL') {
        itemFormatted = null //::TODO:: is this mandatory to make null
      }
      eachFormattedData[item] = itemFormatted
    })
    delete eachFormattedData.weightBar

    let formattedFormData = []
    formattedFormData.push(eachFormattedData)

    return axios({
      method: props.formActionType === 'add' ? 'POST' : 'PUT', //you can set what request you want to be
      url: config.api.baseUrl + url,
      data: formattedFormData,
      headers: {
        Authorization: 'Bearer ' + localStorage.token,
        'Content-Type': 'application/json',
      },
    })
      .then((res) => {
        if (props.formActionType === 'import') {
          setCustomerAgreedRates((rates) => {
            let newRates = [...rates]
            if (newRates.length > customerAgreedRateIdx)
              newRates.splice(customerAgreedRateIdx, 1, res.data[0])
            return newRates
          })
        } else if (props.formActionType === 'add') {
          setCustomerAgreedRates((rates) => [...rates, res.data[0]])
        }
        return Promise.resolve(res)
      })
      .catch((error) => {
        return Promise.reject(error)
      })
  }

  return (
    <AgreedRatesForm
      setAgreedRates={props.setCustomerAgreedRates}
      type={props.type}
      defaultData={props.defaultData}
      formActionType={props.formActionType}
      agreedRateIdx={props.customerAgreedRateIdx}
      closeFormAction={props.cancelAction}
      formData={formFields}
      onSubmit={submitForm}
    />
  )
}

export default AddCustomerAgreedRatesForm
