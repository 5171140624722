import React, { useEffect } from 'react'
import { yupResolver } from '@hookform/resolvers/yup'
import * as Yup from 'yup'
import axios from 'axios'
import { useForm } from 'react-hook-form'
import config from '../../../config'
import { TextBox } from '../../utils/TextBox'
import { Select } from '../../utils/Select'
import { format } from 'date-fns'
import { toast } from 'react-toastify'
import { Modal } from 'react-bootstrap'
import { renderOptionsFromDataArr } from '../../utils/Utils'
import TextInputField from '../../Common/Form/Fields/TextInputField'

const AddSuburbData = (props) => {
  const {
    csvArray,
    setCsvArray,
    type,
    lookupsList,
    defaultData,
    profileFormType,
    profileFormId,
    setEnableForm,
    customerCardId,
    shipperData,
  } = props

  const validationSchema = Yup.object().shape({
    addressId: Yup.string(),
    branchCode: Yup.string().required('This field is required').nullable(),
    cargoHandling: Yup.string(),
    cargotype: Yup.string(),
    //ccSuburbProfileId: Yup.string(), //TODO:: no field added in the UI api it is needed
    commodityCode: Yup.string().required('This field is required').nullable(),
    companyType: Yup.string().required('This field is required').nullable(),
    containerCategory: Yup.string()
      .required('This field is required')
      .nullable(),
    containerSize: Yup.string(),
    currencyCode: Yup.string(),
    customerCode: Yup.string().required('This field is required').nullable(),
    deliveryCode: Yup.string(),
    dimensionUnit: Yup.string(),
    distinctConductCode: Yup.string(),
    dropOffCode: Yup.string(),
    effectiveDate: Yup.date().nullable(),
    expiryDate: Yup.date().nullable(),
    // isActive:
    jobType: Yup.string().nullable(),
    jobWindow: Yup.string(),
    maxAmt: Yup.number().nullable(),
    minAmt: Yup.number().nullable(),
    packageCode: Yup.string(),
    pickUpCode: Yup.string(),
    portOperator: Yup.string(),
    priceType: Yup.string(),
    quotationNo: Yup.string().required('quotation No is required').nullable(),
    quoteDate: Yup.date({ accept: ['', undefined, null] })
      .nullable()
      .default(null),
    rateCode: Yup.string({ accept: ['', undefined, null] }),
    recordID: Yup.string({ accept: ['', undefined, null] }),
    remark: Yup.string(),
    rowType: Yup.string().required('Row Type No is required').nullable(),
    sellRate: Yup.number().required('Sell Rate No is required').nullable(),
    serviceType: Yup.string(),
    shipment: Yup.string(),
    shipperConsignee: Yup.string(),
    slabUnitFrom: Yup.number().nullable(),
    slabUnitTo: Yup.number().nullable(),
    stageMovementType: Yup.string(), //does it need the integer
    vehicleJobType: Yup.string(),
    vendorcode: Yup.string(), //does it need the integer
    // Location
    location1AreaCode: Yup.string().nullable(),
    location1Description: Yup.string().nullable(),
    location2AreaCode: Yup.string().nullable(),
    location2Description: Yup.string().nullable(),
  })

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm({
    resolver: yupResolver(validationSchema),
    reValidateMode: 'onChange',
    defaultValues: defaultData,
  })

  console.log(errors)

  useEffect(() => {
    setValue('quoteDate', new Date())
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const submitAddZone = (formData) => {
    let newArray = csvArray.data || []
    let method = 'PUT'
    if (profileFormType === 'add') {
      method = 'POST'
      newArray.push(formData)
      setCsvArray({ data: newArray, igniter: new Date() })
    } else if (profileFormType === 'import') {
      method = 'POST'
      newArray.splice(profileFormId, 1, formData)
      setCsvArray({ data: newArray, igniter: new Date() })
    }
    const getSuburbObj = profileFormId ? csvArray.data[profileFormId] : formData
    let newFormData = formData
    let formID
    if (profileFormId) {
      formID = getSuburbObj.ccSuburbProfileId
      newFormData.ccSuburbProfileId = formID
    }

    let eachFormattedData = { customerCardId }
    Object.keys(newFormData).forEach((item) => {
      let itemFormatted = newFormData[item]
      if (itemFormatted === '') {
        itemFormatted = null //::TODO:: is this mandatory to make null
      } else if (
        itemFormatted &&
        typeof itemFormatted === String &&
        itemFormatted.startsWith('$')
      ) {
        itemFormatted = item.replace('$', '')
      } else if (itemFormatted === 'NULL') {
        itemFormatted = null //::TODO:: is this mandatory to make null
      }

      if (
        item === 'effectiveDate' ||
        item === 'expiryDate' ||
        item === 'quoteDate'
      )
        itemFormatted = format(itemFormatted, 'yyy-MM-dd')
      eachFormattedData[item] = itemFormatted
    })

    axios({
      method: method, //you can set what request you want to be
      url:
        config.api.baseUrl + '/customercard/suburb-profile/' + (formID || ''),
      data: eachFormattedData,
      headers: {
        Authorization: 'Bearer ' + localStorage.token,
      },
    })
      .then(function () {
        setEnableForm(false)
        toast.success('Form submitted successfully')
      })
      .catch(function (error) {
        toast.error(error.message || 'Form could not be submitted')
      })
  }

  const formBody = (
    <div className="card2">
      <div className="card-body2">
        <form onSubmit={handleSubmit(submitAddZone)}>
          <div>
            <div className="bg-gray">
              <div className="row">
                <article className="col-md-4">
                  <div className="row">
                    <div className="col-md-4">
                      <label>Row Type</label>
                    </div>
                    <div className="col-md-8 mb-4">
                      <TextBox
                        register={register}
                        id="rowType"
                        errors={errors}
                        placeholder="rowType"
                        maxLength="20"
                      />
                    </div>
                  </div>
                </article>
                <article className="col-md-4">
                  <div className="row">
                    <div className="col-md-4">
                      <label>Company Type</label>
                    </div>
                    <div className="col-md-8 mb-4">
                      <TextBox
                        register={register}
                        id="companyType"
                        errors={errors}
                        placeholder="companyType"
                        maxLength="20"
                      />
                    </div>
                  </div>
                </article>

                <article className="col-md-4">
                  <div className="row">
                    <div className="col-md-4">
                      <label>Branch Code</label>
                    </div>
                    <div className="col-md-8 mb-4">
                      <TextBox
                        register={register}
                        id="branchCode"
                        errors={errors}
                        placeholder="branch Code"
                        maxLength="20"
                      />
                    </div>
                  </div>
                </article>

                <article className="col-md-4">
                  <div className="row">
                    <div className="col-md-4">
                      <label>Quotation No</label>
                    </div>
                    <div className="col-md-8 mb-4">
                      <TextBox
                        register={register}
                        id="quotationNo"
                        errors={errors}
                        placeholder="quotationNo"
                        maxLength="20"
                      />
                    </div>
                  </div>
                </article>

                <article className="col-md-4">
                  <div className="row">
                    <div className="col-md-4">
                      <label>Stage Movement Type</label>
                    </div>
                    <div className="col-md-8 mb-4">
                      <select
                        className={`form-select ${
                          errors?.stageMovementType ? 'is-invalid' : ''
                        }`}
                        id="stageMovementType"
                        name="stageMovementType"
                        {...register('stageMovementType')}
                        disabled={type === 'view'}
                      >
                        <option value="">Please Select</option>
                        {lookupsList?.stageMovementTypes?.map((item, i) => (
                          <option key={i} value={item.transportStage}>
                            {item.description}
                          </option>
                        ))}
                      </select>
                      {errors?.stageMovementType && (
                        <p className="invalid-feedback">
                          {errors?.stageMovementType?.message}
                        </p>
                      )}
                    </div>
                  </div>
                </article>

                <article className="col-md-4">
                  <div className="row">
                    <div className="col-md-4">
                      <label>Job Type</label>
                    </div>
                    <div className="col-md-8 mb-4">
                      <Select
                        register={register}
                        id="jobType"
                        errors={errors}
                        data={lookupsList.wharfJobTypes}
                        displayKey="description"
                        valueKey="id"
                        type="edit"
                      />
                    </div>
                  </div>
                </article>
              </div>
            </div>

            <div className="odd-row">
              <div className="row">
                <article className="col-md-4">
                  <div className="row">
                    <div className="col-md-4">
                      <label>Quote Date</label>
                    </div>
                    <div className="col-md-8 mb-4">
                      <input
                        {...register('quoteDate')}
                        id="quoteDate"
                        className={`form-control ${
                          errors?.quoteDate ? 'is-invalid' : ''
                        }`}
                        placeholder="quoteDate"
                        maxLength="20"
                        type="date"
                      />
                    </div>
                  </div>
                </article>
                <article className="col-md-4">
                  <div className="row">
                    <div className="col-md-4">
                      <label>Effective Date</label>
                    </div>
                    <div className="col-md-8 mb-4">
                      <input
                        type="date"
                        {...register('effectiveDate')}
                        className={`form-control ${
                          errors?.effectiveDate ? 'is-invalid' : ''
                        }`}
                        placeholder="Effective Date"
                      />
                      {errors?.effectiveDate && (
                        <p className="invalid-feedback">
                          {errors?.effectiveDate?.message}
                        </p>
                      )}
                    </div>
                  </div>
                </article>

                <article className="col-md-4">
                  <div className="row">
                    <div className="col-md-4">
                      <label>Expiry Date</label>
                    </div>
                    <div className="col-md-8 mb-4">
                      <input
                        type="date"
                        {...register('expiryDate')}
                        className={`form-control ${
                          errors?.expiryDate ? 'is-invalid' : ''
                        }`}
                        placeholder="Expiry Date"
                      />
                      {errors?.expiryDate && (
                        <p className="invalid-feedback">
                          {errors?.expiryDate?.message}
                        </p>
                      )}
                    </div>
                  </div>
                </article>

                <article className="col-md-4">
                  <div className="row">
                    <div className="col-md-4">
                      <label>Address ID</label>
                    </div>
                    <div className="col-md-8 mb-4">
                      <TextBox
                        register={register}
                        id="addressId"
                        errors={errors}
                        placeholder="address Id"
                        maxLength="200"
                      />
                    </div>
                  </div>
                </article>

                <article className="col-md-4">
                  <div className="row">
                    <div className="col-md-4">
                      <label>Shipment</label>
                    </div>
                    <div className="col-md-8 mb-4">
                      <TextBox
                        register={register}
                        id="shipment"
                        errors={errors}
                        placeholder="Shipment"
                        maxLength="30"
                      />
                    </div>
                  </div>
                </article>

                <article className="col-md-4">
                  <div className="row">
                    <div className="col-md-4">
                      <label>Service Type</label>
                    </div>
                    <div className="col-md-8 mb-4">
                      <TextBox
                        register={register}
                        id="serviceType"
                        errors={errors}
                        placeholder="Service Type"
                        maxLength="30"
                      />
                    </div>
                  </div>
                </article>
              </div>
            </div>

            <div className="bg-gray">
              <div className="row">
                <article className="col-md-4">
                  <div className="row">
                    <div className="col-md-4">
                      <label>Vehicle Job Type</label>
                    </div>
                    <div className="col-md-8 mb-4">
                      <select
                        className={`form-select ${
                          errors?.vehicleJobType ? 'is-invalid' : ''
                        }`}
                        id="vehicleJobType"
                        name="vehicleJobType"
                        {...register('vehicleJobType')}
                        disabled={type === 'view'}
                      >
                        <option value="">Please Select</option>
                        {lookupsList?.vehicleJobTypes?.map((item) => (
                          <option key={item.id} value={item.id}>
                            {item.id} - {item.description}
                          </option>
                        ))}
                      </select>
                      {errors?.vehicleJobType && (
                        <p className="invalid-feedback">
                          {errors?.vehicleJobType?.message}
                        </p>
                      )}
                    </div>
                  </div>
                </article>
                {/* <article className="col-md-4">
                  <div className="row">
                    <div className="col-md-4">
                      <label>Pick Up Code</label>
                    </div>
                    <div className="col-md-8 mb-4">
                      <TextBox
                        register={register}
                        id="zone"
                        errors={errors}
                        placeholder="Zone"
                        maxLength="20"
                      />
                    </div>
                  </div>
                </article> */}

                <article className="col-md-4">
                  <div className="row">
                    <div className="col-md-4">
                      <label>Delivery Code</label>
                    </div>
                    <div className="col-md-8 mb-4">
                      <TextBox
                        register={register}
                        id="deliveryCode"
                        errors={errors}
                        placeholder="deliveryCode"
                        maxLength="50"
                      />
                    </div>
                  </div>
                </article>

                <article className="col-md-4">
                  <div className="row">
                    <div className="col-md-4">
                      <label>Package Code</label>
                    </div>
                    <div className="col-md-8 mb-4">
                      <TextBox
                        register={register}
                        id="packageCode"
                        errors={errors}
                        placeholder="packageCode"
                        maxLength="50"
                      />
                    </div>
                  </div>
                </article>

                <article className="col-md-4">
                  <div className="row">
                    <div className="col-md-4">
                      <label>Rate Code</label>
                    </div>
                    <div className="col-md-8 mb-4">
                      <select
                        className={`form-select ${
                          errors?.rateCode ? 'is-invalid' : ''
                        }`}
                        id="rateCode"
                        name="rateCode"
                        {...register('rateCode')}
                        disabled={type === 'view'}
                      >
                        <option value="">Please Select</option>
                        {lookupsList?.rateCodes?.map((item) => (
                          <option key={item.rateCode} value={item.rateCode}>
                            {item.rateGroup} - {item.description}
                          </option>
                        ))}
                      </select>
                      {errors?.rateCode && (
                        <p className="invalid-feedback">
                          {errors?.vehicleJobType?.message}
                        </p>
                      )}
                    </div>
                  </div>
                </article>

                <article className="col-md-4">
                  <div className="row">
                    <div className="col-md-4">
                      <label>Dimension Unit</label>
                    </div>
                    <div className="col-md-8 mb-4">
                      <select
                        className={`form-select ${
                          errors?.dimensionUnit ? 'is-invalid' : ''
                        }`}
                        id="dimensionUnit"
                        name="dimensionUnit"
                        {...register('dimensionUnit')}
                        disabled={type === 'view'}
                      >
                        <option value="">Please Select</option>
                        {lookupsList?.dimensionUnits &&
                          lookupsList?.dimensionUnits?.map((item) => (
                            <option key={item.id} value={item.id}>
                              {item.description}
                            </option>
                          ))}
                      </select>
                      {errors?.dimensionUnit && (
                        <p className="invalid-feedback">
                          {errors?.dimensionUnit?.message}
                        </p>
                      )}
                    </div>
                  </div>
                </article>
              </div>
            </div>

            <section className="odd-row">
              <div className="row">
                <article className="col-md-4">
                  <div className="row">
                    <div className="col-md-4">
                      <label>Currency Code</label>
                    </div>
                    <div className="col-md-8 mb-4">
                      <select
                        className={`form-select ${
                          errors?.currencyCode ? 'is-invalid' : ''
                        }`}
                        id="currencyCode"
                        name="currencyCode"
                        {...register('currencyCode')}
                        disabled={type === 'view'}
                      >
                        <option value="">Please Select</option>
                        {lookupsList?.currencyCodes?.map((item) => (
                          <option
                            key={item.currencyCode}
                            value={item.currencyCode}
                          >
                            {item.description} - {item.currencyCode}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                </article>
                <article className="col-md-4">
                  <div className="row">
                    <div className="col-md-4">
                      <label>Sell rate</label>
                    </div>
                    <div className="col-md-8 mb-4">
                      <TextBox
                        register={register}
                        id="sellRate"
                        errors={errors}
                        placeholder="Sell Rate"
                        maxLength="30"
                        type="number"
                      />
                    </div>
                  </div>
                </article>

                <article className="col-md-4">
                  <div className="row">
                    <div className="col-md-4">
                      <label>Min Amt</label>
                    </div>
                    <div className="col-md-8 mb-4">
                      <TextBox
                        register={register}
                        id="minAmt"
                        errors={errors}
                        placeholder="Min Amount"
                        maxLength="10"
                        type="number"
                      />
                    </div>
                  </div>
                </article>

                <article className="col-md-4">
                  <div className="row">
                    <div className="col-md-4">
                      <label>Max Amt</label>
                    </div>
                    <div className="col-md-8 mb-4">
                      <TextBox
                        register={register}
                        id="maxAmt"
                        errors={errors}
                        placeholder="max Amt"
                        maxLength="30"
                        type="number"
                      />
                    </div>
                  </div>
                </article>

                <article className="col-md-4">
                  <div className="row">
                    <div className="col-md-4">
                      <label>Shipper Consignee</label>
                    </div>
                    <div className="col-md-8 mb-4">
                      <select
                        className={`form-select ${
                          errors?.shipperConsignee ? 'is-invalid' : ''
                        }`}
                        name="shipperConsignee"
                        {...register('shipperConsignee')}
                      >
                        <option value="">Please select</option>
                        {shipperData.map((shipperObj, i) => (
                          <option key={i} value={shipperObj.ccShipperAddressId}>
                            {shipperObj.ccShipperAddressId}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                </article>

                <article className="col-md-4">
                  <div className="row">
                    <div className="col-md-4">
                      <label>Vendor code</label>
                    </div>
                    <div className="col-md-8 mb-4">
                      <select
                        className={`form-select ${
                          errors?.vendorcode ? 'is-invalid' : ''
                        }`}
                        id="vendorcode"
                        name="vendorcode"
                        {...register('vendorcode')}
                        disabled={type === 'view'}
                      >
                        <option value="">Please Select</option>

                        {lookupsList?.vendorCodes &&
                          lookupsList?.vendorCodes?.map((item) => (
                            <option
                              key={item.vendorCode}
                              value={item.vendorCode}
                            >
                              {item.description}
                            </option>
                          ))}
                      </select>
                      {errors?.vendorcode && (
                        <p className="invalid-feedback">
                          {errors?.vendorcode?.message}
                        </p>
                      )}
                    </div>
                  </div>
                </article>
              </div>
            </section>

            <section className="bg-gray">
              <div className="row">
                <article className="col-md-4">
                  <div className="row">
                    <div className="col-md-4">
                      <label>Cargo type</label>
                    </div>
                    <div className="col-md-8 mb-4">
                      <select
                        className={`form-select ${
                          errors?.cargotype ? 'is-invalid' : ''
                        }`}
                        id="cargotype"
                        name="cargotype"
                        {...register('cargotype')}
                        disabled={type === 'view'}
                      >
                        <option value="">Please Select</option>
                        {lookupsList?.cargoTypes &&
                          lookupsList?.cargoTypes?.map((item, index) => (
                            <option
                              key={`${item.cargotype}-${index}`}
                              value={item.cargoType}
                            >
                              {item.description}
                            </option>
                          ))}
                      </select>
                      {errors?.cargotype && (
                        <p className="invalid-feedback">
                          {errors?.cargotype?.message}
                        </p>
                      )}
                    </div>
                  </div>
                </article>
                <article className="col-md-4">
                  <div className="row">
                    <div className="col-md-4">
                      <label>Commodity Code</label>
                    </div>
                    <div className="col-md-8 mb-4">
                      <TextBox
                        register={register}
                        id="commodityCode"
                        errors={errors}
                        placeholder="Commodity Code"
                        maxLength="30"
                      />
                    </div>
                  </div>
                </article>

                <article className="col-md-4">
                  <div className="row">
                    <div className="col-md-4">
                      <label>Slab Unit From</label>
                    </div>
                    <div className="col-md-8 mb-4">
                      <TextBox
                        register={register}
                        id="slabUnitFrom"
                        errors={errors}
                        placeholder="slabUnitFrom"
                        maxLength="10"
                        type="number"
                      />
                    </div>
                  </div>
                </article>

                <article className="col-md-4">
                  <div className="row">
                    <div className="col-md-4">
                      <label>Slab Unit To</label>
                    </div>
                    <div className="col-md-8 mb-4">
                      <TextBox
                        register={register}
                        id="slabUnitTo"
                        errors={errors}
                        placeholder="Slab Unit To"
                        maxLength="30"
                        type="number"
                      />
                    </div>
                  </div>
                </article>

                <article className="col-md-4">
                  <div className="row">
                    <div className="col-md-4">
                      <label>Remark</label>
                    </div>
                    <div className="col-md-8 mb-4">
                      <TextBox
                        register={register}
                        id="remark"
                        errors={errors}
                        placeholder="Remark"
                        maxLength="100"
                      />
                    </div>
                  </div>
                </article>

                <article className="col-md-4">
                  <div className="row">
                    <div className="col-md-4">
                      <label>Container Size</label>
                    </div>
                    <div className="col-md-8 mb-4">
                      <select
                        className={`form-select ${
                          errors?.containerSize ? 'is-invalid' : ''
                        }`}
                        name="containerSize"
                        {...register('containerSize')}
                      >
                        <option value="">Please select</option>
                        <option value="20">20</option>
                        <option value="40">40</option>
                      </select>
                    </div>
                  </div>
                </article>
              </div>
            </section>

            <section className="odd-row">
              <div className="row">
                <article className="col-md-4">
                  <div className="row">
                    <div className="col-md-4">
                      <label>Container Category</label>
                    </div>
                    <div className="col-md-8 mb-4">
                      <select
                        className={`form-select ${
                          errors?.containerCategory ? 'is-invalid' : ''
                        }`}
                        name="containerCategory"
                        {...register('containerCategory')}
                      >
                        <option value="">Please select</option>
                        {renderOptionsFromDataArr(
                          lookupsList?.containerCategories,
                          'id',
                          'description'
                        )}
                      </select>
                      {errors?.containerCategory && (
                        <p className="invalid-feedback">
                          {errors?.containerCategory?.message}
                        </p>
                      )}
                    </div>
                  </div>
                </article>
                <article className="col-md-4">
                  <div className="row">
                    <div className="col-md-4">
                      <label>Distinct Conduct Code</label>
                    </div>
                    <div className="col-md-8 mb-4">
                      <select
                        className={`form-select ${
                          errors?.distinctConductCode ? 'is-invalid' : ''
                        }`}
                        name="distinctConductCode"
                        {...register('distinctConductCode')}
                      >
                        <option value="">Please select</option>
                        {lookupsList?.distinctConducts &&
                          lookupsList?.distinctConducts?.map((item, index) => (
                            <option
                              key={`${item.distinctConductCode}-${index}`}
                              value={item.id}
                            >
                              {item.description}
                            </option>
                          ))}
                      </select>
                      {errors?.distinctConductCode && (
                        <p className="invalid-feedback">
                          {errors?.distinctConductCode?.message}
                        </p>
                      )}
                    </div>
                  </div>
                </article>

                <article className="col-md-4">
                  <div className="row">
                    <div className="col-md-4">
                      <label>Record ID</label>
                    </div>
                    <div className="col-md-8 mb-4">
                      <select
                        className={`form-select ${
                          errors?.recordID ? 'is-invalid' : ''
                        }`}
                        name="recordID"
                        {...register('recordID')}
                      >
                        <option value="">Please select</option>
                        {lookupsList?.recordIDs &&
                          lookupsList?.recordIDs?.map((item) => (
                            <option key={item} value={item}>
                              {item}
                            </option>
                          ))}
                      </select>
                    </div>
                  </div>
                </article>

                <article className="col-md-4">
                  <div className="row">
                    <div className="col-md-4">
                      <label>Cargo Handling</label>
                    </div>
                    <div className="col-md-8 mb-4">
                      <select
                        className={`form-select ${
                          errors?.cargoHandling ? 'is-invalid' : ''
                        }`}
                        name="cargoHandling"
                        {...register('cargoHandling')}
                      >
                        <option value="">Please select</option>
                        {lookupsList?.cargoHandlings &&
                          lookupsList?.cargoHandlings?.map((item, index) => (
                            <option
                              key={`${item.cargoHandling}-${index}`}
                              value={item.id}
                            >
                              {item.description}
                            </option>
                          ))}
                      </select>
                      {errors?.cargoHandling && (
                        <p className="invalid-feedback">
                          {errors?.cargoHandling?.message}
                        </p>
                      )}
                    </div>
                  </div>
                </article>

                <article className="col-md-4">
                  <div className="row">
                    <div className="col-md-4">
                      <label>Price Type</label>
                    </div>
                    <div className="col-md-8 mb-4">
                      <TextBox
                        register={register}
                        id="priceType"
                        errors={errors}
                        placeholder="Price Type"
                        maxLength="10"
                      />
                    </div>
                  </div>
                </article>

                <article className="col-md-4">
                  <div className="row">
                    <div className="col-md-4">
                      <label>Job Window</label>
                    </div>
                    <div className="col-md-8 mb-4">
                      <select
                        className={`form-select ${
                          errors?.jobWindow ? 'is-invalid' : ''
                        }`}
                        id="jobWindow"
                        name="jobWindow"
                        {...register('jobWindow')}
                        disabled={type === 'view'}
                      >
                        <option value="">Please Select</option>
                        {lookupsList?.jobWindows?.map((item) => (
                          <option key={item.jobWindow} value={item.jobWindow}>
                            {item.description}
                          </option>
                        ))}
                      </select>
                      {errors?.jobWindow && (
                        <p className="invalid-feedback">
                          {errors?.jobWindow?.message}
                        </p>
                      )}
                    </div>
                  </div>
                </article>
              </div>
            </section>

            <section className="bg-gray">
              <div className="row">
                <article className="col-md-4">
                  <div className="row">
                    <div className="col-md-4">
                      <label>Port Operator</label>
                    </div>
                    <div className="col-md-8 mb-4">
                      <TextBox
                        register={register}
                        id="portOperator"
                        errors={errors}
                        placeholder="portOperator"
                        maxLength="30"
                      />
                    </div>
                  </div>
                </article>
                <article className="col-md-4">
                  <div className="row">
                    <div className="col-md-4">
                      <label>Drop Off Code</label>
                    </div>
                    <div className="col-md-8 mb-4">
                      <TextBox
                        register={register}
                        id="dropOffCode"
                        errors={errors}
                        placeholder="dropOffCode"
                        maxLength="50"
                      />
                    </div>
                  </div>
                </article>

                {/* <article className="col-md-4">
                  <div className="row">
                    <div className="col-md-4">
                      <label>Record ID</label>
                    </div>
                    <div className="col-md-8 mb-4">
                      <TextBox
                        register={register}
                        id="MinAmt"
                        errors={errors}
                        placeholder="Min Amount"
                        maxLength="20"
                      />
                    </div>
                  </div>
                </article> */}
                <article className="col-md-4">
                  <div className="row">
                    <div className="col-md-4">
                      <label>Customer Code</label>
                    </div>
                    <div className="col-md-8 mb-4">
                      <TextBox
                        register={register}
                        id="customerCode"
                        errors={errors}
                        placeholder="Customer Code"
                        maxLength="30"
                      />
                    </div>
                  </div>
                </article>
                <article className="col-md-4">
                  <div className="row">
                    <div className="col-md-4">
                      <label>Pick Up Code</label>
                    </div>
                    <div className="col-md-8 mb-4">
                      <TextBox
                        register={register}
                        id="pickUpCode"
                        errors={errors}
                        placeholder="Pick Up Code"
                        maxLength="30"
                      />
                    </div>
                  </div>
                </article>
              </div>
            </section>

            <section className="odd-row">
              <div className="row">
                <article className="col-md-6">
                  <TextInputField
                    title="Location 1 Area Code"
                    id="location1AreaCode"
                    register={register}
                    error={errors}
                    labelClassName=""
                  />
                </article>
                <article className="col-md-6">
                  <TextInputField
                    title="Location 1 Description"
                    id="location1Description"
                    register={register}
                    error={errors}
                    labelClassName=""
                  />
                </article>
                <article className="col-md-6">
                  <TextInputField
                    title="Location 2 Area Code"
                    id="location2AreaCode"
                    register={register}
                    error={errors}
                    labelClassName=""
                  />
                </article>
                <article className="col-md-6">
                  <TextInputField
                    title="Location 2 Description"
                    id="location2Description"
                    register={register}
                    error={errors}
                    labelClassName=""
                  />
                </article>
              </div>
            </section>

            <div className="row">
              <div className="col-12 text-right">
                <button className="btn btn-primary" type="submit">
                  {profileFormType === 'add' || profileFormType === 'import'
                    ? 'Submit'
                    : 'Update'}
                </button>
                <button className="btn btn-secondary ms-2" type="reset">
                  Reset
                </button>
                <button
                  className="btn btn-secondary ms-2"
                  type="button"
                  onClick={() => setEnableForm(false)}
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  )

  const formHeader = <h2 className="ms-4">Suburb form</h2>

  return (
    <Modal show={true} onHide={() => setEnableForm(false)} size="lg" centered>
      <Modal.Header closeButton>
        <Modal.Title>{formHeader}</Modal.Title>
      </Modal.Header>
      <Modal.Body>{formBody}</Modal.Body>
    </Modal>
  )
}

export default AddSuburbData
