import dayjs from 'dayjs'

const getValidValues = (obj, dateFormat = 'YYYY-MM-DD') => {
  return Object.entries(obj).reduce((obj, [key, value]) => {
    if (value !== '' && value) {
      if (key.toLowerCase().includes('date')) {
        obj[key] = dayjs(value).format(dateFormat)
      } else obj[key] = value
    }

    return obj
  }, {})
}

export default getValidValues
