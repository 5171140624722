import React, { useEffect, useState } from 'react'
import { TableBuilder } from '../../DataMgmt/Table'
import suburbObjMini from './suburbDataRefMini.json'
import CONSTANTS from '../../../constants.json'
import { format } from 'date-fns'
import config from '../../../config'

const SuburbTable = (props) => {
  const {
    csvArray,
    setCsvArray,
    profileFormType,
    setEnableForm,
    setSuburbFormData,
    setProfileFormId,
    type,
    setProfileFormType = () => '',
  } = props
  const [tableHeaderData, setTableHeaderData] = useState([])
  const [tableBodyData, setTableBodyData] = useState([])

  useEffect(() => {
    if (csvArray.data.length > 0) {
      let miniDataArray = []
      csvArray.data.forEach((item) => {
        let a = {}
        suburbObjMini.forEach((eachKey) => {
          a[eachKey] = item[eachKey]
        })
        miniDataArray.push(a)
      })
      transFormTableData(miniDataArray)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [csvArray])

  const transFormTableData = (data) => {
    let keys = Object.keys(data[0])
    let headers = []
    let tbodyData = []
    keys.forEach((each) => {
      let hdrObj = {
        Header: CONSTANTS[each] ?? each,
        accessor: each,
        className: each,
      }

      if (each === 'editedDate') {
        hdrObj.Cell = ({ value }) => {
          return format(new Date(value), 'dd/MM/yy')
        }
      }
      headers.push(hdrObj)
    })
    if (type !== 'view') {
      headers.push({
        width: 200,
        Header: 'Actions',
        accessor: 'name',

        Cell: ({ cell, row }) => (
          <span style={{ minWidth: '120px', display: 'block' }}>
            <button
              value={cell.row.values.id}
              type="button"
              className="btn btn-primary btn-sm"
              onClick={editTable.bind(cell.row.values.recId, row.index)}
            >
              Edit
            </button>
            <button
              value={cell.row.values.id}
              type="button"
              className="btn btn-secondary btn-sm ms-2"
              onClick={deleteItem.bind(cell.row.values.recId, row.index)}
            >
              Delete
            </button>
          </span>
        ),
      })
    }

    setTableHeaderData(headers)
    setTableBodyData(tbodyData)
    let newData = []
    data.forEach((eachObj) => {
      let newObj = {}
      keys.forEach((each) => {
        if (eachObj[each] === true) {
          newObj[each] = 'YES'
        } else if (eachObj[each] === false) {
          newObj[each] = 'NO'
        } else {
          newObj[each] = eachObj[each]
        }
        newObj.class = 'each'
      })

      newData.push(newObj)
    })

    setTableBodyData(newData)
  }

  const deleteItem = (id) => {
    if (props.type === 'create' || profileFormType === 'import') {
      let newArray = csvArray.data || []
      newArray.splice(id, 1)
      setCsvArray({ data: newArray, igniter: new Date() })
    }
    if (props.type === 'edit') {
      let profileObj = csvArray.data[id]
      let answer = window.confirm(
        'Are you sure to delete suburb profile of ' +
          profileObj.ccSuburbProfileId
      )
      if (!answer) {
        return false
      }

      const url =
        config.api.baseUrl +
        '/customercard/suburb-profile/' +
        profileObj.ccSuburbProfileId
      const requestOptions = {
        method: 'DELETE',
        headers: { Authorization: 'Bearer ' + localStorage.token },
      }

      fetch(url, requestOptions)
        .then((response) => response.json())
        .then(() => {
          alert(
            'suburb profile id ' +
              profileObj.ccSuburbProfileId +
              ' has deleted successfully'
          )
          let newArray = csvArray.data || []
          newArray.splice(id, 1)
          setCsvArray({ data: newArray, igniter: new Date() })
        })
        .catch(() => {
          alert(
            'Unable to delete the suburb profile now please try after some time!'
          )
        })
    }
  }

  const editTable = (id) => {
    setEnableForm(false)
    setProfileFormType('import')
    setTimeout(() => {
      const getSuburbObj = csvArray.data[id]
      setSuburbFormData(getSuburbObj)
      setProfileFormId(id)
      setEnableForm(true)
    }, 100)
  }

  return (
    <div>
      <div className="row">
        <div className="col-md-9">
          <h4>Suburb Profile data</h4>
        </div>
      </div>
      {tableBodyData.length > 0 && (
        <TableBuilder
          tableBodyData={tableBodyData}
          tableHeaderData={tableHeaderData}
          url={'../'}
          editTable={editTable}
        />
      )}
    </div>
  )
}

export default SuburbTable
