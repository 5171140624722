const rateCode = {
  name: 'Rate Code',
  path: '/dataMgmt/user/profileType',
  req: '/',
  columns: 4,
  fields: [
    {
      name: 'Rate Code',
      label: 'Rate Code',
      type: 'text',
      loadLookUp: 'rateCodes',
      validation: 'Alphabetic',
      fieldId: 'rateCode',
      id: 'rateCode',
      validationType: 'string',
      validations: [
        {
          type: 'required',
          params: ['this field is required'],
        },
      ],
    },
    {
      name: 'Rate Rules',
      label: 'Rate Rules',
      type: 'searchable-select',
      loadLookUp: 'rateRuleEnums',
      fieldId: 'rateRules',
      id: 'rateRules',
      multiple: true,
      validationType: 'array',
      validations: [
        {
          type: 'optional',
          params: [],
          nullable: true,
        },
      ],
    },
    {
      name: 'Module',
      label: 'Module',
      type: 'text',
      validation: 'Alphabetic',
      fieldId: 'module',
      id: 'module',
      validationType: 'string',
      max: 30,
      validations: [
        {
          type: 'required',
          params: ['this field is required'],
        },
        {
          type: 'max',
          params: [30, 'Module be more than 30 characters'],
        },
        {
          type: 'min',
          params: [3, 'Field should be min 3 characters'],
        },
      ],
    },
    {
      name: 'Billing Unit',
      label: 'Billing Unit',
      type: 'text',
      validation: 'Alphabetic',
      fieldId: 'billingUnit',
      id: 'billingUnit',
      validationType: 'string',
      max: 100,
      validations: [
        {
          type: 'required',
          params: ['this field is required'],
        },
        {
          type: 'max',
          params: [100, 'Billing Unit be more than 100 characters'],
        },
        {
          type: 'min',
          params: [3, 'Field should be min 3 characters'],
        },
      ],
    },
    {
      name: 'Rate Type',
      label: 'Rate Type',
      type: 'text',
      validation: 'Alphabetic',
      fieldId: 'rateType',
      id: 'rateType',
      validationType: 'string',
      max: 30,
      validations: [
        {
          type: 'required',
          params: ['this field is required'],
        },
        {
          type: 'max',
          params: [30, 'Rate Type be more than 30 characters'],
        },
        {
          type: 'min',
          params: [3, 'Field should be min 3 characters'],
        },
      ],
    },
    {
      name: 'Rate Group',
      label: 'Rate Group',
      validation: 'Alphabetic',
      fieldId: 'rateGroup',
      id: 'rateGroup',
      type: 'select',
      loadLookUp: 'rateGroups',
      validationType: 'string',
      max: 30,
      validations: [
        {
          type: 'required',
          params: ['this field is required'],
        },
        {
          type: 'max',
          params: [30, 'Rate Group be more than 30 characters'],
        },
        {
          type: 'min',
          params: [3, 'Field should be min 3 characters'],
        },
      ],
    },
    {
      name: 'Description',
      label: 'Description',
      type: 'text',
      validation: 'Alphabetic',
      fieldId: 'description',
      id: 'description',
      validationType: 'string',
      max: 100,
      validations: [
        {
          type: 'required',
          params: ['this field is required'],
        },
        {
          type: 'max',
          params: [100, 'Description cannot be more than 100 characters'],
        },
        {
          type: 'min',
          params: [3, 'Field should be min 3 characters'],
        },
      ],
    },
    {
      name: 'Special Service',
      label: 'Special Service',
      type: 'select',
      loadLookUp: 'specialServices',
      fieldId: 'specialService',
      id: 'specialService',
    },
  ],
}
export default rateCode
