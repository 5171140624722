const stockRoom = {
  name: 'Stock Room',
  apiUrlAppend: false,
  path: '/dataMgmt/customer/stockRoom',
  req: '/',
  columns: 4,
  fields: [
    {
      name: 'ID',
      label: 'ID',
      type: 'text',
      validation: 'Alphabetic',
      fieldId: 'id',
      id: 'id',
      validationType: 'string',
      validations: [
        {
          type: 'required',
          params: ['ID is required'],
        },
        {
          type: 'max',
          params: [10, "ID can't be more than 10 characters"],
        },
        {
          type: 'min',
          params: [2, 'ID should be min 2 characters'],
        },
      ],
    },
    {
      name: 'On Hold',
      label: 'On Hold',
      type: 'checkbox',
      validation: 'Alphabetic',
      fieldId: 'onHold',
      id: 'onHold',
      validationType: 'boolean',
      // validations: [
      //   {
      //     type: "oneOf",
      //     params: [[true],"this field is required"]
      //   }
      // ]
    },
    {
      name: 'Description',
      label: 'Description',
      type: 'textarea',
      validation: 'Alphabetic',
      fieldId: 'description',
      id: 'description',
      validationType: 'string',
      max: 30,
      validations: [
        {
          type: 'required',
          params: ['Description is required'],
        },
        {
          type: 'max',
          params: [30, "Description can't be more than 30 characters"],
        },
      ],
    },
    {
      name: 'Damage',
      label: 'Damage',
      type: 'checkbox',
      validation: 'Alphabetic',
      fieldId: 'damage',
      id: 'damage',
      validationType: 'boolean',
      // validations: [
      //   {
      //     type: "oneOf",
      //     params: [[true],"this field is required"]
      //   }
      // ]
    },
    {},
    {
      name: 'Calc Storage',
      label: 'Calc Storage',
      type: 'checkbox',
      validation: 'Alphabetic',
      fieldId: 'calcStorage',
      id: 'calcStorage',
      validationType: 'boolean',
    },
    {},
    {
      name: 'Allow Replenish',
      label: 'Allow Replenish',
      type: 'checkbox',
      validation: 'Alphabetic',
      fieldId: 'allowReplenish',
      id: 'allowReplenish',
      validationType: 'boolean',
    },
  ],
}
export default stockRoom
