import React, { useState } from 'react'
import * as Toastify from '../../../../../../components/utils/Toastify'
import config from '../../../../../../config'
import useModalContainer from '../../../../../../helper/hooks/useModalContainer'

const OrderCardMonitor = ({ contextAPIData }) => {
  const [orderCardMonitor, setOrderCardMonitor] = useState([])

  const getOrderCardMonitor = (orderCustomerDetailsId) => {
    const url =
      config.api.baseUrl +
      '/operations/order/monitor/order-customer/' +
      orderCustomerDetailsId
    const requestOptions = {
      method: 'GET',
      headers: { Authorization: 'Bearer ' + localStorage.token },
    }
    fetch(url, requestOptions)
      .then((response) => response.json())
      .then((data) => {
        setOrderCardMonitor(data)
      })
      .catch((error) => {
        Toastify.Error(error?.response?.data?.error)
      })
  }

  const { ModalContainer, setShow } = useModalContainer({
    text: (
      <button
        onClick={() => {
          if (contextAPIData.orderCustomerDetailsId > 0) {
            getOrderCardMonitor(contextAPIData.orderCustomerDetailsId)
            setShow(true)
          } else Toastify.Warning('Customer Id is not available.')
        }}
        className="unset-all d-flex flex-column align-items-center"
        title="OrderCard Monitor"
      >
        <img
          src="./assets/customer-order/edi-date-time.png"
          alt="OrderCard Monitor"
          width="40px"
          height="40px"
          className="img"
        />
        Order Card
      </button>
    ),
    body: (
      <div>
        <div className="row">
          <div className="col-md-12">
            <div className="">
              <table className="table table-hover">
                <thead>
                  <tr>
                    <th>Order No</th>
                    <th>Status</th>
                    <th>Module Group</th>
                    <th>User</th>
                    <th>Release Date Time</th>
                    <th>Release User</th>
                    <th>Notes</th>
                  </tr>
                </thead>
                <tbody>
                  {orderCardMonitor?.map?.((item, i) => {
                    return (
                      <tr key={i}>
                        <td>{item.orderNumber}</td>
                        <td>{item.activity}</td>
                        <td>{item.moduleGroup}</td>
                        <td>{item.user}</td>
                        <td>{item.releaseDate}</td>
                        <td>{item.releaseUser}</td>
                        <td>{item.notes}</td>
                      </tr>
                    )
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    ),
    header: 'Order Card Monitor',
  })

  return ModalContainer
}

export default OrderCardMonitor
