import React, { useMemo, useState } from 'react'
import { Button, Modal, Spinner } from 'react-bootstrap'
import Table from '../../../../../components/Common/Tanstack-table/Table'
import { createTableColumnsFromObjKeys } from '../../../../../components/utils/Utils'
import { useMasterTemplate } from '../../../../../helper/hooks/api/allocations'
import AddChannelForm from './AddChannelForm'
import ChannelNameCell from './ChannelNameCell'
import DeleteChannelBtnCell from './DeleteChannelBtnCell'

const ChannelsTable = () => {
  const {
    isLoading: isLoadingMasterTemplate,
    data: masterData,
    error,
  } = useMasterTemplate()

  const columns = useMemo(() => {
    if (Array.isArray(masterData?.masterTemplateFunctions)) {
      const cols = createTableColumnsFromObjKeys(
        masterData?.masterTemplateFunctions,
        ['channelName']
      )
      cols.unshift({
        header: 'Channel Name',
        id: 'channelName',
        accessorKey: 'channelName',
        cell: (cell) => <ChannelNameCell cell={cell} />,
      })
      cols.push({
        header: 'Actions',
        id: 'actions',
        accessorKey: 'action',
        cell: (cell) => <DeleteChannelBtnCell cell={cell} />,
      })
      return cols
    }
    return []
  }, [masterData?.masterTemplateFunctions])

  return (
    <div className="mt-3">
      <p>Channels list</p>
      {isLoadingMasterTemplate && <Spinner />}
      {!error && Array.isArray(masterData?.masterTemplateFunctions) && (
        <Table data={masterData?.masterTemplateFunctions} columns={columns} />
      )}
    </div>
  )
}

export default function AddChannelModal() {
  const [show, setShow] = useState(false)

  const handleClose = () => {
    setShow(false)
  }

  return (
    <>
      <Button className="ms-auto" onClick={() => setShow(true)}>
        Add Channel
      </Button>
      <Modal size="xl" show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Add Channel</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <AddChannelForm />
          <ChannelsTable />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}
