import React, { useEffect, useState } from 'react'
import { TableBuilder } from '../../DataMgmt/Table'
import suburbObjMini from './AgreedRatesRefMini.json'
import CONSTANTS from '../../../constants.json'
import { format } from 'date-fns'
import config from '../../../config'

const AgreedRatesTable = (props) => {
  const {
    agreedRatesArray,
    setAgreedRatesArray,
    agreedRateUrl,
    setProfileFormType,
    deleteRow,
    type,
    headersToShow,
  } = props
  const [tableHeaderData, setTableHeaderData] = useState([])
  const [tableBodyData, setTableBodyData] = useState([])

  useEffect(() => {
    if (agreedRatesArray.data.length > 0) {
      let miniDataArray = []
      agreedRatesArray.data.forEach((item) => {
        let a = {}
        ;(headersToShow ?? suburbObjMini).forEach((eachKey) => {
          a[eachKey] = item[eachKey]
        })
        miniDataArray.push(a)
      })
      transFormTableData(miniDataArray)
    } else {
      setTableBodyData([])
      setTableHeaderData([])
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [agreedRatesArray])

  const transFormTableData = (data) => {
    let keys = Object.keys(data[0])
    let headers = []
    let tbodyData = []
    keys.forEach((each, i) => {
      let hdrObj = {
        Header: CONSTANTS[each] ? CONSTANTS[each] : each,
        accessor: each,
        className: each,
      }

      if (each === 'editedDate') {
        hdrObj.Cell = ({ value }) => {
          return value ? format(new Date(value), 'dd/MM/yy') : ''
        }
      }
      headers.push(hdrObj)
    })
    if (type !== 'view') {
      headers.push({
        width: 200,
        Header: 'Actions',
        accessor: 'name',

        Cell: ({ cell, row }) => (
          <span style={{ minWidth: '120px', display: 'block' }}>
            {(type === 'create' || type === 'edit') && (
              <button
                value={cell.row.values.id}
                type="button"
                className="btn btn-primary btn-sm"
                onClick={editTable.bind(cell.row.values.recId, row.index)}
              >
                Edit
              </button>
            )}

            <button
              value={cell.row.values.id}
              type="button"
              className="btn btn-secondary btn-sm ms-2"
              onClick={deleteItem.bind(
                cell.row.values.recId,
                row.index,
                cell.row.values
              )}
            >
              Delete
            </button>
          </span>
        ),
      })
    }

    setTableHeaderData(headers)
    setTableBodyData(tbodyData)
    let newData = []
    data.forEach((eachObj, index) => {
      let newObj = {}
      keys.forEach((each, i) => {
        if (eachObj[each]?.render) {
          newObj[each] = eachObj[each].render()
        } else if (eachObj[each] === true) {
          newObj[each] = 'YES'
        } else if (eachObj[each] === false) {
          newObj[each] = 'NO'
        } else {
          newObj[each] = eachObj[each]
        }
        newObj.class = 'each'
      })

      newData.push(newObj)
    })

    setTableBodyData(newData)
  }

  const deleteItem = (id, obj, b) => {
    if (!obj.agreedRatesId) {
      let newArray = agreedRatesArray.data || []
      newArray.splice(id, 1)
      setAgreedRatesArray({ data: newArray, igniter: new Date() })
    } else {
      let agreedRateId = obj.agreedRatesId
      let answer = window.confirm(
        'Are you sure to delete Agreed rate ' + agreedRateId
      )
      if (!answer) {
        return false
      }

      const url =
        config.api.baseUrl +
        (agreedRateUrl ?? '/customercard/consignee-agreed-rates/') + // generalize
        agreedRateId
      const requestOptions = {
        method: 'DELETE',
        headers: { Authorization: 'Bearer ' + localStorage.token },
      }

      fetch(url, requestOptions)
        .then((response) => response.json())
        .then((data) => {
          alert('Agreed Rate ' + agreedRateId + ' has deleted successfully')
          let newArray = agreedRatesArray.data || []
          newArray.splice(id, 1)
          setAgreedRatesArray({ data: newArray, igniter: new Date() })
        })
        .catch((error) => {
          alert(
            'Unable to delete the Agreed rate now please try after some time!'
          )
        })
    }
  }

  const editTable = (id) => {
    setTimeout(() => {
      setProfileFormType('import')
    }, 100)
  }

  return (
    <div>
      <div className="row mt-4">
        <div className="col-md-9">
          <h4>Agreed Rates</h4>
        </div>
      </div>
      {tableBodyData.length > 0 && (
        <TableBuilder
          tableBodyData={tableBodyData}
          tableHeaderData={tableHeaderData}
          url={'../'}
          editTable={editTable}
          deleteRow={deleteRow}
        />
      )}
    </div>
  )
}

export default AgreedRatesTable
