import { flexRender } from '@tanstack/react-table'
import Popup from 'reactjs-popup'
import { columnResizeMode } from '../Table'
import { reorderColumn } from '../utils'
import Filter from './Filter'

const ColumnHeader = ({ header, table, dontShowFilters, showGroupingIcon }) => {
  const { getState, setColumnOrder } = table
  const { columnOrder } = getState()
  const { column } = header

  const onDrop = (event) => {
    const draggedColumn = JSON.parse(event.dataTransfer.getData('column'))
    if (column.id === 'select') return
    const newColumnOrder = reorderColumn(
      draggedColumn.id,
      column.id,
      columnOrder
    )
    setColumnOrder(newColumnOrder)
  }

  const dragStart = (event) => {
    event.dataTransfer.setData('column', JSON.stringify(column))
  }

  return (
    <div
      className="th"
      colSpan={header.colSpan}
      onDragOver={(e) => e.preventDefault()}
      onDrop={onDrop}
      key={header.id}
      style={{
        width: header.getSize(),
      }}
    >
      <div>
        {header.isPlaceholder ? null : (
          <>
            <div
              {...{
                className: header.column.getCanSort()
                  ? 'cursor-pointer select-none d-flex justify-content-center align-items-center'
                  : 'd-flex justify-content-center align-items-center',
                onClick: header.column.getToggleSortingHandler(),
                // onClick:
                //   header.column.columnDef.isSortAvailable &&
                //   header.column.getToggleSortingHandler(),
              }}
            >
              <span
                className="cursor-grabbing"
                title="Drag Column"
                draggable={header.column.columnDef.id !== 'select'}
                onDragStart={(event) => dragStart(event)}
              >
                {flexRender(
                  header.column.columnDef.header,
                  header.getContext()
                )}
              </span>
              <span title="Sort" className="ms-1">
                {{
                  asc: ' 🔼',
                  desc: ' 🔽',
                }[header.column.getIsSorted()] ?? ' Y'}
              </span>

              {header.column.getCanGroup() &&
              header.column.id !== 'select' &&
              showGroupingIcon ? (
                // If the header can be grouped, let's add a toggle
                <>
                  {header.column.getIsGrouped() ? (
                    <button
                      className="btn m-0 p-0"
                      {...{
                        onClick: header.column.getToggleGroupingHandler(),
                        style: {
                          cursor: 'pointer',
                          fontSize: '14px',
                          height: 'auto',
                        },
                      }}
                    >
                      <span title="Ungroup Column">{`🛑(${header.column.getGroupedIndex()})`}</span>
                    </button>
                  ) : (
                    <Popup
                      trigger={<span className="ms-auto me-2">⋮</span>}
                      position="bottom left"
                      closeOnDocumentClick
                      closeOnEscape
                      on="click"
                      arrow={false}
                      contentStyle={{
                        width: 'fit-content',
                      }}
                    >
                      <div className="menu">
                        <div className="menu-item">
                          <button
                            className="btn btn-info shadow-none w-100"
                            onClick={header.column.getToggleGroupingHandler()}
                          >
                            Group
                          </button>
                        </div>
                      </div>
                    </Popup>
                  )}
                </>
              ) : null}
            </div>
            {header.column.getCanFilter() && !dontShowFilters ? (
              <div>
                <Filter column={header.column} table={table} />
              </div>
            ) : null}
          </>
        )}

        <div
          {...{
            onMouseDown: header.getResizeHandler(),
            onTouchStart: header.getResizeHandler(),
            className: `resizer ${
              header.column.getIsResizing() ? 'isResizing' : ''
            }`,
            style: {
              transform:
                columnResizeMode === 'onEnd' && header.column.getIsResizing()
                  ? `translateX(${
                      table.getState().columnSizingInfo.deltaOffset
                    }px)`
                  : '',
            },
          }}
        />
      </div>
    </div>
  )
}
export default ColumnHeader
