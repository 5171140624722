import Vehicles from '../Pages/transport/vehicles/actions/loadable'
import VehiclesList from '../Pages/transport/vehicles/loadable'
import AdvancedSearch from '../components/Clm/AdvancedSearch/AdvancedSearch'
import ClmDashboard from '../components/Clm/Dashboard/Dashboard'
import LiveLoadTracking from '../components/Clm/LiveLoadTracking/LiveLoadTracking'
import TruckDashboard from '../components/Clm/Trucks/TrucksDashboard'
import TablePage from '../components/Common/TablePage/TablePage'
import Delivery from '../components/Container/Delivery/Delivery'
import Pickup from '../components/Container/Pickup/Pickup'
import StageTracker from '../components/Container/StageTracker/StageTracker'
import CustomerCard from '../components/Customer/CustomerCard'
import OldRates from '../components/Customer/Rates/Rates'
import Dashboard from '../components/Dashboard/Dashboard'
import LoginForm from '../components/Login/Login'
import OperationsDashboard from '../components/OperationsDashboard/OperationsDashboard'
import Drivers from '../components/Transport/Drivers/Drivers'
import DriversList from '../components/Transport/Drivers/DriversList'
import CreateUser from '../components/User/CreateUser'
import AllContainerStageDetailsList from '../modules/cerebro/containers/stage-tracker/AllContainerStageDetailsList'
import CustomerRateCard from '../modules/cerebro/customer/CustomerRateCard/CustomerRateCard'
import Rates from '../modules/cerebro/customer/Rates/Rates'
import CreatePrinciple from '../modules/cerebro/customer/createPrinciple/CreatePrinciple'
import CustomersList from '../modules/cerebro/customer/customerList/CustomersList'
import DataManagementDashboard from '../modules/cerebro/customer/data-management/DataManagementDashboard'
import OrderSlotManagement from '../modules/cerebro/orders/orderSlotManagement/OrderSlotManagement'
import OrdersList from '../modules/cerebro/orders/ordersList/OrdersList'
import ServiceRates from '../modules/cerebro/orders/ordersOperations/OrdersCustomerDetails/components/ServiceRates'
import OrdersOperations from '../modules/cerebro/orders/ordersOperations/OrdersOperations'
import UsersList from '../modules/cerebro/user/UsersList'
import DriversMasterDetails from '../modules/clm/drivers/DriversMasterDetails'
import EnigmaChat from '../modules/clm/enigma-chat'
import Allocations from '../modules/clm/loads-planning/Allocations'
import UserTemplates from '../modules/clm/user-templates/UserTemplates'
import DriverDashboard from '../modules/clm/drivers/Dashboard'

const routeConfig = [
  {
    name: 'login',
    path: '/',
    component: LoginForm,
    includeHeaderFooter: false,
  },
  {
    name: 'dashboard',
    path: '/dashboard',
    component: Dashboard,
    includeHeaderFooter: true,
  },
  {
    name: 'ServiceRates',
    path: '/service-rates',
    component: ServiceRates,
    includeHeaderFooter: true,
  },
  {
    name: 'createUser',
    path: '/user/create',
    component: CreateUser,
    includeHeaderFooter: true,
    staticProps: { type: 'create' },
  },
  {
    name: 'viewUser',
    path: '/user/view/:id',
    component: CreateUser,
    includeHeaderFooter: true,
    staticProps: { type: 'view' },
  },
  {
    name: 'editUser',
    path: '/user/edit/:id',
    component: CreateUser,
    includeHeaderFooter: true,
    staticProps: { type: 'edit' },
  },
  {
    name: 'user',
    path: '/user',
    component: UsersList,
    includeHeaderFooter: true,
  },
  {
    name: 'CustomerDataManagement',
    path: '/customer/dataManagement',
    component: DataManagementDashboard,
    includeHeaderFooter: true,
    showOnlySideNavbarIcons: true,
  },
  {
    name: 'CustomerCard',
    path: '/Customer/create',
    component: CustomerCard,
    includeHeaderFooter: true,
    staticProps: { type: 'create' },
    showOnlySideNavbarIcons: true,
  },
  {
    name: 'CreateConsigneeCard',
    path: '/Customer/create/consignee',
    component: CreatePrinciple,
    includeHeaderFooter: true,
    staticProps: { profile: 'consignee' },
  },
  {
    name: 'CreateShipperCard',
    path: '/Customer/create/shipper',
    component: CreatePrinciple,
    includeHeaderFooter: true,
    staticProps: { profile: 'shipper' },
  },
  {
    name: 'CustomerRateCard',
    path: '/Customer/rate-card',
    component: CustomerRateCard,
    includeHeaderFooter: true,
    staticProps: { type: 'create' },
    showOnlySideNavbarIcons: true,
  },
  {
    name: 'CustomerCard',
    path: '/Customer/view/:id',
    component: CustomerCard,
    includeHeaderFooter: true,
    staticProps: { type: 'view' },
  },
  {
    name: 'CustomerCard',
    path: '/Customer/edit/:id',
    component: CustomerCard,
    includeHeaderFooter: true,
    staticProps: { type: 'edit' },
  },
  {
    name: 'Rates',
    path: '/Customer/action/old-rates/:type/:customerCardId',
    component: OldRates,
    includeHeaderFooter: true,
  },
  {
    name: 'Rates',
    path: '/Customer/action/rates/:type/:customerCardId',
    component: Rates,
    includeHeaderFooter: true,
  },
  {
    name: 'CustomerList',
    path: '/Customer',
    component: CustomersList,
    includeHeaderFooter: true,
  },
  {
    name: 'Drivers',
    path: '/Transport',
    component: DriversList,
    includeHeaderFooter: true,
    staticProps: {},
  },
  {
    name: 'Drivers',
    path: '/transport/drivers',
    component: DriversList,
    includeHeaderFooter: true,
    staticProps: {},
  },
  {
    name: 'Drivers',
    path: '/transport/drivers/create',
    component: Drivers,
    includeHeaderFooter: true,
    staticProps: { mode: 'create' },
  },
  {
    name: 'Drivers',
    path: '/transport/drivers/view/:id',
    component: Drivers,
    includeHeaderFooter: true,
    staticProps: { mode: 'view' },
  },
  {
    name: 'Drivers',
    path: '/transport/drivers/edit/:id',
    component: Drivers,
    includeHeaderFooter: true,
    staticProps: { mode: 'edit' },
  },
  {
    name: 'Vehicles',
    path: '/transport/vehicles',
    component: VehiclesList,
    includeHeaderFooter: true,
    staticProps: {},
  },
  {
    name: 'Vehicles',
    path: '/transport/vehicles/:actionType/:vehicle/:id',
    component: Vehicles,
    includeHeaderFooter: true,
    staticProps: { mode: 'edit' },
  },
  {
    name: 'Stage Tracker',
    path: '/container',
    component: AllContainerStageDetailsList,
    includeHeaderFooter: true,
    staticProps: { mode: 'edit' },
  },
  {
    name: 'Container Stage Tracker',
    path: '/container/stage-tracker',
    component: AllContainerStageDetailsList,
    includeHeaderFooter: true,
    staticProps: {},
  },
  {
    name: 'Container Stage Tracker',
    path: '/container/stage-tracker/edit/:containerStageTrackerId',
    component: StageTracker,
    includeHeaderFooter: true,
    staticProps: { mode: 'edit' },
  },
  {
    name: 'Container Stage Tracker',
    path: '/container/stage-tracker/view/:containerDetailsId',
    component: StageTracker,
    includeHeaderFooter: true,
    staticProps: { mode: 'edit' },
  },
  {
    name: 'Container Pickup',
    path: '/container/pickup',
    component: Pickup,
    includeHeaderFooter: true,
    staticProps: {},
  },
  {
    name: 'Container Pickup',
    path: '/container/pickup/edit/:id',
    component: Pickup,
    includeHeaderFooter: true,
    staticProps: { mode: 'edit' },
  },
  {
    name: 'Container Delivery',
    path: '/container/delivery',
    component: Delivery,
    includeHeaderFooter: true,
    staticProps: {},
  },
  {
    name: 'Container Alert',
    path: '/container/controller/:controller',
    component: TablePage,
    staticProps: {},
  },
  {
    name: 'Operations Dashboard',
    path: '/Operations',
    component: OperationsDashboard,
    includeHeaderFooter: true,
  },
  {
    name: 'OperationsList',
    path: '/Operations/list',
    component: OrdersList,
    includeHeaderFooter: true,
  },
  {
    name: 'OperationCard',
    path: '/Operations/create',
    component: OrdersOperations,
    includeHeaderFooter: true,
    staticProps: {},
    showOnlySideNavbarIcons: true,
  },
  {
    name: 'OrderSlot',
    path: '/Operations/orders-slot',
    component: OrderSlotManagement,
    includeHeaderFooter: true,
  },
  {
    name: 'OperationCard',
    path: '/Operations/view/:id',
    component: OrdersOperations,
    includeHeaderFooter: true,
    staticProps: { type: 'view' },
    showOnlySideNavbarIcons: true,
  },
  {
    name: 'OperationCard',
    path: '/Operations/edit/:id',
    component: OrdersOperations,
    includeHeaderFooter: true,
    staticProps: { type: 'edit' },
    showOnlySideNavbarIcons: true,
  },
  {
    name: 'OperationCard',
    path: '/Operations/edit/:id/stage-tracker/edit/:containerStageTrackerId',
    component: OrdersOperations,
    includeHeaderFooter: true,
    staticProps: { type: 'edit' },
  },
  {
    name: 'ClmDashboard',
    path: '/clm-dashboard',
    component: ClmDashboard,
    headerFooterOf: 'clm-dashboard',
    showSideBarOf: 'clm-dashboard',
  },
  {
    name: 'EnigmaChat',
    path: '/enigma-chat',
    component: EnigmaChat,
    headerFooterOf: 'clm-dashboard',
    showOnlySideNavbarIcons: true,
    showSideBarOf: 'clm-dashboard',
  },
  {
    name: 'AdvancedSearch',
    path: '/advanced-search',
    component: AdvancedSearch,
    headerFooterOf: 'clm-dashboard',
  },
  {
    name: 'LiveLoadTracking',
    path: '/live-load-tracking',
    component: LiveLoadTracking,
    headerFooterOf: 'clm-dashboard',
  },
  {
    name: 'User Templates',
    path: '/user-templates',
    component: UserTemplates,
    headerFooterOf: 'clm-dashboard',
  },
  {
    name: 'Loads Planning',
    path: '/loads-planning',
    component: Allocations,
    headerFooterOf: 'clm-dashboard',
    showOnlySideNavbarIcons: true,
    showSideBarOf: 'clm-dashboard',
  },
  {
    name: 'Drivers Dashboard',
    path: '/driver-dashboard',
    component: DriversMasterDetails,
    headerFooterOf: 'clm-dashboard',
    showOnlySideNavbarIcons: true,
    showSideBarOf: 'clm-dashboard',
  },
  {
    name: 'Drivers Dashboard',
    path: '/driver-dashboard-old',
    component: DriverDashboard,
    headerFooterOf: 'clm-dashboard',
    showOnlySideNavbarIcons: true,
    showSideBarOf: 'clm-dashboard',
  },
  {
    name: 'TruckDashboard',
    path: '/truck-dashboard',
    component: TruckDashboard,
    headerFooterOf: 'clm-dashboard',
  },

  // {
  //   name: "Rational Code",
  //   path: "/customer/dataManagement/rationalCode",
  //   component: DataMgmt,
  //   staticProps: { formName: "Rational Window", module: "customer", subModule: 'rationalCode' },
  //   includeHeaderFooter: true,
  // }
]

export default routeConfig
