import { useState, useEffect } from 'react'

import axios from 'axios'
import { toast } from 'react-toastify'

export function useTableView({
  url,
  headersToShow,
  editRow,
  viewRow,
  deleteRow,
  uniqueId,
  rowFilter = {},
}) {
  const [loading, setLoading] = useState(false)
  const [tableHeaderData, setTableHeaderData] = useState([])
  const [tableBodyData, setTableBodyData] = useState([])

  const fetchList = async () => {
    setLoading(true)
    axios({
      method: 'GET',
      url,
      headers: {
        Authorization: 'Bearer ' + localStorage.token,
      },
    })
      .then((response) => {
        transFormTableData(Object.keys(headersToShow), response?.data)
      })
      .catch((error) => {
        console.error('fetch error', error)
        toast.error(error?.response?.data?.error ?? 'Something went wrong!')
      })
      .finally(() => {
        setLoading(false)
      })
  }

  const transFormTableData = (keys, data) => {
    let headers = keys.map((each) => ({
      Header: headersToShow[each],
      accessor: each,
      className: each,
      filter: rowFilter[each],
    }))

    headers.push({
      width: 200,
      Header: 'Actions',

      Cell: ({ cell }) => (
        <span style={{ minWidth: '120px', display: 'flex' }}>
          <button
            title="View"
            value={cell.row.values[uniqueId]}
            type="button"
            style={{
              backgroundColor: 'transparent',
              border: 'none',
            }}
            onClick={viewRow.bind(null, cell.row.values[uniqueId])}
          >
            <img src="/assets/vIEW.png" alt="View" />
          </button>
          <button
            title="Edit"
            value={cell.row.values[uniqueId]}
            type="button"
            style={{
              backgroundColor: 'transparent',
              border: 'none',
            }}
            onClick={editRow.bind(null, cell.row.values[uniqueId])}
          >
            <img src="/assets/Edit.png" alt="Edit" />
          </button>
          <button
            title="Delete"
            value={cell.row.values[uniqueId]}
            type="button"
            style={{
              backgroundColor: 'transparent',
              border: 'none',
            }}
            onClick={deleteRow.bind(null, cell.row.values[uniqueId])}
          >
            <img src="/assets/Delete.png" alt="Delete" />
          </button>
        </span>
      ),
    })

    setTableHeaderData(headers)
    let newData = []
    data.forEach((eachObj) => {
      let newObj = {}
      keys.forEach((each) => {
        if (!eachObj[each]) newObj[each] = '-'
        else if (eachObj[each] === true) {
          newObj[each] = 'YES'
        } else if (eachObj[each] === false) {
          newObj[each] = 'NO'
        } else {
          newObj[each] = eachObj[each]
        }
        newObj.class = 'each'
      })

      newData.push(newObj)
    })

    setTableBodyData(newData)
  }

  useEffect(() => {
    fetchList()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [url])

  return {
    loading,
    tableHeaderData,
    tableBodyData,
    setTableBodyData,
    setLoading,
    fetchList,
  }
}
