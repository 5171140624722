export const transformCsvData = (data, fields, chop = 1) => {
  const clonedData = data.toSpliced(0, chop)
  clonedData.pop()
  const newData = []
  clonedData.forEach((eachItem, i) => {
    let a = []
    eachItem.forEach((item) => {
      a.push(item.replace('$', ''))
    })
    newData.push(a)
  })

  let arr = []
  newData.forEach((each, i) => {
    let newObj = {}
    fields.forEach((item, index) => {
      newObj[item] = each[index]
    })
    arr.push(newObj)
  })

  return arr
}
