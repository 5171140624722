import { Col } from 'react-bootstrap'

export default function DetailComp({ label, value, md = 6, className = '' }) {
  return (
    <Col md={md} className={className}>
      <p>
        <span className="dim-label">{label}:</span> {value}
      </p>
    </Col>
  )
}
