import { useEffect, useState } from 'react'
import { Tab, Tabs } from 'react-bootstrap'
import axios from 'axios'
import { toast } from 'react-toastify'

import Table from '../../components/Common/Tanstack-table/Table'
import Spinner from '../../components/Spinner/Spinner'
import { Columns } from './tableColumn'
import './ymsStyles.scss'
const YmsTemplate = ({ Header, url }) => {
  const [loading, setLoading] = useState(false)
  const [data, setData] = useState([])
  const getData = () => {
    setLoading(true)
    // const url = `${config.api.baseUrl}${config.api.yms.getGateIncomingDto}`
    axios({
      method: 'GET',
      url: url,
      headers: {
        Authorization: 'Bearer ' + localStorage.token,
      },
      data: {},
    })
      .then(function (response) {
        setData(response.data)
      })
      .catch(function () {
        toast.error('Something went wrong!')
      })
      .finally(() => {
        setLoading(false)
      })
  }

  useEffect(() => {
    getData()
    document.body.setAttribute('data-class', 'clm-container')
    return () => document.body.removeAttribute('data-class')
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div className="w-100 overflow-auto">
      <div className="header">
        <h6>{Header}</h6>
      </div>
      <div className="container-fluid">
        <div className="row">
          {loading && <Spinner />}
          <div className=" col-md-12">
            <Tabs
              defaultActiveKey="all"
              transition={false}
              className="mb-3"
              style={{ marginLeft: '0px' }}
              //   onSelect={(key) => setActiveTab(key)}
            >
              <Tab eventKey="all" title="All">
                <div className="columnData customiseTable cellFeatureTable">
                  <Table columns={[...Columns]} data={data} />
                </div>
              </Tab>
            </Tabs>
          </div>
        </div>
      </div>
    </div>
  )
}

export default YmsTemplate
