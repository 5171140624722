import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  showSidebar: false,
}

export const uiSlice = createSlice({
  name: 'uiReducer',
  initialState,
  reducers: {
    toggleSidebar: (state, action) => {
      state.showSidebar = !state.showSidebar
    },
    closeSidebar: (state, action) => {
      state.showSidebar = false
    },
  },
})

export const { toggleSidebar, closeSidebar } = uiSlice.actions

export const getShowSidebar = (state) => state.ui.showSidebar

export default uiSlice.reducer
