import React, { useEffect, useState } from 'react'

import AddZone from './AddZone'
import CopyZone from './CopyZone'
import ImportZone from './ImportZone'
import axios from 'axios'
import config from '../../../config'
import fieldsList from './ZoneFieldsList.json'
import ZoneTableForm from './ZoneTableForm'

import Spinner from '../../Spinner/Spinner'
const fieldsListData = JSON.stringify(fieldsList)

const ZoneProfile = (props) => {
  const { lookupsList, customerCardId } = props
  const [csvArray, setCsvArray] = useState({ data: [], igniter: new Date() })
  const [showAddZone, setShowAddZone] = useState(false)
  const [showCopyZone, setShowCopyZone] = useState(false)
  const [showImportZone, setShowImportZone] = useState(false)
  const [enableSpinner, setEnableSpinner] = useState(false)
  const [showSuccessMsg, setShowSuccessMsg] = useState(false)
  const [showError, setShowError] = useState(false)

  const [formError, setFormError] = useState(false)

  const intElmsList = [
    'genset20FtContainer',
    'genset40FtContainer',
    'primerMoverRecollection',
    'sideLoader20FtContainer',
    'sideLoader40FtContainer',
    'standard20FtContainer',
    'standard40FtContainer',
    'tollsPrice',
  ]

  useEffect(() => {
    if (customerCardId) {
      const url =
        config.api.baseUrl +
        '/customercard/zone?customerCardId=' +
        customerCardId
      const requestOptions = {
        method: 'GET',
        headers: { Authorization: 'Bearer ' + localStorage.token },
      }
      fetch(url, requestOptions)
        .then((response) => response.json())
        .then((data) => {
          data.forEach((_, i) => {
            data[i].uniqueId = (new Date().getTime() * Math.random()).toFixed()
          })
          setCsvArray({ data: data, igniter: new Date() })
        })
    }
  }, [customerCardId])

  const AddMultiZones = (e, type, zoneCodeCategory) => {
    e.preventDefault()
    setFormError(false)

    let zoneCodeCategoryVal = zoneCodeCategory
    if (type !== 'copy') {
      const zoneType = e.target.querySelector('select')
      if (!zoneType.value) {
        zoneType.classList.add('is-invalid')
        zoneType.focus()
        return false
      } else {
        zoneType.classList.remove('is-invalid')
        zoneCodeCategoryVal = e.target.querySelector('#zoneType').value
      }
    }

    let isValidData = true
    let elms = e.target.querySelectorAll('tbody tr')
    let formData = []
    elms.forEach((each) => {
      let b = each.querySelectorAll('select, input')
      let obj = {}
      b.forEach((each) => {
        let val = each.value
        if (intElmsList.includes(each.name)) {
          val = Number(val)
        }

        obj[each.name] = val

        if (!each.value) {
          isValidData = false
          each.classList.add('is-invalid')
        } else {
          each.classList.remove('is-invalid')
        }
      })
      obj.customerCardId = customerCardId
      obj.customerCardZoneId = null
      obj.zoneProfileName = zoneCodeCategoryVal
      formData.push(obj)
    })

    if (!isValidData) {
      setFormError(true)
      return false
    }

    setEnableSpinner(true)

    const url = config.api.baseUrl + '/customercard/zone/multi'
    setShowError(false)
    setShowSuccessMsg(false)
    axios({
      method: 'POST', //you can set what request you want to be
      url: url,
      data: formData,
      headers: {
        Authorization: 'Bearer ' + localStorage.token,
      },
    })
      .then(function (response) {
        setShowSuccessMsg(true)
        setEnableSpinner(false)
      })
      .catch(function (error) {
        if (error?.response?.data?.data) {
          setShowError(error?.response?.data?.data)
        } else {
          setShowError(true)
        }
        setEnableSpinner(false)
      })
  }

  const enableAddZone = () => {
    setShowAddZone(true)
    setShowImportZone(false)
    setShowCopyZone(false)
    const data = JSON.parse(fieldsListData)
    data.uniqueId = (new Date().getTime() * Math.random()).toFixed()
    setCsvArray({ data: [data], igniter: new Date() })
    setShowError(false)
    setShowSuccessMsg(false)
  }

  const enableImportZone = () => {
    setShowAddZone(false)
    setShowImportZone(true)
    setShowCopyZone(false)
    setCsvArray({ data: [], igniter: new Date() })
    setShowError(false)
    setShowSuccessMsg(false)
    setFormError(false)
  }

  const enableCopyZone = () => {
    setShowAddZone(false)
    setShowImportZone(false)
    setShowCopyZone(true)
    setShowError(false)
    setShowSuccessMsg(false)
    setCsvArray({ data: [], igniter: new Date() })
  }

  const addRow = (e) => {
    const dataObj = JSON.parse(fieldsListData)
    dataObj.uniqueId = (new Date().getTime() * Math.random()).toFixed()
    const newCsvArray = csvArray.data
    newCsvArray.push(dataObj)
    setCsvArray({ data: newCsvArray, igniter: new Date() })
  }

  const deleteRow = (id) => {
    if (props.type === 'create') {
      if (csvArray.data.length < 2) {
        alert('Atleast one row should be there')
        return false
      }
      let data = []
      csvArray.data.forEach((row, index) => {
        if (index !== id) {
          data.push(row)
        }
      })

      setCsvArray({ data: data, igniter: new Date() })
    } else if (props.type === 'edit' && csvArray.data[id].customerCardZoneId) {
      let profileObj = csvArray.data[id]
      let answer = window.confirm(
        'Are you sure to delete zone profile of ' +
          profileObj.customerCardZoneId
      )
      if (!answer) {
        return false
      }

      const url =
        config.api.baseUrl +
        '/customercard/zone/' +
        profileObj.customerCardZoneId
      const requestOptions = {
        method: 'DELETE',
        headers: { Authorization: 'Bearer ' + localStorage.token },
      }

      fetch(url, requestOptions)
        .then((response) => response.json())
        .then(() => {
          alert(
            'suburb profile id ' +
              profileObj.customerCardZoneId +
              ' has deleted successfully'
          )
          let newArray = csvArray.data
          newArray.splice(id, 1)
          setCsvArray({ data: newArray, igniter: new Date() })
        })
        .catch(() => {
          alert(
            'Unable to delete the suburb profile now please try after some time!'
          )
        })
    }
  }

  return (
    <div>
      <div className="row">
        {props.type !== 'view' && (
          <div className="col-12">
            <button
              className="btn btn-primary me-2"
              onClick={() => enableAddZone()}
              disabled={showAddZone}
            >
              Add Zone
            </button>
            <button
              className="btn btn-primary me-2"
              onClick={() => enableImportZone()}
              disabled={showImportZone}
            >
              Import Zone
            </button>
            <button
              className="btn btn-primary"
              onClick={() => enableCopyZone()}
              disabled={showCopyZone}
            >
              Copy Zone
            </button>
          </div>
        )}

        {showImportZone && (
          <div className="mt-3 col-12 import-zone">
            <ImportZone
              zoneCodes={lookupsList.zoneCodes}
              customerCardId={customerCardId}
              AddMultiZones={AddMultiZones}
              setCsvArray={setCsvArray}
              csvArray={csvArray}
              formError={formError}
              showError={showError}
              showSuccessMsg={showSuccessMsg}
              addRow={addRow}
              deleteRow={deleteRow}
              type={props.type}
            />
          </div>
        )}

        {showAddZone && (
          <div className="mt-3 col-12 add-zone">
            <AddZone
              zoneCodes={lookupsList.zoneCodes}
              customerCardId={customerCardId}
              AddMultiZones={AddMultiZones}
              setCsvArray={setCsvArray}
              csvArray={csvArray}
              formError={formError}
              showError={showError}
              showSuccessMsg={showSuccessMsg}
              addRow={addRow}
              deleteRow={deleteRow}
              type={props.type}
            />
          </div>
        )}

        {showCopyZone && (
          <div className="mt-3 col-12 copy-zone">
            <CopyZone
              zoneCodes={lookupsList.zoneCodes}
              customerCardId={customerCardId}
              AddMultiZones={AddMultiZones}
              setCsvArray={setCsvArray}
              csvArray={csvArray}
              formError={formError}
              showError={showError}
              showSuccessMsg={showSuccessMsg}
              addRow={addRow}
              deleteRow={deleteRow}
              type={props.type}
            />
          </div>
        )}
      </div>
      {!showCopyZone &&
        !showAddZone &&
        !showImportZone &&
        csvArray.data &&
        csvArray.data.length > 0 &&
        lookupsList?.zoneCodes?.length > 0 && (
          <>
            <ZoneTableForm
              zoneCodes={lookupsList.zoneCodes}
              deleteRow={deleteRow}
              csvArray={csvArray}
              addRow={addRow}
              type={props.type}
            />
          </>
        )}

      {props.type !== 'create' && csvArray.data.length === 0 && (
        <p>No Zone Profiles found for this customer</p>
      )}

      {enableSpinner && <Spinner />}
    </div>
  )
}

export default ZoneProfile
