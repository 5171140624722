import { useState } from 'react'
import { toast } from 'react-toastify'
import axios from 'axios'
import { faPencil } from '@fortawesome/free-solid-svg-icons'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import IndeterminateCheckbox from '../../../../components/Common/Tanstack-table/components/IndeterminateCheckbox'
import config from '../../../../config'
import UplDetailsModal from '../SubComponents/UplDetailsModal'
import Table from '../../../../components/Common/Tanstack-table/Table'
import Spinner from '../../../../components/Spinner/Spinner'

const AwaitingUnpack = ({
  awaitingUnpackData,
  setUplDetails,
  unpackedTableColumns,
  uplDetails,
  getWms,
  cargoDetailsTable,
}) => {
  const [loading, setLoading] = useState(false)
  const [showUPLDetailsModal, setShowUPLDetailsModal] = useState(false)
  /**
   * It returns true if all the items in the cargoList array have a check property that is true
   * @returns A boolean value.
   */
  const canUpdateToUnpacked = (cargoList, check) => {
    let canUpdate = true
    cargoList.forEach((item) => {
      if (item[check] === false) canUpdate = false
    })
    return canUpdate
  }
  const tableData = uplDetails?.orderContainerCargoDetails
  
  /**
   * It updates the status of the WMS to "Unpacked" if all the cargo is verified
   */
  const updateToUnpacked = () => {
    if (canUpdateToUnpacked(tableData, 'verified')) {
      setLoading(true)
      const url = `${config.api.baseUrl}${config.api.wms.unpacked.updateToUnpacked}${uplDetails?.wmsDetailsId}`
      axios({
        method: 'PUT',
        url: url,
        headers: {
          Authorization: 'Bearer ' + localStorage.token,
        },
      })
        .then(function (response) {
          getWms('AWAITING_UNPACK')
          toast.info('Successully Updated')
        })
        .catch(function (error) {
          toast.error('Something went wrong!')
        })
        .finally(() => {
          setShowUPLDetailsModal(false)
          setLoading(false)
        })
    } else {
      toast.warn('All Cargo Should be Verified!')
    }
  }

  const showUplDetails = (details) => {
    setUplDetails(details)
    setShowUPLDetailsModal(true)
  }
  /* replacing the first column to add UPL view edit icon in table. */
  unpackedTableColumns.splice(0, 1, {
    id: 'select',
    header: ({ table }) => (
      <IndeterminateCheckbox
        {...{
          checked: table.getIsAllRowsSelected(),
          indeterminate: table.getIsSomeRowsSelected(),
          onChange: table.getToggleAllRowsSelectedHandler(),
        }}
      />
    ),
    cell: ({ row }) => (
      <div className="px-1">
        <div className="d-flex">
          <IndeterminateCheckbox
            {...{
              checked: row.getIsSelected(),
              indeterminate: row.getIsSomeSelected(),
              onChange: row.getToggleSelectedHandler(),
            }}
          />
          <button
            className="btn m-0 p-0 "
            onClick={() => {
              showUplDetails(row.original)
            }}
          >
            <FontAwesomeIcon icon={faPencil} className="ps-1" />
          </button>
        </div>
        {row.getIsExpanded() && row.original.jobNumber}
      </div>
    ),
  })

  return (
    <div className="">
      {loading && <Spinner />}
      <div className="columnData customiseTable ">
        <Table
          columns={[...unpackedTableColumns]}
          data={awaitingUnpackData}
          // setSelectedRows={setSelectedUnplannedLoads}
          draggable={false}
          nestedTableDataId="orderContainerCargoDetails"
          renderSubComponent={cargoDetailsTable}
          getRowCanExpand={() => true}
        />
      </div>

      {showUPLDetailsModal && (
        <UplDetailsModal
          uplDetails={uplDetails}
          setShowUPLDetailsModal={setShowUPLDetailsModal}
        >
          <div className="flex-content p-2">
            <div className=""></div>
            <button onClick={updateToUnpacked} className="btn btn-primary">
              Unpack
            </button>
          </div>
        </UplDetailsModal>
      )}
    </div>
  )
}
export default AwaitingUnpack
