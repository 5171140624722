import React from 'react'
import { Spinner } from 'react-bootstrap'

export default function TableBottom({
  resetOrder,
  resetFilters,
  table,
  showOnlyPagination,
  isFetching,
}) {
  return (
    <div className="d-flex">
      {!showOnlyPagination ? (
        <>
          <button onClick={() => resetOrder()} className="btn btn-primary p-2">
            Reset Column Order
          </button>
          <button
            onClick={() => resetFilters()}
            className="btn btn-primary ms-1 p-2"
          >
            Reset Filter
          </button>
        </>
      ) : null}
      <div className="d-flex ms-auto justify-content-center">
        {isFetching && <Spinner />}
        <button
          className="btn border tanstack-table-hook-pg-navigation-btn"
          onClick={() => table.setPageIndex(0)}
          disabled={!table.getCanPreviousPage()}
        >
          {'<<'}
        </button>
        <button
          className="btn border tanstack-table-hook-pg-navigation-btn"
          onClick={() => table.previousPage()}
          disabled={!table.getCanPreviousPage()}
        >
          {'<'}
        </button>
        <button
          className="btn border tanstack-table-hook-pg-navigation-btn"
          onClick={() => table.nextPage()}
          disabled={!table.getCanNextPage()}
        >
          {'>'}
        </button>
        <button
          className="btn border tanstack-table-hook-pg-navigation-btn"
          onClick={() => table.setPageIndex(table.getPageCount() - 1)}
          disabled={!table.getCanNextPage()}
        >
          {'>>'}
        </button>
        <span className="d-flex mx-2">
          <div>Page </div>
          <strong>
            {table.getState().pagination.pageIndex + 1} of{' '}
            {table.getPageCount()}
          </strong>
        </span>
        {!showOnlyPagination ? (
          <span className="d-flex">
            <span style={{ whiteSpace: 'nowrap' }} className="me-1">
              | Go to page:
            </span>
            <input
              style={{ fontSize: 'inherit' }}
              type="number"
              defaultValue={table.getState().pagination.pageIndex + 1}
              onChange={(e) => {
                const page = e.target.value ? Number(e.target.value) - 1 : 0
                table.setPageIndex(page)
              }}
              className="form-control border ps-2 me-1"
            />
          </span>
        ) : null}
        <select
          className="border"
          value={table.getState().pagination.pageSize}
          onChange={(e) => {
            table.setPageSize(Number(e.target.value))
          }}
        >
          {[10, 50, 100, 200, 300, 400, 500, 750, 1000].map((pageSize) => (
            <option key={pageSize} value={pageSize}>
              Show {pageSize}
            </option>
          ))}
        </select>
      </div>
    </div>
  )
}
