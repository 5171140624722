import axios from 'axios'
import config from '../../../config'
import { formData } from './StageTrackerFormData'
import { toast } from 'react-toastify'

export const getDrivers = async () => {
  const { data } = await axios({
    method: 'GET',
    url: `${config.api.baseUrl}/transports/driver/card/all/driver-vehicles`,
    headers: {
      Authorization: 'Bearer ' + localStorage.token,
    },
  })
  return data
}

export const getTruckRegos = async () => {
  const { data } = await axios({
    method: 'GET',
    url: `${config.api.baseUrl}/transports/vehicle/truck/unassigned-available-trucks`,
    headers: {
      Authorization: 'Bearer ' + localStorage.token,
    },
  })
  return data
}

export const getTrailerNumbers = async () => {
  const { data } = await axios({
    method: 'GET',
    url: `${config.api.baseUrl}/transports/vehicle/trailer/all`, // [TODO]: to verify the endpoint (default-trailer)
    headers: {
      Authorization: 'Bearer ' + localStorage.token,
    },
  })

  return data
}

export const getAdditionalLookupData = async (setAdditionalLookupDataState) => {
  const [drivers, truckRegos, trailerNumbers] = await Promise.all([
    getDrivers(),
    getTruckRegos(),
    getTrailerNumbers(),
  ])
  setAdditionalLookupDataState({
    drivers,
    truckRegos,
    trailerNumbers,
  })
}

export const getLookUpOptionsFromArray = (data, field, id, label) => {
  let options = []
  data &&
    data[field.loadLookUp] &&
    data[field.loadLookUp].forEach((val) => {
      let option = {
        id: val[id] ?? val,
        label: val[label] ?? val,
      }
      options.push(option)
    })
  field.options = options
}

// Mention all the dependency fields and the transformation logics, drop down values
export const setLookupFields = (lookupData, setFormFields) => {
  const newFormData = []
  if (lookupData) {
    formData?.forEach(async (field) => {
      const newField = { ...field }
      if (
        newField?.fieldId === 'doorPosition' &&
        newField.loadLookUp === 'doorPositionEnums'
      ) {
        getLookUpOptionsFromArray(lookupData, newField)
      }
      newFormData.push(newField)
    })
  }
  setFormFields(newFormData)
}

export const setByDriver = (driverId, lookupData, setMethods) => {
  const { setFormFields, setDetail } = setMethods
  setLookupFields(lookupData, setFormFields)
  setDetail((prev) => {
    const newDetail = { ...prev }
    newDetail.driverId = driverId || null
    return newDetail
  })
}

export const updateStageField = (formFields, stagesOptions) => {
  const newFormFields = [...formFields]
  const stageFieldIdx = newFormFields.findIndex((f) => f.id === 'stage')
  newFormFields[stageFieldIdx] = {
    ...newFormFields[stageFieldIdx],
    disabled: false,
    type: 'select',
    options: stagesOptions.map((op) => ({ id: op, label: op })),
  }
  return [...newFormFields]
}

export const getGeoLocation = (id, setMethods) => {
  const { setShow, setLoading, setGeoLocation } = setMethods
  if (!id) {
    return toast.info('Truck Rego cant be null')
  }
  setShow(true)
  setLoading(true)
  const url = `${config.api.baseUrl}${config.api.getWebfleetResponse}${id}`
  axios({
    method: 'GET',
    url: url,
    headers: {
      Authorization: 'Bearer ' + localStorage.token,
    },
  })
    .then(function (response) {
      setGeoLocation({
        lat: response.data[0].latitude_mdeg,
        long: response.data[0].longitude_mdeg,
        postext: response.data[0].postext,
        pos_time: response.data[0].pos_time,
        objecttype: response.data[0].objecttype,
        objectname: response.data[0].objectname,
      })
    })
    .catch(function () {
      toast.error('Something went wrong!')
    })
    .finally(() => {
      setLoading(false)
    })
}
