const vehicleJobType = {
  name: 'Delivery Method',
  path: '/dataMgmt/user/profileType',
  req: '/',
  columns: 4,
  fields: [
    {
      name: 'ID',
      label: 'ID',
      type: 'text',
      validation: 'Alphabetic',
      fieldId: 'id',
      id: 'id',
      validationType: 'string',
      validations: [
        {
          type: 'required',
          params: ['this field is required'],
        },
        {
          type: 'max',
          params: [20, 'ID be more than 20 characters'],
        },
        {
          type: 'min',
          params: [3, 'ID should be min 3 characters'],
        },
      ],
    },
    {
      name: 'Uncouple',
      label: 'Uncouple',
      type: 'checkbox',
      validation: 'Alphabetic',
      fieldId: 'uncouple',
      id: 'uncouple',
      validationType: 'boolean',
    },
    {
      name: 'Trailer Type',
      label: 'Trailer Type',
      type: 'select',
      validation: 'Alphabetic',
      fieldId: 'trailerType',
      id: 'trailerType',
      validationType: 'string',
      options: [
        {
          label: 'DROP',
          id: 'drop',
        },
        {
          label: 'FT',
          id: 'FT',
        },
        {
          label: 'NORMAL',
          id: 'NORMAL',
        },
        {
          label: 'RT',
          id: 'RT',
        },
        {
          label: 'SBDL',
          id: 'SBDL',
        },
        {
          label: 'SK',
          id: 'SK',
        },
        {
          label: 'SL',
          id: 'SL',
        },
        {
          label: 'TL',
          id: 'TL',
        },
        {
          label: 'TP',
          id: 'TP',
        },
      ],
      validations: [
        {
          type: 'required',
          params: ['this field is required'],
        },
      ],
    },
    {
      name: 'Drop Off',
      label: 'Drop Off',
      type: 'checkbox',
      validation: 'Alphabetic',
      fieldId: 'dropOff',
      id: 'dropOff',
      validationType: 'boolean',
    },
    {
      name: 'Description',
      label: 'Description',
      type: 'text',
      validation: 'Alphabetic',
      fieldId: 'description',
      id: 'description',
      validationType: 'string',
      validations: [
        {
          type: 'required',
          params: ['this field is required'],
        },
        {
          type: 'max',
          params: [30, 'Description cannot be more than 30 characters'],
        },
        {
          type: 'min',
          params: [3, 'Description should be min 3 characters'],
        },
      ],
    },
    {
      name: 'Multi Drop',
      label: 'Multi Drop',
      type: 'checkbox',
      validation: 'Alphabetic',
      fieldId: 'multiDrop',
      id: 'multiDrop',
      validationType: 'boolean',
    },
  ],
}
export default vehicleJobType
