import React from 'react'
import useTableColumnsWithEditDelete from '../hooks/useTableColumnsWithEditDelete'
import { useState } from 'react'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import fetchService from '../../../../../components/utils/fetchService'
import { Success } from '../../../../../components/utils/Toastify'
import { transformCsvData } from '../utils'
import Papa from 'papaparse'
import Dropzone from 'react-dropzone'
import isEmpty from 'lodash/isEmpty'
import Table from '../../../../../components/Common/Tanstack-table/Table'
import { Button } from 'react-bootstrap'

export default function ImportCsv({
  profileId,
  profileIdKey,
  csvFields,
  postUrl,
  invalidate,
  listRows,
  header,
  EditModal,
}) {
  const queryClient = useQueryClient()
  const [importedData, setImportedData] = useState([])
  const [error, setError] = useState([])
  const [columns, selected, unSelect] = useTableColumnsWithEditDelete({
    fields: csvFields,
    setDetails: setImportedData,
  })

  const mutation = useMutation({
    mutationFn: async (body) => {
      return await fetchService({
        url: postUrl,
        method: 'POST',
        body,
      })
    },
    onError: (error) => {
      if (error?.data && Array.isArray(error.data)) setError(error.data)
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [invalidate],
      })
      Success('Updated Successfully')
      setImportedData([])
    },
  })

  const handleAcceptedFiles = (files) => {
    const reader = new FileReader()
    reader.onload = async ({ target }) => {
      Papa.parse(target.result, {
        header: false,
        complete: ({ data }) => {
          if (data.length < listRows) {
            Error('Please provide a valid csv file')
            return false
          }
          setImportedData(transformCsvData(data, csvFields, listRows - 1))
        },
      })
    }
    reader.readAsText(files[0])
  }

  const saveCsvData = () => {
    setError([])
    mutation.mutate(
      importedData.map((s) => ({
        ...s,
        [profileIdKey]: profileId,
      }))
    )
  }

  const handleCancel = () => {
    setImportedData([])
    setError([])
  }

  return (
    <div className="my-3">
      <h3>{header}</h3>
      <Dropzone
        onDrop={(acceptedFile) => handleAcceptedFiles(acceptedFile)}
        multiple={false}
        accept={{
          'text/csv': [],
        }}
      >
        {({ getRootProps, getInputProps }) => (
          <div className="drop-zone-container" {...getRootProps()}>
            <input {...getInputProps()} />
            <h4>Drag a file here</h4>
          </div>
        )}
      </Dropzone>
      {!isEmpty(importedData) && (
        <div className="mt-3">
          <h3>Imported CSV Data</h3>

          <Table
            showGlobalSearch={false}
            showTableBottom={false}
            columns={columns}
            data={importedData}
          />
          {!isEmpty(error) &&
            error.map(({ key, message }, i) => (
              <p key={`${key}-${i}`} className="text-danger">
                {key} - {message}
              </p>
            ))}
          <div className="d-flex">
            <div className="d-flex gap-2 ms-auto">
              <Button variant="danger" onClick={handleCancel}>
                Cancel
              </Button>
              <Button onClick={saveCsvData}>Save</Button>
            </div>
          </div>
          <hr className="my-3" />
        </div>
      )}
      {selected.data ? (
        <EditModal
          handleClose={() => {
            unSelect()
          }}
          profileIdKey={profileIdKey}
          selectedDetail={selected.data}
          profileId={profileId}
          handleOnSubmit={(formData) => {
            setImportedData((prev) =>
              prev.toSpliced(selected.index, 1, formData)
            )
            unSelect()
          }}
        />
      ) : null}
    </div>
  )
}
