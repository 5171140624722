export const FORKLIFT = []

export const TRAILER = [
  {
    name: 'Bat No',
    label: 'Bat No',
    type: 'text',
    id: 'batNo',
    fieldId: 'batNo',
  },
  {
    name: 'Status',
    label: 'Status',
    type: 'text',
    id: 'status',
    fieldId: 'status',
  },
  {
    name: 'Colour',
    label: 'Colour',
    type: 'text',
    id: 'colour',
    fieldId: 'colour',
  },
  {
    name: 'Created By',
    label: 'Created By',
    type: 'text',
    id: 'createdBy',
    fieldId: 'createdBy',
  },
  {
    name: 'Created Date',
    label: 'Created Date',
    type: 'date',
    id: 'createdDate',
    fieldId: 'createdDate',
  },
  {
    name: 'Display Value',
    label: 'Display Value',
    type: 'text',
    id: 'displayValue',
    fieldId: 'displayValue',
  },
  {
    name: 'Engine No',
    label: 'Engine No',
    type: 'text',
    id: 'engineNo',
    fieldId: 'engineNo',
  },
  {
    name: 'Fleet No',
    label: 'Fleet No',
    type: 'text',
    id: 'fleetNo',
    fieldId: 'fleetNo',
  },
  {
    name: 'Freight Mode',
    label: 'Freight Mode',
    type: 'text',
    id: 'freightMode',
    fieldId: 'freightMode',
  },
  {
    name: 'GCM',
    label: 'GCM',
    type: 'text',
    id: 'gcm',
    fieldId: 'gcm',
  },
  {
    name: 'GVM',
    label: 'GVM',
    type: 'text',
    id: 'gvm',
    fieldId: 'gvm',
  },
  {
    name: 'Image File Name',
    label: 'Image File Name',
    type: 'text',
    id: 'imageFileName',
    fieldId: 'imageFileName',
  },
  {
    name: 'imagePath',
    label: 'imagePath',
    type: 'text',
    id: 'imagePath',
    fieldId: 'imagePath',
  },
  {
    name: 'Insurance Renewal Date',
    label: 'Insurance Renewal Date',
    type: 'date',
    id: 'insuranceRenewalDate',
    fieldId: 'insuranceRenewalDate',
  },
  {
    name: 'Last Modified By',
    label: 'Last Modified By',
    type: 'text',
    id: 'lastModifiedBy',
    fieldId: 'lastModifiedBy',
  },
  {
    name: 'Last Modified Date',
    label: 'Last Modified Date',
    type: 'date',
    id: 'lastModifiedDate',
    fieldId: 'lastModifiedDate',
  },
  {
    name: 'License Plat No',
    label: 'License Plat No',
    type: 'text',
    id: 'licensePlatNo',
    fieldId: 'licensePlatNo',
  },
  {
    name: 'Manufactured Date',
    label: 'Manufactured Date',
    type: 'date',
    id: 'manufacturedDate',
    fieldId: 'manufacturedDate',
  },
  {
    name: 'Mime Type',
    label: 'Mime Type',
    type: 'text',
    id: 'mimeType',
    fieldId: 'mimeType',
  },
  {
    name: 'Model',
    label: 'Model',
    type: 'text',
    id: 'model',
    fieldId: 'model',
  },
  {
    name: 'Registration Renewal Date',
    label: 'Registration Renewal Date',
    type: 'date',
    id: 'registrationRenewalDate',
    fieldId: 'registrationRenewalDate',
  },
  {
    name: 'Road Tax Expiry',
    label: 'Road Tax Expiry',
    type: 'date',
    id: 'roadTaxExpiry',
    fieldId: 'roadTaxExpiry',
  },
  {
    name: 'Tare Weight',
    label: 'Tare Weight',
    type: 'text',
    id: 'tareWeight',
    fieldId: 'tareWeight',
  },
  {
    name: 'Truck Type',
    label: 'Truck Type',
    type: 'text',
    id: 'truckType',
    fieldId: 'truckType',
  },
  {
    name: 'Vehicle Location',
    label: 'Vehicle Location',
    type: 'text',
    id: 'vehicleLocation',
    fieldId: 'vehicleLocation',
  },
  {
    name: 'Vehicle Size',
    label: 'Vehicle Size',
    type: 'text',
    id: 'vehicleSize',
    fieldId: 'vehicleSize',
  },
  {
    name: 'Vehicle Truck Id',
    label: 'Vehicle Truck Id',
    type: 'text',
    id: 'vehicleTruckId',
    fieldId: 'vehicleTruckId',
  },
  {
    name: 'Vin No',
    label: 'Vin No',
    type: 'text',
    id: 'vinNo',
    fieldId: 'vinNo',
  },
]

export const TRUCK = [
  {
    Header: 'Bat No',
    type: 'text',
    accessor: 'batNo',
    Filter: '',
  },
  {
    Header: 'Status',
    type: 'text',
    accessor: 'stageStatus',
    Filter: '',
  },
  {
    Header: 'Driver Status',
    type: 'text',
    accessor: 'driverStatus',
    Filter: '',
  },
  {
    Header: 'Colour',
    type: 'text',
    accessor: 'colour',
    Filter: '',
  },
  {
    Header: 'Created By',
    type: 'text',
    accessor: 'createdBy',
    Filter: '',
  },
  {
    Header: 'Created Date',
    type: 'date',
    accessor: 'createdDate',
    Filter: '',
  },
  {
    Header: 'Default Trailer Ids',
    type: 'text',
    accessor: 'defaultTrailerIds',
    Filter: '',
  },
  {
    Header: 'Display Value',
    type: 'text',
    accessor: 'displayValue',
    Filter: '',
  },
  {
    Header: 'Engine No',
    type: 'text',
    accessor: 'engineNo',
    Filter: '',
  },
  {
    Header: 'Fleet No',
    type: 'text',
    accessor: 'fleetNo',
    Filter: '',
  },
  {
    Header: 'Freight Mode',
    type: 'text',
    accessor: 'freightMode',
    Filter: '',
  },
  {
    Header: 'GCM',
    type: 'text',
    accessor: 'gcm',
    Filter: '',
  },
  {
    Header: 'GVM',
    type: 'text',
    accessor: 'gvm',
    Filter: '',
  },
  {
    Header: 'Image File Name',
    type: 'text',
    accessor: 'imageFileName',
    Filter: '',
  },
  {
    Header: 'imagePath',
    type: 'text',
    id: 'imagePath',
    Filter: '',
  },
  {
    Header: 'Insurance Renewal Date',
    type: 'date',
    accessor: 'insuranceRenewalDate',
    Filter: '',
  },
  {
    Header: 'Last Modified By',
    type: 'text',
    accessor: 'lastModifiedBy',
    Filter: '',
  },
  {
    Header: 'Last Modified Date',
    type: 'date',
    accessor: 'lastModifiedDate',
    Filter: '',
  },
  {
    Header: 'License Plat No',
    type: 'text',
    accessor: 'licensePlatNo',
    Filter: '',
  },
  {
    Header: 'Manufactured Date',
    type: 'date',
    accessor: 'manufacturedDate',
    Filter: '',
  },
  {
    Header: 'Mime Type',
    type: 'text',
    accessor: 'mimeType',
    Filter: '',
  },
  {
    Header: 'Model',
    type: 'text',
    accessor: 'model',
    Filter: '',
  },
  {
    Header: 'Registration Renewal Date',
    type: 'date',
    accessor: 'registrationRenewalDate',
    Filter: '',
  },
  {
    Header: 'Road Tax Expiry',
    type: 'date',
    accessor: 'roadTaxExpiry',
    Filter: '',
  },
  {
    Header: 'Tare Weight',
    type: 'text',
    accessor: 'tareWeight',
    Filter: '',
  },
  {
    Header: 'Truck Type',
    type: 'text',
    accessor: 'truckType',
    Filter: '',
  },
  {
    Header: 'Vehicle Location',
    type: 'text',
    accessor: 'vehicleLocation',
    Filter: '',
  },
  {
    Header: 'Vehicle Size',
    type: 'text',
    accessor: 'vehicleSize',
    Filter: '',
  },
  {
    Header: 'Vehicle Truck Id',
    type: 'text',
    accessor: 'vehicleTruckId',
    Filter: '',
  },
  {
    Header: 'Vin No',
    type: 'text',
    accessor: 'vinNo',
    Filter: '',
  },
]

export const CONTAINERLOAD = [
  {
    name: 'Action',
    label: 'Action',
    type: 'text',
    id: 'stageType',
    fieldId: 'stageType',
  },
  {
    name: 'Scheduled Date and Time',
    label: 'Scheduled Date and Time',
    type: 'date',
    id: 'scheduledDateTime',
    fieldId: 'scheduledDateTime',
  },
  {
    name: 'Actual Date and Time',
    label: 'Actual Date and Time',
    type: 'date',
    id: 'arrivalTime',
    fieldId: 'arrivalTime',
  },
  {
    name: 'Departure Time',
    label: 'Departure Time',
    type: 'date',
    id: 'departureTime',
    fieldId: 'departureTime',
  },
  {
    name: 'Notes',
    label: 'Notes',
    type: 'text',
    id: 'notes',
    fieldId: 'notes',
  },
  {
    name: 'Status',
    label: 'Status',
    type: 'text',
    id: 'status',
    fieldId: 'status',
  },
]
