import React from 'react'
import { ListGroup } from 'react-bootstrap'
import DriverListItem from './DriverListItem'
import { useDriversDetails } from '../../../../helper/hooks/apiHooks'
import { selectStatus } from '../../../../store/slices/allocation/drivers'
import { useSelector } from 'react-redux'

export default function DriversList() {
  const status = useSelector(selectStatus)
  const { data: drivers } = useDriversDetails(status)

  if (drivers?.length === 0)
    return <p className="text-danger">No Drivers data found</p>

  if (!Array.isArray(drivers))
    return <p className="text-danger">Drivers Array not found</p>

  return (
    <ListGroup
      // variant="flush"
      className="mb-2"
      style={{
        maxHeight: 'calc(100vh - 180px)',
        overflowY: 'scroll',
      }}
    >
      {drivers.map((driver) => (
        <DriverListItem driver={driver} key={driver.driverId} />
      ))}
    </ListGroup>
  )
}
