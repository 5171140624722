import React, { useMemo, useState } from 'react'
import { Button, Modal } from 'react-bootstrap'
import Table from '../../../../../components/Common/Tanstack-table/Table'
import CenterSpinner from '../../../../../components/Spinner/CenterSpinner'
import { generateTableColumns } from '../../../../../components/utils/tables'
import { useRunSheetApi } from '../../hooks/useRunSheetAPIs'
import DateSelector from './DateSelector'

export default function RunSheetDriversListModal({ endpoint, header }) {
  const [modalShow, setModalShow] = React.useState(false)
  const [startDate, setStartDate] = useState('sixMonthsBefore')

  const {
    data: queryData,
    isLoading,
    error,
  } = useRunSheetApi(endpoint, startDate)

  const columns = useMemo(() => {
    if (isLoading || error) return []
    let columns = []
    if (queryData?.data?.count) {
      columns = generateTableColumns(queryData.data.driverDetailDTOS[0])
      //   columns.push(generateDriversEditViewDeleteColumn('driverId', true))
    }
    return columns
  }, [
    error,
    isLoading,
    queryData?.data?.count,
    queryData?.data?.driverDetailDTOS,
  ])

  if (isLoading) return <CenterSpinner />

  if (error) return <pre>{JSON.stringify(error, null, 2)}</pre>

  const handleClose = () => setModalShow(false)
  return (
    <>
      <button className="unset-all" onClick={() => setModalShow(true)}>
        {queryData?.data?.count}
      </button>
      <Modal
        show={modalShow}
        onHide={handleClose}
        size="xl"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">{header}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="d-flex">
            <DateSelector startDate={startDate} setStartDate={setStartDate} />
          </div>
          <Table data={queryData.data.driverDetailDTOS} columns={columns} />
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={handleClose}>Close</Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}
