import React, { useState } from 'react'
import config from '../../../../../../config'
import useModalContainer from '../../../../../../helper/hooks/useModalContainer'
import * as Toastify from '../../../../../../components/utils/Toastify'

const ServiceRates = ({ contextAPIData }) => {
  const [serviceRatesList, setServiceRatesList] = useState([])

  const getServiceRates = (_orderCustomerDetailsId) => {
    const url =
      config.api.baseUrl +
      '/operations/order/container/service-rates/by-customer?orderCustomerDetailsId=' +
      _orderCustomerDetailsId
    const requestOptions = {
      method: 'GET',
      headers: { Authorization: 'Bearer ' + localStorage.token },
    }
    fetch(url, requestOptions)
      .then((response) => response.json())
      .then((data) => {
        setServiceRatesList(data)
      })
      .catch((error) => {
        Toastify.Error(error?.response?.data?.error)
      })
  }

  const { ModalContainer, setShow } = useModalContainer({
    text: (
      <button
        onClick={() => {
          if (contextAPIData.orderCustomerDetailsId > 0) {
            getServiceRates(contextAPIData.orderCustomerDetailsId)
            setShow(true)
          }
        }}
        className="unset-all d-flex flex-column align-items-center"
        title="Service rate"
      >
        <img
          src="./assets/customer-order/service-rates.png"
          alt="Service rate"
          width="40px"
          height="40px"
          className="img"
        />
        Service rate
      </button>
    ),
    header: 'Service Rates',
    body: (
      <div className="row">
        <div className="col-md-12">
          <div className="">
            <table className="table table-hover">
              <thead>
                <tr>
                  <th>Rate Code</th>
                  <th>Container Number</th>
                  <th>Description</th>
                  <th>Quantity</th>
                  <th>Notes</th>
                  <th>Custom Added By</th>
                </tr>
              </thead>
              <tbody>
                {serviceRatesList?.map?.((_serviceRate, i) => {
                  return (
                    <tr key={i}>
                      <td>{_serviceRate?.rateCode}</td>
                      <td>{_serviceRate?.containerNumber}</td>
                      <td>{_serviceRate?.description}</td>
                      <td>{_serviceRate?.quantity}</td>
                      <td>{_serviceRate?.notes}</td>
                      <td>{_serviceRate?.createdBy}</td>
                    </tr>
                  )
                })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    ),
  })

  return ModalContainer
}

export default ServiceRates
