import { useState } from 'react'
import { useForm } from 'react-hook-form'
import axios from 'axios'
import { yupResolver } from '@hookform/resolvers/yup'
import * as Yup from 'yup'
import { toast } from 'react-toastify'

import { TextBox } from '../../../../components/utils/TextBox'
import config from '../../../../config'

import Spinner from '../../../../components/Spinner/Spinner'

const { api } = config

const CargoDetails = ({
  cargoDetails,
  defaultValues,
  getWms,
  setShowCargoVerifyModal,
}) => {
  const [loading, setLoading] = useState(false)
  const validationSchema = Yup.object().shape({
    palletsNo: Yup.string().required('Pallets no is required'),
    comments: Yup.string().required('Outturn comment is required'),
  })
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
    reValidateMode: 'onChange',
    defaultValues: defaultValues,
  })
  const handleVerify = (formData) => {
    setLoading(true)
    const url = `${api.baseUrl}${api.wms.unpacked.verifyCargoById}${cargoDetails.cargoDetailsId}&palletsNo=${formData.palletsNo}&comments=${formData.comments}&wmsId=${cargoDetails.wmsDetailsId}`
    axios({
      method: 'PUT',
      url: url,
      headers: {
        Authorization: 'Bearer ' + localStorage.token,
      },
    })
      .then(function (response) {
        getWms && getWms('AWAITING_UNPACK')
        toast.info('Successully Updated')
      })
      .catch(function (error) {
        toast.error('Something went wrong!')
      })
      .finally(() => {
        setLoading(false)
        setShowCargoVerifyModal && setShowCargoVerifyModal(false)
      })
  }
  const cargoDetailsArray = [
    { title: 'Cargo Number', data: cargoDetails.cargoNumber },
    { title: 'Cargo Type', data: cargoDetails.cargoType },
    { title: 'Cargo Description', data: cargoDetails.cargoDescription },
    { title: 'Cargo Line No', data: cargoDetails.cargoLineNo },
    { title: 'Customer ID', data: cargoDetails.customerId },
    { title: 'Product Code', data: cargoDetails.productCode },
    { title: 'HBL No', data: cargoDetails.hblNo },
    { title: 'Volume', data: cargoDetails.volume },
    { title: 'Weight', data: cargoDetails.weight },
    { title: 'PO No', data: cargoDetails.poNo },
    { title: 'Quantity', data: cargoDetails.quantity },
    { title: 'Consignee Name', data: cargoDetails.consigneeName },
    { title: 'Consignment No', data: cargoDetails.consignmentNo },
    { title: 'Measuring Unit', data: cargoDetails.mu },
    { title: 'Batch No', data: cargoDetails.batchNo },
    { title: 'Expiry Date', data: cargoDetails.expiryDate },
    { title: 'Stock Room', data: cargoDetails.stockRoom },
    { title: 'IMO code', data: cargoDetails.imoCode },
    { title: 'UN code', data: cargoDetails.unCode },
    { title: 'Marks Numbers', data: cargoDetails.marksNumbers },
    { title: 'eido PIN', data: cargoDetails.eidoPIN },
    { title: 'Dimension Unit', data: cargoDetails.dimensionUnit },
    { title: 'Remarks', data: cargoDetails.remarks },
  ]
  return (
    <div className="row p-3">
      {loading && <Spinner />}
      {/* details section */}
      <div className="col-md-6 me-auto">
        <div className="h-100 flex-content flex-column col-md-12">
          <div className="fs-5 px-2 text">Cargo Details:</div>
          {cargoDetailsArray.map((item, index) => (
            <div key={index} className="col-md-12 row">
              <i className="col-md-6">{item.title} :</i>
              <strong className="col-md-6 text">{item.data}</strong>
            </div>
          ))}
        </div>
      </div>
      {/* verify form section */}
      <div className="col-md-6">
        <div className="h-100  flex-column">
          <div className="fs-5 mb-3 text">Container No : </div>
          <div className="flex-content p-2">
            <i className="">Cargo Number : </i>
            <strong className="text">{cargoDetails.cargoNumber}</strong>
          </div>
          <div className="flex-content p-2">
            <div className="">No of pallets :</div>
            <div className="">
              <TextBox
                register={register}
                id={`palletsNo`}
                type="number"
                errors={errors}
                placeholder="Pallets Number"
                maxLength="80"
                disabled={cargoDetails.verified}
              />
            </div>
          </div>

          <div className="flex-content p-2 mb-auto">
            <div className="">Outturn Comments :</div>
            <div className="">
              <TextBox
                register={register}
                id={`comments`}
                errors={errors}
                placeholder="Outturn Comments"
                disabled={cargoDetails.verified}
                autocomplete="off"
                // maxLength="80"
                // disabled={type === 'view'}
              />
            </div>
          </div>

          <div className="flex-content">
            <div className=""></div>
            <button
              className="btn btn-primary"
              onClick={handleSubmit(handleVerify)}
              disabled={cargoDetails.verified}
            >
              {cargoDetails.verified ? 'verified' : 'verify'}
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default CargoDetails
