import DriversDashboard from '../../modules/cerebro/transport/vehicles-dashboard/DriversDashboard'

const routes = [
  {
    name: 'Vehicles Dashboard',
    path: '/transport/drivers/dashboard',
    component: DriversDashboard,
    includeHeaderFooter: true,
    staticProps: {},
  },
]

export default routes
