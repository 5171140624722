const equipment_size = {
  name: 'Equipment Size',
  apiUrlAppend: false,
  path: '/dataMgmt/customer/equipment_size',
  req: '/',
  columns: 4,
  fields: [
    {
      name: 'Size',
      label: 'Size',
      type: 'text',
      validation: 'Alphabetic',
      fieldId: 'size',
      id: 'size',
      validationType: 'string',
      validations: [
        {
          type: 'required',
          params: ['Size is required'],
        },
        {
          type: 'max',
          params: [10, 'Size cannot be more than 10 characters'],
        },
      ],
    },
    {
      name: 'Truck',
      label: 'Truck',
      type: 'checkbox',
      validation: 'Alphabetic',
      fieldId: 'truck',
      id: 'truck',
      validationType: 'boolean',
    },
    {
      name: 'Type',
      label: 'Type',
      type: 'text',
      validation: 'Alphabetic',
      fieldId: 'type',
      id: 'type',
      validationType: 'string',
      validations: [],
    },
    {
      name: 'Container',
      label: 'Container',
      type: 'checkbox',
      validation: 'Alphabetic',
      fieldId: 'container',
      id: 'container',
      validationType: 'boolean',
    },
    {
      name: 'ISO Code',
      label: 'ISO Code',
      type: 'text',
      validation: 'Alphabetic',
      fieldId: 'isoCode',
      id: 'isoCode',
      validationType: 'string',
      validations: [],
    },
    {
      name: 'Reefer',
      label: 'Reefer',
      type: 'checkbox',
      validation: 'Alphabetic',
      fieldId: 'reefer',
      id: 'reefer',
      validationType: 'boolean',
    },
    {
      name: 'Tare Weight',
      label: 'Tare Weight',
      type: 'number',
      validation: 'Numeric',
      fieldId: 'tareWeight',
      id: 'tareWeight',
    },
    {
      name: 'DistinctConduct',
      label: 'DistinctConduct',
      type: 'checkbox',
      validation: 'Alphabetic',
      fieldId: 'distinctConduct',
      id: 'distinctConduct',
      validationType: 'boolean',
    },
    {
      name: 'Description',
      label: 'Description',
      type: 'textarea',
      validation: 'Alphabetic',
      fieldId: 'description',
      id: 'description',
      validationType: 'string',
      validations: [
        {
          type: 'required',
          params: ['Description is required'],
        },
        {
          type: 'max',
          params: [50, 'Description cannot be more than 50 characters'],
        },
      ],
    },
    {
      name: 'TEU',
      label: 'TEU',
      type: 'number',
      validation: 'Numeric',
      fieldId: 'teu',
      id: 'teu',
    },
    {
      name: 'Gross Weight',
      label: 'Gross Weight',
      type: 'number',
      validation: 'Numeric',
      fieldId: 'grossWeight',
      id: 'grossWeight',
    },
  ],
}
export default equipment_size
