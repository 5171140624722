import { combineReducers, configureStore } from '@reduxjs/toolkit'
import lookupReducer from './slices/lookup-slice'
import invoiceReducer from './slices/invoice-slice'
import routeReducer from './slices/route-slice'
import customerReducer from './slices/customer-slice'
import vesselReducer from './slices/vessel-slice'
import containerReducer from './slices/container-slice'
import vehiclesReducer from './slices/vehicles-slice'
import enigmaChatReducer from './slices/enigmaChat-slice'
import uiReducer from './slices/ui-slice'
import allocationReducers from './slices/allocation'

export const store = configureStore({
  reducer: {
    lookups: lookupReducer,
    invoice: invoiceReducer,
    route: routeReducer,
    customer: customerReducer,
    vessel: vesselReducer,
    enigmaChat: enigmaChatReducer,
    container: containerReducer,
    vehicles: vehiclesReducer,
    ui: uiReducer,
    allocation: combineReducers(allocationReducers),
  },
})

export default store
