import React from 'react'
import { useDeleteChannelMutation } from '../../../../../helper/hooks/api/allocations'
import { Spinner } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrash } from '@fortawesome/free-solid-svg-icons'

export default function DeleteChannelBtnCell({ cell }) {
  const { mutate, variables, isPending } = useDeleteChannelMutation()

  const data = cell.row.original

  return (
    <button
      type="button"
      title="Delete"
      onClick={() => mutate(data.id)}
      className="unset-all p-1"
    >
      {isPending && variables === data.id ? (
        <Spinner style={{ color: 'var(--primary-color)' }} />
      ) : (
        <FontAwesomeIcon
          fontSize={16}
          style={{ color: 'red' }}
          icon={faTrash}
        />
      )}
    </button>
  )
}
