import { useQuery } from '@tanstack/react-query'
import fetchService from '../../../../components/utils/fetchService'
import formatDate from '../helpers/formatDate'
import dayjs from 'dayjs'
import config from '../../../../config'

const endDate = dayjs().format('YYYY-MM-DD')
const { baseURL, getDateParams } = config.api.drivers.runSheets

export const useRunSheetApi = (endpoint, startDate = 'sixMonthsBefore') => {
  const formattedStartDate = formatDate(startDate)

  const url = `${baseURL}${endpoint}${getDateParams(
    formattedStartDate,
    endDate
  )}`

  return useQuery({
    queryKey: ['transport', url, formattedStartDate],
    queryFn: async () =>
      await fetchService({
        url,
        method: 'post',
      }),
  })
}
