import React, { useEffect, useState } from 'react'
import Button from 'react-bootstrap/Button'
import Form from 'react-bootstrap/Form'
import Modal from 'react-bootstrap/Modal'
import { useDispatch, useSelector } from 'react-redux'
import {
  fetchActiveChatUsers,
  getAllChatUsers,
  getIsCreateConversationModalOpenState,
  createPersonalConversation,
  setCreateConversationModalState,
} from '../../../../store/slices/enigmaChat-slice'

export default function CreateConversationModal() {
  const dispatch = useDispatch()
  const show = useSelector(getIsCreateConversationModalOpenState)
  const users = useSelector(getAllChatUsers)
  const [selectedUser, setSelectedUser] = useState('')

  useEffect(() => {
    if (users.length === 0) dispatch(fetchActiveChatUsers())
  }, [dispatch, users.length])

  const onHide = () => {
    dispatch(setCreateConversationModalState(false))
  }

  const createConversation = () => {
    dispatch(createPersonalConversation(selectedUser))
  }

  return (
    <Modal
      show={show}
      onHide={onHide}
      size="lg"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title>
          Create Personal Conversations
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <h4>Please Select a user</h4>
        <Form.Select
          aria-label="Users list"
          defaultValue=""
          onChange={(e) => setSelectedUser(e.target.value)}
        >
          <option disabled value="">
            Please select
          </option>
          {users.map((user) => (
            <option key={user.userId} value={user.userId}>
              {user.fullName}
            </option>
          ))}
        </Form.Select>
      </Modal.Body>
      <Modal.Footer className="d-flex justify-content-between align-items-center">
        <h4>Create Conversation with selected user</h4>
        <Button
          onClick={createConversation}
          variant="primary"
          disabled={selectedUser === ''}
        >
          Create
        </Button>
      </Modal.Footer>
    </Modal>
  )
}
