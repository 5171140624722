import IndeterminateCheckbox from '../../Common/Tanstack-table/components/IndeterminateCheckbox'
import LoadNote from './LoadNote'
import Driver from './Driver'

export const tableColumnsAttributes = (
  columns,
  setIsSearchData,
  driversDetails,
  setDriversDetails,
  selectedDriver,
  setSelectedDriver
) => {
  return columns.toSpliced(3, 0, {
    header: 'Driver Name',
    type: 'text',
    accessorKey: 'driverName',
    id: 'driverName',
    cell: ({ row, table }) => (
      <div>
        <Driver
          table={table}
          row={row}
          setIsSearchData={setIsSearchData}
          driversDetails={driversDetails}
          setDriversDetails={setDriversDetails}
          selectedDriver={selectedDriver}
          setSelectedDriver={setSelectedDriver}
        />
        {row.getIsExpanded() && row.original.jobNumber}
      </div>
    ),
  })
}

export const notesColumn = {
  header: 'Notes',
  type: 'text',
  accessorKey: 'latestNotes',
  id: 'latestNotes',
  cell: ({ row }) => <LoadNote load={row.original} />,
}

export const selectColumn = {
  id: 'select',
  header: ({ table }) => (
    <IndeterminateCheckbox
      {...{
        checked: table.getIsAllRowsSelected(),
        indeterminate: table.getIsSomeRowsSelected(),
        onChange: table.getToggleAllRowsSelectedHandler(),
      }}
    />
  ),
  cell: ({ row }) => (
    <div className="px-1">
      <IndeterminateCheckbox
        {...{
          checked: row.getIsSelected(),
          indeterminate: row.getIsSomeSelected(),
          onChange: row.getToggleSelectedHandler(),
        }}
      />
      {row.getIsExpanded() && row.original.jobNumber}
    </div>
  ),
  size: 30,
}

export const loadsTableColumns = [
  { ...selectColumn },
  {
    header: 'Load ID',
    accessorKey: 'loadID',
    id: 'loadID',
  },
  {
    ...notesColumn,
  },
  {
    header: 'Status',
    type: 'text',
    accessorKey: 'status',
    id: 'status',
  },
  {
    header: 'Cont Job Number',
    type: 'time',
    accessorKey: 'containerJobNumber',
    id: 'containerJobNumber',
  },
  {
    header: 'Transport Job Type',
    type: 'text',
    accessorKey: 'transportJobType',
    id: 'transportJobType',
  },
  {
    header: 'Container#',
    type: 'text',
    accessorKey: 'container#',
    id: 'container#',
  },
  {
    header: 'Client Delivery D&T',
    type: 'text',
    size: 200,
    accessorKey: 'clientDeliveryDateAndTime',
    id: 'clientDeliveryDateAndTime',
  },
  {
    header: 'Customer',
    type: 'text',
    accessorKey: 'customer',
    id: 'customer',
  },
  {
    header: 'Actual Delivery',
    type: 'date',
    accessorKey: 'actualDeliveryDateTime',
    id: 'actualDeliveryDateTime',
  },
  {
    header: 'Actual PickUp',
    type: 'date',
    accessorKey: 'actualPickUpDateTime',
    id: 'actualPickUpDateTime',
  },
  {
    header: 'Cargo Type',
    type: 'text',
    accessorKey: 'cargoType',
    id: 'cargoType',
    // Filter: SelectColumnFilter,
    // filter: 'includes',
  },
  {
    header: 'Collection Date',
    type: 'date',
    accessorKey: 'collectionDate',
    id: 'collectionDate',
  },
  {
    header: 'Collection Remarks',
    type: 'text',
    accessorKey: 'collectionRemarks',
    id: 'collectionRemarks',
  },
  {
    header: 'Collection Time',
    type: 'time',
    accessorKey: 'collectionTime',
    id: 'collectionTime',
  },
  {
    header: 'Consignee',
    type: 'text',
    accessorKey: 'consignee',
    id: 'consignee',
  },
  // {
  //   header: 'Cont Delivery Type',
  //   type: 'text',
  //   accessorKey: 'containerDeliveryType',
  //   id: 'containerDeliveryType',
  //   // Filter: SelectColumnFilter,
  //   // filter: 'includes',
  // },
  {
    header: 'Cont Ref',
    type: 'text',
    accessorKey: 'containerRef',
    id: 'containerRef',
  },
  {
    header: 'Cont Remarks',
    type: 'time',
    accessorKey: 'containerRemarks',
    id: 'containerRemarks',
  },
  {
    header: 'Cont Stage Tracker Id',
    type: 'text',
    accessorKey: 'containerStageTrackerId',
    id: 'containerStageTrackerId',
  },
  {
    header: 'Customer Ref',
    type: 'time',
    accessorKey: 'customerRef',
    id: 'customerRef',
  },
  {
    header: 'Deliver Location',
    type: 'text',
    accessorKey: 'deliveryLocation',
    id: 'deliveryLocation',
  },
  {
    header: 'Delivery Area/Zone',
    type: 'text',
    accessorKey: 'deliveryAreaOrZone',
    id: 'deliveryAreaOrZone',
  },
  {
    header: 'Delivery Suburb',
    type: 'time',
    accessorKey: 'deliverySuburb',
    id: 'deliverySuburb',
  },
  {
    header: 'Door Position',
    type: 'text',
    accessorKey: 'doorPosition',
    id: 'doorPosition',
  },
  {
    header: 'Distinct Conduct',
    type: 'text',
    accessorKey: 'distinctConduct',
    id: 'distinctConduct',
  },
  {
    header: 'Received Date',
    type: 'date',
    accessorKey: 'doReceivedDate',
    id: 'doReceivedDate',
    // Filter: DateRangeColumnFilter,
    // filter: "dateBetween"
  },
  {
    header: 'Driver ID',
    type: 'text',
    accessorKey: 'driverID',
    id: 'driverID',
    //
  },
  {
    header: 'Eido Pin',
    type: 'text',
    accessorKey: 'eidoPin',
    id: 'eidoPin',
  },
  {
    header: 'Empty Delivery',
    type: 'text',
    accessorKey: 'emptyDelivery',
    id: 'emptyDelivery',
  },
  {
    header: 'Empty Pickup',
    type: 'text',
    accessorKey: 'emptyPickup',
    id: 'emptyPickup',
  },
  {
    header: 'Est Start Date',
    type: 'date',
    accessorKey: 'estStartDate',
    id: 'estStartDate',
  },
  {
    header: 'Est Start Time',
    type: 'time',
    accessorKey: 'estStartTime',
    id: 'estStartTime',
  },
  {
    header: 'Eta Date',
    type: 'date',
    accessorKey: 'etaDate',
    id: 'etaDate',
  },
  {
    header: 'Etd Date',
    type: 'date',
    accessorKey: 'etdDate',
    id: 'etdDate',
  },
  {
    header: 'Exp AV Date',
    type: 'date',
    accessorKey: 'expAVDate',
    id: 'expAVDate',
  },
  {
    header: 'Exp Closing Time',
    type: 'time',
    accessorKey: 'expClosingTime',
    id: 'expClosingTime',
  },
  {
    header: 'First AV Date',
    type: 'date',
    accessorKey: 'firstAVDate',
    id: 'firstAVDate',
  },
  {
    header: 'Grade',
    type: 'text',
    accessorKey: 'grade',
    id: 'grade',
  },
  {
    header: 'Weight',
    type: 'text',
    accessorKey: 'weight',
    id: 'weight',
  },
  {
    header: 'Hold',
    type: 'text',
    accessorKey: 'hold',
    id: 'hold',
  },
  {
    header: 'Imo Code',
    type: 'text',
    accessorKey: 'imoCode',
    id: 'imoCode',
    // Filter: SelectColumnFilter,
    // filter: 'includes',
  },
  {
    header: 'Imp AV Date',
    type: 'date',
    accessorKey: 'impAVDate',
    id: 'impAVDate',
  },
  {
    header: 'Job Window',
    type: 'text',
    accessorKey: 'jobWindow',
    id: 'jobWindow',
  },
  {
    header: 'Mt Dehire Days',
    type: 'text',
    accessorKey: 'mtDehireDays',
    id: 'mtDehireDays',
  },
  {
    header: 'Mt Dehire Ref',
    type: 'text',
    accessorKey: 'mtDehireRef',
    id: 'mtDehireRef',
  },
  {
    header: 'Mt Dehire Slot',
    type: 'text',
    accessorKey: 'mtDehireSlot',
    id: 'mtDehireSlot',
  },
  {
    header: 'Mt Pickup/Dehire Date',
    type: 'date',
    accessorKey: 'mtPickupOrDehireDate',
    id: 'mtPickupOrDehireDate',
  },
  {
    header: 'Mt Pickup Slot',
    type: 'text',
    accessorKey: 'mtPickupSlot',
    id: 'mtPickupSlot',
  },
  {
    header: 'Obl No',
    type: 'text',
    accessorKey: 'oblNo',
    id: 'oblNo',
  },
  {
    header: 'Order Cont Details Id',
    type: 'text',
    accessorKey: 'orderContainerDetailsId',
    id: 'orderContainerDetailsId',
  },
  {
    header: 'Order No',
    type: 'text',
    accessorKey: 'orderNo',
    id: 'orderNo',
  },
  {
    header: 'PickUp Area/Zone',
    type: 'text',
    accessorKey: 'pickUpAreaOrZone',
    id: 'pickUpAreaOrZone',
    // Filter: SelectColumnFilter,
    // filter: 'includes',
  },
  {
    header: 'PickUp Location',
    type: 'text',
    accessorKey: 'pickUpLocation',
    id: 'pickUpLocation',
  },
  {
    header: 'PickUp Suburb',
    type: 'text',
    accessorKey: 'pickUpSuburb',
    id: 'pickUpSuburb',
  },
  {
    header: 'Planned By',
    type: 'text',
    accessorKey: 'plannedBy',
    id: 'plannedBy',
  },
  {
    header: 'Port Ref',
    type: 'text',
    accessorKey: 'portRef',
    id: 'portRef',
  },
  {
    header: 'Refer Temperature',
    type: 'text',
    accessorKey: 'reeferTemperature',
    id: 'reeferTemperature',
  },
  {
    header: 'Release NO',
    type: 'text',
    accessorKey: 'releaseNO',
    id: 'releaseNO',
  },
  {
    header: 'Scheduled Delivery DateTime',
    type: 'date',
    accessorKey: 'scheduledDeliveryDateTime',
    id: 'scheduledDeliveryDateTime',
  },
  {
    header: 'Scheduled PickUp DateTime',
    type: 'date',
    accessorKey: 'scheduledPickUpDateTime',
    id: 'scheduledPickUpDateTime',
  },
  {
    header: 'Seal No',
    type: 'text',
    accessorKey: 'sealNo',
    id: 'sealNo',
  },
  {
    header: 'Shipper',
    type: 'text',
    accessorKey: 'shipper',
    id: 'shipper',
  },
  {
    header: 'Shipping Agent',
    type: 'text',
    accessorKey: 'shippingAgent',
    id: 'shippingAgent',
  },
  {
    header: 'SR',
    type: 'text',
    accessorKey: 'sr',
    id: 'sr',
  },
  {
    header: 'Stage',
    type: 'text',
    accessorKey: 'stage',
    id: 'stage',
  },
  {
    header: 'Movement Type',
    type: 'text',
    accessorKey: 'movement',
    id: 'movement',
  },
  {
    header: 'Movement Req Date',
    type: 'date',
    accessorKey: 'movReqDate',
    id: 'movReqDate',
  },
  {
    header: 'Movement Req Time',
    type: 'time',
    accessorKey: 'movReqTime',
    id: 'movReqTime',
  },
  {
    header: 'Storage Start Date',
    type: 'text',
    accessorKey: 'storageStartDate',
    id: 'storageStartDate',
  },
  {
    header: 'Trailer ID',
    type: 'text',
    accessorKey: 'trailerID',
    id: 'trailerID',
  },
  {
    header: 'Trailer Type',
    type: 'text',
    accessorKey: 'trailerType',
    id: 'trailerType',
  },
  {
    header: 'Transport EndDate',
    type: 'date',
    accessorKey: 'transportEndDate',
    id: 'transportEndDate',
  },
  {
    header: 'Transport EndTime',
    type: 'time',
    accessorKey: 'transportEndTime',
    id: 'transportEndTime',
  },
  {
    header: 'Transport StartDate',
    type: 'date',
    accessorKey: 'transportStartDate',
    id: 'transportStartDate',
  },
  {
    header: 'Transport StartTime',
    type: 'time',
    accessorKey: 'transportStartTime',
    id: 'transportStartTime',
  },
  {
    header: 'Truck Fleet No',
    type: 'text',
    accessorKey: 'truckFleetNo',
    id: 'truckFleetNo',
  },
  {
    header: 'Truck ID',
    type: 'text',
    accessorKey: 'truckID',
    id: 'truckID',
  },
  {
    header: 'Truck Rego',
    type: 'text',
    accessorKey: 'truckRego',
    id: 'truckRego',
  },
  {
    header: 'Type Size',
    type: 'text',
    accessorKey: 'typeSize',
    id: 'typeSize',
  },
  {
    header: 'Dimension',
    type: 'text',
    accessorKey: 'dimension',
    id: 'dimension',
  },
  {
    header: 'UN Code',
    type: 'text',
    accessorKey: 'unCode',
    id: 'unCode',
  },
  {
    header: 'Vessel Name',
    type: 'text',
    accessorKey: 'vesselName',
    id: 'vesselName',
  },
  {
    header: 'Voyage No',
    type: 'text',
    accessorKey: 'voyageNo',
    id: 'voyageNo',
  },
  {
    header: 'Wait',
    type: 'text',
    accessorKey: 'wait',
    id: 'wait',
  },
  {
    header: 'Job Type',
    type: 'text',
    accessorKey: 'jobType',
    id: 'jobType',
  },
]
