import { yupResolver } from '@hookform/resolvers/yup'
import axios from 'axios'
import isEmpty from 'lodash/isEmpty'
import startCase from 'lodash/startCase'
import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useSelector } from 'react-redux'
import * as Yup from 'yup'
import config from '../../config'
import { getAccountingDetails } from '../../store/slices/customer-slice'
import ContainerWithHeading from '../styles/Containers/ContainerWithHeading'
import { handleFetchError } from '../utils/Utils'
import { Success } from '../utils/Toastify'
import InputNumber from '../Common/Form/Fields/InputNumber'

const rulesFormFields = [
  { key: 'ffd', label: 'Free Days in Yard after FFD Normal' },
  { key: 'mt', label: 'Free Days in Yard after MT' },
  { key: 'refeer', label: 'Free Days in Yard Refeer' },
]

const controllerCargoIdKey = {
  customer: 'ccContainerCargoRuleId',
  consignee: 'ccConsigneeCargoId',
  shipper: 'ccShipperCargoId',
}

const controllerApiEndpoints = (id = '') => ({
  customer: {
    GET: `accounting/container-cargo-rules?ccAccountingId=${id}`,
    POST: 'accounting/container-cargo-rules',
    PUT: `accounting/container-cargo-rules`,
  },
  consignee: {
    GET: `consignee-container?ccConsigneeAddressId=${id}`,
    POST: 'consignee-container',
    PUT: `consignee-container/${id}`,
  },
  shipper: {
    GET: `shipper-container?ccShipperAddressId=${id}`,
    POST: 'shipper-container',
    PUT: `shipper-container/${id}`,
  },
})

/**
 * Component to control Cargo Rules
 *
 * Props
 * consigneeId -> controllerId for 'consignee' or 'shipper' holds values of keys 'ccConsigneeAddressId' and 'ccShipperAddressId' respectively.
 *
 * controller = 'customer' | 'consignee' | 'shipper'
 *
 * type = 'view' | 'edit' | undefined
 */
const CargoRules = ({ consigneeId, type, controller }) => {
  const ccAccountingId = useSelector(getAccountingDetails)?.ccAccountingId

  const controllerId = controller === 'customer' ? ccAccountingId : consigneeId
  const [rulesExisted, setRulesExisted] = useState(false)
  const [formDefaultData, setFormDefaultData] = useState({})

  const [showError, setShowError] = useState(false)

  const validationSchema = () => {
    let validationObject = {}
    rulesFormFields.forEach((field) => {
      const { key } = field
      validationObject[`${key}Rate20`] = Yup.number()
        .required(`${key} Rate 20' is required`)
        .typeError('Value is required')
        .min(0, 'Enter a positive number')
      validationObject[`${key}Rate40`] = Yup.number()
        .required(`${key} Rate 40' is required`)
        .typeError('Value is required')
        .min(0, 'Enter a positive number')
    })
    return Yup.object().shape(validationObject)
  }

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm({
    resolver: yupResolver(validationSchema()),
    reValidateMode: 'onChange',
    defaultValues: formDefaultData,
  })

  /**
   * To fetch data for all controller
   */
  useEffect(() => {
    const url =
      config.api.baseUrl +
      '/customercard/' +
      controllerApiEndpoints(controllerId)[controller]['GET']
    if (controllerId) {
      // props.setEnableSpinner(true)
      const requestOptions = {
        method: 'GET',
        headers: { Authorization: 'Bearer ' + localStorage.token },
      }
      fetch(url, requestOptions)
        .then((response) => {
          if (response.status === 200) return response.json()
          throw new Error(response.status)
        })
        .then((data) => {
          setFormDefaultData({ ...data, igniter: new Date() })
          if (!isEmpty(data)) {
            for (const [key, value] of Object.entries(data)) {
              setValue(key, value)
            }

            if (data[controllerCargoIdKey[controller]]) setRulesExisted(true)
          }
        })
        .catch((err) => handleFetchError(err))
      // .finally(() => props.setEnableSpinner(false))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [controllerId])

  /**
   * POST and PUT requests for all controllers
   *
   * @param {Object} formData
   */
  const onSubmitFunction = (formData) => {
    const data = { ...formData }
    const controllerIdKeyName =
      controller === 'customer'
        ? 'ccAccountingId'
        : `cc${startCase(controller)}AddressId`
    const controllerCargoId = data?.[`cc${startCase(controller)}CargoId`]
    data[`${controllerIdKeyName}`] = controllerId
    // props.setEnableSpinner(true)

    const method = rulesExisted ? 'PUT' : 'POST'

    setShowError(false)
    axios({
      method: method,
      url: `${config.api.baseUrl}/customercard/${
        controllerApiEndpoints(controllerCargoId)[controller][method]
      }`,
      data,
      headers: {
        Authorization: 'Bearer ' + localStorage.token,
      },
    })
      .then(({ data }) => {
        if (rulesExisted) Success('Rules updated successfully')
        else Success('Rules created successfully')
        setRulesExisted(true)
        for (const [key, value] of Object.entries(data)) {
          setValue(key, value)
        }
      })
      .catch(function (error) {
        if (error?.response?.data?.data) {
          setShowError(error?.response?.data?.data)
        } else {
          setShowError(true)
        }
      })
    // .finally(() => props.setEnableSpinner(false))
  }

  return (
    <ContainerWithHeading
      heading="Rules"
      containerClassNames="p-2"
      Wrapper="div"
      wrapperProps={{
        className: 'mt-3',
      }}
    >
      <form onSubmit={handleSubmit(onSubmitFunction)}>
        <h4 className="mb-2">Container and Cargo Rules </h4>
        {rulesFormFields.map((field, i) => {
          const { label, key } = field
          return (
            <>
              <div className="row mb-3">
                <div className="col-md-4">
                  <label style={{ fontSize: '0.8rem' }}>{label}</label>
                </div>
                {[20, 40].map((rateNo) => (
                  <InputNumber
                    errors={errors}
                    register={register}
                    name={`${key}Rate${rateNo}`}
                    label={`${rateNo}'`}
                    disabled={type === 'view'}
                  />
                ))}
              </div>
              {i !== rulesFormFields.length - 1 && <hr className="my-2" />}
            </>
          )
        })}
        {type !== 'view' && (
          <div className="row">
            <div className="col-12 text-end mt-3">
              <button type="submit" className="btn btn-primary ms-2">
                {rulesExisted ? 'Update' : 'Save'}
              </button>
              {showError && (
                <div className="text-danger my-4">
                  <p>There is an issue in form submission. please try later.</p>
                  {showError?.map((eachError, i) => {
                    return (
                      <p key={i}>
                        {eachError.key} - {eachError.message}
                      </p>
                    )
                  })}
                </div>
              )}
            </div>
          </div>
        )}
      </form>
    </ContainerWithHeading>
  )
}

export default CargoRules
