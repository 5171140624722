import React from 'react'
import { Link } from 'react-router-dom'
import config from '../../config'

const DashboardItems = ({ index, each }) => {
  const { modules } = config
  const name = each.name?.toUpperCase()
  let item = modules[name]
  if (!item) return false

  if (each.name === 'Yard') {
    each.name = 'Container'
  }
  return (
    <li key={index} className="col-md-4">
      <Link
        to={item.path}
        style={{
          background: `linear-gradient(
            113deg,
            #53b9ea 1.19%,
            rgba(17, 50, 80, 0.42) 51.57%,
            #113250 97.92%
          )`,
        }}
      >
        <img src={item.dbicon} alt="" />
        <span>{each.name}</span>
      </Link>
    </li>
  )
}

export default function Dashboard() {
  document.body.setAttribute('data-class', 'dashboard-container')
  const { userDetails } = JSON.parse(localStorage.data)
  const modules = userDetails.module.subModule

  return (
    <div className="container mt-5 dashboard-page-wpr">
      <div className="menu-items-container">
        <img
          src="/assets/dashboard/faded-grid-bg.svg"
          alt="faded-grid"
          className="faded-grid-bg-img"
        />
        <ul className="row">
          {modules.map((each, index) => (
            <DashboardItems index={index} each={each} key={index} />
          ))}
        </ul>
      </div>
    </div>
  )
}
