const SellRateFormData = [
  {
    name: 'Include CRDs',
    label: 'Include CRDs',
    loadLookUp: 'rateNames',
    type: 'include-exclude-select',
    validation: 'Alphabetic',
    fieldId: 'includeCrdIds',
    id: 'includeCrdIds',
    validationType: 'Array',
    validations: [
      {
        type: 'required',
        params: ['##FIELD_NAME## is required'],
        nullable: true,
      },
    ],
  },
  {
    name: 'Rate Code',
    label: 'Rate Code',
    loadLookUp: 'rateCodes',
    type: 'select',
    validation: 'Alphabetic',
    fieldId: 'rateCode',
    id: 'rateCode',
    validationType: 'string',
    valueasId: true,
    validations: [
      {
        type: 'required',
        params: ['##FIELD_NAME## is required'],
        nullable: true,
      },
    ],
  },
  {
    name: 'Notes',
    label: 'Notes',
    type: 'textarea',
    validation: 'Alphabetic',
    fieldId: 'notes',
    id: 'notes',
    validationType: 'string',
    validations: [
      {
        type: 'required',
        params: ['##FIELD_NAME## is required'],
        nullable: true,
      },
    ],
  },
  {
    name: 'Sell Rate',
    label: 'Sell Rate',
    type: 'text',
    validation: 'Alphabetic',
    fieldId: 'price',
    id: 'price',
    validationType: 'number',
    validations: [
      {
        type: 'required',
        params: ['##FIELD_NAME## is required'],
        nullable: true,
      },
    ],
  },
]

export default SellRateFormData
