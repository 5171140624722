import React from 'react'

import Table from '../../../../components/Common/Tanstack-table/Table'
import UplDetailsModal from '../SubComponents/UplDetailsModal'
import { faPencil } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import IndeterminateCheckbox from '../../../../components/Common/Tanstack-table/components/IndeterminateCheckbox'

const Completed = ({ completeData, setUplDetails, uplDetails, unpackedTableColumns }) => {
  const [showUPLDetailsModal, setShowUPLDetailsModal] = React.useState(false)

  const showUplDetails = (details) => {
    setUplDetails(details)
    setShowUPLDetailsModal(true)
  }

  unpackedTableColumns.splice(0, 1, {
    id: 'select',
    header: ({ table }) => (
      <IndeterminateCheckbox
        {...{
          checked: table.getIsAllRowsSelected(),
          indeterminate: table.getIsSomeRowsSelected(),
          onChange: table.getToggleAllRowsSelectedHandler(),
        }}
      />
    ),
    cell: ({ row }) => (
      <div className="px-1">
        <div className="d-flex">
          <IndeterminateCheckbox
            {...{
              checked: row.getIsSelected(),
              indeterminate: row.getIsSomeSelected(),
              onChange: row.getToggleSelectedHandler(),
            }}
          />
          <button
            className="btn m-0 p-0 "
            onClick={() => {
              showUplDetails(row.original)
            }}
          >
            <FontAwesomeIcon icon={faPencil} className="ps-1" />
          </button>
        </div>
        {row.getIsExpanded() && row.original.jobNumber}
      </div>
    ),
  })

  return (
    <div>
      <div className="columnData customiseTable ">
        <Table
          columns={[...unpackedTableColumns]}
          data={completeData}
          getRowCanExpand={() => true}
        />
      </div>
      {showUPLDetailsModal && (
        <UplDetailsModal
          uplDetails={uplDetails}
          setShowUPLDetailsModal={setShowUPLDetailsModal}
        >
        </UplDetailsModal>
      )}
    </div>
  )
}

export default Completed
