import isEmpty from 'lodash/isEmpty'
import React from 'react'
import { Col, Row } from 'react-bootstrap'
import { useSelector } from 'react-redux'
import ContainerWithHeading from '../../../../../components/styles/Containers/ContainerWithHeading'
import { selectSelectedJob } from '../../../../../store/slices/allocation/drivers'
import LoadDetails from './LoadDetails'
import LoadsList from './LoadsList'
import VehicleDetails from './VehicleDetails'
import DriverDetails from './DriverDetails'
import Notes from './Notes'
import JobShiftIcon from './JobShiftIcon'

export default function JobsDetails() {
  const selectedJob = useSelector(selectSelectedJob)

  const loads = selectedJob?.containerLoads

  if (isEmpty(loads)) return null

  return (
    <ContainerWithHeading
      wrapperProps={{
        className: 'mt-3',
      }}
      Wrapper="div"
      HeaderComponent="div"
      headerClassName="d-flex justify-content-between w-100 align-items-center"
      heading={
        <>
          <h3>Job Details - {selectedJob.jobNumber}</h3>
          <div className="me-3">
            <JobShiftIcon />
          </div>
        </>
      }
    >
      <Row>
        <Col
          md={6}
          style={{
            borderRight: '1px solid gainsboro',
          }}
        >
          <LoadsList />
          <hr className="mt-3" />
          <LoadDetails />
        </Col>
        <Col md={6}>
          <VehicleDetails />
          <hr className="mt-3" />
          <DriverDetails />
          <hr className="mt-3" />
          <Notes />
        </Col>
      </Row>
    </ContainerWithHeading>
  )
}
