import { createColumnHelper } from '@tanstack/react-table'
import isEmpty from 'lodash/isEmpty'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import Table from '../../../components/Common/Tanstack-table/Table'
import IndeterminateCheckbox from '../../../components/Common/Tanstack-table/components/IndeterminateCheckbox'
import { fetchOrderFinancials } from '../../../store/actions/invoice-actions'
import { getOrderFinancials } from '../../../store/slices/invoice-slice'
import { updateOfcStatus } from './utils'

export default function OrderFinancials() {
  const dispatch = useDispatch()
  const history = useHistory()
  const columnHelper = createColumnHelper()
  const ordersFinancials = useSelector(getOrderFinancials)
  const [selectedOfcs, setSelectedOfcs] = useState([])

  useEffect(() => {
    if (isEmpty(ordersFinancials)) dispatch(fetchOrderFinancials())
  }, [dispatch, ordersFinancials])

  const invoiceOrderFinancials = () => {
    if (
      updateOfcStatus(
        selectedOfcs.map((ofc) => ofc.ofcId),
        'INVOICE_G'
      )
    )
      dispatch(fetchOrderFinancials())
  }

  const archiveOrderFinancials = () => {
    if (
      updateOfcStatus(
        selectedOfcs.map((ofc) => ofc.ofcId),
        'ARCHIVED'
      )
    )
      dispatch(fetchOrderFinancials())
  }

  const ordersFinancialsTableColumn = [
    {
      id: 'select',
      header: ({ table }) => (
        <div className="d-flex justify-content-center">
          <IndeterminateCheckbox
            {...{
              checked: table.getIsAllRowsSelected(),
              indeterminate: table.getIsSomeRowsSelected(),
              onChange: table.getToggleAllRowsSelectedHandler(),
            }}
          />
        </div>
      ),
      cell: ({ row }) => (
        <div className="d-flex justify-content-center">
          <IndeterminateCheckbox
            {...{
              checked: row.getIsSelected(),
              indeterminate: row.getIsSomeSelected(),
              onChange: row.getToggleSelectedHandler(),
            }}
          />
        </div>
      ),
    },
    columnHelper.accessor('customerName', {
      id: 'customerName',
      header: () => <span>Customer Name</span>,
      filterFn: 'fuzzy',
    }),
    columnHelper.accessor('referenceNo', {
      id: 'referenceNo',
      header: () => <span>Reference No</span>,
    }),
    columnHelper.accessor('orderNo', {
      id: 'orderNo',
      header: () => <span>Order No</span>,
    }),
    columnHelper.accessor('ofcId', {
      id: 'ofcId',
      header: () => <span>Order financials Card</span>,
      cell: (col) => {
        const { ofcId, ofcName } = col.row.original
        return (
          <span>
            {ofcId} - {ofcName}
          </span>
        )
      },
    }),
    columnHelper.accessor('createdDate', {
      id: 'createdDate',
      header: () => <span>Created at</span>,
    }),
    columnHelper.accessor('status', {
      id: 'status',
      header: () => <span>Status</span>,
      filterFn: 'fuzzy',
    }),
  ]

  return (
    <div className="invoice-OFC-wrapper">
      <header>
        <h1>Order Financials Card (OFC)</h1>
      </header>
      <main>
        <div className="row">
          <div className="col-md-11">
            <Table
              columns={ordersFinancialsTableColumn}
              data={ordersFinancials}
              inheritTableWidth
              setSelectedRows={setSelectedOfcs}
            />
          </div>
          <div className="col-md-1">
            <table className="table" style={{ marginTop: '3.7rem' }}>
              <thead />
              <tbody>
                <tr>
                  <td>
                    <button
                      type="button"
                      className="btn btn-primary w-100"
                      disabled={selectedOfcs.length !== 1}
                      onClick={() =>
                        history.push(
                          `/invoice/view/OFC/${selectedOfcs[0].ofcId}`
                        )
                      }
                    >
                      View
                    </button>
                  </td>
                </tr>
                <tr>
                  <td>
                    <button
                      type="button"
                      className="btn btn-primary w-100"
                      disabled={selectedOfcs.length !== 1}
                      onClick={() =>
                        history.push(
                          `/invoice/edit/OFC/${selectedOfcs[0].ofcId}`
                        )
                      }
                    >
                      Edit
                    </button>
                  </td>
                </tr>
                <tr>
                  <td>
                    <button
                      type="button"
                      className="btn btn-sm btn-primary w-100"
                      disabled={
                        selectedOfcs.length < 1 ||
                        selectedOfcs.some((ofc) => ofc.status === 'PENDING')
                      }
                      onClick={invoiceOrderFinancials}
                    >
                      Generate Invoice
                    </button>
                  </td>
                </tr>
                <tr>
                  <td>
                    <button
                      type="button"
                      className="btn btn-primary w-100"
                      disabled={selectedOfcs.length < 1}
                      onClick={archiveOrderFinancials}
                    >
                      Archive
                    </button>
                  </td>
                </tr>
                <tr>
                  <td>
                    <button
                      type="button"
                      className="btn btn-primary w-100"
                      disabled
                    >
                      Delete
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </main>
    </div>
  )
}
