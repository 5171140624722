import React from 'react'
const Reports = () => {
  return (
    <div className="w-100 overflow-auto">
      <div className="header">
        <h6>Reports</h6>
      </div>
    </div>
  )
}
export default Reports
