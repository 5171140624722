const truck_type = {
  name: 'Truck Type',
  apiUrlAppend: false,
  path: '/dataMgmt/customer/truck_type',
  req: '/',
  columns: 4,
  fields: [
    {
      name: 'ID',
      label: 'ID',
      type: 'text',
      validation: 'Alphabetic',
      fieldId: 'id',
      id: 'id',
      validationType: 'string',
      validations: [
        {
          type: 'required',
          params: ['ID is required'],
        },
        {
          type: 'max',
          params: [30, 'ID cannot be more than 30 characters'],
        },
      ],
    },
    {
      name: 'No Of Trailer',
      label: 'No Of Trailer',
      type: 'number',
      validation: 'Numeric',
      fieldId: 'noOfTrailer',
      id: 'noOfTrailer',
    },
    {
      name: 'Tare Weight',
      label: 'Tare Weight',
      type: 'number',
      validation: 'Numeric',
      fieldId: 'tareWeight',
      id: 'tareWeight',
    },
    {
      name: 'Allow Uncouple',
      label: 'Allow Uncouple',
      type: 'checkbox',
      validation: 'Alphabetic',
      fieldId: 'allowUncouple',
      id: 'allowUncouple',
      validationType: 'string',
    },
    {
      name: 'Gross Weight',
      label: 'Gross Weight',
      type: 'number',
      validation: 'Numeric',
      fieldId: 'grossWeight',
      id: 'grossWeight',
    },
    {
      name: 'Description',
      label: 'Description',
      type: 'textarea',
      validation: 'Alphabetic',
      fieldId: 'description',
      id: 'description',
      validationType: 'string',
      validations: [
        {
          type: 'required',
          params: ['Description is required'],
        },
        {
          type: 'max',
          params: [50, 'Description cannot be more than 50 characters'],
        },
      ],
    },
    {
      name: 'Max Volume',
      label: 'Max Volume',
      type: 'number',
      validation: 'Numeric',
      fieldId: 'maxVolume',
      id: 'maxVolume',
    },
  ],
}
export default truck_type
