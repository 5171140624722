import React, { useMemo } from 'react'
import useDrivers from '../../hooks/useDrivers'
import ResponsivePie from '../../../../../components/Common/Charts/ResponsivePie'
import { ArrayFilters } from '../../helpers/ArrayFilters'

export default function DriverGroupTypesPie({ setSelectedDrivers }) {
  const { data: driversData } = useDrivers()

  const driversClass = useMemo(
    () => new ArrayFilters(driversData?.data),
    [driversData?.data]
  )

  const driversEmployeeTypesData = useMemo(() => {
    return driversClass.groupDataByColumn('driverGroupName')
  }, [driversClass])

  if (!driversData) return []

  return (
    <div className="h-100">
      <div className="h-75 text-dark">
        <h3 style={{ fontSize: '1.25rem' }} className="text-light">
          Driver Group Types
        </h3>

        <ResponsivePie
          data={driversEmployeeTypesData}
          innerRadius={0.75}
          margin={{ top: 0, right: 100, bottom: 0, left: 100 }}
          cornerRadius={0}
          arcLinkLabelsTextColor="#fafafa"
          arcLinkLabelsOffset={-24}
          arcLinkLabel={({ id }) =>
            `${id}`.length > 10 ? `${`${id}`.slice(0, 10)}...` : `${id}`
          }
          colors={{ scheme: 'brown_blueGreen' }}
          onClick={({ id }) => {
            setSelectedDrivers({
              drivers: driversClass.filterByColumn('driverGroupName', id),
              header: `Driver Group - ${id}`,
            })
          }}
        />
      </div>
      <ul className="h-25" style={{ fontSize: '0.75rem' }}>
        {driversEmployeeTypesData.map((t) => (
          <li>
            {t.label} - {t.value}
          </li>
        ))}
      </ul>
    </div>
  )
}
