import React, { useState } from 'react'
import AgreedRatesTable from '../AgreedRates/AgreedRatesTable'
import config from '../../../config'

const RateReviewDate = (props) => {
  const [profileFormType, setProfileFormType] = useState('add')
  const [agreedRatesArray, setAgreedRatesArray] = useState({
    data: [],
    igniter: new Date(),
  })

  const getRateDetail = () => {
    let val = document.getElementById('selectDate').value
    if (val === '') {
      document.querySelector('.dateFieldError').style.display = 'block'
      document.getElementById('selectDate').focus()
      return false
    }

    const url =
      config.api.baseUrl +
      '/customercard/rate-detail/rate-review-profile?reviewDate=' +
      val
    const requestOptions = {
      method: 'POST',
      headers: { Authorization: 'Bearer ' + localStorage.token },
    }
    fetch(url, requestOptions)
      .then((response) => response.json())
      .then((data) => {
        setAgreedRatesArray({ data: data, igniter: new Date() })
      })
  }
  return (
    <div>
      <h4 className="mt-4">Rates detail</h4>

      <div>
        <div className="d-flex">
          <input
            type="date"
            className="form-control me-3"
            placeholder="Select date"
            id="selectDate"
          />
          <button className="btn btn-primary" onClick={getRateDetail}>
            Get Rate Detail
          </button>
        </div>
        <p className="dateFieldError text-danger" style={{ display: 'none' }}>
          please select date
        </p>
      </div>
      <div>
        {agreedRatesArray?.data && agreedRatesArray?.data?.length > 0 && (
          <>
            <AgreedRatesTable
              agreedRatesArray={agreedRatesArray}
              setAgreedRatesArray={setAgreedRatesArray}
              type={props.type}
              setProfileFormType={setProfileFormType}
              profileFormType={profileFormType}
            />
          </>
        )}
        {agreedRatesArray?.data && agreedRatesArray?.data?.length === 0 && (
          <div>No records are appearing to see for this on this rate</div>
        )}
      </div>
    </div>
  )
}

export default RateReviewDate
