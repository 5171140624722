import React, { useEffect, useState } from 'react'
import { yupResolver } from '@hookform/resolvers/yup'
import * as Yup from 'yup'
import { useForm } from 'react-hook-form'
import { format } from 'date-fns'
import { createYupSchema } from '../../Common/yupValidatorCreator'
import { GenerateFormField } from '../../Common/Form'

const AgreedRatesForm = (props) => {
  const {
    type,
    defaultData,
    formActionType,
    closeFormAction,
    formData,
    onSubmit,
  } = props

  const [loading, setLoading] = useState(false)
  const [formSuccess, setFormSuccess] = useState(false)
  const [formSubmitError, setFormSubmitError] = useState(false)

  const viewMode = type === 'view'

  let newValidations = formData.reduce(
    (allFields, fieldGroup) => ({
      ...allFields,
      ...fieldGroup.fields.reduce(createYupSchema, {}),
    }),
    {}
  )
  const validationSchema = Yup.object().shape(newValidations)

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    control,
    watch,
  } = useForm({
    mode: 'onChange',
    resolver: yupResolver(validationSchema),
    reValidateMode: 'onChange',
    defaultValues: defaultData,
  })

  useEffect(() => {
    setValue('quoteDate', format(new Date(), 'yyyy-MM-dd HH:mm:ss'))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const submitAction = (data) => {
    setLoading(true)
    setFormSuccess(false)
    setFormSubmitError(false)

    onSubmit(data)
      .then(function () {
        setFormSuccess(true)
        setLoading(false)
        closeFormAction()
      })
      .catch(function () {
        setFormSubmitError(true)
        setLoading(false)
      })
  }

  return (
    <div className="add-suburb-wpr">
      <div className="card2">
        <div className="card-body2">
          <form onSubmit={handleSubmit(submitAction)}>
            <div>
              <div className="form-group">
                {formData.map((formGroup, formGrpIdx) => {
                  const rowFields = formGroup.fields?.map((field) => (
                    <article key={field.fieldId} className="col-md-4">
                      <div className="row">
                        <div className="col-md-4">
                          <label>{field.name}</label>
                        </div>
                        <div
                          className={`
                            ${errors?.stageMovementType ? 'is-invalid' : ''}
                            col-md-8 mb-4
                          `}
                        >
                          <GenerateFormField
                            key={field.fieldId}
                            data={field}
                            control={control}
                            register={register}
                            err={errors}
                            formMode={formActionType}
                            watch={watch}
                            setValue={setValue}
                          />
                          {errors?.[field.fieldId] && (
                            <p className="invalid-feedback">
                              {errors?.[field.fieldId]?.message}
                            </p>
                          )}{' '}
                        </div>
                      </div>
                    </article>
                  ))
                  return (
                    <div
                      key={formGrpIdx}
                      className={formGrpIdx % 2 ? 'bg-gray' : 'odd-row'}
                    >
                      <h5>{formGroup.name}</h5>
                      <div className="row">{rowFields}</div>
                    </div>
                  )
                })}
              </div>

              {!viewMode && (
                <div className="row">
                  <div className="col-12 text-right">
                    <button
                      className="btn btn-primary"
                      disabled={loading}
                      type="submit"
                    >
                      {!loading &&
                        (formActionType !== 'import' ? 'Add' : 'Update')}
                    </button>
                    <button
                      className="btn btn-secondary ms-2"
                      disabled={loading}
                      type="reset"
                    >
                      Reset
                    </button>
                  </div>
                </div>
              )}
              {formSuccess && (
                <div className="text-success">
                  Form has submitted successfully
                </div>
              )}

              {formSubmitError && (
                <div className="text-danger">Unable to submit the form</div>
              )}
            </div>
          </form>
        </div>
      </div>
    </div>
  )
}

export default AgreedRatesForm
