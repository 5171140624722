import { useQuery } from '@tanstack/react-query'
import formatDate from '../helpers/formatDate'
import fetchService from '../../../../components/utils/fetchService'
import dayjs from 'dayjs'

export const useVehicleTypeDetails = (startDate = 'sixMonthsBefore') => {
  const endDate = dayjs().format('YYYY-MM-DD')
  const formattedStartDate = formatDate(startDate)

  const url = `/dashboard/driver/vehicle-type-details?start-date=${formattedStartDate}&end-date=${endDate}`

  return useQuery({
    queryKey: ['transport', url, startDate],
    queryFn: async () =>
      await fetchService({
        url,
        method: 'post',
      }),
  })
}
