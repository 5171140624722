import fetchService from '../../../../components/utils/fetchService'
import config from '../../../../config'

export const getConsigneesNames = async (id) =>
  await fetchService({
    url: `${config.api.consigneeNames}${id}`,
  })

export const getShipperNames = async (id) =>
  await fetchService({
    url: `${config.api.shipperNames}${id}`,
  })
