import fetchService from '../../components/utils/fetchService'
import { handleFetchError } from '../../components/utils/Utils'
import config from '../../config'
import { setContainerAlerts } from '../slices/container-slice'
import isEmpty from 'lodash/isEmpty'
import * as Toastify from '../../components/utils/Toastify'

export const fetchContainerAlerts = (_, loader) => {
  return async (dispatch) => {
    try {
      loader(true)
      const data = await fetchService({
        url: config.api.containerAlerts,
        method: 'POST',
        body: {},
      })
      if (isEmpty(data)) {
        Toastify.Error('No Data Found')
        return
      }
      dispatch(setContainerAlerts(data))
    } catch (err) {
      handleFetchError(
        err,
        'Some error occurred while fetching Accounting Details.'
      )
    } finally {
      loader(false)
    }
  }
}
