import { useState, useEffect, useRef } from 'react'
import './Accordion.scss'

const Accordion = ({ title, children }) => {
  const [isOpen, setIsOpen] = useState(false)
  const [contentHeight, setContentHeight] = useState(0)
  const hiddenBody = useRef(null)

  useEffect(() => {
    setContentHeight(isOpen ? hiddenBody.current.scrollHeight : 0)
  }, [isOpen])

  return (
    <div className="accordion">
      <div className="accordion-title d-flex align-items-center justify-content-between">
        <h4>{title}</h4>
        <img
          onClick={setIsOpen.bind(this, (prev) => !prev)}
          className={'more-content' + (isOpen ? ' active' : '')}
          src="./assets/down.svg"
          alt="show more"
        />
      </div>
      <div
        ref={hiddenBody}
        style={{ height: `${contentHeight}px` }}
        className={`accordion-content border`}
      >
        {children}
      </div>
    </div>
  )
}

export default Accordion
