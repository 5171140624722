import React, { useMemo } from 'react'
import {
  useTable,
  useFilters,
  useGlobalFilter,
  usePagination,
} from 'react-table'

import { ColumnFilter } from './ColumnFilter'

export function TableBuilder({
  tableHeaderData,
  tableBodyData,
  selectedRow = '',
}) {
  const columns = useMemo(() => tableHeaderData, [tableHeaderData])
  const data = useMemo(() => tableBodyData, [tableBodyData])
  const defaultColumn = React.useMemo(
    () => ({
      Filter: ColumnFilter,
    }),
    []
  )

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable(
      {
        columns,
        data,
        defaultColumn,
      },
      useFilters,
      useGlobalFilter,
      usePagination
    )

  const firstPageRows = rows.slice(0, 100)

  const getSortIcons = (col) => {
    if (col.isSorted) return col.isSortedDesc ? ' 🔽' : ' 🔼'
    return ''
  }

  return (
    <div className="table-responsive">
      <table className="table data-table" {...getTableProps()} width="100%">
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th {...column.getHeaderProps()} className={column.className}>
                  {column.render('Header')}
                  <span>{getSortIcons(column)}</span>
                  <div>{column.canFilter ? column.render('Filter') : null}</div>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {firstPageRows.map((row, i) => {
            prepareRow(row)
            return (
              <tr
                {...row.getRowProps()}
                className={i === selectedRow ? 'active' : ''}
              >
                {row.cells.map((cell) => {
                  return (
                    <td
                      {...cell.getCellProps({
                        className: cell.column.className,
                      })}
                    >
                      {cell.render('Cell')}
                    </td>
                  )
                })}
              </tr>
            )
          })}
        </tbody>
      </table>
    </div>
  )
}
