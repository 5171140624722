import { createSlice } from '@reduxjs/toolkit'
import {
  VEHICLE_TYPES,
  VEHICLE_TYPES_UNIQUE_IDS,
} from '../../Pages/transport/vehicles/data/constants'
import { getVehicleList } from './vehicles-slice'

const initialState = {
  lookupData: {
    recordIDs: [],
    roles: [],
    stateCodes: [],
    currencyCodes: [],
    zoneCodeCategoryEnums: [],
    wharfJobTypeEnums: [],
    customerTransactionEnums: [],
    vehicleTypeEnums: [],
    rateTypeEnums: [],
    containerCargoNumberEnums: [],
    vehicleStatusEnums: [],
    driverStatusEnums: [],
    jobDriverStatusEnums: [],
    freightModeEnums: [],
    rateRuleEnums: [],
    driverShiftEnums: [],
    doorPositionEnums: [],
    countryCodes: [],
    zoneCodes: [],
    postCodes: [],
    areaCodes: [],
    wharfCodes: [],
    shippingAgents: [],
    wharfJobTypes: [],
    stageMovementTypes: [],
    vehicleJobTypes: [],
    rateCodes: [],
    jobWindows: [],
    customerTransactionTypes: [],
    messageTypes: [],
    rateGroups: [],
    rationalCodes: [],
    portCodes: [],
    holdCodes: [],
    containerCategories: [],
    cargoTypes: [],
    containerStatus: [],
    distinctConducts: [],
    vendorCodes: [],
    railHeads: [],
    imoCodes: [],
    cargoHandlings: [],
    documentTypes: [],
    dimensionUnits: [],
    packageTypes: [],
    stockRooms: [],
    vesselDetails: [],
    equipmentSizes: [],
    equipmentTypes: [],
    truckTypes: [],
    trailerTypes: [],
    driverTypes: [],
    yards: [],
    serviceYards: [],
    wareHouses: [],
    creditTermsTypes: [],
    shipmentTypes: [],
    stageTypes: [],
    loadStatusEnums: [],
    functions: [],
    mtdehires: [],
    containerDeliveryTypes: [],
  },
  isLookupDataEmpty: true,
  customerNames: [],
}

const lookUpSlice = createSlice({
  name: 'lookups',
  initialState,
  reducers: {
    setLookupData(state, action) {
      state.lookupData = action.payload
      state.isLookupDataEmpty = false
    },
    setCustomerNames(state, action) {
      state.customerNames = action.payload
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getVehicleList.fulfilled, (state, action) => {
      const { data, vehicle } = action.payload
      if (vehicle === VEHICLE_TYPES.TRAILER)
        state.lookupData.defaultTrailerIds = data.map((fl) => ({
          id: fl[VEHICLE_TYPES_UNIQUE_IDS[VEHICLE_TYPES.TRAILER]],
          description: fl.licensePlatNo,
        }))
    })
  },
})

export const { setLookupData, setCustomerNames } = lookUpSlice.actions
export default lookUpSlice.reducer

export const getLookupDataState = (state) => state.lookups.lookupData
export const getIsLookupDataEmpty = (state) => state.lookups.isLookupDataEmpty
export const getCustomerNamesState = (state) => state.lookups.customerNames
