import React from 'react'
import { Link } from 'react-router-dom'
import {
  customerDataMgmt,
  operationsDataMgmt,
  transportDataMgmt,
  userDataMgmt,
} from '../../../../routes'

const DataManagementDashboard = () => {
  document.body.setAttribute('data-class', 'data-mgmt')
  return (
    <div className="container-fluid mb-4">
      <div className="row">
        <div className="col-md-4 cmd-class">
          <div className="customer-dm">
            <div className="heading-cmd">
              <h3>User Data Management</h3>
            </div>
            <ul>
              <ol>
                {userDataMgmt.map((eachItem, index) => (
                  <li key={index}>
                    <Link to={eachItem.path}>
                      {eachItem.staticProps.formName}
                    </Link>
                  </li>
                ))}
              </ol>
            </ul>
          </div>
        </div>

        <div className="col-md-4">
          <div className="customer-dm">
            <div className="heading-cmd">
              <h3>Operations Data</h3>
            </div>

            <ul>
              <ol>
                {operationsDataMgmt.map((eachItem, index) => (
                  <li key={index}>
                    <Link to={eachItem.path}>
                      {eachItem.staticProps.formName}
                    </Link>
                  </li>
                ))}
              </ol>
            </ul>
          </div>
        </div>

        <div className="col-md-4">
          <div className="customer-dm">
            <div className="heading-cmd">
              <h3>Customer Data Management</h3>
            </div>

            <ul>
              <ol>
                {customerDataMgmt.map((eachItem, index) => (
                  <li key={index}>
                    <Link to={eachItem.path}>
                      {eachItem.staticProps.formName}
                    </Link>
                  </li>
                ))}
              </ol>
            </ul>
          </div>
        </div>

        <div className="col-md-4"></div>

        <div className="col-md-4">
          <div className="customer-dm">
            <div className="heading-cmd">
              <h3>Transport Data</h3>
            </div>

            <ul>
              <ol>
                {transportDataMgmt.map((eachItem, index) => (
                  <li key={index}>
                    <Link to={eachItem.path}>
                      {eachItem.staticProps.formName}
                    </Link>
                  </li>
                ))}
              </ol>
            </ul>
          </div>
        </div>
      </div>
    </div>
  )
}

export default DataManagementDashboard
