import React from 'react'
import ConfirmModal from '../../../../components/Common/Modal/NewConfirmModal'
import { useAssignLoadsToDriverMutation } from '../../../../helper/hooks/apiHooks'

export default function AssignJobConfirmationModal({
  closeConfirmation,
  loadToDriverData,
  invalidateQuery,
}) {
  const mutation = useAssignLoadsToDriverMutation(invalidateQuery)

  const assignJobToDriver = async () => {
    try {
      await mutation.mutateAsync(loadToDriverData)
      closeConfirmation()
    } catch (error) {
      console.log('Error occurred while assigning job', error)
    }
  }

  return (
    <ConfirmModal
      show={loadToDriverData !== null}
      handleClose={closeConfirmation}
      message={`Are you sure you want to assign container [${loadToDriverData?.loads
        .map((l) => l.containerStageTrackerId)
        .join(',')}] to ${loadToDriverData?.driver.driverName}?`}
      handleConfirm={assignJobToDriver}
    />
  )
}
