import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  containerAlerts: [],
}

const containerSlice = createSlice({
  name: 'container',
  initialState,
  reducers: {
    setContainerAlerts(state, action) {
      state.containerAlerts = action.payload
    },
  },
})

export const { setContainerAlerts } = containerSlice.actions

export const getContainerAlerts = (state, _) => state.container.containerAlerts

export default containerSlice.reducer
