import { useEffect, useState } from 'react'

import CustomerAgreedRates from '../AgreedRates/CustomerAgreedRates/CustomerAgreedRates'
import ConsigneeAgreedRates from '../AgreedRates/ConsigneeAgreedRates/ConsigneeAgreedRates'
import ShipperAgreedRates from '../AgreedRates/ShipperAgreedRates/ShipperAgreedRates'
import CRD from '../CRD/CRD'
import RateDetailTable from './RateDetailTable'

import config from '../../../config'

import axios from 'axios'

const { rateDetailing, api } = config

const RateDetailing = (props) => {
  const { customerCardId } = props
  const [activetab, setActivetab] = useState(null)
  const [customerRates, setCustomerRates] = useState([])
  const [consigneeRates, setConsigneeRates] = useState([])
  const [shipperRates, setShipperRates] = useState([])
  const [rates, setRates] = useState([])

  useEffect(() => {
    const url = `${api.baseUrl}${rateDetailing.baseURL}${rateDetailing.allRates.baseURL}?${rateDetailing.allRates.queryParam}=${customerCardId}`
    const method = 'GET'
    const headers = {
      Authorization: 'Bearer ' + localStorage.getItem('token'),
    }
    axios({ url, method, headers })
      .then((res) => {
        setRates((prev) => [...prev, ...res.data.CUSTOMER_RATE_DETAIL])
        setCustomerRates((prev) => [...prev, ...res.data.CUSTOMER_AGREED_RATES])
        setConsigneeRates((prev) => [
          ...prev,
          ...res.data.CONSIGNEE_AGREED_RATES,
        ])
        setShipperRates((prev) => [...prev, ...res.data.SHIPPER_AGREED_RATES])
      })
      .catch((err) => {
        console.error(err)
      })
  }, [customerCardId])
  return (
    <div className="mb-5 rates-card">
      <nav className="mb-4">
        <div className="nav nav-tabs" id="nav-tab" role="tablist">
          <button
            className={'nav-link ' + (activetab === 'crd' && 'active')}
            id="nav-home-tab"
            data-bs-toggle="tab"
            type="button"
            onClick={() => setActivetab('crd')}
          >
            CRD
          </button>
          <button
            className={'nav-link ' + (activetab === 'customer' && 'active')}
            id="nav-profile-tab"
            data-bs-toggle="tab"
            type="button"
            onClick={() => setActivetab('customer')}
          >
            Customer Agreed Rates
          </button>
          <button
            className={'nav-link ' + (activetab === 'consignee' && 'active')}
            id="nav-contact-tab"
            data-bs-toggle="tab"
            onClick={() => setActivetab('consignee')}
            type="button"
            role="tab"
            aria-controls="nav-contact"
            aria-selected="false"
          >
            Consignee Agreed Rates
          </button>
          <button
            className={'nav-link ' + (activetab === 'shipper' && 'active')}
            id="nav-contact-tab"
            data-bs-toggle="tab"
            onClick={() => setActivetab('shipper')}
            type="button"
            role="tab"
            aria-controls="nav-contact"
            aria-selected="false"
          >
            Shipper Agreed Rates
          </button>
        </div>
      </nav>
      <div className="tab-content" id="nav-tabContent">
        <div role="tabpanel" aria-labelledby="nav-contact-tab">
          {activetab === 'crd' && <CRD {...props} parentRef="rates" />}
        </div>
        <div role="tabpanel" aria-labelledby="nav-contact-tab">
          {activetab === 'customer' && (
            <CustomerAgreedRates
              {...props}
              customerAgreedRates={customerRates}
              setCustomerAgreedRates={setCustomerRates}
            />
          )}
        </div>
        <div role="tabpanel" aria-labelledby="nav-home-tab">
          {activetab === 'consignee' && (
            <ConsigneeAgreedRates
              {...props}
              consigneeAgreedRates={consigneeRates}
              setConsigneeAgreedRates={setConsigneeRates}
            />
          )}
        </div>

        <div role="tabpanel" aria-labelledby="nav-profile-tab">
          {activetab === 'shipper' && (
            <ShipperAgreedRates
              {...props}
              shipperAgreedRates={shipperRates}
              setShipperAgreedRates={setShipperRates}
            />
          )}
        </div>
      </div>
      {rates?.length > 0 && !activetab && (
        <div className="rate-detailing-table-wrapper">
          <h3 className="rate-detailing-table-title">All Rates</h3>
          <RateDetailTable csvArray={rates} />
        </div>
      )}
    </div>
  )
}

export default RateDetailing
