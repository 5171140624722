const CopyCRDFormData = [
  {
    name: 'From CRD',
    label: 'From CRD',
    type: 'searchable-select',
    loadLookUp: 'rateNames',
    validation: 'Alphabetic',
    fieldId: 'fromCRD',
    id: 'fromCRD',
    validationType: 'string',
    validations: [
      {
        type: 'required',
        params: ['##FIELD_NAME## is required'],
        nullable: true,
      },
    ],
  },
  {
    name: 'To CRD',
    label: 'To CRD',
    type: 'include-exclude-select',
    loadLookUp: 'rateNames',
    validation: 'Alphabetic',
    fieldId: 'toCRD',
    id: 'toCRD',
    validationType: 'Array',
    validations: [
      {
        type: 'required',
        params: ['##FIELD_NAME## is required'],
        nullable: true,
      },
    ],
  },
]

export default CopyCRDFormData
