import React from 'react'
import axios from 'axios'
import { toast } from 'react-toastify'

import config from '../../../config'

import Spinner from '../../Spinner/Spinner'
import { TableBuilder } from '../../DataMgmt/Table'
import { useTableView } from '../../../helper/hooks/useTableView'
import { useRouter } from '../../../helper/hooks/useRouter'
import filterTypes from '../../Common/Table/FilterList'
import { Link } from 'react-router-dom'

export default function DriversList() {
  const { history } = useRouter()
  const url = `${config.api.baseUrl}/transports/driver/card/all`
  const headersToShow = {
    driverCardId: 'Driver Id',
    driverCardNo: 'Employee Id',
    driverName: 'Driver Name',
    surName: 'Driver Surname',
    gender: 'Gender',
    position: 'Position',
    department: 'Department',
    shift: 'Driver Shift',
  }

  const filterRow = {
    driverName: filterTypes.startsWith,
    driverSurname: filterTypes.startsWith,
    gender: filterTypes.startsWith,
    position: filterTypes.startsWith,
    department: filterTypes.startsWith,
  }

  const editRow = (id) => {
    history.push(history.location.pathname + '/edit/' + id)
  }

  const viewRow = (id) => {
    history.push(history.location.pathname + '/view/' + id)
  }

  const deleteRow = (id) => {
    const answer = window.confirm('Are you sure to delete?')
    if (!answer) return
    setLoading(true)
    axios({
      method: 'DELETE',
      url: `${config.api.baseUrl}/transports/driver/card/${id}`,
      headers: {
        Authorization: 'Bearer ' + localStorage.token,
      },
    })
      .then(() => {
        toast.success('Successfully deleted')
        fetchList()
      })
      .catch(() => {
        toast.error('Failed to delete')
      })
      .finally(() => {
        setLoading(false)
      })
  }

  const { loading, tableBodyData, tableHeaderData, setLoading, fetchList } =
    useTableView({
      url,
      headersToShow,
      editRow,
      viewRow,
      deleteRow,
      uniqueId: 'driverCardId',
      rowFilter: filterRow,
    })

  return (
    <div className="card mb-xl mx-5 mt-2 w-100">
      <div className="card-body">
        <div className="heading">
          <h2>Drivers</h2>
          {/* <Link
            className="btn btn-primary"
            style={{ color: '#fff' }}
            to="/transport/drivers/create"
          >
            Add New Driver
          </Link> */}
        </div>
        <div className="row">
          <div className="col-md-12">
            {tableBodyData.length > 0 && (
              <TableBuilder
                tableBodyData={tableBodyData}
                tableHeaderData={tableHeaderData}
                url={url}
                editTable={editRow}
                deleteRow={deleteRow}
              />
            )}
          </div>
        </div>
      </div>
      {loading && <Spinner />}
    </div>
  )
}
