import { createColumnHelper } from '@tanstack/react-table'
import isEmpty from 'lodash/isEmpty'
import React, { Fragment, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { toast } from 'react-toastify'
import Table from '../../components/Common/Tanstack-table/Table'
import IndeterminateCheckbox from '../../components/Common/Tanstack-table/components/IndeterminateCheckbox'
import { tooltip } from '../../components/Common/tooltip'
import DateRangFile from '../../components/Modals/DateRangeModal'
import fetchService from '../../components/utils/fetchService'
import { fetchInvoices } from '../../store/actions/invoice-actions'
import { getInvoicesList } from '../../store/slices/invoice-slice'
import InvoiceModal from './InvoicePdf/InvoiceModal'
import SentStatusTooltip from './components/SentStatusTooltip'
import useInvoiceDocsModal from './hooks/useInvoiceDocsModal'

const PlusSign = (props) => (
  <span {...props} className="plus-sign">
    +
  </span>
)

export default function Invoice() {
  const dispatch = useDispatch()
  const columnHelper = createColumnHelper()
  const invoicesList = useSelector(getInvoicesList)
  const [, setSelectedInvoices] = useState([])
  const [
    selectedCustomerInvoiceIdForDocsModal,
    setSelectedCustomerInvoiceIdForDocsModal,
  ] = useState(null)

  useEffect(() => {
    if (isEmpty(invoicesList)) dispatch(fetchInvoices())
  }, [dispatch, invoicesList])

  const [InvoiceDocumentsModal, setShowInvoiceDocumentsModal] =
    useInvoiceDocsModal(selectedCustomerInvoiceIdForDocsModal, () =>
      setSelectedCustomerInvoiceIdForDocsModal(null)
    )

  const invoicesTableColumn = [
    columnHelper.accessor('customerName', {
      id: 'customerName',
      header: () => <span>Billing Customer</span>,
      cell: (col) => {
        const { row } = col
        return (
          <div className="d-flex justify-content-start">
            <IndeterminateCheckbox
              {...{
                checked: row.getIsSelected(),
                indeterminate: row.getIsSomeSelected(),
                onChange: row.getToggleSelectedHandler(),
              }}
            />
            <span className="ms-2">{col.getValue()}</span>
          </div>
        )
      },
      filterFn: 'fuzzy',
    }),
    columnHelper.accessor('invoiceNo', {
      id: 'invoiceNo',
      header: () => <span>Invoice No</span>,
      cell: (col) => (
        <Fragment>
          <Link
            data-tip
            data-for={`invoice-no-${col.getValue()}`}
            className="cursor-pointer table-link"
            style={{ width: '100%' }}
            to={`/invoice/view/${col.getValue()}`}
          >
            {col.getValue()}
          </Link>
          {tooltip(
            <div
              className="cursor-pointer"
              onClick={() => {
                setSelectedCustomerInvoiceIdForDocsModal(
                  col.row.original.customerInvoiceId
                )
                setShowInvoiceDocumentsModal(true)
              }}
            >
              Documents
            </div>,
            `invoice-no-${col.getValue()}`,
            'bottom'
          )}
        </Fragment>
      ),
    }),
    columnHelper.accessor('invoiceDate', {
      id: 'invoiceDate',
      header: () => <span>Invoice Date</span>,
    }),
    columnHelper.accessor('sentStatus', {
      id: 'sentStatus',
      header: () => <span>Sent Status</span>,
      cell: (col) => <SentStatusTooltip col={col} />,
    }),
    columnHelper.accessor('invoiceDueDate', {
      id: 'invoiceDueDate',
      header: () => <span>Due Date</span>,
    }),
    columnHelper.accessor('total', {
      id: 'total',
      header: () => <span>Invoice Total</span>,
    }),
    columnHelper.accessor('balance', {
      id: 'balance',
      header: () => <span>Balance</span>,
      filterFn: 'fuzzy',
    }),
    columnHelper.accessor('MYOB', {
      id: 'MYOB',
      header: () => <span>MYOB Export</span>,
      filterFn: 'fuzzy',
    }),
  ]

  // Function to handle form submission
  const handleSubmit = async (startDate, endDate) => {
    try {
      const response = await fetchService({
        url: '/operations/order/invoice/myob/export/invoice',
        method: 'POST',
        body: {
          endDate: endDate,
          manual: false,
          startDate: startDate,
        },
        returnRaw: true,
      })
      const data = await response
      if (
        data ===
        'no auto generated customer invoices data exists between the dates you chosen.'
      ) {
        toast.error(
          'no auto generated customer invoices data exists between the dates you chosen.'
        )
        return
      }
      toast.success('Succesfully Exported MYOB')
    } catch (err) {
      toast.error('Something went wrong!')
    }
  }

  return (
    <Fragment>
      <div className="invoice-wrapper">
        <header>
          <h1>Invoices List</h1>
        </header>
        <main>
          <h3>Accounting Invoices</h3>
          <div className="actions-tab-container">
            <div className="actions-tab">
              <div>
                Invoices <PlusSign />
              </div>
              <div>
                Credit Note <PlusSign />
              </div>
              <div>
                Reconcile and Archive <PlusSign />
              </div>
              <div>
                Archive Loads <PlusSign />
              </div>
            </div>
            <div className="actions-tab">
              <DateRangFile
                header={'Export MYOB'}
                handleSubmit={handleSubmit}
              ></DateRangFile>
            </div>
          </div>
          <InvoiceModal />
          <Table
            columns={invoicesTableColumn}
            data={invoicesList}
            setSelectedRows={setSelectedInvoices}
            inheritTableWidth
          />
        </main>
      </div>
      {InvoiceDocumentsModal}
    </Fragment>
  )
}
