import React from 'react'

export default function NumberInputField({
  title = '',
  id,
  ratio,
  required = false,
  placeholder = '',
  register,
  registerOptions = {},
  errors,
  disabled,
  value = '',
  labelComponent,
  ...props
}) {
  const ratioOfFields = ratio ? ratio : [4, 8]

  const handleBlur = (event) => {
    event.target.value = Number(event.target.value)
  }

  return (
    <div className="row mb-3">
      {labelComponent ? (
        labelComponent
      ) : (
        <label
          htmlFor={id}
          className={`col-sm-${ratioOfFields[0]} col-form-label`}
        >
          {title}
          {required && <span style={{ color: 'red' }}>*</span>}
        </label>
      )}
      <div className={`col-sm-${ratioOfFields[1]} `}>
        <input
          id={id}
          placeholder={placeholder}
          name={id}
          className={`form-control ${errors?.[id] ? 'is-invalid' : ''}`}
          // type="number"
          {...register(id, {
            ...registerOptions,
            onBlur: handleBlur,
            valueAsNumber: true,
          })}
          onInput={(event) => {
            event.target.value = event.target.value
              .replace(/[^0-9.]/g, '')
              .replace(/(\..*)\./g, '$1')
          }}
          disabled={disabled}
          defaultValue={value}
          autoComplete="off"
          {...props}
        />
        {errors?.[id] && (
          <p className="invalid-feedback"> {errors[id].message}</p>
        )}
      </div>
    </div>
  )
}
