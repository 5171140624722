import capitalize from 'lodash/capitalize'
import React from 'react'
import { Modal } from 'react-bootstrap'
import CargoRules from '../../../../../components/Customer/CargoRules'
import config from '../../../../../config'
import CRD from '../../Rates/components/CRD/CRD'
import Ancillaries from './Ancillaries'
import OtherRules from './OtherRules'

const urlsMap = {
  consignee: {
    baseUrl: '/ccConsigneecard/consignee-agreed-rates',
    queryUrl: '/ccConsigneecard/consignee-agreed-rates?ccConsigneeAddressid=',
    namesUrl: (id) => `${config.api.consigneeNames}${id}`,
  },
  shipper: {
    baseUrl: '/customercard/shipper-agreed-rates',
    queryUrl: '/customercard/shipper-agreed-rates?ccShipperAddressid=',
    namesUrl: (id) => `${config.api.shipperNames}${id}`,
  },
}

export default function EntityOptionsModal({
  handleClose,
  entity,
  type,
  selectedDetail,
  customerCardId,
}) {
  const Entity = capitalize(entity)
  const entityIdKey = `cc${Entity}AddressId`
  const entityId = selectedDetail[entityIdKey]

  return (
    <Modal show onHide={handleClose} size="lg" centered>
      <Modal.Header closeButton>
        <Modal.Title>
          Add {Entity} data - ${entityId}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <h4 className="my-4">{Entity} Agreed Rates</h4>
        <CRD
          customerCardId={customerCardId}
          profileId={entityId}
          profileIdKey={entityIdKey}
          baseUrl={urlsMap[entity].baseUrl}
          queryUrl={`${urlsMap[entity].queryUrl}${entityId}`}
          uniqueProfileIdKey={`cc${Entity}AgreedRatesId`}
          type={type}
          optionsConfig={{
            url: urlsMap[entity].namesUrl(customerCardId),
            labelKey: 'code',
            valueKey: 'id',
          }}
        />
        <hr className="my-3" />
        <Ancillaries entity={entity} entityId={entityId} />
        <hr className="my-3" />
        <CargoRules type={type} controller={entity} consigneeId={entityId} />
        <hr className="my-3" />
        <OtherRules entity={entity} entityId={entityId} />
      </Modal.Body>
    </Modal>
  )
}
