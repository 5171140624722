import React from 'react'
import { Spinner } from 'react-bootstrap'

export default function CenterSpinner() {
  return (
    <div className="d-flex justify-content-center align-items-center h-100">
      <Spinner
        style={{ width: '36px', height: '36px' }}
        animation="border"
        variant="primary"
      />
    </div>
  )
}
