import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  status: '',
  selectedDriver: null,
  selectedJob: null,
  selectedContainerLoad: null,
}

export const driversSlice = createSlice({
  name: 'drivers',
  initialState,
  reducers: {
    setStatus: (state, action) => {
      state.selectedDriver = null
      state.selectedJob = null
      state.selectedContainerLoad = null
      state.status = action.payload
    },
    setSelectedDriver: (state, action) => {
      state.selectedJob = null
      state.selectedDriver = action.payload
    },
    setSelectedJob: (state, action) => {
      state.selectedContainerLoad = null
      const job = action.payload
      state.selectedJob = job
      if (job?.containerLoads) {
        const keys = Object.keys(job.containerLoads)
        if (keys.length) {
          state.selectedContainerLoad = job.containerLoads[keys[0]]
        }
      }
    },
    setSelectedContainerLoad: (state, action) => {
      state.selectedContainerLoad = action.payload
    },
  },
})

export const {
  setSelectedDriver,
  setSelectedJob,
  setSelectedContainerLoad,
  setStatus,
} = driversSlice.actions

export const selectSelectedDriver = (state) =>
  state.allocation.drivers.selectedDriver
export const selectSelectedJob = (state) => state.allocation.drivers.selectedJob
export const selectSelectedContainerLoad = (state) =>
  state.allocation.drivers.selectedContainerLoad
export const selectStatus = (state) => state.allocation.drivers.status

export default driversSlice.reducer
