const port_code = {
  name: 'Port Code',
  apiUrlRepelaceUnderscore: true,
  path: '/dataMgmt/customer/port_code',
  req: '/',
  columns: 4,
  fields: [
    {
      name: 'Port Code',
      label: 'Port Code',
      type: 'text',
      validation: 'Alphabetic',
      fieldId: 'portCode',
      id: 'portCode',
      validationType: 'string',
      validations: [
        {
          type: 'required',
          params: ['Port Code is required'],
        },
        {
          type: 'max',
          params: [30, 'Port Code cannot be more than 30 characters'],
        },
      ],
    },
    {},
    {
      name: 'Address',
      label: 'Address',
      type: 'textarea',
      validation: 'Alphabetic',
      fieldId: 'address',
      id: 'address',
      validationType: 'string',
      validations: [
        {
          type: 'required',
          params: ['Address is required'],
        },
        {
          type: 'max',
          params: [200, 'Address cannot be more than 200 characters'],
        },
      ],
    },
    {
      name: 'Area Code',
      label: 'Area Code',
      type: 'select',
      loadLookUp: 'areaCodes',
      validation: 'Alphabetic',
      fieldId: 'areaCode',
      id: 'areaCode',
      validationType: 'string',
      validations: [
        {
          type: 'required',
          params: ['Area Code is required'],
        },
      ],
      options: [],
    },
    {
      name: 'Suburb',
      label: 'Suburb',
      type: 'textarea',
      validation: 'Alphabetic',
      fieldId: 'suburb',
      id: 'suburb',
      validationType: 'string',
      validations: [
        {
          type: 'required',
          params: ['Suburb is required'],
        },
        {
          type: 'max',
          params: [100, 'Suburb cannot be more than 100 characters'],
        },
      ],
    },
    {
      name: 'Zone Code',
      label: 'Zone Code',
      type: 'select',
      loadLookUp: 'zoneCodes',
      validation: 'Alphabetic',
      fieldId: 'zoneCode',
      id: 'zoneCode',
      validationType: 'string',
      validations: [
        {
          type: 'required',
          params: ['Zone Code is required'],
        },
      ],
      options: [],
    },
    {
      name: 'Description',
      label: 'Description',
      type: 'textarea',
      validation: 'Alphabetic',
      fieldId: 'description',
      id: 'description',
      validationType: 'string',
      validations: [
        {
          type: 'required',
          params: ['Description is required'],
        },
        {
          type: 'max',
          params: [50, 'Description cannot be more than 100 characters'],
        },
      ],
    },
  ],
}
export default port_code
