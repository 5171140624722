import { yupResolver } from '@hookform/resolvers/yup'
import axios from 'axios'
import React from 'react'
import { Col, Row } from 'react-bootstrap'
import { useForm } from 'react-hook-form'
import { toast } from 'react-toastify'
import * as Yup from 'yup'
import Input from '../../../../components/Common/Form/Fields/Input'
import config from '../../../../config'

const { api } = config

const UnpackedPageForm = ({
  defaultValues,
  setLoading,
  getWms,
  wmsDetailsId,
  setShowUPLDetailsModal,
}) => {
  const validationSchema = Yup.object().shape({
    dateUnpacked: Yup.string().required('Date Unpacked is required'),
    freeDaysFrom: Yup.string().required('Free Days From is required'),
    freeDaysTo: Yup.string().required('Free Days To required'),
    storageDateFrom: Yup.string().required('Strorage Days From required'),
    storageDateTo: Yup.string().required('Strorage Days To required'),
  })
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: { ...defaultValues },
    reValidateMode: 'onChange',
  })
  const handleApproved = (formData) => {
    setLoading(true)
    const url = `${api.baseUrl}${api.wms.unpacked.updateToApproved}${wmsDetailsId}`
    axios({
      method: 'PUT',
      url: url,
      headers: {
        Authorization: 'Bearer ' + localStorage.token,
      },
      data: formData,
    })
      .then(function (response) {
        getWms('UNPACKED')
        reset()
        toast.info('Successully Updated')
      })
      .catch(function (error) {
        toast.error('Something went wrong!')
      })
      .finally(() => {
        setLoading(false)
        setShowUPLDetailsModal(false)
      })
  }
  return (
    <form
      autoComplete="off"
      className="p-3"
      onSubmit={handleSubmit(handleApproved)}
    >
      <div className="fs-5">
        Storage Days
        <Row>
          <Input
            register={register}
            errors={errors}
            name="dateUnpacked"
            type="date"
          />
          <Col sm={8} />
          <strong>Storage Days:</strong>
          <Input
            register={register}
            errors={errors}
            name="storageDateFrom"
            type="date"
            label="From"
          />
          <Input
            register={register}
            errors={errors}
            name="storageDateTo"
            type="date"
            label="To"
          />
          <Col sm={4} />
          <strong>Free Days:</strong>
          <Input
            register={register}
            errors={errors}
            name="freeDaysFrom"
            type="date"
            label="From"
          />
          <Input
            register={register}
            errors={errors}
            name="freeDaysTo"
            type="date"
            label="To"
          />
        </Row>
      </div>
      <div className="pt-2">
        <button type="submit" className="btn btn-primary">
          Approve
        </button>
      </div>
    </form>
  )
}

export default UnpackedPageForm
