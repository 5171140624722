import React from 'react'
import ContainerWithHeading from '../../../../components/styles/Containers/ContainerWithHeading'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBell } from '@fortawesome/free-solid-svg-icons'

export default function DriverAlerts() {
  return (
    <ContainerWithHeading heading="Driver Alert">
      <div
        className="d-flex justify-content-center align-items-center"
        style={{ height: '15.3rem' }}
      >
        <FontAwesomeIcon className="fa truckIcon" icon={faBell} />
      </div>
    </ContainerWithHeading>
  )
}
