import React, { useState } from 'react'
import { Tab, Tabs } from 'react-bootstrap'
import { Suspense } from 'react'
import ContainerWithHeading from '../../../../../components/styles/Containers/ContainerWithHeading'
import AddressBookManager from './AddressBookManager'

const AddressBook = ({ customerCardId, type }) => {
  const [activeRole, setActiveRole] = useState('consignee')

  return (
    <ContainerWithHeading
      heading="Address Book"
      containerClassNames="address-book-wpr p-2"
    >
      <Tabs
        id="customer-rate-setup-tabs"
        activeKey={activeRole}
        onSelect={(k) => setActiveRole(k)}
        className="mb-3"
      >
        <Tab eventKey="consignee" title="Consignee">
          <Suspense fallback={<div>Loading...</div>}>
            <AddressBookManager
              customerCardId={customerCardId}
              type={type}
              role="consignee"
            />
          </Suspense>
        </Tab>
        <Tab eventKey="shipper" title="Shipper">
          <Suspense fallback={<div>Loading...</div>}>
            <AddressBookManager
              customerCardId={customerCardId}
              type={type}
              role="shipper"
            />
          </Suspense>
        </Tab>
      </Tabs>
    </ContainerWithHeading>
  )
}

export default AddressBook
