import axios from 'axios'
import qs from 'qs'
import { useEffect, useState } from 'react'
import { Button, Modal } from 'react-bootstrap'
import { toast } from 'react-toastify'
import ContainerWithHeading from '../../../../components/styles/Containers/ContainerWithHeading'
import config from '../../../../config'
import CRCEdit from './components/CRCEditDialogue'
import RateCardInfo from './components/RateCardInfo/RateCardInfo'
import RateCards from './components/RateCards'
import CopyCRDForm from './data/CopyCRDFormData'
import PercentageForm from './data/PercentageChangeFormData'
import SellRateForm from './data/SellRateFormData'

const CustomerRateCard = () => {
  const {
    api: { baseUrl },
  } = config

  const [loadingRateNames, setLoadingRateNames] = useState(false)
  const [lookupData, setLookupData] = useState(null)
  const [copyCRDFormData, setCopyCRDFormData] = useState([...CopyCRDForm])
  const [percentageFormData, setPercentageFormData] = useState([
    ...PercentageForm,
  ])
  const [sellRateFormData, setSellRateFormData] = useState([...SellRateForm])
  const [form, setForm] = useState(null)
  const [submitFunc, setSubmitFunc] = useState(null)
  const [eventName, setEventName] = useState('')

  useEffect(() => {
    setLoadingRateNames(true)
    const fetchRateNames = async () => {
      try {
        const rateNameResponse = await axios({
          url: `${baseUrl}/customercard/rate-detail/dashboard/crd-rate-profile-names`,
          method: 'GET',
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        })

        const lookupResponse = await axios({
          url: `${baseUrl}/lookups`,
          method: 'GET',
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        })
        lookupResponse.data.rateNames = Object.entries(
          rateNameResponse.data
        ).map(([key, val]) => ({ id: key, value: val }))
        setLookupData(lookupResponse.data)
      } catch (error) {
        toast.error(error.response?.data?.data[0] ?? 'Something went wrong')
      } finally {
        setLoadingRateNames(false)
      }
    }
    fetchRateNames()
  }, [baseUrl])

  useEffect(() => {
    const getOptions = (form, formUpdate) => {
      if (!form) return
      const formFields = [...form]
      formFields.forEach((formField) => {
        if (formField.loadLookUp) {
          let id = 'id',
            label = 'value'
          if (formField.loadLookUp === 'rateCodes') {
            id = 'rateCode'
            label = 'description'
          }
          getLookUpOptions(lookupData, formField, id, label)
        }
      })
      formUpdate(formFields)
    }
    getOptions(copyCRDFormData, setCopyCRDFormData)
    getOptions(sellRateFormData, setSellRateFormData)
    getOptions(percentageFormData, setPercentageFormData)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lookupData])

  const getLookUpOptions = (data, field, id, label) => {
    let Options = []
    data?.[field.loadLookUp].forEach((val) => {
      let option = {}
      option.label = val[label]
      option.id = val[id]
      Options.push(option)
    })
    field.options = Options
  }

  const emitEvents = (event) => {
    if (event) {
      const { name, event: eventName } = event
      switch (eventName) {
        case 'copy-crd':
          setEventName(name)
          setForm(copyCRDFormData)
          setSubmitFunc(() => onCopyCRDSubmit)
          break
        case 'edit-sale':
          setEventName(name)
          setForm(sellRateFormData)
          setSubmitFunc(() => onSellRateAndPercentageSubmit)
          break
        case 'edit-per-chng':
          setEventName(name)
          setForm(percentageFormData)
          setSubmitFunc(() => onSellRateAndPercentageSubmit)
          break
        default:
          break
      }
    } else {
      setEventName('')
      setForm(null)
      setSubmitFunc(null)
    }
  }

  const onCopyCRDSubmit = (formData) => {
    const { toCRD: toCustomers, fromCRD: fromCustomer } = formData
    axios({
      url: `${baseUrl}/customercard/rate-detail/dashboard/copy/${fromCustomer}`,
      method: 'POST',
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
      params: { toCustomers },
      paramsSerializer: (params) => {
        return qs.stringify(params, { arrayFormat: 'repeat' })
      },
    })
      .then((response) => {
        toast.success('CRD data copied successfully')
        setForm(null)
      })
      .catch((error) => {
        toast.error(error.response.data.data[0].key)
      })
  }

  const onSellRateAndPercentageSubmit = (formData) => {
    axios({
      url: `${baseUrl}/customercard/rate-detail/dashboard/sell-rate/update`,
      method: 'POST',
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
      data: formData,
    })
      .then(() => {
        toast.success('CRD data copied successfully')
        setForm(null)
      })
      .catch((error) => {
        toast.error(error.response.data.data[0].key)
      })
  }

  const onSubmit = (values) => {
    const formData = {}
    form.forEach((field) => {
      formData[field.fieldId] = values[field.fieldId]
      if (field.validationType === 'number')
        formData[field.fieldId] = parseInt(formData[field.fieldId])
      if (field.fieldId === 'fromCRD')
        formData[field.fieldId] = formData[field.fieldId]
          ? parseInt(formData[field.fieldId])
          : null
      if (field.fieldId === 'toCRD' || field.fieldId === 'includeCrdIds')
        formData[field.fieldId] = formData[field.fieldId].map((crd) =>
          crd.id ? parseInt(crd.id) : null
        )
    })
    formData && submitFunc(formData)
  }

  return (
    <div className="container-fluid py-1">
      <ContainerWithHeading
        heading="Customer Rate Card List"
        containerClassNames="p-3"
      >
        <div className="row">
          <div className="col-md-9">
            <RateCards
              rateNameList={lookupData?.rateNames}
              loadingRateNames={loadingRateNames}
            />
          </div>
          <div className="col-md-3">
            <RateCardInfo $emitEvents={emitEvents} />
          </div>
        </div>
      </ContainerWithHeading>
      {form && (
        <Modal show={form !== null} onHide={emitEvents} centered>
          <Modal.Header closeButton>
            <Modal.Title>
              <h2>{eventName}</h2>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <CRCEdit formData={form} onFormSubmit={onSubmit}>
              <div className="col-12 my-4">
                <div className="module-footer">
                  <div>
                    <button type="submit" className="btn btn-gold ms-2 rounded">
                      Submit
                    </button>
                  </div>
                </div>
              </div>
            </CRCEdit>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={emitEvents}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      )}
    </div>
  )
}

export default CustomerRateCard
