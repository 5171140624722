import React from 'react'
import { Col, Row } from 'react-bootstrap'
import DetailComp from './DetailComp'
import { selectSelectedJob } from '../../../../../store/slices/allocation/drivers'
import { useSelector } from 'react-redux'

export default function VehicleDetails() {
  const selectedJob = useSelector(selectSelectedJob)

  if (!selectedJob) return null

  const { jobVehicleTruckDTO } = selectedJob

  if (!jobVehicleTruckDTO)
    return <p className="text-danger">jobVehicleTruckDTO details not found</p>

  return (
    <div className="">
      <div className="d-flex justify-content-between align-items-center">
        <h5>Vehicle Details</h5>
      </div>
      <Row>
        <DetailComp
          label="Transport Job Type"
          value={jobVehicleTruckDTO.transportJobType}
        />
        <DetailComp label="Truck Type" value={jobVehicleTruckDTO.truckType} />
        <DetailComp label="Truck Rego" value={jobVehicleTruckDTO.truckRego} />
        <DetailComp
          label="Vehicle Type"
          value={jobVehicleTruckDTO.vehicleType}
        />
        <Col md={12}>
          <i>Vehicle Trailers:</i>
          <ol>
            {Object.entries(jobVehicleTruckDTO?.vehicleTrailers)?.map(
              ([key, value]) => (
                <li key={key}>
                  {key} - {value}
                </li>
              )
            )}
          </ol>
        </Col>
      </Row>
    </div>
  )
}
