import { createSlice } from '@reduxjs/toolkit'
import { fetchCreditNotes } from '../actions/invoice-actions'

const initialState = {
  ordersFinancials: [],
  invoices: [],
  invoicesDetailsMap: {},
  creditNotes: [],
  loading: false,
}

const invoiceSlice = createSlice({
  name: 'invoice',
  initialState,
  reducers: {
    setOrderFinancials(state, action) {
      state.ordersFinancials = action.payload
    },
    setInvoicesList(state, action) {
      state.invoices = action.payload
    },
    setInvoicesDetailsMap(state, action) {
      const { key, value } = action.payload
      state.invoicesDetailsMap[key] = value
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchCreditNotes.fulfilled, (state, action) => {
        state.loading = false
        state.creditNotes = action.payload
      })
      .addCase(fetchCreditNotes.pending, (state, action) => {
        state.loading = true
      })
      .addCase(fetchCreditNotes.rejected, (state, action) => {
        state.loading = false
      })
  },
})

export const { setOrderFinancials, setInvoicesList, setInvoicesDetailsMap } =
  invoiceSlice.actions

export const getOrderFinancials = (state) => state.invoice.ordersFinancials
export const getInvoicesList = (state) => state.invoice.invoices
export const getInvoiceDetailsFromInvoiceNo = (state, invoiceNo) => {
  if (state.invoice.invoicesDetailsMap.hasOwnProperty(invoiceNo))
    return state.invoice.invoicesDetailsMap[invoiceNo]
  return null
}
export const selectCreditNotes = (state) => state.invoice.creditNotes

export default invoiceSlice.reducer
