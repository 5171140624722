import React from 'react'
import { ListGroup } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import {
  selectSelectedContainerLoad,
  selectSelectedJob,
  setSelectedContainerLoad,
} from '../../../../../store/slices/allocation/drivers'

export default function LoadsList() {
  const dispatch = useDispatch()
  const selectedJob = useSelector(selectSelectedJob)
  const selectedLoad = useSelector(selectSelectedContainerLoad)

  const loads = selectedJob?.containerLoads

  const handleLoadClick = (load) => {
    dispatch(setSelectedContainerLoad(load))
  }

  return (
    <div>
      <h5>Load List</h5>
      <ListGroup variant="flush" as="ol" numbered>
        {Object.entries(loads).map(([key, load]) => (
          <ListGroup.Item
            as="li"
            key={key}
            action
            onClick={() => handleLoadClick(load)}
            active={
              selectedLoad?.containerStageTrackerId ===
              load.containerStageTrackerId
            }
          >
            {key}
          </ListGroup.Item>
        ))}
      </ListGroup>
    </div>
  )
}
