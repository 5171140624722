import {
  keepPreviousData,
  useMutation,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query'
import dayjs from 'dayjs'
import queryString from 'query-string'
import { useLocation } from 'react-router-dom'
import { toast } from 'react-toastify'
import dateFunctions from '../../components/utils/DateFun'
import * as Toastify from '../../components/utils/Toastify'
import fetchService from '../../components/utils/fetchService'
import config from '../../config'
import { unplannedLoadsFiltersFormDefaultValues } from '../../modules/clm/loads-planning/data/allocation-planning.constants'

const baseKeys = {
  cerebro: {
    orderSlotManagement: 'order-slot-management',
    order: 'order',
    container: 'container',
  },
  allocationPlanner: {
    allocations: 'allocation-planner',
  },
}

export const queryKeys = {
  cerebro: {
    orderSlotManagement: {
      base: baseKeys.cerebro.orderSlotManagement,
    },
    order: {
      base: baseKeys.cerebro.order,
      container: {
        stageTracker: (containerId) => [
          baseKeys.cerebro.order,
          '/container/stage-tracker',
          containerId,
        ],
        stageOptions: (containerId) => [
          baseKeys.cerebro.order,
          'stage-options',
          containerId,
        ],
      },
    },
    container: {
      base: baseKeys.cerebro.container,
      stageTracker: [
        baseKeys.cerebro.container,
        '/container/stage-tracker',
        'all',
      ],
    },
  },
  allocationPlanner: {
    base: baseKeys.allocationPlanner.allocations,
    allocations: {
      containerStageTracker: (id) => [
        baseKeys.allocationPlanner.allocations,
        '/container/stage-tracker/',
        id,
      ],
      unplannedLoads: (pageNumber, pgSize, form) => [
        baseKeys.allocationPlanner.allocations,
        'unplanned-loads',
        { pageNumber, pgSize },
        { ...form },
      ],
      advanceSearchDropdowns: [
        baseKeys.allocationPlanner.allocations,
        '/clm/advanced-search/dropdown-values',
      ],
    },
  },
}

export const useUnplannedLoadsQuery = (
  pagination,
  formValues = unplannedLoadsFiltersFormDefaultValues
) => {
  if (formValues?.searchDate)
    formValues.searchDate = dayjs(formValues.searchDate).format('YYYY-MM-DD')
  const validValues = Object.entries(formValues).reduce((obj, [key, value]) => {
    if (value !== '') obj[key] = value

    return obj
  }, {})

  const url =
    formValues.loadFrom || formValues.loadTo
      ? '/clm/advanced-search/load'
      : `${config.api.plannedDashboard}unplanned-loads`

  return useQuery({
    queryKey: queryKeys.allocationPlanner.allocations.unplannedLoads(
      pagination.pageIndex,
      pagination.pageSize,
      validValues
    ),
    queryFn: async () =>
      await fetchService({
        url,
        method: 'POST',
        body: {
          pageNumber: pagination.pageIndex,
          maxResults: pagination.pageSize,
          ...validValues,
        },
      }),
    placeholderData: keepPreviousData,
  })
}

export const useAdvanceSearchDropDownOptions = () => {
  return useQuery({
    queryKey: queryKeys.allocationPlanner.allocations.advanceSearchDropdowns,
    queryFn: async () =>
      await fetchService({
        url: config.api.getDropdownValues,
      }),
  })
}

export const useActiveTemplate = () => {
  const location = useLocation()
  const query = queryString.parse(location.search)

  const currentActiveTemplate = query['template']

  return useQuery({
    queryKey: [`${config.api.userTemplateFunction}/${currentActiveTemplate}`],
    queryFn: async () =>
      await fetchService({
        url: `${config.api.userTemplateFunction}/${currentActiveTemplate}`,
      }),
    enabled: !!currentActiveTemplate,
  })
}

export const useDefaultTemplate = () => {
  const userData = JSON.parse(localStorage.data)

  return useQuery({
    queryKey: [
      `/usertemplate/function/template/default?userId=${userData?.userDetails?.id}`,
    ],
    queryFn: async () =>
      await fetchService({
        url: `/usertemplate/function/template/default?userId=${userData?.userDetails?.id}`,
      }),
    enabled: !!userData?.userDetails?.id,
  })
}

export function useEqPlanningData() {
  const url = '/clm/dashboard/eq-planning'
  return useQuery({
    queryKey: ['loads-planning', url],
    queryFn: async () => await fetchService({ url, method: 'POST', body: {} }),
  })
}

export function useDriversDetails(status = '') {
  const url = status
    ? `${config.api.driverWithStatus}${status}`
    : config.api.driverWithoutStatus
  return useQuery({
    queryKey: ['drivers-vehicles', status],
    queryFn: async () => await fetchService({ url }),
  })
}

export const useAssignLoadsToDriverMutation = (invalidateQuery) => {
  const queryClient = useQueryClient()
  const url = config.api.assignDriverVehicleToStage

  return useMutation({
    mutationFn: async (args) =>
      await fetchService({
        url,
        method: 'POST',
        body: {
          containerStageTrackerIds: args.loads.map(
            (r) => r.containerStageTrackerId
          ),
          driverCardId: args.driver.driverCardId,
        },
      }),
    onSuccess: async () => {
      toast.success('Successfully assigned!')
      await queryClient.invalidateQueries({
        queryKey: invalidateQuery,
      })
    },
    onError: (error) => {
      if (Array.isArray(error?.data)) {
        error.data.forEach(({ message }) => {
          toast.error(message)
        })
      }
    },
  })
}

export const useContainersStageDetails = (queryData) => {
  return useQuery({
    queryKey: queryData.key,
    queryFn: async () => await fetchService({ url: queryData.url }),
  })
}

export const useContainerStageOptions = (containerId) => {
  const url = `${config.api.containerStagesOptions}${containerId}`
  return useQuery({
    queryKey: queryKeys.cerebro.order.container.stageOptions(containerId),
    queryFn: async () => await fetchService({ url }),
    enabled: !!containerId,
  })
}

export const useUpdateContainerStageMutation = (queryKeyToInvalidate) => {
  const queryClient = useQueryClient()
  const mutation = useMutation({
    mutationFn: async (body) => {
      const url = `/container/stage-tracker/${body.containerStageTrackerId}`
      return await fetchService({
        url,
        body,
        method: 'PUT',
      })
    },
    onSuccess: () => {
      toast.success('Stage details updated successfully.')
    },
    onError: (error) => {
      console.log(error)
    },
  })

  const onSubmit = (values) => {
    /**
     * Process datetime-local and time fields
     */
    const body = { ...values }
    body.collectionTime = dateFunctions.getLongTime(body?.collectionTime)
    body.movReqDate = dateFunctions.getShortDate(body?.movReqDate)
    body.movReqTime = dateFunctions.getLongTime(body?.movReqTime)
    body.customerRequestDateTime = dateFunctions.getFullDate(
      body?.customerRequestDateTime
    )
    body.gateInDateTime = dateFunctions.getFullDate(body?.gateInDateTime)
    body.gateOutDateTime = dateFunctions.getFullDate(body?.gateOutDateTime)

    mutation.mutate(body, {
      onSuccess: () => {
        queryClient.invalidateQueries({
          queryKey: queryKeyToInvalidate,
        })
      },
      onError: (res) => {
        if (Array.isArray(res?.data)) {
          res.data.forEach((message) => {
            Toastify.Error(message?.message)
          })
        }
        console.log(res)
      },
    })
  }

  return { ...mutation, onSubmit }
}

export const useGetContainerStageDetailsById = (id) => {
  const url = `/container/stage-tracker/${id}`
  return useQuery({
    queryKey: queryKeys.allocationPlanner.allocations.containerStageTracker(id),
    queryFn: async () => await fetchService({ url }),
    enabled: !!id,
  })
}
