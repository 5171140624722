import React, { useMemo, useEffect } from 'react'
import { useDropzone } from 'react-dropzone'
import './DropZone.scss'

const baseStyle = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: '20px',
  borderWidth: 6,
  borderRadius: 6,
  borderColor: '#eeeeee',
  borderStyle: 'dashed',
  backgroundColor: '#fafafa',
  color: '#bdbdbd',
  transition: 'border .3s ease-in-out',
  height: '100%',
}

const activeStyle = {
  borderColor: '#2196f3',
}

const acceptStyle = {
  borderColor: '#00e676',
}

const rejectStyle = {
  borderColor: '#ff1744',
}

function DropZone({ onDrop, setDocsToUpload }) {
  const {
    acceptedFiles,
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject,
  } = useDropzone({
    onDrop,
  })

  useEffect(() => {
    setDocsToUpload && setDocsToUpload(acceptedFiles)
  }, [acceptedFiles, setDocsToUpload])

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isDragActive ? activeStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isDragActive, isDragReject, isDragAccept]
  )

  return (
    <div className="drop-zone-wrapper">
      <div {...getRootProps({ style })}>
        <input {...getInputProps()} />
        <div>Drag and drop your Files here.</div>
      </div>
    </div>
  )
}

export default DropZone
