import IndeterminateCheckbox from "../../components/Common/Tanstack-table/components/IndeterminateCheckbox";

export const Columns = [
    {
        id: 'select',
        header: ({ table }) => (
            <IndeterminateCheckbox
                {...{
                    checked: table.getIsAllRowsSelected(),
                    indeterminate: table.getIsSomeRowsSelected(),
                    onChange: table.getToggleAllRowsSelectedHandler(),
                }}
            />
        ),
        cell: ({ row }) => (
            <div className="px-1">
                <IndeterminateCheckbox
                    {...{
                        checked: row.getIsSelected(),
                        indeterminate: row.getIsSomeSelected(),
                        onChange: row.getToggleSelectedHandler(),
                    }}
                />
                {row.getIsExpanded() && row.original.jobNumber}
            </div>
        ),
    },
    {
        header: 'Wharf Job Type',
        type: 'text',
        accessorKey: 'wharfJobType',
        id: 'wharfJobType',
    },
    {
        header: 'Truck Number',
        type: 'text',
        accessorKey: 'truckNo',
        id: 'truckNo',
    },
    {
        header: 'trailerID',
        type: 'text',
        accessorKey: 'trailerID',
        id: 'trailerID',
    },
    {
        header: 'Order Number',
        type: 'text',
        accessorKey: 'orderNo',
        id: 'orderNo',
    },
    {
        header: 'containerNo',
        type: 'date',
        accessorKey: 'containerNo',
        id: 'containerNo',
    },
    {
        header: 'customerRefNo',
        type: 'text',
        accessorKey: 'customerRefNo',
        id: 'customerRefNo',
    },
    {
        header: 'stage',
        type: 'text',
        accessorKey: 'stage',
        id: 'stage',
    },

    {
        header: 'Stage Status',
        type: 'text',
        accessorKey: 'stageStatus',
        id: 'stageStatus',
    },
    {
        header: 'oblNo',
        type: 'text',
        accessorKey: 'oblNo',
        id: 'oblNo',
    },
    {
        header: 'releaseNo',
        type: 'text',
        accessorKey: 'releaseNo',
        id: 'releaseNo',
    },
    {
        header: 'size',
        type: 'text',
        accessorKey: 'size',
        id: 'size',
    },
    {
        header: 'ef',
        type: 'text',
        accessorKey: 'ef',
        id: 'ef',
    },
    {
        header: 'vesselName',
        type: 'text',
        accessorKey: 'vesselName',
        id: 'vesselName',
    },
    {
        header: 'voyageNo',
        type: 'text',
        accessorKey: 'voyageNo',
        id: 'voyageNo',
    },
    {
        header: 'driverName',
        type: 'text',
        accessorKey: 'driverName',
        id: 'driverName',
    },
    {
        header: 'licenseNO',
        type: 'text',
        accessorKey: 'licenseNO',
        id: 'licenseNO',
    },
    {
        header: 'shipperName',
        type: 'text',
        accessorKey: 'shipperName',
        id: 'shipperName',
    },
    {
        header: 'consigneeName',
        type: 'text',
        accessorKey: 'consigneeName',
        id: 'consigneeName',
    },
    {
        header: 'freightForward',
        type: 'text',
        accessorKey: 'freightForward',
        id: 'freightForward',
    },
    {
        header: 'imo',
        type: 'text',
        accessorKey: 'imo',
        id: 'imo',
    },
    {
        header: 'un',
        type: 'text',
        accessorKey: 'un',
        id: 'un',
    },
    {
        header: 'estInDate',
        type: 'date',
        accessorKey: 'estInDate',
        id: 'estInDate',
    },

]