import React, { useState, useEffect } from 'react'
import { Button, Modal, Table, Form } from 'react-bootstrap' // You may not need this line
import customerInvoiceOrdersTableCols from './tableColumns'
import config from '../../../config'
import { toast } from 'react-toastify'
import fetchService from '../../../components/utils/fetchService'

const InvoiceModal = () => {
  const [subtotal, setsubtotal] = useState(0)
  const [show, setShow] = useState(false)
  const [tableData, setTableData] = useState([])
  const [invoiceData, setInvoiceData] = useState({})
  const [newRow, setNewRow] = useState({})
  const [totalGST, setTotalGST] = useState(0)
  const [grandTotal, setGrandTotal] = useState(0)

  const handleClose = () => {
    setNewRow({})
    // setEditingIndex(null)
    setShow(false)
  }

  const handleShow = () => setShow(true)

  const calculateTotalGST = () => {
    let totalGST = 0
    for (const row of tableData) {
      totalGST += parseFloat(row.taxAmount || 0)
    }
    setTotalGST(totalGST)
  }
  const [showToast, setShowToast] = useState(true)
  function debounce(func, delay) {
    let timerId

    return function (...args) {
      const context = this

      clearTimeout(timerId)

      timerId = setTimeout(() => {
        func.apply(context, args)
      }, delay)
    }
  }

  const handleInputChange = (e, colId) => {
    const { value } = e.target

    if (colId === 'customerName') {
      const spclchars = /[!@#$%^&*(),.?":{}|<>]/.test(value)

      if (spclchars || value.length > 25) {
        if (showToast) {
          toast.error(
            'Name should not contain special characters and should not be longer than 25 characters.'
          )
          setShowToast(false)
          setTimeout(() => {
            setShowToast(true)
          }, 5000)
        }
        return
      }
    }

    if (colId === 'customerAddress') {
      if (value.length > 100) {
        if (showToast) {
          toast.error('Address should not be longer than 100 characters.')
          setShowToast(false)
          setTimeout(() => {
            setShowToast(true)
          }, 5000)
        }
        return
      }
    }

    if (
      [
        'customerName',
        'customerAddress',
        'invoiceDate',
        'invoiceDueDate',
      ].indexOf(colId) > -1
    ) {
      setInvoiceData((prevData) => ({
        ...prevData,
        [colId]: value,
      }))
      return
    }

    setNewRow((prevRow) => ({ ...prevRow, [colId]: value }))
  }

  const calculatesubtotal = () => {
    let calculatedsubtotal = 0
    for (const row of tableData) {
      calculatedsubtotal += parseFloat(row.amount || 0)
    }
    setsubtotal(calculatedsubtotal)
  }

  const handleSave = async () => {
    try {
      if (
        Object.values(invoiceData).some((value) => !value) ||
        tableData.length === 0
      ) {
        toast.error(
          'Please fill in all required fields and add at least one item.'
        )
        return
      }
      let apiUrl = `${config.api.invoice.invoiceDriver}`
      apiUrl += '?manualCustomerInvoice='
      const a = encodeURIComponent(
        JSON.stringify({
          customerAddress: invoiceData.customerAddress || '',
          customerName: invoiceData.customerName || '',
          invoiceDate: invoiceData.invoiceDate || '',
          invoiceDueDate: invoiceData.invoiceDueDate || '',
          manualCustomerInvoiceOrders: tableData.map((row) => ({
            amount: parseFloat(row.amount || 0),
            billingUnit: row.billingUnit || '',
            containerNo: row.containerNo || '',
            description: row.description || '',
            orderNo: row.orderNo || '',
            quantity: parseFloat(row.quantity || 0),
            containerLoadNo: row.containerLoadNo || '',
            rateCode: row.rateCode || '',
            remarks: row.remarks || '',
            size: row.size || '',
            taxAmount: parseFloat(row.taxAmount || 0),
            unitPrice: parseFloat(row.unitPrice || 0),
          })),
          subTotal: subtotal,
          totalGst: totalGST,
          total: totalGST + subtotal,
        })
      )
      apiUrl += a
      await fetchService({
        url: apiUrl,
        method: 'POST',
        onSuccessCallback: (data) => {
          setTableData([])
          setInvoiceData({})
          setNewRow({})
          handleClose()
        },
      })
    } catch (error) {
      console.error('Error saving data:', error)
      toast.error(error?.response?.data?.error ?? 'Something went wrong!')
    }
  }

  const renderTableHeaders = () => {
    return customerInvoiceOrdersTableCols.map((col) => (
      <th key={col.id} style={{ color: 'black' }}>
        {col.header()}
      </th>
    ))
  }

  const renderTableRows = () => {
    return tableData.map((row, rowIndex) => (
      <tr key={rowIndex}>
        {customerInvoiceOrdersTableCols.map((col) => (
          <td key={col.id}>
            <Form.Control
              type="text"
              placeholder={col.id}
              name={col.id}
              value={row[col.id] || ''}
              onChange={(e) => handleInputChange(e, col.id)}
            />
          </td>
        ))}
        <td>
          <Button variant="danger" onClick={() => handleDeleteRow(rowIndex)}>
            Delete
          </Button>
        </td>
      </tr>
    ))
  }

  const handleAddRow = () => {
    const isAnyFieldEmpty = Object.values(newRow).some(
      (value) => value === undefined || value === ''
    )

    if (isAnyFieldEmpty) {
      // Show error toast for empty fields in the new row
      toast.error('Please fill in all fields in the new row.')
      return
    }
    setTableData([...tableData, newRow])
    setNewRow({})
  }

  useEffect(() => {
    calculatesubtotal()
    calculateTotalGST()
    // eslint-disable-next-line
  }, [tableData])

  useEffect(() => {
    setGrandTotal(subtotal + totalGST)
  }, [subtotal, totalGST])

  const handleDeleteRow = (index) => {
    const newData = tableData.slice()
    newData.splice(index, 1)
    setTableData(newData)
  }

  return (
    <>
      <Button variant="primary" onClick={handleShow}>
        Create Manual Invoice
      </Button>

      <Modal
        show={show}
        dialogClassName="modal-90w"
        onHide={handleClose}
        size="lg"
      >
        <Modal.Header closeButton>
          <Modal.Title>Invoice</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="w-130 h-100">
            <div
              id="invoice-pdf-view-page"
              className="invoice-pdf-view-wrapper"
            >
              <main>
                <div className="d-flex justify-content-between">
                  <div style={{ color: 'black' }}>
                    <h3>Name: </h3>
                    <input
                      type="text"
                      className="mb-3 form-control"
                      placeholder="Enter your name"
                      value={newRow.customerName}
                      onChange={(e) => handleInputChange(e, 'customerName')}
                    />
                    <h3>Address: </h3>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Address"
                      value={newRow.customerAddress}
                      onChange={(e) => handleInputChange(e, 'customerAddress')}
                    />
                  </div>

                  <div>
                    <div className="invoice-meta-data">
                      <p>
                        Date:{' '}
                        <input
                          type="date"
                          className="form-control"
                          placeholder="Date"
                          value={newRow.invoiceDate}
                          onChange={(e) => handleInputChange(e, 'invoiceDate')}
                        />{' '}
                      </p>
                      <p>
                        Invoice Due Date:{' '}
                        <input
                          type="date"
                          className="form-control"
                          placeholder="Due Date"
                          value={newRow.invoiceDueDate}
                          onChange={(e) =>
                            handleInputChange(e, 'invoiceDueDate')
                          }
                        />
                      </p>
                    </div>
                  </div>
                </div>
                <Form>
                  <Table bordered striped responsive>
                    <thead>
                      <tr>{renderTableHeaders()}</tr>
                    </thead>
                    <tbody>
                      {renderTableRows()}
                      <tr>
                        {customerInvoiceOrdersTableCols.map((col) => (
                          <td key={col.id}>
                            <Form.Control
                              type="text"
                              placeholder={col.id}
                              name={col.id}
                              value={newRow[col.id] || ''}
                              onChange={(e) => handleInputChange(e, col.id)}
                            />
                          </td>
                        ))}
                      </tr>
                    </tbody>
                  </Table>
                  <Button variant="primary" onClick={handleAddRow}>
                    Add Row
                  </Button>
                </Form>
                <div className="invoice-bottom">
                  <div className="left">
                    <hr />
                    <h4>Note</h4>
                    <p>This is to notify to the client that</p>
                    <p>new rates are being added</p>
                  </div>
                  <div className="right">
                    <p>SUB TOTAL : {subtotal}</p>
                    <p>TOTAL GST : {totalGST}</p>
                    <strong>Total : {grandTotal}</strong>
                    <hr />
                  </div>
                </div>
              </main>
              <footer>Swift Transport Pty Ltd</footer>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="danger" onClick={handleClose}>
            Close
          </Button>
          <Button variant="secondary" onClick={handleSave}>
            Save
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}

export default InvoiceModal
