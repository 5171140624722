import React from 'react'
import StageDetailsModalForm from '../../../cerebro/containers/stage-tracker/StageDetailsModalForm'
import {
  queryKeys,
  useGetContainerStageDetailsById,
  useUpdateContainerStageMutation,
} from '../../../../helper/hooks/apiHooks'
import Spinner from '../../../../components/Spinner/Spinner'
import { toast } from 'react-toastify'

export default function LoadStageDetails({ handleClose, load }) {
  const { onSubmit } = useUpdateContainerStageMutation(
    queryKeys.allocationPlanner.allocations.containerStageTracker(
      load?.containerStageTrackerId
    )
  )

  const { data, isLoading, error } = useGetContainerStageDetailsById(
    load?.containerStageTrackerId
  )

  if (isLoading) return <Spinner />

  if (error || !data) {
    toast.error("Couldn't fetch stage details")
    return null
  }

  return (
    <StageDetailsModalForm
      onSubmit={onSubmit}
      handleClose={handleClose}
      selectedContainerStage={data}
    />
  )
}
