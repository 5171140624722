import React, { useState } from 'react'
import Tab from 'react-bootstrap/Tab'
import Tabs from 'react-bootstrap/Tabs'
import ContainerWithHeading from '../../../../components/styles/Containers/ContainerWithHeading'
import { Suspense } from 'react'
import { useParams } from 'react-router-dom'
import entityData from './EntityRates/entity.data'

const CustomerRates = React.lazy(() => import('./customerRates/CustomerRates'))
const EntityRates = React.lazy(() => import('./EntityRates/EntityRates'))

const CUSTOMER_RATES = 'Customer Rates'
const CONSIGNEE_RATES = 'Consignee Rates'
const SHIPPER_RATES = 'Shipper Rates'

export default function Rates() {
  const [activeTab, setActiveTab] = useState(CUSTOMER_RATES)
  const { customerCardId, type } = useParams()

  return (
    <div className="container-fluid">
      <ContainerWithHeading heading="Rates">
        <Tabs
          id="customer-rate-setup-tabs"
          activeKey={activeTab}
          onSelect={(k) => setActiveTab(k)}
          className="mb-3"
        >
          <Tab eventKey={CUSTOMER_RATES} title={CUSTOMER_RATES}>
            <Suspense fallback={<div>Loading...</div>}>
              <CustomerRates />
            </Suspense>
          </Tab>
          <Tab eventKey={CONSIGNEE_RATES} title={CONSIGNEE_RATES}>
            <Suspense fallback={<div>Loading...</div>}>
              <EntityRates
                customerCardId={customerCardId}
                type={type}
                entity={entityData.consignee}
              />
            </Suspense>
          </Tab>
          <Tab eventKey={SHIPPER_RATES} title={SHIPPER_RATES}>
            <Suspense fallback={<div>Loading...</div>}>
              <EntityRates
                customerCardId={customerCardId}
                type={type}
                entity={entityData.shipper}
              />
            </Suspense>
          </Tab>
        </Tabs>
      </ContainerWithHeading>
    </div>
  )
}
