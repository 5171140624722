import isNumber from 'lodash/isNumber'
import isString from 'lodash/isString'
import startCase from 'lodash/startCase'
import React, { useMemo } from 'react'
import { Col, Form } from 'react-bootstrap'
import { Controller } from 'react-hook-form'
import Select from 'react-select'
import RequiredSymbol from '../RequiredSymbol'

const isArrayWithPrimitiveValues = (options) =>
  Array.isArray(options) &&
  options.length &&
  (isString(options[0]) || isNumber(options[0]))

export const FormGroupSelectWrapper = ({ children, md, name, label }) => {
  return (
    <Form.Group className="mb-3" as={Col} md={md} controlId={name}>
      <Form.Label>{label}</Form.Label>
      {children}
    </Form.Group>
  )
}
export const ColSelectWrapper = ({ children, md, name, label }) => {
  return (
    <>
      <Col md={6}>
        <label>{label}</label>
      </Col>
      <Col md={6} className="mb-2">
        {children}
      </Col>
    </>
  )
}

export default function SearchableSelectField({
  options,
  control,
  md = 4,
  name,
  label = startCase(name),
  isMulti = false,
  dependentFieldIds = [],
  setValue,
  optionValueKey = name,
  optionLabelKey = 'description',
  required = false,
  disabled = false,
  SelectWrapper = FormGroupSelectWrapper,
  getError,
  type = 'edit',
  ...props
}) {
  const optionsArray = useMemo(() => {
    if (isArrayWithPrimitiveValues(options)) {
      return options.map((op) => ({
        [optionLabelKey]: op,
        [optionValueKey]: op,
      }))
    }
    return options
  }, [options, optionLabelKey, optionValueKey])

  const getOptionLabel = (option) => option?.[optionLabelKey]
  const getOptionValue = (option) => option?.[optionValueKey]

  return (
    <SelectWrapper
      name={name}
      md={md}
      label={
        <span>
          {label}
          {required && <RequiredSymbol />}
        </span>
      }
    >
      <Controller
        control={control}
        name={name}
        render={({ field: { onChange, value }, formState: { errors } }) => {
          const error = getError?.(errors) || errors?.[name]

          const getValue = () => {
            // Guard against undefined or null values for value and optionsArray
            if (!value || !optionsArray) {
              return isMulti ? [] : null
            }

            // For multi-selection, filter options that match the current value
            if (isMulti) return value

            // For single selection, find the option that matches the current value
            return (
              optionsArray.find((option) => option[optionValueKey] === value) ||
              null
            )
          }

          const handleChange = (val) => {
            dependentFieldIds.forEach((fieldId) => {
              setValue(fieldId, null)
            })

            const updatedValue = isMulti
              ? val || []
              : val?.[optionValueKey] || null

            onChange(updatedValue)
          }

          if (type === 'view') {
            return <h5 className="blue-title">{value}</h5>
          }

          return (
            <>
              <Select
                className="form-searchable-select"
                classNamePrefix="form-searchable-select"
                isClearable
                isSearchable
                isMulti={isMulti}
                value={getValue()}
                onChange={handleChange}
                options={optionsArray}
                getOptionLabel={getOptionLabel}
                getOptionValue={getOptionValue}
                isDisabled={disabled}
                {...props}
              />
              {error && (
                <Form.Text className="text-danger">{error?.message}</Form.Text>
              )}
            </>
          )
        }}
      />
    </SelectWrapper>
  )
}
