import React from 'react'
import MessageItem from './MessageItem'
import { getSelectedChat } from '../../../../../store/slices/enigmaChat-slice'
import { useSelector } from 'react-redux'

export default function Messages() {
  const conversation = useSelector(getSelectedChat)
  const { chatMessageResponseDTOS } = conversation

  return (
    <div className="messages-list-container">
      {chatMessageResponseDTOS.map((message) => (
        <MessageItem key={message.messageSid} message={message} />
      ))}
    </div>
  )
}
