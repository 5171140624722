import TablePage from '../components/Common/TablePage/TablePage'
import Invoice from '../Pages/Invoice'
import NotFoundPage from '../Pages/Invoice/components/NotFoundPage'
import EmailInvoice from '../Pages/Invoice/EmailInvoice/EmailInvoice'
import InvoiceDetails from '../Pages/Invoice/InvoiceDetails/InvoiceDetails'
import InvoicePdfView from '../Pages/Invoice/InvoicePdf/InvoicePdfView'
import EditOFCs from '../Pages/Invoice/OrderFinancials/EditOFCs'
import OrderFinancials from '../Pages/Invoice/OrderFinancials/OrderFinancials'

const routes = [
  {
    name: 'invoice',
    path: '/invoice',
    component: Invoice,
    staticProps: {},
  },
  {
    name: 'orderFinancials',
    path: '/invoice-viewAllOFCs',
    component: OrderFinancials,
    staticProps: {},
  },
  {
    name: 'Edit / View OFC',
    path: '/invoice/:mode/OFC/:ofcId',
    component: EditOFCs,
    staticProps: {},
  },
  {
    name: 'invoiceDetails',
    path: '/invoice/view/:invoiceNo',
    component: InvoiceDetails,
    staticProps: {},
  },
  {
    name: 'invoiceEmails',
    path: '/invoice/email',
    component: EmailInvoice,
    staticProps: {},
  },
  {
    name: 'invoicePdfView',
    path: '/invoice/view/pdf/:invoiceNo',
    component: InvoicePdfView,
    staticProps: {},
  },
  {
    name: 'Not Found Page',
    // Provide a fallback url in query when redirecting to this component
    // ?fallbackUrl=
    path: '/invoice-page-not-found',
    component: NotFoundPage,
    staticProps: {},
  },
  {
    name: 'Credit Notes',
    path: '/invoice/:controller',
    component: TablePage,
    staticProps: {},
  },
]
export default routes
