import { Fragment } from 'react'

const ConfirmModal = ({ closeModal, message, confirmAction }) => {
  const cancelAction = (event) => {
    event.stopPropagation()
    closeModal()
  }
  return (
    <Fragment>
      <div
        className={`modal fade show`}
        tabIndex="-1"
        role="dialog"
        aria-labelledby="editDataModalLabel"
        aria-hidden="true"
        style={{ display: 'block', whiteSpace: 'pre-wrap' }}
      >
        <div className="modal-dialog modal-md modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="editDataModalLabel">
              Confirmation
            </h5>

            <span
              className="close"
              data-dismiss="modal"
              aria-label="Close"
              onClick={cancelAction}
            >
              <svg
                fill="#aaa"
                id="Layer_1"
                version="1.0"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M12 2C6.5 2 2 6.5 2 12s4.5 10 10 10 10-4.5 10-10S17.5 2 12 2zm4.9 13.5l-1.4 1.4-3.5-3.5-3.5 3.5-1.4-1.4 3.5-3.5-3.5-3.5 1.4-1.4 3.5 3.5 3.5-3.5 1.4 1.4-3.5 3.5 3.5 3.5z" />
              </svg>
            </span>
          </div>

          <div className="modal-body">
            <h2 className="mt-4">{message}</h2>
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-primary"
              onClick={cancelAction}
              data-dismiss="modal"
            >
              No
            </button>
            <button
              type="button"
              className="btn btn-primary"
              onClick={confirmAction}
              data-dismiss="modal"
            >
              Confirm
            </button>
          </div>
        </div>
      </div>
      <div className="modal-backdrop" onClick={cancelAction}></div>
    </Fragment>
  )
}

export default ConfirmModal
