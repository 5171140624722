import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

import conf from '../../config'
import { toast } from 'react-toastify'
import fetchService from '../../components/utils/fetchService'
import { Info } from '../../components/utils/Toastify'
const { api: config } = conf

const initialState = {
  loading: false,
  searchText: '',
  isCreateConversationModalOpen: false,
  activeUsers: [],
  userConversations: [],
  selectedChat: null,
}

export const fetchActiveChatUsers = createAsyncThunk(
  'enigmaChatReducer/fetchActiveChatUsers',
  async () => {
    const url = config.enigmaChat.getAllUsers
    return fetchService({ url })
  }
)

export const createPersonalConversation = createAsyncThunk(
  'enigmaChatReducer/createPersonalConversation',
  async (receiverAdminId) => {
    const {
      userDetails: { id },
    } = JSON.parse(localStorage.data)
    const genRand = (len) =>
      Math.random()
        .toString(36)
        .substring(2, len + 2)
    const chatIdentityName = `personal-${genRand(8)}`

    const url = `${config.enigmaChat.createPersonalConversation}/${chatIdentityName}?senderAdminId=${id}&receiverAdminId=${receiverAdminId}`

    return fetchService({
      method: 'POST',
      url,
    }).then((response) => {
      if (response.status === 200) {
        return response
      } else {
        throw response
      }
    })
  }
)

export const fetchUserConversations = createAsyncThunk(
  'enigmaChatReducer/fetchUserConversations',
  async () => {
    const { userDetails } = JSON.parse(localStorage.data)
    const url = `${config.enigmaChat.getUserConversations}?userId=${userDetails.id}`
    return fetchService({ url }).then((response) => {
      if (response.status === 200) {
        if (response.data.length === 0)
          throw new Error('User has no conversations')
        return response
      } else {
        throw response
      }
    })
  }
)

export const fetchChatConversations = createAsyncThunk(
  'enigmaChatReducer/fetchChatConversations',
  async (id) => {
    const url = `${config.enigmaChat.getConversation}?conversationSid=${id}`
    return fetchService({ url }).then((response) => {
      if (response.status === 200) {
        return response
      } else {
        throw response
      }
    })
  }
)

export const postMessage = createAsyncThunk(
  'enigmaChatReducer/postMessage',
  async (body) => {
    const url = config.enigmaChat.sendMessage
    const data = new FormData()

    data.append('chatMessageRequestDTO', JSON.stringify(body))

    return fetchService({
      url,
      method: 'POST',
      body: data,
      removeContentType: true,
      isFile: true,
    }).then(async (response) => {
      if (response.status === 200) {
        return true
      } else {
        throw new Error('Unable to publish message!')
      }
    })
  }
)

export const enigmaChatSlice = createSlice({
  name: 'enigmaChatReducer',
  initialState,
  reducers: {
    setLoading: (state, action) => {
      state.loading = action.payload
    },
    pushMessage: (state, action) => {
      const newMessage = action.payload
      if (
        state.selectedChat !== null &&
        state.selectedChat.conversationSid === newMessage.conversationSid
      ) {
        state.selectedChat?.chatMessageResponseDTOS.push(newMessage)
        state.selectedChat.lastMessageCreatedDate = newMessage.createdDate
        state.selectedChat.lastMessage = newMessage.body
      } else {
        Info(`New message received by ${newMessage.author}`)
      }
      const index = state.userConversations.findIndex(
        (c) => c.conversationSid === newMessage.conversationSid
      )
      state.userConversations[index].lastMessageCreatedDate =
        newMessage.createdDate
      state.userConversations[index].lastMessage = newMessage.body
    },
    setCreateConversationModalState: (state, action) => {
      state.isCreateConversationModalOpen = action.payload
    },
    setSearchText: (state, action) => {
      state.searchText = action.payload
    },
    setSelectedChat: (state, action) => {
      state.selectedChat = action.payload
    },
  },
  extraReducers: (builder) => {
    builder
      // fetchActiveChatUsers
      .addCase(fetchActiveChatUsers.pending, (state) => {
        state.loading = true
      })
      .addCase(fetchActiveChatUsers.fulfilled, (state, action) => {
        if (action.payload.data.length > 0)
          state.activeUsers = action.payload.data
        state.loading = false
      })
      .addCase(fetchActiveChatUsers.rejected, (state) => {
        state.loading = false
      })

      // fetchUserConversations
      .addCase(fetchUserConversations.pending, (state) => {
        state.loading = true
      })
      .addCase(fetchUserConversations.fulfilled, (state, action) => {
        if (action.payload.data) state.userConversations = action.payload.data
        state.loading = false
      })
      .addCase(fetchUserConversations.rejected, (state) => {
        state.loading = false
      })

      // fetchChatConversations
      .addCase(fetchChatConversations.pending, (state) => {
        state.loading = true
      })
      .addCase(fetchChatConversations.fulfilled, (state, action) => {
        state.selectedChat = action.payload.data
        state.loading = false
      })
      .addCase(fetchChatConversations.rejected, (state) => {
        state.loading = false
      })

      // postMessage
      // .addCase(postMessage.pending, (state) => {
      //   state.loading = true
      // })
      .addCase(postMessage.fulfilled, (state, action) => {
        state.loading = false
      })
      // .addCase(postMessage.rejected, (state, action) => {
      //   state.loading = false
      // })

      // createPersonalConversation
      .addCase(createPersonalConversation.pending, (state, action) => {
        state.loading = true
      })
      .addCase(createPersonalConversation.fulfilled, (state, action) => {
        state.loading = false
        if (action.payload?.data) {
          toast.success('Created conversation Successfully')
          state.userConversations.push(action.payload?.data)
          state.isCreateConversationModalOpen = false
        } else {
          toast.error('Something went wrong')
        }
      })
      .addCase(createPersonalConversation.rejected, (state, action) => {
        state.loading = false
      })
  },
})

export const {
  setLoading,
  pushMessage,
  setCreateConversationModalState,
  setSearchText,
  setSelectedChat,
} = enigmaChatSlice.actions

export const getAllChatUsers = (state) => state?.enigmaChat.activeUsers

export const getUserConversations = (state) =>
  state?.enigmaChat.userConversations

export const getSelectedChat = (state) => state?.enigmaChat.selectedChat

export const getIsCreateConversationModalOpenState = (state) =>
  state.enigmaChat.isCreateConversationModalOpen

export const getSearchTextState = (state) => state.enigmaChat.searchText

export const getEnigmaChatLoadingState = (state) => state.enigmaChat.loading

export default enigmaChatSlice.reducer
