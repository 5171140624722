import { faPencil, faPhone, faTrash } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useState } from 'react'
import { Col, ListGroup, Row, Spinner } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import AlertModal from '../../../../components/Common/Modal/AlertModal'
import config from '../../../../config'
import {
  useDeleteDriverMutation,
  useTwilioDevice,
} from '../../../../helper/hooks/api/allocations'
import { MessageIcon } from '../../../../icons'
import {
  selectSelectedDriver,
  setSelectedDriver,
} from '../../../../store/slices/allocation/drivers'
import VehicleIcon from './JobsDetails/VehicleIcon'

const { goLiveMonitoring } = config.api

const { base, loginEndpoint, secret, userName } = goLiveMonitoring

const LicensePlatNo = ({ vehicleTruckDTO }) => (
  <a
    href={`${base}${loginEndpoint}${secret}/${btoa(
      `${vehicleTruckDTO?.licensePlatNo}-${vehicleTruckDTO?.batNo}`
    )}/${btoa(userName)}`}
    target="_blank"
    rel="noreferrer"
    className="table-link"
    style={{ lineHeight: '2.2' }}
  >
    {vehicleTruckDTO?.licensePlatNo}
  </a>
)

const DeleteDriverIconBtn = ({ driver }) => {
  const { mutate, isPending, error } = useDeleteDriverMutation()

  const deleteDriver = (closeModal) => {
    mutate(driver.driverCardId, {
      onSuccess: () => {
        closeModal()
      },
    })
  }

  return (
    <AlertModal
      message={
        <div>
          <p>Are you sure you want to delete this driver.</p>
          <p>
            {driver.driverName} - {driver.driverId}
          </p>
          {error?.error && typeof error.error === 'string' && (
            <p className="text-danger">{error.error}</p>
          )}
          {Array.isArray(error?.data) &&
            error.data.map((err) => (
              <p className="text-danger">{err.message}</p>
            ))}
        </div>
      }
      handleConfirm={deleteDriver}
      confirmBtnProps={{
        disabled: isPending,
      }}
      confirmBtnText={isPending ? <Spinner size="sm" /> : 'Confirm'}
    >
      {(openModal) => (
        <button disabled={isPending} className="unset-all" onClick={openModal}>
          <FontAwesomeIcon color="#127ea6" icon={faTrash} />
        </button>
      )}
    </AlertModal>
  )
}

const EditIconBtn = ({ driverId }) => {
  return (
    <Link target="_blank" to={`/transport/drivers/edit/${driverId}`}>
      <FontAwesomeIcon color="#127ea6" icon={faPencil} />
    </Link>
  )
}

const MessageIconBtn = () => {
  return (
    <Link target="_blank" to="/enigma-chat">
      <MessageIcon />
    </Link>
  )
}

const PhoneIconBtn = ({ driver }) => {
  const { data: device } = useTwilioDevice()
  const [contactNumber, setContactNumber] = useState(`+91 ${driver.contactNo}`)

  const handleCall = () => {
    device.connect({
      params: {
        To: contactNumber.replace(' ', ''),
      },
    })
  }

  const handleHangUp = (closeModal) => {
    device.disconnectAll()
    closeModal()
  }

  return (
    <AlertModal
      centered
      handleConfirm={handleCall}
      message={
        <Row>
          <p>Your device is ready to make a call now to</p>
          <Col md={4}>
            <input
              className="form-control"
              value={contactNumber}
              onChange={(e) => setContactNumber(e.target.value)}
            />
          </Col>
        </Row>
      }
      confirmBtnText={
        <span>{device?.isBusy && <Spinner size="sm" />}Make a call now</span>
      }
      confirmBtnProps={{
        disabled: device?.isBusy,
      }}
      closeBtnTxt={device?.isBusy ? 'End Call' : 'close'}
      closeBtnClick={handleHangUp}
    >
      {(openModal) => (
        <button
          className="unset-all"
          onClick={openModal}
          disabled={device?.isBusy}
        >
          <FontAwesomeIcon icon={faPhone} />
        </button>
      )}
    </AlertModal>
  )
}

export default function DriverListItem({ driver }) {
  const dispatch = useDispatch()

  const selectedDriver = useSelector(selectSelectedDriver)

  const handleDriverClick = () => {
    dispatch(setSelectedDriver(driver))
  }

  const isSelectedDriver = selectedDriver?.driverId === driver.driverId

  return (
    <ListGroup.Item
      as="li"
      className="d-flex justify-content-between align-items-start"
      variant={isSelectedDriver ? 'primary' : ''}
    >
      <button className="unset-all d-flex flex-column justify-content-between w-100">
        <div className="fw-bold" onClick={handleDriverClick}>
          {driver.driverName}
        </div>
        <div className="mt-1">
          {/* <span
            style={{
              fontSize: '0.75rem',
              textTransform: 'initial',
            }}
          >
            Id
          </span> */}
          {driver.driverId}
        </div>
      </button>
      <div className="d-flex flex-column justify-content-between">
        <div className="d-flex gap-3 justify-content-end">
          <Link
            target="_blank"
            style={{ color: '#127ea6' }}
            to={`/truck-dashboard?vehicleType=TRUCK&driverCardId=${driver.driverCardId}`}
          >
            <VehicleIcon vehicleType={driver.vehicleTruckDTO?.vehicleType} />
          </Link>
          <PhoneIconBtn driver={driver} />
          <MessageIconBtn />
          <DeleteDriverIconBtn driver={driver} />
          <EditIconBtn driverId={driver.driverId} />
        </div>
        <div className="d-flex justify-content-end">
          <LicensePlatNo vehicleTruckDTO={driver?.vehicleTruckDTO} />
        </div>
      </div>
    </ListGroup.Item>
  )
}
