import { yupResolver } from '@hookform/resolvers/yup'
import axios from 'axios'
import React, { useEffect, useMemo, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useSelector } from 'react-redux'
import * as Yup from 'yup'
import config from '../../../../../../config'
import useModalContainer from '../../../../../../helper/hooks/useModalContainer'
import { useOrdersContext } from '../../../context/orderOperationsContext'
import { getLookupDataState } from '../../../../../../store/slices/lookup-slice'
import { TextBox } from '../../../../../../components/utils/TextBox'
import * as Toastify from '../../../../../../components/utils/Toastify'

const Accounting = ({ type, accountingData, setOperationsData }) => {
  const lookupsList = useSelector(getLookupDataState)
  const [isBillable, setIsBillable] = useState(false)

  const operationsCtxData = useOrdersContext()

  const validationSchema = Yup.object().shape({
    invoiceNumber: Yup.string().matches(/^[a-zA-Z 0-9_-]+$/, {
      message: 'Alphanumeric characters or underscores only',
      excludeEmptyString: true,
    }),
    currencyId: Yup.string().required('Currency is required'),
    roadFuelLevy: Yup.number(),
    unitPrice: Yup.number(),
    isBillable: Yup.boolean(),
  })

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm({
    resolver: yupResolver(validationSchema),
    reValidateMode: 'onChange',
    defaultValues: { ...accountingData },
  })

  useEffect(() => {
    if (accountingData) {
      setValue('roadFuelLevy', accountingData.roadFuelLevy)
      setValue('invoiceNumber', accountingData.invoiceNumber)
      setValue('currencyId', accountingData.currency)
      setValue('unitPrice', accountingData.unitPrice)
      setIsBillable(accountingData.billable)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accountingData])

  const orderCustomerDetailsId = useMemo(() => {
    return operationsCtxData.orderCustomerDetailsId
      ? operationsCtxData.orderCustomerDetailsId
      : accountingData.orderCustomerDetailsId
  }, [operationsCtxData, accountingData])

  const onSubmitFunction = (formData) => {
    let options = {
      billable: formData.isBillable,
      currency: formData.currencyId,
      invoiceNumber: formData.invoiceNumber,
      roadFuelLevy: formData.roadFuelLevy,
      unitPrice: formData.unitPrice,
      orderAccountingId: accountingData?.orderAccountingId,
      orderCustomerDetailsId: orderCustomerDetailsId, // Need to get it from step1 response json
    }

    let requestType = accountingData?.orderAccountingId ? 'PUT' : 'POST'
    let requestUrl = accountingData?.orderAccountingId
      ? config.api.accunting + '/' + accountingData.orderAccountingId
      : config.api.accunting

    axios({
      method: requestType, //you can set what request you want to be
      url: config.api.baseUrl + requestUrl,
      data: options,
      headers: {
        Authorization: 'Bearer ' + localStorage.token,
      },
    })
      .then(function (response) {
        setOperationsData({
          ...operationsCtxData,
          orderAccountingId: response.data.orderAccountingId,
        })
        Toastify.Success('Accounting details submitted successfuly..!')
      })
      .catch(function (error) {
        Toastify.Error(error?.response?.data?.error ?? 'Something went wrong!')
      })
  }

  const handleSubmitWithoutPropagation = (e) => {
    e.preventDefault()
    e.stopPropagation()
    handleSubmit(onSubmitFunction)(e)
  }

  const { ModalContainer, setShow } = useModalContainer({
    text: (
      <button
        onClick={() =>
          operationsCtxData?.orderCustomerDetailsId && setShow(true)
        }
        className="unset-all d-flex flex-column align-items-center"
        title="Accounting"
      >
        <img
          src="./assets/customer-order/accounting.png"
          alt="Accounting"
          width="40px"
          height="40px"
          className="img"
        />
        Accounting
      </button>
    ),
    body: (
      <form autoComplete="off" onSubmit={handleSubmitWithoutPropagation}>
        <div className="row entair-form2">
          <div className="row">
            <div className="col-md-12">
              <div className="row">
                <div className="col-md-1" />
                <div className="col-md-3">
                  <label>Road Fuel Levy :</label>
                </div>
                <div className="col-md-4 mb-4">
                  <TextBox
                    register={register}
                    id="roadFuelLevy"
                    errors={errors}
                    value=""
                    placeholder="Road Fuel Levy"
                    maxLength="10"
                    disabled={type === 'view'}
                    viewMode={type}
                    defaultData={accountingData}
                  />
                </div>
              </div>

              <div className="row">
                <div className="col-md-1" />
                <div className="col-md-3">
                  <label>Invoice Number :</label>
                </div>
                <div className="col-md-4 mb-4">
                  <TextBox
                    register={register}
                    id="invoiceNumber"
                    errors={errors}
                    value=""
                    placeholder="Invoice Number"
                    maxLength="20"
                    disabled={type === 'view'}
                    viewMode={type}
                    defaultData={accountingData}
                  />
                </div>
              </div>

              <div className="row">
                <div className="col-md-1" />
                <div className="col-md-3">
                  <label>Currency :</label>
                </div>
                <div className="col-md-4 mb-4">
                  {type !== 'view' && (
                    <>
                      <select
                        className={`form-select ${
                          errors?.currencyId ? 'is-invalid' : ''
                        }`}
                        id="currencyId"
                        name="currencyId"
                        {...register('currencyId')}
                      >
                        <option value="" disabled>
                          Please Select
                        </option>
                        {lookupsList?.currencyCodes.map((item) => (
                          <option
                            key={item.currencyCode}
                            value={item.currencyCode}
                          >
                            {item.description}
                          </option>
                        ))}
                      </select>
                      {errors?.currencyId && (
                        <p className="invalid-feedback">
                          {errors?.currencyId?.message}
                        </p>
                      )}
                    </>
                  )}
                  {type === 'view' && (
                    <h4 className="blue-title">
                      {accountingData && accountingData.currency}
                    </h4>
                  )}
                </div>
                <div className="col-md-1" title="Add Currency">
                  <img
                    src="./assets/add-button.png"
                    alt="Add New"
                    width="25px"
                    height="25px"
                  />
                </div>
              </div>

              <div className="row">
                <div className="col-md-1" />
                <div className="col-md-3">
                  <label>Unit Price :</label>
                </div>
                <div className="col-md-4 mb-4">
                  <TextBox
                    register={register}
                    id="unitPrice"
                    errors={errors}
                    value=""
                    placeholder="Unit Price"
                    maxLength="10"
                    disabled={type === 'view'}
                    viewMode={type}
                    defaultData={accountingData}
                  />
                </div>
              </div>

              <div className="row">
                <div className="col-md-1" />
                <div className="col-md-3">
                  <label>Billable:</label>
                </div>
                <div className="col-md-4">
                  <input
                    className="form-check-input"
                    {...register('isBillable')}
                    type="checkbox"
                    id="isBillable"
                    checked={isBillable}
                    disabled={type === 'view'}
                    onClick={(e) => {
                      if (e.currentTarget.checked) {
                        setIsBillable(true)
                      } else {
                        setIsBillable(false)
                      }
                    }}
                  />
                  <label
                    className="form-check-label text-white blue-title"
                    htmlFor="isBillable"
                    style={{
                      marginTop: -5,
                      marginLeft: 10,
                    }}
                  >
                    Yes
                  </label>
                </div>
              </div>

              <br />
              {type !== 'view' && (
                <div className="row">
                  <div className="col-md-1" />
                  <div className="col-7 text-right">
                    <button
                      className="btn btn-primary float-end ms-3"
                      type="submit"
                    >
                      Save
                    </button>
                    <button
                      className="btn btn-secondary float-end"
                      type="reset"
                    >
                      Reset
                    </button>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </form>
    ),
    header: 'Accounting Details',
  })

  return ModalContainer
}

export default Accounting
