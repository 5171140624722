import startCase from 'lodash/startCase'

export function generateTableColumns(obj, excludeKeys = []) {
  return Object.keys(obj)
    .filter((key) => {
      const value = obj[key]

      // Simplified filter: Include keys with null values and exclude keys with object values that are not date objects
      return (
        value === null || typeof value !== 'object' || value instanceof Date
      )
    })
    .filter((key) => !excludeKeys.includes(key))
    .map((key) => ({
      header: startCase(key),
      accessorKey: key,
      id: key,
    }))
}
