import fetchService from '../../components/utils/fetchService'
import config from '../../config'
import { setAccountingDetails } from '../slices/customer-slice'
import { handleFetchError } from '../../components/utils/Utils'

export const fetchAccountingDetails = (id, cb, errCb) => {
  return async (dispatch) => {
    try {
      const data = await fetchService({
        url: `${config.api.customerCard}/accounting?customerCardid=${id}`,
      })
      dispatch(setAccountingDetails(data))
      cb(data)
    } catch (err) {
      handleFetchError(
        err,
        'Some error occurred while fetching Accounting Details.'
      )
      errCb()
    }
  }
}
