import React from 'react'
import Header from './Messages/Header'
import Messages from './Messages/Messages'
import Input from './Messages/Input'
import { getSelectedChat } from '../../../../store/slices/enigmaChat-slice'
import { useSelector } from 'react-redux'
import useMediaQuery, {
  mobileQuery,
} from '../../../../helper/hooks/useMediaQuery'

export default function FeedWrapper() {
  const conversation = useSelector(getSelectedChat)
  const isMobile = useMediaQuery(mobileQuery)
  const isConversationSelected = conversation !== null

  return isMobile && !isConversationSelected ? null : (
    <div className="col-md-8 col-xs-12 feed-wrapper p-0 m-0 h-100">
      {conversation === null ? (
        <h5 className="p-3">No Conversation Selected</h5>
      ) : (
        <>
          <Header />
          <Messages />
          <Input />
        </>
      )}
    </div>
  )
}
