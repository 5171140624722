import React from 'react'
import { toast } from 'react-toastify'
import DateRangFile from '../../../components/Modals/DateRangeModal'
import fetchService from '../../../components/utils/fetchService'

const CreditNotesHeader = () => {
  const handleSubmit = async (startDate, endDate) => {
    try {
      const response = await fetchService({
        url: '/operations/order/invoice/myob/export/credit-note',
        method: 'POST',
        body: {
          endDate: endDate,
          manual: false,
          startDate: startDate,
        },
        returnRaw: true,
      })
      const data = await response
      if (
        data ===
        'no auto generated customer invoices data exists between the dates you chosen.'
      ) {
        toast.error(
          'no auto generated customer invoices data exists between the dates you chosen.'
        )
        return
      }
      toast.success('Succesfully Exported MYOB')
    } catch (err) {
      toast.error('Something went wrong!')
    }
  }

  return (
    <div className="flex justify-end">
      {/* <NewCreditNote></NewCreditNote> */}
      <DateRangFile
        header={'Export MYOB'}
        handleSubmit={handleSubmit}
      ></DateRangFile>
    </div>
  )
}

export default CreditNotesHeader
