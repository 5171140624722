import startCase from 'lodash/startCase'
import React from 'react'
import ContainerWithHeading from '../../../../../../components/styles/Containers/ContainerWithHeading'

export default function AvailabilityAlerts({ customerOrderData }) {
  if (customerOrderData === null) return null

  const { wharfJobType } = customerOrderData

  return (
    <ContainerWithHeading
      heading="Availability Alerts"
      Wrapper="div"
      wrapperProps={{ className: 'mt-3' }}
    >
      {[
        'availabilityDate',
        'closingDate',
        wharfJobType === 'IMPORT' && 'eta',
        wharfJobType === 'EXPORT' && 'etd',
        'noOfContainers',
        'sizeOfContainer40gp',
        'wharfJobType',
      ].map((key) => (
        <p key={key}>
          <strong>{startCase(key)}</strong> -{' '}
          {customerOrderData.orderVesselDetails.availabilityAlerts?.[key]}
        </p>
      ))}
    </ContainerWithHeading>
  )
}
