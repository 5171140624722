import React from 'react'
import { Row } from 'react-bootstrap'
import { useSelector } from 'react-redux'
import { selectSelectedContainerLoad } from '../../../../../store/slices/allocation/drivers'
import DetailComp from './DetailComp'

export default function LoadDetails() {
  const selectedLoad = useSelector(selectSelectedContainerLoad)

  if (!selectedLoad) return null

  return (
    <div className="mt-3">
      <h5>Load {selectedLoad.containerLoadNumber} Details</h5>
      <Row>
        <DetailComp label="Job No" value={selectedLoad.jobNo} />
        <DetailComp
          label="Customer Ref"
          value={selectedLoad.customerReferenceNO}
        />
        <DetailComp
          className="mt-1 mb-1"
          label="Billing Customer"
          value={selectedLoad.billingCustomer}
          md={8}
        />
        <DetailComp label="Release No" value={selectedLoad.releaseNO} />
        <DetailComp label="Order No" value={selectedLoad.orderNo} />
        <DetailComp
          label="Consignee Name"
          className="mt-1"
          md={8}
          value={selectedLoad.consigneeName}
        />
        <DetailComp
          label="Container No"
          md={8}
          className="mt-1"
          value={selectedLoad.containerNO}
        />
        <DetailComp
          label="Pick Up Address"
          md={12}
          className="my-1"
          value={selectedLoad.pickupAddress}
        />
        <DetailComp label="Grade" value={selectedLoad.grade} />
        <DetailComp label="Gross Weight" value={selectedLoad.grossWeight} />
        <DetailComp
          label="Container Size"
          md={8}
          value={selectedLoad.containerSize}
        />
        <DetailComp
          label="Delivery Up Address"
          md={12}
          className="my-1"
          value={selectedLoad.deliveryAddress}
        />
      </Row>
    </div>
  )
}
