import React, { Fragment, useEffect } from 'react'
import { Link, useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import isNull from 'lodash/isNull'
import { fetchInvoiceDetails } from '../../../store/actions/invoice-actions'
import { getInvoiceDetailsFromInvoiceNo } from '../../../store/slices/invoice-slice'

const Detail = ({ text = '', value = '' }) => (
  <Fragment>
    <div className="col-md-2 detail-key mb-5">{text}</div>
    <div className="col-md-10 detail-value">{value}</div>
  </Fragment>
)

export default function InvoiceDetails() {
  const dispatch = useDispatch()
  const { invoiceNo } = useParams()
  const invoiceDetails = useSelector((state) =>
    getInvoiceDetailsFromInvoiceNo(state, invoiceNo)
  )

  useEffect(() => {
    if (isNull(invoiceDetails)) dispatch(fetchInvoiceDetails(invoiceNo))
  }, [dispatch, invoiceDetails, invoiceNo])

  return (
    <div className="invoice-details-wrapper">
      <header>
        <h1>Invoice Details</h1>
      </header>
      <main>
        <div className="action-tabs">
          <div>
            <Link to={`/invoice/view/OFC/${invoiceDetails?.ofcId}`}>
              View Orders Financial Card(OFC)
            </Link>
          </div>
          <div>
            <Link to={`/invoice/view/pdf/${invoiceDetails?.invoiceNo}`}>
              View Invoice PDF
            </Link>
          </div>
          <div>Edit Invoice</div>
          <div>Email Invoice</div>
        </div>
        <div className="details-tab">
          <div className="row">
            <Detail text="Invoice No. :" value={invoiceDetails?.invoiceNo} />
            <Detail text="Invoice OFC ID :" value={invoiceDetails?.ofcId} />
            <Detail
              text="Customer Name :"
              value={invoiceDetails?.customerName}
            />
            <Detail text="Sub-total :" value={invoiceDetails?.subTotal} />
            <Detail text="Total GST :" value={invoiceDetails?.totalGst} />
            <Detail text="Total :" value={invoiceDetails?.total} />
            <Detail text="Sent Status :" value={invoiceDetails?.sentStatus} />
            <Detail
              text="Invoice Due Date :"
              value={invoiceDetails?.invoiceDueDate}
            />
          </div>
        </div>
      </main>
    </div>
  )
}
