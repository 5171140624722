import React, { useState } from 'react'
import Dropzone from 'react-dropzone'
import Papa from 'papaparse'
import ZoneTableForm from './ZoneTableForm'

const ImportZone = (props) => {
  const {
    csvArray,
    setCsvArray,
    formError,
    showError,
    showSuccessMsg,
    addRow,
    deleteRow,
  } = props
  const [filePath, setFilePath] = useState('')
  const [filesList, setFilesList] = useState(null)
  const [fileError, setFileError] = useState(false)

  const handleAcceptedFiles = (files) => {
    setFilesList(files[0])
    setFilePath(files[0].path)

    const reader = new FileReader()
    reader.onload = async ({ target }) => {
      Papa.parse(target.result, {
        header: false,
        complete: ({ data }) => {
          if (data.length < 2) {
            setFileError(true)
            return false
          }
          setFileError(null)
          console.log(data)

          let clonedData = [...data]
          clonedData.splice(0, 2)
          let newData = []
          clonedData.forEach((eachItem, i) => {
            let a = []
            eachItem.forEach((item) => {
              a.push(item.replace('$', ''))
            })
            newData.push(a)
          })

          const fields = [
            'zone',
            'kms',
            'standard20FtContainer',
            'standard40FtContainer',
            'sideLoader20FtContainer',
            'sideLoader40FtContainer',
            'dropTrailerDeliver20FtContainer',
            'dropTrailerDeliver40FtContainer',
            'tollsPrice',
          ]
          let arr = []
          newData.forEach((each, i) => {
            let newObj = {}
            fields.forEach((item, index) => {
              newObj[item] = each[index]
            })
            arr.push(newObj)
          })

          setCsvArray({ data: arr, igniter: new Date() })
        },
      })
      // parse(reader.result, (err, data) => {
      //   if (err) {
      //     alert('please upload a valid csv file')
      //     setFileError(true)
      //     return false
      //   }
      // })
    }

    reader.readAsBinaryString(files[0])
  }

  const uploadCSV = (e) => {
    e.preventDefault()
    const zoneType = e.target.querySelector('select')
    if (!zoneType.value) {
      zoneType.classList.add('is-invalid')
      zoneType.focus()
      return false
    }

    if (!filesList) {
      setFileError(true)
      return false
    }
    if (fileError) {
      alert('please upload a valid csv file')
      return false
    }

    props.AddMultiZones(e)
  }

  return (
    <div>
      <form onSubmit={uploadCSV}>
        <div className="row">
          <div className="col-md-9">
            <input type="hidden" value="1" id="customerCardId" />
            <h4>Import Zone profile</h4>
          </div>
          <div className="col-md-2 mb-2">
            <select className="form-select" id="zoneType" name="zone">
              <option value="">Please Select</option>
              <option value="ZONE10KM">Zone 10KM</option>
              <option value="ZONE15KM">Zone 15KM</option>
              <option value="out">Suburb</option>
            </select>
            <p className="invalid-feedback">this field is required</p>
          </div>
        </div>

        <Dropzone
          onDrop={(acceptedFiles) => handleAcceptedFiles(acceptedFiles)}
          multiple={false}
          accept={{
            'text/csv': [],
          }}
        >
          {({ getRootProps, getInputProps }) => (
            <div className="dropzone">
              <div
                className={
                  (fileError ? 'error-file' : '') +
                  ' dz-message needsclick mt-2 '
                }
                {...getRootProps()}
              >
                <input {...getInputProps()} name="uploadfile" />
                <div className="mb-3">
                  <i className="display-4 text-muted ri-upload-cloud-2-line"></i>
                </div>
                <h4>Drag a file here</h4>
                <ul>
                  <li>{filePath}</li>
                </ul>
              </div>
            </div>
          )}
        </Dropzone>

        {fileError && (
          <p className="text-danger">Please upload a valid CSV file</p>
        )}

        {csvArray.data && csvArray.data.length > 0 && (
          <ZoneTableForm
            zoneCodes={props.zoneCodes}
            deleteRow={deleteRow}
            csvArray={csvArray}
            addRow={addRow}
          />
        )}

        {formError && (
          <p className="text-danger my-4">
            Please check the error messages and submit the form again
          </p>
        )}
        <button type="submit" className="btn btn-primary">
          Submit
        </button>
      </form>

      {showError && (
        <div className="text-danger my-4">
          <p>There is an issue in form submission. please try later.</p>
          {showError.length &&
            showError.map((eachError, i) => {
              return (
                <p key={i}>
                  {eachError.key} - {eachError.message}
                </p>
              )
            })}
        </div>
      )}

      {showSuccessMsg && (
        <p className="text-success my-4">Form has submitted successfully!</p>
      )}
    </div>
  )
}

export default ImportZone
