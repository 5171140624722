import rateGroup from './rateGroup'
import wharfJobType from './wharfJobType'
import vehicleJobType from './vehicleJobType'
import stageMovementType from './stageMovementType'
import zoneCode from './zoneCode'
import countryCode from './countryCode'
import customerTransactionType from './customerTransactionType'
import rationalCode from './rationalCode'
import postCode from './postCode'
import areaCode from './areaCode'
import rateCode from './rateCode'
import jobWindow from './jobWindow'
import messageType from './messageType'
import wharfCode from './wharfCode'
import port_code from './portCode'
import shipping_agent from './shippingAgent'
import empty_dehire from './emptyDehire'
import warehouse from './warehouse'
import yard from './yard'
import vendor_code from './vendorCode'
import service_yard from './serviceYard'
import rail_head from './railHead'
import cargoHandling from './cargoHandling'
import cargoType from './cargoType'
import containerCategory from './containerCategory'
import containerStatus from './containerStatus'
import equipment_size from './equipmentSize'
import holdCode from './holdCode'
import IMOCode from './IMOCode'
import package_Type from './packageType'
import document_type from './documentType'
import distinct_Conduct from './distinctConduct'
import stockRoom from './stockRoom'
import dimension_unit from './dimensionUnit'
import vesselDetails from './vesselDetails'
import driver_type from './driverType'
import trailer_type from './trailerType'
import equipment_Type from './equipmentType'
import truck_type from './truckType'
import special_service from './specialService'

const customer = {
  type: 'Customer Management',
  rateGroup,
  wharfJobType,
  vehicleJobType,
  stageMovementType,
  zoneCode,
  countryCode,
  customerTransactionType,
  rationalCode,
  postCode,
  areaCode,
  rateCode,
  jobWindow,
  messageType,
  wharfCode,
  port_code,
  shipping_agent,
  empty_dehire,
  warehouse,
  yard,
  vendor_code,
  service_yard,
  rail_head,
  cargoHandling,
  cargoType,
  containerCategory,
  containerStatus,
  equipment_size,
  holdCode,
  IMOCode,
  package_Type,
  document_type,
  distinct_Conduct,
  stockRoom,
  dimension_unit,
  vesselDetails,
  driver_type,
  trailer_type,
  equipment_Type,
  truck_type,
  special_service,
}

export default customer
