import React, { useEffect } from 'react'
import ConversationsList from './ConversationsList'
import Header from './Header'
import Search from './Search'
import CreateConversationModal from './CreateConversationModal'
import { useDispatch, useSelector } from 'react-redux'
import {
  fetchUserConversations,
  getSelectedChat,
  getUserConversations,
} from '../../../../store/slices/enigmaChat-slice'
import useMediaQuery, {
  mobileQuery,
} from '../../../../helper/hooks/useMediaQuery'

export default function ConversationsWrapper() {
  const dispatch = useDispatch()
  const isMobile = useMediaQuery(mobileQuery)
  const conversations = useSelector(getUserConversations)
  const selectedChat = useSelector(getSelectedChat)

  useEffect(() => {
    if (conversations.length === 0) {
      dispatch(fetchUserConversations())
    }
  }, [conversations, dispatch])

  const isConversationSelected = selectedChat !== null

  return isMobile && isConversationSelected ? null : (
    <div className="col-md-4 col-xs-12 conversations-wrapper">
      <CreateConversationModal />
      <Header />
      <Search />
      <ConversationsList />
    </div>
  )
}
