import React from 'react'
import { Link } from 'react-router-dom'

export default function ErrorPage(params) {
  return (
    <div className="App">
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <h3 className="my-4">404 Page not found</h3>
            Go to <Link to="/dashboard">Dashboard screen</Link> or{' '}
            <Link to="/">Login screen</Link>
          </div>
        </div>
      </div>
    </div>
  )
}
