import React from 'react'

export default function TextInputField({
  title,
  id,
  ratio,
  required = false,
  placeholder,
  register,
  errors,
  type,
  disabled,
  value = '',
  labelClassName = 'col-form-label',
  rowClasses = '',
  ...props
}) {
  const ratioOfFields = ratio ? ratio : [4, 8]

  return (
    <div className={`${rowClasses} row mb-3`}>
      <label
        htmlFor={id}
        className={`col-sm-${ratioOfFields[0]} ${labelClassName}`}
      >
        {title}
        {required && <span style={{ color: 'red' }}>*</span>}
      </label>
      <div className={`col-sm-${ratioOfFields[1]} `}>
        <input
          id={id}
          placeholder={placeholder}
          name={id}
          {...register(id)}
          className={`form-control ${errors?.[id] ? 'is-invalid' : ''}`}
          type={type}
          disabled={disabled}
          defaultValue={value}
          autoComplete="off"
          {...props}
        />
        {errors?.[id] && (
          <p className="invalid-feedback"> {errors[id].message}</p>
        )}
      </div>
    </div>
  )
}
