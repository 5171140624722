export const formData = [
  {
    name: 'ID',
    label: 'ID',
    type: 'text',
    validation: 'Alphabetic',
    fieldId: 'containerPickupId',
    id: 'containerPickupId',
    disabled: true,
  },
  {
    name: 'Order ID',
    label: 'Order ID',
    type: 'text',
    validation: 'Alphabetic',
    fieldId: 'orderNumber',
    id: 'orderNumber',
    disabled: true,
  },
  {
    name: 'Container Load Number',
    label: 'Container Load Number',
    type: 'text',
    validation: 'Alphabetic',
    fieldId: 'containerLoadNumber',
    id: 'containerLoadNumber',
    disabled: true,
  },
  {
    name: 'Container Job Number',
    label: 'Container Job Number',
    type: 'text',
    validation: 'Alphabetic',
    fieldId: 'containerJobNumber',
    id: 'containerJobNumber',
    disabled: true,
  },
  {
    name: 'Container Number',
    label: 'Container Number',
    type: 'text',
    validation: 'Alphabetic',
    fieldId: 'containerNumber',
    id: 'containerNumber',
    disabled: true,
  },
  {
    name: 'Container Stage Mvmt Type',
    label: 'Container Stage Mvmt Type',
    type: 'searchable-select',
    loadLookUp: '',
    validation: 'Alphabetic',
    fieldId: 'containerStageMvmtType',
    id: 'containerStageMvmtType',
    disabled: true,
  },
  {
    name: 'Pick Up Suburb Code',
    label: 'Pick Up Suburb Code',
    type: 'searchable-select',
    loadLookUp: 'areaCodes',
    validation: 'Alphabetic',
    fieldId: 'pickUpSuburbCode',
    id: 'pickUpSuburbCode',
    validationType: 'string',
    validations: [
      {
        type: 'required',
        params: ['##FIELD_NAME## is required'],
        nullable: true,
      },
    ],
  },
  {
    name: 'Delivery Suburb Code',
    label: 'Delivery Suburb Code',
    type: 'searchable-select',
    loadLookUp: 'areaCodes',
    validation: 'Alphabetic',
    fieldId: 'deliverySuburbCode',
    id: 'deliverySuburbCode',
    validationType: 'string',
    validations: [
      {
        type: 'required',
        params: ['##FIELD_NAME## is required'],
        nullable: true,
      },
    ],
  },
  {
    name: 'Client Pick Up Date',
    label: 'Client Pick Up Date',
    type: 'date',
    validation: 'Alphabetic',
    fieldId: 'clientPickUpDate',
    id: 'clientPickUpDate',
    validationType: 'string',
    validations: [
      {
        type: 'optional',
        params: [],
        nullable: true,
      },
    ],
  },
  {
    name: 'Client Pick Up Time',
    label: 'Client Pick Up Time',
    type: 'time',
    validation: 'Alphabetic',
    fieldId: 'clientPickUpTime',
    id: 'clientPickUpTime',
    validationType: 'string',
    validations: [
      {
        type: 'optional',
        params: [],
        nullable: true,
      },
    ],
  },
  {
    name: 'Avaibility Date',
    label: 'Avaibility Date',
    type: 'date',
    validation: 'Alphabetic',
    fieldId: 'avaibilityDate',
    id: 'avaibilityDate',
    validationType: 'string',
    validations: [
      {
        type: 'optional',
        params: [],
        nullable: true,
      },
    ],
  },
  {
    name: 'Date Closing',
    label: 'Date Closing',
    type: 'date',
    validation: 'Alphabetic',
    fieldId: 'dateClosing',
    id: 'dateClosing',
    validationType: 'string',
    validations: [
      {
        type: 'optional',
        params: [],
        nullable: true,
      },
    ],
  },
  {
    name: 'Dehire Date',
    label: 'Dehire Date',
    type: 'date',
    validation: 'Alphabetic',
    fieldId: 'dehireDate',
    id: 'dehireDate',
    validationType: 'string',
    validations: [
      {
        type: 'optional',
        params: [],
        nullable: true,
      },
    ],
  },
  {
    name: 'Status',
    label: 'Status',
    type: 'searchable-select',
    loadLookUp: 'loadStatusEnums',
    validation: 'Alphabetic',
    fieldId: 'status',
    id: 'status',
    validationType: 'string',
    validations: [
      {
        type: 'required',
        params: ['##FIELD_NAME## is required'],
        nullable: true,
      },
    ],
  },
]
