import React from 'react'
import ContainerStages from './ContainerStages'
import { queryKeys } from '../../../../helper/hooks/apiHooks'

export default function AllContainerStageDetailsList() {
  return (
    <div className="px-2">
      <ContainerStages
        queryData={{
          key: queryKeys.cerebro.container.stageTracker,
          url: `/container/stage-tracker/all`,
        }}
        queryKeyToInvalidate={queryKeys.cerebro.container.stageTracker}
      />
    </div>
  )
}
