import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import Spinner from '../Spinner/Spinner'
import { useHistory } from 'react-router-dom'
import axios from 'axios'
import config from '../../config'

export default function LoginForm({ updateIsLogin }) {
  let history = useHistory()
  const [loading, setLoading] = useState(false)
  const [loginError, setLoginError] = useState(false)

  useEffect(() => {
    document.body.setAttribute('data-class', 'login-container')
    return () => {
      document.body.removeAttribute('data-class')
    }
  }, [])

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm()

  const onSubmit = (formData) => {
    setLoading(true)

    const headers = {
      'Content-Type': 'text/javascript',
    }

    const url = config.api.baseUrl + config.api.login
    axios
      .post(
        url,
        {
          userName: formData.userName,
          password: formData.password,
        },
        headers
      )
      .then(function (response) {
        const data = response.data //mockData; //
        localStorage.token = response.data.jwtToken
        localStorage.data = JSON.stringify(data)

        history.push('/dashboard')

        updateIsLogin(true)
        setLoading(false)
      })
      .catch(function (error) {
        localStorage.removeItem('token')
        localStorage.removeItem('data')
        updateIsLogin(false)
        setLoading(false)
        setLoginError(true)
      })
  }

  return (
    <div className="">
      <div className="container">
        <div className="row">
          <div className="col-md-6">
            <h1>Cerebro </h1>
            <h2>Login</h2>
          </div>
          <div className="col-md-6">
            <div className="login-wpr">
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className="mb-4">
                  <input
                    placeholder="User Id"
                    id="userName"
                    type="text"
                    name="userName"
                    className={
                      errors?.userName
                        ? 'form-control is-invalid'
                        : 'form-control'
                    }
                    {...register('userName', {
                      required: true,
                      minLength: 1,
                    })}
                    maxLength="15"
                  />

                  {errors?.userName && (
                    <p className="invalid-feedback">
                      Please enter a valid userid
                    </p>
                  )}
                </div>

                <div className="mb-4">
                  <input
                    placeholder="Password"
                    id="password"
                    type="password"
                    name="password"
                    className={
                      errors?.password
                        ? 'form-control is-invalid'
                        : 'form-control'
                    }
                    {...register('password', {
                      required: true,
                      minLength: 1,
                    })}
                    maxLength="15"
                  />

                  {errors?.password && (
                    <p className="invalid-feedback">Please enter password</p>
                  )}
                </div>
                <div className="form-check mt-3">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    value=""
                    id="flexCheckCheckedDisabled"
                  />
                  <label
                    className="form-check-label text-white"
                    htmlFor="flexCheckCheckedDisabled"
                  >
                    Remember me
                  </label>
                </div>
                <div className="text-center">
                  <button type="submit" className="btn btn-primary mt-4">
                    Login
                  </button>
                </div>
                {loginError && (
                  <div className="text-danger mt-3">
                    Please try with valid credentials.
                  </div>
                )}
                <div className="contact-admin">
                  <p>
                    Having trouble with login please contact{' '}
                    <a href="\#">Admin</a>
                  </p>
                </div>
              </form>
              {loading && <Spinner />}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
