import { Fragment } from 'react'
import ReactTooltip from 'react-tooltip'

export const tooltip = (printText, tipId, place, className = '') => {
  const arr = typeof printText === 'string' && printText.split('\n')
  return (
    <ReactTooltip
      id={tipId}
      type="light"
      place={place}
      className={className}
      border={true}
      borderColor="black"
      multiline={true}
      effect="solid"
      delayHide={500}
      delayShow={500}
      delayUpdate={500}
    >
      <Fragment>
        {typeof printText === 'string'
          ? arr.map((i, key) => {
              return (
                <Fragment key={key}>
                  <div>{i}</div>
                  {key + 1 !== arr.length && <br />}
                </Fragment>
              )
            })
          : printText}
      </Fragment>
    </ReactTooltip>
  )
}
