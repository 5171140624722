import DataFormTable from '../../modules/cerebro/customer/data-management/components/DataFormTable/DataFormTable'

const transportDataMgmt = [
  {
    name: 'Driver Type',
    path: '/customer/dataManagement/driver_type',
    component: DataFormTable,
    staticProps: {
      formName: 'Driver Type',
      module: 'customer',
      subModule: 'driver_type',
      uniqueReferenceKey: 'id',
      lookupKeyName: 'driverTypes',
    },
    includeHeaderFooter: true,
  },
  {
    name: 'Equipment Type',
    path: '/customer/dataManagement/equipment_Type',
    component: DataFormTable,
    staticProps: {
      formName: 'Equipment Type',
      module: 'customer',
      subModule: 'equipment_Type',
      uniqueReferenceKey: 'id',
      lookupKeyName: 'equipmentTypes',
    },
    includeHeaderFooter: true,
  },
  {
    name: 'Trailer Type',
    path: '/customer/dataManagement/trailer_type',
    component: DataFormTable,
    staticProps: {
      formName: 'Trailer Type',
      module: 'customer',
      subModule: 'trailer_type',
      uniqueReferenceKey: 'id',
      lookupKeyName: 'trailerTypes',
    },
    includeHeaderFooter: true,
  },
  {
    name: 'Truck Type',
    path: '/customer/dataManagement/truck_type',
    component: DataFormTable,
    staticProps: {
      formName: 'Truck Type',
      module: 'customer',
      subModule: 'truck_type',
      uniqueReferenceKey: 'id',
      lookupKeyName: 'truckTypes',
    },
    includeHeaderFooter: true,
  },
]

export { transportDataMgmt }
