import React, { useEffect, useState } from 'react'
import { useParams, useHistory } from 'react-router-dom'
import fetchService from '../../../components/utils/fetchService'
import config from '../../../config'
import * as Toastify from '../../../components/utils/Toastify'
import Table from '../../../components/Common/Tanstack-table/Table'
import { createColumnHelper } from '@tanstack/react-table'
import { updateOfcStatus } from './utils'
import { fetchOrderFinancials } from '../../../store/actions/invoice-actions'
import { useDispatch } from 'react-redux'
import startCase from 'lodash/startCase'

export default function EditOFCs() {
  const { ofcId, mode } = useParams()
  const { push } = useHistory()
  const dispatch = useDispatch()
  const columnHelper = createColumnHelper()
  const [ofcContainerFinancials, setOfcContainerFinancials] = useState({
    referenceNo: '',
    orderNo: '',
    ofcOrderContainers: [],
  })

  useEffect(() => {
    if (mode !== 'edit' && mode !== 'view') {
      push('/invoice-page-not-found?fallbackUrl=/invoice-viewAllOFCs')
    }
  }, [mode])

  useEffect(() => {
    const fetchContainerFinancials = async () => {
      try {
        const data = await fetchService({
          url: `${config.api.invoice.ofcContainerFinancials}${ofcId}`,
        })
        setOfcContainerFinancials(data)
      } catch (err) {
        console.error(
          'Some error occurred while fetching Order Financial Container Details.',
          err
        )
        Toastify.Error(
          'Some error occurred while fetching Order Financial Container Details.'
        )
      }
    }

    fetchContainerFinancials()
  }, [ofcId])

  const approveOfcStatus = () => {
    const res = updateOfcStatus([Number(ofcId)], 'APPROVED')
    if (res) {
      dispatch(fetchOrderFinancials())
    }
  }

  const ofcOrderContainerLoadsTableColumns = [
    columnHelper.accessor('containerLoadNo', {
      id: 'containerLoadNo',
      header: () => <span>Container Load No.</span>,
    }),
    columnHelper.accessor('rateCode', {
      id: 'rateCode',
      header: () => <span>Rate Code</span>,
    }),
    columnHelper.accessor('size', {
      id: 'size',
      header: () => <span>Size</span>,
    }),
    columnHelper.accessor('description', {
      id: 'description',
      header: () => <span>Description</span>,
    }),
    columnHelper.accessor('quantity', {
      id: 'quantity',
      header: () => <span>Quantity</span>,
    }),
    columnHelper.accessor('billingUnit', {
      id: 'billingUnit',
      header: () => <span>Billing Unit</span>,
    }),
    columnHelper.accessor('unitPrice', {
      id: 'unitPrice',
      header: () => <span>Unit Price</span>,
    }),
    columnHelper.accessor('remarks', {
      id: 'remarks',
      header: () => <span>Remarks</span>,
    }),
    columnHelper.accessor('amount', {
      id: 'amount',
      header: () => <span>Amount</span>,
    }),
  ]

  return (
    <div className="edit-OFC-wrapper">
      <header>
        <h1>{startCase(mode)} Order Financials Card (OFC)</h1>
      </header>
      <main>
        <h3>
          Customer Reference Number : {ofcContainerFinancials?.referenceNo}
        </h3>
        <h4>Order No. : {ofcContainerFinancials?.orderNo}</h4>
        <h4>Status : {ofcContainerFinancials?.ofcStatus}</h4>
        <div className="border">
          {ofcContainerFinancials.ofcOrderContainers.map((container, index) => {
            const {
              containerNo,
              orderContainerNo,
              ofcOrderContainerLoads,
              total,
            } = container
            return (
              <div key={containerNo} className="container-financial-container">
                {index > 0 ? <hr /> : null}
                <div className="d-flex">
                  <p>Container No. :- {containerNo}</p>
                  <p className="ms-3">
                    Order Container No. :- {orderContainerNo}
                  </p>
                </div>
                <Table
                  columns={ofcOrderContainerLoadsTableColumns}
                  data={ofcOrderContainerLoads}
                  dontShowFilters
                  showGlobalSearch={false}
                  showTableBottom={false}
                  inheritTableWidth
                />
                <div className="d-flex">
                  <div className="m-2 ms-auto">
                    <p>Total :- {total}</p>
                    {index ===
                    ofcContainerFinancials.ofcOrderContainers.length - 1 ? (
                      <p>Gross :- {ofcContainerFinancials?.gross}</p>
                    ) : null}
                  </div>
                  {/* <span className="ms-auto">Gross</span> */}
                </div>
              </div>
            )
          })}
          {mode === 'edit' ? (
            <div className="d-flex">
              <button
                type="button"
                className="btn btn-primary m-2 ms-auto"
                onClick={approveOfcStatus}
                disabled={ofcContainerFinancials?.ofcStatus !== 'PENDING'}
                title="Approve OFC"
              >
                {ofcContainerFinancials?.ofcStatus === 'PENDING'
                  ? 'APPROVED OFC'
                  : ofcContainerFinancials?.ofcStatus}
              </button>
            </div>
          ) : null}
        </div>
      </main>
    </div>
  )
}
