export const formData = [
  {
    name: 'Order No.',
    label: 'Order No.',
    type: 'text',
    validation: 'Alphabetic',
    fieldId: 'orderId',
    id: 'orderId',
  },
  {
    name: 'Container Order Number',
    label: 'Container Order Number',
    type: 'text',
    validation: 'Alphabetic',
    fieldId: 'containerOrderNumber',
    id: 'containerOrderNumber',
  },
  {
    name: 'Container No',
    label: 'Container No',
    type: 'text',
    validation: 'Alphabetic',
    fieldId: 'containerNo',
    id: 'containerNo',
  },
  {
    name: 'Stage',
    label: 'Stage',
    type: 'text',
    validation: 'Alphabetic',
    fieldId: 'stage',
    id: 'stage',
  },
  {
    name: 'container Load No',
    label: 'container Load No',
    type: 'text',
    validation: 'Alphabetic',
    fieldId: 'containerLoadNumber',
    id: 'containerLoadNumber',
  },
  {
    name: 'Customer Name',
    label: 'Customer Name',
    type: 'text',
    validation: 'Alphabetic',
    fieldId: 'customerName',
    id: 'customerName',
  },
  {
    name: 'OBL',
    label: 'OBL',
    type: 'text',
    validation: 'Alphabetic',
    fieldId: 'obl',
    id: 'obl',
  },
  {
    name: 'Port Ref',
    label: 'Port Ref',
    type: 'text',
    validation: 'Alphabetic',
    fieldId: 'portRef',
    id: 'portRef',
  },
  {
    name: 'Port Slot Date',
    label: 'Port Slot Date',
    type: 'text',
    validation: 'Alphabetic',
    fieldId: 'portSlotDate',
    id: 'portSlotDate',
  },
  {
    name: 'Port Slot Time',
    label: 'Port Slot Time',
    type: 'text',
    validation: 'Alphabetic',
    fieldId: 'portSlotTime',
    id: 'portSlotTime',
  },
  {
    name: 'Release No',
    label: 'Release No',
    type: 'text',
    validation: 'Alphabetic',
    fieldId: 'releaseNo',
    id: 'releaseNo',
  },
  {
    name: 'Size Type',
    label: 'Size Type',
    type: 'text',
    validation: 'Alphabetic',
    fieldId: 'sizeType',
    id: 'sizeType',
  },
]
