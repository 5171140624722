import { useMutation, useQueryClient } from '@tanstack/react-query'
import React, { useEffect, useState } from 'react'
import { Spinner } from 'react-bootstrap'
import { toast } from 'react-toastify'
import fetchService from '../../../../../components/utils/fetchService'
import { transitModeOptions } from '../data'

export default function TransitModeOptionCell({ cell }) {
  const queryClient = useQueryClient()
  const { orderContainerDetailsId } = cell.row.original
  const [input, setInput] = useState('')

  useEffect(() => {
    setInput(cell.row.original?.transitMode || '')
  }, [cell])

  const { mutate, isPending } = useMutation({
    mutationFn: async (mode) =>
      await fetchService({
        body: {},
        url: `/operations/order/container/update-transit-mode/${orderContainerDetailsId}?transitMode=${mode}`,
        method: 'POST',
      }),
    onMutate: (mode) => {
      setInput(mode)
    },
    onSuccess: async () => {
      try {
        await queryClient.invalidateQueries({
          queryKey: ['portSlot', 'list'],
        })
      } catch (err) {
        console.log(
          'Error occurred while invalidating query for port slot with form Data',
          err
        )
      }
    },
    onError: () => {
      setInput('')
      toast.error('Transit mode cannot be updated')
    },
  })

  const handleOnChange = async (e) => {
    if (!orderContainerDetailsId) {
      toast.error('Order container details Id not found!')
    } else mutate(e.target.value)
  }

  return (
    <div className="h-100 d-flex align-items-center">
      {isPending ? (
        <Spinner />
      ) : (
        <select
          value={input}
          onChange={handleOnChange}
          className="border-0 w-100"
        >
          <option value="" disabled hidden></option>
          {transitModeOptions.map((v) => (
            <option key={v}>{v}</option>
          ))}
        </select>
      )}
    </div>
  )
}
