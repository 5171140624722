import config from '../../../../../config'
import { strings } from '../enums'

const entityData = {
  consignee: {
    name: 'Consignee',
    tabKey: strings.ConsigneeAgreedRates,
    namesApiUrl: (customerCardId) =>
      `${config.api.consigneeNames}${customerCardId}`,
    profileIdKey: 'ccConsigneeAddressId',
    tabs: {
      crd: {
        baseUrl: '/ccConsigneecard/consignee-agreed-rates',
        queryUrl: (entityId) =>
          `/ccConsigneecard/consignee-agreed-rates?ccConsigneeAddressid=${entityId}`,
        uniqueProfileIdKey: 'ccConsigneeAgreedRatesId',
      },
      zone: {
        baseUrl: '/customercard/consignee-address/zone',
        queryUrl: (entityId) =>
          `/customercard/consignee-address/zone?ccConsigneeAddressId=${entityId}`,
        uniqueProfileIdKey: 'ccConsigneeZoneId',
      },
      suburb: {
        baseUrl: '/customercard/consignee-address/suburb-profile',
        queryUrl: (entityId) =>
          `/customercard/consignee-address/suburb-profile?ccConsigneeAddressId=${entityId}`,
        uniqueProfileIdKey: 'ccConsigneeSuburbProfileId',
      },
      cartage: {
        baseUrl: '/customercard/consignee-address/rate/logic',
        queryUrl: (entityId) =>
          `/customercard/consignee-address/rate/logic/${entityId}`,
        uniqueProfileIdKey: 'consigneeRateLogicId',
      },
    },
  },
  shipper: {
    name: 'Shipper',
    tabKey: strings.ShipperAgreedRates,
    namesApiUrl: (customerCardId) =>
      `${config.api.shipperNames}${customerCardId}`,
    profileIdKey: 'ccShipperAddressId',
    tabs: {
      crd: {
        baseUrl: '/customercard/shipper-agreed-rates',
        queryUrl: (entityId) =>
          `/customercard/shipper-agreed-rates?ccShipperAddressid=${entityId}`,
        uniqueProfileIdKey: 'ccShipperAgreedRatesId',
      },
      zone: {
        baseUrl: '/customercard/shipper-address/zone',
        queryUrl: (entityId) =>
          `/customercard/shipper-address/zone?ccShipperAddressId=${entityId}`,
        uniqueProfileIdKey: 'ccShipperZoneId',
      },
      suburb: {
        baseUrl: '/customercard/shipper-address/suburb-profile',
        queryUrl: (entityId) =>
          `/customercard/shipper-address/suburb-profile?ccShipperAddressId=${entityId}`,
        uniqueProfileIdKey: 'ccShipperSuburbProfileId',
      },
      cartage: {
        baseUrl: '/shippercard/shipper-address/rate/logic',
        queryUrl: (entityId) =>
          `/shippercard/shipper-address/rate/logic/${entityId}`,
        uniqueProfileIdKey: 'shipperRateLogicId',
      },
    },
  },
}

export default entityData
