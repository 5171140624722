import CargoDetails from './CargoDetails'
import { Modal } from 'react-bootstrap'

const CargoVerifyModal = ({
  cargoDetails,
  defaultValues,
  setShowCargoVerifyModal,
  getWms,
}) => {
  return (
    <Modal
      show={true}
      onHide={() => setShowCargoVerifyModal(false)}
      size="lg"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title>{<div className="fs-3">Cargo Details</div>}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <CargoDetails
          cargoDetails={cargoDetails}
          getWms={getWms}
          defaultValues={defaultValues}
          setShowCargoVerifyModal={setShowCargoVerifyModal}
        />
      </Modal.Body>
    </Modal>
  )
}

export default CargoVerifyModal
