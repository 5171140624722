import { useState, useEffect } from 'react'

import CustomerAgreedRatesTable from './CustomerAgreedRatesTable'
import AddCustomerAgreedRatesForm from './AddCustomerAgreedRatesForm'
import startCase from 'lodash/startCase'
import config from '../../../../config'
import useModalContainer from '../../../../helper/hooks/useModalContainer'

const CustomerAgreedRates = (props) => {
  const {
    customerAgreedRates,
    setCustomerAgreedRates,
    lookupsList,
    customerCardId,
    type,
  } = props
  const [consigneeData, setConsigneeData] = useState([])
  const [customerAgreedRateData, setCustomerAgreedRateData] = useState(null)
  const [customerAgreedRateIdx, setCustomerAgreedRateIdx] = useState(null)
  const [formActionType, setFormActionType] = useState(null)

  useEffect(() => {
    const consigneeUrl =
      config.api.baseUrl +
      '/customercard/consignee-address/?customerCardId=' +
      customerCardId

    const requestOptions = {
      method: 'GET',
      headers: { Authorization: 'Bearer ' + localStorage.token },
    }
    fetch(consigneeUrl, requestOptions)
      .then((response) => response.json())
      .then((data) => {
        setConsigneeData(data)
      })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleAddCustomerAgreedRates = () => {
    setFormActionType('add')
  }

  const { ModalContainer } = useModalContainer({
    text: 'Add Customer Agreed Rate',
    onCloseCallback: () => setFormActionType(null),
    body: (
      <AddCustomerAgreedRatesForm
        cancelAction={setFormActionType.bind(this, null)}
        lookupsList={{
          ...lookupsList,
          consignee: consigneeData,
        }}
        customerCardId={customerCardId}
        setCustomerAgreedRates={setCustomerAgreedRates}
        defaultData={
          formActionType === 'import' && {
            ...customerAgreedRateData,
            customerCardId,
          }
        }
        formActionType={formActionType}
        type={type}
        customerAgreedRateIdx={customerAgreedRateIdx}
      />
    ),
    btnClass: 'btn btn-primary mx-1 my-3',
    onShow: handleAddCustomerAgreedRates,
    header: <>{startCase(formActionType)} Customer Agreed Rates Form</>,
  })

  return (
    <div className="customer-agreed-rates">
      {customerAgreedRates.length > 0 ? (
        <CustomerAgreedRatesTable
          csvArray={customerAgreedRates}
          setCsvArray={setCustomerAgreedRates}
          setAgreedRateData={setCustomerAgreedRateData}
          setAgreedRateIdx={setCustomerAgreedRateIdx}
          setFormActionType={setFormActionType}
          type={type}
        />
      ) : (
        <p>No customer agreed rates found!</p>
      )}
      {ModalContainer}
    </div>
  )
}

export default CustomerAgreedRates
