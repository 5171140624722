import { useQuery } from '@tanstack/react-query'
import fetchService from '../../../../components/utils/fetchService'

export default function useDrivers() {
  const url = '/dashboard/driver/all'

  return useQuery({
    queryKey: ['transport', 'drivers-list'],
    queryFn: async () => await fetchService({ url }),
  })
}
