import React from 'react'
import { getFormattedTimestampForMessage } from '../../utils'

export default function MessageItem({ message }) {
  const { body, createdDate, senderId } = message
  const {
    userDetails: { id },
  } = JSON.parse(localStorage.data)

  const userIs = senderId === id ? 'sender' : 'receiver'
  return (
    <div className="row m-0 p-0">
      <div className={`col-sm-12 message-main-${userIs}`}>
        <div className={userIs}>
          <div className="message-text">{body}</div>
          <span className="message-time">
            {getFormattedTimestampForMessage(createdDate)}
          </span>
        </div>
      </div>
    </div>
  )
}
