import { useState } from 'react'
import { faPencil } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

// import Spinner from '../../../Spinner/Spinner'
import Spinner from '../../../../components/Spinner/Spinner'
import Table from "../../../../components/Common/Tanstack-table/Table"
import IndeterminateCheckbox from '../../../../components/Common/Tanstack-table/components/IndeterminateCheckbox'

import {ApprovedPageForm, UplDetailsModal} from '../SubComponents'

const Approved = ({
  approvedData,
  setUplDetails,
  uplDetails,
  getWms,
  cargoDetailsTable,
  unpackedTableColumns,
}) => {
  const [showUPLDetailsModal, setShowUPLDetailsModal] = useState(false)
  const [loading, setLoading] = useState(false)

  const showUplDetails = (details) => {
    setUplDetails(details)
    setShowUPLDetailsModal(true)
  }
  /* replacing the first column to add UPL view edit icon in table. */
  unpackedTableColumns.splice(0, 1, {
    id: 'select',
    header: ({ table }) => (
      <IndeterminateCheckbox
        {...{
          checked: table.getIsAllRowsSelected(),
          indeterminate: table.getIsSomeRowsSelected(),
          onChange: table.getToggleAllRowsSelectedHandler(),
        }}
      />
    ),
    cell: ({ row }) => (
      <div className="px-1">
        <div className="d-flex">
          <IndeterminateCheckbox
            {...{
              checked: row.getIsSelected(),
              indeterminate: row.getIsSomeSelected(),
              onChange: row.getToggleSelectedHandler(),
            }}
          />
          <button
            className="btn m-0 p-0 "
            onClick={() => {
              showUplDetails(row.original)
            }}
          >
            <FontAwesomeIcon icon={faPencil} className="ps-1" />
          </button>
        </div>
        {row.getIsExpanded() && row.original.jobNumber}
      </div>
    ),
  })
  return (
    <div className="columnData customiseTable">
      {loading && <Spinner />}
      <Table
        columns={[...unpackedTableColumns]}
        data={approvedData}
        // setSelectedRows={setSelectedUnplannedLoads}
        // draggable={false}
        nestedTableDataId="orderContainerCargoDetails"
        renderSubComponent={cargoDetailsTable}
        getRowCanExpand={() => true}
      />
      {showUPLDetailsModal && (
        <UplDetailsModal
          uplDetails={uplDetails}
          setShowUPLDetailsModal={setShowUPLDetailsModal}
        >
          <ApprovedPageForm
            wmsDetailsId={uplDetails.wmsDetailsId}
            defaultValues={{
              ...{ clientExpDate: uplDetails?.expClientDate?.slice(0, 10) },
            }}
            setLoading={setLoading}
            getWms={getWms}
            setShowUPLDetailsModal={setShowUPLDetailsModal}
          />
        </UplDetailsModal>
      )}
    </div>
  )
}

export default Approved
