import moment from 'moment'
import * as yup from 'yup'

export const driverFormData = [
  {
    tabId: 'general',
    displayName: 'General',
    uniqueReferenceKey: 'driverId',
    formFields: [
      {
        name: 'Driver ID',
        label: 'Driver ID',
        type: 'text',
        validation: 'Alphabetic',
        fieldId: 'driverId',
        id: 'driverId',
      },
      {
        name: 'Driver Name',
        label: 'Driver Name',
        type: 'text',
        validation: 'Alphabetic',
        fieldId: 'driverName',
        id: 'driverName',
        validationType: 'string',
        validations: [
          {
            type: 'required',
            params: ['##FIELD_NAME## is required'],
            nullable: true,
          },
          {
            type: 'max',
            params: [20, '##FIELD_NAME## cannot be more than 20 characters'],
          },
        ],
      },
      {
        name: 'Surname',
        label: 'Surname',
        type: 'text',
        validation: 'Alphabetic',
        fieldId: 'surName',
        id: 'surName',
        validationType: 'string',
        validations: [
          {
            type: 'max',
            params: [20, '##FIELD_NAME## cannot be more than 20 characters'],
          },
        ],
      },
      {
        name: 'Upload Image',
        label: 'Upload Image',
        type: 'file',
        validation: 'Alphabetic',
        fieldId: 'profilePic',
        id: 'profilePic',
        fileType: 'image/*',
        // hide: true,
        hideInViewMode: true,
      },
      {
        name: 'Gender',
        label: 'Gender',
        type: 'select',
        validation: 'Alphabetic',
        fieldId: 'gender',
        id: 'gender',
        options: [
          {
            id: 'male',
            label: 'Male',
          },
          {
            id: 'female',
            label: 'Female',
          },
          {
            id: 'other',
            label: 'Other',
          },
        ],
        validationType: 'string',
        validations: [
          {
            type: 'required',
            params: ['##FIELD_NAME## is required'],
            nullable: true,
          },
        ],
      },
      {
        name: 'Date of Birth',
        label: 'Date of Birth',
        type: 'date',
        validation: 'Alphabetic',
        fieldId: 'dateOfBirth',
        id: 'dateOfBirth',
        validationType: 'string',
        max: moment().format('YYYY-MM-DD'),
        validations: [
          {
            type: 'required',
            params: ['##FIELD_NAME## is required'],
          },
        ],
      },
      {
        name: 'Position',
        label: 'Position',
        type: 'text',
        validation: 'Alphabetic',
        fieldId: 'position',
        id: 'position',
        validationType: 'string',
        validations: [
          {
            type: 'optional',
            params: [],
            nullable: true,
          },
          {
            type: 'max',
            params: [20, '##FIELD_NAME## cannot be more than 20 characters'],
          },
        ],
      },
      {
        name: 'Department',
        label: 'Department',
        type: 'text',
        validation: 'Alphabetic',
        fieldId: 'department',
        id: 'department',
        validationType: 'string',
        validations: [
          {
            type: 'optional',
            params: [],
            nullable: true,
          },
          {
            type: 'max',
            params: [20, '##FIELD_NAME## cannot be more than 20 characters'],
          },
        ],
      },
      {
        name: 'Company Branch',
        label: 'Company Branch',
        type: 'text',
        validation: 'Alphabetic',
        fieldId: 'companyBranch',
        id: 'companyBranch',
        validationType: 'string',
        validations: [
          {
            type: 'optional',
            params: [],
            nullable: true,
          },
          {
            type: 'max',
            params: [20, '##FIELD_NAME## cannot be more than 20 characters'],
          },
        ],
      },
      {
        name: 'Vendor',
        label: 'Vendor',
        type: 'searchable-select',
        loadLookUp: 'vendorCodes',
        validation: 'Alphabetic',
        fieldId: 'vendor',
        id: 'vendor',
        validationType: 'string',
        validations: [
          {
            type: 'optional',
            params: [],
            nullable: true,
          },
        ],
      },
      {
        name: 'Start Date',
        label: 'Start Date',
        type: 'date',
        validation: 'Alphabetic',
        fieldId: 'startDate',
        id: 'startDate',
        validationType: 'date',
        validations: [
          {
            type: 'required',
            params: ['##FIELD_NAME## is required'],
            nullable: true,
          },
        ],
      },
      {
        name: 'End Date',
        label: 'End Date',
        type: 'date',
        validation: 'Alphabetic',
        fieldId: 'endDate',
        id: 'endDate',
        validationType: 'date',
        validations: [
          {
            type: 'min',
            params: [
              yup.ref('startDate'),
              '##FIELD_NAME## should be greater than Start Date',
            ],
            nullable: true,
          },
        ],
      },
      {
        name: 'Address',
        label: 'Address',
        type: 'textarea',
        validation: 'Alphabetic',
        fieldId: 'address',
        id: 'address',
        validationType: 'string',
        validations: [
          {
            type: 'required',
            params: ['##FIELD_NAME## is required'],
          },
          {
            type: 'max',
            params: [200, '##FIELD_NAME## cannot be more than 200 characters'],
          },
        ],
      },
      {
        name: 'Post Code',
        label: 'Post Code',
        type: 'text',
        validation: 'Alphabetic',
        fieldId: 'postCode',
        id: 'postCode',
        validationType: 'string',
        validations: [
          {
            type: 'required',
            params: ['##FIELD_NAME## is required'],
            nullable: true,
          },
          {
            type: 'max',
            params: [25, '##FIELD_NAME## cannot be more than 25 characters'],
          },
        ],
      },
      {
        name: 'City Suburb',
        label: 'City Suburb',
        type: 'text',
        validation: 'Alphabetic',
        fieldId: 'citySuburb',
        id: 'citySuburb',
        validationType: 'string',
        validations: [
          {
            type: 'required',
            params: ['##FIELD_NAME## is required'],
            nullable: true,
          },
          {
            type: 'max',
            params: [25, '##FIELD_NAME## cannot be more than 25 characters'],
          },
        ],
      },
      {
        name: 'State',
        label: 'State',
        type: 'text',
        validation: 'Alphabetic',
        fieldId: 'state',
        id: 'state',
        validationType: 'string',
        validations: [
          {
            type: 'required',
            params: ['##FIELD_NAME## is required'],
            nullable: true,
          },
          {
            type: 'max',
            params: [25, '##FIELD_NAME## cannot be more than 25 characters'],
          },
        ],
      },
      {
        name: 'Country',
        label: 'Country',
        type: 'text',
        validation: 'Alphabetic',
        fieldId: 'country',
        id: 'country',
        validationType: 'string',
        validations: [
          {
            type: 'required',
            params: ['##FIELD_NAME## is required'],
            nullable: true,
          },
          {
            type: 'max',
            params: [25, '##FIELD_NAME## cannot be more than 25 characters'],
          },
        ],
      },
      {
        name: 'Contact No',
        label: 'Contact No',
        type: 'text',
        validation: 'Alphabetic',
        fieldId: 'contactNo',
        id: 'contactNo',
        validationType: 'string',
        validations: [
          {
            type: 'required',
            params: ['##FIELD_NAME## is required'],
            nullable: true,
          },
          {
            type: 'max',
            params: [10, '##FIELD_NAME## cannot be more than 10 characters'],
          },
        ],
      },
      {
        name: 'Email Address',
        label: 'Email Address',
        type: 'text',
        validation: 'Alphabetic',
        fieldId: 'emailAddress',
        id: 'emailAddress',
        validationType: 'string',
        validations: [
          {
            type: 'required',
            params: ['##FIELD_NAME## is required'],
            nullable: true,
          },
          {
            type: 'email',
            params: ['Must be a valid ##FIELD_NAME##'],
          },
          {
            type: 'max',
            params: [100, '##FIELD_NAME## cannot be more than 100 characters'],
          },
        ],
      },
      {
        name: 'Driver Rate ID',
        label: 'Driver Rate ID',
        type: 'text',
        validation: 'Alphabetic',
        fieldId: 'driverRateId',
        id: 'driverRateId',
        validationType: 'string',
        validations: [
          {
            type: 'optional',
            params: [],
            nullable: true,
          },
          {
            type: 'max',
            params: [20, '##FIELD_NAME## cannot be more than 20 characters'],
          },
        ],
      },
      {
        name: 'Medical Due Next',
        label: 'Medical Due Next',
        type: 'date',
        validation: 'Alphabetic',
        fieldId: 'medicalDueNext',
        id: 'medicalDueNext',
        validationType: 'string',
        validations: [
          {
            type: 'required',
            params: ['##FIELD_NAME## is required'],
            nullable: true,
          },
        ],
      },
      {
        name: 'Current Location',
        label: 'Current Location',
        type: 'text',
        validation: 'Alphabetic',
        fieldId: 'currentLocation',
        id: 'currentLocation',
        validationType: 'string',
        validations: [
          {
            type: 'optional',
            params: [],
            nullable: true,
          },
          {
            type: 'max',
            params: [100, '##FIELD_NAME## cannot be more than 100 characters'],
          },
        ],
      },
      {
        name: 'Passport No.',
        label: 'Passport No.',
        type: 'text',
        validation: 'Alphabetic',
        fieldId: 'passportNo',
        id: 'passportNo',
        validationType: 'string',
        validations: [
          {
            type: 'required',
            params: ['##FIELD_NAME## is required'],
            nullable: true,
          },
          {
            type: 'max',
            params: [15, '##FIELD_NAME## cannot be more than 15 characters'],
          },
        ],
      },
      {
        name: 'Passport Expiry Date',
        label: 'Passport Expiry Date',
        type: 'date',
        validation: 'Alphabetic',
        fieldId: 'passportExpireDate',
        id: 'passportExpireDate',
        validationType: 'string',
        validations: [
          {
            type: 'required',
            params: ['##FIELD_NAME## is required'],
            nullable: true,
          },
        ],
      },
      {
        name: 'Remarks',
        label: 'Remarks',
        type: 'textarea',
        validation: 'Alphabetic',
        fieldId: 'remarks',
        id: 'remarks',
        validationType: 'string',
        validations: [
          {
            type: 'optional',
            params: [],
            nullable: true,
          },
          {
            type: 'max',
            params: [200, '##FIELD_NAME## cannot be more than 200 characters'],
          },
        ],
      },
      {
        name: 'Tracking ID',
        label: 'Tracking ID',
        type: 'text',
        validation: 'Alphabetic',
        fieldId: 'webFleetId',
        id: 'webFleetId',
        validationType: 'string',
        validations: [
          {
            type: 'required',
            params: ['##FIELD_NAME## is required'],
          },
          {
            type: 'max',
            params: [100, '##FIELD_NAME## cannot be more than 100 characters'],
          },
        ],
      },
      {
        name: 'ST Code',
        label: 'ST Code',
        type: 'text',
        validation: 'Alphabetic',
        fieldId: 'stCode',
        id: 'stCode',
        validationType: 'string',
        validations: [
          {
            type: 'required',
            params: ['##FIELD_NAME## is required'],
          },
          {
            type: 'max',
            params: [100, '##FIELD_NAME## cannot be more than 100 characters'],
          },
        ],
      },
      {
        name: 'Driver Shift',
        label: 'Driver Shift',
        type: 'select',
        validation: 'Alphabetic',
        fieldId: 'shift',
        id: 'shift',
        loadLookUp: 'driverShiftEnums',
        validationType: 'string',
        valueasId: true,
        validations: [
          {
            type: 'optional',
            params: [''],
          },
        ],
        options: [],
      },
      {
        name: 'License Number',
        label: 'License Number',
        type: 'text',
        validation: 'Alphabetic',
        fieldId: 'licenseNumber',
        id: 'licenseNumber',
        validationType: 'string',
        valueasId: true,
        validations: [
          {
            type: 'required',
            params: ['##FIELD_NAME## is required'],
          },
          {
            type: 'max',
            params: [30, '##FIELD_NAME## cannot be more than 30 characters'],
          },
        ],
        options: [],
      },
    ],
    isParentTab: true,
  },
  // {
  //   tabId: 'identification-docs',
  //   displayName: 'Identification Docs',
  //   uniqueReferenceKey: 'driverIdentificationId',
  //   url: '/transports/driver/identification',
  //   attachmentFieldId: 'identificationAttachmentsId',
  //   attachmentFieldKeyName: 'driverIdentificationAttachments',
  //   formFields: [
  //     {
  //       name: 'Id',
  //       label: 'Id',
  //       type: 'text',
  //       validation: 'Alphabetic',
  //       fieldId: 'driverIdentificationId',
  //       id: 'driverIdentificationId',
  //     },
  //     {
  //       name: 'License Type',
  //       label: 'License Type',
  //       type: 'text',
  //       validation: 'Alphabetic',
  //       fieldId: 'licenseType',
  //       id: 'licenseType',
  //       validationType: 'string',
  //       validations: [
  //         {
  //           type: 'required',
  //           params: ['##FIELD_NAME## is required'],
  //           nullable: true,
  //         },
  //         {
  //           type: 'max',
  //           params: [10, '##FIELD_NAME## cannot be more than 10 characters'],
  //         },
  //       ],
  //     },
  //     {
  //       name: 'Description',
  //       label: 'Description',
  //       type: 'textarea',
  //       validation: 'Alphabetic',
  //       fieldId: 'description',
  //       id: 'description',
  //       validationType: 'string',
  //       validations: [
  //         {
  //           type: 'optional',
  //           params: [],
  //           nullable: true,
  //         },
  //         {
  //           type: 'max',
  //           params: [20, '##FIELD_NAME## cannot be more than 20 characters'],
  //         },
  //       ],
  //     },
  //     {
  //       name: 'Class',
  //       label: 'Class',
  //       type: 'text',
  //       validation: 'Alphabetic',
  //       fieldId: 'docsClass',
  //       id: 'docsClass',
  //       validationType: 'string',
  //       validations: [
  //         {
  //           type: 'optional',
  //           params: [],
  //           nullable: true,
  //         },
  //         {
  //           type: 'max',
  //           params: [10, '##FIELD_NAME## cannot be more than 10 characters'],
  //         },
  //       ],
  //     },
  //     {
  //       name: 'License No',
  //       label: 'License No',
  //       type: 'text',
  //       validation: 'Alphabetic',
  //       fieldId: 'licenseNo',
  //       id: 'licenseNo',
  //       validationType: 'string',
  //       validations: [
  //         {
  //           type: 'required',
  //           params: ['##FIELD_NAME## is required'],
  //           nullable: true,
  //         },
  //         {
  //           type: 'max',
  //           params: [10, '##FIELD_NAME## cannot be more than 10 characters'],
  //         },
  //       ],
  //     },
  //     {
  //       name: 'Issue Date',
  //       label: 'Issue Date',
  //       type: 'date',
  //       validation: 'Alphabetic',
  //       fieldId: 'issueDate',
  //       id: 'issueDate',
  //       validationType: 'string',
  //       validations: [
  //         {
  //           type: 'required',
  //           params: ['##FIELD_NAME## is required'],
  //           nullable: true,
  //         },
  //       ],
  //     },
  //     {
  //       name: 'Expiry Date',
  //       label: 'Expiry Date',
  //       type: 'date',
  //       validation: 'Alphabetic',
  //       fieldId: 'expiryDate',
  //       id: 'expiryDate',
  //       validationType: 'string',
  //       validations: [
  //         {
  //           type: 'required',
  //           params: ['##FIELD_NAME## is required'],
  //           nullable: true,
  //         },
  //       ],
  //     },
  //     {
  //       name: 'License Expiry Date',
  //       label: 'License Expiry Date',
  //       type: 'date',
  //       validation: 'Alphabetic',
  //       fieldId: 'licenseExpiryDate',
  //       id: 'licenseExpiryDate',
  //       validationType: 'string',
  //       validations: [
  //         {
  //           type: 'required',
  //           params: ['##FIELD_NAME## is required'],
  //           nullable: true,
  //         },
  //       ],
  //     },
  //     {
  //       name: 'Cost',
  //       label: 'Cost',
  //       type: 'number',
  //       validation: 'Alphabetic',
  //       fieldId: 'cost',
  //       id: 'cost',
  //       validationType: 'number',
  //       validations: [
  //         {
  //           type: 'optional',
  //           params: [],
  //           nullable: true,
  //         },
  //       ],
  //     },
  //     {
  //       name: 'Restrictions',
  //       label: 'Restrictions',
  //       type: 'textarea',
  //       validation: 'Alphabetic',
  //       fieldId: 'restrictions',
  //       id: 'restrictions',
  //       validationType: 'string',
  //       validations: [
  //         {
  //           type: 'optional',
  //           params: [],
  //           nullable: true,
  //         },
  //         {
  //           type: 'max',
  //           params: [100, '##FIELD_NAME## cannot be more than 100 characters'],
  //         },
  //       ],
  //     },
  //     {
  //       name: 'Attachments',
  //       label: 'Attachments',
  //       type: 'file',
  //       multiple: true,
  //       validation: 'Alphabetic',
  //       fieldId: 'files',
  //       hideInTableMode: true,
  //       id: 'files',
  //       hideInViewMode: true,
  //     },
  //   ],
  // },
  // {
  //   tabId: 'medical',
  //   displayName: 'Medical',
  //   uniqueReferenceKey: 'driverMedicalId',
  //   url: '/transports/driver/medical',
  //   attachmentFieldId: 'driverMedicalAttachmentsId',
  //   attachmentFieldKeyName: 'driverMedicalAttachments',
  //   formFields: [
  //     {
  //       name: 'Id',
  //       label: 'Id',
  //       type: 'text',
  //       validation: 'Alphabetic',
  //       fieldId: 'driverMedicalId',
  //       id: 'driverMedicalId',
  //     },
  //     {
  //       name: 'Medical No',
  //       label: 'Medical No',
  //       type: 'text',
  //       validation: 'Alphabetic',
  //       fieldId: 'medicalNo',
  //       id: 'medicalNo',
  //       validationType: 'string',
  //       validations: [
  //         {
  //           type: 'optional',
  //           params: [],
  //           nullable: true,
  //         },
  //         {
  //           type: 'max',
  //           params: [20, '##FIELD_NAME## cannot be more than 20 characters'],
  //         },
  //       ],
  //     },
  //     {
  //       name: 'Description',
  //       label: 'Description',
  //       type: 'textarea',
  //       validation: 'Alphabetic',
  //       fieldId: 'description',
  //       id: 'description',
  //       validationType: 'string',
  //       validations: [
  //         {
  //           type: 'optional',
  //           params: [],
  //           nullable: true,
  //         },
  //         {
  //           type: 'max',
  //           params: [30, '##FIELD_NAME## cannot be more than 30 characters'],
  //         },
  //       ],
  //     },
  //     {
  //       name: 'Due Date',
  //       label: 'Due Date',
  //       type: 'date',
  //       validation: 'Alphabetic',
  //       fieldId: 'dueDate',
  //       id: 'dueDate',
  //       validationType: 'string',
  //       validations: [
  //         {
  //           type: 'required',
  //           params: ['##FIELD_NAME## is required'],
  //           nullable: true,
  //         },
  //       ],
  //     },
  //     {
  //       name: 'Cost',
  //       label: 'Cost',
  //       type: 'number',
  //       validation: 'Alphabetic',
  //       fieldId: 'cost',
  //       id: 'cost',
  //       validationType: 'number',
  //       validations: [
  //         {
  //           type: 'optional',
  //           params: [],
  //           nullable: true,
  //         },
  //       ],
  //     },
  //     {
  //       name: 'Condition',
  //       label: 'Condition',
  //       type: 'text',
  //       validation: 'Alphabetic',
  //       fieldId: 'severity',
  //       id: 'severity',
  //       validationType: 'string',
  //       validations: [
  //         {
  //           type: 'optional',
  //           params: [],
  //           nullable: true,
  //         },
  //         {
  //           type: 'max',
  //           params: [30, '##FIELD_NAME## cannot be more than 30 characters'],
  //         },
  //       ],
  //     },
  //     {
  //       name: 'Medical Company',
  //       label: 'Medical Company',
  //       type: 'text',
  //       validation: 'Alphabetic',
  //       fieldId: 'medicalCompany',
  //       id: 'medicalCompany',
  //       validationType: 'string',
  //       validations: [
  //         {
  //           type: 'optional',
  //           params: [],
  //           nullable: true,
  //         },
  //         {
  //           type: 'max',
  //           params: [30, '##FIELD_NAME## cannot be more than 30 characters'],
  //         },
  //       ],
  //     },
  //     {
  //       name: 'Doctor Name',
  //       label: 'Doctor Name',
  //       type: 'text',
  //       validation: 'Alphabetic',
  //       fieldId: 'doctorName',
  //       id: 'doctorName',
  //       validationType: 'string',
  //       validations: [
  //         {
  //           type: 'optional',
  //           params: [],
  //           nullable: true,
  //         },
  //         {
  //           type: 'max',
  //           params: [30, '##FIELD_NAME## cannot be more than 30 characters'],
  //         },
  //       ],
  //     },
  //     {
  //       name: 'Attachments',
  //       label: 'Attachments',
  //       type: 'file',
  //       multiple: true,
  //       validation: 'Alphabetic',
  //       fieldId: 'files',
  //       id: 'files',
  //       hideInViewMode: true,
  //       hideInTableMode: true,
  //     },
  //   ],
  // },
  // {
  //   tabId: 'uniform',
  //   displayName: 'Uniform',
  //   uniqueReferenceKey: 'driverUniformId',
  //   url: '/transports/driver/uniform',
  //   attachmentFieldId: 'driverUniformAttachmentsId',
  //   attachmentFieldKeyName: 'driverUniformAttachments',
  //   formFields: [
  //     {
  //       name: 'Id',
  //       label: 'Id',
  //       type: 'text',
  //       validation: 'Alphabetic',
  //       fieldId: 'driverUniformId',
  //       id: 'driverUniformId',
  //     },
  //     {
  //       name: 'Serial No',
  //       label: 'Serial No',
  //       type: 'text',
  //       validation: 'Alphabetic',
  //       fieldId: 'serialNo',
  //       id: 'serialNo',
  //       validationType: 'string',
  //       validations: [
  //         {
  //           type: 'required',
  //           params: ['##FIELD_NAME## is required'],
  //         },
  //         {
  //           type: 'max',
  //           params: [20, '##FIELD_NAME## cannot be more than 20 characters'],
  //         },
  //       ],
  //     },
  //     {
  //       name: 'Description',
  //       label: 'Description',
  //       type: 'textarea',
  //       validation: 'Alphabetic',
  //       fieldId: 'description',
  //       id: 'description',
  //       validationType: 'string',
  //       validations: [
  //         {
  //           type: 'required',
  //           params: ['##FIELD_NAME## is required'],
  //         },
  //         {
  //           type: 'max',
  //           params: [100, '##FIELD_NAME## cannot be more than 100 characters'],
  //         },
  //       ],
  //     },
  //     {
  //       name: 'Quantity',
  //       label: 'Quantity',
  //       type: 'number',
  //       validation: 'Alphabetic',
  //       fieldId: 'quantity',
  //       id: 'quantity',
  //       validationType: 'number',
  //       validations: [
  //         {
  //           type: 'required',
  //           params: ['##FIELD_NAME## is required'],
  //           nullable: true,
  //         },
  //       ],
  //     },
  //     {
  //       name: 'Size',
  //       label: 'Size',
  //       type: 'text',
  //       validation: 'Alphabetic',
  //       fieldId: 'size',
  //       id: 'size',
  //       validationType: 'string',
  //       validations: [
  //         {
  //           type: 'optional',
  //           params: [],
  //           nullable: true,
  //         },
  //         {
  //           type: 'max',
  //           params: [20, '##FIELD_NAME## cannot be more than 20 characters'],
  //         },
  //       ],
  //     },
  //     {
  //       name: 'Cost',
  //       label: 'Cost',
  //       type: 'number',
  //       validation: 'Alphabetic',
  //       fieldId: 'cost',
  //       id: 'cost',
  //       validationType: 'number',
  //       validations: [
  //         {
  //           type: 'optional',
  //           params: [],
  //           nullable: true,
  //         },
  //       ],
  //     },
  //     {
  //       name: 'Issue Date',
  //       label: 'Issue Date',
  //       type: 'date',
  //       validation: 'Alphabetic',
  //       fieldId: 'issueDate',
  //       id: 'issueDate',
  //       validationType: 'string',
  //       validations: [
  //         {
  //           type: 'optional',
  //           params: [],
  //           nullable: true,
  //         },
  //       ],
  //     },
  //     {
  //       name: 'Return Date',
  //       label: 'Return Date',
  //       type: 'date',
  //       validation: 'Alphabetic',
  //       fieldId: 'returnDate',
  //       id: 'returnDate',
  //       validationType: 'string',
  //       validations: [
  //         {
  //           type: 'optional',
  //           params: [],
  //           nullable: true,
  //         },
  //       ],
  //     },
  //     {
  //       name: 'Remarks',
  //       label: 'Remarks',
  //       type: 'textarea',
  //       validation: 'Alphabetic',
  //       fieldId: 'remarks',
  //       id: 'remarks',
  //       validationType: 'string',
  //       validations: [
  //         {
  //           type: 'optional',
  //           params: [],
  //           nullable: true,
  //         },
  //         {
  //           type: 'max',
  //           params: [200, '##FIELD_NAME## cannot be more than 200 characters'],
  //         },
  //       ],
  //     },
  //     {
  //       name: 'Attachments',
  //       label: 'Attachments',
  //       type: 'file',
  //       multiple: true,
  //       validation: 'Alphabetic',
  //       fieldId: 'files',
  //       hideInTableMode: true,
  //       id: 'files',
  //       hideInViewMode: true,
  //     },
  //   ],
  // },
  // {
  //   tabId: 'permits',
  //   displayName: 'Permit',
  //   uniqueReferenceKey: 'driverPermitId',
  //   url: '/transports/driver/permit',
  //   attachmentFieldId: 'driverPermitAttachmentsId',
  //   attachmentFieldKeyName: 'driverPermitAttachments',
  //   formFields: [
  //     {
  //       name: 'Id',
  //       label: 'Id',
  //       type: 'text',
  //       validation: 'Alphabetic',
  //       fieldId: 'driverPermitId',
  //       id: 'driverPermitId',
  //     },
  //     {
  //       name: 'Serial No',
  //       label: 'Serial No',
  //       type: 'text',
  //       validation: 'Alphabetic',
  //       fieldId: 'serialNo',
  //       id: 'serialNo',
  //       validationType: 'string',
  //       validations: [
  //         {
  //           type: 'required',
  //           params: ['##FIELD_NAME## is required'],
  //         },
  //         {
  //           type: 'max',
  //           params: [10, '##FIELD_NAME## cannot be more than 10 characters'],
  //         },
  //       ],
  //     },
  //     {
  //       name: 'Permit Name',
  //       label: 'Permit Name',
  //       type: 'text',
  //       validation: 'Alphabetic',
  //       fieldId: 'permitName',
  //       id: 'permitName',
  //       validationType: 'string',
  //       validations: [
  //         {
  //           type: 'required',
  //           params: ['##FIELD_NAME## is required'],
  //         },
  //         {
  //           type: 'max',
  //           params: [20, '##FIELD_NAME## cannot be more than 20 characters'],
  //         },
  //       ],
  //     },
  //     {
  //       name: 'Provider',
  //       label: 'Provider',
  //       type: 'text',
  //       validation: 'Alphabetic',
  //       fieldId: 'provider',
  //       id: 'provider',
  //       validationType: 'string',
  //       validations: [
  //         {
  //           type: 'required',
  //           params: ['##FIELD_NAME## is required'],
  //         },
  //         {
  //           type: 'max',
  //           params: [20, '##FIELD_NAME## cannot be more than 20 characters'],
  //         },
  //       ],
  //     },
  //     {
  //       name: 'Permit No',
  //       label: 'Permit No',
  //       type: 'text',
  //       validation: 'Alphabetic',
  //       fieldId: 'permitNo',
  //       id: 'permitNo',
  //       validationType: 'string',
  //       validations: [
  //         {
  //           type: 'optional',
  //           params: [],
  //           nullable: true,
  //         },
  //         {
  //           type: 'max',
  //           params: [20, '##FIELD_NAME## cannot be more than 20 characters'],
  //         },
  //       ],
  //     },
  //     {
  //       name: 'Class',
  //       label: 'Class',
  //       type: 'text',
  //       validation: 'Alphabetic',
  //       fieldId: 'permitClass',
  //       id: 'permitClass',
  //       validationType: 'string',
  //       validations: [
  //         {
  //           type: 'optional',
  //           params: [],
  //           nullable: true,
  //         },
  //         {
  //           type: 'max',
  //           params: [20, '##FIELD_NAME## cannot be more than 20 characters'],
  //         },
  //       ],
  //     },
  //     {
  //       name: 'Issue Date',
  //       label: 'Issue Date',
  //       type: 'date',
  //       validation: 'Alphabetic',
  //       fieldId: 'issueDate',
  //       id: 'issueDate',
  //       validationType: 'string',
  //       validations: [
  //         {
  //           type: 'required',
  //           params: ['##FIELD_NAME## is required'],
  //           nullable: true,
  //         },
  //       ],
  //     },
  //     {
  //       name: 'Expiry Date',
  //       label: 'Expiry Date',
  //       type: 'date',
  //       validation: 'Alphabetic',
  //       fieldId: 'expiryDate',
  //       id: 'expiryDate',
  //       validationType: 'string',
  //       validations: [
  //         {
  //           type: 'required',
  //           params: ['##FIELD_NAME## is required'],
  //           nullable: true,
  //         },
  //       ],
  //     },
  //     {
  //       name: 'Cost',
  //       label: 'Cost',
  //       type: 'number',
  //       validation: 'Alphabetic',
  //       fieldId: 'cost',
  //       id: 'cost',
  //       validationType: 'number',
  //       validations: [
  //         {
  //           type: 'required',
  //           params: ['##FIELD_NAME## is required'],
  //           nullable: true,
  //         },
  //       ],
  //     },
  //     {
  //       name: 'Comment',
  //       label: 'Comment',
  //       type: 'textarea',
  //       validation: 'Alphabetic',
  //       fieldId: 'comment',
  //       id: 'comment',
  //       validationType: 'string',
  //       validations: [
  //         {
  //           type: 'optional',
  //           params: [],
  //           nullable: true,
  //         },
  //         {
  //           type: 'max',
  //           params: [200, '##FIELD_NAME## cannot be more than 200 characters'],
  //         },
  //       ],
  //     },
  //     {
  //       name: 'Attachments',
  //       label: 'Attachments',
  //       type: 'file',
  //       multiple: true,
  //       validation: 'Alphabetic',
  //       fieldId: 'files',
  //       id: 'files',
  //       hideInViewMode: true,
  //       hideInTableMode: true,
  //     },
  //   ],
  // },
  // {
  //   tabId: 'training',
  //   displayName: 'Training',
  //   uniqueReferenceKey: 'driverTrainingId',
  //   url: '/transports/driver/training',
  //   attachmentFieldId: 'driverTrainingAttachmentsId',
  //   attachmentFieldKeyName: 'driverTrainingAttachments',
  //   formFields: [
  //     {
  //       name: 'Id',
  //       label: 'Id',
  //       type: 'text',
  //       validation: 'Alphabetic',
  //       fieldId: 'driverTrainingId',
  //       id: 'driverTrainingId',
  //     },
  //     {
  //       name: 'Certificate No',
  //       label: 'Certificate No',
  //       type: 'text',
  //       validation: 'Alphabetic',
  //       fieldId: 'certificateNo',
  //       id: 'certificateNo',
  //       validationType: 'string',
  //       validations: [
  //         {
  //           type: 'required',
  //           params: ['##FIELD_NAME## is required'],
  //         },
  //         {
  //           type: 'max',
  //           params: [15, '##FIELD_NAME## cannot be more than 15 characters'],
  //         },
  //       ],
  //     },
  //     {
  //       name: 'Certificate Name',
  //       label: 'Certificate Name',
  //       type: 'text',
  //       validation: 'Alphabetic',
  //       fieldId: 'certificateName',
  //       id: 'certificateName',
  //       validationType: 'string',
  //       validations: [
  //         {
  //           type: 'required',
  //           params: ['##FIELD_NAME## is required'],
  //         },
  //         {
  //           type: 'max',
  //           params: [20, '##FIELD_NAME## cannot be more than 20 characters'],
  //         },
  //       ],
  //     },
  //     {
  //       name: 'Description',
  //       label: 'Description',
  //       type: 'textarea',
  //       validation: 'Alphabetic',
  //       fieldId: 'description',
  //       id: 'description',
  //       validationType: 'string',
  //       validations: [
  //         {
  //           type: 'optional',
  //           params: [],
  //           nullable: true,
  //         },
  //         {
  //           type: 'max',
  //           params: [20, '##FIELD_NAME## cannot be more than 20 characters'],
  //         },
  //       ],
  //     },
  //     {
  //       name: 'Training Company',
  //       label: 'Training Company',
  //       type: 'text',
  //       validation: 'Alphabetic',
  //       fieldId: 'trainingCompany',
  //       id: 'trainingCompany',
  //       validationType: 'string',
  //       validations: [
  //         {
  //           type: 'optional',
  //           params: [],
  //           nullable: true,
  //         },
  //         {
  //           type: 'max',
  //           params: [20, '##FIELD_NAME## cannot be more than 20 characters'],
  //         },
  //       ],
  //     },
  //     {
  //       name: 'Training Name',
  //       label: 'Training Name',
  //       type: 'text',
  //       validation: 'Alphabetic',
  //       fieldId: 'trainerName',
  //       id: 'trainerName',
  //       validationType: 'string',
  //       validations: [
  //         {
  //           type: 'optional',
  //           params: [],
  //           nullable: true,
  //         },
  //         {
  //           type: 'max',
  //           params: [20, '##FIELD_NAME## cannot be more than 20 characters'],
  //         },
  //       ],
  //     },
  //     {
  //       name: 'Training Hours',
  //       label: 'Training Hours',
  //       type: 'text',
  //       validation: 'Alphabetic',
  //       fieldId: 'trainingHours',
  //       id: 'trainingHours',
  //       validationType: 'string',
  //       validations: [
  //         {
  //           type: 'optional',
  //           params: [],
  //           nullable: true,
  //         },
  //         {
  //           type: 'max',
  //           params: [5, '##FIELD_NAME## cannot be more than 5 characters'],
  //         },
  //       ],
  //     },
  //     {
  //       name: 'Issue Date',
  //       label: 'Issue Date',
  //       type: 'date',
  //       validation: 'Alphabetic',
  //       fieldId: 'issueDate',
  //       id: 'issueDate',
  //       validationType: 'string',
  //       validations: [
  //         {
  //           type: 'required',
  //           params: ['##FIELD_NAME## is required'],
  //           nullable: true,
  //         },
  //       ],
  //     },
  //     {
  //       name: 'Expiry Date',
  //       label: 'Expiry Date',
  //       type: 'date',
  //       validation: 'Alphabetic',
  //       fieldId: 'expiryDate',
  //       id: 'expiryDate',
  //       validationType: 'string',
  //       validations: [
  //         {
  //           type: 'required',
  //           params: ['##FIELD_NAME## is required'],
  //           nullable: true,
  //         },
  //       ],
  //     },
  //     {
  //       name: 'Cost',
  //       label: 'Cost',
  //       type: 'number',
  //       validation: 'Alphabetic',
  //       fieldId: 'cost',
  //       id: 'cost',
  //       validationType: 'number',
  //       validations: [
  //         {
  //           type: 'optional',
  //           params: [],
  //           nullable: true,
  //         },
  //       ],
  //     },
  //     {
  //       name: 'Comments',
  //       label: 'Comments',
  //       type: 'textarea',
  //       validation: 'Alphabetic',
  //       fieldId: 'comments',
  //       id: 'comments',
  //       validationType: 'string',
  //       validations: [
  //         {
  //           type: 'optional',
  //           params: [],
  //           nullable: true,
  //         },
  //         {
  //           type: 'max',
  //           params: [200, '##FIELD_NAME## cannot be more than 200 characters'],
  //         },
  //       ],
  //     },
  //     {
  //       name: 'Attachments',
  //       label: 'Attachments',
  //       type: 'file',
  //       multiple: true,
  //       validation: 'Alphabetic',
  //       fieldId: 'files',
  //       id: 'files',
  //       hideInViewMode: true,
  //       hideInTableMode: true,
  //     },
  //   ],
  // },
  // {
  //   tabId: 'accidents-incidents',
  //   displayName: 'Accidents / Incidents',
  //   uniqueReferenceKey: 'driverIncidentId',
  //   url: '/transports/driver/incidents',
  //   attachmentFieldId: 'driverIncidentAttachmentsId',
  //   attachmentFieldKeyName: 'driverIncidentsAttachments',
  //   formFields: [
  //     {
  //       name: 'Id',
  //       label: 'Id',
  //       type: 'text',
  //       validation: 'Alphabetic',
  //       fieldId: 'driverIncidentId',
  //       id: 'driverIncidentId',
  //     },
  //     {
  //       name: 'Date',
  //       label: 'Date',
  //       type: 'date',
  //       validation: 'Alphabetic',
  //       fieldId: 'date',
  //       id: 'date',
  //       max: moment().format('YYYY-MM-DD'),
  //       validationType: 'string',
  //       validations: [
  //         {
  //           type: 'required',
  //           params: ['##FIELD_NAME## is required'],
  //           nullable: true,
  //         },
  //       ],
  //     },
  //     {
  //       name: 'Location',
  //       label: 'Location',
  //       type: 'text',
  //       validation: 'Alphabetic',
  //       fieldId: 'location',
  //       id: 'location',
  //       validationType: 'string',
  //       validations: [
  //         {
  //           type: 'required',
  //           params: ['##FIELD_NAME## is required'],
  //           nullable: true,
  //         },
  //         {
  //           type: 'max',
  //           params: [20, '##FIELD_NAME## cannot be more than 20 characters'],
  //         },
  //       ],
  //     },
  //     {
  //       name: 'Accident/Incident Description',
  //       label: 'Accident/Incident Description',
  //       type: 'textarea',
  //       validation: 'Alphabetic',
  //       fieldId: 'accidentDescription',
  //       id: 'accidentDescription',
  //       validationType: 'string',
  //       validations: [
  //         {
  //           type: 'optional',
  //           params: [],
  //           nullable: true,
  //         },
  //         {
  //           type: 'max',
  //           params: [100, '##FIELD_NAME## cannot be more than 100 characters'],
  //         },
  //       ],
  //     },
  //     {
  //       name: 'Witness',
  //       label: 'Witness',
  //       type: 'text',
  //       validation: 'Alphabetic',
  //       fieldId: 'witness',
  //       id: 'witness',
  //       validationType: 'string',
  //       validations: [
  //         {
  //           type: 'optional',
  //           params: [],
  //           nullable: true,
  //         },
  //         {
  //           type: 'max',
  //           params: [20, '##FIELD_NAME## cannot be more than 20 characters'],
  //         },
  //       ],
  //     },
  //     {
  //       name: 'Employee Remarks',
  //       label: 'Employee Remarks',
  //       type: 'textarea',
  //       validation: 'Alphabetic',
  //       fieldId: 'employeeRemarks',
  //       id: 'employeeRemarks',
  //       validationType: 'string',
  //       validations: [
  //         {
  //           type: 'optional',
  //           params: [],
  //           nullable: true,
  //         },
  //         {
  //           type: 'max',
  //           params: [100, '##FIELD_NAME## cannot be more than 100 characters'],
  //         },
  //       ],
  //     },
  //     {
  //       name: 'Company Remarks',
  //       label: 'Company Remarks',
  //       type: 'textarea',
  //       validation: 'Alphabetic',
  //       fieldId: 'companyRemarks',
  //       id: 'companyRemarks',
  //       validationType: 'string',
  //       validations: [
  //         {
  //           type: 'optional',
  //           params: [],
  //           nullable: true,
  //         },
  //         {
  //           type: 'max',
  //           params: [100, '##FIELD_NAME## cannot be more than 100 characters'],
  //         },
  //       ],
  //     },
  //     {
  //       name: 'Investigation',
  //       label: 'Investigation',
  //       type: 'checkbox',
  //       validation: 'Alphabetic',
  //       fieldId: 'investigation',
  //       id: 'investigation',
  //       validationType: 'string',
  //       hideInTableMode: true,
  //       validations: [
  //         {
  //           type: 'optional',
  //           params: [],
  //         },
  //       ],
  //     },
  //     {
  //       name: 'Investigation Date',
  //       label: 'Investigation Date',
  //       type: 'date',
  //       validation: 'Alphabetic',
  //       fieldId: 'investigationDate',
  //       id: 'investigationDate',
  //       validationType: 'string',
  //       validations: [
  //         {
  //           type: 'optional',
  //           params: [],
  //           nullable: true,
  //         },
  //       ],
  //     },
  //     {
  //       name: 'Investigation Result',
  //       label: 'Investigation Result',
  //       type: 'textarea',
  //       validation: 'Alphabetic',
  //       fieldId: 'investigationResult',
  //       id: 'investigationResult',
  //       validationType: 'string',
  //       validations: [
  //         {
  //           type: 'optional',
  //           params: [],
  //           nullable: true,
  //         },
  //         {
  //           type: 'max',
  //           params: [100, '##FIELD_NAME## cannot be more than 100 characters'],
  //         },
  //       ],
  //     },
  //     {
  //       name: 'Attachments',
  //       label: 'Attachments',
  //       type: 'file',
  //       multiple: true,
  //       validation: 'Alphabetic',
  //       fieldId: 'files',
  //       id: 'files',
  //       hideInViewMode: true,
  //       hideInTableMode: true,
  //     },
  //   ],
  // },
]
