import React, { useState, useEffect } from 'react'
import ImportZone from './ImportSuburb'
import CopySuburb from './CopySuburb'
import axios from 'axios'
import AddSuburbData from './AddSuburbData'
import config from '../../../config'
import Spinner from '../../Spinner/Spinner'
import AddSuburb from './AddSuburb'
import SuburbTable from './SuburbTable'

const SuburbProfile = (props) => {
  const { lookupsList, customerCardId } = props
  const [csvArray, setCsvArray] = useState({ data: [], igniter: new Date() })
  const [showAddSuburb, setShowAddSuburb] = useState(false)
  const [showCopyZone, setShowCopyZone] = useState(false)
  const [showImportZone, setShowImportZone] = useState(true)
  const [enableForm, setEnableForm] = useState(false)
  const [suburbFormData, setSuburbFormData] = useState({})
  const [shipperData, setShipperData] = useState([])

  const [profileFormType, setProfileFormType] = useState('add')
  const [profileFormId, setProfileFormId] = useState(null)

  const [enableSpinner, setEnableSpinner] = useState(false)
  const [showSuccessMsg, setShowSuccessMsg] = useState(false)
  const [showError, setShowError] = useState(false)

  const [formError, setFormError] = useState(false)

  useEffect(() => {
    setShowImportZone(false)

    const url =
      config.api.baseUrl +
      '/customercard/shipper-address/?customerCardId=' +
      customerCardId
    const requestOptions = {
      method: 'GET',
      headers: { Authorization: 'Bearer ' + localStorage.token },
    }
    fetch(url, requestOptions)
      .then((response) => response.json())
      .then((data) => {
        setShipperData(data)
      })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (props.type === 'view' || props.type === 'edit') {
      // /api/customercard/suburb-profile
      const url =
        config.api.baseUrl +
        '/customercard/suburb-profile?customerCardid=' +
        customerCardId
      const requestOptions = {
        method: 'GET',
        headers: { Authorization: 'Bearer ' + localStorage.token },
      }
      fetch(url, requestOptions)
        .then((response) => response.json())
        .then((data) => {
          setCsvArray({ data: data, igniter: new Date() })
        })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const AddMultiZones = (_e, type) => {
    setFormError(false)

    let formData = csvArray.data
    formData.forEach((_, i) => {
      formData[i].customerCardId = customerCardId
    })

    setEnableSpinner(true)

    let formattedData = []
    formData.forEach((row) => {
      let data = {}
      Object.keys(row).forEach((key) => {
        let value = row[key]
        if (value === '' || value === 'NULL') value = null
        else if (typeof value === String && value.startsWith('$'))
          value = key.replace('$', '')

        data[key] = value
      })
      formattedData.push(data)
    })

    const url = config.api.baseUrl + '/customercard/suburb-profile/multi'
    setShowError(false)
    setShowSuccessMsg(false)
    axios({
      method: 'POST', //you can set what request you want to be
      url: url,
      data: formattedData,
      headers: {
        Authorization: 'Bearer ' + localStorage.token,
      },
    })
      .then(function () {
        setShowSuccessMsg(true)
        setEnableSpinner(false)
        setShowAddSuburb(false)
        setShowImportZone(false)
        setShowCopyZone(false)
      })
      .catch(function (error) {
        if (error?.response?.data?.data) {
          setShowError(error?.response?.data?.data)
        } else {
          setShowError(true)
        }
        setEnableSpinner(false)
      })
  }

  const enableAddSuburb = () => {
    setShowAddSuburb(true)
    setShowImportZone(false)
    setShowCopyZone(false)
    setCsvArray({})
    setShowError(false)
    setShowSuccessMsg(false)
  }

  const enableImportSuburb = () => {
    setShowAddSuburb(false)
    setShowImportZone(true)
    setShowCopyZone(false)
    setCsvArray([])
    setShowError(false)
    setShowSuccessMsg(false)
  }

  const enableCopySuburb = () => {
    setShowAddSuburb(false)
    setShowImportZone(false)
    setShowCopyZone(true)
    setShowError(false)
    setShowSuccessMsg(false)
    setCsvArray([])
  }

  return (
    <div>
      {props.type !== 'view' && (
        <React.Fragment>
          <div className="row">
            <div className="col-12 mb-4">
              <button
                className="btn btn-primary mb-4 me-3 btn-block2"
                onClick={() => enableAddSuburb()}
                disabled={showAddSuburb}
              >
                Add Suburb
              </button>
              <button
                className="btn btn-primary mb-4 me-3 btn-block2"
                onClick={() => enableImportSuburb()}
                disabled={showImportZone}
              >
                Import Suburb
              </button>
              <button
                className="btn btn-primary mb-4 btn-block2"
                onClick={() => enableCopySuburb()}
                disabled={showCopyZone}
              >
                Copy Suburb
              </button>
            </div>

            {showImportZone && (
              <div className="col-12">
                <ImportZone
                  // zoneCodes={lookupsList.zoneCodes}
                  customerCardId={customerCardId}
                  AddMultiZones={AddMultiZones}
                  setCsvArray={setCsvArray}
                  csvArray={csvArray}
                  formError={formError}
                  showError={showError}
                  showSuccessMsg={showSuccessMsg}
                  setSuburbFormData={setSuburbFormData}
                  setEnableForm={setEnableForm}
                  profileFormType={profileFormType}
                  setProfileFormType={setProfileFormType}
                  setProfileFormId={setProfileFormId}
                  {...props}
                />
              </div>
            )}

            {showAddSuburb && (
              <div className="col-12">
                <AddSuburb
                  lookupsList={lookupsList}
                  customerCardId={customerCardId}
                  AddMultiZones={AddMultiZones}
                  setCsvArray={setCsvArray}
                  csvArray={csvArray}
                  formError={formError}
                  showError={showError}
                  showSuccessMsg={showSuccessMsg}
                  setEnableForm={setEnableForm}
                  enableForm={enableForm}
                  setProfileFormId={setProfileFormId}
                  setProfileFormType={setProfileFormType}
                  setSuburbFormData={setSuburbFormData}
                  {...props}
                />
              </div>
            )}

            {showCopyZone && (
              <div className="col-12">
                <CopySuburb
                  // zoneCodes={lookupsList.zoneCodes}
                  customerCardId={customerCardId}
                  AddMultiZones={AddMultiZones}
                  setCsvArray={setCsvArray}
                  csvArray={csvArray}
                  formError={formError}
                  showError={showError}
                  showSuccessMsg={showSuccessMsg}
                  setEnableForm={setEnableForm}
                  setProfileFormType={setProfileFormType}
                  setProfileFormId={setProfileFormId}
                  setSuburbFormData={setSuburbFormData}
                  {...props}
                />
              </div>
            )}
          </div>

          {enableSpinner && <Spinner />}
        </React.Fragment>
      )}

      {(props.type === 'view' || props.type === 'edit') && (
        <div>
          {csvArray.data && csvArray.data.length === 0 && (
            <p>No Suburb data found for this customer </p>
          )}

          {!showCopyZone &&
            !showAddSuburb &&
            !showImportZone &&
            csvArray.data &&
            csvArray.data.length > 0 && (
              <SuburbTable
                csvArray={csvArray}
                setSuburbFormData={setSuburbFormData}
                setEnableForm={setEnableForm}
                setProfileFormId={setProfileFormId}
                setCsvArray={setCsvArray}
                type={props.type}
              />
            )}
        </div>
      )}

      {enableForm && (
        <AddSuburbData
          lookupsList={lookupsList}
          defaultData={suburbFormData}
          csvArray={csvArray}
          setCsvArray={setCsvArray}
          setEnableForm={setEnableForm}
          profileFormType={profileFormType}
          profileFormId={profileFormId}
          setProfileFormId={setProfileFormId}
          shipperData={shipperData}
          {...props}
        />
      )}
    </div>
  )
}

export default SuburbProfile
