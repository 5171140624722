import React, { Fragment, useEffect, useState } from 'react'
import * as Yup from 'yup'
import { useForm } from 'react-hook-form'
import axios from 'axios'
import isNil from 'lodash/isNil'
import isEmpty from 'lodash/isEmpty'
import { useSelector } from 'react-redux'
import { TextBox } from '../../../../../../components/utils/TextBox'
import { yupResolver } from '@hookform/resolvers/yup'
import config from '../../../../../../config'
import * as Toastify from '../../../../../../components/utils/Toastify'
import { getSelectClass } from '../../../../../../components/utils/Select'
import useModalContainer from '../../../../../../helper/hooks/useModalContainer'
import { getLookupDataState } from '../../../../../../store/slices/lookup-slice'
import { ICONS } from '../../../../../../components/Common/Icons'

const controllerApiEndpointsMap = (id = '') => ({
  order: {
    GET: `/operations/order/container/service-rates/by-container?orderContainerDetailsId=${id}`,
    POST: '/operations/order/container/service-rates',
    PUT: `/operations/order/container/service-rates/${id}`,
    DELETE: `/operations/order/container/service-rates/${id}`,
  },
  stage: {
    GET: `/container/stage-tracker/service-rates?containerStageTrackerId=${id}`,
    POST: '/container/stage-tracker/service-rates',
    PUT: `/container/stage-tracker/service-rates/${id}`,
    DELETE: `/container/stage-tracker/service-rates/${id}`,
  },
})

const ORDER_CONTROLLER = 'order'
const STAGE_CONTROLLER = 'stage'

export default function ContainerServiceRates({
  type,
  controllerData,
  setShowServiceRateModal,
  controller,
}) {
  const lookupsList = useSelector(getLookupDataState)
  const [isCustomFormEnable, setIsCustomFormEnable] = useState(false)
  const [containerNumbers, setContainerNumbers] = useState([])
  const [serviceRate, setServiceRate] = useState({})
  const [serviceRatesList, setServiceRatesList] = useState([])

  const setServiceRateData = (serviceRateData = {}) => {
    setValue('notes', serviceRateData?.notes)

    if (controller === ORDER_CONTROLLER)
      setValue('containerNumber', serviceRateData?.containerNumber)

    setValue('rateCode', serviceRateData?.rateCode)
    setValue('description', serviceRateData?.description)
    setValue('quantity', serviceRateData?.quantity)
    setValue('customRateValue', serviceRateData?.customRateValue)
    setIsCustomFormEnable(serviceRateData?.custom)
    setServiceRate(serviceRateData)
  }

  const validationSchema = Yup.object().shape({
    ...(controller === ORDER_CONTROLLER && {
      containerNumber: Yup.string().required('containerNumber is required'),
    }),
    rateCode: Yup.string().required('Rate Code is required'),
    description: Yup.string().required('Description is required'),
    quantity: Yup.number().required('Quantity is required'),
    notes: Yup.string().required('Notes is required'),
    ...(isCustomFormEnable && {
      customRateValue: Yup.number().required('Rate Value is required'),
    }),
  })

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    setValue,
  } = useForm({
    resolver: yupResolver(validationSchema),
    reValidateMode: 'onChange',
  })

  /**
   * Fetch Service rates
   * id -> orderContainerDetailsId |
   */
  const getServiceRates = (id) => {
    const url =
      config.api.baseUrl + controllerApiEndpointsMap(id)[controller]['GET']

    const requestOptions = {
      method: 'GET',
      headers: { Authorization: 'Bearer ' + localStorage.token },
    }
    fetch(url, requestOptions)
      .then((response) => response.json())
      .then((data) => {
        setServiceRatesList(data)
      })
      .catch((error) => {
        Toastify.Error(error?.response?.data?.error)
      })
  }

  const getContainerNumbers = (orderCustomerDetailsId) => {
    const url =
      config.api.baseUrl +
      '/operations/order/container/numbers/dropdown?orderCustomerDetailsId=' +
      orderCustomerDetailsId
    const requestOptions = {
      method: 'GET',
      headers: { Authorization: 'Bearer ' + localStorage.token },
    }
    fetch(url, requestOptions)
      .then((response) => response.json())
      .then((data) => {
        setContainerNumbers(data)
      })
      .catch((error) => {
        Toastify.Error(error?.response?.data?.error)
      })
  }

  const handleClose = () => {
    reset()
    setShow(false)
    setIsCustomFormEnable(false)
    setShowServiceRateModal?.(false)
    setServiceRate({})
  }

  const submitServiceRateCard = (formData) => {
    let method = 'POST'
    let msg = 'Service Rate Card Created successfully..!'
    if (!isNil(serviceRate.serviceRateId)) {
      method = 'PUT'
      msg = 'Service Rate Card Updated successfully..!'
    }
    const endpoint = controllerApiEndpointsMap(serviceRate.serviceRateId)[
      controller
    ][method]
    const data = {
      ...(!isNil(serviceRate.serviceRateId) && {
        serviceRateId: serviceRate.serviceRateId,
      }),

      ...(controller === ORDER_CONTROLLER && {
        containerNumber: formData.containerNumber,
        orderContainerDetailsId: containerNumbers.find(
          (obj) => obj.containerNumber === formData.containerNumber
        )?.orderContainerDetailsId,
      }),

      ...(controller === STAGE_CONTROLLER && {
        containerStageTrackerId: controllerData.containerStageTrackerId,
      }),

      custom: isCustomFormEnable,
      description: formData.description,
      notes: formData.notes,
      quantity: formData.quantity,
      rateCode: formData.rateCode,

      customRateValue: formData.customRateValue,
    }
    axios({
      method,
      url: config.api.baseUrl + endpoint,
      data,
      headers: {
        Authorization: 'Bearer ' + localStorage.token,
      },
    })
      .then(() => {
        Toastify.Success(msg)
        getServiceRates(controllerData.orderContainerDetailsId)
        handleClose()
      })
      .catch((error) => {
        Toastify.Error(error?.response?.data?.error)
      })
  }

  const deleteServiceRateById = (id) => {
    axios({
      method: 'DELETE',
      url:
        config.api.baseUrl +
        controllerApiEndpointsMap(id)[controller]['DELETE'],
      headers: {
        Authorization: 'Bearer ' + localStorage.token,
      },
    })
      .then(() => {
        Toastify.Success('Service Rate Card deleted successfully..!')
        if (controller === STAGE_CONTROLLER)
          getServiceRates(controllerData.containerStageTrackerId)
        if (controller === ORDER_CONTROLLER)
          getServiceRates(controllerData.orderContainerDetailsId)
      })
      .catch((error) => {
        Toastify.Error(error?.response?.data?.error)
      })
  }

  const handleSubmitWithoutPropagation = (e) => {
    e.preventDefault()
    e.stopPropagation()
    handleSubmit(submitServiceRateCard)(e)
  }

  const { ModalContainer, setShow } = useModalContainer({
    ...(controller !== STAGE_CONTROLLER && {
      text: (
        <button
          type="button"
          className="unset-all d-flex flex-column align-items-center"
          onClick={(e) => {
            e.preventDefault()
            if (controllerData?.orderContainerDetailsId > 0)
              getServiceRates(controllerData.orderContainerDetailsId)

            if (controllerData?.orderCustomerDetailsId > 0)
              getContainerNumbers(controllerData.orderCustomerDetailsId)
            setShow(true)
          }}
        >
          <img
            src="./assets/customer-order/service-rates.png"
            alt="Service rate"
            width="40px"
            height="40px"
          />
          Service Rate
        </button>
      ),
    }),

    body: (
      <Fragment>
        <div className="card2">
          <div className="card-body2">
            <form onSubmit={handleSubmitWithoutPropagation}>
              <div>
                {serviceRate.serviceRateId === undefined && (
                  <div className="row">
                    <div className="col-md-1" />
                    <div className="col-md-3">
                      <h5>Custom From :</h5>
                    </div>
                    <div className="col-md-4 mb-4">
                      <div>
                        {type !== 'view' && (
                          <label className="switch ">
                            <input
                              id="enableCustom"
                              placeholder="enableCustom"
                              name="enableCustom"
                              type="checkbox"
                              onChange={() =>
                                setIsCustomFormEnable(!isCustomFormEnable)
                              }
                            />
                            <div>
                              <span></span>
                            </div>
                          </label>
                        )}
                      </div>
                    </div>
                  </div>
                )}
                {controller === ORDER_CONTROLLER ? (
                  <div className="row">
                    <div className="col-md-1" />
                    <div className="col-md-3">
                      <h5>Container Number :</h5>
                    </div>
                    <div className="col-md-4 mb-4">
                      <select
                        style={{ height: 'auto' }}
                        className={`form-select ${
                          errors?.containerNumber ? 'is-invalid' : ''
                        }`}
                        id="containerNumber"
                        name="containerNumber"
                        {...register('containerNumber')}
                      >
                        <option value="">Please Select</option>
                        {containerNumbers.map((item) => (
                          <option
                            key={item.orderContainerDetailsId}
                            value={item.containerNumber}
                          >
                            {item.containerNumber}
                          </option>
                        ))}
                      </select>
                      {errors?.containerNumber && (
                        <p className="invalid-feedback">
                          {errors?.containerNumber?.message}
                        </p>
                      )}
                    </div>
                  </div>
                ) : null}

                {!isCustomFormEnable ? (
                  <div className="row">
                    <div className="col-md-1" />
                    <div className="col-md-3">
                      <h5>Rate Code :</h5>
                    </div>
                    <div className="col-md-4 mb-4">
                      <select
                        style={{ height: 'auto' }}
                        className={getSelectClass(errors?.rateCode)}
                        id="rateCode"
                        name="rateCode"
                        {...register('rateCode')}
                      >
                        <option value="">Please Select</option>
                        {lookupsList?.rateCodes.map((item) => {
                          return item.rateRules &&
                            item.rateRules?.includes('SERVICERATES') ? (
                            <option key={item.rateCode} value={item.rateCode}>
                              {item.rateCode}
                            </option>
                          ) : null
                        })}
                      </select>
                      {errors?.rateCode && (
                        <p className="invalid-feedback">
                          {errors?.rateCode?.message}
                        </p>
                      )}
                    </div>
                  </div>
                ) : (
                  <>
                    <div className="row">
                      <div className="col-md-1" />
                      <div className="col-md-3">
                        <h5>Custom Rate Code :</h5>
                      </div>
                      <div className="col-md-4 mb-4">
                        <TextBox
                          register={register}
                          id="rateCode"
                          errors={errors}
                          value=""
                          placeholder="rateCode"
                          maxLength="30"
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-1" />
                      <div className="col-md-3">
                        <h5>Custom Rate Value :</h5>
                      </div>
                      <div className="col-md-4 mb-4">
                        <TextBox
                          register={register}
                          id="customRateValue"
                          errors={errors}
                          value=""
                          placeholder="customRateValue"
                          maxLength="30"
                        />
                      </div>
                    </div>
                  </>
                )}

                <div className="row">
                  <div className="col-md-1" />
                  {!isCustomFormEnable ? (
                    <div className="col-md-3">
                      <h5>Description :</h5>
                    </div>
                  ) : (
                    <div className="col-md-3">
                      <h5> Custom Description :</h5>
                    </div>
                  )}

                  <div className="col-md-4 mb-4">
                    <TextBox
                      register={register}
                      id="description"
                      errors={errors}
                      value=""
                      placeholder="Description"
                      maxLength="30"
                    />
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-1" />
                  {!isCustomFormEnable ? (
                    <div className="col-md-3">
                      <h5>Quantity :</h5>
                    </div>
                  ) : (
                    <div className="col-md-3">
                      <h5> Custom Quantity :</h5>
                    </div>
                  )}
                  <div className="col-md-4 mb-4">
                    <TextBox
                      register={register}
                      id="quantity"
                      errors={errors}
                      value=""
                      placeholder="Quantity"
                      maxLength="30"
                    />
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-1" />
                  {!isCustomFormEnable ? (
                    <div className="col-md-3">
                      <h5>Notes :</h5>
                    </div>
                  ) : (
                    <div className="col-md-3">
                      <h5> Custom Notes :</h5>
                    </div>
                  )}
                  <div className="col-md-4 mb-4">
                    <TextBox
                      register={register}
                      id="notes"
                      errors={errors}
                      value=""
                      placeholder="Notes"
                      maxLength="30"
                    />
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-md-1" />
                <div className="col-7 text-right">
                  <button
                    className="btn btn-primary float-end ms-3"
                    type="submit"
                  >
                    {serviceRate?.serviceRateId > 0 ? 'Update' : 'Save    '}
                  </button>
                  <button className="btn btn-primary float-end" type="reset">
                    Reset
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
        {!isEmpty(serviceRatesList) && (
          <div className="card-body">
            <div className="row">
              <div className="col-md-12">
                <div className="">
                  <table className="table table-hover">
                    <thead>
                      <tr>
                        <th>Container No.</th>
                        <th>Rate Code</th>
                        <th>Description</th>
                        <th>Quantity</th>
                        <th>Notes</th>
                        <th>Created By</th>
                        <th>Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      {serviceRatesList?.map?.((_serviceRate, i) => {
                        return (
                          <tr
                            key={i}
                            style={
                              serviceRate?.serviceRateId ===
                              _serviceRate?.serviceRateId
                                ? {
                                    backgroundColor: '#2f5eb257',
                                    color: '#000',
                                  }
                                : {}
                            }
                          >
                            <td>{_serviceRate?.containerNumber}</td>
                            <td>{_serviceRate?.rateCode}</td>
                            <td>{_serviceRate?.description}</td>
                            <td>{_serviceRate?.quantity}</td>
                            <td>{_serviceRate?.notes}</td>
                            <td>{_serviceRate?.createdBy}</td>
                            <td>
                              {' '}
                              <button
                                title="edit"
                                className="btn p-1 me-1"
                                onClick={(event) => {
                                  event.preventDefault()
                                  setServiceRateData(_serviceRate)
                                }}
                              >
                                {ICONS.EDIT}
                              </button>
                              {serviceRate?.serviceRateId ===
                                _serviceRate?.serviceRateId && (
                                <button
                                  className="btn btn-sm btn-primary me-2"
                                  onClick={(event) => {
                                    event.preventDefault()
                                    setServiceRateData()
                                  }}
                                >
                                  Cancel
                                </button>
                              )}
                              <button
                                title="delete"
                                className="btn p-1 me-1"
                                onClick={(event) => {
                                  event.preventDefault()
                                  deleteServiceRateById(
                                    _serviceRate?.serviceRateId
                                  )
                                }}
                              >
                                {ICONS.DELETE}
                              </button>
                            </td>
                          </tr>
                        )
                      })}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        )}
      </Fragment>
    ),
    header: 'Container Service Rate',
    onCloseCallback: handleClose,
  })

  /**
   * This useEffect is to open Service Rates modal in LoadsPlanning
   */
  useEffect(() => {
    if (controller === STAGE_CONTROLLER) {
      if (controllerData?.containerStageTrackerId > 0)
        getServiceRates(controllerData.containerStageTrackerId)

      setShow(true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [controllerData, setShow, controller])

  return ModalContainer
}
