import React, { useEffect, useState } from 'react'
import { yupResolver } from '@hookform/resolvers/yup'
import * as Yup from 'yup'
import { useForm } from 'react-hook-form'
import config from '../../../config'
import SuburbTable from './SuburbTable'

const CopySuburb = (props) => {
  const [customersData, setCustomerData] = useState([])
  const [copyError, setCopyError] = useState(false)
  const url = config.api.baseUrl + config.api.customerCardsList

  const {
    csvArray,
    setCsvArray,
    showError,
    showSuccessMsg,
    setSuburbFormData,
    setEnableForm,
    setProfileFormId,
  } = props

  const validationSchema = Yup.object().shape({
    fromCustomer: Yup.string().required('This field is required'),
  })

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
    reValidateMode: 'onChange',
  })

  useEffect(() => {
    const requestOptions = {
      method: 'GET',
      headers: { Authorization: 'Bearer ' + localStorage.token },
    }

    fetch(url + '?active=true', requestOptions)
      .then((response) => response.json())
      .then((data) => {
        setCustomerData(data)
      })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const getZoneDetailsByClient = (formData) => {
    const requestOptions = {
      method: 'POST',
      headers: { Authorization: 'Bearer ' + localStorage.token },
    }
    let apiUrl =
      config.api.baseUrl +
      '/customercard/suburb-profile/copy/' +
      formData.fromCustomer

    fetch(apiUrl, requestOptions)
      .then((response) => response.json())
      .then((data) => {
        if (Array.isArray(data)) {
          let newData = { data: data, igniter: new Date() }
          setCsvArray(newData)
        }
        if (data.data) {
          setCopyError(JSON.parse(data?.data))
        }
      })
      .catch((error) => {
        if (error?.response?.data?.data) {
          setCopyError(JSON.parse(error?.response?.data?.data))
        } else {
          setCopyError(true)
        }
      })
  }

  const copyZoneProfile = (e) => {
    props.AddMultiZones(e, 'copy')
  }

  return (
    <div>
      <h3>Copy Suburb profile</h3>
      <form onSubmit={handleSubmit(getZoneDetailsByClient)}>
        <div className="row">
          <div className="col-md-2">
            <label>Customer</label>
          </div>
          <div className="col-md-3 mb-4">
            <select
              className={`form-select ${
                errors.fromCustomer ? 'is-invalid' : ''
              }`}
              id="customerCardId"
              name="fromCustomer"
              {...register('fromCustomer')}
            >
              <option value="">Please Select</option>
              {customersData.map((eachCustomer) => (
                <option
                  value={eachCustomer.customerCardId}
                  key={eachCustomer.customerCardId}
                >
                  {eachCustomer.customerName}
                </option>
              ))}
            </select>
            {errors?.fromCustomer && (
              <p className="invalid-feedback">
                {errors?.fromCustomer?.message}
              </p>
            )}
          </div>

          <div className="col-2">
            <button className="btn btn-primary mb-4" type="submit">
              Copy
            </button>
          </div>
        </div>
      </form>

      {copyError && (
        <div className="text-danger my-4">
          <p>
            There is an issue getting the suburb profiles. please try later.
          </p>
          {copyError.length &&
            copyError.map((eachError, i) => {
              return (
                <p key={i}>
                  {eachError.key} - {eachError.message}
                </p>
              )
            })}
        </div>
      )}

      {csvArray.data && csvArray.data.length > 0 && (
        <div>
          <SuburbTable
            csvArray={csvArray}
            setSuburbFormData={setSuburbFormData}
            setEnableForm={setEnableForm}
            setProfileFormId={setProfileFormId}
            setCsvArray={setCsvArray}
          />

          <div className="text-end mt-4">
            <button
              onClick={() => copyZoneProfile()}
              className="btn btn-primary"
            >
              Submit Suburb profile
            </button>
          </div>
          {showSuccessMsg && (
            <p className="text-success">
              Suburb profile has copied successfully.
            </p>
          )}

          {showError && (
            <p className="text-danger">
              Error in copying the suburb profile. Please try after some time.
            </p>
          )}
        </div>
      )}
    </div>
  )
}

export default CopySuburb
