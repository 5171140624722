import React from 'react'
import { Controller, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as Yup from 'yup'
import { createYupSchema } from '../yupValidatorCreator'
import Select from 'react-select'

export function GenerateFormField(props) {
  const { data, reg, err, disabled, watch, control, setValue } = props
  const id = data.fieldId
  const allowNumeric = (event) => {
    event.target.value = event.target.value
      .replace(/[^0-9.]/g, '')
      .replace(/(\..*)\./g, '$1')
  }
  return (
    <React.Fragment>
      {data.type === 'text' && (
        <div>
          <input
            className={`form-control ${
              err[data.fieldId]?.message ? 'is-invalid' : ''
            }`}
            id={id}
            placeholder={data.name}
            {...reg(id)}
            type="text"
            disabled={disabled}
          />
          {err[data.fieldId]?.message && (
            <p className="invalid-feedback">{err[data.fieldId]?.message}</p>
          )}
        </div>
      )}
      {data.type === 'number' && (
        <div>
          <input
            className={`form-control ${
              err[data.fieldId]?.message ? 'is-invalid' : ''
            }`}
            id={id}
            placeholder={data.name}
            {...reg(id)}
            type="text"
            onInput={(event) => {
              allowNumeric(event)
            }}
            disabled={disabled}
          />
          {err[data.fieldId]?.message && (
            <p className="invalid-feedback">{err[data.fieldId]?.message}</p>
          )}
        </div>
      )}

      {data.type === 'time' && (
        <div>
          <input
            className={`form-control ${
              err[data.fieldId]?.message ? 'is-invalid' : ''
            }`}
            id={id}
            placeholder={data.name}
            {...reg(id)}
            type="time"
            disabled={disabled}
          />
          {err[data.fieldId]?.message && (
            <p className="invalid-feedback">{err[data.fieldId]?.message}</p>
          )}
        </div>
      )}

      {data.type === 'textarea' && (
        <div>
          <textarea
            className={`form-control ${
              err[data.fieldId]?.message ? 'is-invalid' : ''
            }`}
            id={id}
            placeholder={data.name}
            {...reg(id)}
            disabled={disabled}
          />
          {err[data.fieldId]?.message && (
            <p className="invalid-feedback">{err[data.fieldId]?.message}</p>
          )}
        </div>
      )}

      {data.type === 'checkbox' && (
        <div>
          <label
            className={`switch ${
              err[data.fieldId]?.message ? ' is-invalid' : ''
            }`}
          >
            <input
              className={` ${err[data.fieldId]?.message ? 'is-invalid' : ''}`}
              id={id}
              placeholder={data.name}
              {...reg(id)}
              type="checkbox"
              disabled={disabled}
            />
            <div>
              <span></span>
            </div>
          </label>
          {err[data.fieldId]?.message && (
            <p className="invalid-feedback">{err[data.fieldId]?.message}</p>
          )}
        </div>
      )}

      {data.type === 'select' && (
        <div>
          <select
            className={`form-select ${
              err[data.fieldId]?.message ? 'is-invalid' : ''
            }`}
            id={id}
            placeholder={data.name}
            {...reg(id)}
          >
            <option value="">Please select</option>
            {data.options &&
              data.options.map((eachOption, index) => (
                <option value={eachOption.id} key={index}>
                  {eachOption.label}
                </option>
              ))}
            disabled={disabled}
          </select>
          {err[data.fieldId]?.message && (
            <p className="invalid-feedback">{err[data.fieldId]?.message}</p>
          )}
        </div>
      )}

      {data.type === 'searchable-select' &&
        (data.multiple ? (
          <div>
            {(() => {
              const options = data.options?.filter((option) =>
                data?.dependsOnFieldId
                  ? option[data?.dependsOnFieldId] ===
                    watch(data?.dependsOnFieldId)
                  : true
              )
              return (
                <Controller
                  control={control}
                  name={data.fieldId}
                  render={({ field: { onChange, value, ...rest } }) => {
                    const initVal = options?.filter((option) =>
                      value?.find((val) => val === option?.id)
                    )
                    return (
                      <Select
                        value={initVal && initVal.length ? initVal : value}
                        className={`form-searchable-select ${
                          err[data.fieldId]?.message ? 'is-invalid' : ''
                        }`}
                        classNamePrefix="form-searchable-select"
                        placeholder={data.name}
                        isClearable
                        isSearchable
                        isMulti={data.multiple}
                        disabled={disabled}
                        onChange={(val) => {
                          if (data?.dependentFieldIds?.length > 0) {
                            data?.dependentFieldIds.forEach((fieldId) => {
                              setValue(fieldId, null)
                            })
                          }
                          onChange(val ?? [])
                        }}
                        options={options}
                        getOptionLabel={(option) => option.label}
                        getOptionValue={(option) => option.id}
                        {...rest}
                      />
                    )
                  }}
                />
              )
            })()}

            {err[data.fieldId]?.message && (
              <p className="invalid-feedback">{err[data.fieldId]?.message}</p>
            )}
          </div>
        ) : (
          <div>
            {(() => {
              const options = data.options?.filter((option) =>
                data?.dependsOnFieldId
                  ? option[data?.dependsOnFieldId] ===
                    watch(data?.dependsOnFieldId)
                  : true
              )
              return (
                <Controller
                  control={control}
                  name={data.fieldId}
                  render={({ field: { onChange, value, ...rest } }) => (
                    <Select
                      className={`form-searchable-select ${
                        err[data.fieldId]?.message ? 'is-invalid' : ''
                      }`}
                      classNamePrefix="form-searchable-select"
                      placeholder={data.name}
                      isClearable
                      isSearchable
                      isMulti={data.multiple}
                      disabled={disabled}
                      value={
                        options?.find((option) => option?.id === value) ?? null
                      }
                      onChange={(val) => {
                        if (data?.dependentFieldIds?.length > 0) {
                          data?.dependentFieldIds.forEach((fieldId) => {
                            setValue(fieldId, null)
                          })
                        }
                        if (data.onChange) data.onChange(val, setValue)
                        if (!val) {
                          setValue(data.fieldId, null)
                          return
                        }
                        onChange(val?.id)
                      }}
                      options={options}
                      getOptionLabel={(option) => option.label}
                      getOptionValue={(option) => option.id}
                      {...rest}
                    />
                  )}
                />
              )
            })()}
            {err[data.fieldId]?.message && (
              <p className="invalid-feedback">{err[data.fieldId]?.message}</p>
            )}
          </div>
        ))}
    </React.Fragment>
  )
}

export function Form({
  defaultValues,
  children,
  onSubmit,
  formData,
  reference,
  columns,
  uniqueReferenceKey,
}) {
  let newValidations = formData.reduce(createYupSchema, {})
  const validationSchema = Yup.object().shape(newValidations)

  const {
    handleSubmit,
    register,
    watch,
    control,
    setValue,
    formState: { errors },
  } = useForm({ resolver: yupResolver(validationSchema), defaultValues })

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      data-form-type={reference && reference === 'editForm' ? 'EDIT' : 'SUBMIT'}
    >
      <div className="row">
        {formData?.map((eachField, index) => (
          <React.Fragment key={index}>
            <div className={columns === 4 ? 'col-md-2' : 'col-md-4'}>
              <label>{eachField.name}</label>
            </div>
            <div className={columns === 4 ? 'col-md-4 mb-4' : 'col-md-6 mb-4'}>
              {reference &&
              reference === 'editForm' &&
              eachField.fieldId === uniqueReferenceKey ? (
                <h4
                  data-field-id={eachField.fieldId}
                  data-unique-ref-key={uniqueReferenceKey}
                >
                  {defaultValues[uniqueReferenceKey]}
                </h4>
              ) : (
                <GenerateFormField
                  data={eachField}
                  reg={register}
                  err={errors}
                  watch={watch}
                  control={control}
                  setValue={setValue}
                  formMode={
                    reference && reference === 'editForm' ? 'EDIT' : 'SUBMIT'
                  }
                />
              )}
            </div>
          </React.Fragment>
        ))}

        {children}
      </div>
    </form>
  )
}
