import React from 'react'
import { Col, Row } from 'react-bootstrap'
import DetailComp from './DetailComp'
import { useSelector } from 'react-redux'
import { selectSelectedJob } from '../../../../../store/slices/allocation/drivers'

export default function DriverDetails() {
  const selectedJob = useSelector(selectSelectedJob)

  if (!selectedJob) return null

  const { jobDriverDTO } = selectedJob

  if (!jobDriverDTO)
    return <p className="text-danger">jobDriverDTO details not found</p>

  return (
    <div className="mt-3">
      <h5>Driver Details</h5>
      <Row>
        <Col md={3} className="my-2">
          <img
            src={
              jobDriverDTO.profilePic
                ? `data:image/jpeg;base64,${jobDriverDTO.profilePic}`
                : '../../../assets/user-avatar.png'
            }
            width="60px"
            alt=""
          />
        </Col>
        <Col md={9}>
          <Row>
            <DetailComp
              md={12}
              label="Driver Id"
              value={jobDriverDTO.driverId}
            />
            <DetailComp
              md={12}
              label="Driver Name"
              value={jobDriverDTO.driverName}
            />
          </Row>
        </Col>
      </Row>
    </div>
  )
}
