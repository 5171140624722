import { faRotateRight } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import { Col, Row, Spinner } from 'react-bootstrap'
import { useForm } from 'react-hook-form'
import { useSelector } from 'react-redux'
import Input from '../../../../../components/Common/Form/Fields/Input'
import Select from '../../../../../components/Common/Form/Fields/Select'
import Table from '../../../../../components/Common/Tanstack-table/Table'
import IconButton from '../../../../../components/styles/Buttons/IconButton'
import { useJobsByStatus } from '../../../../../helper/hooks/api/allocations'
import { getLookupDataState } from '../../../../../store/slices/lookup-slice'
import useJobDetailsColumns from '../../hooks/useJobDetailsColumns'

export default function JobDetails() {
  const lookupsList = useSelector(getLookupDataState)

  const {
    watch,
    register,
    formState: { errors },
    reset,
  } = useForm({
    mode: 'all',
    defaultValues: {
      searchDate: '',
      jobDriverStatus: '',
    },
  })

  const formValues = watch()
  const status = watch('jobDriverStatus')

  const {
    data: response,
    isLoading,
    error,
  } = useJobsByStatus(status, formValues)

  const columns = useJobDetailsColumns(response)

  if (error) return <p>Error occurred while fetching data</p>

  if (isLoading) return <Spinner />

  return (
    <section>
      <Row>
        <Input
          register={register}
          errors={errors}
          name="searchDate"
          type="date"
          md={3}
        />
        <Select
          register={register}
          errors={errors}
          label="Status"
          name="jobDriverStatus"
          options={lookupsList?.jobDriverStatusEnums.filter(
            (s) => !['PLANNED', 'UNPLANNED', 'UNPLANNED_JOB'].includes(s)
          )}
          md={3}
        />
        <Col md={1} className="d-flex align-items-center">
          <IconButton title="Reset" type="button" onClick={() => reset()}>
            <FontAwesomeIcon icon={faRotateRight} />
          </IconButton>
        </Col>
      </Row>
      {isLoading && <Spinner />}
      {!Array.isArray(response.data) || response.data.length === 0 ? (
        <p style={{ flexGrow: 1 }}>
          No data available. Try changing search filters.
        </p>
      ) : (
        <Table columns={columns} data={response.data} />
      )}
    </section>
  )
}
