const PercentageChangeFormData = [
  {
    name: 'Include CRDs',
    label: 'Include CRDs',
    type: 'include-exclude-select',
    validation: 'Alphabetic',
    loadLookUp: 'rateNames',
    fieldId: 'includeCrdIds',
    id: 'includeCrdIds',
    validationType: 'Array',
    validations: [
      {
        type: 'required',
        params: ['##FIELD_NAME## is required'],
        nullable: true,
      },
    ],
  },
  {
    name: 'Rate Code',
    label: 'Rate Code',
    loadLookUp: 'rateCodes',
    type: 'select',
    valueasId: true,
    validation: 'Alphabetic',
    fieldId: 'rateCode',
    id: 'rateCode',
    validationType: 'string',
    validations: [
      {
        type: 'required',
        params: ['##FIELD_NAME## is required'],
        nullable: true,
      },
    ],
  },
  {
    name: 'Notes',
    label: 'Notes',
    type: 'textarea',
    validation: 'Alphabetic',
    fieldId: 'notes',
    id: 'notes',
    validationType: 'string',
    validations: [
      {
        type: 'required',
        params: ['##FIELD_NAME## is required'],
        nullable: true,
      },
    ],
  },
  {
    name: 'Percentage Change',
    label: 'Percentage Change',
    type: 'text',
    validation: 'Alphabetic',
    fieldId: 'percentage',
    id: 'percentage',
    validationType: 'number',
    validations: [
      {
        type: 'required',
        params: ['##FIELD_NAME## is required'],
        nullable: true,
      },
    ],
  },
]

export default PercentageChangeFormData
