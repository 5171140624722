import { useQuery } from '@tanstack/react-query'
import axios from 'axios'
import isEmpty from 'lodash/isEmpty'
import React, { useEffect, useMemo, useRef, useState } from 'react'
import { Modal } from 'react-bootstrap'
import { useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import config from '../../../config'
import useModalContainer from '../../../helper/hooks/useModalContainer'
import { getLookupDataState } from '../../../store/slices/lookup-slice'
import Table from '../../Common/Tanstack-table/Table'
import Map from '../../GoogleMap/GoogleMap'
import Spinner from '../../Spinner/Spinner'
import ContainerWithHeading from '../../styles/Containers/ContainerWithHeading'
import fetchService from '../../utils/fetchService'
import { Form } from './Form'
import stageTrackerColumns from './StageTrackerColumn.config'
import { formData } from './StageTrackerFormData'
import { getGeoLocation, setByDriver, updateStageField } from './utility'

function checkLoadStatus(status) {
  let statuses = ['PLANNED', 'UNPLANNED', 'LOAD', 'HOLD']

  if (status && !statuses.includes(status)) return true

  return false
}

const StageTracker = ({
  mode,
  containerDetailsId,
  useAs,
  editingSingleJob = false,
  jobId = '',
}) => {
  const [currentRecordId, setCurrentRecordId] = useState('')
  const lookupData = useSelector(getLookupDataState)
  const [formFields, setFormFields] = useState([...formData])
  const [loading, setLoading] = useState(false)
  const [listData, setListData] = useState([])
  const [detail, setDetail] = useState({})
  const [error, setError] = useState()
  const displayFormRef = useRef()
  const formRef = useRef()
  const [show, setShow] = useState(false)
  const [geoLocation, setGeoLocation] = useState({
    lat: '',
    long: '',
    postext: '',
    pos_time: '',
    objecttype: '',
    objectname: '',
  })
  const { driverId: defaultDriverId, loadStatus: defaultLoadStatus } = detail

  useQuery({
    queryKey: [
      config.api.containerStagesOptions,
      detail?.orderContainerDetailsId,
    ],
    queryFn: async () =>
      await fetchService({
        url: `${config.api.containerStagesOptions}${detail?.orderContainerDetailsId}`,
      }),
    enabled: !!detail?.orderContainerDetailsId,
    onSuccess: (stagesOptions) => {
      if (checkLoadStatus(detail.loadStatus)) return
      setFormFields((prevFormFields) =>
        updateStageField(prevFormFields, stagesOptions)
      )
    },
  })

  const disableFields = (fields) => {
    const newFormData = []
    if (fields) {
      formData.forEach((field) => {
        const newField = { ...field }
        if (fields.includes(newField.fieldId)) {
          newField.disabled = true
          newField.type = 'text'
        }
        newFormData.push(newField)
      })
    }
    setFormFields(newFormData)
  }

  const fetchList = async () => {
    setLoading(true)
    axios({
      method: 'GET',
      url: `${config.api.baseUrl}/container/stage-tracker/all`,
      headers: {
        Authorization: 'Bearer ' + localStorage.token,
      },
    })
      .then((response) => {
        setListData(response?.data)
      })
      .catch((err) => {
        toast.error(err?.response?.data?.error ?? 'Something went wrong!')
      })
      .finally(() => {
        setLoading(false)
      })
  }

  const fetchDetailById = async (_id) => {
    setLoading(true)
    axios({
      method: 'GET',
      url: `${config.api.baseUrl}/container/stage-tracker/${_id}`,
      headers: {
        Authorization: 'Bearer ' + localStorage.token,
      },
    })
      .then((response) => {
        const { data } = response
        for (const key in data) {
          if (data[key] === null) {
            delete data[key]
          }
        }
        setDetail(response?.data)
        setCurrentRecordId(_id)
        displayFormRef.current?.scrollIntoView()
      })
      .catch((err) => {
        setError(err?.response?.data)
        toast.error(
          err?.response?.data?.error ??
            err?.response?.data?.data?.[0]?.message ??
            'Something went wrong!'
        )
      })
      .finally(() => {
        setLoading(false)
      })
  }

  // get container specific data
  const getCSTByContainerId = async (cId) => {
    if (cId > 0) {
      setLoading(true)
      axios({
        method: 'GET',
        url:
          `${config.api.baseUrl}/container/stage-tracker?orderContainerDetailsById=` +
          cId,
        headers: {
          Authorization: 'Bearer ' + localStorage.token,
        },
      })
        .then((response) => {
          setListData(response?.data)
        })
        .catch((err) => {
          toast.error(err?.response?.data?.error ?? 'Something went wrong!')
        })
        .finally(() => {
          setLoading(false)
        })
    }
  }

  useEffect(() => {
    if (checkLoadStatus(defaultLoadStatus))
      disableFields(formData.map((data) => data.fieldId))
    else if (mode !== 'view') {
      setByDriver(defaultDriverId, lookupData, {
        setFormFields,
        setDetail,
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mode, lookupData, defaultLoadStatus])

  useEffect(() => {
    if (editingSingleJob) {
      fetchDetailById(jobId)
    } else if (containerDetailsId >= 0) {
      getCSTByContainerId(containerDetailsId)
    } else {
      fetchList()
    }
  }, [containerDetailsId, editingSingleJob, jobId])

  const position = {
    lat: geoLocation.lat / 1000000,
    lng: geoLocation.long / 1000000,
  }

  const onSubmit = (_values) => {
    let values = { ..._values }
    setLoading(true)
    setError(null)

    for (const [key, val] of Object.entries(values)) {
      if (formFields.find((data) => data.fieldId === key)?.multiple) {
        values[key] = val?.map((v) => v.id)
      }
      if (
        formFields.find((data) => data.fieldId === key)?.validationType ===
        'number'
      ) {
        values[key] = parseInt(val)
      }
    }

    axios({
      method: 'PUT',
      url: `${config.api.baseUrl}/container/stage-tracker/${currentRecordId}`,
      headers: {
        Authorization: 'Bearer ' + localStorage.token,
      },
      data: {
        ...values,
      },
    })
      .then(async () => {
        toast.success('Successfully saved')
        if (!editingSingleJob) await getCSTByContainerId(containerDetailsId)
        await fetchDetailById(currentRecordId)
        setShowDisplayForm(false)
      })
      .catch((err) => {
        toast.error('Something went wrong!')
        setError(err?.response?.data)
      })
      .finally(() => {
        setLoading(false)
      })
  }

  const { ModalContainer, setShow: setShowDisplayForm } = useModalContainer({
    header: 'Display Form',
    body: (
      <div className="wrapper-container-display-form">
        <div className="form-wrapper">
          {formFields?.length > 0 && lookupData && (
            <Form
              ref={formRef}
              onSubmit={onSubmit}
              formData={formFields}
              // columns={3}
              uniqueReferenceKey={''}
              formMode={mode}
              defaultValues={detail}
              loading={loading}
            >
              <div className="col-12 my-4">
                <div className="module-footer">
                  <div
                    style={{
                      maxWidth: '400px',
                      color: 'red',
                    }}
                  >
                    <ul>
                      {error?.data?.map((d, i) => (
                        <li key={i}>{`${d?.key} - ${d?.message}`}</li>
                      ))}
                    </ul>
                  </div>
                  <div>
                    <button className="btn btn-primary" type="reset">
                      Reset
                    </button>
                    <button type="submit" className="btn btn-primary ms-2">
                      Submit
                    </button>
                    <button
                      type="button"
                      className="btn btn-danger ms-2"
                      onClick={(e) => {
                        e.preventDefault()
                        setDetail({})
                        setCurrentRecordId('')
                      }}
                    >
                      Exit
                    </button>
                  </div>
                </div>
              </div>
            </Form>
          )}
        </div>
      </div>
    ),
    onCloseCallback: () => {
      setDetail({})
      setCurrentRecordId('')
    },
  })

  const columns = useMemo(
    () => [
      {
        header: 'Action',
        id: 'action',
        cell: ({ row }) => (
          <>
            <button
              className="btn btn-sm"
              type="button"
              onClick={(e) => {
                e.stopPropagation()
                setDetail(row.original)
                setShowDisplayForm(true)
                setCurrentRecordId(row.original.containerStageTrackerId)
                displayFormRef.current?.scrollIntoView()
              }}
            >
              <img
                style={{
                  width: '20px',
                  height: '20px ',
                  backgroundColor: 'transparent',
                }}
                src="./assets/icons8-edit.svg"
                alt="edit"
              />
            </button>
            <button
              className="btn btn-sm btn-info"
              type="button"
              onClick={(e) => {
                e.stopPropagation()
                getGeoLocation(row.original.truckRego, {
                  setShow,
                  setLoading,
                  setGeoLocation,
                })
              }}
            >
              Geo
            </button>
          </>
        ),
      },
      ...stageTrackerColumns,
    ],
    [setShowDisplayForm]
  )

  return (
    <div>
      {loading && <Spinner />}

      <ContainerWithHeading heading="Container Stage Tracker">
        {!editingSingleJob && !isEmpty(listData) && (
          <div className="mt-0 p-2">
            <Table data={listData} columns={columns} />
          </div>
        )}
      </ContainerWithHeading>
      <Modal show={show} onHide={() => setShow(false)} size="xl" centered>
        <Modal.Header closeButton>
          <Modal.Title>Vehicle Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row">
            <div className="col-md-3 locationContainer">
              <span>Location : </span>
              <p>{geoLocation?.postext}</p>
            </div>
            <div className="col-md-3 locationContainer">
              <span>Vehicle Type : </span>
              <p>{geoLocation?.objecttype}</p>
            </div>
            <div className="col-md-3 locationContainer">
              <span>Time : </span>
              <p>{geoLocation?.pos_time}</p>
            </div>
            <div className="col-md-3 locationContainer">
              <span>Vehicle Name : </span>
              <p>{geoLocation?.objectname}</p>
            </div>
          </div>
          <Map positionProp={position} />
        </Modal.Body>
      </Modal>
      {mode !== 'view' && currentRecordId && ModalContainer}
    </div>
  )
}

export default StageTracker
