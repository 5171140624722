import EdoAutomationPage from '../../modules/cerebro/orders/EdoAutomation/EdoAutomationPage'

const routes = [
  {
    name: 'EDO',
    path: '/orders/edo/automation',
    component: EdoAutomationPage,
    includeHeaderFooter: true,
    staticProps: {},
  },
]

export default routes
