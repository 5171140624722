import DataFormTable from '../../modules/cerebro/customer/data-management/components/DataFormTable/DataFormTable'

const customerDataMgmt = [
  {
    name: 'DataMgmt Rate Group',
    path: '/customer/dataManagement/rateGroup',
    component: DataFormTable,
    staticProps: {
      formName: 'Rate Group',
      module: 'customer',
      subModule: 'rateGroup',
      uniqueReferenceKey: 'rateGroupCode',
      lookupKeyName: 'rateGroups',
    },
    includeHeaderFooter: true,
    showOnlySideNavbarIcons: true,
  },
  {
    name: 'DataMgmt Vehicle Job Type',
    path: '/customer/dataManagement/vehicleJobType',
    component: DataFormTable,
    staticProps: {
      formName: 'Delivery Method',
      module: 'customer',
      subModule: 'vehicleJobType',
      uniqueReferenceKey: 'id',
      lookupKeyName: 'vehicleJobTypes',
    },
    includeHeaderFooter: true,
    showOnlySideNavbarIcons: true,
  },
  {
    name: 'Wharf Job Type',
    path: '/customer/dataManagement/wharfJobType',
    component: DataFormTable,
    staticProps: {
      formName: 'Job Type',
      module: 'customer',
      subModule: 'wharfJobType',
      uniqueReferenceKey: 'id',
      lookupKeyName: 'wharfJobTypes',
    },
    includeHeaderFooter: true,
    showOnlySideNavbarIcons: true,
  },
  {
    name: 'Customer Stage Movement Type',
    path: '/customer/dataManagement/stageMovementType',
    component: DataFormTable,
    staticProps: {
      formName: 'Movement Type',
      module: 'customer',
      subModule: 'stageMovementType',
      uniqueReferenceKey: 'transportStage',
      lookupKeyName: 'stageMovementTypes',
    },
    includeHeaderFooter: true,
    showOnlySideNavbarIcons: true,
  },
  {
    name: 'Customer Zone Code',
    path: '/customer/dataManagement/zoneCode',
    component: DataFormTable,
    staticProps: {
      formName: 'Zone Code',
      module: 'customer',
      subModule: 'zoneCode',
      uniqueReferenceKey: 'zoneCode',
      lookupKeyName: 'zoneCodes',
    },
    includeHeaderFooter: true,
    showOnlySideNavbarIcons: true,
  },
  {
    name: 'Country Code',
    path: '/customer/dataManagement/countryCode',
    component: DataFormTable,
    staticProps: {
      formName: 'Country Code',
      module: 'customer',
      subModule: 'countryCode',
      uniqueReferenceKey: 'countryCode',
      lookupKeyName: 'countryCodes',
    },
    includeHeaderFooter: true,
    showOnlySideNavbarIcons: true,
  },
  {
    name: 'Customer Transaction Type',
    path: '/customer/dataManagement/customerTransactionType',
    component: DataFormTable,
    staticProps: {
      formName: 'Transaction Type',
      module: 'customer',
      subModule: 'customerTransactionType',
      uniqueReferenceKey: 'id',
      lookupKeyName: 'customerTransactionTypes',
    },
    includeHeaderFooter: true,
    showOnlySideNavbarIcons: true,
  },
  {
    name: 'Post Code',
    path: '/customer/dataManagement/postCode',
    component: DataFormTable,
    staticProps: {
      formName: 'Post Code',
      module: 'customer',
      subModule: 'postCode',
      uniqueReferenceKey: 'postCode',
      lookupKeyName: 'postCodes',
    },
    includeHeaderFooter: true,
    showOnlySideNavbarIcons: true,
  },
  {
    name: 'Area Code',
    path: '/customer/dataManagement/areaCode',
    component: DataFormTable,
    staticProps: {
      formName: 'Area Code',
      module: 'customer',
      subModule: 'areaCode',
      uniqueReferenceKey: 'areaCode',
      lookupKeyName: 'areaCodes',
    },
    includeHeaderFooter: true,
    showOnlySideNavbarIcons: true,
  },
  {
    name: 'Rate Code',
    path: '/customer/dataManagement/rateCode',
    component: DataFormTable,
    staticProps: {
      formName: 'Rate Code',
      module: 'customer',
      subModule: 'rateCode',
      uniqueReferenceKey: 'rateCode',
      lookupKeyName: 'rateCodes',
    },
    includeHeaderFooter: true,
    showOnlySideNavbarIcons: true,
  },
  {
    name: 'Rational Code',
    path: '/customer/dataManagement/rationalCode',
    component: DataFormTable,
    staticProps: {
      formName: 'Rational Code',
      module: 'customer',
      subModule: 'rationalCode',
      uniqueReferenceKey: 'rationalCode',
      lookupKeyName: 'rationalCodes',
    },
    includeHeaderFooter: true,
    showOnlySideNavbarIcons: true,
  },
  {
    name: 'Job Window',
    path: '/customer/dataManagement/jobWindow',
    component: DataFormTable,
    staticProps: {
      formName: 'Job Window',
      module: 'customer',
      subModule: 'jobWindow',
      uniqueReferenceKey: 'jobWindow',
      lookupKeyName: 'jobWindows',
    },
    includeHeaderFooter: true,
    showOnlySideNavbarIcons: true,
  },
  {
    name: 'Message Type',
    path: '/customer/dataManagement/messageType',
    component: DataFormTable,
    staticProps: {
      formName: 'Message Type',
      module: 'customer',
      subModule: 'messageType',
      uniqueReferenceKey: 'messageCode',
      lookupKeyName: 'messageTypes',
    },
    includeHeaderFooter: true,
    showOnlySideNavbarIcons: true,
  },
  {
    name: 'Wharf Code',
    path: '/customer/dataManagement/wharfCode',
    component: DataFormTable,
    staticProps: {
      formName: 'Wharf Code',
      module: 'customer',
      subModule: 'wharfCode',
      uniqueReferenceKey: 'wharfCode',
      lookupKeyName: 'wharfCodes',
    },
    includeHeaderFooter: true,
    showOnlySideNavbarIcons: true,
  },
  {
    name: 'Port Code',
    path: '/customer/dataManagement/port-code',
    component: DataFormTable,
    staticProps: {
      formName: 'Port Code',
      module: 'customer',
      subModule: 'port_code',
      uniqueReferenceKey: 'portCode',
      lookupKeyName: 'portCodes',
    },
    includeHeaderFooter: true,
    showOnlySideNavbarIcons: true,
  },
  {
    name: 'Shipping Agent',
    path: '/customer/dataManagement/shipping-agent',
    component: DataFormTable,
    staticProps: {
      formName: 'Shipping Agent',
      module: 'customer',
      subModule: 'shipping_agent',
      uniqueReferenceKey: 'shippingAgentCode',
      lookupKeyName: 'shippingAgents',
    },
    includeHeaderFooter: true,
    showOnlySideNavbarIcons: true,
  },
  {
    name: 'Empty Dehire',
    path: '/customer/dataManagement/empty-dehire',
    component: DataFormTable,
    staticProps: {
      formName: 'Empty Dehire',
      module: 'customer',
      subModule: 'empty_dehire',
      uniqueReferenceKey: 'code',
      lookupKeyName: 'mtdehires',
    },
    includeHeaderFooter: true,
    showOnlySideNavbarIcons: true,
  },
  {
    name: 'Warehouse',
    path: '/customer/dataManagement/warehouse',
    component: DataFormTable,
    staticProps: {
      formName: 'Warehouse',
      module: 'customer',
      subModule: 'warehouse',
      uniqueReferenceKey: 'code',
      lookupKeyName: 'wareHouses',
    },
    includeHeaderFooter: true,
    showOnlySideNavbarIcons: true,
  },
  {
    name: 'Yard',
    path: '/customer/dataManagement/yard',
    component: DataFormTable,
    staticProps: {
      formName: 'Yard',
      module: 'customer',
      subModule: 'yard',
      uniqueReferenceKey: 'code',
      lookupKeyName: 'yards',
    },
    includeHeaderFooter: true,
    showOnlySideNavbarIcons: true,
  },
  {
    name: 'Vendor Code',
    path: '/customer/dataManagement/vendor-code',
    component: DataFormTable,
    staticProps: {
      formName: 'Vendor Code',
      module: 'customer',
      subModule: 'vendor_code',
      uniqueReferenceKey: 'vendorCode',
      lookupKeyName: 'vendorCodes',
    },
    includeHeaderFooter: true,
    showOnlySideNavbarIcons: true,
  },
  {
    name: 'Service Yard',
    path: '/customer/dataManagement/service-yard',
    component: DataFormTable,
    staticProps: {
      formName: 'Service Yard',
      module: 'customer',
      subModule: 'service_yard',
      uniqueReferenceKey: 'code',
      lookupKeyName: 'serviceYards',
    },
    includeHeaderFooter: true,
    showOnlySideNavbarIcons: true,
  },
  {
    name: 'Rail head',
    path: '/customer/dataManagement/rail-head',
    component: DataFormTable,
    staticProps: {
      formName: 'Rail head',
      module: 'customer',
      subModule: 'rail_head',
      uniqueReferenceKey: 'code',
      lookupKeyName: 'railHeads',
    },
    includeHeaderFooter: true,
    showOnlySideNavbarIcons: true,
  },
]

export default customerDataMgmt
