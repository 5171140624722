import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import React, { useMemo, useState } from 'react'
import { Spinner } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { ICONS } from '../../../../components/Common/Icons'
import Table from '../../../../components/Common/Tanstack-table/Table'
import PageSpinner from '../../../../components/Spinner/Spinner'
import * as DateFun from '../../../../components/utils/DateFun'
import * as Toastify from '../../../../components/utils/Toastify'
import fetchService from '../../../../components/utils/fetchService'
import './OperationsList.scss'
import { toast } from 'react-toastify'

const CheckBoxCell = ({ checked }) => {
  return (
    <div className="d-flex justify-content-center">
      <input type="checkbox" checked={checked} readOnly />
    </div>
  )
}

const columns = [
  {
    header: 'Order Number',
    accessorKey: 'orderNumber',
    id: 'orderNumber',
  },
  {
    header: 'Order Date',
    accessorKey: 'orderDate',
    id: 'orderDate',
  },
  {
    header: 'Job Type',
    accessorKey: 'wharfJobType',
    id: 'wharfJobType',
  },
  {
    header: 'Freight Type',
    accessorKey: 'freightType',
    id: 'freightType',
  },
  {
    header: 'Movement Type',
    accessorKey: 'shipMovementType',
    id: 'shipMovementType',
  },
  {
    header: 'Customer Name',
    accessorKey: 'customerName',
    id: 'customerName',
  },
  {
    header: 'Shipper',
    accessorKey: 'shipper',
    id: 'shipper',
  },
  {
    header: 'Consignee',
    accessorKey: 'consignee',
    id: 'consignee',
  },
  {
    header: 'F 20',
    accessorKey: 'f20',
    id: 'f20',
  },
  {
    header: 'F 40',
    accessorKey: 'f40',
    id: 'f40',
  },
  {
    header: 'Customer Ref',
    accessorKey: 'customerRef',
    id: 'customerRef',
  },
  {
    header: 'Freight',
    accessorKey: 'freight',
    id: 'freight',
    cell: (cell) => <CheckBoxCell checked={cell.getValue()} />,
  },
  {
    header: 'Yard',
    accessorKey: 'yard',
    id: 'yard',
    cell: (cell) => <CheckBoxCell checked={cell.getValue()} />,
  },
  {
    header: 'WMS',
    accessorKey: 'wms',
    id: 'wms',
    cell: (cell) => <CheckBoxCell checked={cell.getValue()} />,
  },
  {
    header: 'MT',
    accessorKey: 'mt',
    id: 'mt',
    cell: (cell) => <CheckBoxCell checked={cell.getValue()} />,
  },
  {
    header: 'Obl No',
    accessorKey: 'oblNo',
    id: 'oblNo',
  },
  {
    header: 'Release No',
    accessorKey: 'releaseNo',
    id: 'releaseNo',
  },
  {
    header: 'Loading Port',
    accessorKey: 'loadingPort',
    id: 'loadingPort',
  },
  {
    header: 'Discharge Port',
    accessorKey: 'dischargePort',
    id: 'dischargePort',
  },
  {
    header: 'Vessel Id',
    accessorKey: 'vesselId',
    id: 'vesselId',
  },
  {
    header: 'Vessel Name',
    accessorKey: 'vesselName',
    id: 'vesselName',
  },
  {
    header: 'Voyage No',
    accessorKey: 'voyageNo',
    id: 'voyageNo',
  },
  {
    header: 'Wharf',
    accessorKey: 'wharf',
    id: 'wharf',
  },
  {
    header: 'ETA',
    accessorKey: 'eta',
    id: 'eta',
  },
  {
    header: 'EDO',
    accessorKey: 'edo',
    id: 'edo',
  },
  {
    header: 'Order Created By',
    accessorKey: 'orderCreatedBy',
    id: 'orderCreatedBy',
  },
  {
    header: 'Edit By',
    accessorKey: 'editBy',
    id: 'editBy',
  },
  {
    header: 'Edit Order Details',
    accessorKey: 'editOrderDetails',
    id: 'editOrderDetails',
  },
  {
    header: 'Status',
    accessorKey: 'orderStatus',
    id: 'orderStatus',
  },
]

export default function OrdersList() {
  const [sortBy, setSortBy] = useState('')
  const queryClient = useQueryClient()

  const GetCustomerOrdersDashboardData = async () => {
    let todayDate = DateFun.getFullDate(new Date())
    let priorDate = DateFun.getFullDate(
      new Date(new Date().setDate(new Date().getDate() - 60))
    )

    let options = {
      fromDate: DateFun.getShortDate(priorDate),
      maxResults: 100,
      orderStatus: null,
      pageNumber: 0,
      toDate: DateFun.getShortDate(todayDate),
      ...(sortBy && { wharfJobType: sortBy }),
    }

    return await fetchService({
      url: '/operations/order/customer/dashboard',
      method: 'POST',
      body: options,
    })
  }

  const {
    data: response,
    error,
    isLoading,
  } = useQuery({
    queryKey: ['/operations/order/customer/dashboard', sortBy],
    queryFn: GetCustomerOrdersDashboardData,
    retry: 2,
    staleTime: 60_000 * 15,
  })

  const deleteOrderMutation = useMutation({
    mutationKey: ['/operations/order/customer', 'delete'],
    mutationFn: async (id) =>
      await fetchService({
        url: `/operations/order/customer/${id}`,
        method: 'DELETE',
        isFile: true,
      }),
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: ['/operations/order/customer/dashboard', sortBy],
      })
      Toastify.Info('Order Deleted successfully!')
    },
  })

  const cols = useMemo(() => {
    const c = [...columns]
    c.unshift({
      header: 'Action',
      id: 'actions',
      size: 125,
      cell: ({ row }) => {
        const isDisabled = row.original.orderStatus === 'COMPLETED'
        return (
          <div className="d-flex gap-3 justify-content-center">
            <Link
              to={`/Operations/view/${row.original.orderCustomerDetailsId}`}
            >
              {ICONS.VIEW}
            </Link>
            {isDisabled ? (
              <button
                className="unset-all"
                onClick={() =>
                  Toastify.Info(
                    "Order status is completed you can't edit again",
                    { position: toast.POSITION.BOTTOM_LEFT }
                  )
                }
              >
                {ICONS.EDIT_DISABLED}
              </button>
            ) : (
              <Link
                to={`/Operations/edit/${row.original.orderCustomerDetailsId}`}
              >
                {ICONS.EDIT}
              </Link>
            )}
            <button
              className="unset-all"
              onClick={() =>
                deleteOrderMutation.mutate(row.original.orderCustomerDetailsId)
              }
            >
              {ICONS.DELETE}
            </button>
          </div>
        )
      },
    })
    return c
  }, [deleteOrderMutation])

  const operationsListData = response?.data

  const changeSortBy = (order) => {
    setSortBy(order)
  }

  if (error) {
    return <pre>{JSON.stringify(error, null, 2)}</pre>
  }

  return (
    <section className="operations-list-wrapper w-100">
      {deleteOrderMutation.isLoading && <PageSpinner />}
      <div className="row p-2">
        <div className="col-md-12">
          <div className="card">
            <div className="card-header">
              <h4>Customer Orders Dashboard</h4>
            </div>
            <div className="card-body p-2">
              <div className="sort-orders-options-wrapper mb-2 text-dark">
                <span
                  onClick={() => changeSortBy('')}
                  className={sortBy === '' && 'selected'}
                >
                  All
                </span>
                <span
                  className={sortBy === 'Import' ? 'selected' : undefined}
                  onClick={() => changeSortBy('Import')}
                >
                  Import
                </span>
                <span
                  className={sortBy === 'Export' ? 'selected' : undefined}
                  onClick={() => changeSortBy('Export')}
                >
                  Export
                </span>
                <span
                  className={sortBy === 'Local' ? 'selected' : undefined}
                  onClick={() => changeSortBy('Local')}
                >
                  Local
                </span>
              </div>
              {isLoading ? (
                <div
                  className="w-100 d-flex justify-content-center align-items-center"
                  style={{ minHeight: '70vh' }}
                >
                  <Spinner
                    style={{
                      color: 'darkslateblue',
                      width: '148px',
                      height: '148px',
                    }}
                  />
                </div>
              ) : (
                <div className="table-wrapper">
                  <Table
                    inheritTableWidth
                    columns={cols}
                    data={operationsListData}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}
