const postCode = {
  name: 'Post Code',
  path: '/dataMgmt/user/profileType',
  req: '/',
  columns: 4,
  fields: [
    {
      name: 'Post Code',
      label: 'Post Code',
      type: 'text',
      validation: 'Alphabetic',
      fieldId: 'postCode',
      id: 'postCode',
      validationType: 'string',
      validations: [
        {
          type: 'required',
          params: ['this field is required'],
        },
        {
          type: 'max',
          params: [10, 'Post Code be more than 10 characters'],
        },
        {
          type: 'min',
          params: [4, 'Field should be min 4 characters'],
        },
      ],
    },
    {
      name: 'City Suburb',
      label: 'City Suburb',
      type: 'text',
      validation: 'Alphabetic',
      fieldId: 'citySuburb',
      id: 'citySuburb',
      validationType: 'string',
      validations: [
        {
          type: 'required',
          params: ['this field is required'],
        },
        {
          type: 'max',
          params: [30, 'City Suburb be more than 30 characters'],
        },
      ],
    },
    {
      name: 'State',
      label: 'State',
      type: 'select',
      validation: 'Alphabetic',
      fieldId: 'state',
      id: 'state',
      validationType: 'string',
      options: [
        {
          label: 'New South Wales',
          id: 'NSW',
        },
        {
          label: 'Victoria',
          id: 'Vic',
        },
        {
          label: 'Queensland',
          id: 'Queensland',
        },
        {
          label: 'South Australia',
          id: 'SA',
        },
        {
          label: 'Western Australia',
          id: 'WA',
        },
        {
          label: 'Tasmania',
          id: 'Tasmania',
        },
        {
          label: 'Northern Territory',
          id: 'NT',
        },
        {
          label: 'Australian Capital Territory',
          id: 'ACT',
        },
      ],
      validations: [
        {
          type: 'required',
          params: ['this field is required'],
        },
      ],
    },
  ],
}
export default postCode
